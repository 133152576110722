/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BaseUser,
    BaseUserFromJSON,
    BaseUserFromJSONTyped,
    BaseUserToJSON,
} from './BaseUser';
import {
    JwtVerifiedCredential,
    JwtVerifiedCredentialFromJSON,
    JwtVerifiedCredentialFromJSONTyped,
    JwtVerifiedCredentialToJSON,
} from './JwtVerifiedCredential';
import {
    MfaBackupCodeAcknowledgement,
    MfaBackupCodeAcknowledgementFromJSON,
    MfaBackupCodeAcknowledgementFromJSONTyped,
    MfaBackupCodeAcknowledgementToJSON,
} from './MfaBackupCodeAcknowledgement';
import {
    ProjectSettingsKyc,
    ProjectSettingsKycFromJSON,
    ProjectSettingsKycFromJSONTyped,
    ProjectSettingsKycToJSON,
} from './ProjectSettingsKyc';

/**
 * 
 * @export
 * @interface SdkUser
 */
export interface SdkUser {
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    projectEnvironmentId: string;
    /**
     * 
     * @type {Array<JwtVerifiedCredential>}
     * @memberof SdkUser
     */
    verifiedCredentials: Array<JwtVerifiedCredential>;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    lastVerifiedCredentialId?: string;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    alias?: string | null;
    /**
     * Standard ISO 3166-1 alpha-2 two-letter country code
     * @type {string}
     * @memberof SdkUser
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    jobTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SdkUser
     */
    policiesConsent?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    tShirtSize?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    team?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    username?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof SdkUser
     */
    firstVisit?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SdkUser
     */
    lastVisit?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SdkUser
     */
    newUser?: boolean;
    /**
     * 
     * @type {object}
     * @memberof SdkUser
     */
    metadata?: object;
    /**
     * 
     * @type {MfaBackupCodeAcknowledgement}
     * @memberof SdkUser
     */
    mfaBackupCodeAcknowledgement?: MfaBackupCodeAcknowledgement | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    btcWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    kdaWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    ltcWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    ckbWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    kasWallet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SdkUser
     */
    dogeWallet?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof SdkUser
     */
    emailNotification?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SdkUser
     */
    discordNotification?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof SdkUser
     */
    newsletterNotification?: boolean | null;
    /**
     * Access lists evaluated by Dynamic when considering access for the user.
     * @type {Array<string>}
     * @memberof SdkUser
     */
    lists?: Array<string>;
    /**
     * A whitespace-separate list of permissions associated with the JWT token issued. This conforms to the JWT standard for scope claims: https://datatracker.ietf.org/doc/html/rfc8693#section-4.2
     * @type {string}
     * @memberof SdkUser
     */
    scope?: string;
    /**
     * 
     * @type {Array<ProjectSettingsKyc>}
     * @memberof SdkUser
     */
    missingFields?: Array<ProjectSettingsKyc>;
}

export function SdkUserFromJSON(json: any): SdkUser {
    return SdkUserFromJSONTyped(json, false);
}

export function SdkUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'projectEnvironmentId': json['projectEnvironmentId'],
        'verifiedCredentials': ((json['verifiedCredentials'] as Array<any>).map(JwtVerifiedCredentialFromJSON)),
        'lastVerifiedCredentialId': !exists(json, 'lastVerifiedCredentialId') ? undefined : json['lastVerifiedCredentialId'],
        'sessionId': !exists(json, 'sessionId') ? undefined : json['sessionId'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'policiesConsent': !exists(json, 'policiesConsent') ? undefined : json['policiesConsent'],
        'tShirtSize': !exists(json, 'tShirtSize') ? undefined : json['tShirtSize'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'firstVisit': !exists(json, 'firstVisit') ? undefined : (new Date(json['firstVisit'])),
        'lastVisit': !exists(json, 'lastVisit') ? undefined : (new Date(json['lastVisit'])),
        'newUser': !exists(json, 'newUser') ? undefined : json['newUser'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mfaBackupCodeAcknowledgement': !exists(json, 'mfaBackupCodeAcknowledgement') ? undefined : MfaBackupCodeAcknowledgementFromJSON(json['mfaBackupCodeAcknowledgement']),
        'btcWallet': !exists(json, 'btcWallet') ? undefined : json['btcWallet'],
        'kdaWallet': !exists(json, 'kdaWallet') ? undefined : json['kdaWallet'],
        'ltcWallet': !exists(json, 'ltcWallet') ? undefined : json['ltcWallet'],
        'ckbWallet': !exists(json, 'ckbWallet') ? undefined : json['ckbWallet'],
        'kasWallet': !exists(json, 'kasWallet') ? undefined : json['kasWallet'],
        'dogeWallet': !exists(json, 'dogeWallet') ? undefined : json['dogeWallet'],
        'emailNotification': !exists(json, 'emailNotification') ? undefined : json['emailNotification'],
        'discordNotification': !exists(json, 'discordNotification') ? undefined : json['discordNotification'],
        'newsletterNotification': !exists(json, 'newsletterNotification') ? undefined : json['newsletterNotification'],
        'lists': !exists(json, 'lists') ? undefined : json['lists'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'missingFields': !exists(json, 'missingFields') ? undefined : ((json['missingFields'] as Array<any>).map(ProjectSettingsKycFromJSON)),
    };
}

export function SdkUserToJSON(value?: SdkUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'projectEnvironmentId': value.projectEnvironmentId,
        'verifiedCredentials': ((value.verifiedCredentials as Array<any>).map(JwtVerifiedCredentialToJSON)),
        'lastVerifiedCredentialId': value.lastVerifiedCredentialId,
        'sessionId': value.sessionId,
        'alias': value.alias,
        'country': value.country,
        'email': value.email,
        'firstName': value.firstName,
        'jobTitle': value.jobTitle,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'policiesConsent': value.policiesConsent,
        'tShirtSize': value.tShirtSize,
        'team': value.team,
        'username': value.username,
        'firstVisit': value.firstVisit === undefined ? undefined : (value.firstVisit.toISOString()),
        'lastVisit': value.lastVisit === undefined ? undefined : (value.lastVisit.toISOString()),
        'newUser': value.newUser,
        'metadata': value.metadata,
        'mfaBackupCodeAcknowledgement': MfaBackupCodeAcknowledgementToJSON(value.mfaBackupCodeAcknowledgement),
        'btcWallet': value.btcWallet,
        'kdaWallet': value.kdaWallet,
        'ltcWallet': value.ltcWallet,
        'ckbWallet': value.ckbWallet,
        'kasWallet': value.kasWallet,
        'dogeWallet': value.dogeWallet,
        'emailNotification': value.emailNotification,
        'discordNotification': value.discordNotification,
        'newsletterNotification': value.newsletterNotification,
        'lists': value.lists,
        'scope': value.scope,
        'missingFields': value.missingFields === undefined ? undefined : ((value.missingFields as Array<any>).map(ProjectSettingsKycToJSON)),
    };
}

