/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnvironmentsResponseEnvironments,
    EnvironmentsResponseEnvironmentsFromJSON,
    EnvironmentsResponseEnvironmentsFromJSONTyped,
    EnvironmentsResponseEnvironmentsToJSON,
} from './EnvironmentsResponseEnvironments';

/**
 * 
 * @export
 * @interface EnvironmentsResponse
 */
export interface EnvironmentsResponse {
    /**
     * 
     * @type {EnvironmentsResponseEnvironments}
     * @memberof EnvironmentsResponse
     */
    environments: EnvironmentsResponseEnvironments;
}

export function EnvironmentsResponseFromJSON(json: any): EnvironmentsResponse {
    return EnvironmentsResponseFromJSONTyped(json, false);
}

export function EnvironmentsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environments': EnvironmentsResponseEnvironmentsFromJSON(json['environments']),
    };
}

export function EnvironmentsResponseToJSON(value?: EnvironmentsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environments': EnvironmentsResponseEnvironmentsToJSON(value.environments),
    };
}

