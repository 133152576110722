/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnvironmentVisitorsResponseUsers
 */
export interface EnvironmentVisitorsResponseUsers {
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVisitorsResponseUsers
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof EnvironmentVisitorsResponseUsers
     */
    uniquePastMonth?: number;
}

export function EnvironmentVisitorsResponseUsersFromJSON(json: any): EnvironmentVisitorsResponseUsers {
    return EnvironmentVisitorsResponseUsersFromJSONTyped(json, false);
}

export function EnvironmentVisitorsResponseUsersFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentVisitorsResponseUsers {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'uniquePastMonth': !exists(json, 'uniquePastMonth') ? undefined : json['uniquePastMonth'],
    };
}

export function EnvironmentVisitorsResponseUsersToJSON(value?: EnvironmentVisitorsResponseUsers | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'uniquePastMonth': value.uniquePastMonth,
    };
}

