/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TurnkeyWalletAccount,
    TurnkeyWalletAccountFromJSON,
    TurnkeyWalletAccountFromJSONTyped,
    TurnkeyWalletAccountToJSON,
} from './TurnkeyWalletAccount';

/**
 * 
 * @export
 * @interface TurnkeyCreateWalletAccountsRequestBodyParameters
 */
export interface TurnkeyCreateWalletAccountsRequestBodyParameters {
    /**
     * 
     * @type {string}
     * @memberof TurnkeyCreateWalletAccountsRequestBodyParameters
     */
    walletId: string;
    /**
     * 
     * @type {Array<TurnkeyWalletAccount>}
     * @memberof TurnkeyCreateWalletAccountsRequestBodyParameters
     */
    accounts: Array<TurnkeyWalletAccount>;
}

export function TurnkeyCreateWalletAccountsRequestBodyParametersFromJSON(json: any): TurnkeyCreateWalletAccountsRequestBodyParameters {
    return TurnkeyCreateWalletAccountsRequestBodyParametersFromJSONTyped(json, false);
}

export function TurnkeyCreateWalletAccountsRequestBodyParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnkeyCreateWalletAccountsRequestBodyParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletId': json['walletId'],
        'accounts': ((json['accounts'] as Array<any>).map(TurnkeyWalletAccountFromJSON)),
    };
}

export function TurnkeyCreateWalletAccountsRequestBodyParametersToJSON(value?: TurnkeyCreateWalletAccountsRequestBodyParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletId': value.walletId,
        'accounts': ((value.accounts as Array<any>).map(TurnkeyWalletAccountToJSON)),
    };
}

