import { FunctionComponent } from 'react';

import { ChainEnum, ProjectSettingsChains } from '@dynamic-labs/sdk-api';
import { WalletConnector } from '@dynamic-labs/sdk-react-core';

export enum IntegrationCategory {
  Chains = 'chains',
  OnboardingAndKYC = 'kyc',
}

export type ProjectSettingCategory =
  | 'chains'
  | 'kyc'
  | 'sdk'
  | 'providers'
  | 'design'
  | 'security';

export type IntegrationBase = {
  Icon: FunctionComponent;
  name: string;
};

export type Integration = IntegrationBase & {
  chain?: ChainEnum;
  comingSoon?: boolean;
  content: {
    full: string;
    short: string;
  };
  scope?: string;
  title: string;
  url: string;
  walletDescription?: string;
};
export type WalletRow = {
  chainClassName: string;
  description: string;
  key: string;
  name: string;
  title: string;
  walletConnector: WalletConnector;
};

export type IntegrationModal = Omit<Integration, 'category' | 'url'> & {
  liveSettings: ProjectSettingsChains[];
  networkMessage?: string;
  requireCustomRpcUrl?: boolean;
  setShowModal: (value: boolean) => void;
  wallets: WalletRow[];
};

export type ChainalysisControlModal = Omit<
  Integration,
  'category' | 'url' | 'chain' | 'walletDescription'
>;

export type Option = { label: string; value: string };

export interface SortBy {
  desc: boolean;
  id: string;
}

export type RpcTestResult = {
  message: string;
  success: boolean;
};

export enum OnboardingStep {
  CREATE_ORGANIZATION = 'create_org',
  INVITE_ACCOUNTS = 'invite_accounts',
}

export type AccessControlType = 'access_list' | 'gating';

export type GateNetwork = {
  chain: string;
  name: string;
  networkId: number;
};
