import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { boolean, object, string } from 'yup';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ExternalAuth } from '@dynamic-labs/sdk-api';
import { AlertProps, Section } from '@dynamic-labs/northstar';

import { useSubscriptionContext } from '../../../app/context/SubscriptionContext';
import { useExternalAuthSettings } from '../../../app/context/SettingsContext/helpers/externalAuthSettings';
import { FormErrors } from '../../../app/routes/Settings/General/FormErrors';
import { logger } from '../../../app/services/logger';
import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { environmentsApi } from '../../../app/services/api';
import { PageHeader, SaveBanner } from '../../components';

import styles from './ThirdPartyAuthSettings.module.scss';
import { ThirdPartyAuthFields } from './ThirdPartyAuthFields';
import { JwtTest } from './JwtTest';

export const ThirdPartyAuth = () => {
  const { t } = useTranslation();
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const {
    cancelChanges,
    globalSettings,
    settings: securitySettings,
    settingsHasChanged,
    updateInitialSettings,
  } = useExternalAuthSettings();
  const { externalAuth } = securitySettings || {};
  const externalAuthDefined = !!externalAuth;
  const [loading, setLoading] = useState(false);

  const { checks } = useSubscriptionContext();
  const { isEnterprisePlan } = checks;
  const { enableExternalAuth } = useFlags();

  const sectionAlerts = useMemo(() => {
    const alerts: AlertProps[] = [];

    if (!isEnterprisePlan && !enableExternalAuth) {
      alerts.push({
        action: {
          as: 'a',
          href: 'https://www.dynamic.xyz/join-slack',
          text: t('third_party_auth.disabled.action'),
        },
        description: t('third_party_auth.disabled.description'),
        title: t('third_party_auth.disabled.heading'),
        variant: 'warning',
      });
    }

    return alerts;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnterprisePlan, enableExternalAuth]);

  const onSubmit = async () => {
    try {
      setLoading(true);
      const response = await environmentsApi.updateProjectSettings({
        environmentId: activeEnvironment?.id ?? '',
        projectSettings: globalSettings[activeEnvironmentType],
      });
      if (!response?.security.externalAuth) return;

      updateInitialSettings();
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    cancelChanges();
  };

  const validationSchema = object().shape({
    aud: string().max(255),
    cookieName: string().max(255),
    enabled: boolean(),
    iss: string().max(255).required(),
    jwksUrl: string().url().required(),
  });

  const initialValues: ExternalAuth = useMemo(
    () => ({
      aud: externalAuthDefined ? externalAuth.aud ?? '' : '',
      cookieName: externalAuthDefined ? externalAuth.cookieName ?? '' : '',
      enabled: externalAuthDefined ? externalAuth.enabled ?? false : false,
      iss: externalAuthDefined ? externalAuth.iss ?? '' : '',
      jwksUrl: externalAuthDefined ? externalAuth.jwksUrl ?? '' : '',
    }),
    [externalAuth],
  );

  if (
    !Object.keys(
      globalSettings[activeEnvironmentType].security?.externalAuth || {},
    ).length
  ) {
    return null;
  }

  return (
    <section className={styles.wrapper} data-testid='third-party-auth'>
      <div className={styles.container}>
        <PageHeader
          title={t('third_party_auth.heading')}
          description={t('third_party_auth.description')}
        />
        <Section
          title={t('third_party_auth.section_title')}
          alerts={sectionAlerts}
          alertsAlignment='top'
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
          >
            {({ errors, handleSubmit }) => (
              <Form>
                <FormErrors />
                <div className={styles.wrapper}>
                  <ThirdPartyAuthFields />
                  <JwtTest />
                </div>
                <AnimatePresence>
                  {Object.keys(errors).length === 0 && settingsHasChanged && (
                    <SaveBanner
                      handleOnSave={handleSubmit}
                      handleOnCancel={handleCancelClick}
                      isLoading={loading}
                    />
                  )}
                </AnimatePresence>
              </Form>
            )}
          </Formik>
        </Section>
      </div>
    </section>
  );
};
