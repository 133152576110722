import Moment from 'react-moment';
import { TFunction } from 'react-i18next';

import { Visitor } from '@dynamic-labs/sdk-api';

import { WalletName } from '../../../../components/WalletName';
import { WalletAddress } from '../../../../components/WalletAddress';
import { ChainName } from '../../../../components/ChainName';
import { TableEmptyData } from '../../../../components/TableEmptyData';
import { TableSkeleton } from '../../../../components/TableSkeleton';

export const visitorsColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: '#',
    accessor: (_row: Visitor, i: number) => i + 1,
    className: 'text-gray-2 min-w-[28px]',
    id: 'index',
    sortable: false,
  },
  {
    Header: t('visitors_management_table.visitId'),
    accessor: (row: Visitor) => row.id,
    className: 'min-w-[270px]',
    id: 'id',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('visitors_management_table.address'),
    accessor: ({ address, chain }: Visitor) =>
      address ? (
        <WalletAddress address={address} chain={chain} />
      ) : (
        <TableEmptyData />
      ),
    className: 'min-w-[116px]',
    id: 'address',
    skeleton: <TableSkeleton />,
    sortable: false,
  },
  {
    Header: t('visitors_management_table.wallet'),
    accessor: ({ walletName }: Visitor) =>
      walletName ? <WalletName name={walletName} /> : <TableEmptyData />,
    className: 'min-w-[116px]',
    id: 'walletName',
    skeleton: <TableSkeleton />,
    sortable: false,
  },

  {
    Header: t('visitors_management_table.chain'),
    accessor: ({ chain }: Visitor) =>
      chain ? <ChainName name={chain} /> : <TableEmptyData />,
    className: 'min-w-[116px]',
    id: 'chain',
    skeleton: <TableSkeleton />,
    sortable: false,
  },
  {
    Header: t('visitors_management_table.connected'),
    accessor: ({ createdAt }: Visitor) =>
      createdAt ? <Moment fromNow>{createdAt}</Moment> : <TableEmptyData />,
    className: 'text-gray-2 min-w-[182px]',
    id: 'createdAt',
    skeleton: <TableSkeleton />,
  },
];
