/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Key
 */
export interface Key {
    /**
     * 
     * @type {string}
     * @memberof Key
     */
    id?: string;
    /**
     * Base64-encoded public key of the corresponding private key used to encode a Dynamic JWT
     * @type {string}
     * @memberof Key
     */
    publicKey?: string;
}

export function KeyFromJSON(json: any): Key {
    return KeyFromJSONTyped(json, false);
}

export function KeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Key {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
    };
}

export function KeyToJSON(value?: Key | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'publicKey': value.publicKey,
    };
}

