import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  EnvironmentEnum,
  ProviderEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';
import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { useSettingsHasChanged } from '../../../../../../app/hooks/useSettingsHasChanged';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { enforceProviderRules } from '../../../../../../app/utils/enforceProviderRules';
import { useSubscriptionLock } from '../../../../../../app/hooks/useSubscriptionLock';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useSaveProvidersSettings } from '../../../../../hooks';
import { ROUTES } from '../../../../../../app/components/Navigation/data';
import { ModalHashLocations } from '../../../../../../app/routes/Configurations/utils';
import { useSignInWithSocialProviders } from '../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';

export enum MissingSettingsWarningEnum {
  KycSocial = 'kycSocial',
  MagicCredentials = 'magicCredentials',
}

export type UseSaveThirdPartySectionReturnType = {
  handleReset: VoidFunction;
  handleSave: VoidFunction;
  missingSettings: {
    action: VoidFunction;
    i18nKeyActionText: string;
    i18nKeyText: string;
    setting: MissingSettingsWarningEnum;
  }[];
  setShouldShowMissingSettingsWarning: Dispatch<SetStateAction<boolean>>;
  shouldShowMissingSettingsWarning: boolean;
  shouldShowSaveBanner: boolean;
};

export const useSaveThirdPartySection =
  (): UseSaveThirdPartySectionReturnType => {
    const navigate = useNavigate();

    const MissingSettingsVariants: Record<
      MissingSettingsWarningEnum,
      {
        action: VoidFunction;
        i18nKeyActionText: string;
        i18nKeyText: string;
      }
    > = {
      [MissingSettingsWarningEnum.MagicCredentials]: {
        action: () => {
          navigate(
            `${ROUTES.embeddedWalletsThirdParty}#${ModalHashLocations.ThirdPartyMagicLink}`,
          );
        },
        i18nKeyActionText:
          'v2.page.embedded_wallets.third_party.missing_settings.credentials_action',
        i18nKeyText:
          'v2.page.embedded_wallets.third_party.missing_settings.credentials',
      },
      [MissingSettingsWarningEnum.KycSocial]: {
        action: () => {
          navigate(
            `${ROUTES.configurations}#${ModalHashLocations.InformationCapture}`,
          );
        },
        i18nKeyActionText:
          'v2.page.embedded_wallets.third_party.missing_settings.social_linking_action',
        i18nKeyText:
          'v2.page.embedded_wallets.third_party.missing_settings.social_linking',
      },
    };

    const {
      hasChanges: hasAnyProviderChanged,
      resetProviderState,
      changedProviders,
      getProviderValue,
      enabledSocialProviders: enabledLinkingProviders,
    } = useProvidersContext();
    const {
      cancelChanges: cancelProjectChanges,
      updateInitialSettings: updateProjectInitialSettings,
      liveHasChanged,
      sandboxHasChanged,
    } = useSettingsHasChanged('sdk');

    const { enabledProviders: enabledSocialSignInProviders } =
      useSignInWithSocialProviders({
        disabled: false,
      });

    const { activeEnvironmentType, environments } = useEnvironmentsContext();
    const { settings } = useSettingsContext();
    const { shouldLockOnLive } = useSubscriptionLock(
      SubscriptionAdvancedScopeEnum.SigninWithEmail,
    );

    const [
      shouldShowMissingSettingsWarning,
      setShouldShowMissingSettingsWarning,
    ] = useState(false);

    const { saveProviders } = useSaveProvidersSettings();

    const { mutate: saveProjectSettings } = useUpdateProjectSettings({
      onSuccess: () => {
        updateProjectInitialSettings(activeEnvironmentType);
      },
    });

    const projectSettingsHasChanged =
      (liveHasChanged && activeEnvironmentType === EnvironmentEnum.Live) ||
      (sandboxHasChanged && activeEnvironmentType === EnvironmentEnum.Sandbox);

    const handleSave = () => {
      if (!shouldLockOnLive) {
        if (missingSettings.length > 0) {
          setShouldShowMissingSettingsWarning(true);
          return;
        }

        if (projectSettingsHasChanged) {
          saveProjectSettings({
            environmentId: environments?.[activeEnvironmentType].id || '',
            projectSettings: settings[activeEnvironmentType],
          });
        }

        if (hasAnyProviderChanged) {
          saveProviders({
            providersToSave: enforceProviderRules(changedProviders),
          });
        }
      }
    };

    const isMagicEnabled = Boolean(
      getProviderValue(ProviderEnum.MagicLink, 'enabledAt'),
    );

    // What needs to be toggled to save?
    // 1. Magic needs to have credentials
    // 2. Email OTP needs to be enabled
    // 2. Social providers needs to be disabled in kyc to enable magic social
    const missingSettings = useMemo(() => {
      if (!isMagicEnabled) {
        return [];
      }

      const missingSettingsList: MissingSettingsWarningEnum[] = [];

      const magicConfigured = Boolean(
        getProviderValue(ProviderEnum.MagicLink, 'clientSecret') &&
          getProviderValue(ProviderEnum.MagicLink, 'clientId'),
      );

      if (!magicConfigured) {
        missingSettingsList.push(MissingSettingsWarningEnum.MagicCredentials);
      }

      const linkingWithSocialEnabled = Boolean(
        enabledLinkingProviders.length > 0 &&
          settings[activeEnvironmentType].kyc.filter(
            (field) => field.name === 'social' && field.enabled,
          ).length > 0,
      );

      if (linkingWithSocialEnabled && enabledSocialSignInProviders.length > 0) {
        missingSettingsList.push(MissingSettingsWarningEnum.KycSocial);
      }

      return missingSettingsList;
    }, [
      isMagicEnabled,
      activeEnvironmentType,
      enabledLinkingProviders.length,
      enabledSocialSignInProviders.length,
      getProviderValue,
      settings,
    ]);

    const handleReset = () => {
      resetProviderState();
      cancelProjectChanges();
    };

    const shouldShowSaveBanner =
      hasAnyProviderChanged || projectSettingsHasChanged;

    return {
      handleReset,
      handleSave,
      missingSettings: missingSettings.map((setting) => ({
        action: MissingSettingsVariants[setting].action,
        i18nKeyActionText: MissingSettingsVariants[setting].i18nKeyActionText,
        i18nKeyText: MissingSettingsVariants[setting].i18nKeyText,
        setting,
      })),
      setShouldShowMissingSettingsWarning,
      shouldShowMissingSettingsWarning,
      shouldShowSaveBanner,
    };
  };
