/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateUserOauthRequest,
    CreateUserOauthRequestFromJSON,
    CreateUserOauthRequestFromJSONTyped,
    CreateUserOauthRequestToJSON,
} from './CreateUserOauthRequest';
import {
    CreateWalletRequest,
    CreateWalletRequestFromJSON,
    CreateWalletRequestFromJSONTyped,
    CreateWalletRequestToJSON,
} from './CreateWalletRequest';
import {
    InternalUpdatableUserFields,
    InternalUpdatableUserFieldsFromJSON,
    InternalUpdatableUserFieldsFromJSONTyped,
    InternalUpdatableUserFieldsToJSON,
} from './InternalUpdatableUserFields';
import {
    InternalUserFieldsAllOf,
    InternalUserFieldsAllOfFromJSON,
    InternalUserFieldsAllOfFromJSONTyped,
    InternalUserFieldsAllOfToJSON,
} from './InternalUserFieldsAllOf';
import {
    MfaBackupCodeAcknowledgement,
    MfaBackupCodeAcknowledgementFromJSON,
    MfaBackupCodeAcknowledgementFromJSONTyped,
    MfaBackupCodeAcknowledgementToJSON,
} from './MfaBackupCodeAcknowledgement';

/**
 * 
 * @export
 * @interface InternalUserFields
 */
export interface InternalUserFields {
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    phoneNumber?: string;
    /**
     * 
     * @type {object}
     * @memberof InternalUserFields
     */
    metadata?: object;
    /**
     * 
     * @type {MfaBackupCodeAcknowledgement}
     * @memberof InternalUserFields
     */
    mfaBackupCodeAcknowledgement?: MfaBackupCodeAcknowledgement | null;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    tShirtSize?: string;
    /**
     * 
     * @type {string}
     * @memberof InternalUserFields
     */
    team?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserFields
     */
    policiesConsent?: boolean;
    /**
     * Standard ISO 3166-1 alpha-2 two-letter country code
     * @type {string}
     * @memberof InternalUserFields
     */
    country?: string | null;
    /**
     * Alphanumeric with slugs and underscores username
     * @type {string}
     * @memberof InternalUserFields
     */
    username?: string | null;
    /**
     * BTC wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    btcWallet?: string | null;
    /**
     * KDA wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    kdaWallet?: string | null;
    /**
     * LTC wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    ltcWallet?: string | null;
    /**
     * CKB wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    ckbWallet?: string | null;
    /**
     * KAS wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    kasWallet?: string | null;
    /**
     * DOGE wallet address
     * @type {string}
     * @memberof InternalUserFields
     */
    dogeWallet?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserFields
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserFields
     */
    discordNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InternalUserFields
     */
    newsletterNotification?: boolean;
    /**
     * When provided, the user email will be marked as verified in our system and the user will be allowed to sign in with that email. When social is enabled and the social provider has a verified email, we will automatically associate that social with the existing user.
     * @type {Date}
     * @memberof InternalUserFields
     */
    emailVerifiedAt?: Date;
    /**
     * If email is trusted and verified, please provide a emailVerifiedAt timestamp. This will ensure that a user signing in with this email can access the correctly-created user account.
     * @type {string}
     * @memberof InternalUserFields
     */
    email?: string;
    /**
     * 
     * @type {Array<CreateWalletRequest>}
     * @memberof InternalUserFields
     */
    wallets?: Array<CreateWalletRequest>;
    /**
     * 
     * @type {Array<CreateUserOauthRequest>}
     * @memberof InternalUserFields
     */
    oauthAccounts?: Array<CreateUserOauthRequest>;
}

export function InternalUserFieldsFromJSON(json: any): InternalUserFields {
    return InternalUserFieldsFromJSONTyped(json, false);
}

export function InternalUserFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): InternalUserFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mfaBackupCodeAcknowledgement': !exists(json, 'mfaBackupCodeAcknowledgement') ? undefined : MfaBackupCodeAcknowledgementFromJSON(json['mfaBackupCodeAcknowledgement']),
        'tShirtSize': !exists(json, 'tShirtSize') ? undefined : json['tShirtSize'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'policiesConsent': !exists(json, 'policiesConsent') ? undefined : json['policiesConsent'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'btcWallet': !exists(json, 'btcWallet') ? undefined : json['btcWallet'],
        'kdaWallet': !exists(json, 'kdaWallet') ? undefined : json['kdaWallet'],
        'ltcWallet': !exists(json, 'ltcWallet') ? undefined : json['ltcWallet'],
        'ckbWallet': !exists(json, 'ckbWallet') ? undefined : json['ckbWallet'],
        'kasWallet': !exists(json, 'kasWallet') ? undefined : json['kasWallet'],
        'dogeWallet': !exists(json, 'dogeWallet') ? undefined : json['dogeWallet'],
        'emailNotification': !exists(json, 'emailNotification') ? undefined : json['emailNotification'],
        'discordNotification': !exists(json, 'discordNotification') ? undefined : json['discordNotification'],
        'newsletterNotification': !exists(json, 'newsletterNotification') ? undefined : json['newsletterNotification'],
        'emailVerifiedAt': !exists(json, 'emailVerifiedAt') ? undefined : (new Date(json['emailVerifiedAt'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'wallets': !exists(json, 'wallets') ? undefined : ((json['wallets'] as Array<any>).map(CreateWalletRequestFromJSON)),
        'oauthAccounts': !exists(json, 'oauthAccounts') ? undefined : ((json['oauthAccounts'] as Array<any>).map(CreateUserOauthRequestFromJSON)),
    };
}

export function InternalUserFieldsToJSON(value?: InternalUserFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'alias': value.alias,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'jobTitle': value.jobTitle,
        'phoneNumber': value.phoneNumber,
        'metadata': value.metadata,
        'mfaBackupCodeAcknowledgement': MfaBackupCodeAcknowledgementToJSON(value.mfaBackupCodeAcknowledgement),
        'tShirtSize': value.tShirtSize,
        'team': value.team,
        'policiesConsent': value.policiesConsent,
        'country': value.country,
        'username': value.username,
        'btcWallet': value.btcWallet,
        'kdaWallet': value.kdaWallet,
        'ltcWallet': value.ltcWallet,
        'ckbWallet': value.ckbWallet,
        'kasWallet': value.kasWallet,
        'dogeWallet': value.dogeWallet,
        'emailNotification': value.emailNotification,
        'discordNotification': value.discordNotification,
        'newsletterNotification': value.newsletterNotification,
        'emailVerifiedAt': value.emailVerifiedAt === undefined ? undefined : (value.emailVerifiedAt.toISOString()),
        'email': value.email,
        'wallets': value.wallets === undefined ? undefined : ((value.wallets as Array<any>).map(CreateWalletRequestToJSON)),
        'oauthAccounts': value.oauthAccounts === undefined ? undefined : ((value.oauthAccounts as Array<any>).map(CreateUserOauthRequestToJSON)),
    };
}

