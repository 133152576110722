/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldType,
    CustomFieldTypeFromJSON,
    CustomFieldTypeFromJSONTyped,
    CustomFieldTypeToJSON,
} from './CustomFieldType';
import {
    CustomFieldValidationRules,
    CustomFieldValidationRulesFromJSON,
    CustomFieldValidationRulesFromJSONTyped,
    CustomFieldValidationRulesToJSON,
} from './CustomFieldValidationRules';

/**
 * 
 * @export
 * @interface CustomField
 */
export interface CustomField {
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof CustomField
     */
    name: string;
    /**
     * A flag indicating whether the custom field is active.
     * @type {boolean}
     * @memberof CustomField
     */
    enabled: boolean;
    /**
     * A flag indicating whether the custom field is required.
     * @type {boolean}
     * @memberof CustomField
     */
    required: boolean;
    /**
     * 
     * @type {CustomFieldType}
     * @memberof CustomField
     */
    fieldType: CustomFieldType;
    /**
     * 
     * @type {CustomFieldValidationRules}
     * @memberof CustomField
     */
    validationRules?: CustomFieldValidationRules;
    /**
     * 
     * @type {number}
     * @memberof CustomField
     */
    position: number;
}

export function CustomFieldFromJSON(json: any): CustomField {
    return CustomFieldFromJSONTyped(json, false);
}

export function CustomFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'key': json['key'],
        'name': json['name'],
        'enabled': json['enabled'],
        'required': json['required'],
        'fieldType': CustomFieldTypeFromJSON(json['fieldType']),
        'validationRules': !exists(json, 'validationRules') ? undefined : CustomFieldValidationRulesFromJSON(json['validationRules']),
        'position': json['position'],
    };
}

export function CustomFieldToJSON(value?: CustomField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
        'name': value.name,
        'enabled': value.enabled,
        'required': value.required,
        'fieldType': CustomFieldTypeToJSON(value.fieldType),
        'validationRules': CustomFieldValidationRulesToJSON(value.validationRules),
        'position': value.position,
    };
}

