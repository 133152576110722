import { ChangeEvent, useState } from 'react';

import isEqual from 'react-fast-compare';
import { t } from 'i18next';
// eslint-disable-next-line import/no-namespace
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { Organization, Project } from '@dynamic-labs/sdk-api';

import { logger } from '../../../services/logger';
import { EllipsisIcon } from '../../../../icons';
import Input from '../../../components/Input';
import { organizationsApi } from '../../../services/api';
import Button from '../../../components/Button';
import { UpdateProjectModal } from '../UpdateProjectModal';
import { DeleteProjectModal } from '../DeleteProjectModal/DeleteProjectModal';

import styles from './OrganizationContent.module.css';

export type OrganizationContentProps = {
  onProjectDelete(): void;
  onProjectUpdate(): void;
  onUpdateSuccess(): void;
  organization: Organization;
  projects: Project[];
};

export const OrganizationContent = ({
  organization: { id, ...initialOrganization },
  onUpdateSuccess,
  onProjectUpdate,
  onProjectDelete,
  projects,
}: OrganizationContentProps) => {
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [selectedProject, setSelectedProject] = useState<Project | undefined>();
  const [modal, setModal] = useState<'update' | 'delete' | undefined>();

  const [organization, setOrganization] = useState(initialOrganization);
  const { name } = organization;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name: inputName, value } = e.target;

    setOrganization((prevOrg) => ({
      ...prevOrg,
      [inputName]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await organizationsApi.updateOrganizationById({
        organizationFields: {
          name,
        },
        organizationId: id,
      });
      onUpdateSuccess();
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
      setIsEditing(false);
    }
  };

  const touched = !isEqual(initialOrganization, organization);
  const isValid = name.length > 0;

  return (
    <>
      <div className='flex justify-between items-center border rounded-2xl px-4 py-6'>
        <div className={styles.fields}>
          <Input
            className={styles.field}
            type='text'
            name='name'
            value={name}
            onChange={handleChange}
            label={t<string>('organization.nameLabel')}
            disabled={!isEditing || loading}
          />
        </div>
        <div className={styles.controls}>
          {isEditing ? (
            <>
              <Button
                variant='secondary'
                type='button'
                disabled={loading}
                onClick={() => {
                  setOrganization(initialOrganization);
                  setIsEditing(false);
                }}
              >
                {t<string>('actions.cancel')}
              </Button>
              <Button
                type='submit'
                disabled={!touched || !isValid}
                onClick={handleSubmit}
                loading={loading}
              >
                {t<string>('actions.save')}
              </Button>
            </>
          ) : (
            <Button
              className={styles.button}
              type='button'
              variant='secondary'
              onClick={() => setIsEditing(true)}
            >
              {t<string>('actions.edit')}
            </Button>
          )}
        </div>
      </div>
      <div className='pt-3 pb-2 text-gray-2 uppercase text-xs font-medium'>
        {t<string>('admin.projects')}
      </div>
      {projects.map((project) => (
        <div
          key={project.id}
          className='flex justify-between items-center border rounded-2xl px-4 py-5 mb-2'
        >
          <div className='text-sm font-bold text-black-2'>{project.name}</div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger className='py-3 px-2 hover:bg-cloud-1 rounded-md'>
              <EllipsisIcon />
            </DropdownMenu.Trigger>
            <DropdownMenu.Content className={styles.dropdownContent}>
              <DropdownMenu.Item
                className={styles.dropdownItem}
                onClick={() => {
                  setSelectedProject(project);
                  setModal('update');
                }}
              >
                {t<string>('actions.edit')}
              </DropdownMenu.Item>
              {projects.length > 1 && (
                <DropdownMenu.Item
                  className={`${styles.dropdownItem} text-red-1`}
                  onClick={() => {
                    setSelectedProject(project);
                    setModal('delete');
                  }}
                >
                  {t<string>('actions.delete')}
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>
      ))}
      {modal === 'update' && selectedProject && (
        <UpdateProjectModal
          onClose={() => setModal(undefined)}
          onProjectUpdate={onProjectUpdate}
          projectId={selectedProject.id}
          projectName={selectedProject.name}
          projects={projects}
        />
      )}
      {modal === 'delete' && selectedProject && (
        <DeleteProjectModal
          onClose={() => {
            setModal(undefined);
            onProjectDelete();
          }}
          projectId={selectedProject.id}
        />
      )}
    </>
  );
};
