/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignInProviderEnum,
    SignInProviderEnumFromJSON,
    SignInProviderEnumFromJSONTyped,
    SignInProviderEnumToJSON,
} from './SignInProviderEnum';
import {
    SocialSignInProvider,
    SocialSignInProviderFromJSON,
    SocialSignInProviderFromJSONTyped,
    SocialSignInProviderToJSON,
} from './SocialSignInProvider';

/**
 * 
 * @export
 * @interface ProjectSettingsSdkSocialSignIn
 */
export interface ProjectSettingsSdkSocialSignIn {
    /**
     * 
     * @type {SignInProviderEnum}
     * @memberof ProjectSettingsSdkSocialSignIn
     */
    signInProvider?: SignInProviderEnum;
    /**
     * 
     * @type {Array<SocialSignInProvider>}
     * @memberof ProjectSettingsSdkSocialSignIn
     */
    providers?: Array<SocialSignInProvider>;
}

export function ProjectSettingsSdkSocialSignInFromJSON(json: any): ProjectSettingsSdkSocialSignIn {
    return ProjectSettingsSdkSocialSignInFromJSONTyped(json, false);
}

export function ProjectSettingsSdkSocialSignInFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkSocialSignIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signInProvider': !exists(json, 'signInProvider') ? undefined : SignInProviderEnumFromJSON(json['signInProvider']),
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(SocialSignInProviderFromJSON)),
    };
}

export function ProjectSettingsSdkSocialSignInToJSON(value?: ProjectSettingsSdkSocialSignIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signInProvider': SignInProviderEnumToJSON(value.signInProvider),
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(SocialSignInProviderToJSON)),
    };
}

