import { useEffect, useState } from 'react';

import { DateSelection, ValidDateSelection } from '../../Analytics.types';

export const useValidDateSelection = (
  dateSelection: DateSelection,
  initialDateSelection: ValidDateSelection,
): ValidDateSelection => {
  const [validDateSelection, setValidDateSelection] =
    useState<ValidDateSelection>(initialDateSelection);

  useEffect(() => {
    if (!dateSelection.end || !dateSelection.start) return;

    setValidDateSelection(dateSelection as ValidDateSelection);
  }, [dateSelection, setValidDateSelection]);

  return validDateSelection;
};
