import { Chip } from '../Chip';

import styles from './Divider.module.css';

type LabelPosition = 'start' | 'center' | 'end';

type Props = {
  className?: string;
  label: string;
  position?: LabelPosition;
};

export const Divider = ({
  label,
  position = 'start',
  className = '',
}: Props) => {
  const Label = (
    <Chip label={label} color='white' className={className} small />
  );

  return (
    <div
      className={`${styles.divider} ${className}`}
      data-testid='divider_container'
    >
      {position === 'start' && Label}
      <div className={`${styles.divider__dash}`} />
      {position === 'center' && Label}
      <div className={`${styles.divider__dash}`} />
      {position === 'end' && Label}
    </div>
  );
};
