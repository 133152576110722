import { RumActionEvent, RumEvent } from '@datadog/browser-rum';

import { logger } from '../app/services/logger';

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function isRumActionEvent(event: RumEvent): event is RumActionEvent {
  return event.type === 'action';
}

export const rumBeforeSend = (event: RumEvent) => {
  try {
    if (isRumActionEvent(event)) {
      // redact dynamic api tokens, which usually start with 'dyn_'
      const name = event.action.target?.name;
      if (name && name.includes('dyn_')) {
        event.action.target.name = 'REDACTED';
      }
    }
  } catch (error) {
    logger.error('BeforeSend datadog error', { error });
  }
  return true;
};
