import { ReactNode } from 'react';

import i18n from 'i18next';

import {
  BookOpen,
  BoltIcon,
  LockIcon,
  AccessListIcon,
  CustomerInformationIcon,
  SettingsIcon,
} from '../../icons';
import { ROUTES } from '../components/Navigation/data';
import '../../i18n';
import { ModalHashLocations } from '../routes/Configurations/utils';

import { FullScope } from './subscription';

export type SimpleContent = {
  content: ReactNode;
  type: 'simple';
};

export type ExtendedContent = {
  firstParagraph: string;
  noData: string;
  secondParagraph: ReactNode;
  type: 'extended';
};

export type VisitedContent = {
  firstSubtitle: string;
  secondSubtitle?: string;
  type: 'visited';
};

export type IntegrationCard = {
  description?: string;
  icon: ReactNode;
  id: string;
  isAdvanced?: boolean;
  scope?: string;
  title: string;
  url: string;
};

export const integrationCards: IntegrationCard[] = [
  {
    description: i18n.t('overview.integrations.docs.description'),
    icon: <BookOpen />,
    id: 'docs',
    title: i18n.t('overview.integrations.docs.title'),
    url: 'https://docs.dynamic.xyz',
  },
  {
    description: i18n.t('overview.integrations.settings.description'),
    icon: <SettingsIcon />,
    id: 'settings',
    title: i18n.t('overview.integrations.settings.title'),
    url: ROUTES.settingsGeneral,
  },
  {
    description: i18n.t('overview.integrations.cors.description'),
    icon: <LockIcon />,
    id: 'cors',
    scope: FullScope.SettingsSecurity,
    title: i18n.t('overview.integrations.cors.title'),
    url: ROUTES.security,
  },
  {
    description: i18n.t('overview.integrations.design.description'),
    icon: <BoltIcon />,
    id: 'design',
    scope: FullScope.Design,
    title: i18n.t('overview.integrations.design.title'),
    url: ROUTES.design,
  },
  {
    description: i18n.t('overview.integrations.kyc.description'),
    icon: <CustomerInformationIcon />,
    id: 'kyc',
    scope: FullScope.InfoCapture,
    title: i18n.t('overview.integrations.kyc.title'),
    url: `${ROUTES.configurations}#${ModalHashLocations.InformationCapture}`,
  },
  {
    description: i18n.t('overview.integrations.access_list.description'),
    icon: <AccessListIcon />,
    id: 'access_list',
    scope: FullScope.AccessList,
    title: i18n.t('overview.integrations.access_list.title'),
    url: `${ROUTES.configurations}#${ModalHashLocations.AccessControl}`,
  },
];
