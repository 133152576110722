import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';

import { Field, FieldArray, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  Gate,
  AccessOutcomeEnum,
  GateRuleType,
  TokenAddress,
  UnprocessableEntity,
} from '@dynamic-labs/sdk-api';

import { AccessOutcomeSelect } from '../../../AccessControlModal/AccessOutcomeSelect';
import { useGatesContext } from '../../../../../../context/GatesContext';
import { PlusIcon } from '../../../../../../../icons';
import Button from '../../../../../../components/Button';
import { Divider } from '../../../../../../components/Divider';
import { ErrorInfo } from '../../../../../../components/ErrorInfo';
import { Icon } from '../../../../../../components/Icon';
import Input from '../../../../../../components/Input';
import { Typography } from '../../../../../../components/Typography';

import { GateRuleView } from './GateRuleView';
import { FormErrors } from './FormErrors';
import { getValidationSchema } from './helpers';
import { GateFormValues } from './types';

interface Props {
  formikFormRef: MutableRefObject<FormikProps<GateFormValues> | null>;
  gate?: Gate;
  loading: boolean;
  onCreateSuccess?: () => void;
  onUpdateSuccess?: () => void;
  setLoading: Dispatch<SetStateAction<boolean>>;
  shouldValidateForm?: boolean;
}

export const GateForm = ({
  gate,
  formikFormRef,
  loading,
  shouldValidateForm,
  setLoading,
  onCreateSuccess,
  onUpdateSuccess,
}: Props) => {
  const { t } = useTranslation();
  const { gates, createGate, updateGate } = useGatesContext();

  const [errorMessage, setErrorMessage] = useState<string>();

  const onSubmit = async (values: GateFormValues) => {
    setLoading(true);

    const updatedGate = {
      id: gate?.id ?? '',
      name: values.gateName?.trim(),
      outcome: values.outcome,
      rules: values.rules.map((rule) => ({
        address: {
          contractAddress: rule.address?.contractAddress?.trim(),
          networkId: rule.address?.networkId,
        } as TokenAddress,
        filter: {
          amount: rule.filter?.amount,
          tokenId: rule.filter?.tokenId?.trim() || undefined,
        },
        type: rule.type as GateRuleType,
      })),
      scope: values.scopeName?.trim(),
    };

    if (gate?.id) {
      updateGate(updatedGate, {
        onError: async (e: any) => {
          if (e.status === 422) {
            const error: UnprocessableEntity = await e.json();
            setErrorMessage(error.error);
          } else {
            setErrorMessage(
              t(
                'integrations.onboarding_and_kyc.access_control.errors.unexpected_error',
              ),
            );
          }
        },
        onSettled: () => setLoading(false),
        onSuccess: onUpdateSuccess,
      });
    } else {
      createGate(updatedGate, {
        onError: async (e: any) => {
          if (e.status === 422) {
            const error: UnprocessableEntity = await e.json();
            setErrorMessage(error.error);
          } else {
            setErrorMessage(
              t(
                'integrations.onboarding_and_kyc.access_control.errors.unexpected_error',
              ),
            );
          }
        },
        onSettled: () => setLoading(false),
        onSuccess: onCreateSuccess,
      });
    }
  };

  return (
    <Formik
      innerRef={formikFormRef}
      onSubmit={onSubmit}
      validationSchema={getValidationSchema(gate, gates)}
      validateOnChange={shouldValidateForm}
      validateOnBlur={shouldValidateForm}
      initialValues={{
        gateName: gate?.name || '',
        outcome: gate?.outcome || ('' as AccessOutcomeEnum),
        rules: gate?.rules || [],
        scopeName: gate?.scope || '',
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form>
          {errorMessage && (
            <ErrorInfo className='mb-5'>
              <Typography variant='paragraph-1'>{errorMessage}</Typography>
            </ErrorInfo>
          )}
          <FormErrors errors={errors} touched={touched} />

          <Field
            as={Input}
            name='gateName'
            id='gateName'
            label={t(
              'integrations.onboarding_and_kyc.access_control.gating.forms.gateNameLabel',
            )}
            error={!!errors.gateName && !!touched.gateName}
          />

          <AccessOutcomeSelect
            selectedOutcome={values.outcome}
            onSelect={(outcome?: AccessOutcomeEnum) => {
              setFieldValue('outcome', outcome);
              setFieldValue('scopeName', '');
            }}
            className='mb-2'
          />

          {values.outcome === AccessOutcomeEnum.Scope && (
            <Field
              as={Input}
              name='scopeName'
              id='scopeName'
              label={t(
                'integrations.onboarding_and_kyc.access_control.scopeNameLabel',
              )}
              error={!!errors.scopeName && !!touched.scopeName}
            />
          )}

          <FieldArray
            name='rules'
            render={(arrayHelpers) => (
              <>
                {values.rules.map((rule, index) => (
                  <div key={`gate-${gate?.id}-rule-${index + 1}`}>
                    {index > 0 && (
                      <Divider
                        label={t(
                          'integrations.onboarding_and_kyc.access_control.logicalOperator.and',
                        )}
                      />
                    )}
                    <GateRuleView
                      index={index}
                      type={rule.type}
                      networkId={rule.address?.networkId}
                      tokenAdrress={rule.address?.contractAddress}
                      onSelectType={(type?: GateRuleType) =>
                        arrayHelpers.replace(index, {
                          ...rule,
                          address: { ...rule.address, contractAddress: '' },
                          type,
                        })
                      }
                      onSelectNetwork={(networkId?: number) =>
                        arrayHelpers.replace(index, {
                          ...rule,
                          address: { ...rule.address, networkId },
                        })
                      }
                      onSelectToken={(address?: string) =>
                        arrayHelpers.replace(index, {
                          ...rule,
                          address: {
                            ...rule.address,
                            contractAddress: address,
                          },
                        })
                      }
                      onDelete={() => arrayHelpers.remove(index)}
                      formErrors={errors}
                      formTouched={touched}
                      showDelete={values.rules.length > 1}
                    />
                  </div>
                ))}
                <Button
                  className='mt-4'
                  variant='secondary'
                  type='button'
                  disabled={loading}
                  leading={<Icon size='medium' icon={<PlusIcon />} />}
                  onClick={() =>
                    arrayHelpers.push({
                      address: { contractAddress: '', networkId: undefined },
                      filter: { amount: 1 },
                      type: '',
                    })
                  }
                >
                  {t(
                    'integrations.onboarding_and_kyc.access_control.gating.forms.add_criteria',
                  )}
                </Button>
              </>
            )}
          />
        </Form>
      )}
    </Formik>
  );
};
