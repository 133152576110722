/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Visitor,
    VisitorFromJSON,
    VisitorFromJSONTyped,
    VisitorToJSON,
} from './Visitor';

/**
 * 
 * @export
 * @interface VisitorsResponse
 */
export interface VisitorsResponse {
    /**
     * 
     * @type {number}
     * @memberof VisitorsResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<Visitor>}
     * @memberof VisitorsResponse
     */
    visitors?: Array<Visitor>;
}

export function VisitorsResponseFromJSON(json: any): VisitorsResponse {
    return VisitorsResponseFromJSONTyped(json, false);
}

export function VisitorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'visitors': !exists(json, 'visitors') ? undefined : ((json['visitors'] as Array<any>).map(VisitorFromJSON)),
    };
}

export function VisitorsResponseToJSON(value?: VisitorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'visitors': value.visitors === undefined ? undefined : ((value.visitors as Array<any>).map(VisitorToJSON)),
    };
}

