/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldValidValue,
    CustomFieldValidValueFromJSON,
    CustomFieldValidValueFromJSONTyped,
    CustomFieldValidValueToJSON,
} from './CustomFieldValidValue';

/**
 * Optional validation rules for the custom field
 * @export
 * @interface CustomFieldValidationRules
 */
export interface CustomFieldValidationRules {
    /**
     * If this field must be unique for every user in the environment
     * @type {boolean}
     * @memberof CustomFieldValidationRules
     */
    unique?: boolean;
    /**
     * The regex pattern that the text field must match
     * @type {string}
     * @memberof CustomFieldValidationRules
     */
    regex?: string;
    /**
     * The dropdown options for the select field
     * @type {Array<CustomFieldValidValue>}
     * @memberof CustomFieldValidationRules
     */
    validOptions?: Array<CustomFieldValidValue>;
    /**
     * The text that will be displayed for the checkbox field
     * @type {string}
     * @memberof CustomFieldValidationRules
     */
    checkboxText?: string;
}

export function CustomFieldValidationRulesFromJSON(json: any): CustomFieldValidationRules {
    return CustomFieldValidationRulesFromJSONTyped(json, false);
}

export function CustomFieldValidationRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValidationRules {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'unique': !exists(json, 'unique') ? undefined : json['unique'],
        'regex': !exists(json, 'regex') ? undefined : json['regex'],
        'validOptions': !exists(json, 'validOptions') ? undefined : ((json['validOptions'] as Array<any>).map(CustomFieldValidValueFromJSON)),
        'checkboxText': !exists(json, 'checkboxText') ? undefined : json['checkboxText'],
    };
}

export function CustomFieldValidationRulesToJSON(value?: CustomFieldValidationRules | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'unique': value.unique,
        'regex': value.regex,
        'validOptions': value.validOptions === undefined ? undefined : ((value.validOptions as Array<any>).map(CustomFieldValidValueToJSON)),
        'checkboxText': value.checkboxText,
    };
}

