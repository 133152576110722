/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Project,
    ProjectFromJSON,
    ProjectFromJSONTyped,
    ProjectToJSON,
} from './Project';

/**
 * 
 * @export
 * @interface ProjectsResponse
 */
export interface ProjectsResponse {
    /**
     * 
     * @type {Array<Project>}
     * @memberof ProjectsResponse
     */
    projects?: Array<Project>;
}

export function ProjectsResponseFromJSON(json: any): ProjectsResponse {
    return ProjectsResponseFromJSONTyped(json, false);
}

export function ProjectsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'projects': !exists(json, 'projects') ? undefined : ((json['projects'] as Array<any>).map(ProjectFromJSON)),
    };
}

export function ProjectsResponseToJSON(value?: ProjectsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'projects': value.projects === undefined ? undefined : ((value.projects as Array<any>).map(ProjectToJSON)),
    };
}

