import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SingleToggleCard,
  Typography,
  PhoneIntegrationIcon,
} from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSetKycFieldProp } from '../../../../../../../app/hooks/useSetKycFieldProp';
import { IntegrationCategory } from '../../../../../../../app/types';
import { UseForSignUpToggle } from '../../shared/UseForSignUpToggle';
import { KycPropToggle } from '../../shared/KycPropToggle';
import { getToggleBanners } from '../../shared/getToggleBanners';
import { AdminIcon } from '../../../../../../../icons';
import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import {
  dynamicSupportedCountries,
  supportedCountries,
} from '../../../../../../../app/routes/Configurations/Providers/components/SmsProviderSection/utils/countries';

import { PhoneNumberCredentials } from './PhoneNumberCredentials';
import styles from './PhoneNumberMethod.module.scss';
import { CountriesDropdown } from './PhoneNumberCredentials/CountriesDropdown';

type PhoneNumberMethodProps = {
  disabled: boolean;
};

export const PhoneNumberMethod: FC<PhoneNumberMethodProps> = ({ disabled }) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.LogInMethodsPhoneNumberMethod,
  });

  const { t } = useTranslation();

  const { settings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { updateActiveEnvKycFieldProp } = useSetKycFieldProp(
    IntegrationCategory.OnboardingAndKYC,
  );
  const { getProviderValue, toggleProvider, onChangeProvider } =
    useProvidersContext();

  const phoneKycField = settings[activeEnvironmentType].kyc.find(
    (field) => field.name === 'phoneNumber',
  );
  const savedPhoneKycField = initialSettings[activeEnvironmentType].kyc.find(
    (field) => field.name === 'phoneNumber',
  );

  const isPhoneKycEnabledSaved = Boolean(
    phoneKycField?.enabled === savedPhoneKycField?.enabled,
  );

  const isPhoneProviderEnabled = Boolean(
    getProviderValue(ProviderEnum.Sms, 'enabledAt'),
  );

  const fieldEnabled = Boolean(phoneKycField?.enabled) && !disabled;

  const handleToggleField = (e: ChangeEvent<HTMLInputElement>) => {
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'phoneNumber',
      type: 'enabled',
    });
    updateActiveEnvKycFieldProp({
      isSelected: false,
      name: 'phoneNumber',
      type: 'required',
    });
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'phoneNumber',
      type: 'unique',
    });
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'phoneNumber',
      type: 'verify',
    });

    toggleProvider(ProviderEnum.Sms, e.target.checked);

    if (e.target.checked) {
      onChangeProvider(
        ProviderEnum.Sms,
        'enabledCountries',
        dynamicSupportedCountries.map((country) => ({
          isoCountryCode: country,
          phoneCountryCode: supportedCountries[country].code,
        })),
      );
    }
  };

  return (
    <>
      <SingleToggleCard
        accordionKey='phone-method'
        title={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.title',
        )}
        description={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.description',
        )}
        allowExpand={false}
        onCardClick={fieldEnabled ? () => handleShowModal(true) : undefined}
        Tags={getToggleBanners({
          enabled: fieldEnabled,
          required: Boolean(phoneKycField?.required),
          unique: Boolean(phoneKycField?.unique),
          useForLogIn: isPhoneProviderEnabled,
          verify: Boolean(phoneKycField?.verify),
        })}
        inputProps={{
          checked: fieldEnabled,
          disabled,
          id: 'phoneNumber-method',
          isSaved: isPhoneKycEnabledSaved,
          onChange: handleToggleField,
          type: 'toggle',
        }}
        Icon={<PhoneIntegrationIcon />}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && fieldEnabled}
        title={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.title',
        )}
        Icon={<PhoneIntegrationIcon />}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.authentication')}
            </Typography>

            <UseForSignUpToggle kycField='phoneNumber' />
          </div>

          <div className={styles.section}>
            <PhoneNumberCredentials />
          </div>

          <div className={styles.section}>
            <CountriesDropdown />
          </div>

          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.profile_information')}
            </Typography>

            <KycPropToggle field='required' name='phoneNumber' />
          </div>

          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.additional_configurations')}
            </Typography>

            <KycPropToggle
              field='verify'
              name='phoneNumber'
              disabled={isPhoneProviderEnabled}
            />
            <KycPropToggle
              field='unique'
              name='phoneNumber'
              disabled={isPhoneProviderEnabled}
            />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
