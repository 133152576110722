/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    CreateProjectResponse,
    CreateProjectResponseFromJSON,
    CreateProjectResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MethodNotAllowed,
    MethodNotAllowedFromJSON,
    MethodNotAllowedToJSON,
    Project,
    ProjectFromJSON,
    ProjectToJSON,
    ProjectRequest,
    ProjectRequestFromJSON,
    ProjectRequestToJSON,
    ProjectsResponse,
    ProjectsResponseFromJSON,
    ProjectsResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
    UpdateProjectRequest,
    UpdateProjectRequestFromJSON,
    UpdateProjectRequestToJSON,
    UpdateProjectResponse,
    UpdateProjectResponseFromJSON,
    UpdateProjectResponseToJSON,
} from '../models';

export interface CreateProjectRequest {
    organizationId: string;
    projectRequest: ProjectRequest;
}

export interface DeleteProjectByIdRequest {
    projectId: string;
}

export interface GetProjectByIdRequest {
    projectId: string;
}

export interface GetProjectsRequest {
    organizationId: string;
}

export interface UpdateProjectOperationRequest {
    projectId: string;
    updateProjectRequest: UpdateProjectRequest;
}

/**
 * 
 */
export class ProjectsApi extends runtime.BaseAPI {

    /**
     * Creates a new project
     */
    async createProjectRaw(requestParameters: CreateProjectRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateProjectResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createProject.');
        }

        if (requestParameters.projectRequest === null || requestParameters.projectRequest === undefined) {
            throw new runtime.RequiredError('projectRequest','Required parameter requestParameters.projectRequest was null or undefined when calling createProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/projects`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProjectRequestToJSON(requestParameters.projectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateProjectResponseFromJSON(jsonValue));
    }

    /**
     * Creates a new project
     */
    async createProject(requestParameters: CreateProjectRequest, initOverrides?: RequestInit): Promise<CreateProjectResponse> {
        const response = await this.createProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a project by ID
     */
    async deleteProjectByIdRaw(requestParameters: DeleteProjectByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling deleteProjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a project by ID
     */
    async deleteProjectById(requestParameters: DeleteProjectByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteProjectByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Find an project by ID
     */
    async getProjectByIdRaw(requestParameters: GetProjectByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Project>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling getProjectById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectFromJSON(jsonValue));
    }

    /**
     * Find an project by ID
     */
    async getProjectById(requestParameters: GetProjectByIdRequest, initOverrides?: RequestInit): Promise<Project> {
        const response = await this.getProjectByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all the active projects the belong to the organization
     */
    async getProjectsRaw(requestParameters: GetProjectsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProjectsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getProjects.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/projects`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProjectsResponseFromJSON(jsonValue));
    }

    /**
     * Fetches all the active projects the belong to the organization
     */
    async getProjects(requestParameters: GetProjectsRequest, initOverrides?: RequestInit): Promise<ProjectsResponse> {
        const response = await this.getProjectsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a project
     */
    async updateProjectRaw(requestParameters: UpdateProjectOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UpdateProjectResponse>> {
        if (requestParameters.projectId === null || requestParameters.projectId === undefined) {
            throw new runtime.RequiredError('projectId','Required parameter requestParameters.projectId was null or undefined when calling updateProject.');
        }

        if (requestParameters.updateProjectRequest === null || requestParameters.updateProjectRequest === undefined) {
            throw new runtime.RequiredError('updateProjectRequest','Required parameter requestParameters.updateProjectRequest was null or undefined when calling updateProject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/projects/{projectId}`.replace(`{${"projectId"}}`, encodeURIComponent(String(requestParameters.projectId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateProjectRequestToJSON(requestParameters.updateProjectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateProjectResponseFromJSON(jsonValue));
    }

    /**
     * Update a project
     */
    async updateProject(requestParameters: UpdateProjectOperationRequest, initOverrides?: RequestInit): Promise<UpdateProjectResponse> {
        const response = await this.updateProjectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
