/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserIdentifierTypeEnum {
    Email = 'email',
    Id = 'id',
    ExternalUserId = 'externalUserId',
    PhoneNumber = 'phoneNumber',
    SocialUsername = 'socialUsername'
}

export function UserIdentifierTypeEnumFromJSON(json: any): UserIdentifierTypeEnum {
    return UserIdentifierTypeEnumFromJSONTyped(json, false);
}

export function UserIdentifierTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIdentifierTypeEnum {
    return json as UserIdentifierTypeEnum;
}

export function UserIdentifierTypeEnumToJSON(value?: UserIdentifierTypeEnum | null): any {
    return value as any;
}

