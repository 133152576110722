import { TFunction } from 'react-i18next';

import { WalletName } from '../../../../../components/WalletName';
import { WalletAddress } from '../../../../../components/WalletAddress';

import { WalletWithWalletProperties } from './types';

export const getAccountTabColumns = (
  t: TFunction<'translation', undefined>,
) => [
  {
    Header: '#',
    accessor: (_row: WalletWithWalletProperties, i: number) => i + 1,
    className: 'max-w-8 w-8',
    id: 'index',
  },
  {
    Header: t('users_management_table.wallets'),
    accessor: (wallet: WalletWithWalletProperties) => (
      <WalletName
        name={wallet.name}
        suffix={wallet.walletProperties?.version}
      />
    ),
    id: 'wallet',
  },
  {
    Header: t('users_management_table.addresses'),
    accessor: (wallet: WalletWithWalletProperties) => (
      <WalletAddress address={wallet.publicKey} chain={wallet.chain} />
    ),
    className: 'text-black-2 font-medium',
    id: 'address',
  },
];
