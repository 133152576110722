/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SdkUser,
    SdkUserFromJSON,
    SdkUserFromJSONTyped,
    SdkUserToJSON,
} from './SdkUser';

/**
 * 
 * @export
 * @interface VerifyResponse
 */
export interface VerifyResponse {
    /**
     * 
     * @type {string}
     * @memberof VerifyResponse
     */
    mfaToken?: string;
    /**
     * Encoded JWT token. This will only be returned when cookie-based authentication is disabled in favor of standard Auth header based authentication.
     * @type {string}
     * @memberof VerifyResponse
     */
    jwt?: string;
    /**
     * 
     * @type {SdkUser}
     * @memberof VerifyResponse
     */
    user: SdkUser;
    /**
     * Encoded JWT token. This will only be returned when cookie-based authentication is disabled in favor of standard Auth header based authentication.
     * @type {string}
     * @memberof VerifyResponse
     */
    minifiedJwt?: string;
    /**
     * Format is a unix-based timestamp. When set, this will be the expiration timestamp on the JWT sent using either the `jwt` field or a response httpOnly cookie set by the server.
     * @type {number}
     * @memberof VerifyResponse
     */
    expiresAt: number;
}

export function VerifyResponseFromJSON(json: any): VerifyResponse {
    return VerifyResponseFromJSONTyped(json, false);
}

export function VerifyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfaToken': !exists(json, 'mfaToken') ? undefined : json['mfaToken'],
        'jwt': !exists(json, 'jwt') ? undefined : json['jwt'],
        'user': SdkUserFromJSON(json['user']),
        'minifiedJwt': !exists(json, 'minifiedJwt') ? undefined : json['minifiedJwt'],
        'expiresAt': json['expiresAt'],
    };
}

export function VerifyResponseToJSON(value?: VerifyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfaToken': value.mfaToken,
        'jwt': value.jwt,
        'user': SdkUserToJSON(value.user),
        'minifiedJwt': value.minifiedJwt,
        'expiresAt': value.expiresAt,
    };
}

