import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { Allowlist } from '@dynamic-labs/sdk-api';

import { Toaster } from '../../../../../components/Toaster';
import { ControlFieldHeader } from '../../../../../components/ControlFieldHeader';
import { AccessListEntriesView } from '../AccessListEntriesView';
import { DeleteAllowListModal } from '../DeleteAllowListModal';
import { toggleAllowList } from '../helpers/helpers';
import { useAccessListsContext } from '../../../../../context/AccessListsContext';
import Button from '../../../../../components/Button';
import { AccessListForm, AllowListFormValues } from '../AccessListForm';
import { ExpandFieldAnimation } from '../../../../../animations/ExpandFieldAnimation';
import { AccessControlTypeChip } from '../../AccessControlModal/AccessControlTypeChip';
import { AccessControlScopeChip } from '../../AccessControlModal/AccessControlScopeChip';

import styles from './AccessListView.module.css';

export type AccessListViewProps = {
  allowList: Allowlist;
  className?: string;
};
export const AccessListView = ({
  allowList,
  className,
}: AccessListViewProps) => {
  const { fetchAccessLists, setAccessLists, activeList, setActiveList } =
    useAccessListsContext();

  const { t } = useTranslation();
  const [modal, setModal] = useState<'delete' | undefined>();
  const [expand, setExpand] = useState<boolean>(activeList === allowList.id);
  const [shouldValidateForm, setShouldValidateForm] = useState(false);

  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormikProps<AllowListFormValues>>(null);

  const handleToggle = async () => {
    if (allowList?.id) {
      const updatedList = await toggleAllowList(allowList);
      /* istanbul ignore next */
      if (updatedList) {
        /* istanbul ignore next */
        setAccessLists((prevAllowLists) => {
          const updatedAllowLists = [...prevAllowLists];
          const index = updatedAllowLists.findIndex(
            (l) => l.id === updatedList.id,
          );
          updatedAllowLists[index] = updatedList;
          return updatedAllowLists;
        });
      }
    }
  };

  const handleSubmit = async () => {
    /* istanbul ignore next */
    if (formRef.current) {
      setShouldValidateForm(true);
      const result = await formRef.current.validateForm(); // returns {} if no errors
      await formRef.current.submitForm();
      if (Object.keys(result).length === 0) onUpdateSuccess();
    }
  };
  const onUpdateSuccess = () => {
    setExpand(false);
    setShowToaster(true);
    setTimeout(() => setShowToaster(false), 3000);
  };

  const isActive = !!allowList?.enabledAt;
  const isPersisted = !!allowList?.id;
  const [showToaster, setShowToaster] = useState(false);
  const TypeAndOutcomeChips = (
    <div className={styles.header__chips}>
      <AccessControlTypeChip type='access_list' />
      <AccessControlScopeChip scope={allowList?.scope} />
    </div>
  );

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles['no-border']]: isPersisted,
        },
        className,
      )}
    >
      <ControlFieldHeader
        disabled={loading}
        isExpanded={expand}
        setIsExpanded={isPersisted ? setExpand : undefined}
        handleToggleChange={handleToggle}
        isInitialEnabled
        header={<div className={styles.header__title}>{allowList.name}</div>}
        className={styles.header__container}
        toggleId={`toggle-${allowList?.id}`}
        toggleValue={isActive}
        Trailing={TypeAndOutcomeChips}
        noPadding
      />

      <AnimatePresence>
        {expand && (
          <ExpandFieldAnimation>
            <div className={styles.inner_container}>
              <AccessListForm
                formikFormRef={formRef}
                allowlist={allowList}
                setLoading={setLoading}
                shouldValidateForm={shouldValidateForm}
              />

              {allowList.id && (
                <>
                  <div className={styles.divider} />
                  <AccessListEntriesView accessListId={allowList.id} />
                  <div className={styles.divider} />
                </>
              )}

              <div className={styles.footer__container}>
                {isPersisted && (
                  <Button
                    variant='secondary'
                    className={styles['button--delete']}
                    onClick={() => {
                      setModal('delete');
                    }}
                  >
                    {t(
                      'integrations.onboarding_and_kyc.access_control.access_list.delete_list_button',
                    )}
                  </Button>
                )}
                {isPersisted && (
                  <Button
                    variant='primary'
                    type='submit'
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    {t(
                      'integrations.onboarding_and_kyc.access_control.access_list.update_list',
                    )}
                  </Button>
                )}
                {!isPersisted && (
                  <>
                    <Button
                      variant='secondary'
                      className={styles['button--delete']}
                      onClick={() => {
                        setAccessLists((prevLists) =>
                          prevLists.filter((l) => l.id !== allowList.id),
                        );
                        setActiveList('');
                      }}
                    >
                      {t(
                        'integrations.onboarding_and_kyc.access_control.create_cancel_button',
                      )}
                    </Button>
                    <Button
                      variant='primary'
                      type='submit'
                      onClick={() => {
                        handleSubmit();
                      }}
                      loading={loading}
                    >
                      {t(
                        'integrations.onboarding_and_kyc.access_control.access_list.forms.save',
                      )}
                    </Button>
                  </>
                )}
              </div>
            </div>
          </ExpandFieldAnimation>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showToaster && (
          <Toaster
            message={t(
              'integrations.onboarding_and_kyc.access_control.gating.toaster',
            ).replace('{{gateName}}', allowList?.name || 'Allowlist')}
            variant='success'
            onExit={() => setShowToaster(false)}
            position='absolute'
          />
        )}
      </AnimatePresence>

      {modal === 'delete' && allowList && (
        <DeleteAllowListModal
          onClose={() => {
            setModal(undefined);
          }}
          onDelete={() => {
            setModal(undefined);
            fetchAccessLists();
          }}
          allowListId={allowList.id}
        />
      )}
    </div>
  );
};

export default AccessListView;
