import { cloneElement, FC } from 'react';

import classNames from 'classnames';

import styles from './Icon.module.scss';
import { IconColor, IconProps } from './Icon.types';

export const Icon: FC<IconProps> = ({
  icon,
  className,
  color,
  size,
  style,
}) => {
  const colorClassNameMap: Record<IconColor, string> = {
    'black-2': styles['icon-color-black-2'],
    'gray-1': styles['icon-color-gray-1'],
    'gray-2': styles['icon-color-gray-2'],
    'green-dark': styles['icon-color-green-dark'],
    'orange-dark': styles['icon-color-orange-dark'],
    'primary-1': styles['icon-color-primary-1'],
    'primary-2': styles['icon-color-primary-2'],
    'red-1': styles['icon-color-red-1'],
    white: styles['icon-color-white'],
  };

  const effectiveClassName = classNames(
    styles.icon,
    className,
    color && colorClassNameMap[color],
  );

  const sizeInRem = size ? size / 16 : undefined;

  const effectiveStyles = {
    ...style,
    height: sizeInRem && `${sizeInRem}rem`,
    minHeight: sizeInRem && `${sizeInRem}rem`,
    minWidth: sizeInRem && `${sizeInRem}rem`,
    width: sizeInRem && `${sizeInRem}rem`,
  };

  return cloneElement(icon, {
    className: effectiveClassName,
    style: effectiveStyles,
  });
};
