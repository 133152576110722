/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateMfaToken,
    CreateMfaTokenFromJSON,
    CreateMfaTokenFromJSONTyped,
    CreateMfaTokenToJSON,
} from './CreateMfaToken';

/**
 * 
 * @export
 * @interface MFAAuthTotpDevicePostRequest
 */
export interface MFAAuthTotpDevicePostRequest {
    /**
     * 
     * @type {string}
     * @memberof MFAAuthTotpDevicePostRequest
     */
    id?: string;
    /**
     * 
     * @type {CreateMfaToken}
     * @memberof MFAAuthTotpDevicePostRequest
     */
    createMfaToken?: CreateMfaToken;
    /**
     * The TOTP code generated by the user
     * @type {string}
     * @memberof MFAAuthTotpDevicePostRequest
     */
    code: string;
}

export function MFAAuthTotpDevicePostRequestFromJSON(json: any): MFAAuthTotpDevicePostRequest {
    return MFAAuthTotpDevicePostRequestFromJSONTyped(json, false);
}

export function MFAAuthTotpDevicePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAAuthTotpDevicePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createMfaToken': !exists(json, 'createMfaToken') ? undefined : CreateMfaTokenFromJSON(json['createMfaToken']),
        'code': json['code'],
    };
}

export function MFAAuthTotpDevicePostRequestToJSON(value?: MFAAuthTotpDevicePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createMfaToken': CreateMfaTokenToJSON(value.createMfaToken),
        'code': value.code,
    };
}

