import { NavLink } from 'react-router-dom';

import Button from '../../../../../components/Button';
import { Typography } from '../../../../../components/Typography';
import { UserManagement } from '../../../../../../icons';
import { VisitedContent } from '../../../../../data/overviewCards';
import { Icon } from '../../../../../components/Icon';

import styles from './MainVisits.module.css';

type Props = {
  buttonText: string;
  content: VisitedContent;
  values: {
    live: number;
    sandbox: number;
  };
};

const url = '/dashboard/users/authenticated';

export const MainVisits = ({
  content,
  buttonText,
  values,
}: Props): JSX.Element => (
  <div className={styles.container}>
    <div className={styles['container--half']}>
      <Typography variant='paragraph-1' className='!text-gray-1'>
        {content.firstSubtitle}
      </Typography>
      <div className={styles.wrapper}>
        <div className={styles.value}>{values.sandbox}</div>
      </div>
      <NavLink to={url}>
        <Button
          className={styles.button}
          variant='secondary'
          leading={<Icon size='medium' icon={<UserManagement />} />}
        >
          {buttonText}
        </Button>
      </NavLink>
    </div>
    <div className={styles.halfWidth}>
      <Typography variant='paragraph-1' className='!text-gray-1'>
        {content.secondSubtitle}
      </Typography>
      <div className={styles.wrapper}>
        <div className={styles.value}>{values.live}</div>
      </div>
    </div>
  </div>
);
