import { OriginResponse } from '@dynamic-labs/sdk-api';

export const addOrigin = (
  origin: OriginResponse | undefined,
  origins: OriginResponse[] | undefined,
) => {
  if (origins && origin) {
    return [...origins, origin];
  }
  if (origin) {
    return [origin];
  }
  return undefined;
};
