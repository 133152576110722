/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AssetDiff,
    AssetDiffFromJSON,
    AssetDiffFromJSONTyped,
    AssetDiffToJSON,
} from './AssetDiff';
import {
    PriceData,
    PriceDataFromJSON,
    PriceDataFromJSONTyped,
    PriceDataToJSON,
} from './PriceData';

/**
 * 
 * @export
 * @interface SimulateTransactionResponse
 */
export interface SimulateTransactionResponse {
    /**
     * 
     * @type {Array<AssetDiff>}
     * @memberof SimulateTransactionResponse
     */
    inAssets: Array<AssetDiff>;
    /**
     * 
     * @type {Array<AssetDiff>}
     * @memberof SimulateTransactionResponse
     */
    outAssets: Array<AssetDiff>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SimulateTransactionResponse
     */
    counterparties?: Array<string>;
    /**
     * 
     * @type {PriceData}
     * @memberof SimulateTransactionResponse
     */
    priceData: PriceData;
    /**
     * 
     * @type {boolean}
     * @memberof SimulateTransactionResponse
     */
    showTotalFiat: boolean;
}

export function SimulateTransactionResponseFromJSON(json: any): SimulateTransactionResponse {
    return SimulateTransactionResponseFromJSONTyped(json, false);
}

export function SimulateTransactionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateTransactionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'inAssets': ((json['inAssets'] as Array<any>).map(AssetDiffFromJSON)),
        'outAssets': ((json['outAssets'] as Array<any>).map(AssetDiffFromJSON)),
        'counterparties': !exists(json, 'counterparties') ? undefined : json['counterparties'],
        'priceData': PriceDataFromJSON(json['priceData']),
        'showTotalFiat': json['showTotalFiat'],
    };
}

export function SimulateTransactionResponseToJSON(value?: SimulateTransactionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'inAssets': ((value.inAssets as Array<any>).map(AssetDiffToJSON)),
        'outAssets': ((value.outAssets as Array<any>).map(AssetDiffToJSON)),
        'counterparties': value.counterparties,
        'priceData': PriceDataToJSON(value.priceData),
        'showTotalFiat': value.showTotalFiat,
    };
}

