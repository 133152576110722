import { createContext, Dispatch, SetStateAction } from 'react';

interface DropdownContextProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const DropdownContext = createContext<DropdownContextProps>({
  isOpen: false,
} as DropdownContextProps);
