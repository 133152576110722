/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnvironmentVisitorsResponseUsers,
    EnvironmentVisitorsResponseUsersFromJSON,
    EnvironmentVisitorsResponseUsersFromJSONTyped,
    EnvironmentVisitorsResponseUsersToJSON,
} from './EnvironmentVisitorsResponseUsers';

/**
 * 
 * @export
 * @interface EnvironmentVisitorsResponse
 */
export interface EnvironmentVisitorsResponse {
    /**
     * 
     * @type {EnvironmentVisitorsResponseUsers}
     * @memberof EnvironmentVisitorsResponse
     */
    users?: EnvironmentVisitorsResponseUsers;
    /**
     * 
     * @type {EnvironmentVisitorsResponseUsers}
     * @memberof EnvironmentVisitorsResponse
     */
    visitors?: EnvironmentVisitorsResponseUsers;
}

export function EnvironmentVisitorsResponseFromJSON(json: any): EnvironmentVisitorsResponse {
    return EnvironmentVisitorsResponseFromJSONTyped(json, false);
}

export function EnvironmentVisitorsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentVisitorsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : EnvironmentVisitorsResponseUsersFromJSON(json['users']),
        'visitors': !exists(json, 'visitors') ? undefined : EnvironmentVisitorsResponseUsersFromJSON(json['visitors']),
    };
}

export function EnvironmentVisitorsResponseToJSON(value?: EnvironmentVisitorsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': EnvironmentVisitorsResponseUsersToJSON(value.users),
        'visitors': EnvironmentVisitorsResponseUsersToJSON(value.visitors),
    };
}

