import { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { LockIcon } from '../../../icons';
import { Typography } from '../Typography';

import styles from './radio-button.module.css';

type RadioButtonProps = React.ComponentPropsWithoutRef<'input'> & {
  className?: string;
  description?: string | ReactNode;
  label?: string | ReactNode;
};

export const RadioButton: FC<RadioButtonProps> = ({
  className,
  disabled,
  id,
  label,
  onChange,
  name,
  value,
  defaultChecked,
  checked,
  description,
}) => (
  <label
    htmlFor={id}
    className={classNames(styles['input-wrapper'], className)}
  >
    <input
      type='radio'
      className={styles['input-hidden']}
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      checked={checked}
      defaultChecked={defaultChecked}
      disabled={disabled}
      data-testid={id}
    />
    <span className={styles['custom-input']}>
      {disabled ? (
        <span className={styles['custom-input__lock']}>
          <LockIcon />
        </span>
      ) : (
        <span className={styles['custom-input__dot']} />
      )}
    </span>
    <div className={styles.texts}>
      <Typography variant='paragraph-2' weight='medium'>
        {label}
      </Typography>
      <Typography variant='paragraph-1' color='gray-1' weight='medium'>
        {description}
      </Typography>
    </div>
  </label>
);
