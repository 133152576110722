/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    KycFieldType,
    KycFieldTypeFromJSON,
    KycFieldTypeFromJSONTyped,
    KycFieldTypeToJSON,
} from './KycFieldType';

/**
 * Contains the field key and the userId for whose information we need to keep
 * @export
 * @interface MergeUserConflictResolution
 */
export interface MergeUserConflictResolution {
    /**
     * 
     * @type {string}
     * @memberof MergeUserConflictResolution
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MergeUserConflictResolution
     */
    fieldKey: string;
    /**
     * 
     * @type {KycFieldType}
     * @memberof MergeUserConflictResolution
     */
    type: KycFieldType;
}

export function MergeUserConflictResolutionFromJSON(json: any): MergeUserConflictResolution {
    return MergeUserConflictResolutionFromJSONTyped(json, false);
}

export function MergeUserConflictResolutionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MergeUserConflictResolution {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'fieldKey': json['fieldKey'],
        'type': KycFieldTypeFromJSON(json['type']),
    };
}

export function MergeUserConflictResolutionToJSON(value?: MergeUserConflictResolution | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'fieldKey': value.fieldKey,
        'type': KycFieldTypeToJSON(value.type),
    };
}

