import { useMutation } from 'react-query';

import { ProjectSettingsKyc } from '@dynamic-labs/sdk-api';

import {
  EnvironmentSettings,
  useSettingsContext,
} from '../../context/SettingsContext';
import { useEnvironmentId } from '../../routes/Configurations/Providers/hooks';
import { environmentsApi } from '../../services/api';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';

export const useSaveOneKycField = () => {
  const { settings, initialSettings, setSettings, setInitialSettings } =
    useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const environmentId = useEnvironmentId();

  const prepareKycSettings = (
    environmentSettings: EnvironmentSettings,
    alreadySavedSetting: ProjectSettingsKyc,
  ) =>
    environmentSettings[activeEnvironmentType].kyc.map((setting) => {
      if (setting.name === alreadySavedSetting?.name) {
        return alreadySavedSetting;
      }
      return setting;
    });

  const prepareEnvironmentSettings = (
    environmentSettings: EnvironmentSettings,
    alreadySavedSetting: ProjectSettingsKyc,
  ) => ({
    ...environmentSettings,
    [activeEnvironmentType]: {
      ...environmentSettings[activeEnvironmentType],
      kyc: prepareKycSettings(environmentSettings, alreadySavedSetting),
    },
  });

  return useMutation(
    ['environments', environmentId, 'save-one-kyc'],
    (settingToSave: ProjectSettingsKyc) =>
      environmentsApi.updateProjectSettings({
        environmentId: environmentId || '',
        projectSettings: {
          ...initialSettings[activeEnvironmentType],
          kyc: prepareKycSettings(initialSettings, settingToSave),
        },
      }),
    {
      onSuccess: (data, settingToSaveFromMutation) => {
        if (!data?.kyc || !data.chains) return;

        // After saving the kyc setting we need to update the react states of settings.
        // This hook updates only one kyc setting, so we need remember not to
        // override all of other settings that customer can be changing.
        // We need to update the settings and initialSettings states separately because
        // they can be different from each other in some cases.
        setSettings(
          prepareEnvironmentSettings(settings, settingToSaveFromMutation),
        );
        setInitialSettings(
          prepareEnvironmentSettings(
            initialSettings,
            settingToSaveFromMutation,
          ),
        );
      },
    },
  );
};
