import { FC, PropsWithChildren, ReactNode } from 'react';

import classNames from 'classnames';

import { Alert, AlertProps } from '../Alert';
import { Typography } from '../Typography';
import { TextButton } from '../TextButton';

import styles from './Section.module.scss';

export type SectionProps = {
  alerts?: AlertProps[];
  alertsAlignment?: 'top' | 'bottom';
  className?: string;
  description?: string;
  help?: {
    text: string;
    url: string;
  };
  title: string | ReactNode;
};

export const Section: FC<PropsWithChildren<SectionProps>> = ({
  children,
  alerts = [],
  title,
  help,
  description,
  className,
  alertsAlignment = 'bottom',
}) => (
  <section className={classNames(styles.section, className)}>
    <div className={styles.left}>
      <div className={styles.textWrapper}>
        <Typography variant='subtitle' weight='medium' color='black-2'>
          {title}
        </Typography>

        <Typography variant='paragraph-2' weight='regular' color='gray-1'>
          {description}
        </Typography>

        {help && (
          <TextButton
            className={styles.helpButton}
            variant='paragraph-2'
            weight='bold'
            color='primary-1'
            as='a'
            href={help.url}
            text={help.text}
          />
        )}
      </div>

      <div
        className={classNames(styles.alerts, {
          [styles['alerts--top']]: alertsAlignment === 'top',
        })}
      >
        {alerts.map((alert) => (
          <Alert
            title={alert.title}
            description={alert.description}
            variant={alert.variant}
            IconOverride={alert.IconOverride}
            action={alert.action}
            show={alert.show}
            key={alert.title}
          />
        ))}
      </div>
    </div>
    <div className={styles.right}>{children}</div>
  </section>
);
