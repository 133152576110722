/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Do NOT use destructing here. Otherwise webpacker cannot set this value appropriately
 */
/* eslint-disable-next-line prefer-destructuring */
const DYNAMIC_ENV = process.env.DYNAMIC_ENV;

export const DEVELOPMENT_ENV = 'development';
export const PRODUCTION_ENV = 'production';
export const LOCAL_ENVS = [DEVELOPMENT_ENV, 'ci'] as const;
export const DEPLOYED_ENVS = [
  'preview',
  'staging',
  'preproduction',
  PRODUCTION_ENV,
] as const;

export const dynamicEnvs = [...LOCAL_ENVS, ...DEPLOYED_ENVS] as const;

export type DynamicLocalEnv = (typeof LOCAL_ENVS)[number];
export type DynamicDeployedEnv = (typeof DEPLOYED_ENVS)[number];
export type DynamicEnv = (typeof dynamicEnvs)[number];

export const isLocalEnv = (value = DYNAMIC_ENV): value is DynamicDeployedEnv =>
  LOCAL_ENVS.some((env) => env === value);

export const isDeployedEnv = (
  value = DYNAMIC_ENV,
): value is DynamicDeployedEnv => DEPLOYED_ENVS.some((env) => env === value);

export const isDynamicEnv = (value = DYNAMIC_ENV): value is DynamicEnv =>
  isLocalEnv(value) || isDeployedEnv(value);

const dynamicEnv = ((): DynamicEnv => {
  if (isDynamicEnv()) {
    return DYNAMIC_ENV as DynamicEnv;
  }

  throw new Error(
    `DYNAMIC_ENV must be one of: ${dynamicEnvs}, got ${DYNAMIC_ENV}`,
  );
})();

export type Environment = {
  companyLogoUrl: string;
  datadog?: Record<string, any>;
  datadogRum: boolean;
  development: boolean;
  env: DynamicEnv;
  isDeployed: boolean;
  preview: boolean;
  production: boolean;
  socialMediaLinkText: string;
  socialMediaUrl: string;
  staging: boolean;
};

const environment: Environment = {
  companyLogoUrl: '/assets/dynamic-logo-2.svg',
  datadog: {
    env: dynamicEnv,
  },
  datadogRum: isDeployedEnv() && dynamicEnv !== 'preview',
  development: dynamicEnv === 'development',
  env: dynamicEnv,
  isDeployed: isDeployedEnv(),
  preview: dynamicEnv === 'preview',
  production: dynamicEnv === 'production',
  socialMediaLinkText: 'Sign up to our waitlist',
  socialMediaUrl: 'https://form.typeform.com/to/hfE9f0T0',
  staging: dynamicEnv === 'staging',
};

export default environment;
