import { ChangeEvent, FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { AuthStorageEnum } from '@dynamic-labs/sdk-api';

import { COOKIES_URL } from '../../../../../app/utils/constants';
import {
  useModalHashLocation,
  ModalHashLocations,
} from '../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../components/SideDrawer';
import { AdminIcon } from '../../../../../icons';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';

import styles from './CookieRow.module.scss';
import { CookieSidePage } from './CookieSidePage';

export const CookieRow: FC = () => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.CookieDomain,
  });

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isCookieDomainEnabled =
    settings[activeEnvironmentType].security.auth?.storage[0] ===
    AuthStorageEnum.Cookie;
  const isCookieDomainSaved =
    settings[activeEnvironmentType].security.auth?.storage[0] ===
    initialSettings[activeEnvironmentType].security.auth?.storage[0];

  const [verificationSucceeded, setVerificationSucceeded] = useState(false);

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newStorage = e.target.checked ? ['cookie'] : ['localstorage'];
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        security: {
          ...settings[activeEnvironmentType].security,
          auth: {
            ...settings[activeEnvironmentType].security.auth,
            storage: newStorage,
          },
        },
      },
    });
  };

  return (
    <>
      <SingleToggleCard
        allowExpand={false}
        accordionKey='custom_domain'
        title={t('v2.page.security.domains_row.cookie_row.toggle.title')}
        description={t('v2.page.security.domains_row.cookie_row.description')}
        inputProps={{
          checked: isCookieDomainEnabled,
          disabled: !isCookieDomainEnabled && !verificationSucceeded,
          id: 'cookie-domain',
          isSaved: isCookieDomainSaved,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        onCardClick={
          isCookieDomainEnabled ? () => handleShowModal(true) : undefined
        }
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        actionLink={{
          onClick: () => handleShowModal(true),
          text: t('v2.page.security.domains_row.cookie_row.toggle.open_modal'),
        }}
      />
      <SideDrawer
        title={t('v2.page.security.domains_row.cookie_row.side_drawer.title')}
        description={t('v2.page.security.domains_row.cookie_row.description')}
        isOpen={showModal}
        handleClose={() => handleShowModal(false)}
        className={styles['side-drawer']}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.docs',
          ),
          url: COOKIES_URL,
        }}
      >
        <CookieSidePage onVerificationStatusChange={setVerificationSucceeded} />
      </SideDrawer>
    </>
  );
};
