import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SingleToggleCard,
  Typography,
  EmailIntegrationIcon,
} from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSetKycFieldProp } from '../../../../../../../app/hooks/useSetKycFieldProp';
import { IntegrationCategory } from '../../../../../../../app/types';
import { UseForSignUpToggle } from '../../shared/UseForSignUpToggle';
import { KycPropToggle } from '../../shared/KycPropToggle';
import { getToggleBanners } from '../../shared/getToggleBanners';
import { AdminIcon } from '../../../../../../../icons';
import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';

import styles from './EmailMethod.module.scss';

type EmailMethodProps = {
  disabled: boolean;
};

export const EmailMethod: FC<EmailMethodProps> = ({ disabled }) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.LogInMethodsEmailMethod,
  });

  const { t } = useTranslation();

  const { settings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { updateActiveEnvKycFieldProp } = useSetKycFieldProp(
    IntegrationCategory.OnboardingAndKYC,
  );
  const { getProviderValue, toggleProvider } = useProvidersContext();

  const emailKycField = settings[activeEnvironmentType].kyc.find(
    (field) => field.name === 'email',
  );
  const savedEmailKycField = initialSettings[activeEnvironmentType].kyc.find(
    (field) => field.name === 'email',
  );

  const isEmailKycEnabledSaved = Boolean(
    emailKycField?.enabled === savedEmailKycField?.enabled,
  );

  const isEmailProviderEnabled = Boolean(
    getProviderValue(ProviderEnum.Dynamic, 'enabledAt'),
  );

  const fieldEnabled = Boolean(emailKycField?.enabled) && !disabled;

  const handleToggleField = (e: ChangeEvent<HTMLInputElement>) => {
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'email',
      type: 'enabled',
    });
    updateActiveEnvKycFieldProp({
      isSelected: false,
      name: 'email',
      type: 'required',
    });
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'email',
      type: 'unique',
    });
    updateActiveEnvKycFieldProp({
      isSelected: e.target.checked,
      name: 'email',
      type: 'verify',
    });

    toggleProvider(ProviderEnum.Dynamic, e.target.checked);
  };

  return (
    <>
      <SingleToggleCard
        accordionKey='email-method'
        title={t('v2.page.log_in_methods.email_and_phoneNumber.email.title')}
        description={t(
          'v2.page.log_in_methods.email_and_phoneNumber.email.description',
        )}
        allowExpand={false}
        onCardClick={fieldEnabled ? () => handleShowModal(true) : undefined}
        Tags={getToggleBanners({
          enabled: fieldEnabled,
          required: Boolean(emailKycField?.required),
          unique: Boolean(emailKycField?.unique),
          useForLogIn: isEmailProviderEnabled,
          verify: Boolean(emailKycField?.verify),
        })}
        inputProps={{
          checked: fieldEnabled,
          disabled,
          id: 'email-method',
          isSaved: isEmailKycEnabledSaved,
          onChange: handleToggleField,
          type: 'toggle',
        }}
        Icon={<EmailIntegrationIcon />}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && fieldEnabled}
        title={t(
          'v2.page.log_in_methods.email_and_phoneNumber.email.drawer.title',
        )}
        Icon={<EmailIntegrationIcon />}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.authentication')}
            </Typography>

            <UseForSignUpToggle kycField='email' />
          </div>

          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.profile_information')}
            </Typography>

            <KycPropToggle field='required' name='email' />
          </div>

          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.header.additional_configurations')}
            </Typography>

            <KycPropToggle
              field='verify'
              name='email'
              disabled={isEmailProviderEnabled}
            />
            <KycPropToggle
              field='unique'
              name='email'
              disabled={isEmailProviderEnabled}
            />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
