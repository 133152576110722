import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseCreateCustomHostnameHook } from './useCreateCustomHostname.types';

export const useCreateCustomHostname: UseCreateCustomHostnameHook = (
  options,
) => {
  const { customHostnamesApi } = useRedcoastQueryContext();

  return useMutation(
    (createCustomHostnameRequest) =>
      customHostnamesApi.createCustomHostname(createCustomHostnameRequest),
    options,
  );
};
