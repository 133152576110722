/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MFAAuthPasskeyDeviceGetResponseAssertion,
    MFAAuthPasskeyDeviceGetResponseAssertionFromJSON,
    MFAAuthPasskeyDeviceGetResponseAssertionFromJSONTyped,
    MFAAuthPasskeyDeviceGetResponseAssertionToJSON,
} from './MFAAuthPasskeyDeviceGetResponseAssertion';
import {
    MFADeviceType,
    MFADeviceTypeFromJSON,
    MFADeviceTypeFromJSONTyped,
    MFADeviceTypeToJSON,
} from './MFADeviceType';

/**
 * 
 * @export
 * @interface MFAAuthPasskeyDeviceGetResponse
 */
export interface MFAAuthPasskeyDeviceGetResponse {
    /**
     * 
     * @type {string}
     * @memberof MFAAuthPasskeyDeviceGetResponse
     */
    id: string;
    /**
     * 
     * @type {MFADeviceType}
     * @memberof MFAAuthPasskeyDeviceGetResponse
     */
    type: MFADeviceType;
    /**
     * 
     * @type {MFAAuthPasskeyDeviceGetResponseAssertion}
     * @memberof MFAAuthPasskeyDeviceGetResponse
     */
    assertion: MFAAuthPasskeyDeviceGetResponseAssertion;
}

export function MFAAuthPasskeyDeviceGetResponseFromJSON(json: any): MFAAuthPasskeyDeviceGetResponse {
    return MFAAuthPasskeyDeviceGetResponseFromJSONTyped(json, false);
}

export function MFAAuthPasskeyDeviceGetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAAuthPasskeyDeviceGetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': MFADeviceTypeFromJSON(json['type']),
        'assertion': MFAAuthPasskeyDeviceGetResponseAssertionFromJSON(json['assertion']),
    };
}

export function MFAAuthPasskeyDeviceGetResponseToJSON(value?: MFAAuthPasskeyDeviceGetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': MFADeviceTypeToJSON(value.type),
        'assertion': MFAAuthPasskeyDeviceGetResponseAssertionToJSON(value.assertion),
    };
}

