import CopyButton from '../../../../components/CopyButton';

import styles from './OriginCell.module.css';

type Props = {
  text: string | undefined;
};

export const OriginCell = ({ text }: Props): JSX.Element => (
  <div className={styles.wrapper}>
    <div className={styles.text}>{text}</div>
    <CopyButton color='gray' type='icon' textToCopy={text || ''} />
  </div>
);
