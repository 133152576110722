/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Network,
    NetworkFromJSON,
    NetworkFromJSONTyped,
    NetworkToJSON,
} from './Network';

/**
 * 
 * @export
 * @interface ProjectSettingsChains
 */
export interface ProjectSettingsChains {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsChains
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsChains
     */
    enabled: boolean;
    /**
     * 
     * @type {Array<Network>}
     * @memberof ProjectSettingsChains
     */
    networks?: Array<Network>;
}

export function ProjectSettingsChainsFromJSON(json: any): ProjectSettingsChains {
    return ProjectSettingsChainsFromJSONTyped(json, false);
}

export function ProjectSettingsChainsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsChains {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'enabled': json['enabled'],
        'networks': !exists(json, 'networks') ? undefined : ((json['networks'] as Array<any>).map(NetworkFromJSON)),
    };
}

export function ProjectSettingsChainsToJSON(value?: ProjectSettingsChains | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'enabled': value.enabled,
        'networks': value.networks === undefined ? undefined : ((value.networks as Array<any>).map(NetworkToJSON)),
    };
}

