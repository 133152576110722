/* eslint-disable @typescript-eslint/no-inferrable-types */

import { t } from 'i18next';
import { Link } from 'react-router-dom';

import { capitalize } from '@dynamic-labs/northstar';
import { CheckIcon } from '@dynamic-labs/sdk-react-core';

import Button from '../../../../../../components/Button';
import { ROUTES } from '../../../../../../components/Navigation/data';
import { Typography } from '../../../../../../components/Typography';
import { useEnvironmentsContext } from '../../../../../../context/EnvironmentsContext';
import stepStyles from '../../../../../../components/Accordion/Accordion.module.css';

import styles from './CopySnippetStep.module.scss';

export const CopySnippetStep = ({
  onStepCompleted,
}: {
  onStepCompleted: (step: number) => void;
}) => {
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();

  const iframeSource: string = `https://readme-embeds.dynamic.xyz/quickstart-dynamic-context-provider${
    activeEnvironment?.id ? `?environmentId=${activeEnvironment.id}` : ''
  }`;

  return (
    <div className={styles.content}>
      <Typography
        variant='subtitle'
        className={stepStyles['stepper-tabs__subtitle']}
      >
        {t<string>('overview.steps.second.subtitle.first').replace(
          '{{env}}',
          capitalize(activeEnvironmentType),
        )}
        <Link
          to={ROUTES.api}
          className={stepStyles['stepper-tabs__subtitle__link']}
        >
          {t<string>('overview.steps.second.subtitle.link')}
        </Link>
        {t<string>('overview.steps.second.subtitle.second')}
      </Typography>

      <iframe
        frameBorder='0'
        title='quickstart-code-snippet'
        width='100%'
        height='600px'
        src={iframeSource}
        allow='clipboard-write'
      />

      <Button
        variant='secondary'
        leading={<CheckIcon />}
        onClick={() => onStepCompleted(2)}
        testId='done-cta'
      >
        {t<string>('overview.steps.done_button')}
      </Button>
    </div>
  );
};
