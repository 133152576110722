// eslint-disable-next-line import/no-namespace
import { t } from 'i18next';

import { CheckIcon } from '@dynamic-labs/sdk-react-core';

import Button from '../../../../../../components/Button';

import styles from './InstallSdkStep.module.scss';

export const InstallSdkStep = ({
  onStepCompleted,
}: {
  onStepCompleted: (step: number) => void;
}) => (
  <div className={styles.content}>
    <iframe
      frameBorder='0'
      title='quickstart-sdk'
      width='100%'
      height='50px'
      src='https://readme-embeds.dynamic.xyz/quickstart-sdk'
    />

    <iframe
      frameBorder='0'
      title='quickstart-installation'
      width='100%'
      height='400px'
      src='https://readme-embeds.dynamic.xyz/quickstart'
      allow='clipboard-write'
    />

    <Button
      variant='secondary'
      leading={<CheckIcon />}
      onClick={() => onStepCompleted(1)}
      testId='done-cta'
    >
      {t<string>('overview.steps.done_button')}
    </Button>
  </div>
);
