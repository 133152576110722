import { Fragment, useEffect, useState } from 'react';

import { Field, useFormikContext } from 'formik';
import i18next from 'i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { ProjectSettingsGeneral } from '@dynamic-labs/sdk-api';

import Input from '../../../../components/Input';
import { Typography } from '../../../../components/Typography';
import { useGeneralSettings } from '../../../../context/SettingsContext/helpers/generalSettings';

import styles from './GeneralFields.module.css';

export const defaultFields = [
  'displayName',
  'appLogo',
  'imageUserNotInAccessList',
  'imageUserInAccessList',
  'emailCompanyName',
];

const nonNullableSettings = ['displayName', 'emailCompanyName'];

export const GeneralFields = () => {
  const { enableCustomWelcomeMessaging } = useFlags();
  const [generalFields, setGeneralFields] = useState<string[]>(defaultFields);

  useEffect(() => {
    if (enableCustomWelcomeMessaging) {
      const newFields = [
        ...defaultFields,
        'collectUserDataWelcomeHeader',
        'collectUserDataWelcomeMessage',
        'supportEmail',
      ];
      setGeneralFields(newFields);
    } else {
      setGeneralFields([...defaultFields, 'supportEmail']);
    }
  }, [enableCustomWelcomeMessaging]);

  const { errors, values, touched, handleBlur, setFieldValue, initialValues } =
    useFormikContext<ProjectSettingsGeneral>();
  const { setSpecificSettings } = useGeneralSettings();

  return (
    <>
      {generalFields.map((field) => {
        const fieldName = field as keyof typeof values;
        return (
          <Fragment key={`field_${fieldName}`}>
            <Typography
              className={styles.heading}
              as='h2'
              variant='paragraph-2'
              weight='medium'
            >
              {i18next.t<string>(`settings_page.general.${fieldName}Heading`)}
            </Typography>
            <Typography
              className={styles.description}
              as='p'
              variant='paragraph-1'
              weight='medium'
            >
              {i18next.t<string>(
                `settings_page.general.${fieldName}Description`,
              )}
            </Typography>
            <Field
              as={Input}
              name={fieldName}
              id={fieldName}
              label={i18next.t<string>(
                `settings_page.general.${fieldName}Placeholder`,
              )}
              className={styles.input}
              onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                handleBlur(e);
                if (!Object.keys(errors).length) {
                  if (
                    nonNullableSettings.includes(fieldName as string) &&
                    values[fieldName] === ''
                  ) {
                    setFieldValue(fieldName, initialValues[fieldName]);
                    return;
                  }

                  setSpecificSettings(values);
                }
              }}
              error={!!errors[fieldName] && !!touched[fieldName]}
            />
          </Fragment>
        );
      })}
    </>
  );
};
