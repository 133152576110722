import { useMutation, useQuery } from 'react-query';

import { Gate, GatesResponse } from '@dynamic-labs/sdk-api';

import { gatesApi } from '../../services/api';

export const useGates = (environmentId: string) => {
  const queryKey = ['environmentId', environmentId, 'gates'];

  const { data, isLoading, refetch } = useQuery<GatesResponse>(
    queryKey,

    () => gatesApi.getEnvironmentGates({ environmentId }),
    {
      enabled: !!environmentId,
      refetchOnWindowFocus: false,
    },
  );

  const createGateMutation = useMutation(
    (gate: Gate) =>
      gatesApi.createGate({
        environmentId,
        gateCreateRequest: { ...gate },
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const updateGateMutation = useMutation(
    (gate: Gate) =>
      gatesApi.updateGate({
        gateId: gate.id,
        gateUpdateRequest: { ...gate },
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const deleteGateMutation = useMutation(
    (gateId: string) =>
      gatesApi.deleteGate({
        gateId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const toggleGateMutation = useMutation(
    (gate: Gate) => {
      if (gate.enabledAt) {
        return gatesApi.disableGate({
          gateId: gate.id,
        });
      }

      return gatesApi.enableGate({
        gateId: gate.id,
      });
    },
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    createGate: createGateMutation.mutate,
    deleteGate: deleteGateMutation.mutate,
    gates: data?.gates ?? [],
    isLoading,
    refetch,
    toggleGate: toggleGateMutation.mutate,
    updateGate: updateGateMutation.mutate,
  };
};
