/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AttestationConveyancePreference,
    AttestationConveyancePreferenceFromJSON,
    AttestationConveyancePreferenceFromJSONTyped,
    AttestationConveyancePreferenceToJSON,
} from './AttestationConveyancePreference';
import {
    AuthenticationExtensionsClientInputs,
    AuthenticationExtensionsClientInputsFromJSON,
    AuthenticationExtensionsClientInputsFromJSONTyped,
    AuthenticationExtensionsClientInputsToJSON,
} from './AuthenticationExtensionsClientInputs';
import {
    AuthenticatorSelectionCriteria,
    AuthenticatorSelectionCriteriaFromJSON,
    AuthenticatorSelectionCriteriaFromJSONTyped,
    AuthenticatorSelectionCriteriaToJSON,
} from './AuthenticatorSelectionCriteria';
import {
    PublicKeyCredentialDescriptor,
    PublicKeyCredentialDescriptorFromJSON,
    PublicKeyCredentialDescriptorFromJSONTyped,
    PublicKeyCredentialDescriptorToJSON,
} from './PublicKeyCredentialDescriptor';
import {
    PublicKeyCredentialParameters,
    PublicKeyCredentialParametersFromJSON,
    PublicKeyCredentialParametersFromJSONTyped,
    PublicKeyCredentialParametersToJSON,
} from './PublicKeyCredentialParameters';
import {
    PublicKeyCredentialRpEntity,
    PublicKeyCredentialRpEntityFromJSON,
    PublicKeyCredentialRpEntityFromJSONTyped,
    PublicKeyCredentialRpEntityToJSON,
} from './PublicKeyCredentialRpEntity';
import {
    PublicKeyCredentialUserEntity,
    PublicKeyCredentialUserEntityFromJSON,
    PublicKeyCredentialUserEntityFromJSONTyped,
    PublicKeyCredentialUserEntityToJSON,
} from './PublicKeyCredentialUserEntity';

/**
 * 
 * @export
 * @interface MFARegisterPasskeyDeviceGetResponseRegistration
 */
export interface MFARegisterPasskeyDeviceGetResponseRegistration {
    /**
     * 
     * @type {AttestationConveyancePreference}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    attestation?: AttestationConveyancePreference;
    /**
     * 
     * @type {PublicKeyCredentialRpEntity}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    rp: PublicKeyCredentialRpEntity;
    /**
     * 
     * @type {PublicKeyCredentialUserEntity}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    user: PublicKeyCredentialUserEntity;
    /**
     * 
     * @type {string}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    challenge: string;
    /**
     * 
     * @type {Array<PublicKeyCredentialParameters>}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    pubKeyCredParams: Array<PublicKeyCredentialParameters>;
    /**
     * 
     * @type {number}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    timeout?: number;
    /**
     * 
     * @type {AuthenticatorSelectionCriteria}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    authenticatorSelection?: AuthenticatorSelectionCriteria;
    /**
     * 
     * @type {Array<PublicKeyCredentialDescriptor>}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    excludeCredentials?: Array<PublicKeyCredentialDescriptor>;
    /**
     * 
     * @type {AuthenticationExtensionsClientInputs}
     * @memberof MFARegisterPasskeyDeviceGetResponseRegistration
     */
    extensions?: AuthenticationExtensionsClientInputs;
}

export function MFARegisterPasskeyDeviceGetResponseRegistrationFromJSON(json: any): MFARegisterPasskeyDeviceGetResponseRegistration {
    return MFARegisterPasskeyDeviceGetResponseRegistrationFromJSONTyped(json, false);
}

export function MFARegisterPasskeyDeviceGetResponseRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFARegisterPasskeyDeviceGetResponseRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestation': !exists(json, 'attestation') ? undefined : AttestationConveyancePreferenceFromJSON(json['attestation']),
        'rp': PublicKeyCredentialRpEntityFromJSON(json['rp']),
        'user': PublicKeyCredentialUserEntityFromJSON(json['user']),
        'challenge': json['challenge'],
        'pubKeyCredParams': ((json['pubKeyCredParams'] as Array<any>).map(PublicKeyCredentialParametersFromJSON)),
        'timeout': !exists(json, 'timeout') ? undefined : json['timeout'],
        'authenticatorSelection': !exists(json, 'authenticatorSelection') ? undefined : AuthenticatorSelectionCriteriaFromJSON(json['authenticatorSelection']),
        'excludeCredentials': !exists(json, 'excludeCredentials') ? undefined : ((json['excludeCredentials'] as Array<any>).map(PublicKeyCredentialDescriptorFromJSON)),
        'extensions': !exists(json, 'extensions') ? undefined : AuthenticationExtensionsClientInputsFromJSON(json['extensions']),
    };
}

export function MFARegisterPasskeyDeviceGetResponseRegistrationToJSON(value?: MFARegisterPasskeyDeviceGetResponseRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestation': AttestationConveyancePreferenceToJSON(value.attestation),
        'rp': PublicKeyCredentialRpEntityToJSON(value.rp),
        'user': PublicKeyCredentialUserEntityToJSON(value.user),
        'challenge': value.challenge,
        'pubKeyCredParams': ((value.pubKeyCredParams as Array<any>).map(PublicKeyCredentialParametersToJSON)),
        'timeout': value.timeout,
        'authenticatorSelection': AuthenticatorSelectionCriteriaToJSON(value.authenticatorSelection),
        'excludeCredentials': value.excludeCredentials === undefined ? undefined : ((value.excludeCredentials as Array<any>).map(PublicKeyCredentialDescriptorToJSON)),
        'extensions': AuthenticationExtensionsClientInputsToJSON(value.extensions),
    };
}

