import { Dispatch, SetStateAction } from 'react';

import { getValueByKey } from '@dynamic-labs/sdk-react-core';

import { Selector } from '../../../../../icons';
import Dropdown from '../../../../components/Dropdown';

import styles from './FiltersDropdown.module.css';
import { columnsNames } from './helpers';

type Props<T> = {
  filters: T[];
  searchFilter: T;
  setSearchFilter: Dispatch<SetStateAction<T>>;
};

export const FiltersDropdown = <T extends string>({
  searchFilter,
  setSearchFilter,
  filters,
}: Props<T>): JSX.Element => (
  <Dropdown>
    <Dropdown.Control>
      <div className={styles.control}>
        <span className={styles.control__label}>By</span>
        <span className={styles.control__selected}>
          {getValueByKey(columnsNames, searchFilter)}
        </span>
        <Selector className={styles.control__arrow} />
      </div>
    </Dropdown.Control>
    <Dropdown.List className='!min-w-[182px] !-bottom-3'>
      {filters.map((id) => (
        <Dropdown.Item
          key={id}
          active={searchFilter === id}
          onClick={() => setSearchFilter(id)}
          className={styles.item}
        >
          <span className={styles.item__text}>
            {getValueByKey(columnsNames, id)}
          </span>
        </Dropdown.Item>
      ))}
    </Dropdown.List>
  </Dropdown>
);
