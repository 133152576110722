/* eslint-disable react/jsx-wrap-multilines */
import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../app/routes/Configurations/utils';
import { AdminIcon } from '../../../../icons';
import { SideDrawer } from '../../../components/SideDrawer';

import styles from './JwtExpirationRow.module.scss';
import { JwtExpirationSidePage } from './JwtExpirationSidePage';

export const JwtExpirationRow: FC = () => {
  const { t } = useTranslation();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.JwtExpiration,
  });

  const handleToggleChange = async () => {};

  return (
    <>
      <Section
        title={t('v2.page.security.jwt_expiration_row.title')}
        description={t('v2.page.security.jwt_expiration_row.description')}
      >
        <SingleToggleCard
          allowExpand={false}
          accordionKey='jwt-expiration'
          title={t('v2.page.security.jwt_expiration_row.toggle.title')}
          description={t(
            'v2.page.security.jwt_expiration_row.toggle.description',
          )}
          inputProps={{
            checked: true,
            disabled: true,
            id: 'jwt-expiration',
            isSaved: true,
            onChange: handleToggleChange,
            type: 'toggle',
          }}
          onCardClick={() => handleShowModal(true)}
          customActionIcon={{
            Icon: <AdminIcon />,
            className: styles['action-icon'],
          }}
        />
      </Section>

      <SideDrawer
        title={t('v2.page.security.jwt_expiration_row.side_drawer.title')}
        description={t(
          'v2.page.security.jwt_expiration_row.side_drawer.description',
        )}
        isOpen={showModal}
        handleClose={() => handleShowModal(false)}
        className={styles['side-drawer']}
      >
        <JwtExpirationSidePage />
      </SideDrawer>
    </>
  );
};
