/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkConfiguration,
    NetworkConfigurationFromJSON,
    NetworkConfigurationFromJSONTyped,
    NetworkConfigurationToJSON,
} from './NetworkConfiguration';

/**
 * 
 * @export
 * @interface NetworkConfigurationResponse
 */
export interface NetworkConfigurationResponse {
    /**
     * 
     * @type {string}
     * @memberof NetworkConfigurationResponse
     */
    chainName?: string;
    /**
     * 
     * @type {Array<NetworkConfiguration>}
     * @memberof NetworkConfigurationResponse
     */
    networks?: Array<NetworkConfiguration>;
}

export function NetworkConfigurationResponseFromJSON(json: any): NetworkConfigurationResponse {
    return NetworkConfigurationResponseFromJSONTyped(json, false);
}

export function NetworkConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkConfigurationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chainName': !exists(json, 'chainName') ? undefined : json['chainName'],
        'networks': !exists(json, 'networks') ? undefined : ((json['networks'] as Array<any>).map(NetworkConfigurationFromJSON)),
    };
}

export function NetworkConfigurationResponseToJSON(value?: NetworkConfigurationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chainName': value.chainName,
        'networks': value.networks === undefined ? undefined : ((value.networks as Array<any>).map(NetworkConfigurationToJSON)),
    };
}

