/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnprocessableEntityErrorCode,
    UnprocessableEntityErrorCodeFromJSON,
    UnprocessableEntityErrorCodeFromJSONTyped,
    UnprocessableEntityErrorCodeToJSON,
} from './UnprocessableEntityErrorCode';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface BulkUserCreateResponseFailed
 */
export interface BulkUserCreateResponseFailed {
    /**
     * 
     * @type {User}
     * @memberof BulkUserCreateResponseFailed
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof BulkUserCreateResponseFailed
     */
    error?: string;
    /**
     * 
     * @type {UnprocessableEntityErrorCode}
     * @memberof BulkUserCreateResponseFailed
     */
    code?: UnprocessableEntityErrorCode;
}

export function BulkUserCreateResponseFailedFromJSON(json: any): BulkUserCreateResponseFailed {
    return BulkUserCreateResponseFailedFromJSONTyped(json, false);
}

export function BulkUserCreateResponseFailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUserCreateResponseFailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'error': !exists(json, 'error') ? undefined : json['error'],
        'code': !exists(json, 'code') ? undefined : UnprocessableEntityErrorCodeFromJSON(json['code']),
    };
}

export function BulkUserCreateResponseFailedToJSON(value?: BulkUserCreateResponseFailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'error': value.error,
        'code': UnprocessableEntityErrorCodeToJSON(value.code),
    };
}

