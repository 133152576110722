import Moment from 'react-moment';
import { TFunction } from 'react-i18next';

import { Export } from '@dynamic-labs/sdk-api';
import { capitalize } from '@dynamic-labs/northstar';

import { TableEmptyData } from '../../../../../components/TableEmptyData';
import { TableSkeleton } from '../../../../../components/TableSkeleton';

export const exportsColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: '#',
    accessor: (_row: Export, i: number) => i + 1,
    className: 'text-gray-2 min-w-[28px]',
    id: 'index',
    sortable: false,
  },
  {
    Header: t('users_export.model'),
    accessor: ({ model }: Export) => capitalize(model),
    className: 'text-gray-2 min-w-[130px]',
    id: 'model',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_export.status'),
    accessor: ({ status }: Export) => capitalize(status),
    className: 'text-gray-2 min-w-[130px]',
    id: 'status',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_export.started_at'),
    accessor: ({ startedAt }: Export) =>
      startedAt ? <Moment fromNow>{startedAt}</Moment> : <TableEmptyData />,
    className: 'text-gray-2 min-w-[130px]',
    id: 'startedAt',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_export.completed_at'),
    accessor: ({ completedAt }: Export) =>
      completedAt ? <Moment fromNow>{completedAt}</Moment> : <TableEmptyData />,
    className: 'text-gray-2 min-w-[130px]',
    id: 'completedAt',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('users_export.download'),
    accessor: ({ downloadUrl }: Export) =>
      downloadUrl ? (
        <a
          className='hover:font-bold'
          href={downloadUrl}
          target='_blank'
          rel='noreferrer'
          data-testid='download-url'
        >
          Download
        </a>
      ) : (
        <TableEmptyData />
      ),
    className: 'text-primary-1 min-w-[130px]',
    id: 'downloadUrl',
    skeleton: <TableSkeleton />,
  },
];
