import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import 'highlight.js/styles/gradient-dark.css';
import { Webhook } from '@dynamic-labs/sdk-api';

import Portal from '../../../../components/Portal';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { Typography } from '../../../../components/Typography';
import { webhooksApi } from '../../../../services/api';
import { logger } from '../../../../services/logger';
import { Badge } from '../../../../components/Badge';
import Button from '../../../../components/Button';
import { Skeleton } from '../../../../components/Skeleton/Skeleton';
import CopyButton from '../../../../components/CopyButton';
import { DeleteWebhookCell } from '../DeleteWebhookCell';
import { EditWebhookCell } from '../EditWebhookCell';
import { Tooltip } from '../../../../components/Tooltip';
import { useWebhooksContext } from '../../../../context/WebhooksContext';

import styles from './WebhookDetailModal.module.css';
import { MessageList } from './MessageList';

type Props = {
  setShowWebhookDetail: (show: boolean) => void;
  webhookId: string | undefined;
};

const SigningSecret = ({
  secret,
  isLoading,
  handleRevealSecret,
}: {
  handleRevealSecret: any;
  isLoading: boolean;
  secret: string | undefined;
}) => {
  const { t } = useTranslation();
  if (secret) {
    return (
      <div className='flex items-center'>
        <Typography variant='paragraph-1' weight='medium' className='truncate'>
          {secret}
        </Typography>
        <CopyButton
          textToCopy={secret}
          type='icon'
          color='gray'
          className='pl-2 self-start'
        />
      </div>
    );
  }

  if (isLoading) {
    return <Skeleton className='!mb-0 h-6' />;
  }

  return (
    <Button
      variant='link'
      className='underline !pl-0'
      onClick={handleRevealSecret}
    >
      {t('webhooks.webhook_detail_modal.reveal_secret_button_text')}
    </Button>
  );
};

export const WebhookDetailModal = ({
  setShowWebhookDetail,
  webhookId,
}: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const { webhooks } = useWebhooksContext();
  const { t } = useTranslation();
  const [webhookDetail, setWebhookDetail] = useState<Webhook | undefined>(
    undefined,
  );
  const [isLoadingWebhookDetail, setIsLoadingWebhookDetail] =
    useState<boolean>(false);

  const webhook = webhooks?.find((w: Webhook) => w.webhookId === webhookId);

  useEffect(() => {
    if (!webhook) {
      setShowWebhookDetail(false);
    }
  }, [setShowWebhookDetail, webhook]);

  if (!activeEnvironment?.id) {
    logger.error('No active environment found');
    return null;
  }
  const handleRevealSecret = async () => {
    if (webhook?.webhookId) {
      setIsLoadingWebhookDetail(true);
      webhooksApi
        .getWebhook({
          environmentId: activeEnvironment.id,
          includeSecret: true,
          webhookId: webhook.webhookId,
        })
        .then((res) => {
          setWebhookDetail((res as any) || {});
          setIsLoadingWebhookDetail(false);
        })
        .catch(() => setIsLoadingWebhookDetail(false));
    }
  };

  const ModalHeaderContent = (
    <Typography variant='paragraph-2' weight='regular'>
      <span className='text-gray-1'>
        {t('webhooks.webhook_detail_modal.subtitle')}
      </span>
      <a
        href={t('webhooks.docs_url')}
        target='_blank'
        className='text-primary-1'
        rel='noreferrer'
      >
        docs.
      </a>
    </Typography>
  );

  return (
    <Portal
      handleClose={() => setShowWebhookDetail(false)}
      className={styles.portal}
    >
      <SideModalHeader
        title={t('webhooks.webhook_detail_modal.title')}
        content={ModalHeaderContent}
        showClose
        onClose={() => setShowWebhookDetail(false)}
        actions={
          webhook ? (
            <div id='webhook-form' className='flex items-center space-x-1'>
              <div className='mr-2'>
                <EditWebhookCell webhook={webhook} />
              </div>
              <DeleteWebhookCell webhookId={webhook?.webhookId} />
            </div>
          ) : null
        }
      />
      <div className='space-y-4'>
        <div className='grid grid-cols-4 justify-items-start'>
          <div>
            <Typography
              variant='paragraph-1'
              weight='medium'
              color='gray-1'
              className='mb-1'
            >
              {t('webhooks.webhook_detail_modal.status_label')}
            </Typography>
            <Badge
              variant={webhook?.isEnabled ? 'green' : 'red'}
              text={webhook?.isEnabled ? 'Enabled' : 'Disabled'}
              size='small'
            />
          </div>
          <div>
            <Typography
              variant='paragraph-1'
              weight='medium'
              color='gray-1'
              className='mb-1'
            >
              {t('webhooks.webhook_detail_modal.events_label')}
            </Typography>
            <div className='flex space-x-2 items-center'>
              <Typography variant='subtitle' weight='medium'>
                {webhook?.events?.includes('everything')
                  ? 'everything'
                  : webhook?.events?.length}
              </Typography>
              <Tooltip
                className='!text-left'
                tooltipText={webhook?.events || []}
                placement='bottom'
              >
                <Typography
                  variant='paragraph-1'
                  weight='medium'
                  className='!pl-0'
                  color='primary-1'
                >
                  {t('webhooks.webhook_detail_modal.view_events_label')}
                </Typography>
              </Tooltip>
            </div>
          </div>
          <div>
            <Typography
              variant='paragraph-1'
              weight='medium'
              color='gray-1'
              className='mb-1'
            >
              {t('webhooks.webhook_detail_modal.version_label')}
            </Typography>
            <Typography variant='subtitle' weight='medium'>
              {webhook?.version ?? '1.0.0'}
            </Typography>
          </div>
          <div className='max-w-[200px]'>
            <Typography
              variant='paragraph-1'
              weight='medium'
              color='gray-1'
              className='mb-1'
            >
              {t('webhooks.webhook_detail_modal.signing_secret_label')}
            </Typography>
            <SigningSecret
              secret={webhookDetail?.secret}
              isLoading={isLoadingWebhookDetail}
              handleRevealSecret={handleRevealSecret}
            />
          </div>
        </div>
        <div className='mt-4'>
          <Typography variant='paragraph-1' weight='medium' color='gray-1'>
            {t('webhooks.webhook_detail_modal.url_label')}
          </Typography>
          <div className='flex items-center mb-3'>
            <Typography variant='paragraph-3' weight='medium'>
              <a
                href={webhook?.url}
                target='_blank'
                rel='noreferrer'
                className='text-blue'
              >
                {webhook?.url}
              </a>
            </Typography>
            {webhook?.url && (
              <CopyButton
                textToCopy={webhook.url}
                type='icon'
                color='gray'
                className='pl-2 self-start'
              />
            )}
          </div>
        </div>
        <div className='mt-4'>
          {webhook && <MessageList webhook={webhook} />}
        </div>
      </div>
    </Portal>
  );
};
