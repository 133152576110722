import { ReactNode } from 'react';

import { m } from 'framer-motion';

type Props = {
  children: ReactNode;
  className?: string;
};

export const OpacityAnimation = ({ children, className }: Props) => (
  <m.div
    initial={{
      opacity: 0,
    }}
    animate={{
      opacity: 1,
      transition: {
        opacity: {
          delay: 0.1,
          duration: 0.15,
        },
      },
    }}
    exit={{
      opacity: 0,
      transition: {
        opacity: {
          duration: 0.15,
        },
      },
    }}
    className={className}
  >
    {children}
  </m.div>
);
