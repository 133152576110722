/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WebhookMessage,
    WebhookMessageFromJSON,
    WebhookMessageFromJSONTyped,
    WebhookMessageToJSON,
} from './WebhookMessage';

/**
 * 
 * @export
 * @interface WebhookMessagesResponse
 */
export interface WebhookMessagesResponse {
    /**
     * 
     * @type {string}
     * @memberof WebhookMessagesResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<WebhookMessage>}
     * @memberof WebhookMessagesResponse
     */
    data?: Array<WebhookMessage>;
}

export function WebhookMessagesResponseFromJSON(json: any): WebhookMessagesResponse {
    return WebhookMessagesResponseFromJSONTyped(json, false);
}

export function WebhookMessagesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookMessagesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(WebhookMessageFromJSON)),
    };
}

export function WebhookMessagesResponseToJSON(value?: WebhookMessagesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor': value.cursor,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(WebhookMessageToJSON)),
    };
}

