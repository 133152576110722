import { ProviderEnum, SignInProviderEnum } from '@dynamic-labs/sdk-api';

export const getSignInProviderForProviderEnum = (
  provider: ProviderEnum,
): SignInProviderEnum | null => {
  switch (provider) {
    case ProviderEnum.EmailOnly:
    case ProviderEnum.Dynamic:
      return SignInProviderEnum.Dynamic;
    case ProviderEnum.MagicLink:
      return SignInProviderEnum.MagicLink;
    case ProviderEnum.Blocto:
      return SignInProviderEnum.Blocto;
    case ProviderEnum.Turnkey:
      return SignInProviderEnum.Turnkey;
    case ProviderEnum.CoinbaseWaas:
      return SignInProviderEnum.CoinbaseWaas;
    default:
      return null;
  }
};
