import { useEffect, useMemo, useState } from 'react';

import { AllowlistEntriesResponse } from '@dynamic-labs/sdk-api';

import { fetchAllowListEntries } from './helpers';

export type UseFetchAllowListEntriesArgs = {
  allowlistId: string | undefined;
};

export const useFetchAllowListEntries = ({
  allowlistId,
}: UseFetchAllowListEntriesArgs) => {
  const limit = 20;
  const [entries, setEntries] = useState<
    AllowlistEntriesResponse | undefined
  >();
  const [entryFilter, setEntryFilter] = useState<string>();
  const [offset, setOffset] = useState<number>(0);
  const [page, setPage] = useState<number>(0);

  useEffect(() => {
    fetchAllowListEntries(allowlistId, setEntries, offset, limit, entryFilter);
  }, [allowlistId, offset, entryFilter]);

  const pageOverflow = useMemo(() => {
    if (entries?.count) {
      const pageCount = Math.ceil(entries.count / limit);

      return pageCount === 0 || page >= pageCount;
    }
    return false;
  }, [entries, page]);

  useEffect(() => {
    setOffset(0);
    setPage(0);
  }, [entryFilter, allowlistId, pageOverflow]);

  const entriesData = useMemo(() => entries?.entries, [entries]);

  return {
    entriesData,
    fetchAllowListEntries: () =>
      fetchAllowListEntries(
        allowlistId,
        setEntries,
        offset,
        limit,
        entryFilter,
      ),
    limit,
    offset,
    page,
    setEntries,
    setEntryFilter,
    setOffset,
    setPage,
    total: entries?.count,
  };
};
