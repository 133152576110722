import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseUpdateProjectSettingsHook } from './useUpdateProjectSettings.types';

export const useUpdateProjectSettings: UseUpdateProjectSettingsHook = (
  options,
) => {
  const { environmentsApi } = useRedcoastQueryContext();

  return useMutation(
    ({ environmentId, projectSettings }) =>
      environmentsApi.updateProjectSettings({
        environmentId,
        projectSettings,
      }),
    options,
  );
};
