import { useMemo } from 'react';

import { useMutation } from 'react-query';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import {
  convertProvidersToMap,
  updateProviders,
} from '../../routes/Configurations/Providers/utils';
import { useEnvironmentId } from '../../routes/Configurations/Providers/hooks';
import { useEnvironmentProviders } from '../useEnvironmentProviders';

export const useSaveProvidersMutation = (
  onSuccess?: () => void,
  onError?: (e: unknown) => void,
) => {
  const environmentId = useEnvironmentId();

  const { data: providersData, refetch } =
    useEnvironmentProviders(environmentId);

  const savedProviders = useMemo<Record<ProviderEnum, Provider>>(
    () => convertProvidersToMap(providersData?.providers || []),
    [providersData],
  );

  return useMutation(
    async (providers: Provider[]) =>
      environmentId &&
      updateProviders(environmentId, savedProviders, providers),
    {
      onError: (e) => onError && onError(e),
      onSuccess: () => {
        refetch();
        if (onSuccess) {
          onSuccess();
        }
      },
    },
  );
};
