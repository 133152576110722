import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useNavigate } from 'react-router-dom';

import { Webhook } from '@dynamic-labs/sdk-api';

import { Typography } from '../../components/Typography';
import Button from '../../components/Button';
import ReactTable from '../../components/ReactTable';
import { PlusIcon } from '../../../icons';
import { Toaster } from '../../components/Toaster';
import { Icon } from '../../components/Icon';
import { UpsellBanner } from '../../components/UpsellBanner';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';
import { useWebhooksContext } from '../../context/WebhooksContext';

import styles from './Api/api.module.css';
import { useWebhooksColumns } from './Webhooks/helpers/useWebhooksColumns';
import { WebhookDetailModal, CreateWebhookModal } from './Webhooks';

const WebhookRoute = () => {
  const [showWebhookModal, setShowWebhookModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);
  const [showWebhookDetail, setShowWebhookDetail] = useState(false);
  const [activeWebhook, setActiveWebhook] = useState<undefined | Webhook>(
    undefined,
  );

  const { enableWebhooks } = useFlags();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const { columns } = useWebhooksColumns();
  const { webhooks, isLoadingWebhooks } = useWebhooksContext();
  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner, shouldLockOnLive, shouldLockWhenFreeScope } =
    useSubscriptionLock('webhooks');

  const webhooksLimitReached = webhooks && webhooks.length >= 10;

  useEffect(() => {
    if (!showToaster) {
      return;
    }
    setTimeout(() => {
      setShowToaster(false);
    }, 3000);
  }, [showToaster]);

  useEffect(() => {
    if (!enableWebhooks) {
      navigate('/dashboard/developer/api');
    }
  }, [enableWebhooks, navigate]);

  return (
    <>
      <div className={styles.container}>
        {showWebhookModal && (
          <CreateWebhookModal setShowModal={() => setShowWebhookModal(false)} />
        )}
        {showWebhookDetail && (
          <WebhookDetailModal
            setShowWebhookDetail={() => setShowWebhookDetail(false)}
            webhookId={activeWebhook?.webhookId}
          />
        )}
        <div className={styles.row__container}>
          {(shouldShowTrialBanner || shouldLockWhenFreeScope) && (
            <UpsellBanner
              daysLeft={subscription?.trialDaysLeft}
              className='mb-4'
            />
          )}
          <div>
            <div className={styles.table__container}>
              <Typography variant='title' className={styles.heading}>
                {t('webhooks_table.title')}
              </Typography>
              <Typography variant='paragraph-1' className={styles.subheading}>
                {t('webhooks_table.subtitle')}
                <a
                  target='_blank'
                  href={t('webhooks.docs_url')}
                  rel='noreferrer'
                  className='text-primary-1 underline'
                >
                  Docs.
                </a>
              </Typography>
              <div data-testid='webhook_management' className='rounded-lg'>
                <ReactTable
                  columns={columns}
                  data={webhooks}
                  loading={isLoadingWebhooks}
                  rowProps={(row) => ({
                    className: 'webhook-detail',
                    onClick: (e) => {
                      if (row?.original && e?.target?.id !== 'webhook-form') {
                        setActiveWebhook(row.original);
                        setShowWebhookDetail(true);
                      }
                    },
                  })}
                />
              </div>
            </div>
            <Button
              onClick={() =>
                webhooksLimitReached
                  ? setShowToaster(true)
                  : setShowWebhookModal(true)
              }
              variant='secondary'
              className={styles['button--add']}
              leading={<Icon size='medium' icon={<PlusIcon />} />}
              disabled={shouldLockOnLive}
            >
              {t('webhooks_table.create_webhook_modal_button')}
            </Button>
          </div>
        </div>
      </div>

      <AnimatePresence>
        {showToaster && (
          <Toaster
            variant='error'
            message='Max 10 webhooks allowed per environment'
            onExit={() => setShowToaster(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default WebhookRoute;
