/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsDesignWidget
 */
export interface ProjectSettingsDesignWidget {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignWidget
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignWidget
     */
    border?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingsDesignWidget
     */
    radius?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignWidget
     */
    textColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignWidget
     */
    theme?: string;
}

export function ProjectSettingsDesignWidgetFromJSON(json: any): ProjectSettingsDesignWidget {
    return ProjectSettingsDesignWidgetFromJSONTyped(json, false);
}

export function ProjectSettingsDesignWidgetFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsDesignWidget {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': !exists(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'border': !exists(json, 'border') ? undefined : json['border'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
        'textColor': !exists(json, 'textColor') ? undefined : json['textColor'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
    };
}

export function ProjectSettingsDesignWidgetToJSON(value?: ProjectSettingsDesignWidget | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backgroundColor': value.backgroundColor,
        'border': value.border,
        'radius': value.radius,
        'textColor': value.textColor,
        'theme': value.theme,
    };
}

