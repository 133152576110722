/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Export,
    ExportFromJSON,
    ExportFromJSONTyped,
    ExportToJSON,
} from './Export';

/**
 * 
 * @export
 * @interface ExportsResponse
 */
export interface ExportsResponse {
    /**
     * 
     * @type {Array<Export>}
     * @memberof ExportsResponse
     */
    exports?: Array<Export>;
}

export function ExportsResponseFromJSON(json: any): ExportsResponse {
    return ExportsResponseFromJSONTyped(json, false);
}

export function ExportsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'exports': !exists(json, 'exports') ? undefined : ((json['exports'] as Array<any>).map(ExportFromJSON)),
    };
}

export function ExportsResponseToJSON(value?: ExportsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'exports': value.exports === undefined ? undefined : ((value.exports as Array<any>).map(ExportToJSON)),
    };
}

