/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    InviteConflictResponse,
    InviteConflictResponseFromJSON,
    InviteConflictResponseToJSON,
    InviteSendRequest,
    InviteSendRequestFromJSON,
    InviteSendRequestToJSON,
    InviteStatusEnum,
    InviteStatusEnumFromJSON,
    InviteStatusEnumToJSON,
    InviteUpdateRequest,
    InviteUpdateRequestFromJSON,
    InviteUpdateRequestToJSON,
    InvitesResponse,
    InvitesResponseFromJSON,
    InvitesResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateInviteRequest {
    organizationId: string;
    inviteSendRequest: InviteSendRequest;
}

export interface DeleteInviteRequest {
    inviteId: string;
}

export interface GetInvitesRequest {
    status: InviteStatusEnum;
}

export interface GetOrganizationInvitesByOrganizationIdRequest {
    organizationId: string;
}

export interface UpdateInviteRequest {
    inviteId: string;
    inviteUpdateRequest: InviteUpdateRequest;
}

/**
 * 
 */
export class InvitesApi extends runtime.BaseAPI {

    /**
     * Creates an invite to the organization
     */
    async createInviteRaw(requestParameters: CreateInviteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling createInvite.');
        }

        if (requestParameters.inviteSendRequest === null || requestParameters.inviteSendRequest === undefined) {
            throw new runtime.RequiredError('inviteSendRequest','Required parameter requestParameters.inviteSendRequest was null or undefined when calling createInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/invites`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteSendRequestToJSON(requestParameters.inviteSendRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Creates an invite to the organization
     */
    async createInvite(requestParameters: CreateInviteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Delete invite for user
     */
    async deleteInviteRaw(requestParameters: DeleteInviteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling deleteInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invites/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete invite for user
     */
    async deleteInvite(requestParameters: DeleteInviteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteInviteRaw(requestParameters, initOverrides);
    }

    /**
     * Get all the user invites
     */
    async getInvitesRaw(requestParameters: GetInvitesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvitesResponse>> {
        if (requestParameters.status === null || requestParameters.status === undefined) {
            throw new runtime.RequiredError('status','Required parameter requestParameters.status was null or undefined when calling getInvites.');
        }

        const queryParameters: any = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitesResponseFromJSON(jsonValue));
    }

    /**
     * Get all the user invites
     */
    async getInvites(requestParameters: GetInvitesRequest, initOverrides?: RequestInit): Promise<InvitesResponse> {
        const response = await this.getInvitesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all the Invites the belong to the organization
     */
    async getOrganizationInvitesByOrganizationIdRaw(requestParameters: GetOrganizationInvitesByOrganizationIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InvitesResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationInvitesByOrganizationId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/invites`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InvitesResponseFromJSON(jsonValue));
    }

    /**
     * Fetches all the Invites the belong to the organization
     */
    async getOrganizationInvitesByOrganizationId(requestParameters: GetOrganizationInvitesByOrganizationIdRequest, initOverrides?: RequestInit): Promise<InvitesResponse> {
        const response = await this.getOrganizationInvitesByOrganizationIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update invite for user (accept/reject)
     */
    async updateInviteRaw(requestParameters: UpdateInviteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.inviteId === null || requestParameters.inviteId === undefined) {
            throw new runtime.RequiredError('inviteId','Required parameter requestParameters.inviteId was null or undefined when calling updateInvite.');
        }

        if (requestParameters.inviteUpdateRequest === null || requestParameters.inviteUpdateRequest === undefined) {
            throw new runtime.RequiredError('inviteUpdateRequest','Required parameter requestParameters.inviteUpdateRequest was null or undefined when calling updateInvite.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/invites/{inviteId}`.replace(`{${"inviteId"}}`, encodeURIComponent(String(requestParameters.inviteId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: InviteUpdateRequestToJSON(requestParameters.inviteUpdateRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update invite for user (accept/reject)
     */
    async updateInvite(requestParameters: UpdateInviteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.updateInviteRaw(requestParameters, initOverrides);
    }

}
