import { ReactNode } from 'react';

import { Typography } from '../Typography';

import styles from './error-info.module.css';

type Props = {
  children?: ReactNode;
  className?: string;
  message?: string;
  title?: string;
};

export const ErrorInfo = ({
  message,
  title,
  children,
  className = '',
}: Props) => (
  <div data-testid='ErrorInfo' className={`${styles.container} ${className}`}>
    {title && (
      <Typography
        variant='paragraph-2'
        weight='medium'
        className={styles.heading}
      >
        {title}
      </Typography>
    )}
    {children ?? (
      <Typography variant='paragraph-1' className={styles.content}>
        {message}
      </Typography>
    )}
  </div>
);
