/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimeUnitEnum,
    TimeUnitEnumFromJSON,
    TimeUnitEnumFromJSONTyped,
    TimeUnitEnumToJSON,
} from './TimeUnitEnum';

/**
 * 
 * @export
 * @interface Duration
 */
export interface Duration {
    /**
     * 
     * @type {number}
     * @memberof Duration
     */
    amount: number;
    /**
     * 
     * @type {TimeUnitEnum}
     * @memberof Duration
     */
    unit: TimeUnitEnum;
}

export function DurationFromJSON(json: any): Duration {
    return DurationFromJSONTyped(json, false);
}

export function DurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Duration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'amount': json['amount'],
        'unit': TimeUnitEnumFromJSON(json['unit']),
    };
}

export function DurationToJSON(value?: Duration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'amount': value.amount,
        'unit': TimeUnitEnumToJSON(value.unit),
    };
}

