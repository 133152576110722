/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookCreateRequest
 */
export interface WebhookCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhookCreateRequest
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookCreateRequest
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookCreateRequest
     */
    events: Array<string>;
}

export function WebhookCreateRequestFromJSON(json: any): WebhookCreateRequest {
    return WebhookCreateRequestFromJSONTyped(json, false);
}

export function WebhookCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'isEnabled': json['isEnabled'],
        'events': json['events'],
    };
}

export function WebhookCreateRequestToJSON(value?: WebhookCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'isEnabled': value.isEnabled,
        'events': value.events,
    };
}

