/* eslint-disable @typescript-eslint/no-shadow */

import { useCallback } from 'react';

import { UseQueryOptions, useQuery } from 'react-query';

import {
  DeeplinkUrlResponse,
  DeeplinkUrlsResponse,
} from '@dynamic-labs/sdk-api';

import { useRedcoastQueryContext } from '../../../contexts/RedcoastQueryContextProvider';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ApiErrorResponse = { json: () => Promise<any> };

export type UseDeeplinkUrlsHook = (
  params: { environmentId: string },
  options?: UseQueryOptions<
    DeeplinkUrlsResponse,
    unknown,
    DeeplinkUrlResponse[],
    string[]
  >,
) => {
  addDeeplink: (url: string) => Promise<DeeplinkUrlResponse>;
  deeplinkUrls: DeeplinkUrlResponse[];
  isLoading: boolean;
  removeDeeplink: (url: string) => Promise<void>;
};

export const useDeeplinkUrls: UseDeeplinkUrlsHook = (
  { environmentId },
  options,
) => {
  const { deeplinkUrlsApi } = useRedcoastQueryContext();

  const queryFn = useCallback(
    () => deeplinkUrlsApi.getDeeplinkUrls({ environmentId }),
    [deeplinkUrlsApi, environmentId],
  );

  const {
    data: deeplinkUrls = [],
    isFetching,
    refetch,
  } = useQuery({
    queryFn,
    queryKey: ['deeplink-urls'],
    select: ({ deeplinkUrls }) => deeplinkUrls,
    ...options,
  });

  return {
    addDeeplink: async (url) => {
      try {
        const result = await deeplinkUrlsApi.addDeeplinkUrl({
          addDeeplinkUrlRequest: { url },
          environmentId,
        });

        await refetch();

        return result;
      } catch (error) {
        const errorJson = await (error as ApiErrorResponse).json();

        const message = errorJson.message ?? errorJson.error ?? errorJson;

        // Replace error message by a more user friendly message
        if (message.includes?.('must match pattern'))
          throw new Error(
            'Invalid deeplink URL. Click "Learn more" above to see our docs',
          );

        throw new Error(message);
      }
    },

    deeplinkUrls,

    isLoading: isFetching,

    removeDeeplink: async (targetUrl) => {
      const deeplinkUrlId = deeplinkUrls.find(
        ({ url }) => targetUrl === url,
      )?.id;

      if (!deeplinkUrlId) return;

      try {
        const result = await deeplinkUrlsApi.deleteDeeplinkUrlById({
          deeplinkUrlId,
        });

        await refetch();

        return result;
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();

        throw new Error(message);
      }
    },
  };
};
