import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetOrganizationsForMemberHook } from './useGetOrganizationsForMember.types';

export const useGetOrganizationsForMember: UseGetOrganizationsForMemberHook = (
  options,
) => {
  const { organizationsApi } = useRedcoastQueryContext();

  return useQuery(
    ['organizationsApi', 'getOrganizationsForMember'],
    async () => organizationsApi.getOrganizationsForMember(),
    options,
  );
};
