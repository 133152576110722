import React, { ReactNode } from 'react';

import styles from './Paper.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export const Paper = ({ children, className = '' }: Props): JSX.Element => (
  <article className={`${styles.card} ${className}`}>{children}</article>
);
