import { useMemo } from 'react';

import { t } from 'i18next';

import { useWalletsBreakdownQuery } from '../../../../hooks/useWalletsBreakdownQuery';
import { WidgetView } from '../WidgetView';

import { WalletBreakdownStatisticsWidgetTypes } from './WalletBreakdownStatisticsWidget.types';
import { WalletValues } from './WalletValues';

const EMBEDDED_WALLET_PROVIDERS = [
  'magiclink',
  'magicemailotp',
  'magicsocial',
  'turnkey',
  'turnkeyhd',
  'coinbasempc',
];

export const WalletBreakdownStatisticsWidget: WalletBreakdownStatisticsWidgetTypes =
  ({ endDate, environmentId, startDate }) => {
    const { data, isLoading } = useWalletsBreakdownQuery({
      endDate,
      environmentId,
      startDate,
    });
    const isEmpty = useMemo(() => !data || !data.length, [data]);

    const filteredEmbeddedWallets = useMemo(() => {
      if (!data) return;

      return data.filter((wallet) =>
        EMBEDDED_WALLET_PROVIDERS.includes(wallet.walletName || ''),
      );
    }, [data]);
    const filteredEmbeddedWalletsAmount = filteredEmbeddedWallets?.reduce(
      (total, item) => total + (item.count || 0),
      0,
    );

    const restOfWallets = useMemo(() => {
      if (!data) return;

      return data.filter(
        (wallet) =>
          !EMBEDDED_WALLET_PROVIDERS.includes(wallet.walletName || ''),
      );
    }, [data]);
    const restOfWalletsAmount = restOfWallets?.reduce(
      (total, item) => total + (item.count || 0),
      0,
    );

    return (
      <WidgetView
        title={t<string>('analytics.widgets.wallet_distribution.title')}
      >
        <div className='overflow-auto max-h-[368px] divide-y '>
          {isEmpty || isLoading ? (
            <div className='text-gray-2 text-sm pt-4'>
              {t<string>('analytics.widgets.wallet_distribution.empty_label')}
            </div>
          ) : (
            <>
              <WalletValues
                title={t<string>(
                  'analytics.widgets.wallet_distribution.embedded',
                )}
                wallets={filteredEmbeddedWallets}
                walletsAmount={filteredEmbeddedWalletsAmount}
              />
              <WalletValues
                title={t<string>(
                  'analytics.widgets.wallet_distribution.wallets',
                )}
                wallets={restOfWallets}
                walletsAmount={restOfWalletsAmount}
              />
            </>
          )}
        </div>
      </WidgetView>
    );
  };
