import { useMemo } from 'react';

import { t } from 'i18next';

import { useVisitAnalyticsQuery } from '../../hooks';
import { SmallWidgetView } from '../SmallWidgetView';
import { SmallLoadingWidgetView } from '../SmallLoadingWidgetView';

import { UniqueUsersWidgetComponent } from './UniqueUsersWidget.types';

export const UniqueUsersWidget: UniqueUsersWidgetComponent = ({
  endDate,
  environmentId,
  startDate,
}) => {
  const { data: visitAnalyticsData, isLoading } = useVisitAnalyticsQuery({
    endDate,
    environmentId,
    startDate,
  });

  const uniqueTotal = useMemo(
    () => visitAnalyticsData?.summedTotalUniqueCount,
    [visitAnalyticsData],
  );

  if (isLoading) {
    return <SmallLoadingWidgetView />;
  }

  return (
    <SmallWidgetView
      title={t<string>('analytics.widgets.unique_users.title')}
      value={uniqueTotal || 0}
    />
  );
};
