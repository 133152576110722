import isEqual from 'react-fast-compare';

import { useSettingsContext } from '../..';
import { useEnvironmentsContext } from '../../../EnvironmentsContext';

export const useModalDesignSettings = () => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings, initialSettings, setInitialSettings } =
    useSettingsContext();

  const setBorder = (border: string) => {
    if (!settings[activeEnvironmentType]?.design?.modal?.border) return;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            border,
          },
        },
      },
    });
  };

  const setTheme = (theme: string) => {
    if (!settings[activeEnvironmentType]?.design?.modal?.theme) return;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            theme,
          },
        },
      },
    });
  };

  const setPrimaryColor = (primaryColor: string) => {
    if (!settings[activeEnvironmentType]?.design?.modal?.primaryColor) return;
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            primaryColor,
          },
        },
      },
    });
  };

  const setBrand = (brand: string) => {
    if (!settings[activeEnvironmentType]?.design?.modal?.brand) return;
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            brand,
          },
        },
      },
    });
  };

  const setTemplate = (template: string) => {
    if (!settings[activeEnvironmentType]?.design?.modal?.template) return;
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            template,
          },
        },
      },
    });
  };

  const settingsHasChanged = !isEqual(
    initialSettings[activeEnvironmentType].design.modal,
    settings[activeEnvironmentType].design.modal,
  );

  const updateInitialSettings = () => {
    setInitialSettings(settings);
  };

  const cancelChanges = () => {
    setSettings(initialSettings);
  };

  return {
    cancelChanges,
    globalSettings: settings,
    setBorder,
    setBrand,
    setPrimaryColor,
    setTemplate,
    setTheme,
    settings: settings?.[activeEnvironmentType].design.modal,
    settingsHasChanged,
    updateInitialSettings,
  };
};
