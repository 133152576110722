/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthenticationExtensionsClientOutputs,
    AuthenticationExtensionsClientOutputsFromJSON,
    AuthenticationExtensionsClientOutputsFromJSONTyped,
    AuthenticationExtensionsClientOutputsToJSON,
} from './AuthenticationExtensionsClientOutputs';
import {
    AuthenticatorAssertionResponse,
    AuthenticatorAssertionResponseFromJSON,
    AuthenticatorAssertionResponseFromJSONTyped,
    AuthenticatorAssertionResponseToJSON,
} from './AuthenticatorAssertionResponse';
import {
    AuthenticatorAttachment,
    AuthenticatorAttachmentFromJSON,
    AuthenticatorAttachmentFromJSONTyped,
    AuthenticatorAttachmentToJSON,
} from './AuthenticatorAttachment';
import {
    PublicKeyCredentialType,
    PublicKeyCredentialTypeFromJSON,
    PublicKeyCredentialTypeFromJSONTyped,
    PublicKeyCredentialTypeToJSON,
} from './PublicKeyCredentialType';

/**
 * 
 * @export
 * @interface PasskeyAuthRequest
 */
export interface PasskeyAuthRequest {
    /**
     * 
     * @type {string}
     * @memberof PasskeyAuthRequest
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PasskeyAuthRequest
     */
    rawId: string;
    /**
     * 
     * @type {AuthenticatorAssertionResponse}
     * @memberof PasskeyAuthRequest
     */
    response: AuthenticatorAssertionResponse;
    /**
     * 
     * @type {AuthenticatorAttachment}
     * @memberof PasskeyAuthRequest
     */
    authenticatorAttachment?: AuthenticatorAttachment;
    /**
     * 
     * @type {AuthenticationExtensionsClientOutputs}
     * @memberof PasskeyAuthRequest
     */
    clientExtensionResults: AuthenticationExtensionsClientOutputs;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PasskeyAuthRequest
     */
    type: PublicKeyCredentialType;
}

export function PasskeyAuthRequestFromJSON(json: any): PasskeyAuthRequest {
    return PasskeyAuthRequestFromJSONTyped(json, false);
}

export function PasskeyAuthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasskeyAuthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rawId': json['rawId'],
        'response': AuthenticatorAssertionResponseFromJSON(json['response']),
        'authenticatorAttachment': !exists(json, 'authenticatorAttachment') ? undefined : AuthenticatorAttachmentFromJSON(json['authenticatorAttachment']),
        'clientExtensionResults': AuthenticationExtensionsClientOutputsFromJSON(json['clientExtensionResults']),
        'type': PublicKeyCredentialTypeFromJSON(json['type']),
    };
}

export function PasskeyAuthRequestToJSON(value?: PasskeyAuthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rawId': value.rawId,
        'response': AuthenticatorAssertionResponseToJSON(value.response),
        'authenticatorAttachment': AuthenticatorAttachmentToJSON(value.authenticatorAttachment),
        'clientExtensionResults': AuthenticationExtensionsClientOutputsToJSON(value.clientExtensionResults),
        'type': PublicKeyCredentialTypeToJSON(value.type),
    };
}

