/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gate,
    GateFromJSON,
    GateFromJSONTyped,
    GateToJSON,
} from './Gate';

/**
 * 
 * @export
 * @interface GatesResponse
 */
export interface GatesResponse {
    /**
     * 
     * @type {Array<Gate>}
     * @memberof GatesResponse
     */
    gates?: Array<Gate>;
}

export function GatesResponseFromJSON(json: any): GatesResponse {
    return GatesResponseFromJSONTyped(json, false);
}

export function GatesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GatesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'gates': !exists(json, 'gates') ? undefined : ((json['gates'] as Array<any>).map(GateFromJSON)),
    };
}

export function GatesResponseToJSON(value?: GatesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'gates': value.gates === undefined ? undefined : ((value.gates as Array<any>).map(GateToJSON)),
    };
}

