import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ROUTES } from '../../components/Navigation/data';
import { useDashboardContext } from '../../context/DashboardContext';
import AcceptInvites from '../Dashboard/AcceptInvites';
import { useHasUserWithPendingMfa } from '../../hooks/useHasUserWithPendingMfa';

import { useAsyncRedirect } from './useAsyncRedirect';

export const OnboardingRoute: React.FC = () => {
  const [hasUserAcceptedInvitation, setHasUserAcceptedInvitation] =
    useState(false);
  const {
    userInvites,
    setActiveOrganizationId,
    setUserInvites,
    setUserOrganizations,
    userOrganizations,
  } = useDashboardContext();
  const navigate = useNavigate();
  const hasUserWithPendingMfa = useHasUserWithPendingMfa();

  useAsyncRedirect({
    hasUserAcceptedInvitation,
    hasUserWithPendingMfa,
    userInvites,
    userOrganizations,
  });

  const onSubmit = async () => {
    setUserInvites([]);
    navigate(ROUTES.overview);
  };

  return (
    <AcceptInvites
      setUserInvites={setUserInvites}
      invites={userInvites}
      setActiveOrganizationId={setActiveOrganizationId}
      onSubmit={onSubmit}
      setUserOrganizations={setUserOrganizations}
      setHasUserAcceptedInvitation={setHasUserAcceptedInvitation}
    />
  );
};
