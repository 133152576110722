/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    ExternalJwtCheckRequest,
    ExternalJwtCheckRequestFromJSON,
    ExternalJwtCheckRequestToJSON,
    ExternalJwtCheckResponse,
    ExternalJwtCheckResponseFromJSON,
    ExternalJwtCheckResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CheckExternalJwtRequest {
    environmentId: string;
    externalJwtCheckRequest: ExternalJwtCheckRequest;
}

/**
 * 
 */
export class ExternalJwtApi extends runtime.BaseAPI {

    /**
     * Check if the external provided JWT is valid for the specified environment
     */
    async checkExternalJwtRaw(requestParameters: CheckExternalJwtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExternalJwtCheckResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling checkExternalJwt.');
        }

        if (requestParameters.externalJwtCheckRequest === null || requestParameters.externalJwtCheckRequest === undefined) {
            throw new runtime.RequiredError('externalJwtCheckRequest','Required parameter requestParameters.externalJwtCheckRequest was null or undefined when calling checkExternalJwt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/externalJwt/check`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExternalJwtCheckRequestToJSON(requestParameters.externalJwtCheckRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExternalJwtCheckResponseFromJSON(jsonValue));
    }

    /**
     * Check if the external provided JWT is valid for the specified environment
     */
    async checkExternalJwt(requestParameters: CheckExternalJwtRequest, initOverrides?: RequestInit): Promise<ExternalJwtCheckResponse> {
        const response = await this.checkExternalJwtRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
