/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifyUnlinkRequest
 */
export interface VerifyUnlinkRequest {
    /**
     * 
     * @type {string}
     * @memberof VerifyUnlinkRequest
     */
    walletId?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyUnlinkRequest
     */
    verifiedCrentialId?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyUnlinkRequest
     */
    primaryWalletId?: string;
}

export function VerifyUnlinkRequestFromJSON(json: any): VerifyUnlinkRequest {
    return VerifyUnlinkRequestFromJSONTyped(json, false);
}

export function VerifyUnlinkRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyUnlinkRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletId': !exists(json, 'walletId') ? undefined : json['walletId'],
        'verifiedCrentialId': !exists(json, 'verifiedCrentialId') ? undefined : json['verifiedCrentialId'],
        'primaryWalletId': !exists(json, 'primaryWalletId') ? undefined : json['primaryWalletId'],
    };
}

export function VerifyUnlinkRequestToJSON(value?: VerifyUnlinkRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletId': value.walletId,
        'verifiedCrentialId': value.verifiedCrentialId,
        'primaryWalletId': value.primaryWalletId,
    };
}

