/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Export,
    ExportFromJSON,
    ExportToJSON,
    ExportCreateRequest,
    ExportCreateRequestFromJSON,
    ExportCreateRequestToJSON,
    ExportsResponse,
    ExportsResponseFromJSON,
    ExportsResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateExportRequest {
    environmentId: string;
    exportCreateRequest: ExportCreateRequest;
}

export interface DownloadExportByIdRequest {
    exportId: string;
}

export interface GetEnvironmentExportsRequest {
    environmentId: string;
}

export interface GetExportByIdRequest {
    exportId: string;
}

/**
 * 
 */
export class ExportsApi extends runtime.BaseAPI {

    /**
     * Create a new export request for the project environment
     */
    async createExportRaw(requestParameters: CreateExportRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Export>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createExport.');
        }

        if (requestParameters.exportCreateRequest === null || requestParameters.exportCreateRequest === undefined) {
            throw new runtime.RequiredError('exportCreateRequest','Required parameter requestParameters.exportCreateRequest was null or undefined when calling createExport.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/exports`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ExportCreateRequestToJSON(requestParameters.exportCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportFromJSON(jsonValue));
    }

    /**
     * Create a new export request for the project environment
     */
    async createExport(requestParameters: CreateExportRequest, initOverrides?: RequestInit): Promise<Export> {
        const response = await this.createExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download an export by ID
     */
    async downloadExportByIdRaw(requestParameters: DownloadExportByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling downloadExportById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exports/{exportId}/download`.replace(`{${"exportId"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Download an export by ID
     */
    async downloadExportById(requestParameters: DownloadExportByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.downloadExportByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get the exports for an environment
     */
    async getEnvironmentExportsRaw(requestParameters: GetEnvironmentExportsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ExportsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentExports.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/exports`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportsResponseFromJSON(jsonValue));
    }

    /**
     * Get the exports for an environment
     */
    async getEnvironmentExports(requestParameters: GetEnvironmentExportsRequest, initOverrides?: RequestInit): Promise<ExportsResponse> {
        const response = await this.getEnvironmentExportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get an export using the ID
     */
    async getExportByIdRaw(requestParameters: GetExportByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Export>> {
        if (requestParameters.exportId === null || requestParameters.exportId === undefined) {
            throw new runtime.RequiredError('exportId','Required parameter requestParameters.exportId was null or undefined when calling getExportById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/exports/{exportId}`.replace(`{${"exportId"}}`, encodeURIComponent(String(requestParameters.exportId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ExportFromJSON(jsonValue));
    }

    /**
     * Get an export using the ID
     */
    async getExportById(requestParameters: GetExportByIdRequest, initOverrides?: RequestInit): Promise<Export> {
        const response = await this.getExportByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
