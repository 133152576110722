import { useState } from 'react';

import { PencilIcon } from '@dynamic-labs/sdk-react-core';
import { Webhook } from '@dynamic-labs/sdk-api';

import { EditWebhookModal } from '../EditWebhookModal';

import styles from './EditWebhookCell.module.css';

type Props = {
  webhook: Webhook;
};

export const EditWebhookCell = ({ webhook }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <button
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        className={styles['button--edit']}
        data-testid='edit-webhook-button'
      >
        <PencilIcon className='w-4 !h-4' />
      </button>
      {showModal && (
        <EditWebhookModal setShowModal={setShowModal} webhook={webhook} />
      )}
    </div>
  );
};
