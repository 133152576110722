import classNames from 'classnames';

import styles from './Card.module.scss';
import {
  CardComponent,
  CardContentComponent,
  CardHeaderComponent,
} from './Card.types';

/** Generic Card component for structuring content */
const Card: CardComponent = ({ children, className, variant = 'default' }) => (
  <div
    className={classNames(styles.card, styles[`card--${variant}`], className)}
  >
    {children}
  </div>
);

/** Pre-built Header for Card */
const Header: CardHeaderComponent = ({
  children,
  className,
  variant = 'default',
}) => (
  <div
    className={classNames(
      styles.header,
      styles[`header--${variant}`],
      className,
    )}
  >
    {children}
  </div>
);

/** Pre-built Content for Card */
const Content: CardContentComponent = ({
  children,
  className,
  variant = 'default',
}) => (
  <div
    className={classNames(
      styles.content,
      styles[`content--${variant}`],
      className,
    )}
  >
    {children}
  </div>
);

Card.Header = Header;
Card.Content = Content;

export { Card };
