import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { capitalize } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { Typography } from '../../../../components/Typography';
import { ProgressBar } from '../../../../components/ProgressBar';
import { useEnvironmentVisitorsQuery } from '../../../../hooks/useEnvironmentVisitorsQuery';

import styles from './SandboxProgress.module.css';

type Props = {
  activeStep: number;
  maxSteps: number;
};

export const SandboxProgress = ({ activeStep, maxSteps }: Props) => {
  const { activeEnvironmentType, environments } = useEnvironmentsContext();
  const { data: liveEnvironmentCounters } = useEnvironmentVisitorsQuery({
    environmentId: environments?.live.id,
  });
  const { t } = useTranslation();

  const description = useMemo(() => {
    if (
      liveEnvironmentCounters &&
      (liveEnvironmentCounters.users.count > 0 ||
        liveEnvironmentCounters.visitors.count > 0)
    ) {
      return t('overview.quick_help.onboarding.live_installed');
    }

    if (activeStep === maxSteps) {
      return t('overview.quick_help.onboarding.progress_completed');
    }

    return t('overview.quick_help.onboarding.progress_setup').replace(
      '{{env}}',
      capitalize(activeEnvironmentType),
    );
  }, [liveEnvironmentCounters, activeStep, maxSteps, t, activeEnvironmentType]);

  return (
    <div
      className={styles['sandbox-progress__container']}
      data-testid='sandbox-progress'
    >
      <ProgressBar
        variant='circle'
        totalSteps={maxSteps}
        currentStep={activeStep}
      />
      <Typography className={styles['sandbox-progress__label']}>
        {t('overview.quick_help.onboarding.progress_label')}
      </Typography>
      <Typography className={styles['sandbox-progress__status']}>
        {description}
      </Typography>
    </div>
  );
};
