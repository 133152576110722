import { useMutation, useQuery } from 'react-query';

import {
  OrganizationMfaSettings,
  OrganizationMfaSettingsResponse,
} from '@dynamic-labs/sdk-api';

import { mfaSettingsApi } from '../../../services/api';

export const useOrganizationMfaSettings = (organizationId: string) => {
  const queryKey = ['organizationId', organizationId, 'mfaSettings'];

  const { data, isLoading, refetch } =
    useQuery<OrganizationMfaSettingsResponse>(
      queryKey,
      () =>
        mfaSettingsApi.getOrganizationMfaSettings({
          organizationId,
        }),
      {
        enabled: !!organizationId,
        refetchOnWindowFocus: false,
      },
    );

  const toggleMfaSettingMutation = useMutation(
    (mfaSetting: OrganizationMfaSettings) => {
      if (mfaSetting.enabledAt) {
        return mfaSettingsApi.disableMfaDeviceForOrganization({
          mfaDeviceType: mfaSetting.mfaDeviceType,
          organizationId,
        });
      }

      return mfaSettingsApi.enableMfaDeviceForOrganization({
        mfaDeviceType: mfaSetting.mfaDeviceType,
        organizationId,
      });
    },
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    isLoading,
    mfaSettings: data?.mfaSettings ?? [],
    refetch,
    toggleMfaSettings: toggleMfaSettingMutation.mutate,
  };
};
