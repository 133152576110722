import { useTranslation } from 'react-i18next';

import { BinIcon } from '../../../../../../../../../icons';

import styles from './DeleteGateRule.module.css';

type Props = {
  onClick: () => void;
};

export const DeleteGateRule = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <button
        type='button'
        aria-label={t(
          'integrations.onboarding_and_kyc.access_control.gating.forms.delete_criteria',
        )}
        className={styles.button__delete}
        onClick={onClick}
      >
        <BinIcon />
      </button>
    </div>
  );
};
