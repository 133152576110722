import { useState, useEffect, useCallback } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { Toggle } from '../../../components/Toggle';
import Input from '../../../components/Input';
import { Typography } from '../../../components/Typography';
import { useSubscriptionLock } from '../../../hooks/useSubscriptionLock';
import { Alert } from '../../../components/Alert';
import { useSdkVersion } from '../../../hooks/useSdkVersion';

import { HCaptchaState } from './types';
import styles from './styles.module.css';

type ControlsProps = HCaptchaState;

const HCAPTCHA_MINIMUM_SDK_VERSION = '1.0.0';

export const Controls = ({ settings, setSettings, scope }: ControlsProps) => {
  const { t } = useTranslation();
  const { isOutdatedSdkVersion } = useSdkVersion(HCAPTCHA_MINIMUM_SDK_VERSION);

  const [siteKey, setSiteKey] = useState(settings?.siteKey ?? '');
  const [secretKey, setSecretKey] = useState(settings?.secretKey ?? '');

  const { shouldLockOnLive } = useSubscriptionLock(scope);

  // disable toggle if there's no siteKey or secretKet
  useEffect(() => {
    if (!siteKey || !secretKey || shouldLockOnLive) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        enabled: false,
      }));
    }
  }, [secretKey, setSettings, shouldLockOnLive, siteKey]);

  // sync local state with settings reset
  useEffect(() => {
    setSiteKey(settings?.siteKey ?? '');
    setSecretKey(settings?.secretKey ?? '');
  }, [settings?.siteKey, settings?.secretKey]);

  const debouncedSiteKey = useDebouncedCallback((newSiteKey) => {
    /* istanbul ignore next */
    if (shouldLockOnLive) {
      return;
    }
    const updatedSettings = { ...settings };
    updatedSettings.siteKey = newSiteKey;
    setSettings(updatedSettings);
  }, 750);

  const handleSiteKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      /* istanbul ignore next */
      if (shouldLockOnLive) {
        return;
      }
      setSiteKey(event.target.value);
      debouncedSiteKey(event.target.value);
    },
    [debouncedSiteKey, shouldLockOnLive],
  );

  const debouncedSecretKey = useDebouncedCallback((newSecretKey) => {
    /* istanbul ignore next */
    if (shouldLockOnLive) {
      return;
    }
    const updatedSettings = { ...settings };
    updatedSettings.secretKey = newSecretKey;
    setSettings(updatedSettings);
  }, 750);

  const handleSecretKeyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      /* istanbul ignore next */
      if (shouldLockOnLive) {
        return;
      }
      setSecretKey(event.target.value);
      debouncedSecretKey(event.target.value);
    },
    [debouncedSecretKey, shouldLockOnLive],
  );

  const handleToggleChange = useCallback(() => {
    /* istanbul ignore next */
    if (shouldLockOnLive) {
      return;
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      enabled: !prevSettings?.enabled,
    }));
  }, [setSettings, shouldLockOnLive]);

  return (
    <div className='border border-cloud-2 p-4 rounded-xl'>
      <div className='flex items-center space-x-4 justify-between flex-wrap'>
        <div className='flex space-x-4 items-center flex-wrap'>
          <div className='w-9 h-5'>
            <Toggle
              withIcon
              variant='success'
              className=''
              id='walletconnect-toggle'
              checked={settings?.enabled ?? false}
              disabled={!settings?.secretKey || !settings?.siteKey}
              handleChange={handleToggleChange}
              ariaLabel={t(
                'integrations.onboarding_and_kyc.hcaptcha.toggle.aria_label',
              )}
            />
          </div>
          <div className='text-sm'>
            {t('integrations.onboarding_and_kyc.hcaptcha.toggle.label')}
          </div>
        </div>
      </div>
      <div className='container pt-2'>
        {isOutdatedSdkVersion && (
          <Alert severity='warning' className='mb-4'>
            {t('integrations.onboarding_and_kyc.hcaptcha.warning')}
          </Alert>
        )}
        <Typography
          variant='paragraph-1'
          weight='regular'
          className={styles['note-typography-override']}
        >
          <Trans i18nKey='integrations.onboarding_and_kyc.hcaptcha.info'>
            <a
              target='blank'
              className='font-bold text-primary-1'
              href='https://www.hcaptcha.com/'
            >
              hCaptcha
            </a>
          </Trans>
        </Typography>
      </div>
      <div className='container pt-2'>
        <Input
          key='siteKey-input'
          name='siteKey'
          id='siteKey-input'
          data-testid='siteKey-input'
          label={t('integrations.onboarding_and_kyc.hcaptcha.siteKey.label')}
          value={siteKey}
          disabled={shouldLockOnLive}
          onChange={handleSiteKeyChange}
          ddHide
        />
        <Input
          key='secretKey-input'
          name='secretKey'
          id='secretKey-input'
          data-testid='secretKey-input'
          label={t('integrations.onboarding_and_kyc.hcaptcha.secretKey.label')}
          value={secretKey}
          disabled={shouldLockOnLive}
          onChange={handleSecretKeyChange}
          ddHide
        />
      </div>
    </div>
  );
};
