import { FC, useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';
import {
  EmbeddedWalletVersionEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';
import {
  Alert,
  Button,
  SpeakerphoneIcon,
  TextButton,
  Typography,
} from '@dynamic-labs/northstar';

import { SaveBanner, MissingSettingsModal } from '../../../../components';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { useOriginsContext } from '../../../../../app/context/OriginsContext';
import { useSubscriptionLock } from '../../../../../app/hooks/useSubscriptionLock';
import { useSaveProvidersSettings } from '../../../../hooks';

import { SettingsRow } from './SettingsRow';
import { EmbeddedWalletRow } from './EmbeddedWalletRow';
import { MFARow } from './MFARow';
import { useSaveDynamicSectionProvider } from './useSaveDynamicSectionProvider';
import styles from './DynamicSection.module.scss';
import { UpgradeVersionModal } from './UpgradeVersionModal';
import { DowngradeVersionModal } from './DowngradeVersionModal';

type DynamicSectionProps = {
  refetchWalletsBreakdown: VoidFunction;
};

export const DynamicSection: FC<DynamicSectionProps> = ({
  refetchWalletsBreakdown,
}) => {
  const { t } = useTranslation();
  const {
    shouldShowSaveBanner,
    handleSave,
    handleReset,
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
    missingSettings,
  } = useSaveDynamicSectionProvider();
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const [showDowngradeModal, setShowDowngradeModal] = useState<boolean>(false);

  const { activeEnvironmentType, environments, refetchEnvironments } =
    useEnvironmentsContext();
  const { originData } = useOriginsContext();
  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );
  const {
    isLoading: isSavingProjectSettings,
    mutateAsync: updateProjectSettings,
  } = useUpdateProjectSettings();
  const { enableEmbeddedWalletSessionKeyRegistration } = useFlags();
  const { isLoading: isSavingProviderSettings } = useSaveProvidersSettings();
  const [showMfaSection, setShowMfaSection] = useState<boolean>(false);
  const environmentSettings = environments?.[activeEnvironmentType];

  const noCorsOrigin =
    (!originData || originData.length === 0) &&
    activeEnvironmentType === 'live';

  const disabled = shouldLockOnLive || noCorsOrigin;

  const isLoading = isSavingProjectSettings || isSavingProviderSettings;

  const isOnV1EmbeddedWallets =
    environmentSettings?.settings?.sdk?.embeddedWallets
      ?.defaultWalletVersion === EmbeddedWalletVersionEnum.V1 &&
    !enableEmbeddedWalletSessionKeyRegistration;

  const handleOnUpdate = async (newSettings: any) => {
    await updateProjectSettings({
      environmentId: environmentSettings?.id as string,
      projectSettings: newSettings[activeEnvironmentType],
    });
    handleReset();
    await refetchEnvironments();
  };

  return (
    <>
      {showUpgradeModal && (
        <UpgradeVersionModal
          setShowModal={setShowUpgradeModal}
          onSubmit={handleOnUpdate}
        />
      )}
      {showDowngradeModal && (
        <DowngradeVersionModal
          setShowModal={setShowDowngradeModal}
          onSubmit={handleOnUpdate}
        />
      )}
      {isOnV1EmbeddedWallets && (
        <div className={styles.upgradeBanner} data-testid='upgrade-banner'>
          <Alert
            description={
              // eslint-disable-next-line react/jsx-wrap-multilines
              (
                <Typography
                  variant='paragraph-1'
                  color='gray-1'
                  weight='regular'
                >
                  <Trans
                    i18nKey='v2.page.embedded_wallets.upgrade_banner.description'
                    components={{
                      action: (
                        <TextButton
                          variant='paragraph-1'
                          text={
                            t(
                              'v2.page.embedded_wallets.upgrade_banner.action',
                            ) as any
                          }
                          onClick={() => setShowUpgradeModal(true)}
                          color='primary-1'
                        />
                      ),
                    }}
                  />
                </Typography>
              ) as unknown as string
            }
            title={t('v2.page.embedded_wallets.upgrade_banner.title')}
            variant='advance'
            IconOverride={<SpeakerphoneIcon />}
            show={isOnV1EmbeddedWallets}
          />
        </div>
      )}

      <div className={styles.wrapper}>
        <EmbeddedWalletRow
          disabled={disabled}
          refetchWalletsBreakdown={refetchWalletsBreakdown}
          setShowDowngradeModal={setShowDowngradeModal}
        />
        <SettingsRow disabled={disabled} />

        {!isOnV1EmbeddedWallets && (
          <div className={styles.showMfaSection} data-testid='show-mfa-section'>
            <Typography variant='paragraph-1' color='gray-1' weight='regular'>
              V1 wallet MFA settings for users with this feature enabled
            </Typography>
            <Button
              dataTestId='show-mfa-section-button'
              text={
                showMfaSection
                  ? t('v2.page.embedded_wallets.dynamic_section.mfa.hide')
                  : t('v2.page.embedded_wallets.dynamic_section.mfa.show')
              }
              variant='secondary'
              onClick={() => setShowMfaSection(!showMfaSection)}
            />
          </div>
        )}

        {/* Show MFA row only if silent signing for user is disabled */}
        {(isOnV1EmbeddedWallets || showMfaSection) && (
          <MFARow disabled={disabled} showV1Badge={showMfaSection} />
        )}
      </div>

      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnSave={handleSave}
            handleOnCancel={handleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {shouldShowMissingSettingsWarning && (
          <MissingSettingsModal
            handleClose={() => setShouldShowMissingSettingsWarning(false)}
            missingSettings={missingSettings}
            title={t(
              'v2.page.embedded_wallets.dynamic_section.missing_settings.title',
            )}
            description={t(
              'v2.page.embedded_wallets.dynamic_section.missing_settings.description',
            )}
          />
        )}
      </AnimatePresence>
    </>
  );
};
