import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { Invite, Organization } from '@dynamic-labs/sdk-api';

import { ROUTES } from '../../components/Navigation/data';

export const useAsyncRedirect = ({
  hasUserWithPendingMfa,
  userInvites,
  hasUserAcceptedInvitation,
  userOrganizations,
}: {
  hasUserAcceptedInvitation: boolean;
  hasUserWithPendingMfa: boolean;
  userInvites: Invite[];
  userOrganizations: Organization[];
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const shouldRedirect =
      (!userInvites?.length && hasUserAcceptedInvitation) ||
      (!userInvites?.length && !userOrganizations?.length);

    if (shouldRedirect && !hasUserWithPendingMfa) {
      navigate(ROUTES.overview);
    }
  }, [
    hasUserAcceptedInvitation,
    hasUserWithPendingMfa,
    navigate,
    userInvites?.length,
    userOrganizations?.length,
  ]);
};
