import classNames from 'classnames';
import { Item } from '@radix-ui/react-dropdown-menu';

import { DropdownMenuItemComponent } from '../../DropdownMenu.types';

export const DropdownMenuItem: DropdownMenuItemComponent = ({
  isSelected,
  className,
  children,
  onSelect,
  onDisabledClick,
  disabled,
  asChild,
}) => (
  <Item
    asChild={asChild}
    className={classNames(className)}
    onClick={!disabled ? onSelect : onDisabledClick}
    role='button'
    tabIndex={0}
    data-isselected={isSelected}
    disabled={disabled}
  >
    {children}
  </Item>
);
