import {
  CSSProperties,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useContext,
} from 'react';

import { DropdownContext } from '../dropdown-context';

import styles from './components.module.css';

interface Props {
  active?: boolean;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  style?: CSSProperties;
}

export const DropdownItem: FC<PropsWithChildren<Props>> = ({
  children,
  active,
  className,
  style,
  onClick,
}) => {
  const { setIsOpen } = useContext(DropdownContext);
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    if (onClick) onClick(e);
    setIsOpen(false);
  };

  return (
    <li>
      <button
        type='button'
        className={`${styles.item} ${active && '!bg-cloud-2'} ${className}`}
        style={style}
        onClick={handleClick}
      >
        {children}
      </button>
    </li>
  );
};

DropdownItem.defaultProps = {
  active: false,
  className: '',
  onClick: undefined,
  style: undefined,
};
