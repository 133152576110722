import { useCallback, useState } from 'react';

import { Link, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';

import { BillingSubscriptionPlanTypeEnum } from '@dynamic-labs/sdk-api';

import { Spinner } from '../../../../components/Spinner';
import { DropdownIcon } from '../../../../../icons';
import { Paper } from '../../../../components/Paper';
import { Typography } from '../../../../components/Typography';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { ConfirmModal } from '../../../../components/ConfirmModal';

import styles from './UsersCounters.module.css';

type Counter = {
  name: string;
  value: number;
};

type Props = {
  counters: Counter[];
  isFreeFeature?: boolean;
  isLoading: boolean;
  monthlyUnique: number;
  title: string;
  titleUrl: string;
  type: 'visitors' | 'users';
};

const SANDBOX_USER_LIMIT = 1000;
const FREE_USER_LIMIT = 200;
const STANDARD_USER_LIMIT = 2000;

const INFINITE = 'Unlimited';

const LIMIT_FOR_PLAN: Record<BillingSubscriptionPlanTypeEnum, string | number> =
  {
    [BillingSubscriptionPlanTypeEnum.Free]: FREE_USER_LIMIT,
    [BillingSubscriptionPlanTypeEnum.Advanced]: STANDARD_USER_LIMIT,
    [BillingSubscriptionPlanTypeEnum.Standard]: STANDARD_USER_LIMIT,
    [BillingSubscriptionPlanTypeEnum.Enterprise]: INFINITE,
  };

export const UsersCounters = ({
  title,
  counters,
  titleUrl,
  isFreeFeature = true,
  isLoading,
  monthlyUnique,
  type,
}: Props) => {
  const { t } = useTranslation();
  const { subscription, upgradeToAdvancePlan, isUpgrading } =
    useSubscriptionContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const [showUpgradePlanConfirmModal, setShowUpgradePlanConfirmModal] =
    useState(false);

  const confirmUpgradePlan = useCallback(async () => {
    await upgradeToAdvancePlan();
    setShowUpgradePlanConfirmModal(false);
  }, [upgradeToAdvancePlan, setShowUpgradePlanConfirmModal]);

  const navigate = useNavigate();

  const getLimitForPlanAndEnv = () => {
    if (type === 'visitors') {
      return INFINITE;
    }

    if (activeEnvironmentType === 'live') {
      return LIMIT_FOR_PLAN[
        subscription?.planType || BillingSubscriptionPlanTypeEnum.Free
      ];
    }

    return SANDBOX_USER_LIMIT;
  };

  const limit = getLimitForPlanAndEnv();

  const getPercentageOfUsage = () => {
    if (limit === INFINITE) {
      return undefined;
    }

    return Math.round((monthlyUnique / (limit as number)) * 100);
  };

  const getLimitTextMessage = () => {
    const planType = subscription?.planType;
    if (limit === INFINITE || !planType) {
      return undefined;
    }

    if (type === 'users' && activeEnvironmentType === 'sandbox') {
      return t('overview.configured.counters.limits.sandbox', {
        limit: SANDBOX_USER_LIMIT,
      });
    }

    if (planType === BillingSubscriptionPlanTypeEnum.Free) {
      return (
        <Trans
          i18nKey='overview.configured.counters.limits.free'
          values={{ limit: FREE_USER_LIMIT }}
        >
          You are limited to users.
          <button
            type='button'
            onClick={(e) => {
              e.stopPropagation();
              setShowUpgradePlanConfirmModal(true);
            }}
            className={styles.upgrade}
          >
            Upgrade
          </button>
          .
        </Trans>
      );
    }

    if (planType === BillingSubscriptionPlanTypeEnum.Advanced) {
      return t('overview.configured.counters.limits.advanced', {
        limit: STANDARD_USER_LIMIT,
      });
    }

    // Standard
    return t('overview.configured.counters.limits.standard', {
      limit: STANDARD_USER_LIMIT,
    });
  };

  const percentageOfUsage = getPercentageOfUsage();

  const getValueToShow = useCallback(
    (value: number, maxAmount?: number | string) => {
      if (isLoading) {
        return (
          <Typography variant='numbers' className={styles.value}>
            <Spinner />
          </Typography>
        );
      }

      if (maxAmount) {
        return (
          <span className='flex gap-1.5 flex-end'>
            <Typography variant='numbers' className={styles.value}>
              {value}
            </Typography>
            <Typography
              variant='subtitle'
              weight='medium'
              color='gray-2'
              className={styles['value--max-amount']}
            >
              / {maxAmount}
            </Typography>
          </span>
        );
      }

      return (
        <Typography variant='numbers' className={styles.value}>
          {value}
        </Typography>
      );
    },
    [isFreeFeature, isLoading, type],
  );

  const handleCardClick = () => {
    navigate(titleUrl);
  };

  return (
    <section className={styles.container}>
      <Link to={titleUrl} className={styles.link}>
        <Typography weight='bold' variant='paragraph-2'>
          {title}
        </Typography>
        <DropdownIcon className={styles['arrow-icon']} />
      </Link>
      <button type='button' onClick={handleCardClick} className={styles.card}>
        <Paper className={styles.wrapper}>
          {counters.map(({ name, value }) => (
            <div key={title} className={styles.counter}>
              <Typography
                variant='paragraph-1'
                weight='medium'
                className={styles.counter__name}
              >
                {name}
              </Typography>
              {getValueToShow(value)}
            </div>
          ))}

          <div className={styles.counter}>
            <Typography
              variant='paragraph-1'
              weight='medium'
              className={styles.counter__name}
            >
              Monthly unique {type}
            </Typography>
            {getValueToShow(monthlyUnique, limit)}

            {percentageOfUsage !== undefined && (
              <>
                <div className={styles.progress}>
                  <div
                    style={{ width: `${percentageOfUsage}%` }}
                    className={classNames(styles.progress__value, {
                      [styles['progress__value--over-limit']]:
                        percentageOfUsage > 100,
                      [styles['progress__value--error']]:
                        percentageOfUsage > 90 && percentageOfUsage <= 100,
                      [styles['progress__value--warning']]:
                        percentageOfUsage > 75 && percentageOfUsage <= 90,
                    })}
                  />
                </div>

                <Typography variant='paragraph-1' color='gray-1'>
                  {getLimitTextMessage()}
                </Typography>
              </>
            )}
          </div>
        </Paper>
      </button>

      {showUpgradePlanConfirmModal && (
        <div data-testid='confirm-modal'>
          <ConfirmModal
            title={t('subscription.upgrade_modal.title')}
            confirmationText={t('subscription.upgrade_modal.confirmBtn')}
            confirmationBtnVariant='primary'
            cancelText={t('subscription.upgrade_modal.cancelBtn')}
            message={t('subscription.upgrade_modal.content')}
            onConfirm={confirmUpgradePlan}
            onCancel={() => setShowUpgradePlanConfirmModal(false)}
            loading={isUpgrading}
          />
        </div>
      )}
    </section>
  );
};
