import { HTMLAttributes, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Typography.module.css';

export type TypographyTag =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'a'
  | 'span';

export type TypographyVariant =
  | 'title'
  | 'subtitle'
  | 'paragraph-0'
  | 'paragraph-1'
  | 'paragraph-2'
  | 'paragraph-3'
  | 'code-0'
  | 'code-1'
  | 'code-2'
  | 'anchor'
  | 'numbers';

export type TypographyWeight = 'regular' | 'medium' | 'bold';

export type TypographyColor = 'gray-1' | 'gray-2' | 'primary-1' | 'inherit';

type TypographyType = {
  as?: TypographyTag;
  children: ReactNode;
  className?: string;
  color?: TypographyColor;
  dataTestId?: string;
  ddHide?: boolean;
  variant?: TypographyVariant;
  weight?: TypographyWeight;
} & HTMLAttributes<HTMLElement>;

export const Typography = ({
  as: Tag = 'p',
  children,
  className = '',
  variant = 'paragraph-0',
  weight = 'regular',
  color,
  dataTestId,
  ddHide,
}: TypographyType) => (
  <Tag
    className={classNames(
      styles.text,
      styles[variant],
      styles[weight],
      color && styles[color],
      className,
    )}
    data-testid={dataTestId}
    data-dd-privacy={ddHide ? 'mask' : undefined}
  >
    {children}
  </Tag>
);
