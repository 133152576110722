/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameServiceSdkSettingsEvm
 */
export interface NameServiceSdkSettingsEvm {
    /**
     * Top-level ENS domain for the environment for EVM embedded wallets in this environment
     * @type {string}
     * @memberof NameServiceSdkSettingsEvm
     */
    domain: string;
}

export function NameServiceSdkSettingsEvmFromJSON(json: any): NameServiceSdkSettingsEvm {
    return NameServiceSdkSettingsEvmFromJSONTyped(json, false);
}

export function NameServiceSdkSettingsEvmFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameServiceSdkSettingsEvm {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'domain': json['domain'],
    };
}

export function NameServiceSdkSettingsEvmToJSON(value?: NameServiceSdkSettingsEvm | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'domain': value.domain,
    };
}

