import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

import { useDefaultChartOptions } from '../../hooks';

import { PieChartComponent, PieOptions } from './PieChart.types';

ChartJS.register(ArcElement, Tooltip, Legend);

ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = '500';
ChartJS.defaults.font.family = 'Roboto Mono';

export const PieChart: PieChartComponent = ({ data, options }) => {
  const effectiveOptions = useDefaultChartOptions<PieOptions>(options);

  return <Pie data={data} options={effectiveOptions} />;
};
