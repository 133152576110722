/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmailVerificationCreateResponse,
    EmailVerificationCreateResponseFromJSON,
    EmailVerificationCreateResponseFromJSONTyped,
    EmailVerificationCreateResponseToJSON,
} from './EmailVerificationCreateResponse';
import {
    NextViewEnum,
    NextViewEnumFromJSON,
    NextViewEnumFromJSONTyped,
    NextViewEnumToJSON,
} from './NextViewEnum';
import {
    SdkUser,
    SdkUserFromJSON,
    SdkUserFromJSONTyped,
    SdkUserToJSON,
} from './SdkUser';
import {
    SmsVerificationCreateResponse,
    SmsVerificationCreateResponseFromJSON,
    SmsVerificationCreateResponseFromJSONTyped,
    SmsVerificationCreateResponseToJSON,
} from './SmsVerificationCreateResponse';
import {
    UpdateSelfResponseAllOf,
    UpdateSelfResponseAllOfFromJSON,
    UpdateSelfResponseAllOfFromJSONTyped,
    UpdateSelfResponseAllOfToJSON,
} from './UpdateSelfResponseAllOf';
import {
    VerifyResponse,
    VerifyResponseFromJSON,
    VerifyResponseFromJSONTyped,
    VerifyResponseToJSON,
} from './VerifyResponse';

/**
 * 
 * @export
 * @interface UpdateSelfResponse
 */
export interface UpdateSelfResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateSelfResponse
     */
    mfaToken?: string;
    /**
     * Encoded JWT token. This will only be returned when cookie-based authentication is disabled in favor of standard Auth header based authentication.
     * @type {string}
     * @memberof UpdateSelfResponse
     */
    jwt?: string;
    /**
     * 
     * @type {SdkUser}
     * @memberof UpdateSelfResponse
     */
    user: SdkUser;
    /**
     * Encoded JWT token. This will only be returned when cookie-based authentication is disabled in favor of standard Auth header based authentication.
     * @type {string}
     * @memberof UpdateSelfResponse
     */
    minifiedJwt?: string;
    /**
     * Format is a unix-based timestamp. When set, this will be the expiration timestamp on the JWT sent using either the `jwt` field or a response httpOnly cookie set by the server.
     * @type {number}
     * @memberof UpdateSelfResponse
     */
    expiresAt: number;
    /**
     * 
     * @type {NextViewEnum}
     * @memberof UpdateSelfResponse
     */
    nextView: NextViewEnum;
    /**
     * 
     * @type {EmailVerificationCreateResponse}
     * @memberof UpdateSelfResponse
     */
    emailVerification?: EmailVerificationCreateResponse;
    /**
     * 
     * @type {SmsVerificationCreateResponse}
     * @memberof UpdateSelfResponse
     */
    smsVerification?: SmsVerificationCreateResponse;
}

export function UpdateSelfResponseFromJSON(json: any): UpdateSelfResponse {
    return UpdateSelfResponseFromJSONTyped(json, false);
}

export function UpdateSelfResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSelfResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfaToken': !exists(json, 'mfaToken') ? undefined : json['mfaToken'],
        'jwt': !exists(json, 'jwt') ? undefined : json['jwt'],
        'user': SdkUserFromJSON(json['user']),
        'minifiedJwt': !exists(json, 'minifiedJwt') ? undefined : json['minifiedJwt'],
        'expiresAt': json['expiresAt'],
        'nextView': NextViewEnumFromJSON(json['nextView']),
        'emailVerification': !exists(json, 'emailVerification') ? undefined : EmailVerificationCreateResponseFromJSON(json['emailVerification']),
        'smsVerification': !exists(json, 'smsVerification') ? undefined : SmsVerificationCreateResponseFromJSON(json['smsVerification']),
    };
}

export function UpdateSelfResponseToJSON(value?: UpdateSelfResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfaToken': value.mfaToken,
        'jwt': value.jwt,
        'user': SdkUserToJSON(value.user),
        'minifiedJwt': value.minifiedJwt,
        'expiresAt': value.expiresAt,
        'nextView': NextViewEnumToJSON(value.nextView),
        'emailVerification': EmailVerificationCreateResponseToJSON(value.emailVerification),
        'smsVerification': SmsVerificationCreateResponseToJSON(value.smsVerification),
    };
}

