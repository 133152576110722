/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FrameworkSettings,
    FrameworkSettingsFromJSON,
    FrameworkSettingsFromJSONTyped,
    FrameworkSettingsToJSON,
} from './FrameworkSettings';

/**
 * 
 * @export
 * @interface SdkSettingsRequest
 */
export interface SdkSettingsRequest {
    /**
     * 
     * @type {object}
     * @memberof SdkSettingsRequest
     */
    dynamicContextProps?: object;
    /**
     * 
     * @type {object}
     * @memberof SdkSettingsRequest
     */
    dynamicWagmiSettings?: object;
    /**
     * 
     * @type {FrameworkSettings}
     * @memberof SdkSettingsRequest
     */
    frameworkSettings?: FrameworkSettings;
}

export function SdkSettingsRequestFromJSON(json: any): SdkSettingsRequest {
    return SdkSettingsRequestFromJSONTyped(json, false);
}

export function SdkSettingsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkSettingsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dynamicContextProps': !exists(json, 'dynamicContextProps') ? undefined : json['dynamicContextProps'],
        'dynamicWagmiSettings': !exists(json, 'dynamicWagmiSettings') ? undefined : json['dynamicWagmiSettings'],
        'frameworkSettings': !exists(json, 'frameworkSettings') ? undefined : FrameworkSettingsFromJSON(json['frameworkSettings']),
    };
}

export function SdkSettingsRequestToJSON(value?: SdkSettingsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dynamicContextProps': value.dynamicContextProps,
        'dynamicWagmiSettings': value.dynamicWagmiSettings,
        'frameworkSettings': FrameworkSettingsToJSON(value.frameworkSettings),
    };
}

