import { useMemo, useState } from 'react';

import { t } from 'i18next';

import { UpsellBanner } from '../../components/UpsellBanner';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { FullScope } from '../../data/subscription';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';

import styles from './Analytics.module.css';
import { AnalyticsComponent, DateSelection } from './Analytics.types';
import {
  AverageDailySessionsWidget,
  DailyUsersBarChartWidget,
  DateSelectionTabs,
  SessionsWidget,
  SmallWidgetView,
  UniqueUsersWidget,
  WalletBreakdownStatisticsWidget,
} from './components';
import { useValidDateSelection } from './hooks';
import { createDateSelectionForPastXDays } from './utils';
import { LockedDailyUsersBarChartWidget } from './components/LockedDailyUsersBarChartWidget';
import { LockedWalletBreakdownPieChartWidget } from './components/LockedWalletBreakdownPieChartWidget';

export const Analytics: AnalyticsComponent = () => {
  const initialDateSelection = createDateSelectionForPastXDays('30d', {
    days: 30,
  });

  const scope = FullScope.AnalyticsUsers;

  const { activeEnvironment } = useEnvironmentsContext();
  const { subscription } = useSubscriptionContext();
  const { shouldLockOnLive, shouldShowTrialBanner } =
    useSubscriptionLock(scope);

  const [dateSelection, setDateSelection] =
    useState<DateSelection>(initialDateSelection);

  const validDateSelection = useValidDateSelection(
    dateSelection,
    initialDateSelection,
  );

  const environmentId = useMemo(
    () => activeEnvironment?.id,
    [activeEnvironment],
  );

  if (!environmentId) return null;

  return (
    <div className={styles.container}>
      {shouldShowTrialBanner && (
        <UpsellBanner daysLeft={subscription?.trialDaysLeft} />
      )}

      {!shouldLockOnLive && (
        <DateSelectionTabs value={dateSelection} onChange={setDateSelection} />
      )}

      <div className={styles['widgets-row']}>
        {shouldLockOnLive ? (
          <>
            <SmallWidgetView
              disabled
              title={t<string>('analytics.widgets.average_daily_session.title')}
              value='N/A'
            />
            <SmallWidgetView
              disabled
              title={t<string>('analytics.widgets.sessions.title')}
              value='N/A'
            />
            <SmallWidgetView
              disabled
              title={t<string>('analytics.widgets.unique_users.title')}
              value='N/A'
            />
          </>
        ) : (
          <>
            <AverageDailySessionsWidget
              environmentId={environmentId}
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
            <SessionsWidget
              environmentId={environmentId}
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
            <UniqueUsersWidget
              environmentId={environmentId}
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
          </>
        )}
      </div>

      <div className={styles['widgets-grid']}>
        {shouldLockOnLive ? (
          <>
            <LockedDailyUsersBarChartWidget
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
            <LockedWalletBreakdownPieChartWidget />
          </>
        ) : (
          <>
            <DailyUsersBarChartWidget
              environmentId={environmentId}
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
            <WalletBreakdownStatisticsWidget
              environmentId={environmentId}
              startDate={validDateSelection.start}
              endDate={validDateSelection.end}
            />
          </>
        )}
      </div>
    </div>
  );
};
