/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum GateRuleType {
    Nft = 'nft',
    Token = 'token'
}

export function GateRuleTypeFromJSON(json: any): GateRuleType {
    return GateRuleTypeFromJSONTyped(json, false);
}

export function GateRuleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GateRuleType {
    return json as GateRuleType;
}

export function GateRuleTypeToJSON(value?: GateRuleType | null): any {
    return value as any;
}

