import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import {
  BooleanInput,
  DropdownMenu,
  DropdownMenuDropdownWrapper,
  OpacityAnimation,
  SlideAnimation,
  Tag,
  Typography,
} from '@dynamic-labs/northstar';

import {
  dynamicSupportedCountries,
  supportedCountries,
  CountryData,
  CountryPhoneData,
} from '../../../../../../../../../app/routes/Configurations/Providers/components/SmsProviderSection/utils/countries';
import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';

import styles from './CountriesDropdown.module.scss';

export const CountriesDropdown: FC = () => {
  const { t } = useTranslation();

  const { getProviderValue, onChangeProvider } = useProvidersContext();

  const useDynamicCredentials = Boolean(
    !getProviderValue(ProviderEnum.Sms, 'clientId') ||
      !getProviderValue(ProviderEnum.Sms, 'clientSecret') ||
      !getProviderValue(ProviderEnum.Sms, 'accountSid') ||
      !getProviderValue(ProviderEnum.Sms, 'twilioNumber'),
  );

  const enabledCountries =
    getProviderValue(ProviderEnum.Sms, 'enabledCountries') || [];

  const handleChangeCountries = (
    countryCode: string,
    countryData: CountryData,
    checked: boolean,
  ) => {
    const updatedCountriesList = enabledCountries.filter(
      (country) => country.isoCountryCode !== countryCode,
    );

    if (checked) {
      updatedCountriesList.push({
        isoCountryCode: countryCode,
        phoneCountryCode: countryData.code,
      });
    }

    onChangeProvider(
      ProviderEnum.Sms,
      'enabledCountries',
      updatedCountriesList,
    );
  };

  const countries: Partial<CountryPhoneData> = useDynamicCredentials
    ? Object.fromEntries(
        dynamicSupportedCountries.map((code) => [
          code,
          supportedCountries[code],
        ]),
      )
    : supportedCountries;

  const sortedCountries = Object.entries(countries).sort(([, a], [, b]) =>
    a.name.localeCompare(b.name),
  );

  const allCountriesChecked =
    enabledCountries.length === sortedCountries.length;

  const handleSelectAllChanged = (checked: boolean) => {
    if (!checked) {
      onChangeProvider(ProviderEnum.Sms, 'enabledCountries', []);
      return;
    }

    const allCountries = sortedCountries.map(([countryCode, countryData]) => ({
      isoCountryCode: countryCode,
      phoneCountryCode: countryData.code,
    }));

    onChangeProvider(ProviderEnum.Sms, 'enabledCountries', allCountries);
  };

  const namesOfEnabledCountries = enabledCountries.map(
    (country) =>
      sortedCountries.find(([code]) => code === country.isoCountryCode)?.[1]
        .name as string,
  );

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-3' weight='medium' color='gray-1'>
        {t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.header',
        )}
      </Typography>

      <div className={styles.wrapper}>
        <div className={styles['enabled-countries']}>
          <Typography variant='paragraph-1' weight='bold' color='black-2'>
            {t(
              'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.enabled',
            )}
          </Typography>

          {namesOfEnabledCountries.map((country) => (
            <Tag color='transparent' size='large' text={country} />
          ))}
        </div>

        <DropdownMenu
          placeholderText={t(
            'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.dropdown.placeholder',
          )}
          itemsWrapper={CountriesDropdownItemsWrapper}
          itemsSideOffset={8}
          className={styles.dropdownMenu}
          options={[
            <DropdownMenu.Item value='selectAll' rawOption='selectAll' asChild>
              <BooleanInput
                checked={allCountriesChecked}
                id='select-all'
                onChange={(e) => handleSelectAllChanged(e.target.checked)}
                type='checkbox'
                isSaved
                label={t(
                  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.dropdown.select_all',
                )}
              />
            </DropdownMenu.Item>,
            ...sortedCountries.map(([countryCode, countryData]) => (
              <DropdownMenu.Item
                value={`${countryData.code}-${countryData.name}`}
                rawOption={countryData}
                asChild
              >
                <BooleanInput
                  checked={
                    !!enabledCountries.find(
                      (country) => country.isoCountryCode === countryCode,
                    )
                  }
                  id={`${countryData.code}-${countryData.name}`}
                  onChange={(e) => {
                    handleChangeCountries(
                      countryCode,
                      countryData,
                      e.target.checked,
                    );
                  }}
                  type='checkbox'
                  isSaved
                  label={countryData.name}
                />
              </DropdownMenu.Item>
            )),
          ]}
          zIndex={1000}
        />

        <Typography variant='paragraph-2' weight='bold' color='black-2'>
          {t(
            'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.title',
          )}
        </Typography>

        <Typography variant='paragraph-2' weight='regular' color='gray-1'>
          {t(
            'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.countries.description',
          )}
        </Typography>
      </div>
    </div>
  );
};

export const CountriesDropdownItemsWrapper: DropdownMenuDropdownWrapper = ({
  children,
  targetWidth,
}) => (
  <OpacityAnimation>
    <SlideAnimation startPosition='down' endPosition='down'>
      <div className={styles.dropdown} style={{ width: targetWidth }}>
        {children}
      </div>
    </SlideAnimation>
  </OpacityAnimation>
);
