/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderMultichainAccountAbstractionProviders
 */
export interface ProviderMultichainAccountAbstractionProviders {
    /**
     * 
     * @type {string}
     * @memberof ProviderMultichainAccountAbstractionProviders
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderMultichainAccountAbstractionProviders
     */
    chain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderMultichainAccountAbstractionProviders
     */
    _default?: boolean;
}

export function ProviderMultichainAccountAbstractionProvidersFromJSON(json: any): ProviderMultichainAccountAbstractionProviders {
    return ProviderMultichainAccountAbstractionProvidersFromJSONTyped(json, false);
}

export function ProviderMultichainAccountAbstractionProvidersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderMultichainAccountAbstractionProviders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'chain': json['chain'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
    };
}

export function ProviderMultichainAccountAbstractionProvidersToJSON(value?: ProviderMultichainAccountAbstractionProviders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'chain': value.chain,
        'default': value._default,
    };
}

