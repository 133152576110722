/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MFADeviceType,
    MFADeviceTypeFromJSON,
    MFADeviceTypeFromJSONTyped,
    MFADeviceTypeToJSON,
} from './MFADeviceType';

/**
 * 
 * @export
 * @interface OrganizationMfaSettings
 */
export interface OrganizationMfaSettings {
    /**
     * 
     * @type {Date}
     * @memberof OrganizationMfaSettings
     */
    enabledAt?: Date | null;
    /**
     * 
     * @type {MFADeviceType}
     * @memberof OrganizationMfaSettings
     */
    mfaDeviceType: MFADeviceType;
}

export function OrganizationMfaSettingsFromJSON(json: any): OrganizationMfaSettings {
    return OrganizationMfaSettingsFromJSONTyped(json, false);
}

export function OrganizationMfaSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMfaSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (json['enabledAt'] === null ? null : new Date(json['enabledAt'])),
        'mfaDeviceType': MFADeviceTypeFromJSON(json['mfaDeviceType']),
    };
}

export function OrganizationMfaSettingsToJSON(value?: OrganizationMfaSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt === null ? null : value.enabledAt.toISOString()),
        'mfaDeviceType': MFADeviceTypeToJSON(value.mfaDeviceType),
    };
}

