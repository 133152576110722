import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard, Typography } from '@dynamic-labs/northstar';

import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { AdminIcon } from '../../../../../../../icons';
import styles from '../../BrandedWalletsSection/WalletConnect/WalletConnect.module.scss';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { ChangedSettingsIndicator } from '../../../../../EmbeddedWalletsPage/Sections/shared';

type MultiAssetProps = {
  disabled: boolean;
};

type ToggleField = 'enableMultiAsset' | 'showFiat';

export const MultiAsset: FC<MultiAssetProps> = ({ disabled }) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.LogInMethodsMultiAsset,
  });

  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isSettingEnabled = (type: ToggleField) =>
    Boolean(settings[activeEnvironmentType].sdk?.[type]) && !disabled;

  const isSettingSaved = (type: ToggleField) =>
    Boolean(
      initialSettings[activeEnvironmentType].sdk?.[type] ===
        isSettingEnabled(type),
    );

  const handleToggle = (
    e: ChangeEvent<HTMLInputElement>,
    type: ToggleField,
  ) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          [type]: e.target.checked,
        },
      },
    });
  };

  return (
    <>
      <SingleToggleCard
        accordionKey='multiAsset'
        title={t('v2.page.log_in_methods.design.multiAsset.title')}
        description={t('v2.page.log_in_methods.design.multiAsset.description')}
        allowExpand={false}
        onCardClick={
          isSettingEnabled('enableMultiAsset')
            ? () => handleShowModal(true)
            : undefined
        }
        inputProps={{
          checked: isSettingEnabled('enableMultiAsset'),
          disabled,
          id: 'multiAsset',
          isSaved: isSettingSaved('enableMultiAsset'),
          onChange: (e) => handleToggle(e, 'enableMultiAsset'),
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        RightSideTags={
          isSettingEnabled('enableMultiAsset') && isSettingSaved('showFiat')
            ? [
                {
                  Tag: <ChangedSettingsIndicator amount={1} />,
                  id: 'showFiat',
                },
              ]
            : undefined
        }
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && isSettingEnabled('enableMultiAsset')}
        title={t('v2.page.log_in_methods.design.multiAsset.drawer.title')}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t(
                'v2.page.log_in_methods.design.multiAsset.drawer.additional_settings',
              )}
            </Typography>

            <SingleToggleCard
              title={t(
                'v2.page.log_in_methods.design.multiAsset.drawer.showFiat.title',
              )}
              description={t(
                'v2.page.log_in_methods.design.multiAsset.drawer.showFiat.description',
              )}
              accordionKey='showFiat'
              inputProps={{
                checked: isSettingEnabled('showFiat'),
                disabled,
                id: 'showFiat',
                isSaved: isSettingSaved('showFiat'),
                onChange: (e) => handleToggle(e, 'showFiat'),
                type: 'toggle',
              }}
              allowExpand={false}
            />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
