import { FC, useEffect, useState } from 'react';

import { Root, Item } from '@radix-ui/react-accordion';
import { useLocation } from 'react-router-dom';

import { Typography } from '../../Typography';
import { MenuItem } from '../MenuItem';
import { AccordionContent } from '../../AccordionContent';
import { type SectionProps } from '../SidebarNavigation.types';

import styles from './Section.module.scss';

export const Section: FC<SectionProps> = ({
  title,
  items,
  disabled,
  onDisabledClick,
}) => {
  const [expandedFields, setExpandedFields] = useState<string[]>([]);

  const location = useLocation();

  const handleSetExpandedFieldFromChild = (valueToSave: string) => {
    setExpandedFields((prev) => {
      if (prev.includes(valueToSave)) {
        return prev.filter((item) => item !== valueToSave);
      }

      return [...prev, valueToSave];
    });
  };

  // on page refresh if there is a match it should expand the section
  useEffect(() => {
    items.forEach((item) => {
      if (location.pathname.includes(item.linkTo)) {
        setExpandedFields([...expandedFields, item.linkTo]);
      }
    });
  }, []);

  return (
    <Root
      type='multiple'
      className={styles.section}
      value={expandedFields}
      onValueChange={setExpandedFields}
    >
      {items.length > 0 && (
        <>
          <Typography
            variant='paragraph-0'
            weight='bold'
            className={styles.title}
            color='gray-2'
          >
            {title}
          </Typography>

          {items.map((item) => (
            <Item value={item.linkTo} key={item.linkTo}>
              <MenuItem
                key={item.linkTo}
                expandable={item.subItems && item.subItems.length > 0}
                linkTo={item.linkTo}
                text={item.text}
                icon={item.icon}
                handleSetExpandedField={handleSetExpandedFieldFromChild}
                disabled={disabled}
                onDisabledClick={onDisabledClick}
              />

              {item.subItems && item.subItems.length > 0 && (
                <AccordionContent
                  animationDurationMS={200}
                  numItems={item.subItems.length}
                >
                  <div className={styles.contentWrapper}>
                    {item.subItems.map((subItem) => (
                      <MenuItem
                        key={subItem.linkTo}
                        expandable={false}
                        linkTo={subItem.linkTo}
                        text={subItem.text}
                        isSubItem
                        disabled={disabled}
                        onDisabledClick={onDisabledClick}
                      />
                    ))}
                  </div>
                </AccordionContent>
              )}
            </Item>
          ))}
        </>
      )}
    </Root>
  );
};
