import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type DropdownMenuContextValue = {
  open: boolean;
  setOpen: (open: boolean) => void;
  setSubMenuOpenId: (id: string | undefined) => void;
  subMenuOpenId: string | undefined;
};

export const DropdownMenuContext = createContext<
  DropdownMenuContextValue | undefined
>(undefined);

type DropdownMenuContextProviderProps = {
  children: ReactNode;
};

export const DropdownMenuContextProvider = ({
  children,
}: DropdownMenuContextProviderProps) => {
  const [open, setOpen] = useState(false);
  const [subMenuOpenId, setSubMenuOpenId] = useState<string | undefined>(
    undefined,
  );

  useEffect(() => {
    if (!open) {
      setSubMenuOpenId(undefined);
    }
  }, [open]);

  const value = useMemo(
    () => ({
      open,
      setOpen,
      setSubMenuOpenId,
      subMenuOpenId,
    }),
    [open, subMenuOpenId],
  );

  return (
    <DropdownMenuContext.Provider value={value}>
      {children}
    </DropdownMenuContext.Provider>
  );
};

export const useDropdownMenuContext = () => {
  const value = useContext(DropdownMenuContext);
  if (value === undefined) {
    throw new Error(
      'useDropdownMenuContext called without being wrapped in DropdownMenuContextProvider',
    );
  }

  return value;
};
