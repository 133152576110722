import { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  CustomerInformationIcon,
  SingleToggleCard,
} from '@dynamic-labs/northstar';

import { TestAccountFieldValues } from '../TestAccountFields';

/** Toggle component for enabling Test Accounts */
export const TestAccountToggle = ({
  testAccountActive,
}: {
  testAccountActive: boolean;
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<TestAccountFieldValues>();

  const handleToggleChange = useCallback(() => {
    const newEnabledValue = !values.enabled;
    setFieldValue('enabled', newEnabledValue);
  }, [setFieldValue, values]);

  return (
    <SingleToggleCard
      accordionKey='test_accounts'
      title={t('test_accounts.toggle.title')}
      allowExpand={false}
      inputProps={{
        checked: values.enabled,
        id: 'testAccountsToggle',
        isSaved: values.enabled === testAccountActive,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
      Icon={<CustomerInformationIcon />}
    />
  );
};
