import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetInvitesHook } from './useGetInvites.types';

export const useGetInvites: UseGetInvitesHook = ({ status }, options) => {
  const { invitesApi } = useRedcoastQueryContext();

  return useQuery(
    ['invitesApi', 'getInvites', status],
    async () => invitesApi.getInvites({ status }),
    options,
  );
};
