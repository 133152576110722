/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenBalance
 */
export interface TokenBalance {
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    networkId?: number;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof TokenBalance
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof TokenBalance
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof TokenBalance
     */
    symbol: string;
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    decimals: number;
    /**
     * 
     * @type {string}
     * @memberof TokenBalance
     */
    logoURI: string;
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    balance: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    rawBalance: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof TokenBalance
     */
    marketValue?: number;
}

export function TokenBalanceFromJSON(json: any): TokenBalance {
    return TokenBalanceFromJSONTyped(json, false);
}

export function TokenBalanceFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenBalance {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'networkId': !exists(json, 'networkId') ? undefined : json['networkId'],
        'address': json['address'],
        'name': json['name'],
        'symbol': json['symbol'],
        'decimals': json['decimals'],
        'logoURI': json['logoURI'],
        'balance': json['balance'],
        'rawBalance': json['rawBalance'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'marketValue': !exists(json, 'marketValue') ? undefined : json['marketValue'],
    };
}

export function TokenBalanceToJSON(value?: TokenBalance | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'networkId': value.networkId,
        'address': value.address,
        'name': value.name,
        'symbol': value.symbol,
        'decimals': value.decimals,
        'logoURI': value.logoURI,
        'balance': value.balance,
        'rawBalance': value.rawBalance,
        'price': value.price,
        'marketValue': value.marketValue,
    };
}

