/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NameService,
    NameServiceFromJSON,
    NameServiceFromJSONTyped,
    NameServiceToJSON,
} from './NameService';
import {
    NativeCurrency,
    NativeCurrencyFromJSON,
    NativeCurrencyFromJSONTyped,
    NativeCurrencyToJSON,
} from './NativeCurrency';

/**
 * 
 * @export
 * @interface NetworkConfiguration
 */
export interface NetworkConfiguration {
    /**
     * A light client, compared to a full node, tracks only pieces of certain information on a blockchain. Light clients do not track the entire state of a blockchain and also do not contain every transaction/block of a chain.
     * @type {string}
     * @memberof NetworkConfiguration
     */
    lcdUrl?: string;
    /**
     * [Deprecated] use `name` property instead
     * @type {string}
     * @memberof NetworkConfiguration
     */
    chainName?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    shortName: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    chain: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    chainId: string;
    /**
     * 
     * @type {NameService}
     * @memberof NetworkConfiguration
     */
    nameService?: NameService;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    networkId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkConfiguration
     */
    iconUrls: Array<string>;
    /**
     * 
     * @type {NativeCurrency}
     * @memberof NetworkConfiguration
     */
    nativeCurrency: NativeCurrency;
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkConfiguration
     */
    rpcUrls: Array<string>;
    /**
     * Contains the client private RPC urls
     * @type {Array<string>}
     * @memberof NetworkConfiguration
     */
    privateCustomerRpcUrls?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkConfiguration
     */
    blockExplorerUrls: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    vanityName?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkConfiguration
     */
    bech32Prefix?: string;
}

export function NetworkConfigurationFromJSON(json: any): NetworkConfiguration {
    return NetworkConfigurationFromJSONTyped(json, false);
}

export function NetworkConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): NetworkConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lcdUrl': !exists(json, 'lcdUrl') ? undefined : json['lcdUrl'],
        'chainName': !exists(json, 'chainName') ? undefined : json['chainName'],
        'name': json['name'],
        'shortName': json['shortName'],
        'chain': json['chain'],
        'chainId': json['chainId'],
        'nameService': !exists(json, 'nameService') ? undefined : NameServiceFromJSON(json['nameService']),
        'networkId': json['networkId'],
        'iconUrls': json['iconUrls'],
        'nativeCurrency': NativeCurrencyFromJSON(json['nativeCurrency']),
        'rpcUrls': json['rpcUrls'],
        'privateCustomerRpcUrls': !exists(json, 'privateCustomerRpcUrls') ? undefined : json['privateCustomerRpcUrls'],
        'blockExplorerUrls': json['blockExplorerUrls'],
        'vanityName': !exists(json, 'vanityName') ? undefined : json['vanityName'],
        'bech32Prefix': !exists(json, 'bech32Prefix') ? undefined : json['bech32Prefix'],
    };
}

export function NetworkConfigurationToJSON(value?: NetworkConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lcdUrl': value.lcdUrl,
        'chainName': value.chainName,
        'name': value.name,
        'shortName': value.shortName,
        'chain': value.chain,
        'chainId': value.chainId,
        'nameService': NameServiceToJSON(value.nameService),
        'networkId': value.networkId,
        'iconUrls': value.iconUrls,
        'nativeCurrency': NativeCurrencyToJSON(value.nativeCurrency),
        'rpcUrls': value.rpcUrls,
        'privateCustomerRpcUrls': value.privateCustomerRpcUrls,
        'blockExplorerUrls': value.blockExplorerUrls,
        'vanityName': value.vanityName,
        'bech32Prefix': value.bech32Prefix,
    };
}

