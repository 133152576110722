/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Webhook
 */
export interface Webhook {
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    webhookId: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    secret?: string;
    /**
     * 
     * @type {string}
     * @memberof Webhook
     */
    version?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Webhook
     */
    events: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Webhook
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Webhook
     */
    enabledAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Webhook
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Webhook
     */
    updatedAt: Date;
}

export function WebhookFromJSON(json: any): Webhook {
    return WebhookFromJSONTyped(json, false);
}

export function WebhookFromJSONTyped(json: any, ignoreDiscriminator: boolean): Webhook {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environmentId': json['environmentId'],
        'webhookId': json['webhookId'],
        'url': json['url'],
        'secret': !exists(json, 'secret') ? undefined : json['secret'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'events': json['events'],
        'isEnabled': json['isEnabled'],
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (new Date(json['enabledAt'])),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
    };
}

export function WebhookToJSON(value?: Webhook | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environmentId': value.environmentId,
        'webhookId': value.webhookId,
        'url': value.url,
        'secret': value.secret,
        'version': value.version,
        'events': value.events,
        'isEnabled': value.isEnabled,
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': (value.updatedAt.toISOString()),
    };
}

