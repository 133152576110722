import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button, Typography } from '@dynamic-labs/northstar';

import Header from '../../../app/components/Portal/Header';
import Portal from '../../../app/components/Portal';
import { ROUTES } from '../../../app/components/Navigation/data';

import styles from './AddPaymentMethodInfoModal.module.scss';

type AddPaymentMethodInfoModalProps = {
  handleClose: VoidFunction;
};

export const AddPaymentMethodInfoModal: FC<AddPaymentMethodInfoModalProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleAddPaymentMethod = () => {
    handleClose();
    navigate(ROUTES.adminSubscriptionTab);
  };

  return (
    <Portal
      handleClose={handleClose}
      className={styles.portal}
      backdropClassName={styles.backdrop}
    >
      <Header
        handleClose={handleClose}
        headingText={t('v2.component.add_payment_method_info_modal.title')}
      />

      <div className={styles.content}>
        <Typography variant='subtitle'>
          {t('v2.component.add_payment_method_info_modal.subtitle')}
        </Typography>

        <Typography variant='paragraph-2' weight='regular'>
          {t('v2.component.add_payment_method_info_modal.content')}
        </Typography>
      </div>

      <Button
        text={t('v2.component.add_payment_method_info_modal.cta')}
        size='large'
        onClick={handleAddPaymentMethod}
        className={styles.cta}
      />
    </Portal>
  );
};
