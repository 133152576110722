import React, { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { MultiSection, SingleToggleCard } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { BloctoIcon } from '@dynamic-labs/iconic';

import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';

type BloctoRowProps = {
  disabled: boolean;
  refetchWalletsBreakdown: VoidFunction;
};

export const BloctoRow: FC<BloctoRowProps> = ({
  disabled,
  refetchWalletsBreakdown,
}) => {
  const { t } = useTranslation();

  const { getProviderValue, hasProviderChanged, toggleProvider } =
    useProvidersContext();

  const isBloctoEnabled = Boolean(
    getProviderValue(ProviderEnum.Blocto, 'enabledAt'),
  );

  const isBloctoSaved = Boolean(!hasProviderChanged(ProviderEnum.Blocto));

  const handleBloctoToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    toggleProvider(ProviderEnum.Blocto, event.target.checked);
    refetchWalletsBreakdown();
  };

  return (
    <MultiSection.Item
      title={t('v2.page.embedded_wallets.third_party.blocto.title')}
      description={t('v2.page.embedded_wallets.third_party.blocto.description')}
    >
      <SingleToggleCard
        accordionKey='blocto'
        title={t('v2.page.embedded_wallets.third_party.blocto.toggle')}
        Icon={<BloctoIcon />}
        allowExpand={false}
        inputProps={{
          checked: isBloctoEnabled,
          disabled,
          id: 'blocto',
          isSaved: isBloctoSaved,
          onChange: handleBloctoToggleChange,
          type: 'toggle',
        }}
      />
    </MultiSection.Item>
  );
};
