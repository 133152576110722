import { Formik, Form, Field } from 'formik';
import { object, string } from 'yup';
import { useTranslation, Trans } from 'react-i18next';

import { logger } from '../../../../services/logger';
import Button from '../../../../components/Button';
import { ErrorInfo } from '../../../../components/ErrorInfo';
import { Alert } from '../../../../components/Alert';
import Portal from '../../../../components/Portal';
import Input from '../../../../components/Input';
import { originsApi } from '../../../../services/api';
import { NEW_ORIGIN_REGEXP } from '../../../../utils/constants/regexp';
import { addOrigin } from '../helpers/addOrigin';
import { useOriginsContext } from '../../../../context/OriginsContext';

import styles from './AddOriginModal.module.css';

type Props = {
  environmentId: string;
  handleClose: VoidFunction;
};

type FormValues = {
  origin: string;
};

export const AddOriginModal = ({
  handleClose,
  environmentId,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const { originData, setOrigins } = useOriginsContext();
  const onSubmit = async (values: FormValues) => {
    try {
      if (values.origin.endsWith('/')) {
        values.origin = values.origin.slice(0, -1);
      }

      const response = await originsApi.addOrigin({
        environmentId,
        inlineObject: {
          origin: values.origin,
        },
      });
      const origins = addOrigin(response.origin, originData);
      setOrigins(origins);
    } catch (e) {
      logger.error(e);
    } finally {
      handleClose();
    }
  };

  const schema = object({
    origin: string()
      .required(t('settings_page.security.cors.add_origin.errors.required'))
      .matches(
        NEW_ORIGIN_REGEXP,
        t('settings_page.security.cors.add_origin.errors.regexp'),
      ),
  });

  return (
    <Portal handleClose={handleClose} className={styles.portal}>
      <div className={styles.heading}>
        {t('settings_page.security.cors.add_origin.heading')}
      </div>
      <Formik
        onSubmit={onSubmit}
        initialValues={{ origin: '' }}
        validationSchema={schema}
        validateOnChange={false}
      >
        {({ errors }) => (
          <>
            {errors.origin && (
              <ErrorInfo
                title={t('settings_page.security.cors.add_origin.errors.title')}
                className={styles.error}
                message={errors.origin}
              />
            )}
            <Form>
              <Field
                as={Input}
                name='origin'
                id='origin'
                label={t(
                  'settings_page.security.cors.add_origin.input_placeholder',
                )}
              />
              <Alert severity='warning'>
                <UrlInfo />
              </Alert>
              <div className={styles.buttons}>
                <Button onClick={handleClose} variant='secondary'>
                  {t('settings_page.security.cors.add_origin.buttons.cancel')}
                </Button>
                <Button type='submit'>
                  {t('settings_page.security.cors.add_origin.buttons.add')}
                </Button>
              </div>
            </Form>
          </>
        )}
      </Formik>
    </Portal>
  );
};

const UrlInfo = () => {
  const { t } = useTranslation();
  return (
    <Trans
      i18nKey='settings_page.security.cors.add_origin.info'
      style={{ 'white-space': 'pre-wrap' }}
    >
      Origin must be a valid
      <a
        className={styles['rfc-url']}
        href={t('settings_page.security.cors.add_origin.origin_docs_url')}
      >
        Origin
      </a>
      . You may use a wildcard (*) in any part of your domain to represent 0 or
      more characters (a-z, 0-9, -, .).
      <>
        <br />
        <br />
      </>
      For additional guidance and examples, check out the
      <a
        className={styles['rfc-url']}
        href={t('settings_page.security.cors.add_origin.dynamic_cors_docs_url')}
      >
        documentation
      </a>
      .
    </Trans>
  );
};
