/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookUpdateRequest
 */
export interface WebhookUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof WebhookUpdateRequest
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebhookUpdateRequest
     */
    isEnabled: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof WebhookUpdateRequest
     */
    events: Array<string>;
}

export function WebhookUpdateRequestFromJSON(json: any): WebhookUpdateRequest {
    return WebhookUpdateRequestFromJSONTyped(json, false);
}

export function WebhookUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'url': json['url'],
        'isEnabled': json['isEnabled'],
        'events': json['events'],
    };
}

export function WebhookUpdateRequestToJSON(value?: WebhookUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'url': value.url,
        'isEnabled': value.isEnabled,
        'events': value.events,
    };
}

