import { useTranslation } from 'react-i18next';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import { ArrowRightIcon, ArrowLeftIcon } from '../../../icons';

import styles from './pagination.module.css';

type Props = Pick<ReactPaginateProps, 'onPageChange'> & {
  currentPageTotal?: number;
  forcePage?: number;
  limit: number;
  offset: number;
  total: number;
};

export const Pagination = ({
  onPageChange,
  total,
  offset,
  forcePage,
  currentPageTotal = 0,
  limit,
}: Props) => {
  const { t } = useTranslation();
  const pageCount = Math.ceil(total / limit);
  const startItem = offset + 1;
  const endItem = offset + currentPageTotal;

  return (
    <div className={styles.container}>
      {currentPageTotal > 0 && (
        <p className={styles.results} data-testid='pagination-info'>
          {t('table.pagination_results', {
            endItem,
            startItem,
            totalItems: total,
          })}
        </p>
      )}
      <ReactPaginate
        forcePage={forcePage}
        breakLabel='...'
        nextLabel={<ArrowRightIcon />}
        onPageChange={onPageChange}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        previousLabel={<ArrowLeftIcon />}
        containerClassName={styles.pagination}
        pageClassName={styles.pagination__page}
        activeClassName={styles['pagination__page--active']}
        pageLinkClassName={styles.pagination__link}
        breakLinkClassName={styles.pagination__break_link}
        breakClassName={styles.pagination__break}
        activeLinkClassName={styles['pagination__link--active']}
        previousClassName={styles.pagination__prev}
        nextClassName={styles.pagination__next}
        disabledClassName={styles.disabled}
      />
    </div>
  );
};
