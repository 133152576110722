import { useTranslation } from 'react-i18next';

import { AccessControlType } from '../../../../../types';
import { Chip } from '../../../../../components/Chip';

type Props = {
  type: AccessControlType;
};

export const AccessControlTypeChip = ({ type }: Props) => {
  const { t } = useTranslation();

  return (
    <Chip
      label={t(`integrations.onboarding_and_kyc.access_control.${type}.name`)}
      color='blue'
    />
  );
};
