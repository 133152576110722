/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublicKeyCredentialUserEntity
 */
export interface PublicKeyCredentialUserEntity {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialUserEntity
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialUserEntity
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialUserEntity
     */
    displayName: string;
}

export function PublicKeyCredentialUserEntityFromJSON(json: any): PublicKeyCredentialUserEntity {
    return PublicKeyCredentialUserEntityFromJSONTyped(json, false);
}

export function PublicKeyCredentialUserEntityFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicKeyCredentialUserEntity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'displayName': json['displayName'],
    };
}

export function PublicKeyCredentialUserEntityToJSON(value?: PublicKeyCredentialUserEntity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'displayName': value.displayName,
    };
}

