import { t } from 'i18next';

import { ChainEnum, IntegrationSetting } from '@dynamic-labs/sdk-api';
import { createWallet } from '@dynamic-labs/sdk-react-core';
import { WalletBookSchema } from '@dynamic-labs/wallet-book';
import {
  getSupportedChainsForWalletConnector,
  getSupportedWallets,
} from '@dynamic-labs/multi-wallet';

import { WalletRow, IntegrationCategory } from '../../../../types';

export interface ChainRows {
  liveSettings: IntegrationSetting[];
  name: string;
}

export const createChainRows = ({ name, liveSettings }: ChainRows) =>
  liveSettings
    .map(({ name: chainName }) => ({
      name: chainName,
    }))
    .filter((setting) => setting.name === name)
    .map((chain) => chain.name);

export const walletTableHeaders = [
  t('integrations.chains.details.wallet_headers.name'),
];

export const getWalletRows = async (
  walletBook: WalletBookSchema,
  chain: ChainEnum,
  description: string,
): Promise<WalletRow[]> => {
  const allSupportedWalletConnectors = getSupportedWallets({} as any);

  const allSupportedWalletConnectorsForChain = (
    await allSupportedWalletConnectors
  ).filter((walletConnector) => {
    const supportedChains = getSupportedChainsForWalletConnector(
      walletBook,
      walletConnector,
    ).map((chainType) => chainType.toString());
    return supportedChains.includes(chain);
  });

  const allSupportedWalletsForChain = allSupportedWalletConnectorsForChain.map(
    (wallet) => createWallet(walletBook, wallet),
  );

  return allSupportedWalletsForChain.map((wallet) => ({
    ...wallet,
    category: IntegrationCategory.Chains,
    chainClassName: '!col-span-12',
    description,
    title: `${wallet.name} (key: ${wallet.key})`,
  }));
};
