import { useQuery } from 'react-query';

import { settingsApi } from '../../services/api';

export const useEnvironmentProviders = (environmentId: string | undefined) =>
  useQuery(
    ['settingsApi', 'getEnvironmentProviders', environmentId],
    () =>
      settingsApi.getEnvironmentProviders({
        environmentId: environmentId ?? '',
      }),
    {
      enabled: !!environmentId,
      refetchOnWindowFocus: false,
    },
  );
