/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JwtVerifiedCredentialHashes,
    JwtVerifiedCredentialHashesFromJSON,
    JwtVerifiedCredentialHashesFromJSONTyped,
    JwtVerifiedCredentialHashesToJSON,
} from './JwtVerifiedCredentialHashes';

/**
 * This contains standard JWT claims to identify a session for the Dynamic user. For more details, see: https://www.iana.org/assignments/jwt/jwt.xhtml#claims
 * @export
 * @interface MinifiedDynamicJwt
 */
export interface MinifiedDynamicJwt {
    /**
     * Project environment jwt public key id, used to verify the jwt using the the jwks endpoint
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    kid: string;
    /**
     * Audience: this will be the origin header, pertaining to the client site
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    aud: string;
    /**
     * Issuer: current backend api host + environment id
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    iss: string;
    /**
     * Dynamic user ID
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    sub: string;
    /**
     * Dynamic session ID
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    sid: string;
    /**
     * Expiration timestamp
     * @type {number}
     * @memberof MinifiedDynamicJwt
     */
    exp?: number;
    /**
     * Timestamp for when the JWT was issued at
     * @type {number}
     * @memberof MinifiedDynamicJwt
     */
    iat?: number;
    /**
     * Dynamic environment ID
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    environmentId: string;
    /**
     * ID of the most recently used verified credential. This would refer to a value in the verified credentials list in the corresponding SdkUser
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    lastVerifiedCredentialId: string;
    /**
     * A whitespace-separate list of permissions associated with the JWT token issued. This conforms to the JWT standard for scope claims: https://datatracker.ietf.org/doc/html/rfc8693#section-4.2
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    scope?: string;
    /**
     * 
     * @type {JwtVerifiedCredentialHashes}
     * @memberof MinifiedDynamicJwt
     */
    verifiedCredentialsHashes?: JwtVerifiedCredentialHashes;
    /**
     * 
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MinifiedDynamicJwt
     */
    username?: string;
}

export function MinifiedDynamicJwtFromJSON(json: any): MinifiedDynamicJwt {
    return MinifiedDynamicJwtFromJSONTyped(json, false);
}

export function MinifiedDynamicJwtFromJSONTyped(json: any, ignoreDiscriminator: boolean): MinifiedDynamicJwt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kid': json['kid'],
        'aud': json['aud'],
        'iss': json['iss'],
        'sub': json['sub'],
        'sid': json['sid'],
        'exp': !exists(json, 'exp') ? undefined : json['exp'],
        'iat': !exists(json, 'iat') ? undefined : json['iat'],
        'environmentId': json['environment_id'],
        'lastVerifiedCredentialId': json['last_verified_credential_id'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'verifiedCredentialsHashes': !exists(json, 'verifiedCredentialsHashes') ? undefined : JwtVerifiedCredentialHashesFromJSON(json['verifiedCredentialsHashes']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function MinifiedDynamicJwtToJSON(value?: MinifiedDynamicJwt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kid': value.kid,
        'aud': value.aud,
        'iss': value.iss,
        'sub': value.sub,
        'sid': value.sid,
        'exp': value.exp,
        'iat': value.iat,
        'environment_id': value.environmentId,
        'last_verified_credential_id': value.lastVerifiedCredentialId,
        'scope': value.scope,
        'verifiedCredentialsHashes': JwtVerifiedCredentialHashesToJSON(value.verifiedCredentialsHashes),
        'email': value.email,
        'username': value.username,
    };
}

