import { ChangeEvent, FC, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ChainConfiguration,
  ProjectSettingsChains,
  ProviderEnum,
} from '@dynamic-labs/sdk-api';
import { EthereumIcon, SolanaIcon } from '@dynamic-labs/iconic';
import { SingleToggleCard, TextButton } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { Badge } from '../../../../../../../app/components/Badge';
import { ROUTES } from '../../../../../../../app/components/Navigation/data';
import { ModalHashLocations } from '../../../../../../../app/routes/Configurations/utils';

import styles from './EmbeddedWalletChainToggle.module.scss';

export enum EmbeddedWalletSupportedChainEnum {
  Evm = 'EVM',
  Sol = 'SOL',
}

const ChainNames = {
  EVM: 'evm',
  SOL: 'solana',
};

const chainIcons: Record<EmbeddedWalletSupportedChainEnum, ReactElement> = {
  EVM: <EthereumIcon />,
  SOL: <SolanaIcon />,
};

type EmbeddedWalletChainToggleProps = {
  chainName: EmbeddedWalletSupportedChainEnum;
};

export const EmbeddedWalletChainToggle: FC<EmbeddedWalletChainToggleProps> = ({
  chainName,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings, initialSettings } = useSettingsContext();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const chainConfigurations =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.chainConfigurations;

  const isChainEnabledGlobally = Boolean(
    settings[activeEnvironmentType]?.chains.find(
      (chain: ProjectSettingsChains) => chain.name === ChainNames[chainName],
    )?.enabled,
  );

  const handleChainToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const ewChainConfiguration = JSON.parse(
      JSON.stringify(chainConfigurations),
    );

    const chainConfigToUpdate = ewChainConfiguration?.find(
      (config: ChainConfiguration) => config.name === chainName,
    );

    chainConfigToUpdate.enabled =
      event.target.checked && isChainEnabledGlobally;

    // if this was previously the primary chain, make it no longer the primary.
    if (!event.target.checked && chainConfigToUpdate.primary) {
      const otherConfig = ewChainConfiguration?.filter(
        (c: ChainConfiguration) => c.name !== chainConfigToUpdate.name,
      )[0];
      if (otherConfig && otherConfig.enabled) {
        otherConfig.primary = true;
      }
      chainConfigToUpdate.primary = false;
    }

    if (event.target.checked && !chainConfigToUpdate.primary) {
      const otherConfig = ewChainConfiguration?.filter(
        (c: ChainConfiguration) => c.name !== chainConfigToUpdate.name,
      )[0];

      if (otherConfig && !otherConfig.enabled) {
        otherConfig.primary = false;
        chainConfigToUpdate.primary = true;
      }
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            chainConfigurations: ewChainConfiguration,
          },
        },
      },
    });
  };

  const isChainEnabled = Boolean(
    chainConfigurations?.find(
      (chain: ChainConfiguration) => chain.name === chainName,
    )?.enabled,
  );

  const isChainSettingSaved = (): boolean => {
    const savedChainEnabledState = Boolean(
      initialSettings[
        activeEnvironmentType
      ]?.sdk?.embeddedWallets?.chainConfigurations?.find(
        (c: ChainConfiguration) => c.name === chainName && c.enabled,
      ),
    );

    const currentChainEnabledState = Boolean(
      chainConfigurations?.find(
        (c: ChainConfiguration) => c.name === chainName && c.enabled,
      ),
    );

    return (
      Boolean(currentChainEnabledState === savedChainEnabledState) &&
      Boolean(getProviderValue(ProviderEnum.Turnkey, 'enabledAt')) &&
      isTurnkeySettingSaved
    );
  };

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const shouldDisableChainToggle = Boolean(
    !isTurnkeyEnabled || !isChainEnabledGlobally,
  );

  const isPrimaryChain = Boolean(
    chainConfigurations?.find(
      (chain: ChainConfiguration) => chain.name === chainName,
    )?.primary,
  );

  const getTags = () => {
    const tags: { Tag: ReactElement; id: string }[] = [];

    if (isPrimaryChain && isChainEnabledGlobally) {
      tags.push({
        Tag: (
          <Badge
            text={t(
              'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.default',
            )}
            size='big'
            variant='secondary'
          />
        ),
        id: 'default',
      });
    }

    return tags;
  };

  const getRightSideTags = () => {
    const tags: { Tag: ReactElement; id: string }[] = [];

    if (!isChainEnabledGlobally) {
      tags.push({
        Tag: (
          <TextButton
            onClick={() =>
              navigate(
                `${ROUTES.chainsAndNetworks}#${ModalHashLocations.Solana}`,
              )
            }
            text={t(
              'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.alert.chain_disabled.action',
            )}
            color='primary-1'
          />
        ),
        id: 'default',
      });
    }

    return tags;
  };

  return (
    <div className={styles.wrapper}>
      <SingleToggleCard
        inputProps={{
          checked: isTurnkeyEnabled && isChainEnabledGlobally && isChainEnabled,
          disabled: shouldDisableChainToggle,
          id: chainName,
          isSaved: isChainSettingSaved(),
          onChange: handleChainToggle,
          type: 'toggle',
        }}
        Icon={chainIcons[chainName]}
        accordionKey={chainName}
        title={t(
          `v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.${chainName}`,
        )}
        allowExpand={false}
        Tags={getTags()}
        RightSideTags={getRightSideTags()}
      />
    </div>
  );
};
