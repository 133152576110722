import React, { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { MultiSection, SingleToggleCard } from '@dynamic-labs/northstar';
import { MagicLinkIcon } from '@dynamic-labs/iconic';

import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { SideDrawer } from '../../../../../components/SideDrawer';
import { AdminIcon } from '../../../../../../icons';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../app/routes/Configurations/utils';

import styles from './MagicRow.module.scss';
import { MagicCredentialsSection } from './MagicCredentialsSection';
import { MagicSocialSection } from './MagicSocialSection';

const DOCS_URL =
  'https://docs.dynamic.xyz/wallets/embedded-wallets/key-management/magic';

type MagicRowProps = {
  disabled: boolean;
  refetchWalletsBreakdown: VoidFunction;
};

export const MagicRow: FC<MagicRowProps> = ({
  disabled,
  refetchWalletsBreakdown,
}) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.ThirdPartyMagicLink,
  });
  const { t } = useTranslation();

  const { getProviderValue, toggleProvider, savedProviders } =
    useProvidersContext();

  const isMagicLinkEnabled = Boolean(
    getProviderValue(ProviderEnum.MagicLink, 'enabledAt'),
  );

  const savedMagicLinkEnabled = Boolean(savedProviders?.magicLink?.enabledAt);

  const isMagicLinkSaved = Boolean(
    isMagicLinkEnabled === savedMagicLinkEnabled,
  );

  const handleMagicLinkToggleChange = async (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const isMagicConfigured = Boolean(
      getProviderValue(ProviderEnum.MagicLink, 'clientSecret') &&
        getProviderValue(ProviderEnum.MagicLink, 'clientId'),
    );

    if (event.target.checked && !isMagicConfigured) {
      handleShowModal(true);
    }

    toggleProvider(ProviderEnum.MagicLink, event.target.checked);
    await refetchWalletsBreakdown();
  };

  return (
    <>
      <MultiSection.Item
        title={t('v2.page.embedded_wallets.third_party.magicLink.title')}
        description={t(
          'v2.page.embedded_wallets.third_party.magicLink.description',
        )}
      >
        <SingleToggleCard
          accordionKey='magic-link'
          allowExpand={false}
          title={t('v2.page.embedded_wallets.third_party.magicLink.toggle')}
          Icon={<MagicLinkIcon />}
          inputProps={{
            checked: isMagicLinkEnabled,
            disabled,
            id: 'magic-link-toggle',
            isSaved: isMagicLinkSaved,
            onChange: handleMagicLinkToggleChange,
            type: 'toggle',
          }}
          onCardClick={
            isMagicLinkEnabled ? () => handleShowModal(true) : undefined
          }
          customActionIcon={{
            Icon: <AdminIcon />,
            className: styles['action-icon'],
          }}
        />
      </MultiSection.Item>

      <SideDrawer
        title={t(
          'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.title',
        )}
        isOpen={showModal && isMagicLinkEnabled}
        handleClose={() => handleShowModal(false)}
        Icon={<MagicLinkIcon />}
        className={styles.modal}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.docs',
          ),
          url: DOCS_URL,
        }}
      >
        <div className={styles.drawer}>
          <MagicCredentialsSection />
          <MagicSocialSection />
        </div>
      </SideDrawer>
    </>
  );
};
