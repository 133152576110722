import {
  createContext,
  useContext,
  useState,
  useMemo,
  Dispatch,
  useEffect,
  SetStateAction,
} from 'react';

import { Allowlist } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { allowListsApi } from '../../services/api';

import { fetchAllowLists } from './helpers';

export type AccessListsContextProps = {
  accessLists: Allowlist[];
  activeList: string;
  fetchAccessLists(): Promise<void>;
  liveIsActive: boolean;
  sandboxIsActive: boolean;
  setAccessLists: Dispatch<SetStateAction<Allowlist[]>>;
  setActiveList: Dispatch<SetStateAction<string>>;
};

type AccessListsContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export type CountResult = {
  count: number;
  projectEnvironmentId: string;
};
export const AccessListsContext = createContext<
  AccessListsContextProps | undefined
>(undefined);

export const AccessListsContextProvider = ({
  children,
}: AccessListsContextProviderProps) => {
  const { activeEnvironment, environments } = useEnvironmentsContext();
  const [accessLists, setAccessLists] = useState<Allowlist[]>([]);
  const [activeList, setActiveList] = useState<string>('');
  const [sandboxActive, setSandboxActive] = useState<boolean>(false);
  const [liveActive, setLiveActive] = useState<boolean>(false);

  const sandboxEnvironment = environments?.sandbox;
  const liveEnvironment = environments?.live;

  const environmentId = activeEnvironment?.id;

  useEffect(() => {
    fetchAllowLists(environmentId, setAccessLists);
  }, [environmentId, activeEnvironment]);

  useEffect(() => {
    const getSandboxLists = async () => {
      if (sandboxEnvironment?.id) {
        let liveLists: Allowlist[] = [];

        if (sandboxEnvironment.id === activeEnvironment?.id) {
          liveLists = accessLists;
        } else {
          try {
            liveLists = await allowListsApi.getAllowlistsByEnvironmentId({
              environmentId: sandboxEnvironment.id,
            });
          } catch {
            // eslint-disable-next-line no-empty
          }
        }

        const hasEnabledLists = !!(
          liveLists && liveLists.find((list) => list.enabledAt)
        );

        setSandboxActive(hasEnabledLists);
      }
    };

    getSandboxLists();
  }, [sandboxEnvironment, accessLists, activeEnvironment]);

  useEffect(() => {
    const getLiveLists = async () => {
      if (liveEnvironment?.id) {
        let liveLists: Allowlist[] = [];

        if (liveEnvironment.id === activeEnvironment?.id) {
          liveLists = accessLists;
        } else {
          try {
            liveLists = await allowListsApi.getAllowlistsByEnvironmentId({
              environmentId: liveEnvironment.id,
            });
            // eslint-disable-next-line no-empty
          } catch {}
        }

        const hasEnabledLists = !!(
          liveLists && liveLists.find((list) => list.enabledAt)
        );

        setLiveActive(hasEnabledLists);
      }
    };
    getLiveLists();
  }, [liveEnvironment, accessLists, activeEnvironment]);

  const value = useMemo(
    () => ({
      accessLists,
      activeList,
      /* instanbul ignore next */
      fetchAccessLists: () => fetchAllowLists(environmentId, setAccessLists),
      liveIsActive: liveActive,
      sandboxIsActive: sandboxActive,
      setAccessLists,
      setActiveList,
    }),
    [accessLists, activeList, liveActive, sandboxActive, environmentId],
  );

  return (
    <AccessListsContext.Provider value={value}>
      {children}
    </AccessListsContext.Provider>
  );
};

export const useAccessListsContext = () => {
  const context = useContext(AccessListsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useAccessListsContext not wrapped in `AccessListsContextProvider`.',
    );
  }

  return context;
};
