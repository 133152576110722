/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CreateTurnkeyEmbeddedWalletSpecificOpts,
    CreateTurnkeyEmbeddedWalletSpecificOptsFromJSON,
    CreateTurnkeyEmbeddedWalletSpecificOptsFromJSONTyped,
    CreateTurnkeyEmbeddedWalletSpecificOptsToJSON,
} from './CreateTurnkeyEmbeddedWalletSpecificOpts';

/**
 * @type CreateEmbeddedWalletSpecificOpts
 * 
 * @export
 */
export type CreateEmbeddedWalletSpecificOpts = CreateTurnkeyEmbeddedWalletSpecificOpts;

export function CreateEmbeddedWalletSpecificOptsFromJSON(json: any): CreateEmbeddedWalletSpecificOpts {
    return CreateEmbeddedWalletSpecificOptsFromJSONTyped(json, false);
}

export function CreateEmbeddedWalletSpecificOptsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmbeddedWalletSpecificOpts {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return { ...CreateTurnkeyEmbeddedWalletSpecificOptsFromJSONTyped(json, true) };
}

export function CreateEmbeddedWalletSpecificOptsToJSON(value?: CreateEmbeddedWalletSpecificOpts | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return { ...CreateTurnkeyEmbeddedWalletSpecificOptsToJSON(value) };
}

