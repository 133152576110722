import { t } from 'i18next';

export const columnsNames = {
  alias: t('integrations.onboarding_and_kyc.settings.alias'),
  country: t('integrations.onboarding_and_kyc.settings.country'),
  email: t('integrations.onboarding_and_kyc.settings.email'),
  firstName: t('integrations.onboarding_and_kyc.settings.firstName'),
  jobTitle: t('integrations.onboarding_and_kyc.settings.jobTitle'),
  lastName: t('integrations.onboarding_and_kyc.settings.lastName'),
  phoneNumber: t('integrations.onboarding_and_kyc.settings.phoneNumber'),
  policiesConsent: t(
    'integrations.onboarding_and_kyc.settings.policiesConsent',
  ),
  tShirtSize: t('integrations.onboarding_and_kyc.settings.tShirtSize'),
  team: t('integrations.onboarding_and_kyc.settings.team'),
  username: t('integrations.onboarding_and_kyc.settings.username'),
};
