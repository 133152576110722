/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationExtensionsClientInputs
 */
export interface AuthenticationExtensionsClientInputs {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationExtensionsClientInputs
     */
    appid?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationExtensionsClientInputs
     */
    credProps?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationExtensionsClientInputs
     */
    hmacCreateSecret?: boolean;
}

export function AuthenticationExtensionsClientInputsFromJSON(json: any): AuthenticationExtensionsClientInputs {
    return AuthenticationExtensionsClientInputsFromJSONTyped(json, false);
}

export function AuthenticationExtensionsClientInputsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationExtensionsClientInputs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appid': !exists(json, 'appid') ? undefined : json['appid'],
        'credProps': !exists(json, 'credProps') ? undefined : json['credProps'],
        'hmacCreateSecret': !exists(json, 'hmacCreateSecret') ? undefined : json['hmacCreateSecret'],
    };
}

export function AuthenticationExtensionsClientInputsToJSON(value?: AuthenticationExtensionsClientInputs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appid': value.appid,
        'credProps': value.credProps,
        'hmacCreateSecret': value.hmacCreateSecret,
    };
}

