/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthenticatorAttachment,
    AuthenticatorAttachmentFromJSON,
    AuthenticatorAttachmentFromJSONTyped,
    AuthenticatorAttachmentToJSON,
} from './AuthenticatorAttachment';
import {
    ResidentKeyRequirement,
    ResidentKeyRequirementFromJSON,
    ResidentKeyRequirementFromJSONTyped,
    ResidentKeyRequirementToJSON,
} from './ResidentKeyRequirement';
import {
    UserVerificationRequirement,
    UserVerificationRequirementFromJSON,
    UserVerificationRequirementFromJSONTyped,
    UserVerificationRequirementToJSON,
} from './UserVerificationRequirement';

/**
 * 
 * @export
 * @interface AuthenticatorSelectionCriteria
 */
export interface AuthenticatorSelectionCriteria {
    /**
     * 
     * @type {AuthenticatorAttachment}
     * @memberof AuthenticatorSelectionCriteria
     */
    authenticatorAttachment?: AuthenticatorAttachment;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticatorSelectionCriteria
     */
    requireResidentKey?: boolean;
    /**
     * 
     * @type {ResidentKeyRequirement}
     * @memberof AuthenticatorSelectionCriteria
     */
    residentKey?: ResidentKeyRequirement;
    /**
     * 
     * @type {UserVerificationRequirement}
     * @memberof AuthenticatorSelectionCriteria
     */
    userVerification?: UserVerificationRequirement;
}

export function AuthenticatorSelectionCriteriaFromJSON(json: any): AuthenticatorSelectionCriteria {
    return AuthenticatorSelectionCriteriaFromJSONTyped(json, false);
}

export function AuthenticatorSelectionCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatorSelectionCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'authenticatorAttachment': !exists(json, 'authenticatorAttachment') ? undefined : AuthenticatorAttachmentFromJSON(json['authenticatorAttachment']),
        'requireResidentKey': !exists(json, 'requireResidentKey') ? undefined : json['requireResidentKey'],
        'residentKey': !exists(json, 'residentKey') ? undefined : ResidentKeyRequirementFromJSON(json['residentKey']),
        'userVerification': !exists(json, 'userVerification') ? undefined : UserVerificationRequirementFromJSON(json['userVerification']),
    };
}

export function AuthenticatorSelectionCriteriaToJSON(value?: AuthenticatorSelectionCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'authenticatorAttachment': AuthenticatorAttachmentToJSON(value.authenticatorAttachment),
        'requireResidentKey': value.requireResidentKey,
        'residentKey': ResidentKeyRequirementToJSON(value.residentKey),
        'userVerification': UserVerificationRequirementToJSON(value.userVerification),
    };
}

