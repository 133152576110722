/* eslint-disable import/no-namespace */
import { FC, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { TrashIcon } from '../../icons';
import { Button } from '../Button';
import { Input } from '../Input';
import { Table, TableProps } from '../Table';
import { Typography } from '../Typography';

import styles from './EditableTable.module.scss';

export type EditableTableProps<T extends string> = TableProps<T> & {
  addButtonLabel?: string;
  addButtonLoading?: boolean;
  inputSchema?: yup.StringSchema;
  onAdd?: (value: string) => Promise<unknown>;
  onChangeInputVisibility?: (visibility: boolean) => void;
  onDelete: (index: number) => Promise<unknown>;
  submitButtonLabel?: string;
};

export const EditableTable = <T extends string>({
  items,
  action: actionOverride,
  onAdd,
  onDelete,
  onChangeInputVisibility,
  addButtonLabel = 'Add item',
  addButtonLoading = false,
  submitButtonLabel = 'Submit',
  columnsAndProportions,
  rowHeight,
  className,
  emptyLabel,
  style,
  inputSchema = yup.string(),
}: EditableTableProps<T>): ReturnType<FC<EditableTableProps<T>>> => {
  const [schema] = useState(yup.object({ newItem: inputSchema }));
  type NewItemForm = yup.InferType<typeof schema>;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [showInput, _setShowInput] = useState(false);

  const setShowInput = (show: boolean) => {
    _setShowInput(show);
    onChangeInputVisibility?.(show);
  };

  const {
    handleSubmit,
    register,
    reset,
    setError,
    formState: {
      errors: { newItem: inputError },
    },
  } = useForm<NewItemForm>({ resolver: yupResolver(schema) });

  const onSubmit: SubmitHandler<NewItemForm> = async ({ newItem }) => {
    try {
      if (newItem && onAdd) await onAdd(newItem);
      setShowInput(false);
      reset();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.message) setError('newItem', { message: error.message });
      else setError('newItem', { message: error });
    }
  };

  const NewItemInput = showInput ? (
    <form className={styles['new-item']} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.row}>
        <Input
          name='newItem'
          onBlur={register('newItem').onBlur}
          onChange={register('newItem').onChange}
          ref={register('newItem').ref}
          error={Boolean(inputError)}
          padding='small'
          autofocus
        />
        <Button text={submitButtonLabel} type='submit' />
      </div>

      {inputError?.message && (
        <Typography variant='paragraph-2' color='red-1' weight='medium'>
          {inputError.message}
        </Typography>
      )}
    </form>
  ) : null;

  return (
    <div className={styles.wrapper}>
      <Table
        columnsAndProportions={columnsAndProportions}
        rowHeight={rowHeight}
        className={className}
        emptyLabel={emptyLabel}
        style={style}
        items={
          NewItemInput
            ? [
                ...items,
                {
                  key: '_editable-table--input',
                  override: NewItemInput,
                  overrideHeight: inputError?.message ? 90 : 50,
                },
              ]
            : items
        }
        action={actionOverride ?? { icon: <TrashIcon />, onClick: onDelete }}
      />

      {onAdd && (
        <Button
          text={addButtonLabel}
          onClick={() => setShowInput(true)}
          disabled={showInput}
          className={styles['add-button']}
          size='medium'
          isLoading={addButtonLoading}
        />
      )}
    </div>
  );
};
