import { FC, useCallback } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { SidebarNavigation } from '@dynamic-labs/northstar';

import { useDashboardRole } from '../../../app/hooks/useDashboardRole';
import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { useSaveBannerContext } from '../../contexts/SaveBannerContext';

import { navigationItems, NavigationItemPermissions } from './navigationItems';

type NavigationProps = {
  hideNavigation?: boolean;
};

export const Navigation: FC<NavigationProps> = ({ hideNavigation = false }) => {
  const { checkForAccess } = useDashboardRole();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { shouldShowSaveBanner, setShouldWaveSaveBanner } =
    useSaveBannerContext();
  const featureFlags = useFlags();

  /** Helper to check for access on a menu item */
  const runChecks = useCallback(
    (item: NavigationItemPermissions) => {
      // Check role permissions
      if (!checkForAccess(item.minRole)) return false;

      // Check environment type
      if (
        item.environment !== undefined &&
        item.environment !== activeEnvironmentType
      ) {
        return false;
      }

      // Check for flags
      if (
        item.hideBehindFeatureFlagKey &&
        !featureFlags[item.hideBehindFeatureFlagKey]
      ) {
        return false;
      }

      return true;
    },
    [activeEnvironmentType, checkForAccess, featureFlags],
  );

  const filteredNavigationItemsByRole = navigationItems.map((section) => ({
    ...section,
    items: section.items.filter((item) => runChecks(item)),
  }));

  const filteredNavigationSubItemsByRole = filteredNavigationItemsByRole.map(
    (section) => ({
      ...section,
      items: section.items.map((item) => ({
        ...item,
        subItems: item.subItems?.filter((subItem) => runChecks(subItem)),
      })),
    }),
  );

  if (hideNavigation) {
    return null;
  }

  const handleDisabledNavigationClick = () => {
    if (shouldShowSaveBanner) {
      setShouldWaveSaveBanner(true);
    }
  };

  return (
    <SidebarNavigation
      items={filteredNavigationSubItemsByRole}
      disabled={shouldShowSaveBanner}
      onDisabledClick={handleDisabledNavigationClick}
    />
  );
};
