import { FC, Fragment } from 'react';

import { Typography } from '../Typography';
import Button from '../Button';
import { Icon } from '../Icon';

import styles from './QuickHelp.module.css';

type Props = {
  ButtonIcon?: FC;
  HeadingIcon?: FC;
  className?: string;
  content: string;
  documentation: string;
  name?: string;
  url: string;
};

export const QuickHelp = ({
  name,
  content,
  documentation,
  url,
  className = '',
  HeadingIcon = Fragment,
  ButtonIcon = Fragment,
}: Props): JSX.Element => (
  <div className={`${styles.container} ${className}`}>
    {name && (
      <div className={styles.header}>
        <HeadingIcon />
        <Typography
          variant='paragraph-2'
          weight='medium'
          className={styles.heading}
        >
          {name}
        </Typography>
      </div>
    )}
    <Typography variant='paragraph-2' className={styles.content}>
      {content}
    </Typography>
    <a href={url} rel='noreferrer' target='_blank'>
      <Button
        variant='secondary'
        leading={<Icon size='medium' icon={<ButtonIcon />} />}
      >
        <Typography variant='paragraph-1' weight='medium'>
          {documentation}
        </Typography>
      </Button>
    </a>
  </div>
);
