import { useCallback } from 'react';

import { UseQueryOptions, useQuery } from 'react-query';

import { OriginsResponse } from '@dynamic-labs/sdk-api';

import { useRedcoastQueryContext } from '../../../contexts/RedcoastQueryContextProvider';

type ApiErrorResponse = {
  json: () => Promise<{ error: string; status: number }>;
};

export type UseOriginsHook = (
  params: { environmentId: string },
  options?: UseQueryOptions<
    OriginsResponse,
    unknown,
    OriginsResponse,
    string[]
  >,
) => {
  addOrigin: (origin: string) => Promise<void>;
  deleteOrigin: (originId: string) => Promise<void>;
  isLoading: boolean;
  origins: OriginsResponse | undefined;
};

export const useCors: UseOriginsHook = ({ environmentId }, options) => {
  const { originsApi } = useRedcoastQueryContext();

  const queryFn = useCallback(
    () => originsApi.getOrigins({ environmentId }),
    [originsApi, environmentId],
  );

  const {
    data: origins,
    isFetching,
    refetch,
  } = useQuery({
    queryFn,
    queryKey: ['origins', environmentId],
    ...options,
  });

  return {
    addOrigin: async (origin) => {
      let formattedOrigin = origin;
      if (formattedOrigin.endsWith('/')) {
        formattedOrigin = formattedOrigin.slice(0, -1);
      }
      try {
        await originsApi.addOrigin({
          environmentId,
          inlineObject: { origin: formattedOrigin },
        });
        await refetch();
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();
        if (message.status === 400) {
          throw new Error('Invalid Format');
        } else {
          throw new Error(message.error);
        }
      }
    },

    deleteOrigin: async (originId) => {
      try {
        await originsApi.deleteOriginById({ originId });
        await refetch();
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();
        if (message.status === 400) {
          throw new Error('Invalid Format');
        } else {
          throw new Error(message.error);
        }
      }
    },

    isLoading: isFetching,
    origins,
  };
};
