import { useQuery } from 'react-query';

import { useDynamicContext } from '@dynamic-labs/sdk-react-core';
import { NameServiceData } from '@dynamic-labs/sdk-api-core';

/**
 * Hook to get domains associated with the primary wallet
 *
 * Currently only supports getting the primary ENS domain (example.eth)
 */
export const useWalletDomains = () => {
  const { primaryWallet } = useDynamicContext();

  const queryKey = ['walletDomains'];

  /** useQuery wrapper for getting the primary ENS domain */
  const { data, isLoading } = useQuery<NameServiceData | undefined>(
    queryKey,
    async () => {
      try {
        return await primaryWallet?.getNameService();
      } catch (e) {
        return undefined;
      }
    },
  );

  return {
    isLoading,
    primaryEnsDomain: data,
  };
};
