import { FC, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';

import { Section } from '@dynamic-labs/northstar';

import { logger } from '../../../app/services/logger';
import { useNameServicesContext } from '../../../app/context/NameServicesContext';
import { PageHeader, SaveBanner } from '../../components';

import styles from './GlobalWallets.module.scss';
import { SubdomainsToggle } from './SubdomainsToggle';
import { GlobalWalletsFieldValues } from './util';
import { SubdomainsRequiredToggle } from './SubdomainsRequiredToggle';

export const GlobalWallets: FC = () => {
  const { t } = useTranslation();

  const { nameServices, enableNameServices, disableNameServices } =
    useNameServicesContext();

  const formikRef = useRef<FormikProps<GlobalWalletsFieldValues>>(null);

  const [loading, setLoading] = useState(false);
  const onSubmit = async (values: GlobalWalletsFieldValues) => {
    try {
      setLoading(true);
      if (values.subdomainsEnabled) {
        await enableNameServices();
      } else {
        await disableNameServices();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
  };

  const isSubdomainsEnabled = useMemo(
    () => nameServices !== undefined && nameServices.enabledAt !== undefined,
    [nameServices],
  );

  const initialValues: GlobalWalletsFieldValues = useMemo(
    () => ({
      subdomainsEnabled: isSubdomainsEnabled,
    }),
    [isSubdomainsEnabled],
  );

  return (
    <section className={styles.wrapper} data-testid='test-accounts'>
      <div className={styles.container}>
        <PageHeader
          title={t('v2.page.global_wallets.title')}
          description={t('v2.page.global_wallets.description')}
        />
        <Section
          title={t('v2.page.global_wallets.subdomains.title')}
          description={t('v2.page.global_wallets.subdomains.description')}
          alerts={[
            {
              description: t(
                'v2.page.global_wallets.subdomains.alert.description',
              ),
              title: t('v2.page.global_wallets.subdomains.alert.title'),
              variant: 'warning',
            },
          ]}
          alertsAlignment='top'
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ values, handleSubmit }) => (
              <Form>
                <div className={styles.wrapper}>
                  <SubdomainsToggle />
                </div>
                {isSubdomainsEnabled && (
                  <div className={styles.wrapper}>
                    <SubdomainsRequiredToggle />
                  </div>
                )}
                <AnimatePresence>
                  {values.subdomainsEnabled !==
                    initialValues.subdomainsEnabled && (
                    <SaveBanner
                      handleOnSave={handleSubmit}
                      handleOnCancel={handleCancelClick}
                      isLoading={loading}
                    />
                  )}
                </AnimatePresence>
              </Form>
            )}
          </Formik>
        </Section>
      </div>
    </section>
  );
};
