/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AttestationConveyancePreference {
    Direct = 'direct',
    Enterprise = 'enterprise',
    Indirect = 'indirect',
    None = 'none'
}

export function AttestationConveyancePreferenceFromJSON(json: any): AttestationConveyancePreference {
    return AttestationConveyancePreferenceFromJSONTyped(json, false);
}

export function AttestationConveyancePreferenceFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttestationConveyancePreference {
    return json as AttestationConveyancePreference;
}

export function AttestationConveyancePreferenceToJSON(value?: AttestationConveyancePreference | null): any {
    return value as any;
}

