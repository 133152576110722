import { ChainalysisConfiguration } from '@dynamic-labs/sdk-api';

import { chainalysisApi } from '../../services/api';
import { logger } from '../../services/logger';

export const DefaultConfiguration: ChainalysisConfiguration = {
  createdAt: new Date(1),
  id: '',
  kytApiEnabled: false,
  projectEnvironmentId: '',
  sanctionsApiEnabled: false,
  updatedAt: new Date(1),
};

export const fetchChainalysisConfiguration = async (
  environmentId: string | undefined,
) => {
  if (!environmentId) {
    return DefaultConfiguration;
  }
  try {
    const response = await chainalysisApi.getChainalysisByEnvironmentId({
      environmentId,
    });
    return response;
  } catch (e) {
    if (!(e instanceof Response && e.status === 404)) {
      logger.error(e);
    }
    return DefaultConfiguration;
  }
};
