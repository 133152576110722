import { useState } from 'react';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { useWalletsBreakdownQuery } from '../../../../../hooks/useWalletsBreakdownQuery';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';

type Props = {
  providers: Provider[];
  savedProviders: Record<ProviderEnum, Provider>;
};

const MAGIC_WALLET_NAMES = ['magiclink', 'magicemailotp', 'magicsocial'];

const TURNKEY_WALLET_NAMES = ['turnkey', 'turnkeyhd'];

export const useShouldShowWalletsWarningModal = ({
  savedProviders,
  providers,
}: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();

  const [showMagicEmbeddedWalletsWarning, setShowMagicEmbeddedWalletsWarning] =
    useState(false);
  const [
    showEmbeddedWalletsChangeWarning,
    setShowEmbeddedWalletsChangeWarning,
  ] = useState(false);

  const [currentEnablingProvider, setCurrentEnablingProvider] = useState<
    Provider | undefined
  >();
  const [currentDisablingProvider, setCurrentDisablingProvider] = useState<
    Provider | undefined
  >();

  const [walletsAmount, setWalletsAmount] = useState({
    coinbaseWaas: 0,
    magic: 0,
    turnkey: 0,
  });

  const { refetch: refetchWalletsBreakdown } = useWalletsBreakdownQuery(
    {
      environmentId: activeEnvironment?.id || '',
    },
    {
      enabled: false,
      onSuccess: (data) => {
        const magicAndEmbeddedWalletsFromBreakdown = data?.filter(
          (wallet) =>
            MAGIC_WALLET_NAMES.includes(wallet.walletName || '') ||
            TURNKEY_WALLET_NAMES.includes(wallet.walletName || '') ||
            wallet.walletName === 'coinbasempc',
        );

        const sumOfMagicWalletsFromBreakdown =
          magicAndEmbeddedWalletsFromBreakdown?.reduce((total, item) => {
            if (MAGIC_WALLET_NAMES.includes(item.walletName || '')) {
              return total + (item.count || 0);
            }
            return total;
          }, 0);

        const sumOfTurnkeyWalletsFromBreakdown =
          magicAndEmbeddedWalletsFromBreakdown?.reduce((total, item) => {
            if (TURNKEY_WALLET_NAMES.includes(item.walletName || '')) {
              return total + (item.count || 0);
            }
            return total;
          }, 0);

        const sumOfCoinbaseWaasWalletsFromBreakdown =
          magicAndEmbeddedWalletsFromBreakdown?.find(
            (wallet) => wallet.walletName === 'coinbasempc',
          )?.count || 0;

        setWalletsAmount({
          coinbaseWaas: sumOfCoinbaseWaasWalletsFromBreakdown,
          magic: sumOfMagicWalletsFromBreakdown,
          turnkey: sumOfTurnkeyWalletsFromBreakdown,
        });

        const currentlyEnablingProvider = providers.find(
          (provider) =>
            !savedProviders[provider.provider]?.enabledAt && provider.enabledAt,
        );
        setCurrentEnablingProvider(currentlyEnablingProvider);

        const currentlyDisablingProvider = providers.find(
          (provider) =>
            savedProviders[provider.provider]?.enabledAt && !provider.enabledAt,
        );
        setCurrentDisablingProvider(currentlyDisablingProvider);

        const currentlyDisablingProviderHasAnyWallets =
          // VARIANT 1: Disabling previously enabled magic and there are already magic wallets created
          (currentlyDisablingProvider?.provider === ProviderEnum.MagicLink &&
            sumOfMagicWalletsFromBreakdown > 0) ||
          // VARIANT 2: Enabling magic when previously CoinbaseWaas was enabled
          // and there are CoinbaseWaas wallets created
          (currentlyDisablingProvider?.provider === ProviderEnum.CoinbaseWaas &&
            sumOfCoinbaseWaasWalletsFromBreakdown > 0) ||
          // VARIANT 3: Enabling magic when previously Turnkey was enabled and there are Turnkey wallets created
          (currentlyDisablingProvider?.provider === ProviderEnum.Turnkey &&
            sumOfTurnkeyWalletsFromBreakdown > 0);

        // This should show when changing from magic to turnkey or coinbaseWaas
        const shouldShowMagicEmbeddedWalletsWarning =
          // First check if one of enbling/disabling providers is MagicLink
          // AND (one of the variants from currentlyDisablingProviderHasAnyWallets)
          (currentlyEnablingProvider?.provider === ProviderEnum.MagicLink ||
            currentlyDisablingProvider?.provider === ProviderEnum.MagicLink) &&
          currentlyDisablingProviderHasAnyWallets;

        /* istanbul ignore else */
        if (shouldShowMagicEmbeddedWalletsWarning) {
          setShowMagicEmbeddedWalletsWarning(true);
          return;
        }

        // This should show when changing from turnkey to coinbaseWaas
        // Or when disabling already enabled embedded provider
        const shouldShowEmbeddedWalletsChangeWarning =
          // First check if one of enbling/disabling providers is CoinbaseWaas
          // or Turnkey AND (one of the variants from currentlyDisablingProviderHasAnyWallets)
          (currentlyEnablingProvider?.provider === ProviderEnum.CoinbaseWaas ||
            currentlyEnablingProvider?.provider === ProviderEnum.Turnkey ||
            currentlyDisablingProvider?.provider ===
              ProviderEnum.CoinbaseWaas ||
            currentlyDisablingProvider?.provider === ProviderEnum.Turnkey) &&
          currentlyDisablingProviderHasAnyWallets;

        /* istanbul ignore else */
        if (shouldShowEmbeddedWalletsChangeWarning) {
          setShowEmbeddedWalletsChangeWarning(true);
        }
      },
      refetchOnMount: true,
      refetchOnWindowFocus: false,
    },
  );

  return {
    currentDisablingProvider,
    currentEnablingProvider,
    refetchWalletsBreakdown,
    setShowEmbeddedWalletsChangeWarning,
    setShowMagicEmbeddedWalletsWarning,
    showEmbeddedWalletsChangeWarning,
    showMagicEmbeddedWalletsWarning,
    walletsAmount,
  };
};
