import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { PageHeader } from '../../components/PageHeader';
import { SaveBanner } from '../../components/SaveBanner';

import { DeeplinkUrlRow } from './DeeplinkUrlRow';
import styles from './Security.module.scss';
import { JwtExpirationRow } from './JwtExpirationRow';
import { DomainsRow } from './DomainsRow';
import { useProjectSettingsUpdate } from './helpers';
import { AccountMfaRow } from './AccountMfaRow';

export const Security: FC = () => {
  const { t } = useTranslation();

  const { enableMfa } = useFlags();

  const { isLoading } = useUpdateProjectSettings();

  const {
    handleReset: handleSecurityReset,
    handleSaveProjectSettings: handleSaveSecuritySettings,
    projectSettingsHasChanged: projectSecuritySettingsHasChanged,
  } = useProjectSettingsUpdate('security');

  const {
    handleReset: handleSDKReset,
    handleSaveProjectSettings: handleSaveSDKSettings,
    projectSettingsHasChanged: projectSDKSettingsHasChanged,
  } = useProjectSettingsUpdate('sdk');

  const combinedHandleSave = () => {
    handleSaveSDKSettings();
    handleSaveSecuritySettings();
  };

  const combinedHandleReset = () => {
    handleSDKReset();
    handleSecurityReset();
  };

  return (
    <section className={styles.wrapper}>
      <PageHeader
        title={t('v2.page.security.title')}
        description={t('v2.page.security.description')}
      />

      {enableMfa && <AccountMfaRow />}
      <DomainsRow />
      <DeeplinkUrlRow />
      <JwtExpirationRow />
      <AnimatePresence mode='wait'>
        {(projectSDKSettingsHasChanged ||
          projectSecuritySettingsHasChanged) && (
          <SaveBanner
            handleOnSave={combinedHandleSave}
            handleOnCancel={combinedHandleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>
    </section>
  );
};
