import classNames from 'classnames';

import { Spinner } from '../Spinner';

import styles from './Button.module.css';
import { ButtonComponent } from './Button.types';

export const Button: ButtonComponent = ({
  large,
  children,
  className = '',
  onClick,
  disabled,
  type = 'button',
  testId,
  leading,
  suffix,
  loading = false,
  variant = 'primary',
  full,
}) => {
  const buttonClassName = classNames(
    styles.container,
    className,
    styles[variant],
    {
      [styles.large]: large,
      [styles.full]: full,
      [styles.disabled]: disabled && !loading,
    },
  );

  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      data-testid={testId}
      onClick={onClick}
      disabled={disabled || loading}
      className={buttonClassName}
    >
      {leading && <div className={classNames(styles.leading)}>{leading}</div>}
      {children}
      {suffix && <div className={classNames(styles.suffix)}>{suffix}</div>}

      {loading && (
        <div className={styles['spinner-container']}>
          <Spinner className={styles.spinner} />
        </div>
      )}
    </button>
  );
};
