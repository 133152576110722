import { FC, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../../../../app/context/SettingsContext';

type RecoveryToggleProps = {
  authenticatorEnabled: boolean;
  disabled: boolean;
};

export const RecoveryToggle: FC<RecoveryToggleProps> = ({
  disabled,
  authenticatorEnabled,
}) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const emailRecoveryEnabled = Boolean(
    settings[activeEnvironmentType]?.sdk?.embeddedWallets?.emailRecoveryEnabled,
  );
  const initialEmailRecoveryEnabled = Boolean(
    initialSettings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.emailRecoveryEnabled,
  );

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isSettingSaved = Boolean(
    emailRecoveryEnabled === initialEmailRecoveryEnabled,
  );

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType]?.sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            emailRecoveryEnabled: event.target.checked,
          },
        },
      },
    });

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.recovery.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.recovery.description',
      )}
      descriptionModificators={{
        i18nKey:
          'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.recovery.description',
      }}
      accordionKey='recovery'
      inputProps={{
        checked:
          isTurnkeyEnabled && authenticatorEnabled && emailRecoveryEnabled,
        disabled: !isTurnkeyEnabled || !authenticatorEnabled || disabled,
        id: 'recovery',
        isSaved: isTurnkeySettingSaved && isSettingSaved,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
    />
  );
};
