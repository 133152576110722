import { useCallback } from 'react';

import {
  BillingSubscription,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';

export const useScope = (subscription: BillingSubscription | undefined) => {
  const isScopeAllowed = useCallback(
    (scope: string) => {
      if (!subscription) return false;
      return subscription.scope.includes(scope);
    },
    [subscription],
  );

  const isScopeAdvanced = (scope: string) =>
    Object.values<string>(SubscriptionAdvancedScopeEnum).includes(scope);

  return {
    isScopeAdvanced,
    isScopeAllowed,
  };
};
