import React from 'react';

import { useLDClient } from 'launchdarkly-react-client-sdk';
import isEqual from 'lodash/isEqual';

import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

export type UseLDIdentifyArgsType = {
  activeEnvironmentId?: string;
  activeOrganizationId?: string;
  activeProjectId?: string;
};

type LDContext = {
  custom?: {
    environmentId?: string;
    organizationId?: string;
    projectId?: string;
  };
  email?: string;
  key?: string;
};

const isSameContext = (next: LDContext, prev?: LDContext) => {
  if (!prev) return false;
  const isSameKey = next.key === prev.key;
  const isSameEmail = next?.email === prev?.email;
  const isSameCustom = isEqual(next?.custom, prev?.custom);
  return isSameKey && isSameEmail && isSameCustom;
};

const isTestEmail = (userEmail?: string) => {
  if (process.env.DYNAMIC_ENV === 'production') return false;
  if (!userEmail) return false;
  return userEmail.startsWith('test_qa_');
};

export const useLDIdentify = ({
  activeOrganizationId,
  activeProjectId,
  activeEnvironmentId,
}: UseLDIdentifyArgsType = {}) => {
  const ldClient = useLDClient();
  const { user } = useDynamicContext();
  React.useEffect(() => {
    const previousContext = ldClient?.getContext();

    // In integration testing, we do not want the user information to be written to the context.
    if (user && !isTestEmail(user?.email)) {
      const context: LDContext = {
        custom: {
          environmentId: activeEnvironmentId,
          organizationId: activeOrganizationId,
          projectId: activeProjectId,
        },
        email: user.email,
        key: user.email || user.userId,
      };
      if (!isSameContext(context, previousContext as LDContext)) {
        ldClient?.identify(context);
      }
    } else if (previousContext?.key !== 'anonymous') {
      // If in the previous context the user information was stored, and the user is undefined
      // then a logout must have occurred. In this case, we updates context withthe anonymous user.
      ldClient?.identify({
        anonymous: true,
        key: 'anonymous',
      });
    }
  }, [
    ldClient,
    user,
    activeOrganizationId,
    activeProjectId,
    activeEnvironmentId,
  ]);
};
