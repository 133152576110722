import { useTranslation } from 'react-i18next';

import {
  findWalletBookWallet,
  useWalletBookContext,
  WalletIcon,
} from '@dynamic-labs/wallet-book';

import { Typography } from '../../../../../components/Typography';
import { toPercent } from '../../../utils';

export type WalletValuesProps = {
  title: string;
  wallets?: {
    count?: number;
    walletName?: string;
  }[];
  walletsAmount?: number;
};

const embeddedWallets = ['turnkey', 'turnkeyhd', 'coinbasempc'];

// We want to combine all embedded wallets into one in out analytics
const combineEmbeddedWallets = (wallets: WalletValuesProps['wallets']) => {
  const embeddedWalletsSum =
    wallets
      ?.filter((wallet) => embeddedWallets.includes(wallet.walletName || ''))
      .reduce((acc, wallet) => acc + (wallet.count || 0), 0) || 0;

  const walletsWithoutEmbeddedWallets =
    wallets?.filter(
      (wallet) => !embeddedWallets.includes(wallet.walletName || ''),
    ) || [];

  if (embeddedWalletsSum) {
    walletsWithoutEmbeddedWallets.push({
      count: embeddedWalletsSum,
      walletName: 'turnkey', // Leave as turnkey so we get right icon
    });
  }

  return walletsWithoutEmbeddedWallets;
};

export const WalletValues = ({
  title,
  wallets,
  walletsAmount,
}: WalletValuesProps) => {
  const { walletBook } = useWalletBookContext();
  const { t } = useTranslation();

  const groupedWallets = combineEmbeddedWallets(wallets);

  // Display 'Dynamic embedded wallet' for embedded wallets
  const safeGetWalletBookWalletName = (walletName?: string) => {
    if (walletName === 'turnkey') {
      return 'Dynamic embedded wallet';
    }

    const walletBookWallet = findWalletBookWallet(walletBook, walletName);

    if (!walletBookWallet?.name) {
      return 'Unknown';
    }

    return walletBookWallet.name;
  };

  // sort wallets by count in descending order
  const sortedWallets = [...groupedWallets]
    .sort((a, b) => (a.count || 0) - (b.count || 0))
    .reverse();

  return (
    <>
      <div className='flex justify-between items-center py-3'>
        <Typography
          variant='paragraph-2'
          weight='medium'
          color='gray-2'
          className='capitalize'
        >
          {title}
        </Typography>
        <Typography variant='code-1' weight='medium' color='gray-2'>
          {typeof walletsAmount === 'number'
            ? walletsAmount.toLocaleString()
            : walletsAmount}{' '}
          {(walletsAmount && walletsAmount > 1) || walletsAmount === 0
            ? t('analytics.widgets.wallet_distribution.wallets')
            : t('analytics.widgets.wallet_distribution.wallet')}
        </Typography>
      </div>

      {sortedWallets?.map((wallet) => (
        <div
          key={wallet.walletName}
          className='flex gap-3 py-3 items-center'
          data-testid='wallet-value'
        >
          <WalletIcon walletKey={wallet.walletName} className='w-6 h-6' />
          <Typography variant='paragraph-3' weight='medium'>
            {safeGetWalletBookWalletName(wallet.walletName)}
          </Typography>

          <Typography variant='code-2' weight='medium' className='ml-auto'>
            {toPercent(walletsAmount || 0, wallet.count || 0)}
          </Typography>
        </div>
      ))}
    </>
  );
};
