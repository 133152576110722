import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  BillingSubscription,
  BillingSubscriptionPlanTypeEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';
import { useLocalStorage } from '@dynamic-labs/sdk-react-core';

import { logger } from '../../services/logger';
import { organizationsApi } from '../../services/api';
import { useDashboardContext } from '../DashboardContext';
import { CLOSED_INFO_BANNERS_MAP } from '../../utils/constants';
import { useScope } from '../../hooks/useScope';

type SubscriptionContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

type SubscriptionContextType = {
  advancedScopeArray: string[];
  checks: {
    isAdvancedPlan: boolean;
    isAdvancedTrialWithPaymentMethod: boolean;
    isAdvancedTrialWithoutPaymentMethod: boolean;
    isAdvancedWithPaymentMethod: boolean;
    isAdvancedWithoutPaymentMethod: boolean;
    isEnterprisePlan: boolean;
    isFreePlan: boolean;
    isFreeWithPaymentMethod: boolean;
    isFreeWithoutPaymentMethod: boolean;
    isStandardPlan: boolean;
    isStandardWithPaymentMethod: boolean;
    isStandardWithoutPaymentMethod: boolean;
  };
  closedInfoAlertsMap: string[];
  fetchSubscriptionInfo: () => Promise<void>;
  isScopeAdvanced: (arg: string) => boolean;
  isScopeAllowed: (arg: string) => boolean;
  isUpgrading: boolean;
  setClosedInfoAlertsMap: (arg: string[]) => void;
  subscription: BillingSubscription | undefined;
  upgradeToAdvancePlan: () => Promise<void>;
};

export const SubscriptionContext = createContext<
  SubscriptionContextType | undefined
>(undefined);

export const SubscriptionContextProvider = ({
  children,
}: SubscriptionContextProviderProps) => {
  const { activeOrganizationId } = useDashboardContext();
  const [subscription, setSubscription] = useState<
    BillingSubscription | undefined
  >();
  const { isScopeAdvanced, isScopeAllowed } = useScope(subscription);

  const [isUpgrading, setIsUpgrading] = useState(false);

  const [closedInfoAlertsMap, setClosedInfoAlertsMap] = useLocalStorage(
    CLOSED_INFO_BANNERS_MAP,
    [] as string[],
  );

  const [advancedScopeArray] = useState(
    Object.values(SubscriptionAdvancedScopeEnum),
  );

  const { planType, hasPaymentMethod, inTrial } = subscription || {};

  // this is a object which contain all cases related to the notion table here
  // https://www.notion.so/dynamic-labs/Billing-self-service-states-fa31aaf2f68846659926400c713e17b8
  const checks = useMemo(() => {
    const isAdvancedPlan =
      planType === BillingSubscriptionPlanTypeEnum.Advanced;

    const isStandardPlan =
      planType === BillingSubscriptionPlanTypeEnum.Standard;

    const isFreePlan = planType === BillingSubscriptionPlanTypeEnum.Free;

    const isEnterprisePlan =
      planType === BillingSubscriptionPlanTypeEnum.Enterprise;

    const isAdvancedTrialWithoutPaymentMethod =
      isAdvancedPlan && Boolean(inTrial) && !hasPaymentMethod;

    const isAdvancedWithPaymentMethod =
      isAdvancedPlan && Boolean(hasPaymentMethod) && !inTrial;

    const isAdvancedWithoutPaymentMethod =
      isAdvancedPlan && !hasPaymentMethod && !inTrial;

    const isAdvancedTrialWithPaymentMethod =
      isAdvancedPlan && Boolean(inTrial) && Boolean(hasPaymentMethod);

    const isStandardWithPaymentMethod =
      isStandardPlan && Boolean(hasPaymentMethod);

    const isStandardWithoutPaymentMethod = isStandardPlan && !hasPaymentMethod;

    const isFreeWithPaymentMethod = isFreePlan && Boolean(hasPaymentMethod);

    const isFreeWithoutPaymentMethod = isFreePlan && !hasPaymentMethod;

    return {
      isAdvancedPlan,
      isAdvancedTrialWithPaymentMethod,
      isAdvancedTrialWithoutPaymentMethod,
      isAdvancedWithPaymentMethod,
      isAdvancedWithoutPaymentMethod,
      isEnterprisePlan,
      isFreePlan,
      isFreeWithPaymentMethod,
      isFreeWithoutPaymentMethod,
      isStandardPlan,
      isStandardWithPaymentMethod,
      isStandardWithoutPaymentMethod,
    };
  }, [hasPaymentMethod, inTrial, planType]);

  const fetchSubscriptionInfo = useCallback(async () => {
    try {
      if (!activeOrganizationId) return;
      const response =
        await organizationsApi.getBillingSubscriptionByOrganization({
          organizationId: activeOrganizationId,
        });

      setSubscription(response);
    } catch (error) {
      logger.error('error getting organization subscription', {
        activeOrganizationId,
        error,
      });
    }
  }, [activeOrganizationId]);

  const upgradeToAdvancePlan = useCallback(async () => {
    try {
      if (!activeOrganizationId) return;

      setIsUpgrading(true);
      const response =
        await organizationsApi.upgradeSubscriptionForOrganization({
          organizationId: activeOrganizationId,
        });
      setSubscription(response);
    } catch (e) {
      logger.error(e);
    } finally {
      setIsUpgrading(false);
    }
  }, [activeOrganizationId]);

  useEffect(() => {
    fetchSubscriptionInfo();

    // DYN-1824: we need this intervall to avoid Stripe's session expiration that happens every 5 minutes
    const fiveMinutesToMs = 60 * 1000 * 5;
    const refreshInterval = setInterval(fetchSubscriptionInfo, fiveMinutesToMs);
    return () => clearInterval(refreshInterval);
  }, [fetchSubscriptionInfo]);

  const value = useMemo(
    () => ({
      advancedScopeArray,
      checks,
      closedInfoAlertsMap,
      fetchSubscriptionInfo,
      isScopeAdvanced,
      isScopeAllowed,
      isUpgrading,
      setClosedInfoAlertsMap,
      subscription,
      upgradeToAdvancePlan,
    }),
    [
      advancedScopeArray,
      checks,
      closedInfoAlertsMap,
      fetchSubscriptionInfo,
      isScopeAdvanced,
      isScopeAllowed,
      isUpgrading,
      setClosedInfoAlertsMap,
      subscription,
      upgradeToAdvancePlan,
    ],
  );

  return (
    <SubscriptionContext.Provider value={value}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export const useSubscriptionContext = () => {
  const context = useContext(SubscriptionContext);

  if (context === undefined) {
    throw new Error(
      'usage of useSubscriptionContext not wrapped in `SubscriptionContextProvider`.',
    );
  }

  return context;
};
