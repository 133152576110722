import { ReactNode } from 'react';

import { m } from 'framer-motion';

type Props = {
  children: ReactNode;
  className?: string;
  play: boolean;
};

export const TwirlAnimation = ({ children, className, play }: Props) => {
  const variants = {
    play: {
      rotate: [0, 0.3, -0.3, 0.3, -0.3, 0],
      transition: {
        duration: 0.7,
      },
    },
    stop: { rotate: 0 },
  };

  return (
    <m.div
      style={{ originX: 0.5, originY: 0.5 }}
      variants={variants}
      animate={play ? 'play' : 'stop'}
      className={className}
      initial={false}
    >
      {children}
    </m.div>
  );
};
