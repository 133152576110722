import { m } from 'framer-motion';

import { CloseIcon } from '@dynamic-labs/sdk-react-core';

import { Typography } from '../Typography';

import styles from './Toaster.module.css';

type VariantEnum = 'default' | 'success' | 'error' | 'warning';

type PositionEnum = 'fixed' | 'absolute';

type Props = {
  message: string;
  onExit: VoidFunction;
  position?: PositionEnum;
  variant?: VariantEnum;
};

export const Toaster = ({
  message,
  variant = 'default',
  position = 'fixed',
  onExit,
}: Props): JSX.Element => {
  const initialYPosition = position === 'absolute' ? -10 : 10;
  const animateYPosition = position === 'absolute' ? 10 : 35;
  return (
    <m.div
      data-testid='toaster-container'
      className={`${styles.container} ${styles[variant]} ${
        position === 'absolute' ? styles['position-absolute'] : ''
      }`}
      initial={{ opacity: 0, x: '-50%', y: initialYPosition }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.2,
        },
        y: animateYPosition,
      }}
      exit={{ opacity: 0, x: '-50%', y: initialYPosition }}
    >
      <Typography
        weight='bold'
        variant='paragraph-1'
        className={styles[variant]}
      >
        {message}
      </Typography>
      <CloseIcon data-testid='exit' className={styles.icon} onClick={onExit} />
    </m.div>
  );
};
