/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SimulateUserOpRequest
 */
export interface SimulateUserOpRequest {
    /**
     * 
     * @type {string}
     * @memberof SimulateUserOpRequest
     */
    chainId: string;
    /**
     * 
     * @type {object}
     * @memberof SimulateUserOpRequest
     */
    operation: object;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof SimulateUserOpRequest
     */
    entryPoint: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateUserOpRequest
     */
    domain?: string;
    /**
     * 
     * @type {string}
     * @memberof SimulateUserOpRequest
     */
    value: string;
}

export function SimulateUserOpRequestFromJSON(json: any): SimulateUserOpRequest {
    return SimulateUserOpRequestFromJSONTyped(json, false);
}

export function SimulateUserOpRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SimulateUserOpRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chainId': json['chainId'],
        'operation': json['operation'],
        'entryPoint': json['entryPoint'],
        'domain': !exists(json, 'domain') ? undefined : json['domain'],
        'value': json['value'],
    };
}

export function SimulateUserOpRequestToJSON(value?: SimulateUserOpRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chainId': value.chainId,
        'operation': value.operation,
        'entryPoint': value.entryPoint,
        'domain': value.domain,
        'value': value.value,
    };
}

