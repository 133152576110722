import { Connection } from '@solana/web3.js';
import { JsonRpcProvider } from 'ethers';
import { Provider } from 'starknet';

type TestRPCUrlOptions = {
  networkId?: number;
  rpcUrl: string;
};

export const testEvmRpcUrl = async (args: TestRPCUrlOptions) => {
  const provider = new JsonRpcProvider(args.rpcUrl);
  try {
    const result = await provider.getNetwork();
    return Number(result.chainId) === args.networkId;
  } catch (e) {
    return false;
  }
};

export const testSolanaRpcUrl = async (args: TestRPCUrlOptions) => {
  try {
    const provider = new Connection(args.rpcUrl);
    await provider.getVersion();

    return true;
  } catch (e) {
    return false;
  }
};
export const testStarknetRpcUrl = async (args: TestRPCUrlOptions) => {
  try {
    const provider = new Provider({ rpc: { nodeUrl: args.rpcUrl } });
    const block = await provider.getBlock('latest');
    return block !== undefined;
  } catch (e) {
    return false;
  }
};
