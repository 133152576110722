import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import PopupModal from '../../../../components/PopupModal';
import { PopupHeader } from '../../../../components/PopupModal/PopupHeader/PopupHeader';
import { WebhookForm } from '../WebhookForm';

import styles from './CreateWebhookModal.module.css';

export const CreateWebhookModal = ({
  setShowModal,
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) => (
  <PopupModal handleClose={() => setShowModal(false)} className={styles.portal}>
    <PopupHeader headingText={t('webhooks.create_webhook_modal.title')} />
    <WebhookForm setShowModal={setShowModal} />
  </PopupModal>
);
