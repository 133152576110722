/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserFilterableFieldsEnum {
    All = 'all',
    Id = 'id',
    WalletPublicKey = 'walletPublicKey',
    Email = 'email',
    Alias = 'alias',
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    JobTitle = 'jobTitle',
    Wallet = 'wallet',
    Username = 'username',
    AccountId = 'accountId'
}

export function UserFilterableFieldsEnumFromJSON(json: any): UserFilterableFieldsEnum {
    return UserFilterableFieldsEnumFromJSONTyped(json, false);
}

export function UserFilterableFieldsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFilterableFieldsEnum {
    return json as UserFilterableFieldsEnum;
}

export function UserFilterableFieldsEnumToJSON(value?: UserFilterableFieldsEnum | null): any {
    return value as any;
}

