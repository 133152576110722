import { FC, ReactElement } from 'react';

import {
  EnvironmentsDropdown,
  EnvironmentsDropdownProps,
} from '../EnvironmentsDropdown';
import { Icon } from '../Icon';
import { TextButton } from '../TextButton';
import {
  UserOrganizationsDropdown,
  type UserOrganizationsDropdownProps,
} from '../UserOrganizationsDropdown';
import { DynamicTopBarWidget } from '../DynamicTopBarWidget';
import {
  UserProjectsDropdown,
  type UserProjectsDropdownProps,
} from '../UserProjectsDropdown';
import { SparklesIcon } from '../../icons';

import styles from './TopBar.module.scss';

export type HelpButtonProps = {
  href: string;
  icon: ReactElement;
  text: string;
};

export type TopBarProps = {
  environmentsDropdown: EnvironmentsDropdownProps;
  helpButtons: HelpButtonProps[];
  openChangelog: () => void;
  organizationsDropdown: UserOrganizationsDropdownProps;
  projectsDropdown: UserProjectsDropdownProps;
};

export const TopBar: FC<TopBarProps> = ({
  organizationsDropdown,
  projectsDropdown,
  environmentsDropdown,
  helpButtons,
  openChangelog,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.topBar}>
      <div className={styles.dropdown}>
        <UserOrganizationsDropdown
          activeOrganizationId={organizationsDropdown.activeOrganizationId}
          organizationActions={organizationsDropdown.organizationActions}
          organizations={organizationsDropdown.organizations}
          organizationsDropdownCTA={
            organizationsDropdown.organizationsDropdownCTA
          }
          setActiveOrganization={organizationsDropdown.setActiveOrganization}
          isLoading={organizationsDropdown.isLoading}
        />
      </div>

      <div className={styles.dropdown}>
        <UserProjectsDropdown
          activeProjectId={projectsDropdown.activeProjectId}
          setActiveProject={projectsDropdown.setActiveProject}
          projectActions={projectsDropdown.projectActions}
          projects={projectsDropdown.projects}
          projectsDropdownCTA={projectsDropdown.projectsDropdownCTA}
          isLoading={projectsDropdown.isLoading}
        />
      </div>

      <div className={styles.dropdown}>
        <EnvironmentsDropdown
          activeEnvironmentType={environmentsDropdown.activeEnvironmentType}
          environments={environmentsDropdown.environments}
          setActiveEnvironmentType={
            environmentsDropdown.setActiveEnvironmentType
          }
        />
      </div>

      <div className={styles.help}>
        <TextButton
          onClick={openChangelog}
          text='Product updates'
          leading={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <Icon
              icon={<SparklesIcon />}
              size={24}
              className={styles['icon--primary']}
            />
          }
          className={styles.button}
          variant='paragraph-2'
          weight='medium'
          color='primary-1'
        />
        {helpButtons.map((button) => (
          <TextButton
            text={button.text}
            leading={
              <Icon icon={button.icon} size={24} className={styles.icon} />
            }
            href={button.href}
            as='a'
            className={styles.button}
            variant='paragraph-2'
            weight='medium'
            key={button.text}
          />
        ))}

        <DynamicTopBarWidget />
      </div>
    </div>
  </div>
);
