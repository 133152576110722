/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SmsVerificationCreateResponse
 */
export interface SmsVerificationCreateResponse {
    /**
     * 
     * @type {string}
     * @memberof SmsVerificationCreateResponse
     */
    verificationUUID: string;
    /**
     * This field contains the phone number in the country. This could also contain the area code within a country.
     * @type {string}
     * @memberof SmsVerificationCreateResponse
     */
    phoneNumber: string;
    /**
     * This field contains the phone international country code. See https://countrycode.org/
     * @type {string}
     * @memberof SmsVerificationCreateResponse
     */
    phoneCountryCode: string;
    /**
     * ISO-3166 two-character country code. See https://en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
     * @type {string}
     * @memberof SmsVerificationCreateResponse
     */
    isoCountryCode: string;
}

export function SmsVerificationCreateResponseFromJSON(json: any): SmsVerificationCreateResponse {
    return SmsVerificationCreateResponseFromJSONTyped(json, false);
}

export function SmsVerificationCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SmsVerificationCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'verificationUUID': json['verificationUUID'],
        'phoneNumber': json['phoneNumber'],
        'phoneCountryCode': json['phoneCountryCode'],
        'isoCountryCode': json['isoCountryCode'],
    };
}

export function SmsVerificationCreateResponseToJSON(value?: SmsVerificationCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'verificationUUID': value.verificationUUID,
        'phoneNumber': value.phoneNumber,
        'phoneCountryCode': value.phoneCountryCode,
        'isoCountryCode': value.isoCountryCode,
    };
}

