import { MutableRefObject, useCallback, useEffect, useState } from 'react';

export const useElementSize = <T extends HTMLElement | null>(
  ref: MutableRefObject<T>,
) => {
  const [rect, setRect] = useState<DOMRect | undefined>();

  const onResize = useCallback(() => {
    const element = ref.current;

    if (!element) return;

    setRect(element.getBoundingClientRect());
  }, [ref, setRect]);

  useEffect(() => {
    const element = ref.current;

    if (!element) return;

    onResize();

    element.addEventListener('resize', onResize);

    return () => element.removeEventListener('resize', onResize);
  }, [onResize, ref]);

  return rect;
};
