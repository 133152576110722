/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SdkViewSectionType {
    Email = 'email',
    EmailAndPhone = 'emailAndPhone',
    Phone = 'phone',
    Separator = 'separator',
    Social = 'social',
    Text = 'text',
    Wallet = 'wallet'
}

export function SdkViewSectionTypeFromJSON(json: any): SdkViewSectionType {
    return SdkViewSectionTypeFromJSONTyped(json, false);
}

export function SdkViewSectionTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkViewSectionType {
    return json as SdkViewSectionType;
}

export function SdkViewSectionTypeToJSON(value?: SdkViewSectionType | null): any {
    return value as any;
}

