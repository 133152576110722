/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum VisitorFilterableFieldsEnum {
    All = 'all',
    Id = 'id',
    WalletPublicKey = 'walletPublicKey'
}

export function VisitorFilterableFieldsEnumFromJSON(json: any): VisitorFilterableFieldsEnum {
    return VisitorFilterableFieldsEnumFromJSONTyped(json, false);
}

export function VisitorFilterableFieldsEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): VisitorFilterableFieldsEnum {
    return json as VisitorFilterableFieldsEnum;
}

export function VisitorFilterableFieldsEnumToJSON(value?: VisitorFilterableFieldsEnum | null): any {
    return value as any;
}

