import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Typography } from '../Typography';
import { DropdownMenu } from '../DropdownMenu';
import { OpacityAnimation } from '../../animations';
import { Icon } from '../Icon';

import styles from './ChainDropdownMenu.module.scss';
import { ChainDropdownMenuProps } from './ChainDropdownMenu.types';
import {
  ChainDropdownItemsWrapper,
  ChainDropdownSelectedValueRenderer,
} from './components';
import { chainToIconMap, chainToNameMap } from './helpers/chains';

export const ChainDropdownMenu: FC<ChainDropdownMenuProps> = ({
  chains,
  setSelectedChain,
  selectedChain,
  zIndex,
  disabled,
}) => (
  <AnimatePresence mode='wait'>
    <OpacityAnimation>
      <DropdownMenu
        value={selectedChain}
        onSelect={(value) => value && setSelectedChain(value)} // Don't allow undefined value
        options={[
          ...chains.map((chain) => (
            <DropdownMenu.Item
              value={chain.chainName}
              className={styles.item}
              rawOption={chain}
              key={chain.chainName}
            >
              <Icon icon={chainToIconMap[chain.chainName]} size={20} />

              <Typography
                variant='paragraph-2'
                weight='medium'
                className={styles.text}
              >
                {chainToNameMap[chain.chainName]}
              </Typography>
            </DropdownMenu.Item>
          )),
        ]}
        className={styles.container}
        allowUndefinedValue={false} // Don't allow undefined value
        itemsSideOffset={8}
        selectedValueWrapper={ChainDropdownSelectedValueRenderer}
        itemsWrapper={ChainDropdownItemsWrapper}
        zIndex={zIndex}
        disabled={disabled}
      />
    </OpacityAnimation>
  </AnimatePresence>
);
