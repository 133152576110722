import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  ProjectSettingsSdkEmbeddedWallets,
  ProviderEnum,
  SupportedSecurityMethods,
} from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { AdminIcon } from '../../../../../../../icons';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { ChangedSettingsIndicator } from '../../../shared';

import styles from './SecurityMethodToggle.module.scss';
import { PasskeySettingsDrawer } from './DrawerContent/PasskeySettingsDrawer';
import { OTCSettingsDrawer } from './DrawerContent/OTCSettingsDrawer';

const DOCS_URL_PASSKEY =
  'https://docs.dynamic.xyz/wallets/embedded-wallets/passkeys';
const DOCS_URL_OTC =
  'https://docs.dynamic.xyz/wallets/embedded-wallets/one-time-codes';

type SecurityMethods = keyof Omit<SupportedSecurityMethods, 'password'>;

type SecurityMethodToggleProps = {
  disabled: boolean;
  method: SecurityMethods;
};

export const SecurityMethodToggle: FC<SecurityMethodToggleProps> = ({
  method,
  disabled,
}) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash:
      method === 'passkey'
        ? ModalHashLocations.DynamicEmbeddedWalletMFAPasskey
        : ModalHashLocations.DynamicEmbeddedWalletMFAOneTimeCode,
  });

  const { t } = useTranslation();

  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const supportedSecurityMethodsSettings =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.supportedSecurityMethods;
  const initialSupportedSecurityMethodsSettings =
    initialSettings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.supportedSecurityMethods;

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isSecurityMethodSaved = Boolean(
    supportedSecurityMethodsSettings?.[method]?.isEnabled ===
      initialSupportedSecurityMethodsSettings?.[method]?.isEnabled,
  );

  // If we are disabling passkey we should also disable forceAuthenticatorAtSignup
  const checkIfShouldDisableForceAuthenticatorAtSignup = (
    value: boolean,
    currentValue: ProjectSettingsSdkEmbeddedWallets | undefined,
  ) => {
    if (method === 'passkey' && !value) {
      return {
        forceAuthenticatorAtSignup: false,
      };
    }

    return {
      forceAuthenticatorAtSignup: currentValue?.forceAuthenticatorAtSignup,
    };
  };

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType]?.sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            ...checkIfShouldDisableForceAuthenticatorAtSignup(
              event.target.checked,
              settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            ),
            supportedSecurityMethods: {
              ...settings[activeEnvironmentType]?.sdk?.embeddedWallets
                ?.supportedSecurityMethods,
              [method]: {
                ...settings[activeEnvironmentType]?.sdk?.embeddedWallets
                  ?.supportedSecurityMethods?.[method],
                isEnabled: event.target.checked,
              },
            },
          },
        },
      },
    });
  };

  const isSecurityMethodEnabled =
    isTurnkeyEnabled &&
    Boolean(supportedSecurityMethodsSettings?.[method]?.isEnabled);

  const getChangesIndicatorAmount = () => {
    let amount = 0;

    if (
      settings[activeEnvironmentType]?.sdk?.embeddedWallets
        ?.forceAuthenticatorAtSignup
    ) {
      amount += 1;
    }

    if (
      settings[activeEnvironmentType]?.sdk?.embeddedWallets
        ?.emailRecoveryEnabled
    ) {
      amount += 1;
    }

    return amount;
  };

  return (
    <>
      <SingleToggleCard
        allowExpand={false}
        title={t(
          `v2.page.embedded_wallets.dynamic_section.mfa.method.${method}.title`,
        )}
        description={t(
          `v2.page.embedded_wallets.dynamic_section.mfa.method.${method}.description`,
        )}
        accordionKey={method}
        inputProps={{
          checked: isSecurityMethodEnabled,
          disabled: !isTurnkeyEnabled || disabled,
          id: method,
          isSaved: isTurnkeySettingSaved && isSecurityMethodSaved,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        onCardClick={
          isSecurityMethodEnabled ? () => handleShowModal(true) : undefined
        }
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        RightSideTags={
          isSecurityMethodEnabled && method === 'passkey'
            ? [
                {
                  Tag: (
                    <ChangedSettingsIndicator
                      amount={getChangesIndicatorAmount()}
                    />
                  ),
                  id: 'changes-indicator',
                },
              ]
            : undefined
        }
      />

      <SideDrawer
        title={t(
          `v2.page.embedded_wallets.dynamic_section.mfa.method.${method}.drawer.title`,
        )}
        description={t(
          `v2.page.embedded_wallets.dynamic_section.mfa.method.${method}.drawer.description`,
        )}
        isOpen={showModal && isSecurityMethodEnabled}
        handleClose={() => handleShowModal(false)}
        className={styles.modal}
        docs={{
          text: t(
            `v2.page.embedded_wallets.dynamic_section.mfa.method.${method}.drawer.docs`,
          ),
          url: method === 'passkey' ? DOCS_URL_PASSKEY : DOCS_URL_OTC,
        }}
      >
        {method === 'passkey' && <PasskeySettingsDrawer disabled={disabled} />}
        {method === 'email' && <OTCSettingsDrawer disabled={disabled} />}
      </SideDrawer>
    </>
  );
};
