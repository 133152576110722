import {
  add,
  differenceInDays,
  endOfDay,
  format,
  isBefore,
  startOfDay,
} from 'date-fns';

import { FillMissingDaysProps } from './fillMissingDays.types';

export const fillMissingDays = <T>({
  data,
  endDate,
  fillFn,
  getter,
  startDate,
}: FillMissingDaysProps<T>): T[] => {
  const numberOfDaysInRange =
    differenceInDays(endOfDay(endDate), startOfDay(startDate)) + 1;

  const mapDateToDatum: { [key: string]: T } = data.reduce(
    (dateToDatum, datum) => {
      const dateKey = format(getter(datum), 'yyyy-MM-dd');

      return {
        ...dateToDatum,
        [dateKey]: datum,
      };
    },
    {},
  );

  for (let index = 0; index < numberOfDaysInRange; index += 1) {
    const date = add(startDate, { days: index });

    const dateKey = format(date, 'yyyy-MM-dd');

    const hasDataForDate = !!mapDateToDatum[dateKey];

    if (!hasDataForDate) {
      mapDateToDatum[dateKey] = fillFn(date);
    }
  }

  return Object.values(mapDateToDatum).sort((a, b) =>
    isBefore(getter(b), getter(a)) ? 1 : -1,
  );
};
