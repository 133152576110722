import { useState } from 'react';

import {
  UserFilterableFieldsEnum,
  ChainEnum,
  ProviderEnum,
} from '@dynamic-labs/sdk-api';

export const useUsersSearchFilter = () => {
  const [usersSearchFilter, setUsersSearchFilter] =
    useState<UserFilterableFieldsEnum>(
      UserFilterableFieldsEnum.WalletPublicKey,
    );
  const [usersChainFilter, setUsersChainFilter] = useState<ChainEnum>();

  const [usersProviderFilter, setUsersProviderFilter] =
    useState<ProviderEnum>();

  return {
    setUsersChainFilter,
    setUsersProviderFilter,
    setUsersSearchFilter,
    usersChainFilter,
    usersProviderFilter,
    usersSearchFilter,
  };
};
