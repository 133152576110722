import { OpacityAnimation, SlideAnimation } from '../../../../animations';
import { DropdownMenuDropdownSubItemsWrapper } from '../../../DropdownMenu/DropdownMenu.types';

import styles from './UserOrganizationsDropdownSubItemsWrapper.module.scss';

export const UserOrganizationsDropdownSubItemsWrapper: DropdownMenuDropdownSubItemsWrapper =
  ({ children }) => (
    <OpacityAnimation>
      <SlideAnimation startPosition='down' endPosition='down'>
        <div className={styles.container}>{children}</div>
      </SlideAnimation>
    </OpacityAnimation>
  );
