/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';
import {
    UserFilterableFieldsEnum,
    UserFilterableFieldsEnumFromJSON,
    UserFilterableFieldsEnumFromJSONTyped,
    UserFilterableFieldsEnumToJSON,
} from './UserFilterableFieldsEnum';

/**
 * 
 * @export
 * @interface UserSearchFilterParams
 */
export interface UserSearchFilterParams {
    /**
     * 
     * @type {string}
     * @memberof UserSearchFilterParams
     */
    filterValue?: string;
    /**
     * 
     * @type {UserFilterableFieldsEnum}
     * @memberof UserSearchFilterParams
     */
    filterColumn?: UserFilterableFieldsEnum;
    /**
     * 
     * @type {ProviderEnum}
     * @memberof UserSearchFilterParams
     */
    filterSocialProvider?: ProviderEnum;
    /**
     * 
     * @type {ChainEnum}
     * @memberof UserSearchFilterParams
     */
    chain?: ChainEnum;
}

export function UserSearchFilterParamsFromJSON(json: any): UserSearchFilterParams {
    return UserSearchFilterParamsFromJSONTyped(json, false);
}

export function UserSearchFilterParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserSearchFilterParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filterValue': !exists(json, 'filterValue') ? undefined : json['filterValue'],
        'filterColumn': !exists(json, 'filterColumn') ? undefined : UserFilterableFieldsEnumFromJSON(json['filterColumn']),
        'filterSocialProvider': !exists(json, 'filterSocialProvider') ? undefined : ProviderEnumFromJSON(json['filterSocialProvider']),
        'chain': !exists(json, 'chain') ? undefined : ChainEnumFromJSON(json['chain']),
    };
}

export function UserSearchFilterParamsToJSON(value?: UserSearchFilterParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filterValue': value.filterValue,
        'filterColumn': UserFilterableFieldsEnumToJSON(value.filterColumn),
        'filterSocialProvider': ProviderEnumToJSON(value.filterSocialProvider),
        'chain': ChainEnumToJSON(value.chain),
    };
}

