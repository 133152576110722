/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateMfaToken,
    CreateMfaTokenFromJSON,
    CreateMfaTokenFromJSONTyped,
    CreateMfaTokenToJSON,
} from './CreateMfaToken';

/**
 * 
 * @export
 * @interface MFAAuthRecoveryDevicePostRequest
 */
export interface MFAAuthRecoveryDevicePostRequest {
    /**
     * 
     * @type {CreateMfaToken}
     * @memberof MFAAuthRecoveryDevicePostRequest
     */
    createMfaToken?: CreateMfaToken;
    /**
     * A one time use recovery code
     * @type {string}
     * @memberof MFAAuthRecoveryDevicePostRequest
     */
    code: string;
}

export function MFAAuthRecoveryDevicePostRequestFromJSON(json: any): MFAAuthRecoveryDevicePostRequest {
    return MFAAuthRecoveryDevicePostRequestFromJSONTyped(json, false);
}

export function MFAAuthRecoveryDevicePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAAuthRecoveryDevicePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createMfaToken': !exists(json, 'createMfaToken') ? undefined : CreateMfaTokenFromJSON(json['createMfaToken']),
        'code': json['code'],
    };
}

export function MFAAuthRecoveryDevicePostRequestToJSON(value?: MFAAuthRecoveryDevicePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createMfaToken': CreateMfaTokenToJSON(value.createMfaToken),
        'code': value.code,
    };
}

