import { createContext, useContext, PropsWithChildren, useMemo } from 'react';

import { NameServiceData } from '@dynamic-labs/sdk-api-core';

import { useWalletDomains } from '../../hooks/useWalletDomains';

type WalletDomainsContextProps = {
  isLoadingDomains: boolean;
  primaryEnsDomain: NameServiceData | undefined;
};

const WalletDomainsContext = createContext<
  WalletDomainsContextProps | undefined
>(undefined);

export const WalletDomainsProvider = ({ children }: PropsWithChildren) => {
  const { isLoading, primaryEnsDomain } = useWalletDomains();

  const value = useMemo(
    () => ({
      isLoadingDomains: isLoading,
      primaryEnsDomain,
    }),
    [primaryEnsDomain, isLoading],
  );

  return (
    <WalletDomainsContext.Provider value={value}>
      {children}
    </WalletDomainsContext.Provider>
  );
};

export const useWalletDomainsContext = () => {
  const context = useContext(WalletDomainsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useWalletDomainsContext not wrapped in `WalletDomainsContextProvider`.',
    );
  }

  return context;
};
