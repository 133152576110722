/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthSettings,
    AuthSettingsFromJSON,
    AuthSettingsFromJSONTyped,
    AuthSettingsToJSON,
} from './AuthSettings';
import {
    Duration,
    DurationFromJSON,
    DurationFromJSONTyped,
    DurationToJSON,
} from './Duration';
import {
    ExternalAuth,
    ExternalAuthFromJSON,
    ExternalAuthFromJSONTyped,
    ExternalAuthToJSON,
} from './ExternalAuth';
import {
    HCaptchaSettings,
    HCaptchaSettingsFromJSON,
    HCaptchaSettingsFromJSONTyped,
    HCaptchaSettingsToJSON,
} from './HCaptchaSettings';
import {
    MFASettings,
    MFASettingsFromJSON,
    MFASettingsFromJSONTyped,
    MFASettingsToJSON,
} from './MFASettings';

/**
 * 
 * @export
 * @interface ProjectSettingsSecurity
 */
export interface ProjectSettingsSecurity {
    /**
     * 
     * @type {Duration}
     * @memberof ProjectSettingsSecurity
     */
    jwtDuration?: Duration;
    /**
     * 
     * @type {HCaptchaSettings}
     * @memberof ProjectSettingsSecurity
     */
    hCaptcha?: HCaptchaSettings;
    /**
     * 
     * @type {MFASettings}
     * @memberof ProjectSettingsSecurity
     */
    mfa?: MFASettings;
    /**
     * 
     * @type {AuthSettings}
     * @memberof ProjectSettingsSecurity
     */
    auth?: AuthSettings;
    /**
     * 
     * @type {ExternalAuth}
     * @memberof ProjectSettingsSecurity
     */
    externalAuth?: ExternalAuth;
}

export function ProjectSettingsSecurityFromJSON(json: any): ProjectSettingsSecurity {
    return ProjectSettingsSecurityFromJSONTyped(json, false);
}

export function ProjectSettingsSecurityFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSecurity {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jwtDuration': !exists(json, 'jwtDuration') ? undefined : DurationFromJSON(json['jwtDuration']),
        'hCaptcha': !exists(json, 'hCaptcha') ? undefined : HCaptchaSettingsFromJSON(json['hCaptcha']),
        'mfa': !exists(json, 'mfa') ? undefined : MFASettingsFromJSON(json['mfa']),
        'auth': !exists(json, 'auth') ? undefined : AuthSettingsFromJSON(json['auth']),
        'externalAuth': !exists(json, 'externalAuth') ? undefined : ExternalAuthFromJSON(json['externalAuth']),
    };
}

export function ProjectSettingsSecurityToJSON(value?: ProjectSettingsSecurity | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jwtDuration': DurationToJSON(value.jwtDuration),
        'hCaptcha': HCaptchaSettingsToJSON(value.hCaptcha),
        'mfa': MFASettingsToJSON(value.mfa),
        'auth': AuthSettingsToJSON(value.auth),
        'externalAuth': ExternalAuthToJSON(value.externalAuth),
    };
}

