import { AnimatePresence } from 'framer-motion';

import { Icon } from '../../../Icon';
import { Tag } from '../../../Tag';
import { Typography } from '../../../Typography';
import { SelectorIcon } from '../../../../icons';
import { DropdownMenuRenderValue } from '../../../DropdownMenu/DropdownMenu.types';
import { Organization } from '../../UserOrganizationsDropdown.types';
import { OrganizationLogo } from '../OrganizationLogo';

import styles from './UserOrganizationsSelectedValueRenderer.module.scss';

export const UserOrganizationsSelectedValueRenderer: DropdownMenuRenderValue<
  string,
  Organization
> = (option) => {
  const {
    props: { rawOption },
  } = option;

  return (
    <div className={styles.container}>
      <AnimatePresence initial={false}>
        {rawOption && (
          <div className={styles.item}>
            <OrganizationLogo
              name={rawOption.name}
              logoUrl={rawOption.logoUrl}
            />
            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {rawOption.name}
            </Typography>

            {rawOption.subscriptionPlanType && (
              <Tag
                color='gray'
                size='medium'
                className={styles.tag}
                text={rawOption.subscriptionPlanType}
              />
            )}
          </div>
        )}
      </AnimatePresence>
      <div className={styles.trigger}>
        <Icon icon={<SelectorIcon />} size={20} />
      </div>
    </div>
  );
};
