export const dynamicTopBarWidgetCssOverrides = `
.dynamic-widget-inline-controls__network-picker-main {
  display: none;
}

.dynamic-widget-inline-controls {
  background-color: transparent;
  width: max-content;
  min-width: max-content;
  border-radius: 0;
}

.account-control__container--active, .account-control__container:hover {
  background-color: rgba(85, 90, 124, 0.03);
}

.account-control__container {
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out;
}
`;
