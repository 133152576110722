import React from 'react';

import { OpacityAnimation, SlideAnimation } from '../../../../animations';
import { DropdownMenuDropdownWrapper } from '../../../DropdownMenu/DropdownMenu.types';

import styles from './EnvironmentsDropdownItemsWrapper.module.scss';

export const EnvironmentsDropdownItemsWrapper: DropdownMenuDropdownWrapper = ({
  children,
  targetWidth,
}) => (
  <OpacityAnimation>
    <SlideAnimation startPosition='down' endPosition='down'>
      <div style={{ width: targetWidth }} className={styles.container}>
        {children}
      </div>
    </SlideAnimation>
  </OpacityAnimation>
);
