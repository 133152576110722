/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnrampConfiguration,
    OnrampConfigurationFromJSON,
    OnrampConfigurationFromJSONTyped,
    OnrampConfigurationToJSON,
} from './OnrampConfiguration';

/**
 * 
 * @export
 * @interface SupportedOnrampsResponse
 */
export interface SupportedOnrampsResponse {
    /**
     * 
     * @type {Array<OnrampConfiguration>}
     * @memberof SupportedOnrampsResponse
     */
    onramps?: Array<OnrampConfiguration>;
}

export function SupportedOnrampsResponseFromJSON(json: any): SupportedOnrampsResponse {
    return SupportedOnrampsResponseFromJSONTyped(json, false);
}

export function SupportedOnrampsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedOnrampsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'onramps': !exists(json, 'onramps') ? undefined : ((json['onramps'] as Array<any>).map(OnrampConfigurationFromJSON)),
    };
}

export function SupportedOnrampsResponseToJSON(value?: SupportedOnrampsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'onramps': value.onramps === undefined ? undefined : ((value.onramps as Array<any>).map(OnrampConfigurationToJSON)),
    };
}

