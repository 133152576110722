/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ExportFormatEnum {
    Csv = 'csv'
}

export function ExportFormatEnumFromJSON(json: any): ExportFormatEnum {
    return ExportFormatEnumFromJSONTyped(json, false);
}

export function ExportFormatEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportFormatEnum {
    return json as ExportFormatEnum;
}

export function ExportFormatEnumToJSON(value?: ExportFormatEnum | null): any {
    return value as any;
}

