import { AnimatePresence, m } from 'framer-motion';

import { CheckIcon, classNames } from '@dynamic-labs/sdk-react-core';

import { Typography } from '../Typography';

import styles from './Accordion.module.css';
import { AccordionPropsType } from './Accordion.types';

export const Accordion = ({
  content,
  onClick,
  isActive,
  isCompleted,
}: AccordionPropsType) => {
  const stepClassName = classNames(styles['accordion-item'], {
    [styles['accordion-item--completed']]: isCompleted,
    [styles['accordion-item--active']]: isActive,
  });

  const { id, title, content: stepContent } = content;

  return (
    <m.div key={`step-${id}`} className={stepClassName}>
      <AnimatePresence key={`step-${id}`}>
        <>
          <div className={styles['accordion-item--icon']}>
            {!isCompleted ? id : <CheckIcon />}
          </div>
          <div className={styles['quick-start-stepper__copy-wrapper']}>
            <button
              type='button'
              className={styles['quick-start-stepper__accordion--trigger']}
              onClick={onClick}
            >
              <Typography
                className={styles['accordion-item--title']}
                variant='paragraph-3'
                weight='bold'
              >
                {title}
              </Typography>
            </button>

            {stepContent && isActive && (
              <m.div
                key={`answer-${id}`}
                initial={{ height: 0, opacity: 0 }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                  transition: {
                    duration: 0.3,
                  },
                }}
                exit={{ height: 0, opacity: 0 }}
                className={styles['accordion-item--content']}
              >
                {stepContent}
              </m.div>
            )}
          </div>
        </>
      </AnimatePresence>
    </m.div>
  );
};
