/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Allowlist,
    AllowlistFromJSON,
    AllowlistToJSON,
    AllowlistEntriesResponse,
    AllowlistEntriesResponseFromJSON,
    AllowlistEntriesResponseToJSON,
    AllowlistEntry,
    AllowlistEntryFromJSON,
    AllowlistEntryToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MethodNotAllowed,
    MethodNotAllowedFromJSON,
    MethodNotAllowedToJSON,
    PostAllowlistEntriesRequest,
    PostAllowlistEntriesRequestFromJSON,
    PostAllowlistEntriesRequestToJSON,
    PostAllowlistsRequest,
    PostAllowlistsRequestFromJSON,
    PostAllowlistsRequestToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateAllowlistByEnvironmentIdRequest {
    environmentId: string;
    postAllowlistsRequest: PostAllowlistsRequest;
}

export interface DeleteAllowlistByIdRequest {
    allowlistId: string;
}

export interface DeleteAllowlistEntryByIdRequest {
    allowlistEntryId: string;
}

export interface DisableAllowlistByIdRequest {
    allowlistId: string;
}

export interface EnableAllowlistByIdRequest {
    allowlistId: string;
}

export interface GetAllowlistsByEnvironmentIdRequest {
    environmentId: string;
}

export interface GetAllowlistsByIdRequest {
    allowlistId: string;
}

export interface GetEntriesByAllowlistIdRequest {
    allowlistId: string;
    orderBy?: string;
    offset?: number;
    limit?: number;
    query?: string;
}

export interface PostEntryByAllowlistIdRequest {
    allowlistId: string;
    postAllowlistEntriesRequest: PostAllowlistEntriesRequest;
}

export interface UpdateAllowlistByIdRequest {
    allowlistId: string;
    postAllowlistsRequest: PostAllowlistsRequest;
}

/**
 * 
 */
export class AllowlistsApi extends runtime.BaseAPI {

    /**
     * Create new allowlist for a environment
     */
    async createAllowlistByEnvironmentIdRaw(requestParameters: CreateAllowlistByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Allowlist>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createAllowlistByEnvironmentId.');
        }

        if (requestParameters.postAllowlistsRequest === null || requestParameters.postAllowlistsRequest === undefined) {
            throw new runtime.RequiredError('postAllowlistsRequest','Required parameter requestParameters.postAllowlistsRequest was null or undefined when calling createAllowlistByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/allowlists`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAllowlistsRequestToJSON(requestParameters.postAllowlistsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistFromJSON(jsonValue));
    }

    /**
     * Create new allowlist for a environment
     */
    async createAllowlistByEnvironmentId(requestParameters: CreateAllowlistByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<Allowlist> {
        const response = await this.createAllowlistByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an allowlist
     */
    async deleteAllowlistByIdRaw(requestParameters: DeleteAllowlistByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling deleteAllowlistById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an allowlist
     */
    async deleteAllowlistById(requestParameters: DeleteAllowlistByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAllowlistByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Delete an allowlist entry
     */
    async deleteAllowlistEntryByIdRaw(requestParameters: DeleteAllowlistEntryByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.allowlistEntryId === null || requestParameters.allowlistEntryId === undefined) {
            throw new runtime.RequiredError('allowlistEntryId','Required parameter requestParameters.allowlistEntryId was null or undefined when calling deleteAllowlistEntryById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlistEntries/{allowlistEntryId}`.replace(`{${"allowlistEntryId"}}`, encodeURIComponent(String(requestParameters.allowlistEntryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an allowlist entry
     */
    async deleteAllowlistEntryById(requestParameters: DeleteAllowlistEntryByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteAllowlistEntryByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Disable the allow list
     */
    async disableAllowlistByIdRaw(requestParameters: DisableAllowlistByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Allowlist>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling disableAllowlistById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}/disable`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistFromJSON(jsonValue));
    }

    /**
     * Disable the allow list
     */
    async disableAllowlistById(requestParameters: DisableAllowlistByIdRequest, initOverrides?: RequestInit): Promise<Allowlist> {
        const response = await this.disableAllowlistByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable the allowlist
     */
    async enableAllowlistByIdRaw(requestParameters: EnableAllowlistByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Allowlist>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling enableAllowlistById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}/enable`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistFromJSON(jsonValue));
    }

    /**
     * Enable the allowlist
     */
    async enableAllowlistById(requestParameters: EnableAllowlistByIdRequest, initOverrides?: RequestInit): Promise<Allowlist> {
        const response = await this.enableAllowlistByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all allowlists for a environment
     */
    async getAllowlistsByEnvironmentIdRaw(requestParameters: GetAllowlistsByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Allowlist>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getAllowlistsByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/allowlists`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AllowlistFromJSON));
    }

    /**
     * Get all allowlists for a environment
     */
    async getAllowlistsByEnvironmentId(requestParameters: GetAllowlistsByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<Array<Allowlist>> {
        const response = await this.getAllowlistsByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get allowlist by id
     */
    async getAllowlistsByIdRaw(requestParameters: GetAllowlistsByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Allowlist>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling getAllowlistsById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistFromJSON(jsonValue));
    }

    /**
     * Get allowlist by id
     */
    async getAllowlistsById(requestParameters: GetAllowlistsByIdRequest, initOverrides?: RequestInit): Promise<Allowlist> {
        const response = await this.getAllowlistsByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all entries for an allowlist
     */
    async getEntriesByAllowlistIdRaw(requestParameters: GetEntriesByAllowlistIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllowlistEntriesResponse>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling getEntriesByAllowlistId.');
        }

        const queryParameters: any = {};

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}/entries`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistEntriesResponseFromJSON(jsonValue));
    }

    /**
     * Get all entries for an allowlist
     */
    async getEntriesByAllowlistId(requestParameters: GetEntriesByAllowlistIdRequest, initOverrides?: RequestInit): Promise<AllowlistEntriesResponse> {
        const response = await this.getEntriesByAllowlistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new entry for an allowlist
     */
    async postEntryByAllowlistIdRaw(requestParameters: PostEntryByAllowlistIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AllowlistEntry>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling postEntryByAllowlistId.');
        }

        if (requestParameters.postAllowlistEntriesRequest === null || requestParameters.postAllowlistEntriesRequest === undefined) {
            throw new runtime.RequiredError('postAllowlistEntriesRequest','Required parameter requestParameters.postAllowlistEntriesRequest was null or undefined when calling postEntryByAllowlistId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}/entries`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostAllowlistEntriesRequestToJSON(requestParameters.postAllowlistEntriesRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistEntryFromJSON(jsonValue));
    }

    /**
     * Create a new entry for an allowlist
     */
    async postEntryByAllowlistId(requestParameters: PostEntryByAllowlistIdRequest, initOverrides?: RequestInit): Promise<AllowlistEntry> {
        const response = await this.postEntryByAllowlistIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the outcome, scope, or name of an allowlist entry by ID
     */
    async updateAllowlistByIdRaw(requestParameters: UpdateAllowlistByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Allowlist>> {
        if (requestParameters.allowlistId === null || requestParameters.allowlistId === undefined) {
            throw new runtime.RequiredError('allowlistId','Required parameter requestParameters.allowlistId was null or undefined when calling updateAllowlistById.');
        }

        if (requestParameters.postAllowlistsRequest === null || requestParameters.postAllowlistsRequest === undefined) {
            throw new runtime.RequiredError('postAllowlistsRequest','Required parameter requestParameters.postAllowlistsRequest was null or undefined when calling updateAllowlistById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/allowlists/{allowlistId}`.replace(`{${"allowlistId"}}`, encodeURIComponent(String(requestParameters.allowlistId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PostAllowlistsRequestToJSON(requestParameters.postAllowlistsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AllowlistFromJSON(jsonValue));
    }

    /**
     * Update the outcome, scope, or name of an allowlist entry by ID
     */
    async updateAllowlistById(requestParameters: UpdateAllowlistByIdRequest, initOverrides?: RequestInit): Promise<Allowlist> {
        const response = await this.updateAllowlistByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
