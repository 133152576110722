import { AnimatePresence } from 'framer-motion';

import Header from '../SingleChain/Header';
import { Typography } from '../../../components/Typography';
import { useAccessListsContext } from '../../../context/AccessListsContext';
import { Integration } from '../../../types';
import { ModalHashLocations, useModalHashLocation } from '../utils';

import styles from './AccessControl.module.css';
import { AccessControlModal } from './AccessControlModal';

export type AccessControlProps = Omit<
  Integration,
  'category' | 'url' | 'chain' | 'walletDescription'
>;

export const AccessControl = ({
  Icon,
  content,
  title,
  comingSoon,
  scope,
}: AccessControlProps) => {
  const { sandboxIsActive, liveIsActive } = useAccessListsContext();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.AccessControl,
  });

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <AccessControlModal
            title={title}
            icon={Icon}
            content={content.full}
            setShowModal={handleShowModal}
            scope={scope}
          />
        )}
      </AnimatePresence>
      <button
        aria-label='Open access control modal'
        type='button'
        className={styles.container}
        onClick={() => (comingSoon ? null : handleShowModal(true))}
      >
        <Header
          Icon={Icon}
          name={title}
          isLiveActive={liveIsActive}
          isSandboxActive={sandboxIsActive}
          comingSoon={comingSoon}
          scope={scope}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {content.short}
        </Typography>
      </button>
    </>
  );
};
