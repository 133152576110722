/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateProjectResponseProjectProjectEnvironments,
    CreateProjectResponseProjectProjectEnvironmentsFromJSON,
    CreateProjectResponseProjectProjectEnvironmentsFromJSONTyped,
    CreateProjectResponseProjectProjectEnvironmentsToJSON,
} from './CreateProjectResponseProjectProjectEnvironments';

/**
 * 
 * @export
 * @interface CreateProjectResponseProject
 */
export interface CreateProjectResponseProject {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponseProject
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponseProject
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectResponseProject
     */
    organizationId?: string;
    /**
     * 
     * @type {Array<CreateProjectResponseProjectProjectEnvironments>}
     * @memberof CreateProjectResponseProject
     */
    projectEnvironments?: Array<CreateProjectResponseProjectProjectEnvironments>;
}

export function CreateProjectResponseProjectFromJSON(json: any): CreateProjectResponseProject {
    return CreateProjectResponseProjectFromJSONTyped(json, false);
}

export function CreateProjectResponseProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectResponseProject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'projectEnvironments': !exists(json, 'projectEnvironments') ? undefined : ((json['projectEnvironments'] as Array<any>).map(CreateProjectResponseProjectProjectEnvironmentsFromJSON)),
    };
}

export function CreateProjectResponseProjectToJSON(value?: CreateProjectResponseProject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'organizationId': value.organizationId,
        'projectEnvironments': value.projectEnvironments === undefined ? undefined : ((value.projectEnvironments as Array<any>).map(CreateProjectResponseProjectProjectEnvironmentsToJSON)),
    };
}

