/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChainalysisConfiguration,
    ChainalysisConfigurationFromJSON,
    ChainalysisConfigurationToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
} from '../models';

export interface DisableSanctionsApiByEnvironmentIdRequest {
    environmentId: string;
}

export interface EnableSanctionsApiByEnvironmentIdRequest {
    environmentId: string;
}

export interface GetChainalysisByEnvironmentIdRequest {
    environmentId: string;
}

/**
 * 
 */
export class ChainalysisApi extends runtime.BaseAPI {

    /**
     * Disables the Sanctions API
     */
    async disableSanctionsApiByEnvironmentIdRaw(requestParameters: DisableSanctionsApiByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChainalysisConfiguration>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling disableSanctionsApiByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/integrations/chainalysis/sanctions/disable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChainalysisConfigurationFromJSON(jsonValue));
    }

    /**
     * Disables the Sanctions API
     */
    async disableSanctionsApiByEnvironmentId(requestParameters: DisableSanctionsApiByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<ChainalysisConfiguration> {
        const response = await this.disableSanctionsApiByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable the Sanctions API
     */
    async enableSanctionsApiByEnvironmentIdRaw(requestParameters: EnableSanctionsApiByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChainalysisConfiguration>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling enableSanctionsApiByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/integrations/chainalysis/sanctions/enable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChainalysisConfigurationFromJSON(jsonValue));
    }

    /**
     * Enable the Sanctions API
     */
    async enableSanctionsApiByEnvironmentId(requestParameters: EnableSanctionsApiByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<ChainalysisConfiguration> {
        const response = await this.enableSanctionsApiByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find the Chainalysis configuration for an environment.
     */
    async getChainalysisByEnvironmentIdRaw(requestParameters: GetChainalysisByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ChainalysisConfiguration>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getChainalysisByEnvironmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/integrations/chainalysis`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChainalysisConfigurationFromJSON(jsonValue));
    }

    /**
     * Find the Chainalysis configuration for an environment.
     */
    async getChainalysisByEnvironmentId(requestParameters: GetChainalysisByEnvironmentIdRequest, initOverrides?: RequestInit): Promise<ChainalysisConfiguration> {
        const response = await this.getChainalysisByEnvironmentIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
