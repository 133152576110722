import {
  createContext,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  RefObject,
  useState,
  useEffect,
} from 'react';

type SaveBannerContextType = {
  saveBannerParentRef: RefObject<HTMLDivElement>;
  setShouldShowSaveBanner: (shouldShowSaveBanner: boolean) => void;
  setShouldWaveSaveBanner: (shouldWaveSaveBanner: boolean) => void;
  shouldShowSaveBanner: boolean;
  shouldWaveSaveBanner: boolean;
};

type Props = {
  children: ReactNode;
};

export const SaveBannerContext = createContext<
  SaveBannerContextType | undefined
>(undefined);

export const SaveBannerContextProvider = ({ children }: Props) => {
  const [shouldShowSaveBanner, setShouldShowSaveBanner] = useState(false);
  const [shouldWaveSaveBanner, setShouldWaveSaveBanner] = useState(false);
  const saveBannerParentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!shouldWaveSaveBanner) return;

    setTimeout(() => {
      setShouldWaveSaveBanner(false);
    }, 700);
  }, [shouldWaveSaveBanner]);

  const value = useMemo(
    () => ({
      saveBannerParentRef,
      setShouldShowSaveBanner,
      setShouldWaveSaveBanner,
      shouldShowSaveBanner,
      shouldWaveSaveBanner,
    }),
    [shouldShowSaveBanner, shouldWaveSaveBanner],
  );

  return (
    <SaveBannerContext.Provider value={value}>
      {children}
    </SaveBannerContext.Provider>
  );
};

export const useSaveBannerContext = () => {
  const context = useContext(SaveBannerContext);

  if (context === undefined) {
    throw new Error(
      'usage of useSaveBannerContext not wrapped in `SaveBannerContextProvider`.',
    );
  }

  return context;
};
