import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { ChainEnum, ProviderEnum } from '@dynamic-labs/sdk-api';

import { SearchIcon } from '../../../../../icons';
import { ChainFilterDropdown } from '../ChainFilterDropdown';
import { ProviderFilterDropdown } from '../ProviderFilterDropdown';
import { FiltersDropdown } from '../FiltersDropdown/FiltersDropdown';

import styles from './TableFilters.module.css';

type Props<T> = {
  chainFilter: ChainEnum | undefined;
  filters: T[];
  onSearchQuery: (searchQuery?: string) => void;
  providerFilter?: ProviderEnum | undefined;
  searchFilter: T;
  setChainFilter: Dispatch<SetStateAction<ChainEnum | undefined>>;
  setProviderFilter?: Dispatch<SetStateAction<ProviderEnum | undefined>>;
  setSearchFilter: Dispatch<SetStateAction<T>>;
};

export const TableFilters = <T extends string>({
  filters,
  onSearchQuery,
  searchFilter,
  setSearchFilter,
  chainFilter,
  setChainFilter,
  providerFilter,
  setProviderFilter,
}: Props<T>): JSX.Element => {
  const { t } = useTranslation();
  const callbackDelay = 500;

  const handleUserSearchQueryChange = useDebouncedCallback((value) => {
    onSearchQuery(value);
  }, callbackDelay);

  return (
    <div className='flex'>
      <div className={styles['search-container']}>
        <SearchIcon className={styles.icon} />
        <input
          placeholder={t('users_management_table.userid_search')}
          onChange={(e) => handleUserSearchQueryChange(e.target.value)}
          className={styles.search}
        />
      </div>
      <div className='mr-4'>
        <FiltersDropdown
          filters={filters}
          setSearchFilter={setSearchFilter}
          searchFilter={searchFilter}
        />
      </div>
      <div className='mr-4'>
        <ChainFilterDropdown
          setChainFilter={setChainFilter}
          chainFilter={chainFilter}
        />
      </div>
      {setProviderFilter && (
        <div className='mr-4'>
          <ProviderFilterDropdown
            setProviderFilter={setProviderFilter}
            providerFilter={providerFilter}
          />
        </div>
      )}
    </div>
  );
};
