/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WhenToImplementEnum {
    Yesterday = 'yesterday',
    NextTwoWeeks = 'nextTwoWeeks',
    NextTwoMonths = 'nextTwoMonths',
    JustBrowsing = 'justBrowsing'
}

export function WhenToImplementEnumFromJSON(json: any): WhenToImplementEnum {
    return WhenToImplementEnumFromJSONTyped(json, false);
}

export function WhenToImplementEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WhenToImplementEnum {
    return json as WhenToImplementEnum;
}

export function WhenToImplementEnumToJSON(value?: WhenToImplementEnum | null): any {
    return value as any;
}

