import { FC, useState } from 'react';

import { useFormik, FormikProvider, FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

import {
  InviteSendRequest,
  MemberRoleField,
  RoleEnum,
  UpdateMemberRoleRequest,
} from '@dynamic-labs/sdk-api';

import { stripEmptyString } from '../../../../utils/stripEmptyString';
import { logger } from '../../../../services/logger';
import { useDashboardContext } from '../../../../context/DashboardContext';
import { Member, useMembersContext } from '../../../../context/MembersContext';
import { invitesApi, membersApi } from '../../../../services/api';
import { ErrorInfo } from '../../../../components/ErrorInfo';
import { Typography } from '../../../../components/Typography';

import InputFields from './InputFields';
import FormActions from './FormActions';
import styles from './form.module.css';

interface Props {
  isUpdateMode?: boolean;
  memberToUpdate?: Member;
  setShowModal: VoidFunction;
}

const DefaultRole = RoleEnum.Admin;

const Form: FC<Props> = ({
  setShowModal,
  isUpdateMode = false,
  memberToUpdate,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { activeOrganizationId } = useDashboardContext();
  const { fetchMembers, setMembers } = useMembersContext();
  const [errorMessage, setErrorMessage] = useState<string>();
  const formik = useFormik({
    initialValues: {
      invites:
        isUpdateMode && memberToUpdate
          ? [
              {
                alias: memberToUpdate.alias,
                email: memberToUpdate.email ?? '',
                role: memberToUpdate.role ?? DefaultRole,
                walletPublicKey: memberToUpdate.walletPublicKey ?? '',
              },
            ]
          : [
              {
                alias: '',
                email: '',
                role: DefaultRole,
                walletPublicKey: '',
              },
            ],
    },
    onSubmit: (values) => {
      values.invites.forEach(
        async ({ alias, email, role, walletPublicKey }) => {
          try {
            setLoading(true);

            if (!activeOrganizationId) {
              throw new Error('Active Organization Id is undefined');
            }

            if (isUpdateMode && memberToUpdate) {
              const memberRole: MemberRoleField = {
                role: values.invites[0].role,
              };
              const updateMembersRoleRequest: UpdateMemberRoleRequest = {
                memberId: memberToUpdate.id ?? '',
                memberRoleField: memberRole,
              };

              await membersApi.updateMemberRole(updateMembersRoleRequest);

              const members = await fetchMembers();
              setMembers(members);
              setShowModal();
            } else {
              // formik could have empty string values, so remove and use undefined
              const inviteSendRequest: InviteSendRequest = {
                alias: stripEmptyString(alias),
                email: stripEmptyString(email || ''),
                role: role ?? DefaultRole,
                walletPublicKey: stripEmptyString(walletPublicKey || ''),
              };

              await invitesApi.createInvite({
                inviteSendRequest,
                organizationId: activeOrganizationId,
              });

              const members = await fetchMembers();
              setMembers(members);

              setShowModal();
            }
          } catch (e: any) {
            if (e.status === 409) {
              setErrorMessage(t('admin.invites_modal.errorAlreadyExists'));
            } else if (e.status === 422) {
              if (isUpdateMode) {
                setErrorMessage(t('admin.invites_modal.unableToUpdateRole'));
              }
            }
            logger.error(e);
          } finally {
            setLoading(false);
          }
        },
      );
    },
  });

  const errorHeading = isUpdateMode
    ? t('admin.invites_modal.errorUpdateMode')
    : t('admin.invites_modal.error');

  const initialActiveField =
    isUpdateMode && memberToUpdate && !memberToUpdate.walletPublicKey
      ? 'email'
      : 'wallet';

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit}>
        {errorMessage && (
          <ErrorInfo className='mb-5'>
            <span>
              <Typography
                className={styles.errorHeading}
                variant='paragraph-2'
                weight='medium'
              >
                {errorHeading}
              </Typography>
              <Typography className={styles.errorMessage} variant='paragraph-1'>
                {errorMessage}
              </Typography>
            </span>
          </ErrorInfo>
        )}
        <FieldArray
          name='invites'
          render={(values) => (
            <div>
              {formik.values.invites.map((_, index) => (
                <InputFields
                  // eslint-disable-next-line react/no-array-index-key
                  key={`invite-${index}`}
                  formik={formik}
                  index={index}
                  isUpdateMode={isUpdateMode}
                  initialActiveField={initialActiveField}
                />
              ))}
              <FormActions
                setShowModal={setShowModal}
                values={values}
                loading={loading}
                isUpdateMode={isUpdateMode}
              />
            </div>
          )}
        />
      </form>
    </FormikProvider>
  );
};

export default Form;
