import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';

import { logger } from '../../../../../services/logger';
import { SearchIcon } from '../../../../../../icons';
import { useFetchAllowListEntries } from '../helpers/useFetchAllowListEntries';
import { Typography } from '../../../../../components/Typography';
import { deleteAllowListEntryById } from '../helpers/helpers';
import { AccessListEntryForm } from '../AccessListEntryForm';
import ReactTable from '../../../../../components/ReactTable';
import { Pagination } from '../../../../../components/Pagination';
import { useListEntriesColumns } from '../helpers/useListEntriesColumns';

import styles from './AccessListEntriesView.module.css';

type AccessListEntriesViewProps = {
  accessListId: string;
};

export const AccessListEntriesView = ({
  accessListId,
}: AccessListEntriesViewProps) => {
  const {
    entriesData = [],
    fetchAllowListEntries,
    offset,
    page,
    setPage,
    total = 0,
    limit,
    setOffset,
    setEntryFilter,
  } = useFetchAllowListEntries({
    allowlistId: accessListId,
  });

  const { t } = useTranslation();

  const handleSearchChange = useDebouncedCallback((value) => {
    setEntryFilter(value);
  }, 100);

  const handleDeleteClick = async (entryId: string) => {
    try {
      await deleteAllowListEntryById(entryId);
      fetchAllowListEntries();
    } catch (e) {
      logger.error(e);
    }
  };

  const { columns } = useListEntriesColumns(handleDeleteClick);

  return (
    <div>
      <div className={styles.description__container}>
        <Typography className={styles.description} as='p'>
          {t(
            'integrations.onboarding_and_kyc.access_control.access_list.add_address_to_list',
          )}
        </Typography>
      </div>

      <AccessListEntryForm
        onCreateSuccess={fetchAllowListEntries}
        accessListId={accessListId}
      />
      <div className={styles.description__container}>
        <Typography className={styles.description} as='p'>
          {t(
            'integrations.onboarding_and_kyc.access_control.access_list.addresses_list',
          )}
        </Typography>
      </div>
      <div className={styles.entries__container}>
        <div className={styles['search-container']}>
          <SearchIcon className={styles.icon} />
          <input
            placeholder={t('users_management_table.userid_search')}
            className={styles.search}
            onChange={(e) => handleSearchChange(e.target.value)}
          />
        </div>
        <div className='my-4'>
          <ReactTable columns={columns} data={entriesData} />
        </div>
        {total > 0 && (
          <Pagination
            total={total}
            forcePage={page}
            onPageChange={({ selected }) => {
              setOffset(selected * limit);
              setPage(selected);
            }}
            limit={limit}
            currentPageTotal={entriesData?.length}
            offset={offset}
          />
        )}
      </div>
    </div>
  );
};
export default AccessListEntriesView;
