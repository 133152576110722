/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportCreateRequestFilter,
    ExportCreateRequestFilterFromJSON,
    ExportCreateRequestFilterFromJSONTyped,
    ExportCreateRequestFilterToJSON,
} from './ExportCreateRequestFilter';
import {
    ExportFormatEnum,
    ExportFormatEnumFromJSON,
    ExportFormatEnumFromJSONTyped,
    ExportFormatEnumToJSON,
} from './ExportFormatEnum';
import {
    ExportModelEnum,
    ExportModelEnumFromJSON,
    ExportModelEnumFromJSONTyped,
    ExportModelEnumToJSON,
} from './ExportModelEnum';
import {
    ExportStatusEnum,
    ExportStatusEnumFromJSON,
    ExportStatusEnumFromJSONTyped,
    ExportStatusEnumToJSON,
} from './ExportStatusEnum';

/**
 * 
 * @export
 * @interface Export
 */
export interface Export {
    /**
     * 
     * @type {string}
     * @memberof Export
     */
    id: string;
    /**
     * 
     * @type {ExportFormatEnum}
     * @memberof Export
     */
    format: ExportFormatEnum;
    /**
     * 
     * @type {ExportModelEnum}
     * @memberof Export
     */
    model: ExportModelEnum;
    /**
     * 
     * @type {ExportStatusEnum}
     * @memberof Export
     */
    status: ExportStatusEnum;
    /**
     * 
     * @type {ExportCreateRequestFilter}
     * @memberof Export
     */
    filter: ExportCreateRequestFilter;
    /**
     * 
     * @type {Date}
     * @memberof Export
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Export
     */
    startedAt?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof Export
     */
    completedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Export
     */
    downloadUrl?: string;
    /**
     * When defined, this is the error message and stack trace received when attempting to process the export.
     * @type {string}
     * @memberof Export
     */
    error?: string;
}

export function ExportFromJSON(json: any): Export {
    return ExportFromJSONTyped(json, false);
}

export function ExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): Export {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'format': ExportFormatEnumFromJSON(json['format']),
        'model': ExportModelEnumFromJSON(json['model']),
        'status': ExportStatusEnumFromJSON(json['status']),
        'filter': ExportCreateRequestFilterFromJSON(json['filter']),
        'createdAt': (new Date(json['createdAt'])),
        'startedAt': !exists(json, 'startedAt') ? undefined : (json['startedAt'] === null ? null : new Date(json['startedAt'])),
        'completedAt': !exists(json, 'completedAt') ? undefined : (json['completedAt'] === null ? null : new Date(json['completedAt'])),
        'downloadUrl': !exists(json, 'downloadUrl') ? undefined : json['downloadUrl'],
        'error': !exists(json, 'error') ? undefined : json['error'],
    };
}

export function ExportToJSON(value?: Export | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'format': ExportFormatEnumToJSON(value.format),
        'model': ExportModelEnumToJSON(value.model),
        'status': ExportStatusEnumToJSON(value.status),
        'filter': ExportCreateRequestFilterToJSON(value.filter),
        'createdAt': (value.createdAt.toISOString()),
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt === null ? null : value.startedAt.toISOString()),
        'completedAt': value.completedAt === undefined ? undefined : (value.completedAt === null ? null : value.completedAt.toISOString()),
        'downloadUrl': value.downloadUrl,
        'error': value.error,
    };
}

