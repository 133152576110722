import { FC, useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, Dialog, Typography } from '@dynamic-labs/northstar';

import { logger } from '../../../../../app/services/logger';
import { useNameServicesContext } from '../../../../../app/context/NameServicesContext';

import styles from './RemoveSubdomainDialog.module.scss';

type RemoveSubdomainDialogProps = {
  onOpenChange: (open: boolean) => void;
  open: boolean;
};

export const RemoveSubdomainDialog: FC<RemoveSubdomainDialogProps> = ({
  open,
  onOpenChange,
}) => {
  const { t } = useTranslation();

  const { deleteNameServices, nameServices } = useNameServicesContext();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  /** Emits the close dialog event */
  const closeDialog = useCallback(() => {
    onOpenChange(false);
  }, [onOpenChange]);

  /** Attempts to delete the ENS domain and closes the dialog */
  const handleConfirm = useCallback(async () => {
    setIsLoading(true);

    try {
      await deleteNameServices();
      closeDialog();
    } catch (e) {
      logger.error(e);
      setError(
        t(
          'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.error',
        ),
      );
    } finally {
      setIsLoading(false);
    }
  }, [closeDialog, deleteNameServices, t]);

  return (
    <Dialog open={open} onOpenChange={closeDialog}>
      <Dialog.Header
        title={t(
          'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.title',
        )}
      />
      <Dialog.Content className={styles.content}>
        <Typography variant='paragraph-2' weight='regular' as='p'>
          {t(
            'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.content_1',
          )}
        </Typography>

        <Typography variant='paragraph-2' weight='bold' as='p'>
          {nameServices?.domain}
        </Typography>

        <Typography variant='paragraph-2' weight='regular' as='p'>
          {t(
            'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.content_2',
          )}
        </Typography>

        <Typography variant='paragraph-2' weight='bold' as='p'>
          {t(
            'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.content_3',
          )}
        </Typography>

        {error && (
          <Typography
            variant='paragraph-2'
            weight='regular'
            as='p'
            color='red-1'
          >
            {error}
          </Typography>
        )}

        <div className={styles.footer}>
          <Button
            size='large'
            variant='secondary'
            onClick={closeDialog}
            text={t(
              'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.cancel',
            )}
          />
          <Button
            size='large'
            variant='warning'
            onClick={handleConfirm}
            text={t(
              'v2.page.global_wallets.subdomains.drawer.active_subdomain.remove_dialog.confirm',
            )}
            disabled={isLoading}
          />
        </div>
      </Dialog.Content>
    </Dialog>
  );
};
