import { ProjectSettingsKyc } from '@dynamic-labs/sdk-api';

import { useSaveOneKycField } from '../useSaveOneKycField';
import { useSetKycFieldProp } from '../useSetKycFieldProp';
import { IntegrationCategory } from '../../types';
import { useProvidersContext } from '../../context/ProvidersContext';
import { isSocialKycSetting } from '../../utils/isSocialKycSetting';

type Props = {
  disabled: boolean;
  kycSettings: ProjectSettingsKyc[];
};

export const useDisableSocialKycIfNoSocialProviders = ({
  kycSettings,
  disabled,
}: Props) => {
  const { enabledSocialProviders } = useProvidersContext();

  const socialKycSetting = kycSettings.find((kycSetting) =>
    isSocialKycSetting(kycSetting.name),
  );

  const { mutate: disableProvider } = useSaveOneKycField();
  const { updateActiveEnvKycFieldProp } = useSetKycFieldProp(
    IntegrationCategory.OnboardingAndKYC,
  );

  const disableSocialKycFieldIfNoSocialProviders = () => {
    if (!enabledSocialProviders.length && socialKycSetting?.enabled) {
      disableProvider({
        ...socialKycSetting,
        enabled: false,
      });

      updateActiveEnvKycFieldProp({
        disabled,
        isSelected: false,
        name: 'social',
        type: 'enabled',
      });
    }
  };

  return disableSocialKycFieldIfNoSocialProviders;
};
