import { cloneElement, useRef } from 'react';

import { Content, Root, Portal } from '@radix-ui/react-dropdown-menu';

import { useElementSize } from '@dynamic-labs/northstar';

import { DropdownTrigger } from '../DropdownTrigger';

import styles from './Select.module.css';
import { SelectItem } from './components';
import { SelectProps } from './Select.types';
import { defaultDropdownBuilder, defaultRenderValue } from './utils';

// eslint-disable-next-line @typescript-eslint/comma-dangle
export const Select = <T,>({
  className,
  options,
  value,
  placeholder,
  renderValue = defaultRenderValue,
  onSelect,
  dropdownBuilder = defaultDropdownBuilder,
  dropdownTriggerRootClassName,
  disabled = false,
  error = false,
}: SelectProps<T>) => {
  const targetRef = useRef<HTMLDivElement | null>(null);

  const targetRect = useElementSize(targetRef);
  const selectedOption = options?.find(
    (option) => option.props.value === value,
  );

  const valueElement = selectedOption
    ? renderValue(selectedOption)
    : placeholder;

  const optionsElements = options?.map((option) => {
    const optionValue = option.props.value;

    const isSelected = optionValue === value;

    return cloneElement(option, {
      isSelected,
      key: JSON.stringify(optionValue),
      onSelect: () => {
        if (optionValue === value) {
          onSelect?.(undefined);
        } else {
          onSelect?.(optionValue);
        }
      },
    });
  });

  return (
    <div className={className} ref={targetRef}>
      <Root>
        <DropdownTrigger
          rootClassName={dropdownTriggerRootClassName}
          disabled={disabled}
          error={error}
        >
          {valueElement}
        </DropdownTrigger>
        <Portal>
          <Content
            align='start'
            sideOffset={8}
            className={styles['select-content']}
          >
            {dropdownBuilder({
              children: optionsElements,
              targetWidth: targetRect?.width,
            })}
          </Content>
        </Portal>
      </Root>
    </div>
  );
};

Select.Item = SelectItem;
