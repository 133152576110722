import { t } from 'i18next';

import { Tab, Tabs } from '../../../../components/Tabs';
import { createDateSelectionForPastXDays } from '../../utils';
import { CustomSelectionTab } from '../CustomSelectionTab/CustomSelectionTab';

import { DateSelectionTabsComponent } from './DateSelectionTabs.types';

export const DateSelectionTabs: DateSelectionTabsComponent = ({
  onChange,
  value,
}) => (
  <Tabs>
    <Tab
      onClick={() =>
        onChange(createDateSelectionForPastXDays('30d', { days: 30 }))
      }
      isActive={value.key === '30d'}
    >
      {t<string>('analytics.tabs.30_days')}
    </Tab>

    <Tab
      onClick={() =>
        onChange(createDateSelectionForPastXDays('90d', { days: 90 }))
      }
      isActive={value.key === '90d'}
    >
      {t<string>('analytics.tabs.90_days')}
    </Tab>

    <Tab
      onClick={() =>
        onChange(createDateSelectionForPastXDays('year', { years: 1 }))
      }
      isActive={value.key === 'year'}
    >
      {t<string>('analytics.tabs.year')}
    </Tab>

    <CustomSelectionTab value={value} onChange={onChange} />
  </Tabs>
);
