import { FC, PropsWithChildren } from 'react';

import { DynamicWidget } from '@dynamic-labs/sdk-react-core';

import { DynamicLogoDark } from '../../../icons';
import Background from '../../../icons/background.svg';

import styles from './dynamic-split-layout.module.css';

const DynamicSplitLayout: FC<PropsWithChildren<object>> = ({ children }) => (
  <div className={styles.container}>
    <section className={styles.section__left}>
      <DynamicLogoDark className={styles.logo} />
      <div>{children}</div>
    </section>
    <div
      className={styles.section__right}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className={styles.change__wallet}>
        {/*
          We are using DynamicWiddget so have access to "copy address" and "logout",
          but we should NOT be using multi-wallet here!
        */}
        <DynamicWidget />
      </div>
    </div>
  </div>
);

export default DynamicSplitLayout;
