/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsDesignModal
 */
export interface ProjectSettingsDesignModal {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    border?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    brand?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    primaryColor?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingsDesignModal
     */
    radius?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    theme?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    view?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignModal
     */
    template?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSettingsDesignModal
     */
    displayOrder?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsDesignModal
     */
    emailOnly?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsDesignModal
     */
    showWalletsButton?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsDesignModal
     */
    emailSubmitButtonInsideInput?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsDesignModal
     */
    splitEmailAndSocial?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsDesignModal
     */
    socialAboveEmail?: boolean;
}

export function ProjectSettingsDesignModalFromJSON(json: any): ProjectSettingsDesignModal {
    return ProjectSettingsDesignModalFromJSONTyped(json, false);
}

export function ProjectSettingsDesignModalFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsDesignModal {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'border': !exists(json, 'border') ? undefined : json['border'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'primaryColor': !exists(json, 'primaryColor') ? undefined : json['primaryColor'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
        'theme': !exists(json, 'theme') ? undefined : json['theme'],
        'view': !exists(json, 'view') ? undefined : json['view'],
        'template': !exists(json, 'template') ? undefined : json['template'],
        'displayOrder': !exists(json, 'displayOrder') ? undefined : json['displayOrder'],
        'emailOnly': !exists(json, 'emailOnly') ? undefined : json['emailOnly'],
        'showWalletsButton': !exists(json, 'showWalletsButton') ? undefined : json['showWalletsButton'],
        'emailSubmitButtonInsideInput': !exists(json, 'emailSubmitButtonInsideInput') ? undefined : json['emailSubmitButtonInsideInput'],
        'splitEmailAndSocial': !exists(json, 'splitEmailAndSocial') ? undefined : json['splitEmailAndSocial'],
        'socialAboveEmail': !exists(json, 'socialAboveEmail') ? undefined : json['socialAboveEmail'],
    };
}

export function ProjectSettingsDesignModalToJSON(value?: ProjectSettingsDesignModal | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'border': value.border,
        'brand': value.brand,
        'primaryColor': value.primaryColor,
        'radius': value.radius,
        'theme': value.theme,
        'view': value.view,
        'template': value.template,
        'displayOrder': value.displayOrder,
        'emailOnly': value.emailOnly,
        'showWalletsButton': value.showWalletsButton,
        'emailSubmitButtonInsideInput': value.emailSubmitButtonInsideInput,
        'splitEmailAndSocial': value.splitEmailAndSocial,
        'socialAboveEmail': value.socialAboveEmail,
    };
}

