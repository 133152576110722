import {
  IntegrationSetting,
  ProjectSettingsChains,
  ProjectSettingsKyc,
} from '@dynamic-labs/sdk-api';

import { IntegrationCategory } from '../../types';
import { useSettingsContext } from '../../context/SettingsContext';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';

interface UseSingleSettingStateProps {
  category: IntegrationCategory;
  name: string;
}

type SettingsUnion = Array<ProjectSettingsChains | ProjectSettingsKyc>;

// This hook is used to retrieve and update a single setting in the settings context.
// ie. You can use it to get chain settings or kyc settings.
// It returns the initial setting, the current setting, and a function to update the setting.
export const useSingleSettingState = ({
  category,
  name,
}: UseSingleSettingStateProps) => {
  const { settings, setSettings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const specificSettings = settings[activeEnvironmentType][category];
  const updatedSettings = [...specificSettings];

  const setSingleSetting = (newSetting: IntegrationSetting) => {
    updatedSettings[
      specificSettings.findIndex((setting) => setting.name === newSetting.name)
    ] = newSetting;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        [category]: updatedSettings,
      },
    });
  };

  const initialSettingsArray: SettingsUnion =
    initialSettings[activeEnvironmentType][category];
  const settingsArray: SettingsUnion =
    settings[activeEnvironmentType][category];

  const singleInitialSetting =
    initialSettingsArray.find((setting) => setting.name === name) ?? null;

  const singleSetting =
    settingsArray.find((setting) => setting.name === name) ?? null;

  return { setSingleSetting, singleInitialSetting, singleSetting };
};
