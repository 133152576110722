/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookMessageRedeliveryResponse
 */
export interface WebhookMessageRedeliveryResponse {
    /**
     * 
     * @type {string}
     * @memberof WebhookMessageRedeliveryResponse
     */
    message?: string;
}

export function WebhookMessageRedeliveryResponseFromJSON(json: any): WebhookMessageRedeliveryResponse {
    return WebhookMessageRedeliveryResponseFromJSONTyped(json, false);
}

export function WebhookMessageRedeliveryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookMessageRedeliveryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function WebhookMessageRedeliveryResponseToJSON(value?: WebhookMessageRedeliveryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
    };
}

