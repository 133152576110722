import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import classNames from 'classnames';

import { ClassStyleProps } from '../../utils';

import styles from './Typography.module.scss';
import {
  TypographyColor,
  TypographyTag,
  TypographyVariant,
  TypographyWeight,
} from './Typography.types';

type TypographyType = ClassStyleProps & {
  as?: TypographyTag;
  className?: string;
  color?: TypographyColor;
  dataTestId?: string;
  ddHide?: boolean;
  variant?: TypographyVariant;
  weight?: TypographyWeight;
} & HTMLAttributes<HTMLElement>;

export const Typography: FC<PropsWithChildren<TypographyType>> = ({
  as: Tag = 'p',
  children,
  className = '',
  variant = 'paragraph-0',
  weight = 'regular',
  color = 'black-2',
  dataTestId,
  ddHide,
  style,
}: TypographyType) => (
  <Tag
    className={classNames(
      styles.text,
      styles[variant],
      styles[weight],
      styles[color],
      className,
    )}
    data-testid={dataTestId}
    data-dd-privacy={ddHide ? 'mask' : undefined}
    style={style}
  >
    {children}
  </Tag>
);
