import { useMemo, useState } from 'react';

import {
  DynamicContext,
  getValueByKey,
  MockContextProvider,
  ShadowDOM,
  ThemeData,
  themesData,
  ViewType,
} from '@dynamic-labs/sdk-react-core';

import { useModalDesignSettings } from '../../../../context/SettingsContext/helpers/designHelpers';
import { OptionsSwitcher } from '../../../../components/OptionsSwitcher';
import { viewOptions } from '../optionsData';
import { dynamicContextDefault } from '../../../../utils/constants/defaults';

import { WalletListMock } from './MockedViews/WalletListMock';
import styles from './ViewsPreview.module.css';
import {
  CollectUserDataViewMock,
  PendingSignatureViewMock,
  QrCodeViewMock,
} from './MockedViews';

const viewMap = {
  'collect-user-data': CollectUserDataViewMock,
  'pending-signature': PendingSignatureViewMock,
  'qr-code': QrCodeViewMock,
  'wallet-list': WalletListMock,
};

export const ViewsPreview = () => {
  const [viewType, setViewType] = useState<ViewType>('wallet-list');
  const MockedView = getValueByKey(viewMap, viewType);
  const { settings } = useModalDesignSettings();
  const { theme, border, brand, primaryColor, view, template } = settings || {};
  const customerTheme = {
    ...themesData,
    border,
    brandStyle: brand,
    customColor: primaryColor,
    template,
    theme: getValueByKey(themesData.themes, theme || 'light'),
    view,
  } as ThemeData;

  const newToWeb3WalletChainMap = {
    primary_chain: 'evm',
    wallets: {},
  };

  const values = useMemo(() => dynamicContextDefault, []);

  return (
    <div className={styles.preview}>
      <OptionsSwitcher
        initialActiveKey='wallet-list'
        options={viewOptions}
        onOptionClick={(key) => setViewType(key as ViewType)}
      />

      <div className={styles.views}>
        <ShadowDOM>
          <DynamicContext.Provider value={values}>
            <MockContextProvider
              view={{ type: viewType }}
              customerTheme={customerTheme}
              newToWeb3WalletChainMap={newToWeb3WalletChainMap}
            >
              <MockedView />
            </MockContextProvider>
          </DynamicContext.Provider>
        </ShadowDOM>
      </div>
    </div>
  );
};
