/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PriceData
 */
export interface PriceData {
    /**
     * 
     * @type {number}
     * @memberof PriceData
     */
    nativeTokenUsdPrice?: number;
}

export function PriceDataFromJSON(json: any): PriceData {
    return PriceDataFromJSONTyped(json, false);
}

export function PriceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): PriceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nativeTokenUsdPrice': !exists(json, 'nativeTokenUsdPrice') ? undefined : json['nativeTokenUsdPrice'],
    };
}

export function PriceDataToJSON(value?: PriceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nativeTokenUsdPrice': value.nativeTokenUsdPrice,
    };
}

