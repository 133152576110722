import { ChangeEvent, FC } from 'react';

import { t as staticTMethod } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm, Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { Input, Typography } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';

import styles from './OwnTwilioCredentialsInput.module.scss';

const requiredText = staticTMethod(
  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.required',
);

const schema = yup.object({
  accountSid: yup.string().required(requiredText),
  clientId: yup.string().required(requiredText),
  clientSecret: yup.string().required(requiredText),
  twilioNumber: yup.string().required(requiredText),
});

type CredentialsForm = yup.InferType<typeof schema>;

type FieldTypes = 'clientId' | 'clientSecret' | 'accountSid' | 'twilioNumber';
const FIELDS: FieldTypes[] = [
  'clientId',
  'clientSecret',
  'accountSid',
  'twilioNumber',
];

export const OwnTwilioCredentialsInput: FC = () => {
  const { t } = useTranslation();

  const { getProviderValue, onChangeProvider } = useProvidersContext();

  const methods = useForm<CredentialsForm>({
    defaultValues: {
      accountSid: getProviderValue(ProviderEnum.Sms, 'accountSid'),
      clientId: getProviderValue(ProviderEnum.Sms, 'clientId'),
      clientSecret: getProviderValue(ProviderEnum.Sms, 'clientSecret'),
      twilioNumber: getProviderValue(ProviderEnum.Sms, 'twilioNumber'),
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    register,
    getValues,
    formState: { errors },
    control,
  } = methods;

  const handleOnBlur = () => {
    if (
      errors.clientId ||
      errors.clientSecret ||
      errors.accountSid ||
      errors.twilioNumber
    )
      return;

    onChangeProvider(ProviderEnum.Sms, 'clientId', getValues('clientId'));
    onChangeProvider(
      ProviderEnum.Sms,
      'clientSecret',
      getValues('clientSecret'),
    );
    onChangeProvider(ProviderEnum.Sms, 'accountSid', getValues('accountSid'));
    onChangeProvider(
      ProviderEnum.Sms,
      'twilioNumber',
      getValues('twilioNumber'),
    );
  };

  const handlePhoneNumberInput = (e: ChangeEvent<HTMLInputElement>) => {
    const digitsOnly = e.target.value.replace(/\D/g, '');
    if (digitsOnly.length) {
      return {
        ...e,
        target: {
          ...e.target,
          value: `+${digitsOnly}`,
        },
      };
    }

    return {
      ...e,
      target: {
        ...e.target,
        value: '',
      },
    };
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <FormProvider {...methods}>
      <form onBlur={handleOnBlur} className={styles.form}>
        {FIELDS.map((field) => (
          <div key={field}>
            {field === 'twilioNumber' ? (
              <Controller
                control={control}
                name={field}
                render={({ field: formField }) => (
                  <Input
                    value={formField.value}
                    name={field}
                    onBlur={formField.onBlur}
                    onChange={(e) =>
                      formField.onChange(handlePhoneNumberInput(e))
                    }
                    error={!!errors[field]}
                    label={t(
                      `v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.${field}`,
                    )}
                    maskDatadogValue
                  />
                )}
              />
            ) : (
              <Input
                name={field}
                onBlur={register(field).onBlur}
                onChange={register(field).onChange}
                ref={register(field).ref}
                error={!!errors[field]}
                label={t(
                  `v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.${field}`,
                )}
                maskDatadogValue
              />
            )}
            {errors[field]?.message && (
              <Typography
                variant='paragraph-1'
                color='inherit'
                className={styles.error}
              >
                {errors[field]?.message}
              </Typography>
            )}
          </div>
        ))}
      </form>
    </FormProvider>
  );
};
