import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import Portal from '../../../../components/Portal';
import { useOnrampProvidersContext } from '../../../../context/OnrampProvidersContext';
import { BanxaIcon } from '../../../../../icons';
import { OnrampProviderView } from '../OnrampProviderView';

import styles from './OnrampModal.module.css';

type Props = {
  Icon: React.FunctionComponent;
  content: string;
  setShowModal: (value: boolean) => void;
  title: string;
};

export const OnrampModal = ({ Icon, content, setShowModal, title }: Props) => {
  const { t } = useTranslation();

  const { providers } = useOnrampProvidersContext();

  const onrampProviders = [
    {
      icon: <BanxaIcon />,
      id: ProviderEnum.Banxa,
      label: t('integrations.wallets.onramp.providers.banxa.toggle.label'),
      termsUrl:
        'https://banxa.com/wp-content/uploads/2023/10/Banxa-Customer-Terms-and-Conditions-30-October-2023-BANXA.pdf',
    },
  ];

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <EnvironmentTabsLayout className={styles.layout}>
        <SideModalHeader
          Icon={Icon}
          title={title}
          content={content}
          showClose
          onClose={() => setShowModal(false)}
        />

        <div className={styles.container}>
          {onrampProviders.map((provider) => {
            const savedProvider = providers.find(
              (p) => p.provider === provider.id,
            );

            return (
              <OnrampProviderView
                key={provider.id}
                id={provider.id}
                label={provider.label}
                icon={provider.icon}
                termsUrl={provider.termsUrl}
                provider={savedProvider}
              />
            );
          })}
        </div>
      </EnvironmentTabsLayout>
    </Portal>
  );
};
