import React, {
  FocusEventHandler,
  HtmlHTMLAttributes,
  useEffect,
  useState,
} from 'react';

import styles from './ColorInput.module.css';

type Props = Omit<HtmlHTMLAttributes<HTMLInputElement>, 'defaultValue'> & {
  defaultValue?: string;
};

export const ColorInput = ({ defaultValue = '', onBlur, className }: Props) => {
  const [value, setValue] = useState(defaultValue);
  const [color, setColor] = useState(defaultValue);

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (e) => {
    const newColor = e.target.value || defaultValue;
    setValue(newColor);
    setColor(newColor);
    onBlur?.(e);
  };

  useEffect(() => {
    setColor(defaultValue);
    setValue(defaultValue);
  }, [defaultValue]);

  return (
    <div className={`${styles.container} ${className}`}>
      <input
        type='text'
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={handleOnBlur}
        className={styles.input}
      />
      <div
        data-testid='color-preview'
        style={{
          backgroundColor: color,
          borderColor: color,
        }}
        className={styles['color-preview']}
      />
    </div>
  );
};
