import { FC, PropsWithChildren } from 'react';

import classNames from 'classnames';

import styles from './SelectDropdown.module.css';

type SelectDropdownProps = {
  className?: string;
  width?: number;
};

export const SelectDropdown: FC<PropsWithChildren<SelectDropdownProps>> = ({
  children,
  className,
  width,
}) => (
  <div
    style={{ width }}
    className={classNames(className, styles.selectDropdown)}
  >
    {children}
  </div>
);
