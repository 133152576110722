import { FC, PropsWithChildren, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import {
  XIcon,
  Icon,
  IconButton,
  TextButton,
  Typography,
  Button,
  CloseContext,
} from '@dynamic-labs/northstar';

import Portal from '../../../app/components/Portal';

import styles from './SideDrawer.module.scss';

type SideDrawerProps = {
  Icon?: ReactElement;
  className?: string;
  description?: string;
  docs?: {
    text: string;
    url: string;
  };
  handleClose: VoidFunction;
  isOpen: boolean;
  title: string;
};

/** Composite component type */
type SideDrawerComponent = FC<PropsWithChildren<SideDrawerProps>> & {
  Footer: FC<SideDrawerFooterProps>;
};

/** A modal drawer component that slides open from the side */
const SideDrawer: SideDrawerComponent = ({
  isOpen,
  handleClose,
  title,
  description,
  docs,
  Icon: icon,
  children,
  className,
}) => (
  <AnimatePresence>
    {isOpen && (
      <Portal
        handleClose={handleClose}
        className={classNames(styles.portal, className)}
      >
        <CloseContext.Provider value={handleClose}>
          <div className={styles.wrapper}>
            {/**
            this is being done to solve a very specific problem:
            the X icon appears briefly while the changelog iframe is loading, but when the
            iframe loads, it covers the X icon since the iframe is made to take up the full side drawer
           */}
            {title && (
              <IconButton
                icon={<XIcon />}
                onClick={handleClose}
                size={24}
                className={styles['close-icon']}
              />
            )}

            {icon && <Icon icon={icon} size={40} className={styles.icon} />}

            <header className={styles.header}>
              <Typography variant='title'>{title}</Typography>
              {description && (
                <Typography
                  variant='paragraph-3'
                  weight='regular'
                  color='gray-1'
                >
                  {description}
                </Typography>
              )}
              {docs && (
                <TextButton
                  as='a'
                  href={docs.url}
                  text={docs.text}
                  color='primary-1'
                />
              )}
            </header>

            {children}
          </div>
        </CloseContext.Provider>
      </Portal>
    )}
  </AnimatePresence>
);

/** Props for the Footer sub-component */
type SideDrawerFooterProps = {
  /** Text for the cancel button */
  cancelText?: string;

  /** Whether the confirm button is disabled */
  confirmDisabled?: boolean;

  /** Text for the confirm button */
  confirmText?: string;

  /** Callback on cancel */
  onCancel?: VoidFunction;

  /** Callback on confirm */
  onConfirm?: VoidFunction;
};

/** A sticky footer for the side drawer */
const Footer: FC<SideDrawerFooterProps> = ({
  confirmDisabled,
  confirmText,
  onConfirm,
  cancelText,
  onCancel,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer} data-testid='side-drawer-footer'>
      <Button
        variant='secondary'
        size='medium'
        text={cancelText ?? t('common.drawer.cancel')}
        onClick={onCancel}
      />
      <Button
        variant='primary'
        size='medium'
        text={confirmText ?? t('common.drawer.confirm')}
        onClick={onConfirm}
        disabled={confirmDisabled}
      />
    </div>
  );
};

SideDrawer.Footer = Footer;

export { SideDrawer };
