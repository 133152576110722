import {
  CSSProperties,
  MutableRefObject,
  PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { createPortal } from 'react-dom';

import { AnchorOrigin, Position, TransformOrigin } from '../Popper.types';
import { PopperContent } from '../PopperContent';
import { usePopper } from '../PopperContext';
import { getChildPosition } from '../utils';

export type PopperProps = {
  anchorOrigin?: AnchorOrigin;
  anchorRef: MutableRefObject<HTMLElement | null>;
  className?: string;
  style?: CSSProperties;
  transformOrigin?: TransformOrigin;
};

// eslint-disable-next-line multiline-comment-style
/** Allows to render content outside of the current DOM parent and specify a reference position
 * To allow for opening and closing of menus, see MenuList and Dropdown components
 */
export const Popper = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PopperProps>
>(
  (
    {
      anchorRef,
      anchorOrigin = 'top-left',
      transformOrigin = 'top-left',
      children,
      style,
      className,
    },
    ref,
  ) => {
    const { containerRef } = usePopper();

    const [anchorPosition, setAnchorPosition] = useState<
      Position | undefined
    >();

    // Calculates anchor position based on anchorOrigin's relative position to popper's container
    // and sets up scroll listener to close popper
    useEffect(() => {
      const container = containerRef.current;
      const anchor = anchorRef.current;

      if (!container || !anchor) return;

      setAnchorPosition(getChildPosition(container, anchor));
    }, [anchorRef, containerRef, setAnchorPosition]);

    /** Position of the anchor's vertex that was selected by anchorOrigin  */
    const anchorOriginPosition: Position | undefined = useMemo(() => {
      const anchorRect = anchorRef.current?.getBoundingClientRect();

      if (!anchorPosition || !anchorRect) return;

      switch (anchorOrigin) {
        case 'top-center':
          return {
            ...anchorPosition,
            left: anchorPosition.left + anchorRect.width / 2,
          };
        case 'top-left':
          return anchorPosition;
        case 'top-right':
          return {
            ...anchorPosition,
            left: anchorPosition.left + anchorRect.width,
          };
        case 'bottom-center':
          return {
            ...anchorPosition,
            left: anchorPosition.left + anchorRect.width / 2,
            top: anchorPosition.top + anchorRect.height,
          };
        case 'bottom-left':
          return {
            ...anchorPosition,
            top: anchorPosition.top + anchorRect.height,
          };
        case 'bottom-right':
          return {
            ...anchorPosition,
            left: anchorPosition.left + anchorRect.width,
            top: anchorPosition.top + anchorRect.height,
          };
        default:
          return undefined;
      }
    }, [anchorPosition, anchorRef, anchorOrigin]);

    if (!anchorOriginPosition || containerRef.current === null) return null;

    return createPortal(
      <PopperContent
        ref={ref}
        transformOrigin={transformOrigin}
        style={{ ...anchorOriginPosition, ...style }}
        className={className}
      >
        {children}
      </PopperContent>,
      containerRef.current,
    );
  },
);

Popper.displayName = 'Popper';
