import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Button, PlusIcon } from '@dynamic-labs/northstar';
import { CustomFieldType, CustomFieldRequest } from '@dynamic-labs/sdk-api';
import { useCustomFields } from '@dynamic-labs/redcoast-query';

import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { useModalHashLocation } from '../../../../../../../app/routes/Configurations/utils';

import styles from './CreateNewFields.module.scss';
import { NewFieldSideDrawer } from './NewFieldSideDrawer';

const defaultField: CustomFieldRequest = {
  enabled: true,
  fieldType: CustomFieldType.Text,
  name: '',
  position: 0,
  required: false,
};

export const CreateNewFields: FC = () => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: 'new-custom-fields',
  });
  const { activeEnvironment } = useEnvironmentsContext();
  const envId = activeEnvironment?.id || '';
  const { addCustomField } = useCustomFields({ environmentId: envId });

  const [newField, setNewField] = useState<CustomFieldRequest>({
    ...defaultField,
  });

  const handleSaveField = async () => {
    await addCustomField(newField as CustomFieldRequest);
    setNewField(defaultField);
    handleShowModal(false);
  };

  const handleChangeField = (updatedField: CustomFieldRequest) => {
    setNewField(updatedField);
  };

  const handleOpenModal = () => {
    setNewField({ ...defaultField });
    handleShowModal(true);
  };

  return (
    <>
      <Button
        dataTestId='create_new_field_buttons'
        text={t('v2.page.log_in_methods.kyc.custom_fields.button')}
        onClick={handleOpenModal}
        size='medium'
        variant='secondary'
        icon={<PlusIcon />}
      />
      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal}
        title={t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.title')}
        className={styles.drawer}
      >
        {showModal && (
          <NewFieldSideDrawer
            field={newField}
            disabled={false}
            onClose={() => handleShowModal(false)}
            onSave={handleSaveField}
            onChange={handleChangeField}
            onDelete={() => {}}
          />
        )}
      </SideDrawer>
    </>
  );
};
