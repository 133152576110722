export const toPercent = (total: number, value: number): string => {
  const percentage = (value / total) * 100;

  if (percentage >= 99 && percentage < 100) {
    return '99%+';
  }
  if (percentage < 1 && percentage > 0) {
    return '<1%';
  }
  return `${Math.round(percentage)}%`;
};
