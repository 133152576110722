/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SocialSignInProviderEnum,
    SocialSignInProviderEnumFromJSON,
    SocialSignInProviderEnumFromJSONTyped,
    SocialSignInProviderEnumToJSON,
} from './SocialSignInProviderEnum';

/**
 * 
 * @export
 * @interface SocialSignInProvider
 */
export interface SocialSignInProvider {
    /**
     * 
     * @type {SocialSignInProviderEnum}
     * @memberof SocialSignInProvider
     */
    provider: SocialSignInProviderEnum;
    /**
     * 
     * @type {boolean}
     * @memberof SocialSignInProvider
     */
    enabled?: boolean;
}

export function SocialSignInProviderFromJSON(json: any): SocialSignInProvider {
    return SocialSignInProviderFromJSONTyped(json, false);
}

export function SocialSignInProviderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SocialSignInProvider {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': SocialSignInProviderEnumFromJSON(json['provider']),
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
    };
}

export function SocialSignInProviderToJSON(value?: SocialSignInProvider | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': SocialSignInProviderEnumToJSON(value.provider),
        'enabled': value.enabled,
    };
}

