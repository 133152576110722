import { useCallback, useEffect, useState } from 'react';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { Typography } from '../../../../components/Typography';
import { RpcTestResult } from '../../../../types';

import styles from './RpcInputForm.module.css';

interface RpcInputProps {
  chainName: string;
  defaultRpcUrl?: string;
  disabled: boolean;
  label: string;
  onChange: (e: React.FocusEvent<HTMLInputElement>) => void;
  requireCustomRpcUrl?: boolean;
  testResult?: RpcTestResult;
  testUrl: (url: string) => void;
  value: string;
}

const RpcInputForm = ({
  value,
  chainName,
  label,
  disabled,
  testResult,
  testUrl,
  onChange,
  defaultRpcUrl,
  requireCustomRpcUrl,
}: RpcInputProps) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
      onChange(e);
    },
    [onChange],
  );

  return (
    <div className='container pt-2'>
      <div className='flex'>
        <Input
          key={`rpcInput-${chainName}`}
          name={`rpcUrl-${chainName}`}
          id={`rpcUrl-${chainName}`}
          label={label || 'Please enter your RPC url'}
          value={inputValue}
          onChange={handleInputChange}
        />
        <Button
          full={false}
          disabled={disabled || inputValue === ''}
          type='submit'
          className='ml-2 mb-2'
          variant='secondary'
          onClick={() => testUrl(inputValue)}
        >
          Test URL
        </Button>
      </div>

      {requireCustomRpcUrl ? (
        <Typography
          variant='paragraph-1'
          className='bg-yellow-4 p-2 rounded-lg text-yellow-1'
        >
          A custom RPC URL is required for this chain
          {defaultRpcUrl ? `, e.g. ${defaultRpcUrl}` : ''}
        </Typography>
      ) : (
        // hide copy when there is no default rpc url defined
        defaultRpcUrl && (
          <Typography
            variant='paragraph-1'
            color='gray-1'
            className={styles.rpc__container}
          >
            If left empty, the default public RPC URL shown above will be used.
          </Typography>
        )
      )}

      <Typography
        variant='paragraph-1'
        className={
          testResult?.success
            ? `${styles['rpc__test--success']}`
            : `${styles['rpc__test--failed']}`
        }
      >
        {testResult?.message}
      </Typography>
    </div>
  );
};

export default RpcInputForm;
