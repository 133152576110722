/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum TimeUnitEnum {
    Days = 'days',
    Hours = 'hours',
    Minutes = 'minutes'
}

export function TimeUnitEnumFromJSON(json: any): TimeUnitEnum {
    return TimeUnitEnumFromJSONTyped(json, false);
}

export function TimeUnitEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimeUnitEnum {
    return json as TimeUnitEnum;
}

export function TimeUnitEnumToJSON(value?: TimeUnitEnum | null): any {
    return value as any;
}

