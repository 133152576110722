import { FC, PropsWithChildren } from 'react';

import { Navigate } from 'react-router-dom';

import { EnvironmentEnum, RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardRole } from '../../hooks/useDashboardRole';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';

type ProtectedRouteProps = {
  environment?: EnvironmentEnum;
  role: RoleEnum;
};

/** Route to home if checks fail */
const checkFailed = <Navigate to='/' />;

export const ProtectedRoute: FC<PropsWithChildren<ProtectedRouteProps>> = ({
  environment,
  role,
  children,
}) => {
  const { checkForAccess } = useDashboardRole();
  const { activeEnvironmentType } = useEnvironmentsContext();

  // Check role permissions
  if (!checkForAccess(role)) {
    return checkFailed;
  }

  // Check if the route is only for certain environments
  if (environment !== undefined && environment !== activeEnvironmentType) {
    return checkFailed;
  }

  return children;
};
