/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateTokenResponse,
    CreateTokenResponseFromJSON,
    CreateTokenResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MethodNotAllowed,
    MethodNotAllowedFromJSON,
    MethodNotAllowedToJSON,
    PostTokenFields,
    PostTokenFieldsFromJSON,
    PostTokenFieldsToJSON,
    TokensResponse,
    TokensResponseFromJSON,
    TokensResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteTokenByIdRequest {
    tokenId: string;
}

export interface GetTokensRequest {
    environmentId: string;
}

export interface PostTokenRequest {
    environmentId: string;
    postTokenFields: PostTokenFields;
}

/**
 * 
 */
export class TokensApi extends runtime.BaseAPI {

    /**
     * Delete a token by token id
     */
    async deleteTokenByIdRaw(requestParameters: DeleteTokenByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.tokenId === null || requestParameters.tokenId === undefined) {
            throw new runtime.RequiredError('tokenId','Required parameter requestParameters.tokenId was null or undefined when calling deleteTokenById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tokens/{tokenId}`.replace(`{${"tokenId"}}`, encodeURIComponent(String(requestParameters.tokenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a token by token id
     */
    async deleteTokenById(requestParameters: DeleteTokenByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTokenByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get all the tokens for a project environment (does not include the raw token)
     */
    async getTokensRaw(requestParameters: GetTokensRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TokensResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getTokens.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/tokens`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokensResponseFromJSON(jsonValue));
    }

    /**
     * Get all the tokens for a project environment (does not include the raw token)
     */
    async getTokens(requestParameters: GetTokensRequest, initOverrides?: RequestInit): Promise<TokensResponse> {
        const response = await this.getTokensRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new API Token
     */
    async postTokenRaw(requestParameters: PostTokenRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CreateTokenResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling postToken.');
        }

        if (requestParameters.postTokenFields === null || requestParameters.postTokenFields === undefined) {
            throw new runtime.RequiredError('postTokenFields','Required parameter requestParameters.postTokenFields was null or undefined when calling postToken.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/tokens`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostTokenFieldsToJSON(requestParameters.postTokenFields),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTokenResponseFromJSON(jsonValue));
    }

    /**
     * Create a new API Token
     */
    async postToken(requestParameters: PostTokenRequest, initOverrides?: RequestInit): Promise<CreateTokenResponse> {
        const response = await this.postTokenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
