import { useTranslation } from 'react-i18next';

import { Typography } from '../../../components/Typography';

export const TermsAndConditions = () => {
  const { t } = useTranslation();
  return (
    <Typography
      variant='paragraph-1'
      className='text-gray-1 ml-1'
      dataTestId='terms-and-conditions'
    >
      {t('create_org.tos.info', {
        button_name: t('create_org.continue'),
      })}
      <a
        href='https://www.dynamic.xyz/terms-conditions'
        className='text-primary-1 hover:underline'
        target='_blank'
        rel='noreferrer'
      >
        {t('create_org.tos.tos')}
      </a>
      {t('create_org.tos.and')}
      <a
        href='https://www.dynamic.xyz/privacy-policy'
        className='text-primary-1 hover:underline'
        target='_blank'
        rel='noreferrer'
      >
        {t('create_org.tos.privacy_policy')}
      </a>
      .
    </Typography>
  );
};
