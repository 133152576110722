/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OrganizationMember,
    OrganizationMemberFromJSON,
    OrganizationMemberFromJSONTyped,
    OrganizationMemberToJSON,
} from './OrganizationMember';

/**
 * 
 * @export
 * @interface OrganizationMembersResponse
 */
export interface OrganizationMembersResponse {
    /**
     * 
     * @type {number}
     * @memberof OrganizationMembersResponse
     */
    count: number;
    /**
     * 
     * @type {Array<OrganizationMember>}
     * @memberof OrganizationMembersResponse
     */
    members: Array<OrganizationMember>;
}

export function OrganizationMembersResponseFromJSON(json: any): OrganizationMembersResponse {
    return OrganizationMembersResponseFromJSONTyped(json, false);
}

export function OrganizationMembersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationMembersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'members': ((json['members'] as Array<any>).map(OrganizationMemberFromJSON)),
    };
}

export function OrganizationMembersResponseToJSON(value?: OrganizationMembersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'members': ((value.members as Array<any>).map(OrganizationMemberToJSON)),
    };
}

