import { useCallback, useState } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { GlobalWalletsFieldValues } from '../util';
import { AdminIcon } from '../../../../icons';
import { SideDrawer } from '../../../components/SideDrawer';
import { SubdomainsDrawer } from '../SubdomainsDrawer';

import styles from './SubdomainsToggle.module.scss';

/** Toggle component for enabling personalized subdomains */
export const SubdomainsToggle = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, initialValues } =
    useFormikContext<GlobalWalletsFieldValues>();

  const [showDrawer, setShowDrawer] = useState(false);

  const handleToggleChange = useCallback(() => {
    const newToggleValue = !values.subdomainsEnabled;
    setFieldValue('subdomainsEnabled', newToggleValue);
  }, [setFieldValue, values]);

  const onSettingsClick = useCallback(() => {
    setShowDrawer(true);
  }, []);

  /** Closing the drawer without setting ENS domain should reset toggle */
  const onDrawerClose = useCallback(() => {
    setShowDrawer(false);
  }, []);

  return (
    <>
      <SingleToggleCard
        accordionKey='subdomains'
        title={t('v2.page.global_wallets.subdomains.toggle.title')}
        allowExpand={false}
        onCardClick={values.subdomainsEnabled ? onSettingsClick : undefined}
        inputProps={{
          checked: values.subdomainsEnabled,
          id: 'subdomainsToggle',
          isSaved: values.subdomainsEnabled === initialValues.subdomainsEnabled,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />
      <SideDrawer
        handleClose={onDrawerClose}
        isOpen={showDrawer}
        title={t('v2.page.global_wallets.subdomains.title')}
        description={t('v2.page.global_wallets.subdomains.drawer.description')}
      >
        <SubdomainsDrawer />
      </SideDrawer>
    </>
  );
};
