import React from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { ChainalysisCopyText } from '../../../../data/integrations';
import { Typography } from '../../../../components/Typography';
import { useChainalysisContext } from '../../../../context/ChainalysisContext';
import { chainalysisApi } from '../../../../services/api';
import { Toggle } from '../../../../components/Toggle';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import Portal from '../../../../components/Portal';
import { ConfirmToast } from '../../../../components/ConfirmToast';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { UpsellBanner } from '../../../../components/UpsellBanner';
import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { SideModalHeader } from '../../../../components/SideModalHeader';

import styles from './ChainalysisModal.module.css';

type Props = {
  Icon: React.FunctionComponent;
  content: string;
  scope?: string;
  setShowModal: (value: boolean) => void;
  title: string;
};

export const ChainalysisModal = ({
  Icon,
  content,
  setShowModal,
  title,
  scope,
}: Props) => {
  const { t } = useTranslation();
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const {
    configuration,
    setConfiguration,
    newConfiguration,
    setNewConfiguration,
  } = useChainalysisContext();
  const { subscription } = useSubscriptionContext();
  const { shouldLockOnLive, shouldShowTrialBanner } =
    useSubscriptionLock(scope);

  const { trialDaysLeft } = subscription || {};

  const currentConfig = configuration[activeEnvironmentType];

  const changedCurrentConfig = newConfiguration[activeEnvironmentType];

  const handleConfirmClick = async () => {
    if (!activeEnvironment?.id || shouldLockOnLive) {
      return;
    }

    const newConfig = changedCurrentConfig.sanctionsApiEnabled
      ? await chainalysisApi.enableSanctionsApiByEnvironmentId({
          environmentId: activeEnvironment.id,
        })
      : await chainalysisApi.disableSanctionsApiByEnvironmentId({
          environmentId: activeEnvironment.id,
        });

    if (activeEnvironmentType === 'live') {
      setConfiguration({ ...configuration, live: newConfig });
    } else {
      setConfiguration({ ...configuration, sandbox: newConfig });
    }
  };

  const handleToggle = () => {
    if (shouldLockOnLive) return;
    if (activeEnvironmentType === 'live') {
      setNewConfiguration({
        ...newConfiguration,
        live: {
          ...newConfiguration.live,
          sanctionsApiEnabled: !newConfiguration.live.sanctionsApiEnabled,
        },
      });
    } else {
      setNewConfiguration({
        ...newConfiguration,
        sandbox: {
          ...newConfiguration.sandbox,
          sanctionsApiEnabled: !newConfiguration.sandbox.sanctionsApiEnabled,
        },
      });
    }
  };

  const handleCancelClick = () => {
    if (activeEnvironmentType === 'live') {
      setNewConfiguration({
        ...newConfiguration,
        live: configuration.live,
      });
    } else {
      setNewConfiguration({
        ...newConfiguration,
        sandbox: configuration.sandbox,
      });
    }
  };

  const toggleTextClassName = shouldLockOnLive
    ? styles['heading--disabled']
    : '';

  const settingsHasChanged =
    currentConfig.sanctionsApiEnabled !==
    changedCurrentConfig.sanctionsApiEnabled;

  return (
    <Portal
      handleClose={() => !settingsHasChanged && setShowModal(false)}
      className={styles.portal}
    >
      <AnimatePresence>
        {settingsHasChanged && (
          <ConfirmToast
            confirmationText={t('settings_page.confirmButton')}
            cancelText={t('settings_page.cancelButton')}
            className={styles['confirmation-toast']}
            message={t('settings_page.confirmMessage')}
            onConfirm={handleConfirmClick}
            onCancel={handleCancelClick}
          />
        )}
      </AnimatePresence>

      <EnvironmentTabsLayout className='!left-0 !w-full'>
        <SideModalHeader
          Icon={Icon}
          title={title}
          content={content}
          showClose
          onClose={() => setShowModal(false)}
        />

        {shouldShowTrialBanner && (
          <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
        )}

        <div className={styles.header__outer}>
          <div className={styles.header__container}>
            <Toggle
              withIcon
              variant={
                newConfiguration[activeEnvironmentType].sanctionsApiEnabled ===
                configuration[activeEnvironmentType].sanctionsApiEnabled
                  ? 'success'
                  : 'primary'
              }
              className={styles.header__toggle}
              id='toggle-chainalysis'
              handleChange={handleToggle}
              checked={
                newConfiguration[activeEnvironmentType].sanctionsApiEnabled
              }
              ariaLabel='toggle chainalysis'
              disabled={shouldLockOnLive}
            />

            <Typography
              variant='paragraph-2'
              className={`${styles.heading} ${toggleTextClassName}`}
            >
              {t('integrations.compliance.chainalysis.enable_sanctions')}
            </Typography>
          </div>
        </div>
        <Typography variant='paragraph-1' className={styles.copy}>
          <ChainalysisCopyText urlClassName={styles.copy__url} />
        </Typography>
      </EnvironmentTabsLayout>
    </Portal>
  );
};

export default ChainalysisModal;
