/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SupportedSecurityMethod
 */
export interface SupportedSecurityMethod {
    /**
     * 
     * @type {boolean}
     * @memberof SupportedSecurityMethod
     */
    isDefault: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedSecurityMethod
     */
    isEnabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupportedSecurityMethod
     */
    isPermanentAuthenticator: boolean;
    /**
     * 
     * @type {number}
     * @memberof SupportedSecurityMethod
     */
    listPosition: number;
}

export function SupportedSecurityMethodFromJSON(json: any): SupportedSecurityMethod {
    return SupportedSecurityMethodFromJSONTyped(json, false);
}

export function SupportedSecurityMethodFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedSecurityMethod {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isDefault': json['isDefault'],
        'isEnabled': json['isEnabled'],
        'isPermanentAuthenticator': json['isPermanentAuthenticator'],
        'listPosition': json['listPosition'],
    };
}

export function SupportedSecurityMethodToJSON(value?: SupportedSecurityMethod | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isDefault': value.isDefault,
        'isEnabled': value.isEnabled,
        'isPermanentAuthenticator': value.isPermanentAuthenticator,
        'listPosition': value.listPosition,
    };
}

