/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NonceResponse
 */
export interface NonceResponse {
    /**
     * 
     * @type {string}
     * @memberof NonceResponse
     */
    nonce?: string;
}

export function NonceResponseFromJSON(json: any): NonceResponse {
    return NonceResponseFromJSONTyped(json, false);
}

export function NonceResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): NonceResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'nonce': !exists(json, 'nonce') ? undefined : json['nonce'],
    };
}

export function NonceResponseToJSON(value?: NonceResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'nonce': value.nonce,
    };
}

