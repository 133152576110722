import { useCallback } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Typography } from '../../../components/Typography';
import { Integration } from '../../../types';
import Header from '../SingleChain/Header';
import { useSettingsContext } from '../../../context/SettingsContext';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import { IntegrationModal } from '../components/IntegrationModal';
import { FullScope } from '../../../data/subscription';
import { useSubscriptionLock } from '../../../hooks/useSubscriptionLock';
import { UpsellBanner } from '../../../components/UpsellBanner';
import { useSubscriptionContext } from '../../../context/SubscriptionContext';
import { ModalHashLocations, useModalHashLocation } from '../utils';

import { SetHCaptchaSettings } from './types';
import styles from './styles.module.css';
import { Controls } from './Controls';

type HCaptchaCardProps = Omit<Integration, 'category' | 'url'>;

export const HCaptchaCard = ({
  Icon,
  title,
  content,
  name,
}: HCaptchaCardProps) => {
  const { settings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.HCaptcha,
  });

  const scope = FullScope.Captcha;
  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock(scope);
  const { trialDaysLeft } = subscription || {};

  const hcaptchaSettings = settings[activeEnvironmentType].security.hCaptcha;

  const setHCaptchaSettings: SetHCaptchaSettings = useCallback(
    (newSettings) => {
      setSettings((prevSettings) => {
        const prevHcaptchaSettings =
          prevSettings[activeEnvironmentType].security.hCaptcha;

        // Deep clone the settings object so that we don't overwrite the previous state
        const updatedSettings = JSON.parse(JSON.stringify(prevSettings));

        const newHCaptchaSettings =
          typeof newSettings === 'function'
            ? newSettings(prevHcaptchaSettings)
            : newSettings;

        updatedSettings[activeEnvironmentType].security.hCaptcha =
          newHCaptchaSettings;

        return updatedSettings;
      });
    },
    [activeEnvironmentType, setSettings],
  );

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <IntegrationModal
            category='security'
            Icon={Icon}
            content={content}
            name={name}
            setShowModal={handleShowModal}
            title={title}
          >
            {shouldShowTrialBanner && (
              <UpsellBanner daysLeft={trialDaysLeft} className='mb-3' />
            )}
            <Controls
              settings={hcaptchaSettings}
              setSettings={setHCaptchaSettings}
              scope={scope}
            />
          </IntegrationModal>
        )}
      </AnimatePresence>
      <button
        type='button'
        className='max-w-[350px] p-4 border border-cloud-2 rounded-xl flex flex-col justify-start items-start'
        onClick={() => handleShowModal(!showModal)}
      >
        <Header
          Icon={Icon}
          name={title}
          isLiveActive={settings.live.security.hCaptcha?.enabled ?? false}
          isSandboxActive={settings.sandbox.security.hCaptcha?.enabled ?? false}
          scope={scope}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {content.short}
        </Typography>
      </button>
    </>
  );
};
