/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomHostnameStatusEnum,
    CustomHostnameStatusEnumFromJSON,
    CustomHostnameStatusEnumFromJSONTyped,
    CustomHostnameStatusEnumToJSON,
} from './CustomHostnameStatusEnum';
import {
    CustomHostnameVerificationRecord,
    CustomHostnameVerificationRecordFromJSON,
    CustomHostnameVerificationRecordFromJSONTyped,
    CustomHostnameVerificationRecordToJSON,
} from './CustomHostnameVerificationRecord';

/**
 * 
 * @export
 * @interface CustomHostname
 */
export interface CustomHostname {
    /**
     * 
     * @type {string}
     * @memberof CustomHostname
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CustomHostname
     */
    hostname: string;
    /**
     * 
     * @type {CustomHostnameStatusEnum}
     * @memberof CustomHostname
     */
    status: CustomHostnameStatusEnum;
    /**
     * 
     * @type {Array<CustomHostnameVerificationRecord>}
     * @memberof CustomHostname
     */
    verificationRecords: Array<CustomHostnameVerificationRecord>;
    /**
     * 
     * @type {string}
     * @memberof CustomHostname
     */
    errors?: string;
}

export function CustomHostnameFromJSON(json: any): CustomHostname {
    return CustomHostnameFromJSONTyped(json, false);
}

export function CustomHostnameFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomHostname {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'hostname': json['hostname'],
        'status': CustomHostnameStatusEnumFromJSON(json['status']),
        'verificationRecords': ((json['verificationRecords'] as Array<any>).map(CustomHostnameVerificationRecordFromJSON)),
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function CustomHostnameToJSON(value?: CustomHostname | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'hostname': value.hostname,
        'status': CustomHostnameStatusEnumToJSON(value.status),
        'verificationRecords': ((value.verificationRecords as Array<any>).map(CustomHostnameVerificationRecordToJSON)),
        'errors': value.errors,
    };
}

