import { useCallback, useContext } from 'react';

import classNames from 'classnames';
import {
  Root,
  Portal,
  Overlay,
  Content,
  Title,
  Close,
} from '@radix-ui/react-dialog';

import { CloseContext } from '../../contexts/CloseContext';
import { Typography } from '../Typography';
import { XIcon } from '../../icons';
import { IconButton } from '../IconButton';

import {
  DialogComponent,
  DialogContentComponent,
  DialogHeaderComponent,
} from './Dialog.types';
import styles from './Dialog.module.scss';

/** Base Dialog component built from radix-ui */
const Dialog: DialogComponent = ({
  children,
  className,
  open,
  onOpenChange,
}) => {
  const handleClose = useCallback(() => onOpenChange?.(false), [onOpenChange]);

  return (
    <Root open={open} onOpenChange={onOpenChange} modal>
      <Portal>
        <CloseContext.Provider value={handleClose}>
          <Overlay className={styles.overlay} />
          <Content
            className={classNames(styles.dialog, className)}
            data-testid='dialog-content'
          >
            {children}
          </Content>
        </CloseContext.Provider>
      </Portal>
    </Root>
  );
};

/** Header sub-component for Dialog */
const Header: DialogHeaderComponent = ({
  title,
  Icon,
  showClose = true,
  className,
}) => {
  const handleClose = useContext(CloseContext);

  return (
    <div className={classNames(styles.header, className)}>
      {Icon}

      <Title>
        {typeof title === 'string' ? (
          <Typography variant='title'>{title}</Typography>
        ) : (
          title
        )}
      </Title>

      {showClose && handleClose && (
        <Close asChild>
          <IconButton
            icon={<XIcon />}
            size={24}
            onClick={handleClose}
            testId='dialog-header-close'
          />
        </Close>
      )}
    </div>
  );
};

Dialog.Header = Header;

/** Inner content component for Dialog, used for styling */
const InnerContent: DialogContentComponent = ({ children, className }) => (
  <div className={classNames(styles.content, className)}>{children}</div>
);

Dialog.Content = InnerContent;

export { Dialog };
