import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SingleToggleCard,
  Typography,
  MultiWalletIntegrationIcon,
} from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { AdminIcon } from '../../../../../../../icons';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { ChangedSettingsIndicator } from '../../../../../EmbeddedWalletsPage/Sections/shared';

import styles from './MultiWallet.module.scss';

type SideDrawerToggleProp =
  | 'multiWalletUnlinkDisabled'
  | 'confirmWalletTransfers'
  | 'preventOrphanedAccounts';

type ToggleProp = 'multiWallet' | SideDrawerToggleProp;

const drawerTogglesProps: SideDrawerToggleProp[] = [
  'multiWalletUnlinkDisabled',
  'confirmWalletTransfers',
  'preventOrphanedAccounts',
];

type MultiWalletProps = {
  disabled: boolean;
};

export const MultiWallet: FC<MultiWalletProps> = ({ disabled }) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.LogInMethodsMultiWallet,
  });

  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isSettingEnabled = (key: ToggleProp) =>
    Boolean(settings[activeEnvironmentType].sdk?.[key]) && !disabled;

  const isSettingSaved = (key: ToggleProp) =>
    Boolean(
      initialSettings[activeEnvironmentType].sdk?.[key] ===
        isSettingEnabled(key),
    );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>, prop: ToggleProp) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          [prop]: e.target.checked,
        },
      },
    });
  };

  const getAmountOfEnabledL2Settings = () =>
    drawerTogglesProps.reduce(
      (acc, key) => (isSettingEnabled(key) ? acc + 1 : acc),
      0,
    );

  return (
    <>
      <SingleToggleCard
        accordionKey='multiWallet'
        title={t('v2.page.log_in_methods.branded_wallets.multiWallet.toggle')}
        description={t(
          'v2.page.log_in_methods.branded_wallets.multiWallet.description',
        )}
        allowExpand={false}
        onCardClick={
          isSettingEnabled('multiWallet')
            ? () => handleShowModal(true)
            : undefined
        }
        inputProps={{
          checked: Boolean(isSettingEnabled('multiWallet')),
          disabled,
          id: 'multiWallet',
          isSaved: isSettingSaved('multiWallet'),
          onChange: (e) => handleToggle(e, 'multiWallet'),
          type: 'toggle',
        }}
        Icon={<MultiWalletIntegrationIcon />}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        RightSideTags={
          isSettingEnabled('multiWallet')
            ? [
                {
                  Tag: (
                    <ChangedSettingsIndicator
                      amount={getAmountOfEnabledL2Settings()}
                    />
                  ),
                  id: 'enabled',
                },
              ]
            : undefined
        }
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && isSettingEnabled('multiWallet')}
        title={t(
          'v2.page.log_in_methods.branded_wallets.multiWallet.drawer.title',
        )}
        description={t(
          'v2.page.log_in_methods.branded_wallets.multiWallet.drawer.description',
        )}
        Icon={<MultiWalletIntegrationIcon />}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t(
                'v2.page.log_in_methods.branded_wallets.multiWallet.drawer.additional_settings',
              )}
            </Typography>

            {drawerTogglesProps.map((key) => (
              <SingleToggleCard
                accordionKey={key}
                title={t(
                  `v2.page.log_in_methods.branded_wallets.multiWallet.drawer.${key}.title`,
                )}
                description={t(
                  `v2.page.log_in_methods.branded_wallets.multiWallet.drawer.${key}.description`,
                )}
                allowExpand={false}
                inputProps={{
                  checked: Boolean(isSettingEnabled(key)),
                  disabled,
                  id: key,
                  isSaved: isSettingSaved(key),
                  onChange: (e) => handleToggle(e, key),
                  type: 'toggle',
                }}
                key={key}
              />
            ))}
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
