import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseCheckExternalJwtHook } from './useCheckExternalJwt.types';

export const useCheckExternalJwt: UseCheckExternalJwtHook = (options) => {
  const { externalJwtApi } = useRedcoastQueryContext();

  return useMutation(
    ({ environmentId, externalJwtCheckRequest }) =>
      externalJwtApi.checkExternalJwt({
        environmentId,
        externalJwtCheckRequest,
      }),
    options,
  );
};
