import { FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Button, TextButton, Typography } from '@dynamic-labs/northstar';

import Portal from '../../../app/components/Portal';
import Header from '../../../app/components/Portal/Header';

import styles from './ApiErrorsModal.module.scss';

export type ApiError = {
  action?: VoidFunction;
  i18nKeyActionText?: string;
  i18nKeyText: string;
  provider?: string;
};

type ApiErrorsModalProps = {
  apiErrors: ApiError[];
  handleClose: VoidFunction;
  title: string;
};

export const ApiErrorsModal: FC<ApiErrorsModalProps> = ({
  handleClose,
  apiErrors,
  title,
}) => {
  const { t } = useTranslation();

  const handleActionClick = (action: VoidFunction) => {
    action();
    handleClose();
  };

  const getComponents = (
    i18nKeyActionText: string | undefined,
    action: VoidFunction | undefined,
    provider: string | undefined,
  ) => {
    if (i18nKeyActionText) {
      return {
        action: (
          <TextButton
            variant='link'
            text={t(i18nKeyActionText as any, {
              provider,
            })}
            onClick={() => action && handleActionClick(action)}
            color='primary-1'
          />
        ),
      };
    }

    return undefined;
  };

  return (
    <Portal
      handleClose={handleClose}
      className={styles.portal}
      backdropClassName={styles.backdrop}
    >
      <Header handleClose={handleClose} headingText={title} />

      <div className={styles.content}>
        <div className={styles.actions}>
          {apiErrors.map(
            ({ action, i18nKeyActionText, i18nKeyText, provider }, index) => (
              <div key={`${i18nKeyText}-${i18nKeyActionText}`}>
                <Typography variant='paragraph-2' weight='bold'>
                  {index + 1}.{' '}
                  <Trans
                    i18nKey={i18nKeyText as any}
                    components={getComponents(
                      i18nKeyActionText,
                      action,
                      provider,
                    )}
                    values={{
                      provider,
                    }}
                  />
                </Typography>
              </div>
            ),
          )}
        </div>

        <Button
          text={t(
            'v2.page.embedded_wallets.dynamic_section.missing_settings.close',
          )}
          variant='secondary'
          size='large'
          onClick={handleClose}
        />
      </div>
    </Portal>
  );
};
