import { useCallback } from 'react';

import { SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import {
  EnvironmentSettings,
  useSettingsContext,
} from '../../../../../context/SettingsContext';

export type SignInProvider = SocialSignInProviderEnum;

export type SocialSignInProviderType = {
  enabled?: boolean;
  provider?: SignInProvider;
};

type Props = {
  disabled: boolean;
};

// ## useSignInWithSocialProviders
// Use this hook to get, set, and manipulate the enabled state of sign in providers.
//
// This will affect only providers which enabled state is stored in the sdk settings.
// ie. Magic and Dynamic **SIGN IN (only)** providers.
//
// ### IMPORTANT NOTE!
//
// If you want to manipulate credentials for these providers use `ProvidersContext`.
// Their credentials are stored in the Providers table.
//
// **Be careful when manipulating the enabled state of these providers as it could enable social linking.**
//
export const useSignInWithSocialProviders = ({ disabled }: Props) => {
  const { activeEnvironmentType } = useEnvironmentsContext();

  const { settings, setSettings, initialSettings } = useSettingsContext();

  const listOfProviders: SignInProvider[] = [
    ...Object.values(SocialSignInProviderEnum),
  ];

  const getEnabledProvidersFromSettings = useCallback(
    (settingsToCheck: EnvironmentSettings): SignInProvider[] => {
      /* istanbul ignore next */
      const providers =
        settingsToCheck[activeEnvironmentType]?.sdk.socialSignIn?.providers ||
        [];

      return providers
        .filter((provider) => provider.enabled)
        .map((provider) => provider.provider as SignInProvider);
    },
    [activeEnvironmentType],
  );

  const enabledProviders = getEnabledProvidersFromSettings(settings);

  const initialProviders = getEnabledProvidersFromSettings(initialSettings);

  const toggleSocialSignInProvider = useCallback(
    (provider: SocialSignInProviderEnum, state?: boolean) => {
      if (disabled) {
        return;
      }

      setSettings((prevState) =>
        disableSocialSignInProviderHelper(
          provider,
          prevState,
          activeEnvironmentType,
          state,
        ),
      );
    },
    [activeEnvironmentType, disabled, setSettings],
  );

  // It can be used to disable all social sign-in providers in the project settings
  // ie. When changing from Dynamic to Magic.
  const disableAllSocialSignInProviders = useCallback(() => {
    if (!settings || !setSettings) {
      return;
    }

    // Deep clone the settings object so that we don't overwrite the previous state
    const updatedSettings = JSON.parse(JSON.stringify(settings));

    const prevSocialSignInProviders =
      settings[activeEnvironmentType].sdk.socialSignIn?.providers || [];

    updatedSettings[activeEnvironmentType].sdk.socialSignIn.providers =
      prevSocialSignInProviders.map((p) => ({
        ...p,
        enabled: false,
      }));

    setSettings(updatedSettings);
  }, [activeEnvironmentType, setSettings, settings]);

  return {
    disableAllSocialSignInProviders,
    enabledProviders,
    initialProviders,
    listOfProviders,
    toggleSocialSignInProvider,
  };
};

export const disableSocialSignInProviderHelper = (
  provider: SocialSignInProviderEnum,
  prevState: EnvironmentSettings,
  activeEnvironmentType: 'sandbox' | 'live',
  state?: boolean,
) => {
  const prevSocialSignInSettings =
    prevState[activeEnvironmentType].sdk.socialSignIn?.providers || [];

  const updatedSocialSignInSettings = prevSocialSignInSettings.map((p) => {
    if (p.provider === provider) {
      return {
        ...p,
        enabled: state ?? !p.enabled,
      };
    }
    return p;
  });

  const updatedSettings = JSON.parse(JSON.stringify(prevState));

  updatedSettings[activeEnvironmentType].sdk.socialSignIn.providers =
    updatedSocialSignInSettings;

  return updatedSettings;
};
