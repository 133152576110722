/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum WalletProviderEnum {
    BrowserExtension = 'browserExtension',
    CustodialService = 'custodialService',
    WalletConnect = 'walletConnect',
    QrCode = 'qrCode',
    DeepLink = 'deepLink',
    EmbeddedWallet = 'embeddedWallet',
    SmartContractWallet = 'smartContractWallet'
}

export function WalletProviderEnumFromJSON(json: any): WalletProviderEnum {
    return WalletProviderEnumFromJSONTyped(json, false);
}

export function WalletProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletProviderEnum {
    return json as WalletProviderEnum;
}

export function WalletProviderEnumToJSON(value?: WalletProviderEnum | null): any {
    return value as any;
}

