/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NameServiceSdkSettingsEvm,
    NameServiceSdkSettingsEvmFromJSON,
    NameServiceSdkSettingsEvmFromJSONTyped,
    NameServiceSdkSettingsEvmToJSON,
} from './NameServiceSdkSettingsEvm';

/**
 * 
 * @export
 * @interface NameServiceSdkSettings
 */
export interface NameServiceSdkSettings {
    /**
     * 
     * @type {NameServiceSdkSettingsEvm}
     * @memberof NameServiceSdkSettings
     */
    evm: NameServiceSdkSettingsEvm;
}

export function NameServiceSdkSettingsFromJSON(json: any): NameServiceSdkSettings {
    return NameServiceSdkSettingsFromJSONTyped(json, false);
}

export function NameServiceSdkSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameServiceSdkSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'evm': NameServiceSdkSettingsEvmFromJSON(json['evm']),
    };
}

export function NameServiceSdkSettingsToJSON(value?: NameServiceSdkSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'evm': NameServiceSdkSettingsEvmToJSON(value.evm),
    };
}

