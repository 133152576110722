/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateEmbeddedWalletParams,
    CreateEmbeddedWalletParamsFromJSON,
    CreateEmbeddedWalletParamsFromJSONTyped,
    CreateEmbeddedWalletParamsToJSON,
} from './CreateEmbeddedWalletParams';

/**
 * 
 * @export
 * @interface CreateEmbeddedWalletsRequest
 */
export interface CreateEmbeddedWalletsRequest {
    /**
     * The parameters which will be used to create embedded wallets for the user.
     * @type {Array<CreateEmbeddedWalletParams>}
     * @memberof CreateEmbeddedWalletsRequest
     */
    embeddedWallets: Array<CreateEmbeddedWalletParams>;
}

export function CreateEmbeddedWalletsRequestFromJSON(json: any): CreateEmbeddedWalletsRequest {
    return CreateEmbeddedWalletsRequestFromJSONTyped(json, false);
}

export function CreateEmbeddedWalletsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmbeddedWalletsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'embeddedWallets': ((json['embeddedWallets'] as Array<any>).map(CreateEmbeddedWalletParamsFromJSON)),
    };
}

export function CreateEmbeddedWalletsRequestToJSON(value?: CreateEmbeddedWalletsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'embeddedWallets': ((value.embeddedWallets as Array<any>).map(CreateEmbeddedWalletParamsToJSON)),
    };
}

