/* eslint-disable react/jsx-props-no-spreading */
import { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Column,
  PluginHook,
  useSortBy,
  useTable,
  Row,
  RowPropGetter,
} from 'react-table';

import { SortColumnIcon } from '../../../icons';
import { SortBy } from '../../types';
import { Typography } from '../Typography';

import styles from './table.module.css';

type InfoBannerMessage = {
  content: string;
  link?: string;
  linkText?: string;
};

interface Props<T extends object> {
  columns: Array<Column<T>>;
  data?: Array<T>;
  infoBanner?: InfoBannerMessage;
  loading?: boolean;
  noDataMessage?: string;
  plugins?: Array<PluginHook<T>>;
  rowProps?: (row: Row<T>) => {
    onClick?: (e: React.ChangeEvent<HTMLButtonElement>) => void;
  };
  withHiddenColumns?: string[];
  withSortParams?: {
    onSort: (sortBy: SortBy) => void;
  };
}

const ReactTable = <T extends object>({
  columns,
  data,
  loading,
  infoBanner,
  withSortParams,
  withHiddenColumns,
  plugins = [],
  rowProps = () => ({}),
  noDataMessage,
}: Props<T>) => {
  const { t } = useTranslation();
  const { onSort } = withSortParams || {};
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
    setHiddenColumns,
  } = useTable<T>(
    {
      columns,
      data: (loading ? columns : data ?? []) as Readonly<T[]>,
      manualSortBy: true,
      ...(withHiddenColumns && {
        initialState: { hiddenColumns: withHiddenColumns },
      }),
    },
    useSortBy,
    ...plugins,
  );

  useEffect(() => {
    if (!onSort || !sortBy) return;
    const [sortParams] = sortBy;
    onSort(sortParams);
  }, [onSort, sortBy]);

  useEffect(() => {
    if (withHiddenColumns) setHiddenColumns(withHiddenColumns);
  }, [withHiddenColumns, setHiddenColumns]);

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead className={styles.table_head}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                scope='col'
                className={styles.cell_header}
                {...column.getHeaderProps(
                  column.sortable !== false
                    ? withSortParams && column.getSortByToggleProps()
                    : undefined,
                )}
              >
                <div className='flex items-center'>
                  {column.render('Header')}
                  {column.sortable !== false && withSortParams && (
                    <div
                      className={`${styles.sort} ${
                        column.isSorted ? styles['sort--active'] : ''
                      } ${column.isSortedDesc ? styles['sort--desc'] : ''} `}
                    >
                      <SortColumnIcon />
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.table_body} {...getTableBodyProps()}>
        {infoBanner && (
          <td
            data-testid='top-table-banner-cell'
            className={styles['table__info-banner--top']}
            colSpan={headerGroups[0].headers.length}
          >
            <div className={styles['table__info-banner--top__wrapper']}>
              <Typography variant='paragraph-1'>
                {infoBanner.content}
              </Typography>
              <a href={infoBanner.link} target='_blank' rel='noreferrer'>
                {infoBanner.linkText}
              </a>
            </div>
          </td>
        )}
        {rows.length > 0 || loading ? (
          rows.map((row) => {
            prepareRow(row);

            return (
              <tr {...row.getRowProps(rowProps(row) as RowPropGetter<T>)}>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className={` ${styles.cell_data}`}
                  >
                    <div
                      className={`flex items-center px-4 ${
                        cell.column.className ?? ''
                      }`}
                    >
                      {loading && cell.column.skeleton
                        ? cell.column.skeleton
                        : cell.render('Cell')}
                    </div>
                  </td>
                ))}
              </tr>
            );
          })
        ) : (
          <tr>
            <td colSpan={100}>
              <div className={styles.no_data}>
                {noDataMessage || t('table.noData')}
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default ReactTable;
