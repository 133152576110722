import { Dispatch, SetStateAction } from 'react';

import { Allowlist } from '@dynamic-labs/sdk-api';

import { allowListsApi } from '../../services/api';
import { logger } from '../../services/logger';

export const fetchAllowLists = async (
  environmentId: string | undefined,
  setAllowLists: Dispatch<SetStateAction<Allowlist[]>>,
) => {
  try {
    if (environmentId) {
      const response = await allowListsApi.getAllowlistsByEnvironmentId({
        environmentId,
      });

      setAllowLists(response);
    }
  } catch (e) {
    logger.error(e);
  }
};
