/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticatorAssertionResponse
 */
export interface AuthenticatorAssertionResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponse
     */
    clientDataJSON: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponse
     */
    authenticatorData: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponse
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAssertionResponse
     */
    userHandle?: string;
}

export function AuthenticatorAssertionResponseFromJSON(json: any): AuthenticatorAssertionResponse {
    return AuthenticatorAssertionResponseFromJSONTyped(json, false);
}

export function AuthenticatorAssertionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatorAssertionResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientDataJSON': json['clientDataJSON'],
        'authenticatorData': json['authenticatorData'],
        'signature': json['signature'],
        'userHandle': !exists(json, 'userHandle') ? undefined : json['userHandle'],
    };
}

export function AuthenticatorAssertionResponseToJSON(value?: AuthenticatorAssertionResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientDataJSON': value.clientDataJSON,
        'authenticatorData': value.authenticatorData,
        'signature': value.signature,
        'userHandle': value.userHandle,
    };
}

