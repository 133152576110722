import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import {
  Invite,
  InviteStatusEnum,
  Organization,
  Project,
} from '@dynamic-labs/sdk-api';
import { isMobile } from '@dynamic-labs/utils';
import {
  useGetInvites,
  useGetOrganizationsForMember,
  useGetProjects,
} from '@dynamic-labs/redcoast-query';
import { useIsLoggedIn } from '@dynamic-labs/sdk-react-core';

import { MobileComingSoon } from '../../routes/Dashboard/MobileComingSoon/MobileComingSoon';
import { ROUTES } from '../../components/Navigation/data';
import { useHasUserWithPendingMfa } from '../../hooks/useHasUserWithPendingMfa';

import { useStorageActiveOrgAndProject } from './helpers';

export type DashboardContextValue = {
  activeOrganization: Organization | undefined;
  activeOrganizationId: string | undefined;
  activeProjectId: string | undefined;
  fetchUserOrganizations: VoidFunction;
  fetchUserProjects: VoidFunction;
  isLoadingUserOrganizations: boolean;
  isLoadingUserProjects: boolean;
  setActiveOrganizationId: (organizationId: string | undefined) => void;
  setActiveProjectId: (projectId: string | undefined) => void;
  setUserInvites: Dispatch<SetStateAction<Invite[]>>;
  setUserOrganizations: Dispatch<SetStateAction<Organization[]>>;
  setUserProjects: Dispatch<SetStateAction<Project[]>>;
  userInvites: Invite[];
  userOrganizations: Organization[];
  userProjects: Project[];
};

export const DashboardContext = createContext<
  DashboardContextValue | undefined
>(undefined);

type DashboardContextProviderProps = {
  children: ReactNode;
};

export const DashboardContextProvider = ({
  children,
}: DashboardContextProviderProps) => {
  const hasUserWithPendingMfa = useHasUserWithPendingMfa();

  const [userOrganizations, setUserOrganizations] = useState<Organization[]>(
    [],
  );
  const [userProjects, setUserProjects] = useState<Project[]>([]);
  const [userInvites, setUserInvites] = useState<Invite[]>([]);
  const {
    activeOrganizationId,
    activeProjectId,
    setActiveOrganizationId,
    setActiveProjectId,
  } = useStorageActiveOrgAndProject();

  const isLoggedIn = useIsLoggedIn();

  const navigate = useNavigate();

  const {
    isLoading: isLoadingUserOrganizations,
    refetch: refetchUserOrganizations,
    isFetchedAfterMount: organizationsFetchFinished,
  } = useGetOrganizationsForMember({
    onSuccess: (response) => {
      if (!response.organizations || response.organizations.length === 0)
        return;

      setActiveOrganizationId(
        response.organizations.find((org) => org.id === activeOrganizationId)
          ?.id ?? response.organizations[0].id,
      );
      setUserOrganizations(response.organizations);
    },
  });

  const { isLoading: isLoadingUserProjects, refetch: refetchUserProjects } =
    useGetProjects(
      {
        organizationId: activeOrganizationId || '', // query will be triggered only when activeOrganizationId is truthy
      },
      {
        enabled: Boolean(activeOrganizationId),
        onSuccess: (response) => {
          if (!response.projects || response.projects.length === 0) return;

          setActiveProjectId(
            response.projects.find((project) => project.id === activeProjectId)
              ?.id ?? response.projects[0].id,
          );
          setUserProjects(response.projects);
        },
      },
    );

  const { isFetchedAfterMount: finishedFetchingInvites } = useGetInvites(
    {
      status: InviteStatusEnum.Pending,
    },
    {
      onSuccess: (response) => {
        if (!response.invites) return;

        setUserInvites(response.invites);
        if (response.invites.length !== 0) {
          navigate(ROUTES.onboarding);
        }
      },
    },
  );

  const value = useMemo(
    () => ({
      activeOrganization: userOrganizations.find(
        ({ id }) => id === activeOrganizationId,
      ),
      activeOrganizationId,
      activeProjectId,
      fetchUserOrganizations: refetchUserOrganizations,
      fetchUserProjects: refetchUserProjects,
      isLoadingUserOrganizations,
      isLoadingUserProjects,
      setActiveOrganizationId,
      setActiveProjectId,
      setUserInvites,
      setUserOrganizations,
      setUserProjects,
      userInvites,
      userOrganizations,
      userProjects,
    }),
    [
      userOrganizations,
      activeOrganizationId,
      activeProjectId,
      refetchUserOrganizations,
      refetchUserProjects,
      isLoadingUserOrganizations,
      isLoadingUserProjects,
      setActiveOrganizationId,
      setActiveProjectId,
      userInvites,
      userProjects,
    ],
  );

  const apiFetchFinished =
    (!isLoadingUserOrganizations || !isLoadingUserProjects) &&
    finishedFetchingInvites &&
    organizationsFetchFinished;

  const renderProperView = useCallback(() => {
    if (isMobile() && window.location.pathname !== '/dashboard/onboarding') {
      return <MobileComingSoon />;
    }

    if (!isLoggedIn && !hasUserWithPendingMfa) {
      navigate(ROUTES.home);
      return;
    }

    if (!apiFetchFinished) return null;

    return children;
  }, [apiFetchFinished, children, hasUserWithPendingMfa, isLoggedIn, navigate]);

  return (
    <DashboardContext.Provider value={value}>
      {renderProperView()}
    </DashboardContext.Provider>
  );
};

export const useDashboardContext = () => {
  const value = useContext(DashboardContext);
  if (value === undefined) {
    throw new Error(
      'useDashboardContext called without being wrapped in DashboardContext.Provider',
    );
  }

  return value;
};
