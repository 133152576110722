import { Dispatch, SetStateAction, useMemo, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  EnvironmentEnum,
  ProviderEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';
import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { useSettingsHasChanged } from '../../../../../../app/hooks/useSettingsHasChanged';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { enforceProviderRules } from '../../../../../../app/utils/enforceProviderRules';
import { useSubscriptionLock } from '../../../../../../app/hooks/useSubscriptionLock';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useSaveProvidersSettings } from '../../../../../hooks';
import { ROUTES } from '../../../../../../app/components/Navigation/data';
import { ModalHashLocations } from '../../../../../../app/routes/Configurations/utils';

export enum MissingSettingsWarningEnum {
  Chain = 'chain',
  MFA = 'mfa',
}

export type UseSaveDynamicSectionProviderReturnType = {
  handleReset: VoidFunction;
  handleSave: VoidFunction;
  missingSettings: {
    action: VoidFunction;
    i18nKeyActionText: string;
    i18nKeyText: string;
    setting: MissingSettingsWarningEnum;
  }[];
  setShouldShowMissingSettingsWarning: Dispatch<SetStateAction<boolean>>;
  shouldShowMissingSettingsWarning: boolean;
  shouldShowSaveBanner: boolean;
};

export const useSaveDynamicSectionProvider = () => {
  const navigate = useNavigate();

  const MissingSettingsVariants: Record<
    MissingSettingsWarningEnum,
    {
      action: VoidFunction;
      i18nKeyActionText: string;
      i18nKeyText: string;
    }
  > = {
    [MissingSettingsWarningEnum.Chain]: {
      action: () => {
        navigate(
          `${ROUTES.embeddedWalletsDynamic}#${ModalHashLocations.DynamicEmbeddedWallet}`,
        );
      },
      i18nKeyActionText:
        'v2.page.embedded_wallets.dynamic_section.missing_settings.one_chain',
      i18nKeyText:
        'v2.page.embedded_wallets.dynamic_section.missing_settings.chain',
    },
    [MissingSettingsWarningEnum.MFA]: {
      action: () => {
        navigate(ROUTES.embeddedWalletsDynamic);
      },
      i18nKeyActionText:
        'v2.page.embedded_wallets.dynamic_section.missing_settings.one_mfa',
      i18nKeyText:
        'v2.page.embedded_wallets.dynamic_section.missing_settings.mfa',
    },
  };

  const {
    hasChanges: hasAnyProviderChanged,
    resetProviderState,
    changedProviders,
    getProviderValue,
  } = useProvidersContext();
  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
    liveHasChanged,
    sandboxHasChanged,
  } = useSettingsHasChanged('sdk');
  const { activeEnvironmentType, environments } = useEnvironmentsContext();
  const { settings } = useSettingsContext();
  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );

  const [
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
  ] = useState(false);

  const projectSettingsHasChanged =
    (liveHasChanged && activeEnvironmentType === EnvironmentEnum.Live) ||
    (sandboxHasChanged && activeEnvironmentType === EnvironmentEnum.Sandbox);

  const shouldShowSaveBanner =
    hasAnyProviderChanged || projectSettingsHasChanged;

  const { mutate: saveProjectSettings } = useUpdateProjectSettings({
    onSuccess: () => {
      updateProjectInitialSettings(activeEnvironmentType);
    },
  });

  const { saveProviders } = useSaveProvidersSettings();

  const handleSave = () => {
    if (!shouldLockOnLive) {
      if (missingSettings.length > 0) {
        setShouldShowMissingSettingsWarning(true);
        return;
      }

      if (projectSettingsHasChanged) {
        saveProjectSettings({
          environmentId: environments?.[activeEnvironmentType].id || '',
          projectSettings: settings[activeEnvironmentType],
        });
      }

      if (hasAnyProviderChanged) {
        saveProviders({
          providersToSave: enforceProviderRules(changedProviders),
        });
      }
    }
  };

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  // What needs to be toggled to save?
  // 1. At least one chain
  // 2. Email OTP needs to be enabled
  // 3. At least one mfa - only when NOT on silent signing list
  const missingSettings = useMemo(() => {
    if (!isTurnkeyEnabled) {
      return [];
    }

    const embeddedWalletsSettings =
      settings[activeEnvironmentType]?.sdk?.embeddedWallets;

    if (!embeddedWalletsSettings) {
      return [];
    }

    const missingSettingsList: MissingSettingsWarningEnum[] = [];

    const atLeastOneChainEnabled = Boolean(
      embeddedWalletsSettings.chainConfigurations?.some(
        (chain) => chain.enabled,
      ),
    );

    if (!atLeastOneChainEnabled) {
      missingSettingsList.push(MissingSettingsWarningEnum.Chain);
    }

    const { supportedSecurityMethods } = embeddedWalletsSettings;

    const atLeastOneMFAMethodEnabled = supportedSecurityMethods
      ? Object.values(supportedSecurityMethods).filter(
          (method) => method?.isEnabled,
        ).length > 0
      : false;

    if (!atLeastOneMFAMethodEnabled) {
      missingSettingsList.push(MissingSettingsWarningEnum.MFA);
    }

    return missingSettingsList;
  }, [isTurnkeyEnabled, settings, activeEnvironmentType]);

  const handleReset = () => {
    resetProviderState();
    cancelProjectChanges();
  };

  return {
    handleReset,
    handleSave,
    missingSettings: missingSettings.map((setting) => ({
      action: MissingSettingsVariants[setting].action,
      i18nKeyActionText: MissingSettingsVariants[setting].i18nKeyActionText,
      i18nKeyText: MissingSettingsVariants[setting].i18nKeyText,
      setting,
    })),
    setShouldShowMissingSettingsWarning,
    shouldShowMissingSettingsWarning,
    shouldShowSaveBanner,
  };
};
