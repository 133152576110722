import { FormError } from '../../../../../../../components/FormError/form-error';
import { ErrorInfo } from '../../../../../../../components/ErrorInfo';
import { ErrorMessage, GateErrors, GateTouchedFields } from '../types';
import { buildErrorMessages } from '../helpers';

interface Props {
  errors: GateErrors;
  touched: GateTouchedFields;
}

export const FormErrors = ({ errors, touched }: Props): JSX.Element | null => {
  const errorMessages: ErrorMessage[] = buildErrorMessages(errors);

  // Should show errors message only if fields with errors have been touched
  const showErrors =
    Object.keys(touched).length &&
    Object.keys(errors).length &&
    Object.keys(touched).some((key) => (errors as any)[key]);

  if (!showErrors) return null;

  return (
    <ErrorInfo className='mb-5'>
      {errorMessages.map(({ title, message }) => (
        <FormError key={title} title={title} message={message} />
      ))}
    </ErrorInfo>
  );
};
