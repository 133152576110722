import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
  FC,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
} from 'react';

import { CustomField, CustomFieldRequest } from '@dynamic-labs/sdk-api';
import { useCustomFields as useCustomFieldsApi } from '@dynamic-labs/redcoast-query';

import { useEnvironmentId } from '../../routes/Configurations/Providers/hooks';

export type CustomFieldsContextValue = {
  cancelChanges: () => void;
  currentFields: CustomField[];
  customFields: CustomField[] | undefined;
  deleteCustomField: (customFieldId: string) => Promise<void>;
  getFieldById: (id: string) => CustomField | undefined;
  isLoading: boolean;
  saveChanges: () => Promise<void>;
  setCurrentFields: Dispatch<SetStateAction<CustomField[]>>;
  settingsHasChanged: boolean;
  updateCustomField: (
    customFieldId: string,
    customFieldRequest: CustomFieldRequest,
  ) => Promise<CustomField | void>;
  updateLocalField: (updatedField: CustomField) => void;
};

const CustomFieldsContext = createContext<CustomFieldsContextValue | undefined>(
  undefined,
);

export const CustomFieldsProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const environmentId = useEnvironmentId();
  const {
    customFields,
    updateCustomField: updateCustomFieldApi,
    deleteCustomField: deleteCustomFieldApi,
    isLoading,
  } = useCustomFieldsApi({
    environmentId: environmentId || '',
  });

  const [initialFields, setInitialFields] = useState<CustomField[]>([]);
  const [currentFields, setCurrentFields] = useState<CustomField[]>([]);

  useEffect(() => {
    if (customFields) {
      setInitialFields(customFields);
      setCurrentFields(customFields);
    }
  }, [customFields]);

  const settingsHasChanged = currentFields.some((currentField) => {
    const originalField = initialFields.find(
      (field) => field.id === currentField.id,
    );
    return originalField && currentField.enabled !== originalField.enabled;
  });

  const saveChanges = async () => {
    if (!initialFields) {
      return;
    }

    const fieldsToUpdate = currentFields.filter((currentField) => {
      const originalField = initialFields.find(
        (field) => field.id === currentField.id,
      );
      return originalField && currentField.enabled !== originalField.enabled;
    });

    await Promise.all(
      fieldsToUpdate.map((field) =>
        updateCustomFieldApi(field.id, { ...field, enabled: field.enabled }),
      ),
    );
  };

  const updateLocalField = useCallback((updatedField: CustomField) => {
    setCurrentFields((prevFields) =>
      prevFields.map((field) =>
        field.id === updatedField.id ? updatedField : field,
      ),
    );
  }, []);

  const getFieldById = useCallback(
    (id: string) => currentFields.find((field) => field.id === id),
    [currentFields],
  );

  const cancelChanges = () => {
    setCurrentFields(initialFields);
  };

  const updateCustomField = async (
    customFieldId: string,
    customFieldRequest: CustomFieldRequest,
  ) => {
    const updatedField = await updateCustomFieldApi(
      customFieldId,
      customFieldRequest,
    );
    return updatedField;
  };

  const deleteCustomField = async (customFieldId: string) => {
    await deleteCustomFieldApi(customFieldId);
  };

  const value = useMemo(
    () => ({
      cancelChanges,
      currentFields,
      customFields,
      deleteCustomField,
      getFieldById,
      isLoading,
      saveChanges,
      setCurrentFields,
      settingsHasChanged,
      updateCustomField,
      updateLocalField,
    }),
    [currentFields, settingsHasChanged, customFields, isLoading],
  );

  return (
    <CustomFieldsContext.Provider value={value}>
      {children}
    </CustomFieldsContext.Provider>
  );
};

export const useCustomFieldsContext = () => {
  const context = useContext(CustomFieldsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useCustomFieldsContext not wrapped in `CustomFieldsProvider`.',
    );
  }

  return context;
};
