import { useCallback, useMemo } from 'react';

import { t } from 'i18next';
import { format } from 'date-fns';

import { Tab } from '../../../../components/Tabs';
import Button from '../../../../components/Button';
import { DateInput } from '../../../../components/DateInput';
import Dropdown from '../../../../components/Dropdown';
import { InputLabel } from '../../../../components/InputLabel';
import { ArrowRightIcon } from '../../../../../icons';

import { CustomSelectionTabComponent } from './CustomSelectionTab.types';

export const CustomSelectionTab: CustomSelectionTabComponent = ({
  onChange,
  value,
}) => {
  const setDate = useCallback(
    (type: 'start' | 'end', date: Date) =>
      onChange((latestValue) => ({
        ...latestValue,
        [type]: date,
      })),
    [onChange],
  );

  const handleOnClickTab = useCallback(
    () =>
      onChange((latestValue) => {
        if (latestValue.key === 'custom') return latestValue;

        return {
          key: 'custom',
        };
      }),
    [onChange],
  );

  const tabLabel = useMemo(() => {
    const { start, end } = value;

    const showDatesFormatted = value.key === 'custom' && start && end;

    if (showDatesFormatted) {
      return (
        <div className='flex items-center gap-1'>
          {format(start, 'MM-dd-yyyy')} <ArrowRightIcon />
          {format(end, 'MM-dd-yyyy')}
        </div>
      );
    }

    return t<string>('analytics.tabs.custom');
  }, [value]);

  const handleSetEndDate = useCallback(
    (date: Date) => {
      const formattedDate = date.setHours(23, 59, 59, 999);
      setDate('end', new Date(formattedDate));
    },
    [setDate],
  );

  return (
    <div className='text-sm'>
      <Dropdown>
        <Dropdown.Control>
          <Tab
            dataTestId='custom-selection-bottom'
            onClick={handleOnClickTab}
            isActive={value.key === 'custom'}
          >
            {tabLabel}
          </Tab>
        </Dropdown.Control>
        <Dropdown.List className='!min-w-[182px]'>
          <div className='p-3 flex flex-col gap-2'>
            <InputLabel
              label={t<string>('analytics.tabs.custom_menu.from_label')}
            >
              <DateInput
                dataTestId='start-date-input'
                placeholder={t<string>(
                  'analytics.tabs.custom_menu.from_input.placeholder',
                )}
                value={value.start}
                onChange={(date) => setDate('start', date)}
                max={new Date()}
              />
            </InputLabel>

            <InputLabel
              label={t<string>('analytics.tabs.custom_menu.to_label')}
            >
              <DateInput
                dataTestId='end-date-input'
                placeholder={t<string>(
                  'analytics.tabs.custom_menu.to_input.placeholder',
                )}
                value={value.end}
                onChange={handleSetEndDate}
                disabled={!value.start}
                max={new Date()}
              />
            </InputLabel>
          </div>
          <Dropdown.Footer>
            <Button
              onClick={() => onChange({ key: 'custom' })}
              variant='secondary'
            >
              {t<string>('analytics.tabs.custom_menu.clear_button_text')}
            </Button>
          </Dropdown.Footer>
        </Dropdown.List>
      </Dropdown>
    </div>
  );
};
