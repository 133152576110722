import { UseMutationOptions, useMutation } from 'react-query';

import { ExportFormatEnum, ExportModelEnum } from '@dynamic-labs/sdk-api';

import { exportsApi } from '../../../../../services/api';

export const useCreateExportMutation = (
  environmentId: string,
  exportCreateRequest: {
    format: ExportFormatEnum;
    model: ExportModelEnum;
  },
  options: UseMutationOptions<unknown, Error>,
) =>
  useMutation(
    ['environments', environmentId, 'exports', 'create'],
    () =>
      exportsApi.createExport({
        environmentId,
        exportCreateRequest,
      }),
    options,
  );
