import { useMemo } from 'react';

import { format } from 'date-fns';

import { fillMissingDays } from '../../utils';
import { BarChart } from '../../../../components/Chart';

import { LoadingBarChartComponent } from './LoadingBarChart.types';

export const LoadingBarChart: LoadingBarChartComponent = ({
  startDate,
  endDate,
}) => {
  const data = useMemo(
    () =>
      fillMissingDays<{ count: number; date: Date }>({
        data: [],
        endDate,
        fillFn: (date) => ({
          count: 5,
          date,
        }),
        getter: ({ date }) => date,
        startDate,
      }),
    [startDate, endDate],
  );

  const barChartData = useMemo(
    () => ({
      datasets: [
        {
          backgroundColor: '#E9ECF2',
          data: data.map(({ count }) => count),
        },
      ],
      labels: data.map(({ date }) => format(date, 'MM-dd')),
    }),
    [data],
  );

  return (
    <BarChart
      options={{
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            grid: {
              color: 'rgba(233, 236, 242, 1)',
            },
            ticks: {
              color: 'rgba(121, 126, 146, 1)',
            },
          },
          y: {
            beginAtZero: true,
            grid: {
              color: 'rgba(233, 236, 242, 1)',
            },
            max: 50,
            min: 0,
            ticks: {
              color: 'rgba(121, 126, 146, 1)',
            },
          },
        },
      }}
      data={barChartData}
    />
  );
};
