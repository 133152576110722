/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Provider,
    ProviderFromJSON,
    ProviderToJSON,
    ProviderCreateRequest,
    ProviderCreateRequestFromJSON,
    ProviderCreateRequestToJSON,
    ProviderUpdateRequest,
    ProviderUpdateRequestFromJSON,
    ProviderUpdateRequestToJSON,
    ProviderUrlsResponse,
    ProviderUrlsResponseFromJSON,
    ProviderUrlsResponseToJSON,
    ProvidersResponse,
    ProvidersResponseFromJSON,
    ProvidersResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateProviderRequest {
    environmentId: string;
    providerCreateRequest: ProviderCreateRequest;
}

export interface DeleteProviderRequest {
    providerId: string;
}

export interface DisableProviderRequest {
    providerId: string;
}

export interface EnableProviderRequest {
    providerId: string;
}

export interface GetEnvironmentProviderUrlsRequest {
    environmentId: string;
}

export interface GetEnvironmentProvidersRequest {
    environmentId: string;
}

export interface GetProviderRequest {
    providerId: string;
}

export interface UpdateProviderRequest {
    providerId: string;
    providerUpdateRequest: ProviderUpdateRequest;
}

/**
 * 
 */
export class SettingsApi extends runtime.BaseAPI {

    /**
     * Creates a new provider for the project environment
     */
    async createProviderRaw(requestParameters: CreateProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Provider>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createProvider.');
        }

        if (requestParameters.providerCreateRequest === null || requestParameters.providerCreateRequest === undefined) {
            throw new runtime.RequiredError('providerCreateRequest','Required parameter requestParameters.providerCreateRequest was null or undefined when calling createProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/settings/providers`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderCreateRequestToJSON(requestParameters.providerCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderFromJSON(jsonValue));
    }

    /**
     * Creates a new provider for the project environment
     */
    async createProvider(requestParameters: CreateProviderRequest, initOverrides?: RequestInit): Promise<Provider> {
        const response = await this.createProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a provider by providerId
     */
    async deleteProviderRaw(requestParameters: DeleteProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling deleteProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/providers/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a provider by providerId
     */
    async deleteProvider(requestParameters: DeleteProviderRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteProviderRaw(requestParameters, initOverrides);
    }

    /**
     * Disable the provider for the environment
     */
    async disableProviderRaw(requestParameters: DisableProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Provider>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling disableProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/providers/{providerId}/disable`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderFromJSON(jsonValue));
    }

    /**
     * Disable the provider for the environment
     */
    async disableProvider(requestParameters: DisableProviderRequest, initOverrides?: RequestInit): Promise<Provider> {
        const response = await this.disableProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable the provider for the environment
     */
    async enableProviderRaw(requestParameters: EnableProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Provider>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling enableProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/settings/providers/{providerId}/enable`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderFromJSON(jsonValue));
    }

    /**
     * Enable the provider for the environment
     */
    async enableProvider(requestParameters: EnableProviderRequest, initOverrides?: RequestInit): Promise<Provider> {
        const response = await this.enableProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the URLs for the environment providers
     */
    async getEnvironmentProviderUrlsRaw(requestParameters: GetEnvironmentProviderUrlsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProviderUrlsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentProviderUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/settings/providers/urls`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderUrlsResponseFromJSON(jsonValue));
    }

    /**
     * Get the URLs for the environment providers
     */
    async getEnvironmentProviderUrls(requestParameters: GetEnvironmentProviderUrlsRequest, initOverrides?: RequestInit): Promise<ProviderUrlsResponse> {
        const response = await this.getEnvironmentProviderUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the providers for an environment
     */
    async getEnvironmentProvidersRaw(requestParameters: GetEnvironmentProvidersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ProvidersResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentProviders.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/settings/providers`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProvidersResponseFromJSON(jsonValue));
    }

    /**
     * Get the providers for an environment
     */
    async getEnvironmentProviders(requestParameters: GetEnvironmentProvidersRequest, initOverrides?: RequestInit): Promise<ProvidersResponse> {
        const response = await this.getEnvironmentProvidersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Gets a provider
     */
    async getProviderRaw(requestParameters: GetProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Provider>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling getProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/settings/providers/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderFromJSON(jsonValue));
    }

    /**
     * Gets a provider
     */
    async getProvider(requestParameters: GetProviderRequest, initOverrides?: RequestInit): Promise<Provider> {
        const response = await this.getProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates a provider
     */
    async updateProviderRaw(requestParameters: UpdateProviderRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Provider>> {
        if (requestParameters.providerId === null || requestParameters.providerId === undefined) {
            throw new runtime.RequiredError('providerId','Required parameter requestParameters.providerId was null or undefined when calling updateProvider.');
        }

        if (requestParameters.providerUpdateRequest === null || requestParameters.providerUpdateRequest === undefined) {
            throw new runtime.RequiredError('providerUpdateRequest','Required parameter requestParameters.providerUpdateRequest was null or undefined when calling updateProvider.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/settings/providers/{providerId}`.replace(`{${"providerId"}}`, encodeURIComponent(String(requestParameters.providerId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ProviderUpdateRequestToJSON(requestParameters.providerUpdateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProviderFromJSON(jsonValue));
    }

    /**
     * Updates a provider
     */
    async updateProvider(requestParameters: UpdateProviderRequest, initOverrides?: RequestInit): Promise<Provider> {
        const response = await this.updateProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
