import { useState } from 'react';

// eslint-disable-next-line import/no-namespace
import * as Tabs from '@radix-ui/react-tabs';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Organization, Project } from '@dynamic-labs/sdk-api';

import Button from '../../components/Button';
import { MembersContextProvider } from '../../context/MembersContext';
import { PlusIcon } from '../../../icons';
import { Icon } from '../../components/Icon';
import { useHashLocation } from '../../hooks/useHashLocation';

import InvitesModal from './InvitesModal';
import { OrganizationContent } from './OrganizationContent';
import { SubscriptionContent } from './SubscriptionContent';
import { MembersTable } from './MembersTable';
import styles from './AdminView.module.css';
import { SecurityContent } from './SecurityContent';

type AdminViewProps = {
  onProjectDelete(): void;
  onProjectUpdate(): void;
  onUpdateSuccess(): void;
  organization: Organization;
  projects: Project[];
};

const DEAFULT_TAB = 'organization';

enum Tab {
  MembersTab = 'members',
  OrganizationTab = 'organization',
  SecurityTab = 'security',
  SubscriptionTab = 'subscription',
}

export const AdminView = ({
  organization,
  onUpdateSuccess,
  onProjectUpdate,
  onProjectDelete,
  projects,
}: AdminViewProps) => {
  const [showModal, setShowModal] = useState(false);

  const { enableBillingInDashboard, enableOrganizationAdminSettings } =
    useFlags();

  const { t } = useTranslation();

  const { hash: tabValue, setHash } = useHashLocation({
    availableValues: Object.values(Tab),
    defaultValue: DEAFULT_TAB,
  });

  return (
    <div className='w-full'>
      <Tabs.Root
        defaultValue={tabValue}
        onValueChange={(value) => setHash(value)}
      >
        <Tabs.List>
          <Tabs.Trigger
            className={styles.tabTrigger}
            value={Tab.OrganizationTab}
          >
            {t('admin.tabs.organization')}
          </Tabs.Trigger>
          <Tabs.Trigger className={styles.tabTrigger} value={Tab.MembersTab}>
            {t('admin.tabs.members')}
          </Tabs.Trigger>
          {enableBillingInDashboard && (
            <Tabs.Trigger
              className={styles.tabTrigger}
              value={Tab.SubscriptionTab}
            >
              {t('admin.tabs.subscription')}
            </Tabs.Trigger>
          )}
          {enableOrganizationAdminSettings && (
            <Tabs.Trigger className={styles.tabTrigger} value={Tab.SecurityTab}>
              {t('admin.tabs.security')}
            </Tabs.Trigger>
          )}
        </Tabs.List>

        {enableOrganizationAdminSettings && (
          <Tabs.Content className='py-6' value='security'>
            <SecurityContent />
          </Tabs.Content>
        )}

        {enableBillingInDashboard && (
          <Tabs.Content className='py-6' value='subscription'>
            <SubscriptionContent />
          </Tabs.Content>
        )}

        <Tabs.Content className='py-6' value='organization'>
          <OrganizationContent
            organization={organization}
            onUpdateSuccess={onUpdateSuccess}
            onProjectUpdate={onProjectUpdate}
            onProjectDelete={onProjectDelete}
            projects={projects}
          />
        </Tabs.Content>
        <Tabs.Content className='py-6' value='members'>
          <MembersContextProvider>
            <MembersTable />
            <Button
              onClick={() => setShowModal(true)}
              variant='secondary'
              className={styles['button--add']}
              leading={<Icon size='medium' icon={<PlusIcon />} />}
            >
              {t('admin.invite')}
            </Button>
            {showModal && (
              <InvitesModal setShowModal={() => setShowModal(false)} />
            )}
          </MembersContextProvider>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  );
};
