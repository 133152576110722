/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SignInProviderEnum,
    SignInProviderEnumFromJSON,
    SignInProviderEnumFromJSONTyped,
    SignInProviderEnumToJSON,
} from './SignInProviderEnum';

/**
 * 
 * @export
 * @interface ProjectSettingsSdkEmailSignIn
 */
export interface ProjectSettingsSdkEmailSignIn {
    /**
     * 
     * @type {SignInProviderEnum}
     * @memberof ProjectSettingsSdkEmailSignIn
     */
    signInProvider?: SignInProviderEnum;
}

export function ProjectSettingsSdkEmailSignInFromJSON(json: any): ProjectSettingsSdkEmailSignIn {
    return ProjectSettingsSdkEmailSignInFromJSONTyped(json, false);
}

export function ProjectSettingsSdkEmailSignInFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkEmailSignIn {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'signInProvider': !exists(json, 'signInProvider') ? undefined : SignInProviderEnumFromJSON(json['signInProvider']),
    };
}

export function ProjectSettingsSdkEmailSignInToJSON(value?: ProjectSettingsSdkEmailSignIn | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'signInProvider': SignInProviderEnumToJSON(value.signInProvider),
    };
}

