import { TFunction } from 'react-i18next';

import { Webhook } from '@dynamic-labs/sdk-api';

import { TableSkeleton } from '../../../components/TableSkeleton';
import { Badge } from '../../../components/Badge';

export const webhooksColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: t('webhooks_table.url'),
    accessor: (row: Webhook) => (
      <div className='text-gray-1 text-xs overflow-hidden text-ellipsis'>
        {row.url}
      </div>
    ),
    className: 'min-w-[180px] w-full hover:cursor-pointer',
    id: 'url',
    skeleton: <TableSkeleton />,
  },
  {
    Header: 'Events',
    accessor: (row: Webhook) => (
      <Badge
        variant='white'
        text={`${row.events?.length} events`}
        size='small'
      />
    ),
    className: 'hover:cursor-pointer',
    id: 'events',
    skeleton: <TableSkeleton />,
  },
  {
    Header: 'Status',
    accessor: (row: Webhook) => (
      <Badge
        variant={row.isEnabled ? 'green' : 'red'}
        text={row.isEnabled ? 'Enabled' : 'Disabled'}
        size='small'
      />
    ),
    className: 'hover:cursor-pointer',
    id: 'status',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
];
