import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@dynamic-labs/northstar';

import { RequireAuthenticatorAtSignUpToggle } from '../shared/RequireAuthenticatorAtSignUpToggle';
import { RecoveryToggle } from '../shared/RecoveryToggle';
import { useEnvironmentsContext } from '../../../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../../../app/context/SettingsContext';

import styles from './PasskeySettingsDrawer.module.scss';

type PasskeySettingsDrawerProps = {
  disabled: boolean;
};

export const PasskeySettingsDrawer: FC<PasskeySettingsDrawerProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings } = useSettingsContext();

  const supportedSecurityMethodsSettings =
    settings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.supportedSecurityMethods;

  const isPasskeyEnabled = Boolean(
    supportedSecurityMethodsSettings?.passkey?.isEnabled,
  );

  return (
    <div className={styles.drawer}>
      <Typography variant='paragraph-3' color='gray-1' weight='medium'>
        {t(
          'v2.page.embedded_wallets.dynamic_section.mfa.method.passkey.drawer.advanced',
        )}
      </Typography>

      <RequireAuthenticatorAtSignUpToggle
        disabled={disabled}
        authenticatorEnabled={isPasskeyEnabled}
      />
      <RecoveryToggle
        disabled={disabled}
        authenticatorEnabled={isPasskeyEnabled}
      />
    </div>
  );
};
