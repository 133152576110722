/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';

/**
 * 
 * @export
 * @interface OnrampConfiguration
 */
export interface OnrampConfiguration {
    /**
     * 
     * @type {ProviderEnum}
     * @memberof OnrampConfiguration
     */
    provider?: ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof OnrampConfiguration
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OnrampConfiguration
     */
    iconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof OnrampConfiguration
     */
    url?: string;
}

export function OnrampConfigurationFromJSON(json: any): OnrampConfiguration {
    return OnrampConfigurationFromJSONTyped(json, false);
}

export function OnrampConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): OnrampConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : ProviderEnumFromJSON(json['provider']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function OnrampConfigurationToJSON(value?: OnrampConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderEnumToJSON(value.provider),
        'name': value.name,
        'iconUrl': value.iconUrl,
        'url': value.url,
    };
}

