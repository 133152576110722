/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery } from 'react-query';

import { Webhook, WebhookMessage } from '@dynamic-labs/sdk-api';

import { Typography } from '../../../../components/Typography';
import { Skeleton } from '../../../../components/Skeleton/Skeleton';
import Button from '../../../../components/Button';
import { RefreshIcon } from '../../../../../icons';
import { webhooksApi } from '../../../../services/api';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';

import { MessageListItem } from './MessageListItem';
import styles from './MessageList.module.css';

export const MessageList = ({ webhook }: { webhook: Webhook }) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const [expanded, setExpanded] = useState<string>('');
  const { t } = useTranslation();
  const { webhookId } = webhook;
  const environmentId = activeEnvironment?.id || '';

  const fetchWebhookMessages = async (page: any) => {
    const cursor = page?.pageParam || undefined;
    const res = await webhooksApi.getWebhookMessages({
      cursor,
      environmentId,
      webhookId,
    });
    return {
      data: res.data,
      nextPage: res.cursor,
    };
  };

  const {
    data: webhookMessagesResponse,
    isLoading: isLoadingWebhookMessages,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isRefetching,
    refetch,
  } = useInfiniteQuery<any>({
    getNextPageParam: (lastPage) => lastPage.nextPage,
    queryFn: fetchWebhookMessages,
    queryKey: ['webhookMessages', environmentId, webhookId],
    refetchInterval: 5000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const messages =
    webhookMessagesResponse?.pages?.map(({ data }) => data).flat() || [];
  const messagesMap: { [key: string]: WebhookMessage[] } = {};
  messages
    ?.sort((a: any, b: any) => b.updatedAt - a.updatedAt)
    .forEach((event: WebhookMessage) => {
      const day = moment(event.updatedAt).format('MMM Do, YYYY');
      if (!messagesMap[day]) {
        messagesMap[day] = [];
      }
      messagesMap[day].push(event);
    });

  let content;
  if (isLoadingWebhookMessages && !isRefetching) {
    content = (
      <div className={styles.messageList}>
        <div className={styles.messageHeader}>
          <div className='h-10 flex items-center px-4'>
            <Skeleton className='!py-2 !mb-0' />
          </div>
          {[1, 2, 3].map((id) => (
            <div key={id} className='h-14 flex items-center py-3 px-4'>
              <Skeleton className='!mb-0' />
            </div>
          ))}
        </div>
      </div>
    );
  } else if (Object.keys(messagesMap).length) {
    content = (
      <div className={styles.messageList}>
        {Object.keys(messagesMap).map((day: string) => (
          <div key={day} className={styles.messageHeader}>
            <div className='sticky top-0 z-10 px-4 py-3 text-xs font-medium text-gray-1 bg-cloud-1'>
              <h3>{day}</h3>
            </div>
            {messagesMap[day].map((message) => (
              <MessageListItem
                webhook={webhook}
                key={message.deliveryId}
                deliveryId={message?.deliveryId}
                expanded={expanded}
                setExpanded={setExpanded}
                message={message}
              />
            ))}
          </div>
        ))}
        {hasNextPage && (
          <div className='flex justify-center my-3'>
            <Button
              variant='link'
              onClick={() => fetchNextPage()}
              className='flex justify-between'
              loading={isFetching}
            >
              <span
                className={`relative py-1 ${isFetching ? 'invisible' : ''}`}
              >
                {t(
                  'webhooks.webhook_detail_modal.message_list_pagnation_button_label',
                )}
              </span>
            </Button>
          </div>
        )}
      </div>
    );
  } else {
    content = (
      <Typography variant='paragraph-1' className='mb-3'>
        {t('webhooks.webhook_detail_modal.message_list_empty_label')}
      </Typography>
    );
  }

  return (
    <div>
      <div className='flex items-center justify-between mb-2'>
        <Typography variant='paragraph-1' weight='medium' color='gray-1'>
          {t('webhooks.webhook_detail_modal.messages_section_label')}
        </Typography>
        <Button
          variant='secondary'
          disabled={isLoadingWebhookMessages}
          onClick={() => refetch()}
          loading={isRefetching}
        >
          <RefreshIcon className='w-4 h-4 text-gray-2' />
        </Button>
      </div>
      {content}
    </div>
  );
};
