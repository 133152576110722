import { useQuery } from 'react-query';

import { fetchVisitorCountByEnvId } from './helpers';
import {
  EnvironmentVisitorsQueryHook,
  EnvironmentVisitorsQueryHookData,
} from './useEnvironmentVisitorsQuery.types';

export const useEnvironmentVisitorsQuery: EnvironmentVisitorsQueryHook = (
  { environmentId },
  options,
) =>
  useQuery<EnvironmentVisitorsQueryHookData>(
    ['environments', environmentId, 'visitors'],
    () => fetchVisitorCountByEnvId(environmentId),
    options,
  );
