import { useTranslation } from 'react-i18next';
import { Field } from 'formik';

import { ChainEnum, GateRuleType } from '@dynamic-labs/sdk-api';

import Input from '../../../../../../../components/Input';
import { GateErrors, GateRulePartial, GateTouchedFields } from '../types';

import { DeleteGateRule } from './DeleteGateRule';
import { GateRuleTypeSelect } from './GateRuleTypeSelect';
import { GateRuleNetworkSelect } from './GateRuleNetworkSelect';
import { GateRuleTokenSelect } from './GateRuleTokenSelect';
import styles from './GateRuleView.module.css';

type GateRuleViewProps = {
  formErrors: GateErrors;
  formTouched: GateTouchedFields;
  index: number;
  networkId?: number;
  onDelete: () => void;
  onSelectNetwork: (networkId?: number) => void;
  onSelectToken: (address?: string) => void;
  onSelectType: (type?: GateRuleType) => void;
  showDelete?: boolean;
  tokenAdrress?: string;
  type?: GateRuleType;
};

export const GateRuleView = ({
  index,
  type,
  networkId,
  tokenAdrress,
  onSelectType,
  onSelectNetwork,
  onSelectToken,
  onDelete,
  formErrors,
  formTouched,
  showDelete = true,
}: GateRuleViewProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles['rule-view']}>
      <GateRuleNetworkSelect
        selectedNetworkId={networkId}
        onSelect={onSelectNetwork}
        error={
          !!(formErrors.rules?.[index] as GateRulePartial)?.address
            ?.networkId &&
          !!(formTouched.rules?.[index] as GateRulePartial)?.address?.networkId
        }
      />

      <div className={styles['rule-view--row']}>
        <GateRuleTypeSelect
          selectedType={type}
          onSelect={onSelectType}
          className={styles['rule-view--row-field']}
          error={
            !!(formErrors.rules?.[index] as GateRulePartial)?.type &&
            !!(formTouched.rules?.[index] as GateRulePartial)?.type
          }
          disabled={!networkId}
        />

        {type === GateRuleType.Token && (
          <GateRuleTokenSelect
            className={styles['rule-view--row-field']}
            selectedTokenAddress={tokenAdrress}
            chainName={ChainEnum.Evm}
            networkId={networkId}
            onSelect={onSelectToken}
            error={
              !!(formErrors.rules?.[index] as GateRulePartial)?.address
                ?.contractAddress &&
              !!(formTouched.rules?.[index] as GateRulePartial)?.address
                ?.contractAddress
            }
          />
        )}

        {type !== GateRuleType.Token && (
          <Field
            className={styles['rule-view--row-field']}
            as={Input}
            name={`rules.${index}.address.contractAddress`}
            label={t(
              'integrations.onboarding_and_kyc.access_control.gating.forms.contractAddressLabel',
            )}
            error={
              !!(formErrors.rules?.[index] as GateRulePartial)?.address
                ?.contractAddress &&
              !!(formTouched.rules?.[index] as GateRulePartial)?.address
                ?.contractAddress
            }
            disabled={!type}
          />
        )}

        {type === GateRuleType.Token && (
          <Field
            as={Input}
            type='number'
            className={styles['rule-view--row-field']}
            name={`rules.${index}.filter.amount`}
            label={t(
              'integrations.onboarding_and_kyc.access_control.gating.forms.minimumAmountLabel',
            )}
            error={
              !!(formErrors.rules?.[index] as GateRulePartial)?.filter
                ?.amount &&
              !!(formTouched.rules?.[index] as GateRulePartial)?.filter?.amount
            }
            disabled={!type}
          />
        )}

        {type === GateRuleType.Nft && (
          <Field
            as={Input}
            type='string'
            className={styles['rule-view--row-field']}
            name={`rules.${index}.filter.tokenId`}
            label={t(
              'integrations.onboarding_and_kyc.access_control.gating.forms.tokenIdLabel',
            )}
            error={
              !!(formErrors.rules?.[index] as GateRulePartial)?.filter
                ?.tokenId &&
              !!(formTouched.rules?.[index] as GateRulePartial)?.filter?.tokenId
            }
            disabled={!type}
          />
        )}

        {showDelete && <DeleteGateRule onClick={onDelete} />}
      </div>
    </div>
  );
};
