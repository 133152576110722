import { CSSProperties, PropsWithChildren, forwardRef } from 'react';

import classNames from 'classnames';

import { AnchorOrigin, TransformOrigin } from '../Popper.types';

export type PopperContentProps = {
  className?: string;
  style?: CSSProperties;
  transformOrigin: TransformOrigin;
};

const mapTransformOriginToClassName: Record<AnchorOrigin, string> = {
  'bottom-center': '-translate-x-1/2 -translate-y-full',
  'bottom-left': 'translate-x-0 -translate-y-full',
  'bottom-right': '-translate-x-full -translate-y-full',
  'top-center': '-translate-x-1/2 translate-y-0',
  'top-left': 'translate-x-0 translate-y-0',
  'top-right': '-translate-x-full translate-y-0',
};

export const PopperContent = forwardRef<
  HTMLDivElement,
  PropsWithChildren<PopperContentProps>
>(({ children, transformOrigin, style, className }, ref) => (
  <div
    data-testid='popper-content'
    ref={ref}
    className={classNames(
      'absolute flex z-[10000]',
      mapTransformOriginToClassName[transformOrigin],
      className,
    )}
    style={style}
  >
    {children}
  </div>
));

PopperContent.displayName = 'PopperContent';
