import { t } from 'i18next';

import { Project } from '@dynamic-labs/sdk-api';

import PopupModal from '../../../components/PopupModal';
import PopupHeader from '../../../components/PopupModal/PopupHeader';

import { UpdateProjectForm } from './UpdateProjectForm';
import styles from './UpdateProjectModal.module.css';

type UpdateProjectModalProps = {
  onClose(): void;
  onProjectUpdate(): void;
  projectId: string;
  projectName: string;
  projects: Project[];
};

export const UpdateProjectModal = ({
  onClose,
  projectId,
  projectName,
  projects,
  onProjectUpdate,
}: UpdateProjectModalProps) => (
  <PopupModal handleClose={onClose} className={styles.portal}>
    <PopupHeader
      handleClose={onClose}
      headingText={t('update_project.title')}
    />
    <UpdateProjectForm
      onProjectUpdate={onProjectUpdate}
      submitText={t('actions.save')}
      cancelText={t('actions.cancel')}
      cancelAction={onClose}
      submitAction={onClose}
      projectId={projectId}
      projects={projects}
      projectName={projectName}
    />
  </PopupModal>
);
