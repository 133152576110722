import { FC } from 'react';

import { Icon } from '../../../Icon';
import { TextButton } from '../../../TextButton';
import { PlusIcon } from '../../../../icons';
import { ProjectsDropdownCTA } from '../../UserProjectsDropdown.types';

import styles from './CreateProjectCTA.module.scss';

export const CreateProjectCTA: FC<ProjectsDropdownCTA> = ({
  text,
  action,
  setOpen,
}) => (
  <TextButton
    className={styles.button}
    leading={<Icon icon={<PlusIcon />} size={20} className={styles.icon} />}
    onClick={() => {
      setOpen?.(false);
      action();
    }}
    color='inherit'
    text={text}
  />
);
