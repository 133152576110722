/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthenticationExtensionsClientInputs,
    AuthenticationExtensionsClientInputsFromJSON,
    AuthenticationExtensionsClientInputsFromJSONTyped,
    AuthenticationExtensionsClientInputsToJSON,
} from './AuthenticationExtensionsClientInputs';
import {
    PublicKeyCredentialDescriptor,
    PublicKeyCredentialDescriptorFromJSON,
    PublicKeyCredentialDescriptorFromJSONTyped,
    PublicKeyCredentialDescriptorToJSON,
} from './PublicKeyCredentialDescriptor';
import {
    UserVerificationRequirement,
    UserVerificationRequirementFromJSON,
    UserVerificationRequirementFromJSONTyped,
    UserVerificationRequirementToJSON,
} from './UserVerificationRequirement';

/**
 * 
 * @export
 * @interface MFAAuthPasskeyDeviceGetResponseAssertion
 */
export interface MFAAuthPasskeyDeviceGetResponseAssertion {
    /**
     * 
     * @type {string}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    rpId?: string;
    /**
     * 
     * @type {string}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    challenge: string;
    /**
     * 
     * @type {number}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    timeout?: number;
    /**
     * 
     * @type {UserVerificationRequirement}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    userVerification?: UserVerificationRequirement;
    /**
     * 
     * @type {Array<PublicKeyCredentialDescriptor>}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    allowCredentials?: Array<PublicKeyCredentialDescriptor>;
    /**
     * 
     * @type {AuthenticationExtensionsClientInputs}
     * @memberof MFAAuthPasskeyDeviceGetResponseAssertion
     */
    extensions?: AuthenticationExtensionsClientInputs;
}

export function MFAAuthPasskeyDeviceGetResponseAssertionFromJSON(json: any): MFAAuthPasskeyDeviceGetResponseAssertion {
    return MFAAuthPasskeyDeviceGetResponseAssertionFromJSONTyped(json, false);
}

export function MFAAuthPasskeyDeviceGetResponseAssertionFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAAuthPasskeyDeviceGetResponseAssertion {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rpId': !exists(json, 'rpId') ? undefined : json['rpId'],
        'challenge': json['challenge'],
        'timeout': !exists(json, 'timeout') ? undefined : json['timeout'],
        'userVerification': !exists(json, 'userVerification') ? undefined : UserVerificationRequirementFromJSON(json['userVerification']),
        'allowCredentials': !exists(json, 'allowCredentials') ? undefined : ((json['allowCredentials'] as Array<any>).map(PublicKeyCredentialDescriptorFromJSON)),
        'extensions': !exists(json, 'extensions') ? undefined : AuthenticationExtensionsClientInputsFromJSON(json['extensions']),
    };
}

export function MFAAuthPasskeyDeviceGetResponseAssertionToJSON(value?: MFAAuthPasskeyDeviceGetResponseAssertion | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rpId': value.rpId,
        'challenge': value.challenge,
        'timeout': value.timeout,
        'userVerification': UserVerificationRequirementToJSON(value.userVerification),
        'allowCredentials': value.allowCredentials === undefined ? undefined : ((value.allowCredentials as Array<any>).map(PublicKeyCredentialDescriptorToJSON)),
        'extensions': AuthenticationExtensionsClientInputsToJSON(value.extensions),
    };
}

