/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthenticatorTransportProtocol {
    Internal = 'AUTHENTICATOR_TRANSPORT_INTERNAL',
    Usb = 'AUTHENTICATOR_TRANSPORT_USB',
    Nfc = 'AUTHENTICATOR_TRANSPORT_NFC',
    Ble = 'AUTHENTICATOR_TRANSPORT_BLE',
    Hybrid = 'AUTHENTICATOR_TRANSPORT_HYBRID'
}

export function AuthenticatorTransportProtocolFromJSON(json: any): AuthenticatorTransportProtocol {
    return AuthenticatorTransportProtocolFromJSONTyped(json, false);
}

export function AuthenticatorTransportProtocolFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatorTransportProtocol {
    return json as AuthenticatorTransportProtocol;
}

export function AuthenticatorTransportProtocolToJSON(value?: AuthenticatorTransportProtocol | null): any {
    return value as any;
}

