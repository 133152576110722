import { ChangeEvent, FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { t as staticTMethod } from 'i18next';
// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Input, SingleToggleCard, Typography } from '@dynamic-labs/northstar';
import { WalletConnectIcon } from '@dynamic-labs/iconic';

import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../../../../app/routes/Configurations/utils';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { AdminIcon } from '../../../../../../../icons';
import { SideDrawer } from '../../../../../../components/SideDrawer';

import styles from './WalletConnect.module.scss';

const requiredText = staticTMethod(
  'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.required',
);

const schema = yup.object({
  dappProjectId: yup.string().required(requiredText),
  walletProjectId: yup.string().required(requiredText),
});

type ProjectIdForm = yup.InferType<typeof schema>;

type WalletConnectProps = {
  disabled: boolean;
};

export const WalletConnect: FC<WalletConnectProps> = ({ disabled }) => {
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.LogInMethodsWalletConnect,
  });

  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const savedDappProjectId =
    settings[activeEnvironmentType].sdk?.walletConnect?.projectId;

  const savedWalletProjectId =
    settings[activeEnvironmentType].sdk?.walletConnect?.walletProjectId;

  const methods = useForm<ProjectIdForm>({
    defaultValues: {
      dappProjectId: savedDappProjectId,
      walletProjectId: savedWalletProjectId,
    },
    mode: 'all',
    resolver: yupResolver(schema),
  });

  const {
    register,
    getValues,
    formState: { errors },
    reset,
  } = methods;

  useEffect(() => {
    reset({
      dappProjectId: savedDappProjectId,
      walletProjectId: savedWalletProjectId,
    });
  }, [reset, savedDappProjectId, savedWalletProjectId]);

  const handleOnBlur = () => {
    if (errors.dappProjectId || errors.walletProjectId) {
      return;
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          walletConnect: {
            ...settings[activeEnvironmentType].sdk.walletConnect,
            projectId: getValues('dappProjectId'),
            walletProjectId: getValues('walletProjectId'),
          },
        },
      },
    });
  };

  const isSettingEnabled =
    Boolean(settings[activeEnvironmentType].sdk?.walletConnect?.v2Enabled) &&
    !disabled;

  const isSettingSaved = Boolean(
    initialSettings[activeEnvironmentType].sdk?.walletConnect?.v2Enabled ===
      isSettingEnabled,
  );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          walletConnect: {
            ...settings[activeEnvironmentType].sdk.walletConnect,
            v2Enabled: e.target.checked,
          },
        },
      },
    });
  };

  return (
    <>
      <SingleToggleCard
        accordionKey='walletConnect'
        title={t('v2.page.log_in_methods.branded_wallets.walletConnect.toggle')}
        description={t(
          'v2.page.log_in_methods.branded_wallets.walletConnect.description',
        )}
        allowExpand={false}
        onCardClick={isSettingEnabled ? () => handleShowModal(true) : undefined}
        inputProps={{
          checked: Boolean(isSettingEnabled),
          disabled,
          id: 'walletConnect',
          isSaved: isSettingSaved,
          onChange: handleToggle,
          type: 'toggle',
        }}
        Icon={<WalletConnectIcon />}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && isSettingEnabled}
        title={t(
          'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.title',
        )}
        Icon={<WalletConnectIcon />}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t(
                'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.projectId',
              )}
            </Typography>

            <SingleToggleCard
              accordionKey='walletConnect-credentials'
              description={t(
                'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.description',
              )}
              descriptionModificators={{
                components: {
                  action: (
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                    <a
                      href='https://cloud.walletconnect.com/'
                      target='_blank'
                      rel='noreferrer'
                      className={styles.link}
                    />
                  ),
                },
                i18nKey:
                  'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.description',
              }}
              defaultExpanded
            >
              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...methods}>
                <form onBlur={handleOnBlur} className={styles.form}>
                  <div>
                    <Input
                      name='walletProjectId'
                      onBlur={register('walletProjectId').onBlur}
                      onChange={register('walletProjectId').onChange}
                      ref={register('walletProjectId').ref}
                      error={!!errors.walletProjectId}
                      label={t(
                        'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.walletProjectIdLabel',
                      )}
                      maskDatadogValue
                    />
                    {errors.walletProjectId?.message && (
                      <Typography
                        variant='paragraph-1'
                        color='inherit'
                        className={styles.error}
                      >
                        {errors.walletProjectId?.message}
                      </Typography>
                    )}
                  </div>
                </form>
              </FormProvider>

              {/* eslint-disable-next-line react/jsx-props-no-spreading */}
              <FormProvider {...methods}>
                <form onBlur={handleOnBlur} className={styles.form}>
                  <div>
                    <Input
                      name='dappProjectId'
                      onBlur={register('dappProjectId').onBlur}
                      onChange={register('dappProjectId').onChange}
                      ref={register('dappProjectId').ref}
                      error={!!errors.dappProjectId}
                      label={t(
                        'v2.page.log_in_methods.branded_wallets.walletConnect.drawer.dappProjectIdLabel',
                      )}
                      maskDatadogValue
                    />
                    {errors.dappProjectId?.message && (
                      <Typography
                        variant='paragraph-1'
                        color='inherit'
                        className={styles.error}
                      >
                        {errors.dappProjectId?.message}
                      </Typography>
                    )}
                  </div>
                </form>
              </FormProvider>
            </SingleToggleCard>
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
