import { AnimatePresence } from 'framer-motion';

import { Icon } from '../../../Icon';
import { Typography } from '../../../Typography';
import { SelectorIcon } from '../../../../icons';
import { DropdownMenuRenderValue } from '../../../DropdownMenu/DropdownMenu.types';
import { Project } from '../../UserProjectsDropdown.types';

import styles from './UserProjectsSelectedValueRenderer.module.scss';

export const UserProjectsSelectedValueRenderer: DropdownMenuRenderValue<
  string,
  Project
> = (option) => {
  const {
    props: { rawOption },
  } = option;

  return (
    <div className={styles.container}>
      <AnimatePresence initial={false}>
        {rawOption && (
          <Typography
            variant='paragraph-2'
            weight='medium'
            className={styles.text}
          >
            {rawOption.name}
          </Typography>
        )}
      </AnimatePresence>
      <div className={styles.trigger}>
        <Icon icon={<SelectorIcon />} size={20} />
      </div>
    </div>
  );
};
