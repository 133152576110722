import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import {
  useModalHashLocation,
  ModalHashLocations,
} from '../../../../../app/routes/Configurations/utils';
import { SideDrawer } from '../../../../components/SideDrawer';
import { AdminIcon } from '../../../../../icons';
import { CORS_URL } from '../../../../../app/utils/constants';

import styles from './CorsRow.module.scss';
import { CorsSidePage } from './CorsSidePage';

export const CorsRow: FC = () => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.Cors,
  });

  const handleToggleChange = async () => {};

  return (
    <>
      <SingleToggleCard
        allowExpand={false}
        accordionKey='cors'
        title={t('v2.page.security.domains_row.cors_row.toggle.title')}
        description={t(
          'v2.page.security.domains_row.cors_row.toggle.description',
        )}
        inputProps={{
          checked: true,
          disabled: true,
          id: 'cors',
          isSaved: true,
          onChange: handleToggleChange,
          type: 'toggle',
        }}
        onCardClick={() => handleShowModal(true)}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
      />
      <SideDrawer
        title={t('v2.page.security.domains_row.cors_row.side_drawer.title')}
        description={t(
          'v2.page.security.domains_row.cors_row.side_drawer.description',
        )}
        isOpen={showModal}
        handleClose={() => handleShowModal(false)}
        className={styles['side-drawer']}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.docs',
          ),
          url: CORS_URL,
        }}
      >
        <CorsSidePage />
      </SideDrawer>
    </>
  );
};
