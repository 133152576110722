/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectProjectEnvironments,
    ProjectProjectEnvironmentsFromJSON,
    ProjectProjectEnvironmentsFromJSONTyped,
    ProjectProjectEnvironmentsToJSON,
} from './ProjectProjectEnvironments';

/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    organizationId: string;
    /**
     * 
     * @type {Array<ProjectProjectEnvironments>}
     * @memberof Project
     */
    projectEnvironments?: Array<ProjectProjectEnvironments>;
}

export function ProjectFromJSON(json: any): Project {
    return ProjectFromJSONTyped(json, false);
}

export function ProjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): Project {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'id': json['id'],
        'organizationId': json['organizationId'],
        'projectEnvironments': !exists(json, 'projectEnvironments') ? undefined : ((json['projectEnvironments'] as Array<any>).map(ProjectProjectEnvironmentsFromJSON)),
    };
}

export function ProjectToJSON(value?: Project | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'id': value.id,
        'organizationId': value.organizationId,
        'projectEnvironments': value.projectEnvironments === undefined ? undefined : ((value.projectEnvironments as Array<any>).map(ProjectProjectEnvironmentsToJSON)),
    };
}

