import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { visitorsColumns } from './visitorColumns';

export const useVisitorsColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(() => visitorsColumns(t), [t]);

  return { columns };
};
