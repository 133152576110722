import { m } from 'framer-motion';

export const WavingHandAnimation = () => (
  <m.div
    style={{
      display: 'inline-block',
      marginBottom: '-20px',
      marginRight: '-45px',
      paddingBottom: '20px',
      paddingRight: '45px',
    }}
    animate={{ rotate: [0, 20, 0, 20, 0] }}
    transition={{
      duration: 1,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatDelay: 0.7,
    }}
    className='w-max text-[22px] leading-[29px];'
  >
    👋
  </m.div>
);
