import { TestContext, ValidationError } from 'yup';

import { Project } from '@dynamic-labs/sdk-api';

export const validateProjectName = (
  value: string | undefined,
  context: TestContext,
  userProjects: Project[],
): ValidationError | boolean => {
  const { path, createError } = context;
  if (!value) return createError({ message: 'required', path });
  if (userProjects.some((project) => project.name === value)) {
    return createError({
      message: 'duplicatedName',
      path,
    });
  }
  return true;
};
