import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';

type RequiredAtOnboardingToggleProps = {
  disabled: boolean;
};

export const RequiredAtOnboardingToggle: FC<
  RequiredAtOnboardingToggleProps
> = ({ disabled }) => {
  const { t } = useTranslation();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const isRequireAtOnboarding = Boolean(
    settings[activeEnvironmentType].security?.mfa?.required,
  );

  const initialIsRequireAtOnboarding = Boolean(
    initialSettings[activeEnvironmentType].security?.mfa?.required,
  );

  const isSettingSaved = Boolean(
    isRequireAtOnboarding === initialIsRequireAtOnboarding,
  );

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        security: {
          ...settings[activeEnvironmentType]?.security,
          mfa: {
            ...settings[activeEnvironmentType]?.security?.mfa,
            required: event.target.checked,
          },
        },
      },
    });

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.security.account_mfa_row.side_drawer.toggle.require_at_onboarding.title',
      )}
      description={t(
        'v2.page.security.account_mfa_row.side_drawer.toggle.require_at_onboarding.description',
      )}
      accordionKey='require_at_onboarding'
      inputProps={{
        checked: isRequireAtOnboarding,
        disabled,
        id: 'require_at_onboarding',
        isSaved: isSettingSaved,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
    />
  );
};
