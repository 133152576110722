import React, { ReactNode } from 'react';

import classNames from 'classnames';

import styles from './input.module.css';

type Props = React.ComponentPropsWithoutRef<'input'> & {
  Icon?: ReactNode;
  className?: string;
  ddHide?: boolean;
  disableBottomMargin?: boolean;
  error?: boolean;
  inputClassName?: string;
  label?: string;
};

const Input = ({
  className = '',
  disabled,
  error = false,
  id,
  label,
  type,
  onChange,
  onBlur,
  name,
  value,
  inputClassName,
  Icon,
  autoFocus,
  disableBottomMargin = false,
  ddHide,
}: Props) => (
  <div
    className={classNames(styles.container, className, {
      [styles['disable-bottom-margin']]: disableBottomMargin,
    })}
  >
    <input
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}
      disabled={disabled}
      name={name}
      aria-label={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      type={type}
      id={id}
      data-testid={id}
      placeholder={label}
      className={`peer peer-focus:pt-6 text-xs placeholder-transparent ${
        styles.input
      } ${error ? styles['input-error'] : ''} ${inputClassName}`}
      data-dd-privacy={ddHide ? 'mask' : undefined}
    />
    {label && (
      <label
        htmlFor={id}
        className={`peer-placeholder-shown:text-xs peer-placeholder-shown:top-[16px] peer-focus:top-2 peer-focus:text-[10px] peer-focus:leading-[12px] ${
          styles.label
        } ${error ? styles['label-error'] : ''}`}
      >
        {label}
      </label>
    )}

    {Icon}
  </div>
);

export default Input;
