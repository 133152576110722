import { useMemo } from 'react';

import { ProjectSettingsChains } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../context/SettingsContext';

export const useEnabledChains = (): ProjectSettingsChains[] => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { initialSettings } = useSettingsContext();

  return useMemo(() => {
    const enabledChainFilter = (chain: ProjectSettingsChains) => chain.enabled;
    const filterOutEthereum = (chain: ProjectSettingsChains) =>
      chain.name !== 'ethereum';

    const chains =
      activeEnvironmentType === 'live'
        ? initialSettings.live.chains
        : initialSettings.sandbox.chains;

    return chains?.filter(filterOutEthereum).filter(enabledChainFilter);
  }, [
    activeEnvironmentType,
    initialSettings.live.chains,
    initialSettings.sandbox.chains,
  ]);
};
