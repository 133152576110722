import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import {
  EnvironmentEnum,
  ProviderEnum,
  UnprocessableEntity,
} from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import { ConfirmToast } from '../../../../components/ConfirmToast';
import { ConfirmModal } from '../../../../components/ConfirmModal';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { ProjectSettingCategory } from '../../../../types';
import { environmentsApi } from '../../../../services/api';
import { useSettingsHasChanged } from '../../../../hooks/useSettingsHasChanged';
import { useProvidersContext } from '../../../../context/ProvidersContext';
import { useGeneralSettings } from '../../../../context/SettingsContext/helpers/generalSettings';
import { useDisableSocialKycIfNoSocialProviders } from '../../../../hooks/useDisableSocialKycIfNoSocialProviders';
import { useSaveProvidersMutation } from '../../../../hooks/useSaveProvidersMutation';
import { ErrorInfo } from '../../../../components/ErrorInfo';

import styles from './SubmitSettingsSection.module.css';

type Props = {
  category: ProjectSettingCategory;
};

const SubmitSettingsSection = ({ category }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const { activeEnvironmentType, activeEnvironment } = useEnvironmentsContext();
  const {
    liveHasChanged,
    sandboxHasChanged,
    settings,
    updateInitialSettings,
    cancelChanges,
  } = useSettingsHasChanged(category);

  const { settingsHasChanged: generalSettingsHasChanged } =
    useGeneralSettings();

  const {
    hasChanges: socialProvidersHasChanged,
    resetProviderState,
    providers,
  } = useProvidersContext();

  const kycSettings = settings[activeEnvironmentType].kyc;

  const disableSocialKycFieldIfNoSocialProviders =
    useDisableSocialKycIfNoSocialProviders({ disabled: false, kycSettings });

  const handleError = async (e: any) => {
    if (e.status === 422) {
      const error: UnprocessableEntity = await e.json();
      setErrorMessage(error.error); // we need to display this errorMessage properly!
    } else {
      logger.error(e);
    }
  };

  const { mutate: mutateSocialProviders } = useSaveProvidersMutation(
    disableSocialKycFieldIfNoSocialProviders,
    handleError,
  );

  const settingsChanged =
    (activeEnvironmentType === 'live' && liveHasChanged) ||
    (activeEnvironmentType === 'sandbox' && sandboxHasChanged) ||
    generalSettingsHasChanged ||
    socialProvidersHasChanged;

  const submitChanges = async () => {
    try {
      const response = await environmentsApi.updateProjectSettings({
        environmentId: activeEnvironment?.id ?? '',
        projectSettings: settings[activeEnvironmentType],
      });

      if (!response?.kyc || !response.chains) return;
      updateInitialSettings(activeEnvironmentType as EnvironmentEnum);
      setErrorMessage(undefined);
    } catch (e: any) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  const supportedTurnkeyWalletChains = ['evm', 'solana'];

  const validateChainSettings = async () => {
    const isTurnkeyEmbeddedWalletSupportedChainEnabled = settings[
      activeEnvironmentType
    ].chains?.some(
      (chain) =>
        chain.enabled &&
        supportedTurnkeyWalletChains.find((c) => c === chain.name),
    );

    const isTurnkeyEnabled = providers?.find(
      (p) => p.provider === ProviderEnum.Turnkey && p.enabledAt,
    );
    if (
      !isTurnkeyEmbeddedWalletSupportedChainEnabled &&
      Boolean(isTurnkeyEnabled)
    ) {
      // warn user about auto-disabling dynamic embedded wallets if we do not have
      // any chains enabled that are supported by that feature
      setShowWarningModal(true);
      return;
    }

    await submitChanges();
  };

  const handleClick = async () => {
    setLoading(true);

    if (category === 'chains') {
      return validateChainSettings();
    }

    if (socialProvidersHasChanged) {
      mutateSocialProviders(providers);
    }

    await submitChanges();
  };

  const handleCancelClick = () => {
    cancelChanges();
    setLoading(false);
    resetProviderState();
  };

  return (
    <AnimatePresence>
      {errorMessage && (
        <ErrorInfo className={styles.error}>{errorMessage}</ErrorInfo>
      )}
      {!showWarningModal && settingsChanged && (
        <ConfirmToast
          confirmationText={t('design_page.confirmButton')}
          cancelText={t('design_page.cancelButton')}
          message={t('design_page.confirmMessage')}
          onConfirm={handleClick}
          loading={loading}
          className={styles.confirmation}
          onCancel={handleCancelClick}
        />
      )}
      {showWarningModal && (
        <ConfirmModal
          cancelText={t('design_page.modal.warnings.cancel')}
          confirmationText={t('design_page.modal.warnings.confirm')}
          message={t('design_page.modal.warnings.network_settings')}
          onConfirm={() => {
            setShowWarningModal(false);
            submitChanges();
          }}
          onCancel={() => {
            setShowWarningModal(false);
            handleCancelClick();
          }}
          className='!z-[32]'
          backdropClassName='!z-[31]'
          title={t('design_page.modal.warnings.title')}
        />
      )}
    </AnimatePresence>
  );
};

export default SubmitSettingsSection;
