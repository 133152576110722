import ReactTable from '../../../components/ReactTable';
import { useMembersContext } from '../../../context/MembersContext';

import styles from './MembersTable.module.css';
import { useMembersColumns } from './components/membersColumns/useMembersColumns';

export const MembersTable = () => {
  const { members } = useMembersContext();
  const { columns } = useMembersColumns();

  return (
    <div className={styles.table__container}>
      <div data-testid='members'>
        <ReactTable columns={columns} data={members} />
      </div>
    </div>
  );
};
