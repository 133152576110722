/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OauthRequest
 */
export interface OauthRequest {
    /**
     * Temporary auth code for oauth2 access
     * @type {string}
     * @memberof OauthRequest
     */
    code?: string;
    /**
     * Temporary auth code verifier for oauth2 access
     * @type {string}
     * @memberof OauthRequest
     */
    codeVerifier?: string;
    /**
     * Temporary auth state for oauth2 access
     * @type {string}
     * @memberof OauthRequest
     */
    state: string;
    /**
     * Optional captcha token to verify that the user is not a bot
     * @type {string}
     * @memberof OauthRequest
     */
    captchaToken?: string;
    /**
     * 
     * @type {string}
     * @memberof OauthRequest
     */
    sessionPublicKey?: string;
}

export function OauthRequestFromJSON(json: any): OauthRequest {
    return OauthRequestFromJSONTyped(json, false);
}

export function OauthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'codeVerifier': !exists(json, 'codeVerifier') ? undefined : json['codeVerifier'],
        'state': json['state'],
        'captchaToken': !exists(json, 'captchaToken') ? undefined : json['captchaToken'],
        'sessionPublicKey': !exists(json, 'sessionPublicKey') ? undefined : json['sessionPublicKey'],
    };
}

export function OauthRequestToJSON(value?: OauthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'codeVerifier': value.codeVerifier,
        'state': value.state,
        'captchaToken': value.captchaToken,
        'sessionPublicKey': value.sessionPublicKey,
    };
}

