import { ChangeEvent } from 'react';

import { TimeUnitEnum } from '@dynamic-labs/sdk-api';
import { Input } from '@dynamic-labs/northstar';

import { ONLY_NUMBERS_REGEXP } from '../../../../utils/constants';
import { Tooltip } from '../../../../components/Tooltip';

import styles from './TimeInput.module.scss';

type Props = {
  amount: number | '';
  className?: string;
  disabled?: boolean;
  disabledTooltipText?: string[];
  onBlur?: VoidFunction;
  setAmount: (value: number | '') => void;
  unit: TimeUnitEnum;
};

export const MAX_MINUTES = 60;

export const MAX_HOURS = 24;
export const MAX_DAYS = 30;

export const MAX_VALUES_PER_UNIT = {
  [TimeUnitEnum.Minutes]: MAX_MINUTES,
  [TimeUnitEnum.Hours]: MAX_HOURS,
  [TimeUnitEnum.Days]: MAX_DAYS,
};

export const TimeInput = ({
  amount,
  setAmount,
  unit,
  onBlur,
  disabled = false,
  disabledTooltipText = [],
  className,
}: Props) => {
  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    const getMaxValue = () => MAX_VALUES_PER_UNIT[unit];

    const maxValue = getMaxValue();
    const limit = 2;

    if (e.target.value.length === 0) {
      setAmount('');
      return;
    }

    if (!ONLY_NUMBERS_REGEXP.test(e.target.value)) {
      setAmount(amount);
      return;
    }

    const parsedValue = parseInt(e.target.value.slice(0, limit), 10);
    if (parsedValue > maxValue) {
      setAmount(maxValue);
      return;
    }

    setAmount(parsedValue);
  };

  return (
    <Tooltip
      tooltipText={disabledTooltipText}
      disableHover={!disabled || disabledTooltipText.length === 0}
    >
      <Input
        type='number'
        name='time-input'
        className={className ?? styles['input--value']}
        value={amount}
        onChange={handleValueChange}
        onBlur={onBlur}
        id='time-input'
        disabled={disabled}
      />
    </Tooltip>
  );
};
