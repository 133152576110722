import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { getValueByKey } from '@dynamic-labs/sdk-react-core';
import { StorageService } from '@dynamic-labs/utils';

import Checkbox from '../../../../components/Checkbox';
import Dropdown from '../../../../components/Dropdown';
import { CogIcon } from '../../../../../icons';

import styles from './columns-dropdown.module.css';
import { columnsNames } from './helpers';

interface Props {
  columnsIds: string[];
  hiddenColumns: string[];
  setHiddenColumns: Dispatch<SetStateAction<string[]>>;
}
export const ColumnsDropdown = ({
  setHiddenColumns,
  hiddenColumns,
  columnsIds,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const handleCheck = (checked: boolean, id: string) => {
    const updatedColumns = hiddenColumns.filter((stateId) => stateId !== id);

    if (!checked) updatedColumns.push(id);
    setHiddenColumns(updatedColumns);
    StorageService.setItem('hidden_users_columns', updatedColumns);
  };

  return (
    <Dropdown>
      <Dropdown.Control>
        <div className={styles.control}>
          <CogIcon className='mr-1.5' />
          <span>{t('users_management_table.columns')}</span>
        </div>
      </Dropdown.Control>
      <Dropdown.List className='!min-w-[182px] !-bottom-3'>
        {columnsIds.map((id) => (
          <label key={id} className={styles.item} htmlFor={id}>
            <Checkbox
              id={id}
              className='mr-2'
              onChange={(e) => handleCheck(e.target.checked, id)}
              defaultChecked={
                !hiddenColumns.find((hiddenId) => hiddenId === id)
              }
            />
            <span className={styles.item__text}>
              {t('users_management_table.show', {
                columnName: getValueByKey(columnsNames, id),
              })}
            </span>
          </label>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};
