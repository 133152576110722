/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ProviderKernelVersionEnum {
    V24 = 'v2_4',
    V30 = 'v3_0',
    V31 = 'v3_1'
}

export function ProviderKernelVersionEnumFromJSON(json: any): ProviderKernelVersionEnum {
    return ProviderKernelVersionEnumFromJSONTyped(json, false);
}

export function ProviderKernelVersionEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderKernelVersionEnum {
    return json as ProviderKernelVersionEnum;
}

export function ProviderKernelVersionEnumToJSON(value?: ProviderKernelVersionEnum | null): any {
    return value as any;
}

