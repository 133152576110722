/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChainConfiguration
 */
export interface ChainConfiguration {
    /**
     * 
     * @type {string}
     * @memberof ChainConfiguration
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof ChainConfiguration
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ChainConfiguration
     */
    primary: boolean;
}

export function ChainConfigurationFromJSON(json: any): ChainConfiguration {
    return ChainConfigurationFromJSONTyped(json, false);
}

export function ChainConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChainConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'enabled': json['enabled'],
        'primary': json['primary'],
    };
}

export function ChainConfigurationToJSON(value?: ChainConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'enabled': value.enabled,
        'primary': value.primary,
    };
}

