import { useEffect, useState } from 'react';

import classNames from 'classnames';

import { CheckIcon } from '../../../icons';
import { Typography } from '../Typography';

import { ProgressBarProps } from './ProgressBar.types';
import styles from './ProgressBar.module.css';

export const ProgressBar = ({
  currentStep = 0,
  totalSteps,
  variant,
}: ProgressBarProps) => {
  const SPINNER_DIAMETER = 64;
  const SPINNER_STROKE_ARRAY = 360;

  const [percentage, setPercentage] = useState(0);
  const [strokeOffset, setStrokeOffset] = useState(SPINNER_STROKE_ARRAY);

  useEffect(() => {
    const currentPercentage = (currentStep / totalSteps) * 100;
    setPercentage(currentPercentage);
    setStrokeOffset(
      currentPercentage === 100
        ? SPINNER_STROKE_ARRAY
        : SPINNER_STROKE_ARRAY -
            (SPINNER_STROKE_ARRAY / totalSteps / 2) * currentStep,
    );
  }, [currentStep, strokeOffset, totalSteps]);

  const progressBarClassName = classNames(
    styles.progressbar__container,
    styles[`progressbar__container--${variant}`],
  );

  return (
    <div className={progressBarClassName} data-testid='progress-bar'>
      <svg
        fill='none'
        height={SPINNER_DIAMETER}
        width={SPINNER_DIAMETER}
        viewBox={`0 0 ${SPINNER_DIAMETER} ${SPINNER_DIAMETER}`}
        version='1.1'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          className={styles['progressbar__bg-progress-layer']}
          cx='50%'
          cy='50%'
          r='45%'
          fill='transparent'
          strokeWidth='4'
        />
        <circle
          className={styles['progressbar__front-progress-layer']}
          cx='50%'
          cy='50%'
          r='45%'
          fill='transparent'
          strokeWidth='4'
          strokeDashoffset={strokeOffset}
          strokeDasharray={SPINNER_STROKE_ARRAY}
        />
      </svg>
      {percentage < 100 ? (
        <Typography
          weight='bold'
          variant='paragraph-2'
          className={styles['progressbar__percentage-label']}
          data-testid='percentage-wrapper'
        >
          {`${percentage.toFixed(0)}%`}
        </Typography>
      ) : (
        <CheckIcon className={styles['progressbar__completed-icon']} />
      )}
    </div>
  );
};
