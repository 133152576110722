import { useMutation, useQuery } from 'react-query';

import {
  WebhookCreateRequest,
  WebhookUpdateRequest,
  WebhooksResponse,
} from '@dynamic-labs/sdk-api';

import { webhooksApi } from '../../services/api';

export const useWebhooks = (environmentId: string) => {
  const queryKey = ['environmentId', environmentId, 'webhooks'];

  const { data, isLoading, refetch } = useQuery<WebhooksResponse>(
    queryKey,
    () => webhooksApi.getWebhooks({ environmentId }),
    {
      enabled: !!environmentId,
    },
  );

  const createWebhookMutation = useMutation(
    (webhook: WebhookCreateRequest) =>
      webhooksApi.createWebhook({
        environmentId,
        webhookCreateRequest: { ...webhook },
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const updateWebhookMutation = useMutation(
    ({
      webhookId,
      webhook,
    }: {
      webhook: WebhookUpdateRequest;
      webhookId: string;
    }) =>
      webhooksApi.updateWebhookById({
        environmentId,
        webhookId,
        webhookUpdateRequest: { ...webhook },
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const deleteWebhookMutation = useMutation(
    (webhookId: string) =>
      webhooksApi.deleteWebhookById({
        environmentId,
        webhookId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    createWebhook: createWebhookMutation.mutateAsync,
    deleteWebhook: deleteWebhookMutation.mutateAsync,
    isLoading,
    refetch,
    updateWebhook: updateWebhookMutation.mutateAsync,
    webhooks: data?.data ?? [],
  };
};
