export const createModalRoot = (id = 'modal_root') => {
  let root = document.getElementById(id);

  if (root) return root;

  root = document.createElement('div');
  root.setAttribute('id', id);
  root.setAttribute('data-testid', id);

  document.body.appendChild(root);
  return root;
};
