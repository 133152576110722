import React from 'react';

import { OpacityAnimation, SlideAnimation } from '../../../../animations';
import { DropdownMenuDropdownWrapper } from '../../../DropdownMenu/DropdownMenu.types';

import styles from './UserOrganizationsDropdownItemsWrapper.module.scss';

export const UserOrganizationsDropdownItemsWrapper: DropdownMenuDropdownWrapper =
  ({ children }) => {
    // Last child is the CTA button
    // It shouldn't be inside list with overflow-y
    const lastChild = React.Children.toArray(children).pop();

    // Other children are the organizations
    // They should be inside list with overflow-y, so they can be scrolled
    const restChildren = React.Children.toArray(children).slice(0, -1);

    return (
      <OpacityAnimation>
        <SlideAnimation startPosition='down' endPosition='down'>
          <div className={styles.container}>
            <div className={styles.list}>
              {restChildren.map((child) => child)}
            </div>
            {lastChild}
          </div>
        </SlideAnimation>
      </OpacityAnimation>
    );
  };
