import { TestContext, ValidationError } from 'yup';
import { FormikErrors } from 'formik';
import { t } from 'i18next';

import { Organization } from '@dynamic-labs/sdk-api';
import { getValueByKey } from '@dynamic-labs/sdk-react-core';

export type InputsErrors = FormikErrors<{
  organizationName: string;
  websiteUrl: string;
}>;
const requiredErrorsMap = {
  description: t('create_org.descriptionLabel'),
  organizationName: t('create_org.orgNameLabel'),
  websiteUrl: t('create_org.websiteUrlLabel'),
};

export const buildErrorsInfo = (errors: InputsErrors) => {
  const requiredErrors = Object.entries(errors)
    .filter((errorData) => errorData[1] === 'required')
    .map(([key]) => getValueByKey(requiredErrorsMap, key));

  const requiredErrorMessage =
    !!requiredErrors?.length &&
    (requiredErrors.length > 1
      ? t('create_org.required_error_plural', {
          error: requiredErrors.join(', '),
        })
      : t('create_org.required_error_singular', {
          error: requiredErrors[0],
        }));

  const requiredError = requiredErrorMessage
    ? {
        message: requiredErrorMessage,
        title: t('create_org.required_error_title'),
      }
    : undefined;

  const invalidOrganizationNameError =
    errors.organizationName === 'invalidOrganizationNameError'
      ? {
          message: t('create_org.invalid_organization_name_error'),
          title: t('create_org.invalid_organization_name_error_title'),
        }
      : undefined;

  let validationError;
  if (errors.organizationName === 'duplicatedName') {
    validationError = {
      message: t('create_org.duplicate_name_error'),
      title: t('create_org.duplicate_name_error_title'),
    };
  } else if (errors.websiteUrl) {
    validationError = {
      message: t('create_org.invalid_url_error'),
      title: t('create_org.invalid_url_error_title'),
    };
  }

  return { invalidOrganizationNameError, requiredError, validationError };
};
export const validateOrgName = (
  value: string | undefined,
  context: TestContext,
  userOrganizations: Organization[],
): ValidationError | boolean => {
  const { path, createError } = context;
  if (!value) return createError({ message: 'required', path });
  if (userOrganizations.some((organization) => organization.name === value)) {
    return createError({
      message: 'duplicatedName',
      path,
    });
  }
  return true;
};
