import { createContext, useContext, useMemo } from 'react';

import { UseMutateFunction } from 'react-query';

import { Provider, ProviderCreateRequest } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { useOnrampProviders } from '../../hooks/useOnrampProviders';

export type OnrampProvidersContextProps = {
  createProvider: UseMutateFunction<
    Provider,
    unknown,
    ProviderCreateRequest,
    unknown
  >;
  providers: Provider[];
  toggleProvider: UseMutateFunction<Provider, unknown, Provider, unknown>;
};

type OnrampProvidersContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const OnrampProvidersContext = createContext<
  OnrampProvidersContextProps | undefined
>(undefined);

export const OnrampProvidersContextProvider = ({
  children,
}: OnrampProvidersContextProviderProps) => {
  const { activeEnvironment } = useEnvironmentsContext();

  const { providers, createProvider, toggleProvider } = useOnrampProviders(
    activeEnvironment?.id || '',
  );

  const value = useMemo(
    () => ({
      createProvider,
      providers,
      toggleProvider,
    }),
    [createProvider, providers, toggleProvider],
  );

  return (
    <OnrampProvidersContext.Provider value={value}>
      {children}
    </OnrampProvidersContext.Provider>
  );
};

export const useOnrampProvidersContext = () => {
  const context = useContext(OnrampProvidersContext);

  if (context === undefined) {
    throw new Error(
      'usage of useOnrampProvidersContext not wrapped in `OnrampProvidersContextProvider`.',
    );
  }

  return context;
};
