import { FC, ChangeEvent } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../../../../app/context/SettingsContext';

type RequireAuthenticatorAtSignUpToggleProps = {
  authenticatorEnabled: boolean;
  disabled: boolean;
};

export const RequireAuthenticatorAtSignUpToggle: FC<
  RequireAuthenticatorAtSignUpToggleProps
> = ({ disabled, authenticatorEnabled }) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, initialSettings, setSettings } = useSettingsContext();

  const forcePasskeyAtSignup = Boolean(
    settings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.forceAuthenticatorAtSignup,
  );
  const initialForcePasskeyAtSignup = Boolean(
    initialSettings[activeEnvironmentType]?.sdk?.embeddedWallets
      ?.forceAuthenticatorAtSignup,
  );

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isSettingSaved = Boolean(
    forcePasskeyAtSignup === initialForcePasskeyAtSignup,
  );

  const handleToggleChange = (event: ChangeEvent<HTMLInputElement>) =>
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType]?.sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType]?.sdk?.embeddedWallets,
            forceAuthenticatorAtSignup: event.target.checked,
          },
        },
      },
    });

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.require_at_sign_up.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.method.toggle.require_at_sign_up.description',
      )}
      accordionKey='require_at_sign_up'
      inputProps={{
        checked:
          isTurnkeyEnabled && authenticatorEnabled && forcePasskeyAtSignup,
        disabled: !isTurnkeyEnabled || !authenticatorEnabled || disabled,
        id: 'require_at_sign_up',
        isSaved: isTurnkeySettingSaved && isSettingSaved,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
    />
  );
};
