import './config';
import { FC, StrictMode, Suspense } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import ReactDOM from 'react-dom/client';
import './i18n';
import { LazyMotion, domAnimation } from 'framer-motion';

import {
  useWalletBookCdn,
  WalletBookContextProvider,
} from '@dynamic-labs/wallet-book';
import { createStorageService, StorageService } from '@dynamic-labs/utils';

import './app/gtm';

import { getLaunchDarklyProvider } from './app/utils/launchDarkly';
import { PopperProvider } from './app/components/Popper';
import { DashboardV2Router } from './appV2';

const queryClient = new QueryClient();

if (!StorageService.getSourceStorage('localStorage')) {
  StorageService.registerSourceStorage(
    'localStorage',
    createStorageService({
      storage: localStorage,
    }),
  );
}

const App: FC = () => {
  const walletBook = useWalletBookCdn();
  return (
    <Suspense fallback='Loading...'>
      <QueryClientProvider client={queryClient}>
        <LazyMotion features={domAnimation} strict>
          <WalletBookContextProvider walletBook={walletBook}>
            <PopperProvider>
              <DashboardV2Router />
            </PopperProvider>
          </WalletBookContextProvider>
        </LazyMotion>
      </QueryClientProvider>
    </Suspense>
  );
};

getLaunchDarklyProvider().then((LDProvider) =>
  ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <StrictMode>
      <LDProvider>
        <App />
      </LDProvider>
    </StrictMode>,
  ),
);
