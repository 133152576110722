import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { Project } from '@dynamic-labs/sdk-api';
import {
  LogoutIcon,
  UserProfile,
  useDynamicContext,
} from '@dynamic-labs/sdk-react-core';

import { Typography } from '../../../../components/Typography';
import CreateOrganizationForm from '../../Forms/CreateOrganizationForm';
import { WavingHandAnimation } from '../../../../animations/WavingHandAnimation';
import { Tooltip } from '../../../../components/Tooltip';

import styles from './create-first-org.module.css';

export interface Props {
  disabled?: boolean;
  onOrganizationCreated?: () => void;
  setUserProjects: Dispatch<SetStateAction<Project[]>>;
  user?: UserProfile;
}

const CreateFirstOrganization = ({
  setUserProjects,
  disabled,
  onOrganizationCreated,
  user,
}: Props) => {
  const { handleLogOut } = useDynamicContext();
  const { t } = useTranslation();

  const handleSubmitAction = async () => {
    onOrganizationCreated?.();
  };

  return (
    <div className={styles.container} data-testid='create-first-organization'>
      <div className='mb-4'>
        <div className='mb-0.5 flex gap-2 items-center'>
          <Typography variant='title' weight='medium'>
            {t('create_org.first_org.preamble')}
          </Typography>
          <WavingHandAnimation />
          <div className='ml-auto'>
            <Tooltip tooltipText={[t('create_org.logout')]}>
              <LogoutIcon
                data-testid='logout'
                className={styles.logout}
                onClick={handleLogOut}
              />
            </Tooltip>
          </div>
        </div>
        <Typography
          variant='paragraph-2'
          weight='regular'
          className='whitespace-nowrap !text-gray-1'
        >
          {t('create_org.first_org.title')}
        </Typography>
      </div>
      <CreateOrganizationForm
        setUserProjects={setUserProjects}
        submitText={t('create_org.continue')}
        disabled={disabled}
        submitAction={handleSubmitAction}
        user={user}
      />
    </div>
  );
};

export default CreateFirstOrganization;
