/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UserFieldsCheckEnum {
    Email = 'email',
    Username = 'username',
    NameServiceSubdomainHandle = 'name-service-subdomain-handle'
}

export function UserFieldsCheckEnumFromJSON(json: any): UserFieldsCheckEnum {
    return UserFieldsCheckEnumFromJSONTyped(json, false);
}

export function UserFieldsCheckEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFieldsCheckEnum {
    return json as UserFieldsCheckEnum;
}

export function UserFieldsCheckEnumToJSON(value?: UserFieldsCheckEnum | null): any {
    return value as any;
}

