import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import {
  TypographyColor,
  Typography,
  TypographyVariant,
  TypographyWeight,
} from '../Typography';

import styles from './TextButton.module.scss';

export type TextButtonProps = {
  as?: 'button' | 'a';
  className?: string;
  color?: TypographyColor;
  disabled?: boolean;
  href?: string;
  leading?: ReactElement;
  onClick?: VoidFunction;
  text: string;
  trailing?: ReactElement;
  variant?: TypographyVariant;
  weight?: TypographyWeight;
};

export const TextButton: FC<TextButtonProps> = ({
  variant = 'button-medium',
  color,
  text,
  onClick,
  disabled,
  className,
  leading,
  trailing,
  as: Tag = 'button',
  href,
  weight,
}) => (
  <Tag
    disabled={disabled}
    className={classNames(
      {
        [styles.disabled]: disabled,
      },
      className,
    )}
    onClick={Tag === 'button' ? onClick : undefined}
    type={Tag === 'button' ? 'button' : undefined}
    rel={Tag === 'a' ? 'noreferrer' : undefined}
    target={Tag === 'a' ? '_blank' : undefined}
    href={Tag === 'a' ? href : undefined}
    data-testid='text-button'
  >
    {leading}
    <Typography variant={variant} color={color} weight={weight}>
      {text}
    </Typography>
    {trailing}
  </Tag>
);
