import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../components/Typography';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import { IntegrationModal } from '../../../../types';
import { ChainControls } from '../ChainControls';
import Portal from '../../../../components/Portal';
import Table from '../../../../components/Table';
import SubmitSettingsSection from '../SubmitSettingsSection';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { useSettingsHasChanged } from '../../../../hooks/useSettingsHasChanged';
import { AvailableWallet } from '../AvailableWallets';

import { createChainRows, walletTableHeaders } from './data';
import styles from './chain-details-modal.module.css';

const ChainDetailsModal = ({
  Icon,
  name,
  content,
  liveSettings,
  setShowModal,
  title,
  wallets,
  networkMessage,
  requireCustomRpcUrl,
}: IntegrationModal) => {
  const { t } = useTranslation();
  const { liveHasChanged, sandboxHasChanged } = useSettingsHasChanged('chains');

  const { enableStarknetNetworks } = useFlags();

  const isStarknet = name === 'starknet';

  const isEvm = name === 'evm';

  const preventModalClose = liveHasChanged || sandboxHasChanged;

  const showChains =
    liveSettings && (isStarknet ? enableStarknetNetworks : true);

  return (
    <Portal
      handleClose={() => (preventModalClose ? null : setShowModal(false))}
      className={styles.portal}
    >
      <EnvironmentTabsLayout className='!left-0 !w-full'>
        <SideModalHeader
          Icon={Icon}
          title={title}
          content={content.full}
          showClose
          onClose={() => (preventModalClose ? null : setShowModal(false))}
        />
        {isEvm && (
          <Typography
            variant='paragraph-2'
            weight='regular'
            className={styles.description}
          >
            <a
              href={t('integrations.chains.networks.custom.link')}
              target='_blank'
              rel='noreferrer'
              className='text-primary-1 underline ml-1'
            >
              {t('integrations.chains.networks.custom.link_text')}
            </a>
            {t('integrations.chains.networks.custom.content')}
          </Typography>
        )}
        {showChains && (
          <Table
            rows={createChainRows({
              liveSettings,
              name,
            }).map((chainName) => (
              <ChainControls
                requireCustomRpcUrl={requireCustomRpcUrl}
                networkMessage={networkMessage}
                name={chainName}
                key={chainName}
              />
            ))}
          />
        )}
        <Table
          headers={walletTableHeaders}
          rows={wallets.map((wallet) => (
            <AvailableWallet walletData={wallet} key={wallet.title} />
          ))}
        />
        <SubmitSettingsSection category='chains' />
      </EnvironmentTabsLayout>
    </Portal>
  );
};

ChainDetailsModal.defaultProps = {
  chain: 'ETH',
  walletDescription: '',
};

export default ChainDetailsModal;
