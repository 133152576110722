import { useTranslation } from 'react-i18next';

import { SingleKey } from '../../../../components/SingleKey/SingleKey';
import { Paper } from '../../../../components/Paper';
import { Typography } from '../../../../components/Typography';

import styles from './JwksCard.module.css';

type Props = {
  environmentId: string;
};

export const JwksCard = ({ environmentId }: Props) => {
  const { t } = useTranslation();
  const originHeader = window.location.origin;
  const jwksUrl = `${originHeader}/api/v0/sdk/${environmentId}/.well-known/jwks`;

  return (
    <Paper>
      <>
        <div className={`${styles.row} mb-3`}>
          <div className={styles.info}>
            <Typography variant='paragraph-1' className={styles.title}>
              {t('apis.JWKS')}
            </Typography>
            <Typography variant='paragraph-1' className={styles.subtitle}>
              {t('apis.JWKS_description')}
            </Typography>
          </div>
          <SingleKey ddHide keyValue={jwksUrl} />
        </div>
        <div />
      </>
    </Paper>
  );
};
