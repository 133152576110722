import { cloneElement } from 'react';

import classNames from 'classnames';

import styles from './Icon.module.css';
import { IconColor, IconComponent, IconSize } from './Icon.types';

export const Icon: IconComponent = ({ icon, className = '', color, size }) => {
  const colorClassNameMap: Record<IconColor, string> = {
    'gray-1': styles['icon-color-gray-1'],
    'gray-2': styles['icon-color-gray-2'],
    'text-primary-1': styles['icon-color-text-primary-1'],
    'text-primary-2': styles['icon-color-text-primary-2'],
    'text-tertiary': styles['icon-color-text-tertiary'],
  };

  const sizeClassNameMap: Record<IconSize, string> = {
    large: styles['icon-size-large'],
    medium: styles['icon-size-medium'],
  };

  const effectiveClassName = classNames(
    styles.icon,
    className,
    color ? colorClassNameMap[color] : '',
    size ? sizeClassNameMap[size] : '',
  );

  return cloneElement(icon, { className: effectiveClassName });
};
