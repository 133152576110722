import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { dynamicSignInSocialProviders } from '../../../../utils/constants';
import { Selector } from '../../../../../icons';
import Dropdown from '../../../../components/Dropdown';

import styles from './ProviderFilterDropdown.module.css';

type Props = {
  providerFilter: ProviderEnum | undefined;
  setProviderFilter: Dispatch<SetStateAction<ProviderEnum | undefined>>;
};

export const ProviderFilterDropdown = ({
  setProviderFilter,
  providerFilter,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const defaultSelection = t('users_management_table.none');
  const filters = Object.values(ProviderEnum).filter((provider) =>
    dynamicSignInSocialProviders.includes(provider),
  );

  const currentValue = !providerFilter
    ? defaultSelection
    : providerFilter.toString();

  return (
    <Dropdown>
      <Dropdown.Control>
        <div className={styles.control}>
          <span className={styles.control__selected}>{currentValue}</span>
          <Selector className={styles.control__arrow} />
        </div>
      </Dropdown.Control>
      <Dropdown.List className='!min-w-[182px] !-bottom-3'>
        <Dropdown.Item
          key={defaultSelection}
          active={!providerFilter}
          onClick={() => setProviderFilter(undefined)}
          className={styles.item}
        >
          <span className='mx-1'>{defaultSelection}</span>
        </Dropdown.Item>
        {filters.map((id) => (
          <Dropdown.Item
            key={id}
            active={providerFilter === id}
            onClick={() => setProviderFilter(id)}
            className={styles.item}
          >
            <span className='mx-1'>{id}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};
