import { t } from 'i18next';
import { Trans } from 'react-i18next';

import { DynamicWidget, ExternalLinkIcon } from '@dynamic-labs/sdk-react-core';

import { Logo, OnboardingIcon, CheckIcon } from '../../../../icons';
import { Typography } from '../../../components/Typography';
import Button from '../../../components/Button';

import styles from './MobileComingSoon.module.css';

export const MobileComingSoon = () => (
  <div className={styles.container}>
    <header className={styles.header}>
      <Logo className={styles.logo} />
      <DynamicWidget />
    </header>
    <main className={styles.main}>
      <div className={styles.checkIconContainer}>
        <CheckIcon width={40} height={40} className={styles.checkIcon} />
      </div>
      <Typography className={styles.heading} as='h1'>
        <Trans i18nKey='mobile_coming_soon.heading' />
      </Typography>
      <Typography variant='paragraph-3' className={styles.paragraph}>
        {t<string>('mobile_coming_soon.subheading')}
      </Typography>
    </main>
    <OnboardingIcon className={styles.onboardingIcon} />
    <footer className={styles.footer}>
      <a
        className={styles['marketing-page-link']}
        href='https://dynamic.xyz'
        target='_blank'
        rel='noreferrer'
      >
        <Button
          className={styles.footerLink}
          variant='link'
          suffix={<ExternalLinkIcon />}
        >
          dynamic.xyz
        </Button>
      </a>
      <span>© {new Date(Date.now()).getFullYear()} Dynamic Labs, Inc.</span>
      <a
        href='https://www.dynamic.xyz/privacy-policy'
        target='_blank'
        rel='noreferrer'
      >
        Privacy Policy
      </a>
      <a
        href='https://www.dynamic.xyz/terms-conditions'
        target='_blank'
        rel='noreferrer'
      >
        Terms of Service
      </a>
    </footer>
  </div>
);
