/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NameServiceData
 */
export interface NameServiceData {
    /**
     * 
     * @type {string}
     * @memberof NameServiceData
     */
    avatar?: string;
    /**
     * 
     * @type {string}
     * @memberof NameServiceData
     */
    name?: string;
}

export function NameServiceDataFromJSON(json: any): NameServiceData {
    return NameServiceDataFromJSONTyped(json, false);
}

export function NameServiceDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): NameServiceData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avatar': !exists(json, 'avatar') ? undefined : json['avatar'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function NameServiceDataToJSON(value?: NameServiceData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avatar': value.avatar,
        'name': value.name,
    };
}

