import { Fragment } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ExternalAuth } from '@dynamic-labs/sdk-api';
import { Input, Tag, Typography } from '@dynamic-labs/northstar';

import { useExternalAuthSettings } from '../../../../../app/context/SettingsContext/helpers/externalAuthSettings';

import styles from './InputFields.module.scss';

export const InputFields = ({
  fieldName,
  isRequired,
  externalAuthEnabled,
}: {
  externalAuthEnabled: boolean;
  fieldName: keyof ExternalAuth;
  isRequired: boolean;
}) => {
  const { t } = useTranslation();
  const { errors, values, touched, handleBlur, setFieldValue } =
    useFormikContext<ExternalAuth>();
  const { setSpecificSettings } = useExternalAuthSettings();

  return (
    <Fragment key={`field_${fieldName}`}>
      <div className={styles.heading_container}>
        <Typography
          className={styles.heading}
          as='h1'
          variant='paragraph-2'
          weight='bold'
          color='black-2'
        >
          {t(`third_party_auth.${fieldName}.Heading`)}
        </Typography>
        {isRequired && (
          <Tag
            color='transparent'
            size='large'
            className={styles.tag}
            text={t('third_party_auth.tag')}
          />
        )}
      </div>
      <Typography
        className={styles.description}
        as='p'
        variant='paragraph-1'
        weight='regular'
        color='black-2'
      >
        {t(`third_party_auth.${fieldName}.Description`)}
      </Typography>

      <Input
        name={fieldName}
        id={fieldName}
        label={t(`third_party_auth.${fieldName}.Placeholder`)}
        className={styles.input}
        onBlur={(e) => {
          handleBlur(e);
          if (!Object.keys(errors).length) {
            setSpecificSettings({ externalAuth: values });
          }
        }}
        onChange={(e) => {
          setFieldValue(fieldName, e.target.value);
        }}
        value={(values[fieldName] as string) ?? ''}
        error={!!errors[fieldName] && !!touched[fieldName]}
        disabled={!externalAuthEnabled}
      />
    </Fragment>
  );
};
