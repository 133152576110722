/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Event,
    EventFromJSON,
    EventToJSON,
    EventType,
    EventTypeFromJSON,
    EventTypeToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
} from '../models';

export interface GetEventsRequest {
    environmentId: string;
    cursor?: string;
    resourceType?: string;
}

/**
 * 
 */
export class EventsApi extends runtime.BaseAPI {

    /**
     * Get event types
     */
    async getEventTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<EventType>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/eventTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventTypeFromJSON));
    }

    /**
     * Get event types
     */
    async getEventTypes(initOverrides?: RequestInit): Promise<Array<EventType>> {
        const response = await this.getEventTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get environment events
     */
    async getEventsRaw(requestParameters: GetEventsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Event>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEvents.');
        }

        const queryParameters: any = {};

        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }

        if (requestParameters.resourceType !== undefined) {
            queryParameters['resourceType'] = requestParameters.resourceType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/events`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EventFromJSON));
    }

    /**
     * Get environment events
     */
    async getEvents(requestParameters: GetEventsRequest, initOverrides?: RequestInit): Promise<Array<Event>> {
        const response = await this.getEventsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
