import { useMutation, useQuery } from 'react-query';

import {
  ChainEnum,
  NameServiceCreateRequest,
  NameServiceResponse,
  NameServiceSigningMessageRequest,
} from '@dynamic-labs/sdk-api';

import { nameServicesApi } from '../../services/api';

export const useNameServices = (
  environmentId: string,
  chainName: ChainEnum,
  enabled = true,
) => {
  const queryKey = ['nameService', environmentId, chainName];

  /**
   * useQuery wrapper for getNameServices
   *
   * On fetch, set data to undefined if server errors (404 page expected).
   */
  const { data, isLoading, refetch } = useQuery<
    NameServiceResponse | undefined
  >(
    queryKey,
    async () => {
      try {
        return await nameServicesApi.getNameService({
          chainName,
          environmentId,
        });
      } catch (e) {
        return undefined;
      }
    },
    {
      enabled: !!environmentId && enabled,
    },
  );

  /**
   * useMutation wrapper for grabbing the SIWE message.
   *
   * This functions as a POST request and needs the context to pass an onSuccess function.
   */
  const fetchNameServiceSigningMessage = useMutation(
    (nameServiceSigningMessageRequest: NameServiceSigningMessageRequest) =>
      nameServicesApi.fetchNameServiceSigningMessage({
        chainName,
        environmentId,
        nameServiceSigningMessageRequest,
      }),
  );

  /** useMutation wrapper for setting up name services for a project */
  const createNameServicesMutation = useMutation(
    (nameServiceCreateRequest: NameServiceCreateRequest) =>
      nameServicesApi.createNameService({
        chainName,
        environmentId,
        nameServiceCreateRequest,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  /** useMutation wrapper for deleting name services from a project */
  const deleteNameServicesMutation = useMutation(
    () =>
      nameServicesApi.deleteNameService({
        chainName,
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  /** useMutation wrapper for enabling name services */
  const enableNameServicesMutation = useMutation(
    () =>
      nameServicesApi.enableNameService({
        chainName,
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  /** useMutation wrapper for disabling name services */
  const disableNameServicesMutation = useMutation(
    () =>
      nameServicesApi.disableNameService({
        chainName,
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    createNameServices: createNameServicesMutation.mutateAsync,
    deleteNameServices: deleteNameServicesMutation.mutateAsync,
    disableNameServices: disableNameServicesMutation.mutateAsync,
    enableNameServices: enableNameServicesMutation.mutateAsync,
    fetchNameServiceSigningMessage: fetchNameServiceSigningMessage.mutateAsync,
    isLoading,
    nameServices: data,
    refetch,
  };
};
