import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetCustomHostnamesHook } from './useGetCustomHostnames.types';

export const useGetCustomHostnames: UseGetCustomHostnamesHook = (
  { environmentId },
  options,
) => {
  const { customHostnamesApi } = useRedcoastQueryContext();

  return useQuery(
    ['customHostnamesApi', 'getCustomHostnames', environmentId],
    async () => customHostnamesApi.getCustomHostnames({ environmentId }),
    options,
  );
};
