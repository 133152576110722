import { NameServiceSigningMessageRequest } from '@dynamic-labs/sdk-api';

/**
 * Builds a signing message request for Namestone
 *
 * @param address - The primary wallet address
 *
 * @returns an unsigned message
 */
export const getNamestoneSigningMessageRequest = (
  address: string,
): NameServiceSigningMessageRequest => ({
  address,
  domain: window.location.hostname,
  uri: 'https://namestone.xyz/api/public_v1/get-siwe-message',
});
