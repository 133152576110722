import { useState } from 'react';

import Moment from 'react-moment';

import { BinIcon } from '../../../../../icons';
import { DeleteOriginModal } from '../DeleteOriginModal';

import styles from './CreatedAtCell.module.css';

type Props = {
  origin: string | undefined;
  originId: string | undefined;
  text: Date | undefined;
};

export const CreatedAtCell = ({
  text,
  originId,
  origin,
}: Props): JSX.Element => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className={styles.wrapper}>
        <Moment format='MM.DD.YYYY'>{text}</Moment>
        <BinIcon onClick={() => setShowModal(true)} />
      </div>
      {showModal && originId && origin && (
        <DeleteOriginModal
          originId={originId}
          origin={origin}
          handleClose={() => setShowModal(false)}
        />
      )}
    </>
  );
};
