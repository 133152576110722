/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    NameServiceData,
    NameServiceDataFromJSON,
    NameServiceDataFromJSONTyped,
    NameServiceDataToJSON,
} from './NameServiceData';

/**
 * 
 * @export
 * @interface JwtPayloadDeprecatedInfo
 */
export interface JwtPayloadDeprecatedInfo {
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    alias?: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof JwtPayloadDeprecatedInfo
     */
    chain?: ChainEnum;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    email?: string;
    /**
     * 
     * @type {NameServiceData}
     * @memberof JwtPayloadDeprecatedInfo
     */
    ens?: NameServiceData;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JwtPayloadDeprecatedInfo
     */
    lists?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    tShirtSize?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    wallet?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtPayloadDeprecatedInfo
     */
    walletPublicKey?: string;
}

export function JwtPayloadDeprecatedInfoFromJSON(json: any): JwtPayloadDeprecatedInfo {
    return JwtPayloadDeprecatedInfoFromJSONTyped(json, false);
}

export function JwtPayloadDeprecatedInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtPayloadDeprecatedInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'chain': !exists(json, 'chain') ? undefined : ChainEnumFromJSON(json['chain']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ens': !exists(json, 'ens') ? undefined : NameServiceDataFromJSON(json['ens']),
        'environmentId': json['environmentId'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'lists': !exists(json, 'lists') ? undefined : json['lists'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'tShirtSize': !exists(json, 'tShirtSize') ? undefined : json['tShirtSize'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'wallet': !exists(json, 'wallet') ? undefined : json['wallet'],
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
    };
}

export function JwtPayloadDeprecatedInfoToJSON(value?: JwtPayloadDeprecatedInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alias': value.alias,
        'chain': ChainEnumToJSON(value.chain),
        'email': value.email,
        'ens': NameServiceDataToJSON(value.ens),
        'environmentId': value.environmentId,
        'firstName': value.firstName,
        'jobTitle': value.jobTitle,
        'lastName': value.lastName,
        'lists': value.lists,
        'phoneNumber': value.phoneNumber,
        'tShirtSize': value.tShirtSize,
        'userId': value.userId,
        'username': value.username,
        'wallet': value.wallet,
        'walletPublicKey': value.walletPublicKey,
    };
}

