/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectSettingsDesignButton,
    ProjectSettingsDesignButtonFromJSON,
    ProjectSettingsDesignButtonFromJSONTyped,
    ProjectSettingsDesignButtonToJSON,
} from './ProjectSettingsDesignButton';
import {
    ProjectSettingsDesignModal,
    ProjectSettingsDesignModalFromJSON,
    ProjectSettingsDesignModalFromJSONTyped,
    ProjectSettingsDesignModalToJSON,
} from './ProjectSettingsDesignModal';
import {
    ProjectSettingsDesignWidget,
    ProjectSettingsDesignWidgetFromJSON,
    ProjectSettingsDesignWidgetFromJSONTyped,
    ProjectSettingsDesignWidgetToJSON,
} from './ProjectSettingsDesignWidget';

/**
 * 
 * @export
 * @interface ProjectSettingsDesign
 */
export interface ProjectSettingsDesign {
    /**
     * 
     * @type {ProjectSettingsDesignModal}
     * @memberof ProjectSettingsDesign
     */
    modal?: ProjectSettingsDesignModal;
    /**
     * 
     * @type {ProjectSettingsDesignButton}
     * @memberof ProjectSettingsDesign
     */
    button?: ProjectSettingsDesignButton;
    /**
     * 
     * @type {ProjectSettingsDesignWidget}
     * @memberof ProjectSettingsDesign
     */
    widget?: ProjectSettingsDesignWidget;
}

export function ProjectSettingsDesignFromJSON(json: any): ProjectSettingsDesign {
    return ProjectSettingsDesignFromJSONTyped(json, false);
}

export function ProjectSettingsDesignFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsDesign {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'modal': !exists(json, 'modal') ? undefined : ProjectSettingsDesignModalFromJSON(json['modal']),
        'button': !exists(json, 'button') ? undefined : ProjectSettingsDesignButtonFromJSON(json['button']),
        'widget': !exists(json, 'widget') ? undefined : ProjectSettingsDesignWidgetFromJSON(json['widget']),
    };
}

export function ProjectSettingsDesignToJSON(value?: ProjectSettingsDesign | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'modal': ProjectSettingsDesignModalToJSON(value.modal),
        'button': ProjectSettingsDesignButtonToJSON(value.button),
        'widget': ProjectSettingsDesignWidgetToJSON(value.widget),
    };
}

