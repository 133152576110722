/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublishEventsEvents,
    PublishEventsEventsFromJSON,
    PublishEventsEventsFromJSONTyped,
    PublishEventsEventsToJSON,
} from './PublishEventsEvents';

/**
 * 
 * @export
 * @interface PublishEvents
 */
export interface PublishEvents {
    /**
     * 
     * @type {Array<PublishEventsEvents>}
     * @memberof PublishEvents
     */
    events: Array<PublishEventsEvents>;
}

export function PublishEventsFromJSON(json: any): PublishEvents {
    return PublishEventsFromJSONTyped(json, false);
}

export function PublishEventsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishEvents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'events': ((json['events'] as Array<any>).map(PublishEventsEventsFromJSON)),
    };
}

export function PublishEventsToJSON(value?: PublishEvents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'events': ((value.events as Array<any>).map(PublishEventsEventsToJSON)),
    };
}

