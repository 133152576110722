import { useCallback } from 'react';

import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import { ProjectSettingCategory } from '../../types';
import { useSettingsContext } from '../../context/SettingsContext';

// This hook is used to determine if any settings have changed in the settings context for a given category.
// It returns:
// - a boolean for each environment,
// - a function to update the initial settings (use it to update the initial settings when you save) IT'S NOT SAVING!
// - a function to cancel changes.
export const useSettingsHasChanged = (category: ProjectSettingCategory) => {
  const { settings, setSettings, initialSettings, setInitialSettings } =
    useSettingsContext();

  const settingsHasChangedForEnv = useCallback(
    (env: EnvironmentEnum) =>
      JSON.stringify(settings[env][category]) !==
      JSON.stringify(initialSettings[env][category]),
    [category, initialSettings, settings],
  );

  const updateInitialSettings = (env: EnvironmentEnum) =>
    setInitialSettings({
      ...initialSettings,
      [env]: {
        ...initialSettings[env],
        ...settings[env],
      },
    });

  const cancelChanges = () => {
    setSettings(initialSettings);
  };

  return {
    cancelChanges,
    liveHasChanged: settingsHasChangedForEnv(EnvironmentEnum.Live),
    sandboxHasChanged: settingsHasChangedForEnv(EnvironmentEnum.Sandbox),
    settings,
    updateInitialSettings,
  };
};
