import { Dispatch, FormEvent, SetStateAction, useState } from 'react';

import { t } from 'i18next';

import { logger } from '../../../../services/logger';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import Button from '../../../../components/Button';
import PopupModal from '../../../../components/PopupModal';
import { PopupHeader } from '../../../../components/PopupModal/PopupHeader/PopupHeader';
import { useWebhooks } from '../../../../hooks/useWebhooks';

import styles from './DeleteWebhookModal.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  webhookId: string;
};

export const DeleteWebhookModal = ({ setShowModal, webhookId }: Props) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { deleteWebhook } = useWebhooks(activeEnvironment?.id || '');

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!activeEnvironment) return;

    try {
      setIsLoading(true);
      await deleteWebhook(webhookId);
    } catch (error) {
      logger.error(error);
    } finally {
      setShowModal(false);
      setIsLoading(true);
    }
  };

  return (
    <PopupModal
      backdropClassName='!z-30'
      handleClose={() => setShowModal(false)}
      className={styles.portal}
    >
      <PopupHeader
        handleClose={() => setShowModal(false)}
        headingText={t<string>('webhooks.delete_webhook_modal.title')}
      />
      <p className={styles.paragraph}>
        {t<string>('webhooks.delete_webhook_modal.content')}
      </p>
      <form className={styles.form} onSubmit={handleSubmit}>
        <Button
          onClick={() => setShowModal(false)}
          variant='secondary'
          className={styles['cancel--button']}
        >
          {t<string>('webhooks.delete_webhook_modal.cancel_button')}
        </Button>
        <Button
          type='submit'
          variant='secondary'
          className={styles['button--delete']}
          loading={isLoading}
        >
          {t<string>('webhooks.delete_webhook_modal.delete_button')}
        </Button>
      </form>
    </PopupModal>
  );
};
