/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProjectEnvironment,
    ProjectEnvironmentFromJSON,
    ProjectEnvironmentFromJSONTyped,
    ProjectEnvironmentToJSON,
} from './ProjectEnvironment';

/**
 * 
 * @export
 * @interface EnvironmentsResponseEnvironments
 */
export interface EnvironmentsResponseEnvironments {
    /**
     * 
     * @type {ProjectEnvironment}
     * @memberof EnvironmentsResponseEnvironments
     */
    live: ProjectEnvironment;
    /**
     * 
     * @type {ProjectEnvironment}
     * @memberof EnvironmentsResponseEnvironments
     */
    sandbox: ProjectEnvironment;
}

export function EnvironmentsResponseEnvironmentsFromJSON(json: any): EnvironmentsResponseEnvironments {
    return EnvironmentsResponseEnvironmentsFromJSONTyped(json, false);
}

export function EnvironmentsResponseEnvironmentsFromJSONTyped(json: any, ignoreDiscriminator: boolean): EnvironmentsResponseEnvironments {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'live': ProjectEnvironmentFromJSON(json['live']),
        'sandbox': ProjectEnvironmentFromJSON(json['sandbox']),
    };
}

export function EnvironmentsResponseEnvironmentsToJSON(value?: EnvironmentsResponseEnvironments | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'live': ProjectEnvironmentToJSON(value.live),
        'sandbox': ProjectEnvironmentToJSON(value.sandbox),
    };
}

