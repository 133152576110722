import { Typography } from '../Typography';
import { LockIcon } from '../../../icons';

import styles from './Chip.module.css';

type ChipColor = 'green' | 'yellow' | 'red' | 'blue' | 'grey' | 'white';

type Props = {
  className?: string;
  color: ChipColor;
  isLocked?: boolean;
  label: string;
  small?: boolean;
};

export const Chip = ({
  color,
  isLocked = false,
  label,
  small = false,
  className = '',
}: Props) => (
  <div
    className={`${styles.chip} ${small ? styles.small : ''} ${
      styles[`chip--${color}`]
    } ${className}`}
    data-testid='chip_container'
  >
    {isLocked && <LockIcon className={styles['chip--icon-lock']} />}
    <Typography
      variant={`${small ? 'paragraph-1' : 'paragraph-2'}`}
      className={`${styles.chip__text} ${styles[`chip__text--${color}`]}`}
    >
      {label}
    </Typography>
  </div>
);
