import {
  FC,
  MutableRefObject,
  PropsWithChildren,
  createContext,
  useContext,
  useRef,
} from 'react';

type PopperContext = {
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export const popperContext = createContext<PopperContext | undefined>(
  undefined,
);

export const PopperProvider: FC<PropsWithChildren> = ({ children }) => {
  const { Provider } = popperContext;

  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <Provider
      value={{
        containerRef,
      }}
    >
      <div
        ref={containerRef}
        style={{ display: 'flex', flex: 1, position: 'relative' }}
      >
        {children}
      </div>
    </Provider>
  );
};

export const usePopper = () => {
  const context = useContext(popperContext);

  if (!context) {
    throw new Error(
      'To use popper, use the PopperProvider above in the dom hierarchy',
    );
  }

  return context;
};
