/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeeplinkUrlResponse
 */
export interface DeeplinkUrlResponse {
    /**
     * 
     * @type {string}
     * @memberof DeeplinkUrlResponse
     */
    id: string;
    /**
     * A valid deeplink url. Accepts custom schemes. Must not include any paths. A single wildcard (*) can be used as the first subdomain.
     * @type {string}
     * @memberof DeeplinkUrlResponse
     */
    url: string;
    /**
     * 
     * @type {Date}
     * @memberof DeeplinkUrlResponse
     */
    createdAt: Date;
}

export function DeeplinkUrlResponseFromJSON(json: any): DeeplinkUrlResponse {
    return DeeplinkUrlResponseFromJSONTyped(json, false);
}

export function DeeplinkUrlResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeeplinkUrlResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'url': json['url'],
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function DeeplinkUrlResponseToJSON(value?: DeeplinkUrlResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'url': value.url,
        'createdAt': (value.createdAt.toISOString()),
    };
}

