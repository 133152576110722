/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InlineObject,
    InlineObjectFromJSON,
    InlineObjectToJSON,
    InlineResponse201,
    InlineResponse201FromJSON,
    InlineResponse201ToJSON,
    InlineResponse400,
    InlineResponse400FromJSON,
    InlineResponse400ToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    OriginsResponse,
    OriginsResponseFromJSON,
    OriginsResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface AddOriginRequest {
    environmentId: string;
    inlineObject: InlineObject;
}

export interface DeleteOriginByIdRequest {
    originId: string;
}

export interface GetOriginsRequest {
    environmentId: string;
}

/**
 * 
 */
export class OriginsApi extends runtime.BaseAPI {

    /**
     * Adds an allowed origin for this project environment
     */
    async addOriginRaw(requestParameters: AddOriginRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InlineResponse201>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling addOrigin.');
        }

        if (requestParameters.inlineObject === null || requestParameters.inlineObject === undefined) {
            throw new runtime.RequiredError('inlineObject','Required parameter requestParameters.inlineObject was null or undefined when calling addOrigin.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/origins`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InlineObjectToJSON(requestParameters.inlineObject),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse201FromJSON(jsonValue));
    }

    /**
     * Adds an allowed origin for this project environment
     */
    async addOrigin(requestParameters: AddOriginRequest, initOverrides?: RequestInit): Promise<InlineResponse201> {
        const response = await this.addOriginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a origin by id
     */
    async deleteOriginByIdRaw(requestParameters: DeleteOriginByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.originId === null || requestParameters.originId === undefined) {
            throw new runtime.RequiredError('originId','Required parameter requestParameters.originId was null or undefined when calling deleteOriginById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/origins/{originId}`.replace(`{${"originId"}}`, encodeURIComponent(String(requestParameters.originId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a origin by id
     */
    async deleteOriginById(requestParameters: DeleteOriginByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteOriginByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get all the allowed origins for a project environment
     */
    async getOriginsRaw(requestParameters: GetOriginsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OriginsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getOrigins.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/origins`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OriginsResponseFromJSON(jsonValue));
    }

    /**
     * Get all the allowed origins for a project environment
     */
    async getOrigins(requestParameters: GetOriginsRequest, initOverrides?: RequestInit): Promise<OriginsResponse> {
        const response = await this.getOriginsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
