import { useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMatch, useNavigate } from 'react-router-dom';

import {
  OpacityAnimation,
  SlideAnimation,
  type Tab,
  Tabs,
} from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { PageHeader } from '../../components';
import { useProvidersContext } from '../../../app/context/ProvidersContext';
import { useSaveBannerContext } from '../../contexts/SaveBannerContext';
import { ROUTES } from '../../../app/components/Navigation/data';
import {
  ExistingEmbeddedWalletsWarningModal,
  ExistingWalletsWarningModal,
} from '../../../app/routes/Configurations/EmbeddedWallets/components';
import { useShouldShowWalletsWarningModal } from '../../../app/routes/Configurations/Providers/hooks';
import { useOnUnMount } from '../../hooks';

import { DynamicSection, ThirdPartySection } from './Sections';
import styles from './EmbeddedWalletsPage.module.scss';
import { useSaveThirdPartySection } from './Sections/ThirdPartySection/useSaveThirdPartySection';
import { useSaveDynamicSectionProvider } from './Sections/DynamicSection/useSaveDynamicSectionProvider';

export const EmbeddedWalletsPage = () => {
  const { enableMagicLinkProviderDashboardIntegration } = useFlags();
  const { t } = useTranslation();

  const tabs: Tab[] = [
    {
      id: 'dynamic',
      label: t('v2.page.embedded_wallets.tabs.dynamic'),
    },
    {
      id: 'third_party',
      label: t('v2.page.embedded_wallets.tabs.third_party'),
    },
  ];

  const {
    handleReset: handleResetThirdPartySection,
    shouldShowSaveBanner: shouldShowThirdPartySaveBanner,
  } = useSaveThirdPartySection();
  const {
    handleReset: handleResetDynamicSection,
    shouldShowSaveBanner: shouldShowDynamicSaveBanner,
  } = useSaveDynamicSectionProvider();

  const { setShouldWaveSaveBanner } = useSaveBannerContext();

  const { hasChanges, providers, savedProviders } = useProvidersContext();

  const {
    walletsAmount,
    showEmbeddedWalletsChangeWarning,
    setShowEmbeddedWalletsChangeWarning,
    currentDisablingProvider,
    currentEnablingProvider,
    showMagicEmbeddedWalletsWarning,
    setShowMagicEmbeddedWalletsWarning,
    refetchWalletsBreakdown,
  } = useShouldShowWalletsWarningModal({ providers, savedProviders });

  const matchDynamic = useMatch(ROUTES.embeddedWalletsDynamic);
  const matchThirdParty = useMatch(ROUTES.embeddedWalletsThirdParty);
  const navigate = useNavigate();

  const magicExists =
    Boolean(
      providers.find(
        (provider) => provider.provider === ProviderEnum.MagicLink,
      ),
    ) || enableMagicLinkProviderDashboardIntegration;

  const bloctoExists = Boolean(
    providers.find((provider) => provider.provider === ProviderEnum.Blocto),
  );

  const onDisabledTabsClick = () => {
    setShouldWaveSaveBanner(true);
  };

  const getInitialActiveTabId = () => {
    if (matchDynamic) {
      return tabs[0].id;
    }
    if (matchThirdParty && (magicExists || bloctoExists)) {
      return tabs[1].id;
    }

    // if not dynamic subpage or third party not allowed to be shown then redirect to dynamic subpage
    navigate(ROUTES.embeddedWalletsDynamic);
    return tabs[0].id;
  };
  const [activeTabId, setActiveTabId] = useState(getInitialActiveTabId());

  const getSection = (tabId: string) => {
    if (tabId === 'dynamic') {
      return (
        <DynamicSection refetchWalletsBreakdown={refetchWalletsBreakdown} />
      );
    }
    return (
      <ThirdPartySection refetchWalletsBreakdown={refetchWalletsBreakdown} />
    );
  };

  const handleTabChange = (tabId: string) => {
    setActiveTabId(tabId);
    navigate(
      tabId === 'dynamic'
        ? ROUTES.embeddedWalletsDynamic
        : ROUTES.embeddedWalletsThirdParty,
    );
  };

  const handleReset = () => {
    if (shouldShowThirdPartySaveBanner) {
      handleResetThirdPartySection();
    }
    if (shouldShowDynamicSaveBanner) {
      handleResetDynamicSection();
    }
  };

  useOnUnMount(() => {
    handleReset();
  });

  return (
    <>
      <section className={styles.container}>
        <PageHeader
          title={t('v2.page.embedded_wallets.title')}
          description={t('v2.page.embedded_wallets.description')}
        />

        {(magicExists || bloctoExists) && (
          <Tabs
            tabs={tabs}
            activeTabId={activeTabId}
            setActiveTabId={handleTabChange}
            disabled={hasChanges}
            onDisabledClick={onDisabledTabsClick}
          />
        )}

        <AnimatePresence initial={false} mode='wait'>
          <SlideAnimation
            endPosition='left'
            startPosition='right'
            animation={{ duration: 0.2 }}
            exit={{ duration: 0.2 }}
            key={activeTabId}
          >
            <OpacityAnimation key={activeTabId}>
              {getSection(activeTabId)}
            </OpacityAnimation>
          </SlideAnimation>
        </AnimatePresence>
      </section>

      {showMagicEmbeddedWalletsWarning && (
        <ExistingWalletsWarningModal
          onClickCancelChanges={handleReset}
          setShowMagicEmbeddedWalletsWarning={
            setShowMagicEmbeddedWalletsWarning
          }
          walletsAmount={walletsAmount}
        />
      )}

      {showEmbeddedWalletsChangeWarning && (
        <ExistingEmbeddedWalletsWarningModal
          onClickCancelChanges={handleReset}
          setShowEmbeddedWalletsChangeWarning={
            setShowEmbeddedWalletsChangeWarning
          }
          walletsAmount={walletsAmount}
          currentDisablingProvider={currentDisablingProvider}
          currentEnablingProvider={currentEnablingProvider}
        />
      )}
    </>
  );
};
