import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../../components/Dropdown';
import { MoreIcon } from '../../../../../../icons';
import { Typography } from '../../../../../components/Typography';
import { RemoveUserModal } from '../RemoveUserModal';
import { Member } from '../../../../../context/MembersContext';
import InvitesModal from '../../../InvitesModal';

import styles from './UserDropdown.module.css';

interface UserDropdownProps {
  member: Member;
}

export const UserDropdown = ({ member }: UserDropdownProps) => {
  const { t } = useTranslation();
  const [showRemoveUserModal, setShowRemoveUserModal] = useState(false);
  const [showInvitesModal, setShowInvitesModal] = useState(false);

  return (
    <>
      <Dropdown>
        <Dropdown.Control>
          <MoreIcon className={styles.more} />
        </Dropdown.Control>
        <Dropdown.List className='z-20'>
          <Dropdown.Item
            key={`remove_${member.alias}`}
            onClick={() => setShowRemoveUserModal(true)}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className='flex pl-2'
            >
              {t('admin.table.dropdown.remove')}
            </Typography>
          </Dropdown.Item>
          <Dropdown.Item
            key={`change_role_${member.alias}`}
            onClick={() => setShowInvitesModal(true)}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className='flex pl-2'
            >
              {t('admin.table.dropdown.changeRole')}
            </Typography>
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
      {showInvitesModal && (
        <InvitesModal
          setShowModal={setShowInvitesModal}
          isUpdateMode
          memberToUpdate={member}
        />
      )}
      {showRemoveUserModal && (
        <RemoveUserModal
          setShowModal={setShowRemoveUserModal}
          member={member}
        />
      )}
    </>
  );
};
