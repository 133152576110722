import { useMemo } from 'react';

import { t } from 'i18next';
import { differenceInDays } from 'date-fns';

import { useVisitAnalyticsQuery } from '../../hooks';
import { SmallWidgetView } from '../SmallWidgetView';
import { SmallLoadingWidgetView } from '../SmallLoadingWidgetView';

import { AverageDailySessionsWidgetComponent } from './AverageDailySessionsWidget.types';

export const AverageDailySessionsWidget: AverageDailySessionsWidgetComponent =
  ({ endDate, environmentId, startDate }) => {
    const { data: visitAnalyticsData, isLoading } = useVisitAnalyticsQuery({
      endDate,
      environmentId,
      startDate,
    });

    const numberOfDays = useMemo(() => {
      if (!endDate || !startDate) return undefined;

      return differenceInDays(endDate, startDate);
    }, [startDate, endDate]);

    const totalSessions = useMemo(
      () => visitAnalyticsData?.summedSessionsCount,
      [visitAnalyticsData],
    );

    const averageDailySessions = useMemo(() => {
      if (!totalSessions || !numberOfDays) return 0;

      return Math.ceil((totalSessions / numberOfDays) * 100) / 100;
    }, [numberOfDays, totalSessions]);

    if (isLoading) {
      return <SmallLoadingWidgetView />;
    }

    return (
      <SmallWidgetView
        title={t<string>('analytics.widgets.average_daily_session.title')}
        value={visitAnalyticsData ? averageDailySessions : undefined}
      />
    );
  };
