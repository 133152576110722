import { formatISO } from 'date-fns';
import { useQuery } from 'react-query';

import { analyticsApi } from '../../../../services/api';

import {
  DailyUsersQueryHook,
  DailyUsersQueryHookData,
} from './useVisitAnalyticsQuery.types';

export const useVisitAnalyticsQuery: DailyUsersQueryHook = (
  { endDate, environmentId, startDate },
  options,
) =>
  useQuery<DailyUsersQueryHookData>(
    ['environmentId', environmentId, 'daily-visits', startDate, endDate],
    () =>
      analyticsApi.getVisitAnalytics({
        endDate: formatISO(endDate),
        environmentId,
        startDate: formatISO(startDate),
      }),
    options,
  );
