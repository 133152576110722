import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';

type SkipOptionalFieldsToggleProps = {
  disabled: boolean;
};

export const SkipOptionalFieldsToggle: FC<SkipOptionalFieldsToggleProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { settings, setSettings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isSettingEnabled = Boolean(
    settings[activeEnvironmentType].general
      .skipOptionalKYCFieldDuringOnboarding,
  );
  const initialIsSettingEnabled = Boolean(
    initialSettings[activeEnvironmentType].general
      .skipOptionalKYCFieldDuringOnboarding,
  );
  const isSettingSaved = isSettingEnabled === initialIsSettingEnabled;

  const handleToggleSetting = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        general: {
          ...settings[activeEnvironmentType].general,
          skipOptionalKYCFieldDuringOnboarding: e.target.checked,
        },
      },
    });
  };

  return (
    <SingleToggleCard
      accordionKey='skip-optional-fields'
      title={t(
        'v2.page.log_in_methods.design.skipOptionalKYCFieldDuringOnboarding.title',
      )}
      description={t(
        'v2.page.log_in_methods.design.skipOptionalKYCFieldDuringOnboarding.description',
      )}
      allowExpand={false}
      inputProps={{
        checked: isSettingEnabled && !disabled,
        disabled,
        id: 'skip-optional-fields',
        isSaved: isSettingSaved,
        onChange: handleToggleSetting,
        type: 'toggle',
      }}
    />
  );
};
