/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Location in the browser where Dynamic will attempt to store the JWT token.
 * @export
 * @enum {string}
 */
export enum AuthStorageEnum {
    Localstorage = 'localstorage',
    Cookie = 'cookie'
}

export function AuthStorageEnumFromJSON(json: any): AuthStorageEnum {
    return AuthStorageEnumFromJSONTyped(json, false);
}

export function AuthStorageEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthStorageEnum {
    return json as AuthStorageEnum;
}

export function AuthStorageEnumToJSON(value?: AuthStorageEnum | null): any {
    return value as any;
}

