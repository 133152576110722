import { Dispatch, FC, SetStateAction, useRef, useState } from 'react';

import { t } from 'i18next';

import {
  Invite,
  InviteStatusEnum,
  Organization,
  OrganizationMfaSettings,
} from '@dynamic-labs/sdk-api';
import {
  useDynamicModals,
  useMfa,
  useDynamicEvents,
} from '@dynamic-labs/sdk-react-core';

import { logger } from '../../../../services/logger';
import Button from '../../../../components/Button';
import { Typography } from '../../../../components/Typography';
import { invitesApi } from '../../../../services/api';
import { useOrganizationMfaSettings } from '../../../Admin/SecurityContent/useOrganizationMfaSettings';

import styles from './invite-banner.module.css';

interface Props {
  setActiveOrganizationId: (organizationId: string | undefined) => void;
  setHasUserAcceptedInvitation: Dispatch<SetStateAction<boolean>>;
  setUserInvites: Dispatch<SetStateAction<Invite[]>>;
  setUserOrganizations: Dispatch<SetStateAction<Organization[]>>;
}

const InviteBanner: FC<Props & Invite> = ({
  id,
  createdByEmail,
  organizationName,
  organizationId,
  role,
  setActiveOrganizationId,
  setUserOrganizations,
  setUserInvites,
  setHasUserAcceptedInvitation,
}) => {
  const { mfaSettings } = useOrganizationMfaSettings(organizationId);

  const statusRef = useRef<null | InviteStatusEnum>(null);

  const { getUserDevices } = useMfa();

  useDynamicEvents('mfaCompletionFailure', ({ error }) => {
    logger.error('MFA completion failed', error);
  });

  useDynamicEvents('mfaCompletionSuccess', ({ mfaToken }) => {
    if (!statusRef.current) return;

    handleInviteAction(statusRef.current, mfaToken);
  });

  const { setShowOTPVerification, setShowMfaChooseType } = useDynamicModals();

  const [loading, setLoading] = useState<InviteStatusEnum | false>(false);

  const handleInviteActionWithMfa = async (status: InviteStatusEnum) => {
    statusRef.current = status;

    if (
      mfaSettings.some((setting: OrganizationMfaSettings) => setting.enabledAt)
    ) {
      const devices = await getUserDevices();
      if (!devices.length) {
        setShowMfaChooseType(true);
      } else {
        setShowOTPVerification(true);
      }
    } else {
      await handleInviteAction(status);
    }
  };

  const handleInviteAction = async (
    status: InviteStatusEnum,
    authToken?: string,
  ) => {
    try {
      setLoading(status);

      if (!id) return;

      await invitesApi
        .withPreMiddleware(async (context) => {
          if (!context.init.headers) {
            context.init.headers = {};
          }
          if (authToken) {
            Object.assign(context.init.headers, {
              'x-mfa-auth-token': authToken,
            });
          }

          return {
            init: context.init,
            url: context.url,
          };
        })
        .updateInvite({
          inviteId: id,
          inviteUpdateRequest: {
            status,
          },
        });

      setUserInvites((invites: Invite[]) =>
        invites.filter((invite) => invite.id !== id),
      );

      if (status === InviteStatusEnum.Accepted) {
        setActiveOrganizationId(organizationId);
        setUserOrganizations([
          { id: organizationId, name: organizationName, role },
        ]);
        setHasUserAcceptedInvitation(true);
      }
    } catch (err) {
      logger.error(err);
    } finally {
      setLoading(false);
    }
  };

  // need this because some old invited do not have a createdBy
  const title = createdByEmail
    ? t<string>('invites.banner.title_with_email', {
        createdByEmail,
        organizationName,
      })
    : t<string>('invites.banner.title_no_email', {
        organizationName,
      });

  return (
    <div className={styles.container}>
      <Typography
        variant='paragraph-3'
        weight='medium'
        className={styles.heading}
      >
        {title}
      </Typography>

      <Typography
        variant='paragraph-3'
        weight='medium'
        className={styles.subtitle}
      >
        {t<string>('invites.banner.subtitle')}
      </Typography>

      <div className={styles.action__container}>
        <Button
          disabled={!!loading}
          className={styles.button__accept}
          loading={loading === InviteStatusEnum.Accepted}
          onClick={() => handleInviteActionWithMfa(InviteStatusEnum.Accepted)}
        >
          {t<string>('invites.banner.accept')}
        </Button>
        <Button
          disabled={!!loading}
          loading={loading === InviteStatusEnum.Rejected}
          variant='secondary'
          onClick={() => handleInviteAction(InviteStatusEnum.Rejected)}
        >
          {t<string>('invites.banner.ignore')}
        </Button>
      </div>
    </div>
  );
};

export default InviteBanner;
