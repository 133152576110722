import { useMemo } from 'react';

import { useFlags } from 'launchdarkly-react-client-sdk';

import { useSubscriptionContext } from '../context/SubscriptionContext';
import { useEnvironmentsContext } from '../context/EnvironmentsContext';

export const useSubscriptionLock = (scope?: string) => {
  const { isScopeAllowed, advancedScopeArray, checks } =
    useSubscriptionContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { enableBillingInDashboard } = useFlags();

  const {
    isAdvancedTrialWithoutPaymentMethod,
    isAdvancedTrialWithPaymentMethod,
    isEnterprisePlan,
    isFreeWithPaymentMethod,
    isFreeWithoutPaymentMethod,
    isAdvancedWithoutPaymentMethod,
    isStandardWithoutPaymentMethod,
    isStandardPlan,
  } = checks;

  const shouldLock = useMemo<boolean>(
    () => enableBillingInDashboard && scope && !isScopeAllowed(scope),
    [enableBillingInDashboard, isScopeAllowed, scope],
  );

  const shouldLockOnLive = useMemo<boolean>(
    () => activeEnvironmentType === 'live' && shouldLock,
    [activeEnvironmentType, shouldLock],
  );

  const shouldShowTrialChip = useMemo<boolean>(
    () =>
      enableBillingInDashboard &&
      (isAdvancedTrialWithoutPaymentMethod || isAdvancedTrialWithPaymentMethod),
    [
      enableBillingInDashboard,
      isAdvancedTrialWithPaymentMethod,
      isAdvancedTrialWithoutPaymentMethod,
    ],
  );

  const shouldLockWhenFreeScope = useMemo<boolean>(
    () =>
      enableBillingInDashboard &&
      activeEnvironmentType === 'live' &&
      (isFreeWithPaymentMethod ||
        isFreeWithoutPaymentMethod ||
        isAdvancedWithoutPaymentMethod ||
        isStandardWithoutPaymentMethod),
    [
      enableBillingInDashboard,
      isAdvancedWithoutPaymentMethod,
      isStandardWithoutPaymentMethod,
      isFreeWithPaymentMethod,
      isFreeWithoutPaymentMethod,
      activeEnvironmentType,
    ],
  );

  const shouldShowAdvanced = useMemo<boolean>(
    () =>
      enableBillingInDashboard &&
      activeEnvironmentType === 'live' &&
      !isEnterprisePlan &&
      !isStandardPlan &&
      scope &&
      advancedScopeArray.includes(scope),
    [
      activeEnvironmentType,
      advancedScopeArray,
      enableBillingInDashboard,
      scope,
      isEnterprisePlan,
      isStandardPlan,
    ],
  );

  const shouldShowTrialBanner = useMemo<boolean>(
    () =>
      enableBillingInDashboard &&
      (isAdvancedTrialWithoutPaymentMethod ||
        isAdvancedTrialWithPaymentMethod ||
        shouldLockOnLive),
    [
      enableBillingInDashboard,
      isAdvancedTrialWithoutPaymentMethod,
      isAdvancedTrialWithPaymentMethod,
      shouldLockOnLive,
    ],
  );

  return {
    shouldLock,
    shouldLockOnLive,
    shouldLockWhenFreeScope,
    shouldShowAdvanced,
    shouldShowTrialBanner,
    shouldShowTrialChip,
  };
};
