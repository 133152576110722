import { ProjectSettingsChains } from '@dynamic-labs/sdk-api';

import { GateNetwork } from '../../../../types';

export const getAllNetworks = (
  chains: ProjectSettingsChains[],
): GateNetwork[] => {
  const allNetworks: GateNetwork[] = [];

  chains.forEach((chain) => {
    if (chain.networks) {
      chain.networks.forEach((network) => {
        allNetworks.push({
          chain: chain.name,
          name: network.chainName,
          networkId: network.networkId,
        });
      });
    }
  });

  return allNetworks;
};
