import { FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import {
  CopyField,
  Typography,
  SingleToggleCard,
} from '@dynamic-labs/northstar';

import { NAMESTONE_RESOLVER } from '../../util';

import styles from './ResolverInstructions.module.scss';

interface ResolverInstructionsProps {
  domainName: string;
}

/** Component to display the instructions for setting the resolver for a subdomain */
export const ResolverInstructions: FC<ResolverInstructionsProps> = ({
  domainName,
}) => {
  const { t } = useTranslation();

  return (
    <SingleToggleCard
      accordionKey='ens-step-2'
      title={t('v2.page.global_wallets.subdomains.drawer.steps.step_2.title')}
      description={t(
        'v2.page.global_wallets.subdomains.drawer.steps.step_2.description',
      )}
      allowExpand
      defaultExpanded
    >
      <div className={styles.card_content}>
        <Typography as='p' variant='paragraph-2' weight='bold' color='black-2'>
          {t(
            'v2.page.global_wallets.subdomains.drawer.steps.step_2.resolver_step_1.title',
          )}
        </Typography>
        <Typography
          as='p'
          variant='paragraph-1'
          weight='regular'
          color='black-2'
        >
          <Trans i18nKey='v2.page.global_wallets.subdomains.drawer.steps.step_2.resolver_step_1.description'>
            In
            <a
              href={`https://app.ens.domains/${domainName}?tab=more`}
              target='_blank'
              className='font-bold text-primary-1'
              rel='noreferrer'
            >
              ENS settings
            </a>
            , set your resolver to:
          </Trans>
        </Typography>

        <CopyField copyText={NAMESTONE_RESOLVER} className={styles.copy} />

        <Typography as='p' variant='paragraph-2' weight='bold' color='black-2'>
          {t(
            'v2.page.global_wallets.subdomains.drawer.steps.step_2.resolver_step_2.title',
          )}
        </Typography>
        <Typography
          as='p'
          variant='paragraph-1'
          weight='regular'
          color='black-2'
        >
          {t(
            'v2.page.global_wallets.subdomains.drawer.steps.step_2.resolver_step_2.description',
          )}
        </Typography>
      </div>
    </SingleToggleCard>
  );
};
