import { useMutation } from 'react-query';

import { Provider, ProviderCreateRequest } from '@dynamic-labs/sdk-api';

import { settingsApi } from '../../services/api';
import { useEnvironmentProviders } from '../useEnvironmentProviders';

const ONRAMP_PROVIDERS = ['banxa'];

export const useOnrampProviders = (environmentId: string) => {
  const { data, isLoading, refetch } = useEnvironmentProviders(environmentId);

  const createProviderMutation = useMutation(
    (provider: ProviderCreateRequest) =>
      settingsApi.createProvider({
        environmentId,
        providerCreateRequest: { ...provider },
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  const toggleProviderMutation = useMutation(
    (provider: Provider) => {
      if (provider.enabledAt) {
        return settingsApi.disableProvider({
          providerId: provider.id || '',
        });
      }

      return settingsApi.enableProvider({
        providerId: provider.id || '',
      });
    },
    {
      onSuccess: () => refetch(),
    },
  );

  const getOnrampProviders = () => {
    if (!data?.providers) {
      return [];
    }

    return data.providers.filter((provider) =>
      ONRAMP_PROVIDERS.includes(provider.provider),
    );
  };

  return {
    createProvider: createProviderMutation.mutate,
    isLoading,
    providers: getOnrampProviders(),
    refetch,
    toggleProvider: toggleProviderMutation.mutate,
  };
};
