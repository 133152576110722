import React, { FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import {
  JwtVerifiedCredentialFormatEnum,
  ProviderEnum,
  User,
} from '@dynamic-labs/sdk-api';

import { Skeleton } from '../../../../../components/Skeleton/Skeleton';
import { useUserDetails } from '../useUserDetails';
import ReactTable from '../../../../../components/ReactTable';

import { AccountTabUserField } from './AccountTabUserField';
import { getAccountTabColumns } from './getAccountTabColumns';
import { getSocialsTableColumns } from './getSocialsTableColumns';
import { MfaDevicesSection } from './mfa';
import { WalletWithWalletProperties } from './types';

export type AccountTabProps = {
  userId?: string;
};

const accountTabUserFields = [
  {
    key: 'email',
    label: 'Email',
  },
];
const AccountTabUserFieldsSkeleton = () => (
  <>
    <Skeleton />
    <Skeleton />
  </>
);

const TableSkeleton = ({ amount = 6 }: { amount?: number }) => (
  <div className='grid gap-2 gap-y-0 grid-cols-3'>
    {new Array(amount).fill(null).map((_, key) => (
      // eslint-disable-next-line react/no-array-index-key
      <Skeleton key={key} />
    ))}
  </div>
);

export const AccountTab: FC<AccountTabProps> = ({ userId }) => {
  const { t } = useTranslation();

  const accountTabColumns = getAccountTabColumns(t);
  const socialTableColumns = getSocialsTableColumns(t);

  const { data: userDetails, isLoading, refetch } = useUserDetails(userId);
  const walletsCount = userDetails?.wallets?.length;

  // We need a copy of userDetails since we potentially modify the value
  const data = [...(userDetails?.oauthAccounts ?? [])];

  // Farcaster doesn't show up in userDetails.oauthAccounts so we conditionally add it to the data
  const farcaster = userDetails?.verifiedCredentials?.find(
    (vc) =>
      vc.format === JwtVerifiedCredentialFormatEnum.Oauth &&
      vc.oauthProvider === ProviderEnum.Farcaster,
  );
  if (farcaster) {
    data.push({
      accountUsername: farcaster.publicIdentifier,
      id: farcaster.id,
      provider: ProviderEnum.Farcaster,
    });
  }

  const userWallets: WalletWithWalletProperties[] =
    userDetails?.wallets?.map((wallet) => {
      const walletVc = userDetails.verifiedCredentials?.find(
        (vc) => vc.id === wallet.id,
      );
      return {
        ...wallet,
        walletProperties: walletVc?.walletProperties,
      };
    }) || [];

  return (
    <div className='account-tab'>
      <div className='mb-6'>
        {userDetails ? (
          accountTabUserFields.map(({ key, label }) => {
            const value = userDetails[key as keyof User];
            return (
              <AccountTabUserField
                copy={key === 'email'}
                key={key}
                label={label}
                value={value}
              />
            );
          })
        ) : (
          <AccountTabUserFieldsSkeleton />
        )}
      </div>

      <MfaDevicesSection
        devices={userDetails?.mfaDevices ?? []}
        userId={userDetails?.id}
        onUpdate={refetch}
      />

      {userDetails ? (
        <>
          <p className='mb-3 text-xs text-gray-1'>
            <Trans i18nKey='users_details_modal.account.wallets_connected'>
              {{ walletsCount }}
            </Trans>
          </p>
          <ReactTable columns={accountTabColumns} data={userWallets || []} />
        </>
      ) : (
        <>
          <Skeleton />
          <TableSkeleton />
        </>
      )}

      <div className='mt-4 w-[600px]'>
        <p className='mb-3 text-xs text-gray-1'>Social Accounts</p>

        {!isLoading ? (
          <ReactTable columns={socialTableColumns} data={data} />
        ) : (
          <TableSkeleton amount={2} />
        )}
      </div>
    </div>
  );
};
