import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseDisableProviderHook } from './useDisableProvider.types';

export const useDisableProvider: UseDisableProviderHook = (options) => {
  const { settingsApi } = useRedcoastQueryContext();

  return useMutation(
    ({ providerId }) =>
      settingsApi.disableProvider({
        providerId,
      }),
    options,
  );
};
