/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum MfaBackupCodeAcknowledgement {
    Pending = 'pending',
    Complete = 'complete'
}

export function MfaBackupCodeAcknowledgementFromJSON(json: any): MfaBackupCodeAcknowledgement {
    return MfaBackupCodeAcknowledgementFromJSONTyped(json, false);
}

export function MfaBackupCodeAcknowledgementFromJSONTyped(json: any, ignoreDiscriminator: boolean): MfaBackupCodeAcknowledgement {
    return json as MfaBackupCodeAcknowledgement;
}

export function MfaBackupCodeAcknowledgementToJSON(value?: MfaBackupCodeAcknowledgement | null): any {
    return value as any;
}

