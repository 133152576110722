import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetEnvironmentProvidersHook } from './useGetEnvironmentProviders.types';

export const useGetEnvironmentProviders: UseGetEnvironmentProvidersHook = (
  { environmentId },
  options,
) => {
  const { settingsApi } = useRedcoastQueryContext();

  return useQuery(
    ['settingsApi', 'getEnvironmentProviders', environmentId],
    async () => settingsApi.getEnvironmentProviders({ environmentId }),
    options,
  );
};
