import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { logger } from '../../../services/logger';
import { ConfirmToast } from '../../../components/ConfirmToast';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import { useModalDesignSettings } from '../../../context/SettingsContext/helpers/designHelpers';
import { environmentsApi } from '../../../services/api';

import styles from './ModalRoute.module.css';
import { StylingOptions } from './StylingOptions';
import { ViewsPreview } from './ViewsPreview';

export const ModalRoute = () => {
  const { t } = useTranslation();
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const {
    updateInitialSettings,
    globalSettings,
    settingsHasChanged,
    cancelChanges,
  } = useModalDesignSettings();
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      const response = await environmentsApi.updateProjectSettings({
        environmentId: activeEnvironment?.id ?? '',
        projectSettings: globalSettings[activeEnvironmentType],
      });

      if (!response?.design) return;
      updateInitialSettings();
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    cancelChanges();
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.options__container}>
          <StylingOptions />
        </div>

        <div className={styles.views__container}>
          <ViewsPreview />
        </div>
      </div>
      <AnimatePresence>
        {settingsHasChanged && (
          <ConfirmToast
            confirmationText={t('design_page.confirmButton')}
            cancelText={t('design_page.cancelButton')}
            message={t('design_page.confirmMessage')}
            onConfirm={handleClick}
            onCancel={handleCancelClick}
            loading={loading}
          />
        )}
      </AnimatePresence>
    </>
  );
};
