/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    VisitorSearchFilterParams,
    VisitorSearchFilterParamsFromJSON,
    VisitorSearchFilterParamsToJSON,
    VisitorsResponse,
    VisitorsResponseFromJSON,
    VisitorsResponseToJSON,
} from '../models';

export interface GetEnvironmentVisitorsRequest {
    environmentId: string;
    filter?: VisitorSearchFilterParams;
    orderBy?: string;
    offset?: number;
    limit?: number;
}

/**
 * 
 */
export class VisitsApi extends runtime.BaseAPI {

    /**
     * Get all visitors for an environment
     */
    async getEnvironmentVisitorsRaw(requestParameters: GetEnvironmentVisitorsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<VisitorsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getEnvironmentVisitors.');
        }

        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.orderBy !== undefined) {
            queryParameters['orderBy'] = requestParameters.orderBy;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/visitors`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => VisitorsResponseFromJSON(jsonValue));
    }

    /**
     * Get all visitors for an environment
     */
    async getEnvironmentVisitors(requestParameters: GetEnvironmentVisitorsRequest, initOverrides?: RequestInit): Promise<VisitorsResponse> {
        const response = await this.getEnvironmentVisitorsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
