import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProjectSettingsKyc } from '@dynamic-labs/sdk-api';
import { SingleToggleCard, Typography } from '@dynamic-labs/northstar';

import { AdminIcon } from '../../../../../../../icons';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { useModalHashLocation } from '../../../../../../../app/routes/Configurations/utils';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { getToggleBanners } from '../../shared/getToggleBanners';
import { KycPropToggle } from '../../shared/KycPropToggle';

import styles from './KycFieldToggle.module.scss';

type KycFieldToggleProps = {
  disabled: boolean;
  field: ProjectSettingsKyc;
};

export const KycFieldToggle: FC<KycFieldToggleProps> = ({
  field,
  disabled,
}) => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: `user-information-${field.name.toLowerCase()}`,
  });
  const { setSettings, settings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const currentInitialKycSetting = initialSettings[
    activeEnvironmentType
  ].kyc.find((kycField) => kycField.name === field.name);
  const otherKycSettings = settings[activeEnvironmentType].kyc.filter(
    (kycField) => kycField.name !== field.name,
  );

  const isSaved = field.enabled === currentInitialKycSetting?.enabled;

  const handleEnableKycField = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        kyc: [
          ...otherKycSettings,
          {
            ...field,
            enabled: e.target.checked,
          },
        ],
      },
    });
  };

  const fieldEnabled = Boolean(field.enabled) && !disabled;

  return (
    <>
      <SingleToggleCard
        accordionKey={`${field.name}-toggle`}
        title={t(
          `v2.page.log_in_methods.kyc.displayNames.${field.name}` as any,
        )}
        allowExpand={false}
        onCardClick={fieldEnabled ? () => handleShowModal(true) : undefined}
        inputProps={{
          checked: fieldEnabled,
          disabled,
          id: `${field.name}-toggle`,
          isSaved,
          onChange: handleEnableKycField,
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        Tags={getToggleBanners({
          enabled: fieldEnabled,
          required: Boolean(field.required),
        })}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && fieldEnabled}
        title={t(
          `v2.page.log_in_methods.kyc.displayNames.${field.name}` as any,
        )}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <Typography variant='paragraph-3' weight='medium' color='gray-1'>
              {t('v2.page.log_in_methods.kyc.drawer.settings')}
            </Typography>

            <KycPropToggle field='required' name={field.name} />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
