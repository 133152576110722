/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AllowlistEntry,
    AllowlistEntryFromJSON,
    AllowlistEntryFromJSONTyped,
    AllowlistEntryToJSON,
} from './AllowlistEntry';

/**
 * 
 * @export
 * @interface AllowlistEntriesResponse
 */
export interface AllowlistEntriesResponse {
    /**
     * 
     * @type {number}
     * @memberof AllowlistEntriesResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<AllowlistEntry>}
     * @memberof AllowlistEntriesResponse
     */
    entries?: Array<AllowlistEntry>;
}

export function AllowlistEntriesResponseFromJSON(json: any): AllowlistEntriesResponse {
    return AllowlistEntriesResponseFromJSONTyped(json, false);
}

export function AllowlistEntriesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AllowlistEntriesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(AllowlistEntryFromJSON)),
    };
}

export function AllowlistEntriesResponseToJSON(value?: AllowlistEntriesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'entries': value.entries === undefined ? undefined : ((value.entries as Array<any>).map(AllowlistEntryToJSON)),
    };
}

