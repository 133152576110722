/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BulkUserCreateResponseFailed,
    BulkUserCreateResponseFailedFromJSON,
    BulkUserCreateResponseFailedFromJSONTyped,
    BulkUserCreateResponseFailedToJSON,
} from './BulkUserCreateResponseFailed';
import {
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface BulkUserCreateResponse
 */
export interface BulkUserCreateResponse {
    /**
     * 
     * @type {Array<User>}
     * @memberof BulkUserCreateResponse
     */
    created?: Array<User>;
    /**
     * 
     * @type {Array<User>}
     * @memberof BulkUserCreateResponse
     */
    updated?: Array<User>;
    /**
     * 
     * @type {Array<BulkUserCreateResponseFailed>}
     * @memberof BulkUserCreateResponse
     */
    failed?: Array<BulkUserCreateResponseFailed>;
    /**
     * 
     * @type {number}
     * @memberof BulkUserCreateResponse
     */
    total?: number;
}

export function BulkUserCreateResponseFromJSON(json: any): BulkUserCreateResponse {
    return BulkUserCreateResponseFromJSONTyped(json, false);
}

export function BulkUserCreateResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUserCreateResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'created': !exists(json, 'created') ? undefined : ((json['created'] as Array<any>).map(UserFromJSON)),
        'updated': !exists(json, 'updated') ? undefined : ((json['updated'] as Array<any>).map(UserFromJSON)),
        'failed': !exists(json, 'failed') ? undefined : ((json['failed'] as Array<any>).map(BulkUserCreateResponseFailedFromJSON)),
        'total': !exists(json, 'total') ? undefined : json['total'],
    };
}

export function BulkUserCreateResponseToJSON(value?: BulkUserCreateResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'created': value.created === undefined ? undefined : ((value.created as Array<any>).map(UserToJSON)),
        'updated': value.updated === undefined ? undefined : ((value.updated as Array<any>).map(UserToJSON)),
        'failed': value.failed === undefined ? undefined : ((value.failed as Array<any>).map(BulkUserCreateResponseFailedToJSON)),
        'total': value.total,
    };
}

