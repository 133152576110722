import { GateRuleType } from '@dynamic-labs/sdk-api';

import { Typography } from '../../../../../../../../../components/Typography';
import { SelectRenderValue } from '../../../../../../../../../components/Select';
import {
  GateRuleNftIcon,
  GateRuleTokenIcon,
} from '../../../../../../../../../../icons';

export const getGateRuleTypeIcon = (type: GateRuleType) => {
  if (type === GateRuleType.Nft) {
    return GateRuleNftIcon;
  }

  return GateRuleTokenIcon;
};

export const renderRuleTypeSelectValue: SelectRenderValue<GateRuleType> = (
  option,
) => {
  const GateRuleTypeIcon = getGateRuleTypeIcon(option.props.value);

  return (
    <div className='flex items-center gap-2'>
      <GateRuleTypeIcon />

      <Typography variant='paragraph-1' weight='medium' as='span'>
        {option.props.children}
      </Typography>
    </div>
  );
};
