import { FC, PropsWithChildren, useContext } from 'react';

import { DropdownContext } from '../dropdown-context';

export type Props = React.ComponentPropsWithoutRef<'button'> & {
  className?: string;
  disabled?: boolean;
  testId?: string;
};
export const DropdownControl: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  disabled,
  testId,
}: Props) => {
  const { isOpen, setIsOpen } = useContext(DropdownContext);

  return (
    <button
      type='button'
      onClick={() => setIsOpen(!isOpen)}
      className={className}
      data-state={isOpen ? 'open' : 'closed'}
      disabled={disabled}
      data-testid={testId}
    >
      {children}
    </button>
  );
};
