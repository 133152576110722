import {
  createContext,
  useEffect,
  useContext,
  useMemo,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react';

import { OriginResponse } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { originsApi } from '../../services/api';
import { logger } from '../../services/logger';

export type OriginsContextValue = {
  environmentId: string | undefined;
  originData: OriginResponse[] | undefined;
  setOrigins: Dispatch<SetStateAction<OriginResponse[] | undefined>>;
};

export const OriginsContext = createContext<OriginsContextValue | undefined>(
  undefined,
);

type Props = {
  children: ReactNode;
};

export const OriginsContextProvider = ({ children }: Props): JSX.Element => {
  const [origins, setOrigins] = useState<OriginResponse[] | undefined>();

  const { activeEnvironment } = useEnvironmentsContext();

  useEffect(() => {
    const fetchOrigins = async () => {
      try {
        if (activeEnvironment?.id) {
          const response = await originsApi.getOrigins({
            environmentId: activeEnvironment.id,
          });
          setOrigins(response.origins);
        }
      } catch (e) {
        logger.error(e);
      }
    };
    fetchOrigins();
  }, [activeEnvironment?.id]);

  const value = useMemo(
    () => ({
      environmentId: activeEnvironment?.id,
      originData: origins,
      setOrigins,
    }),
    [origins, activeEnvironment?.id],
  );

  return (
    <OriginsContext.Provider value={value}>{children}</OriginsContext.Provider>
  );
};

export const useOriginsContext = () => {
  const context = useContext(OriginsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useOriginsContext not wrapped in `OriginsContextProvider`.',
    );
  }

  return context;
};
