import React, { ReactNode, useRef, useEffect, useCallback } from 'react';

import { createPortal } from 'react-dom';
import { m, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { createModalRoot } from '../../utils/createModalRoot';

import styles from './popupmodal.module.css';

interface Props {
  backdropClassName?: string;
  children: ReactNode;
  className?: string;
  handleClose?: () => void;
}

type PopupModalProps = Props & { rootId?: string };

const ModalComponent: React.FC<Omit<Props, 'className'>> = ({
  children,
  handleClose,
  backdropClassName,
}) => {
  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape' && handleClose) {
        handleClose();
      }
    },
    [handleClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  const backdropClassNames = classNames(
    styles.backdrop,
    { [styles.backdrop__clickable]: !!handleClose },
    backdropClassName,
  );

  const animationProps = {
    animate: { opacity: 1 },
    exit: { opacity: 0 },
    initial: { opacity: 0 },
    transition: { duration: 0.3 },
  };

  return (
    <>
      <m.div
        animate={animationProps.animate}
        exit={animationProps.exit}
        initial={animationProps.initial}
        transition={animationProps.transition}
        role='button'
        onClick={handleClose}
        className={backdropClassNames}
        tabIndex={0}
        aria-label='Close modal'
      />
      <m.div
        animate={{ ...animationProps.animate, scale: 1 }}
        exit={{ ...animationProps.exit, scale: 0.95 }}
        initial={{ ...animationProps.initial, scale: 0.95 }}
        transition={animationProps.transition}
        className={styles.container}
      >
        {children}
      </m.div>
    </>
  );
};

const PopupModal: React.FC<PopupModalProps> = ({
  children,
  handleClose,
  rootId,
  backdropClassName,
}) => {
  const modalRoot = useRef(createModalRoot(rootId));

  return createPortal(
    <AnimatePresence>
      <ModalComponent
        handleClose={handleClose}
        backdropClassName={backdropClassName}
      >
        {children}
      </ModalComponent>
    </AnimatePresence>,
    modalRoot.current,
  );
};

export default PopupModal;
