import { FC, useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  Button,
  CopyIcon,
  EditableTable,
  Typography,
} from '@dynamic-labs/northstar';
import {
  useCreateCustomHostname,
  useDeleteCustomHostnames,
  useGetCustomHostnames,
} from '@dynamic-labs/redcoast-query';
import {
  CustomHostname,
  CustomHostnameVerificationRecord,
  UnprocessableEntityErrorCode,
} from '@dynamic-labs/sdk-api';

import { useEnvironmentId } from '../../../../../../app/routes/Configurations/Providers/hooks';

import styles from './CookieSidePage.module.scss';

const domainRegexp = /^(?!-)[A-Za-z0-9-]+([-.][a-z0-9]+)*\.[A-Za-z]{2,15}$/;

interface CookieSidePageProps {
  onVerificationStatusChange: (status: boolean) => void;
}

export const CookieSidePage: FC<CookieSidePageProps> = ({
  onVerificationStatusChange,
}) => {
  const { t } = useTranslation();
  const environmentId = useEnvironmentId();

  const copyDomain = (domain: string) => {
    navigator.clipboard.writeText(domain);
  };

  const [previousHostname, setPreviousHostname] = useState<
    CustomHostname | undefined
  >();
  const [verificationError, setVerificationError] = useState<
    string | undefined
  >(undefined);

  const {
    data: customHostnamesData,
    refetch: refetchHostnames,
    isRefetching: isRefetchingHostnames,
  } = useGetCustomHostnames(
    { environmentId: environmentId || '' },
    {
      enabled: !!environmentId,
      onSuccess: (data: any) => {
        if (data.customHostnames.length === 0) return;

        const [customHostname] = data.customHostnames;

        if (
          previousHostname?.status === 'pending' &&
          customHostname.status === 'pending'
        ) {
          setVerificationError(
            t(
              'v2.page.security.domains_row.cookie_row.side_drawer.verification_error',
            ),
          );
        }

        setPreviousHostname(customHostname);
      },
    },
  );

  const customHostnames: CustomHostname[] = useMemo(
    () => customHostnamesData?.customHostnames || [],
    [customHostnamesData],
  );

  const customHostnameStatus =
    customHostnames.length > 0 ? customHostnames[0]?.status : undefined;

  const verificationSucceeded = customHostnameStatus === 'active';

  useEffect(() => {
    onVerificationStatusChange(verificationSucceeded);
  }, [verificationSucceeded, onVerificationStatusChange]);

  const [inputError, setInputError] = useState<string | undefined>(undefined);

  const {
    mutate: createCustomHostnameMutation,
    isLoading: isLoadingCreateCustomHostname,
  } = useCreateCustomHostname({
    onError: async (error) => {
      try {
        const jsonError = await error.json();
        if ('code' in jsonError && 'error' in jsonError) {
          if (
            jsonError.code ===
            UnprocessableEntityErrorCode.InvalidCustomHostname
          ) {
            setInputError(jsonError.error);
          }
        }
      } catch {
        setInputError(undefined);
      }
    },
    onSuccess: () => {
      refetchHostnames();
    },
  });

  const {
    mutateAsync: deleteCustomHostnames,
    isLoading: isLoadingDeleteCustomHostnames,
  } = useDeleteCustomHostnames({
    onSuccess: () => {
      refetchHostnames();
    },
  });

  const handleAddDomain = (domain: string) => {
    setInputError(undefined);

    const validDomain = domainRegexp.test(domain.trim());

    if (!validDomain) {
      setInputError(
        t('v2.page.security.domains_row.cookie_row.side_drawer.input_error'),
      );
      return;
    }

    createCustomHostnameMutation({
      customHostnameCreateRequest: {
        hostname: domain.trim(),
      },
      environmentId: environmentId || '',
    });
  };

  const handleVerifyDomain = () => {
    setVerificationError(undefined);
    refetchHostnames();
  };

  const handleRemoveDomain = async () => {
    await deleteCustomHostnames({
      environmentId: environmentId || '',
    });
    setInputError(undefined);
    setPreviousHostname(undefined);
    setVerificationError(undefined);
  };

  const tableItems = customHostnames.flatMap((hostname) =>
    hostname.verificationRecords.map(
      (record: CustomHostnameVerificationRecord, index: number) => ({
        Name: (
          <button
            className={styles.domain}
            type='button'
            onClick={() => copyDomain(record.name ?? '')}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {record.name}
            </Typography>
            <CopyIcon className={styles.copy} />
          </button>
        ),
        Type: (
          <Typography variant='paragraph-1' weight='medium' color='gray-1'>
            {record.dnsRecordType}
          </Typography>
        ),
        Value: (
          <button
            className={styles.domain}
            type='button'
            onClick={() => copyDomain(record.value ?? '')}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {record.value}
            </Typography>
            <CopyIcon className={styles.copy} />
          </button>
        ),
        key: `${hostname.hostname}-${index}`,
      }),
    ),
  );

  return (
    <div className={styles.content}>
      {verificationSucceeded && (
        <Alert
          title={t(
            'v2.page.security.domains_row.cookie_row.side_drawer.success',
          )}
          variant='success'
        />
      )}
      {!verificationSucceeded && (
        <Typography variant='paragraph-2' weight='bold'>
          {t('v2.page.security.domains_row.cookie_row.side_drawer.add_domain')}
        </Typography>
      )}

      {inputError && (
        <Typography variant='paragraph-1' className={styles.error}>
          {inputError}
        </Typography>
      )}

      {verificationError && (
        <Typography variant='paragraph-1' className={styles.error}>
          {verificationError}
        </Typography>
      )}

      {customHostnames.length === 0 ? (
        <EditableTable
          columnsAndProportions={[
            { label: 'Type', proportion: 0.15 },
            { label: 'Name', proportion: 0.425 },
            { label: 'Value', proportion: 0.425 },
          ]}
          items={tableItems}
          rowHeight={40}
          emptyLabel={t(
            isRefetchingHostnames
              ? 'v2.page.security.domains_row.cookie_row.side_drawer.empty_loading'
              : 'v2.page.security.domains_row.cookie_row.side_drawer.empty',
          )}
          onAdd={async (domain) => handleAddDomain(domain)}
          onDelete={async () => {}}
          action={{ icon: null, onClick: async () => {} }}
          addButtonLabel={t(
            'v2.page.security.domains_row.cookie_row.side_drawer.button_add',
          )}
          addButtonLoading={
            isLoadingCreateCustomHostname || isRefetchingHostnames
          }
        />
      ) : (
        <>
          {!verificationSucceeded && (
            <EditableTable
              columnsAndProportions={[
                { label: 'Type', proportion: 0.15 },
                { label: 'Name', proportion: 0.425 },
                { label: 'Value', proportion: 0.425 },
              ]}
              items={tableItems}
              rowHeight={40}
              emptyLabel={t(
                isRefetchingHostnames
                  ? 'v2.page.security.domains_row.cookie_row.side_drawer.empty_loading'
                  : 'v2.page.security.domains_row.cookie_row.side_drawer.empty',
              )}
              onDelete={async () => {}}
              action={{ icon: null, onClick: async () => {} }}
            />
          )}
          <div className={styles.buttonWrapper}>
            {!verificationSucceeded && (
              <Button
                text={t(
                  'v2.page.security.domains_row.cookie_row.side_drawer.button_verify',
                )}
                onClick={handleVerifyDomain}
                size='medium'
                isLoading={
                  isLoadingDeleteCustomHostnames || isRefetchingHostnames
                }
              />
            )}
            <Button
              text={t(
                'v2.page.security.domains_row.cookie_row.side_drawer.button_delete',
              )}
              onClick={handleRemoveDomain}
              size='medium'
              isLoading={
                isLoadingDeleteCustomHostnames || isRefetchingHostnames
              }
            />
          </div>
        </>
      )}
    </div>
  );
};
