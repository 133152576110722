import { ReactComponent as Discord } from './discord.svg';
import { ReactComponent as HelpDesk } from './helpdesk.svg';
import { ReactComponent as Slack } from './slack.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Github } from './github.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as Facebook } from './facebook.svg';

export const Apps = {
  Discord,
  Facebook,
  Github,
  Google,
  HelpDesk,
  Slack,
  Twitter,
};
