/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DnsRecordType {
    Txt = 'TXT',
    Cname = 'CNAME'
}

export function DnsRecordTypeFromJSON(json: any): DnsRecordType {
    return DnsRecordTypeFromJSONTyped(json, false);
}

export function DnsRecordTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DnsRecordType {
    return json as DnsRecordType;
}

export function DnsRecordTypeToJSON(value?: DnsRecordType | null): any {
    return value as any;
}

