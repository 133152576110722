/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Webhook,
    WebhookFromJSON,
    WebhookFromJSONTyped,
    WebhookToJSON,
} from './Webhook';

/**
 * 
 * @export
 * @interface WebhooksResponse
 */
export interface WebhooksResponse {
    /**
     * 
     * @type {string}
     * @memberof WebhooksResponse
     */
    cursor?: string;
    /**
     * 
     * @type {Array<Webhook>}
     * @memberof WebhooksResponse
     */
    data?: Array<Webhook>;
}

export function WebhooksResponseFromJSON(json: any): WebhooksResponse {
    return WebhooksResponseFromJSONTyped(json, false);
}

export function WebhooksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhooksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cursor': !exists(json, 'cursor') ? undefined : json['cursor'],
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(WebhookFromJSON)),
    };
}

export function WebhooksResponseToJSON(value?: WebhooksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cursor': value.cursor,
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(WebhookToJSON)),
    };
}

