import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { TimeUnitEnum } from '@dynamic-labs/sdk-api';

import { Toaster } from '../../../../components/Toaster';
import Button from '../../../../components/Button';
import { Typography } from '../../../../components/Typography';
import { TimeTypeDropDown } from '../TimeTypeDropDown';
import { MAX_DAYS, MAX_HOURS, TimeInput } from '../TimeInput';
import { useJwtSettings } from '../../../../context/SettingsContext/helpers/jwtSettings';

import styles from './JWT.module.css';

const DEFAULT_AMOUNT = 2;
const DEFAULT_UNIT = TimeUnitEnum.Hours;
const JWT_TIME_UNITS = [TimeUnitEnum.Hours, TimeUnitEnum.Days];

export const JWT = () => {
  const { t } = useTranslation();

  const {
    settings,
    setJwtSettings,
    isLoading,
    saveJwtSettings,
    settingsHasChanged,
  } = useJwtSettings();

  const [amount, setAmount] = useState<number | ''>(
    settings?.jwtDuration?.amount ?? DEFAULT_AMOUNT,
  );
  const [unit, setUnit] = useState<TimeUnitEnum>(
    settings?.jwtDuration?.unit ?? DEFAULT_UNIT,
  );
  const [showToaster, setShowToaster] = useState(false);

  useEffect(() => {
    setAmount(settings?.jwtDuration?.amount ?? DEFAULT_AMOUNT);
    setUnit(settings?.jwtDuration?.unit ?? DEFAULT_UNIT);
  }, [settings?.jwtDuration?.amount, settings?.jwtDuration?.unit]);

  useEffect(() => {
    setJwtSettings({
      amount: amount === '' ? DEFAULT_AMOUNT : amount,
      unit: unit as TimeUnitEnum,
    });
  }, [amount, unit]);

  const timeText = useMemo(
    () =>
      t('settings_page.security.jwt.time', {
        days: MAX_DAYS,
        hours: MAX_HOURS,
      }),
    [t],
  );

  const defaultValueText = useMemo(
    () =>
      t('settings_page.security.jwt.default_value', {
        type: DEFAULT_UNIT,
        value: DEFAULT_AMOUNT,
      }),
    [t],
  );

  const handleSave = async () => {
    await saveJwtSettings();
    setShowToaster(true);
    setTimeout(() => setShowToaster(false), 3000);
  };

  return (
    <div className={styles.container}>
      <Typography className={styles.heading} as='h2'>
        {t('settings_page.security.jwt.heading')}
      </Typography>
      <div className={styles.text}>
        {t('settings_page.security.jwt.text')}
        <span className={styles.strong}>{timeText}</span>
        {defaultValueText}
      </div>

      <div className={styles['input-wrapper']}>
        <TimeInput amount={amount} setAmount={setAmount} unit={unit} />
        <TimeTypeDropDown
          unit={unit}
          setUnit={setUnit}
          values={JWT_TIME_UNITS}
        />
        <Button
          loading={isLoading}
          onClick={handleSave}
          variant='secondary'
          disabled={!settingsHasChanged}
        >
          {t('settings_page.security.jwt.save')}
        </Button>
      </div>
      <AnimatePresence>
        {showToaster && (
          <Toaster
            message={t('settings_page.security.jwt.toaster')}
            variant='success'
            onExit={() => setShowToaster(false)}
          />
        )}
      </AnimatePresence>
    </div>
  );
};
