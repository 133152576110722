import React, { ReactNode } from 'react';

import { EnvironmentIndicator } from '../../components/EnvironmentIndicator';

import styles from './EnvironmentTabsLayout.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

export const EnvironmentTabsLayout = ({ children, className = '' }: Props) => (
  <>
    <div className={`${styles.container} ${className}`}>
      <EnvironmentIndicator />
    </div>
    {children}
  </>
);
