import { TFunction } from 'react-i18next';

import { InviteStatusEnum } from '@dynamic-labs/sdk-api';

import { TableEmptyData } from '../../../../../components/TableEmptyData';
import { TableSkeleton } from '../../../../../components/TableSkeleton';
import { WalletAddress } from '../../../../../components/WalletAddress';
import { Chip } from '../../../../../components/Chip';
import { InviteDropdown } from '../InviteDropdown';
import { Member } from '../../../../../context/MembersContext';
import { UserDropdown } from '../UserDropdown';

export const membersColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: t('admin.table.member'),
    accessor: ({ alias, firstName, status }: Member) => (
      <>
        <div>{firstName || alias || 'N/A'}</div>
        {status === InviteStatusEnum.Pending && (
          <Chip color='yellow' small label={t('admin.table.invitePending')} />
        )}
      </>
    ),
    className: 'min-w-[270px]',
    id: 'member',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('admin.table.role'),
    accessor: ({ role }: Member) => <div>{role}</div>,
    className: 'min-w-[270px]',
    id: 'role',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('admin.table.wallet'),
    accessor: ({ walletPublicKey, wallet }: Member) =>
      walletPublicKey ? (
        <WalletAddress address={walletPublicKey} walletName={wallet} />
      ) : (
        <TableEmptyData />
      ),
    className: 'min-w-[270px]',
    id: 'wallet',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('admin.table.email'),
    accessor: (row: Member) => (
      <>
        <div>{row.email}</div>
        {row.id &&
          (row.type === 'user' ? (
            <UserDropdown member={row} />
          ) : (
            <InviteDropdown alias={row.alias} inviteId={row.id} />
          ))}
      </>
    ),
    className: 'min-w-[270px] justify-between',
    id: 'email',
    skeleton: <TableSkeleton />,
  },
];
