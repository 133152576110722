import { FormikErrors } from 'formik';
import { t } from 'i18next';

import { getValueByKey } from '@dynamic-labs/sdk-react-core';

export type InputsErrors = FormikErrors<{
  projectName: string;
}>;

const requiredErrorsMap = {
  projectName: t('create_project.projectNameLabel'),
};

export const buildErrorsInfo = (errors: InputsErrors) => {
  const requiredErrors = Object.entries(errors)
    .filter((errorData) => errorData[1] === 'required')
    .map(([key]) => getValueByKey(requiredErrorsMap, key));

  const requiredError =
    !!requiredErrors?.length &&
    (requiredErrors.length > 1
      ? t('create_project.required_error_plural', {
          error: requiredErrors.join(', '),
        })
      : t('create_project.required_error_singular', {
          error: requiredErrors.join(', '),
        }));

  const duplicatedName =
    errors.projectName === 'duplicatedName'
      ? t('create_project.duplicate_name_error')
      : undefined;

  const invalidProjectNameError =
    errors.projectName === 'invalidProjectNameError'
      ? t('create_project.invalid_project_name_error')
      : undefined;

  return { duplicatedName, invalidProjectNameError, requiredError };
};
