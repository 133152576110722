/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum JwtVerifiedCredentialFormatEnum {
    Blockchain = 'blockchain',
    Email = 'email',
    Oauth = 'oauth',
    Passkey = 'passkey',
    PhoneNumber = 'phoneNumber',
    ExternalUser = 'externalUser'
}

export function JwtVerifiedCredentialFormatEnumFromJSON(json: any): JwtVerifiedCredentialFormatEnum {
    return JwtVerifiedCredentialFormatEnumFromJSONTyped(json, false);
}

export function JwtVerifiedCredentialFormatEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtVerifiedCredentialFormatEnum {
    return json as JwtVerifiedCredentialFormatEnum;
}

export function JwtVerifiedCredentialFormatEnumToJSON(value?: JwtVerifiedCredentialFormatEnum | null): any {
    return value as any;
}

