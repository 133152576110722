import { useMemo, useState, useRef } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { boolean, object } from 'yup';

import { Alert, Section } from '@dynamic-labs/northstar';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

import { FormErrors } from '../../../app/routes/Settings/General/FormErrors';
import { logger } from '../../../app/services/logger';
import { useTestAccountContext } from '../../../app/context/TestAccountContext';
import { PageHeader, SaveBanner } from '../../components';

import { TestAccountFormField, TestAccountToggle } from './TestAccountFields';
import styles from './TestAccounts.module.scss';
import { TestAccountFieldValues } from './TestAccountFields/TestAccountFields';

/** Component for Test Accounts Page */
export const TestAccounts = () => {
  const { t } = useTranslation();

  // Grab the user for their email
  const { user } = useDynamicContext();

  // Set up test account services
  const { testAccount, createTestAccount, deleteTestAccount } =
    useTestAccountContext();

  const [loading, setLoading] = useState(false);
  const formikRef = useRef<FormikProps<TestAccountFieldValues>>(null);

  const onSubmit = async (values: TestAccountFieldValues) => {
    try {
      setLoading(true);
      if (values.enabled) {
        await createTestAccount();
      } else {
        await deleteTestAccount();
      }
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    formikRef.current?.resetForm();
  };

  const validationSchema = object().shape({
    enabled: boolean(),
  });

  const initialValues: TestAccountFieldValues = useMemo(
    () => ({
      enabled: testAccount !== undefined,
    }),
    [testAccount],
  );

  /** Use the user's email if possible */
  const email = useMemo(() => {
    if (user && user.email) {
      // Check if the account already has +dynamic_test
      if (user.email.indexOf('+dynamic_test@') > -1) {
        return user.email;
      }

      // Assume the email is well formatted and inject +dynamic_test
      const atIndex = user.email.indexOf('@');
      return `${user.email.slice(0, atIndex)}+dynamic_test${user.email.slice(
        atIndex,
      )}`;
    }
    return 'your_email+dynamic_test@YOUR_DOMAIN';
  }, [user]);

  return (
    <section className={styles.wrapper} data-testid='test-accounts'>
      <div className={styles.container}>
        <PageHeader
          title={t('test_accounts.heading')}
          description={t('test_accounts.description')}
        />
        <Alert
          variant='info'
          title={t('test_accounts.alert.title')}
          description={t('test_accounts.alert.description')}
        />
        <Section
          title={t('test_accounts.section_title')}
          description={t('test_accounts.section_description')}
          help={{
            text: t('test_accounts.section_action'),
            url: 'https://docs.dynamic.xyz/developer-dashboard/test-accounts',
          }}
        >
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            innerRef={formikRef}
          >
            {({ errors, values, handleSubmit }) => (
              <Form>
                <FormErrors />
                <div className={styles.wrapper}>
                  <TestAccountToggle
                    testAccountActive={testAccount !== undefined}
                  />
                </div>
                {testAccount !== undefined && (
                  <div className={styles.wrapper}>
                    <TestAccountFormField
                      title={t('test_accounts.email.title')}
                      description={t('test_accounts.email.description')}
                      readOnlyValue={email}
                    />
                    <TestAccountFormField
                      title={t('test_accounts.phone.title')}
                      description={t('test_accounts.phone.description')}
                      readOnlyValue='+1 555-111-2222'
                    />
                    <TestAccountFormField
                      title={t('test_accounts.otp.title')}
                      description={t('test_accounts.otp.description')}
                      copyValue={testAccount.code}
                    />
                  </div>
                )}
                <AnimatePresence>
                  {Object.keys(errors).length === 0 &&
                    values.enabled !== !!testAccount && (
                      <SaveBanner
                        handleOnSave={handleSubmit}
                        handleOnCancel={handleCancelClick}
                        isLoading={loading}
                      />
                    )}
                </AnimatePresence>
              </Form>
            )}
          </Formik>
        </Section>
      </div>
    </section>
  );
};
