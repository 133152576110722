import { GateNetwork } from '../../../../types';

// Ethereum Mainnet, Optimism Mainnet, Polygon Mainnet, Arbitrum Mainnet, Base Mainnet
// Ethereum Sepolia, Optimism Sepolia, Polygon Mumbai, Arbitrum Sepolia, Base Sepolia
const SUPPORTED_EVM_NETWORKS = [
  1, 10, 137, 42161, 8453, 11155111, 11155420, 421614, 80001, 84532,
];

export const getAvailableNetworks = (
  enabledNetworks: GateNetwork[],
): GateNetwork[] => {
  const availableNetworks = enabledNetworks.filter(
    (enabledNetwork) =>
      enabledNetwork.chain === 'evm' &&
      enabledNetwork.networkId &&
      SUPPORTED_EVM_NETWORKS.includes(enabledNetwork.networkId),
  );

  return availableNetworks;
};
