import { ProviderEnum, SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';
import { capitalize } from '@dynamic-labs/northstar';

export const getProviderDisplayName = (
  provider: ProviderEnum | SocialSignInProviderEnum,
) => {
  switch (provider) {
    case 'twitter':
      return 'X / Twitter';
    case 'coinbasesocial':
      return 'Coinbase';
    case 'epicgames':
      return 'Epic Games';
    default:
      return capitalize(provider);
  }
};
