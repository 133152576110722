import React, { ReactElement, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { formatDateToInternationalFormat } from '../../../../utils/constants/defaults';
import { useOnrampProvidersContext } from '../../../../context/OnrampProvidersContext';
import Button from '../../../../components/Button';
import { Alert } from '../../../../components/Alert';
import { Icon } from '../../../../components/Icon';
import { ExpandFieldAnimation } from '../../../../animations/ExpandFieldAnimation';
import { ControlFieldHeader } from '../../../../components/ControlFieldHeader';
import { CheckCircleIcon } from '../../../../../icons';

import styles from './OnrampProviderView.module.css';

type Props = {
  icon: ReactElement;
  id: ProviderEnum;
  label: string;
  provider?: Provider;
  termsUrl: string;
};

export const OnrampProviderView = ({
  provider,
  id,
  label,
  icon,
  termsUrl,
}: Props) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(true);
  const [loading, setLoading] = useState(false);

  const { createProvider, toggleProvider } = useOnrampProvidersContext();

  const acceptedTerms = async () => {
    setLoading(true);

    await createProvider(
      {
        provider: id,
        termsUrl,
      },
      { onSettled: () => setLoading(false) },
    );
  };

  const onToggleChange = async () => {
    if (!provider) {
      return;
    }

    setLoading(true);

    await toggleProvider(provider, {
      onSettled: () => setLoading(false),
    });
  };

  return (
    <div>
      <ControlFieldHeader
        disabled={!provider?.termsAcceptedByUser}
        isExpanded={isExpanded}
        setIsExpanded={setIsExpanded}
        handleToggleChange={onToggleChange}
        isInitialEnabled={!!provider?.enabledAt}
        header={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <div className={styles.header}>
            <Icon icon={icon} />

            <div className={styles.header__title}>{label}</div>
          </div>
        }
        toggleId={`${id}_toggle`}
        toggleValue={!!provider?.enabledAt}
      />

      <AnimatePresence>
        {isExpanded && (
          <ExpandFieldAnimation>
            <div className={styles.terms}>
              <Alert severity='info' className={styles.terms__alert}>
                <div>
                  <Trans i18nKey='integrations.wallets.onramp.terms.link'>
                    {label}
                    <a
                      target='blank'
                      className='font-bold text-primary-1'
                      href={termsUrl}
                    >
                      terms of services.
                    </a>
                  </Trans>
                </div>
                <div className={styles['terms__alert--bold']}>
                  <Trans i18nKey='integrations.wallets.onramp.terms.prohibited_businness_message'>
                    {label}
                  </Trans>
                </div>
              </Alert>
              {provider?.termsAcceptedByUser ? (
                <Alert
                  severity='info'
                  className={classNames(
                    styles.terms__alert,
                    styles.terms__signed,
                  )}
                >
                  <CheckCircleIcon />
                  <Trans i18nKey='integrations.wallets.onramp.terms.signed_message'>
                    {provider.termsAcceptedByUser.email}
                    {formatDateToInternationalFormat(
                      provider.termsAcceptedByUser.createdAt,
                    )}
                  </Trans>
                </Alert>
              ) : (
                <Button
                  testId='sign_term_button'
                  variant='secondary'
                  type='submit'
                  disabled={loading}
                  onClick={acceptedTerms}
                >
                  {t('integrations.wallets.onramp.terms.sign_button')}
                </Button>
              )}
            </div>
          </ExpandFieldAnimation>
        )}
      </AnimatePresence>
    </div>
  );
};
