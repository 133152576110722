import { ReactElement } from 'react';

import {
  CheckIcon,
  ExclamationIcon,
  FilledLockIcon,
  HoldingIcon,
} from '../../icons';

import { InputType, BooleanInputVariant } from './BooleanInput.types';

export const VariantIconMap: Record<
  BooleanInputVariant,
  ReactElement | undefined
> = {
  active: <CheckIcon />,
  default: undefined,
  error: <ExclamationIcon />,
  holding: <HoldingIcon />,
  'locked-active': <FilledLockIcon />,
  'locked-inactive': <FilledLockIcon />,
  warning: <ExclamationIcon />,
};

type GetVariantProps = {
  checked: boolean;
  disabled?: boolean;
  isError?: boolean;
  isSaved?: boolean;
  isWarning?: boolean;
};

export const getVariant = ({
  isError,
  isWarning,
  isSaved,
  disabled,
  checked,
}: GetVariantProps): BooleanInputVariant => {
  if (isError) {
    return 'error';
  }

  if (isWarning) {
    return 'warning';
  }

  if (disabled) {
    return checked ? 'locked-active' : 'locked-inactive';
  }

  if (isSaved && checked) {
    return 'active';
  }

  if (checked) {
    return 'holding';
  }

  return 'default';
};

export const getType = (type: InputType): string => {
  if (type === 'radio') {
    return 'radio';
  }

  return 'checkbox';
};
