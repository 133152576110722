import { FormError } from '../../../../components/FormError/form-error';
import { ErrorInfo } from '../../../../components/ErrorInfo';

interface Props {
  errors: ErrorsMessages;
  showErrors: boolean;
}

type Error = {
  message: string;
  title: string;
};

export type ErrorsMessages = {
  invalidOrganizationNameError?: Error;
  requiredError?: Error;
  validationError?: Error;
};

export const FormErrors = ({
  errors,
  showErrors,
}: Props): JSX.Element | null => {
  if (!showErrors) return null;

  const { requiredError, validationError, invalidOrganizationNameError } =
    errors;

  return (
    <ErrorInfo className='mb-5'>
      {requiredError && (
        <FormError
          title={requiredError.title}
          message={requiredError.message}
        />
      )}
      {validationError && (
        <FormError
          title={validationError.title}
          message={validationError.message}
        />
      )}
      {invalidOrganizationNameError && (
        <FormError
          title={invalidOrganizationNameError.title}
          message={invalidOrganizationNameError.message}
        />
      )}
    </ErrorInfo>
  );
};
