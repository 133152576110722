import { ChangeEvent, FC, useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { CustomField, CustomFieldRequest } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useCustomFieldsContext } from '../../../../../../../app/context/CustomFieldsContext';
import { AdminIcon } from '../../../../../../../icons';
import { SideDrawer } from '../../../../../../components/SideDrawer';
import { useModalHashLocation } from '../../../../../../../app/routes/Configurations/utils';
import { getToggleBanners } from '../../shared/getToggleBanners';
import { NewFieldSideDrawer } from '../CreateNewFields/NewFieldSideDrawer';

import styles from './CustomFieldsToggle.module.scss';

type CustomFieldsToggleProps = {
  disabled: boolean;
  field: CustomField;
};

export const CustomFieldsToggle: FC<CustomFieldsToggleProps> = ({
  field,
  disabled,
}) => {
  const { t } = useTranslation();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: `custom-field-${field.name
      .toLowerCase()
      .replace(/\s+/g, '-')}`,
  });
  const {
    updateCustomField,
    deleteCustomField,
    getFieldById,
    updateLocalField,
  } = useCustomFieldsContext();

  const fieldById = getFieldById(field.id);

  const handleEnableCustomField = (e: ChangeEvent<HTMLInputElement>) => {
    const updatedField = { ...fieldById, enabled: e.target.checked };
    updateLocalField(updatedField as CustomField);
  };

  const handleFieldChange = (
    updatedField: CustomField | CustomFieldRequest,
  ) => {
    if ('id' in updatedField) {
      updateLocalField(updatedField);
    } else {
      updateLocalField({ ...fieldById, ...updatedField } as CustomField);
    }
  };

  const handleSaveField = async () => {
    await updateCustomField(
      fieldById?.id ?? '',
      fieldById as CustomFieldRequest,
    );
  };

  const handleDeleteField = async () => {
    await deleteCustomField(fieldById?.id ?? '');
  };

  useEffect(() => {
    if (!showModal) {
      updateLocalField(field);
    }
  }, [showModal, field, updateLocalField]);

  const fieldEnabled = Boolean(fieldById?.enabled) && !disabled;

  return (
    <>
      <SingleToggleCard
        accordionKey={`${field.name}-toggle`}
        title={field.name}
        allowExpand={false}
        onCardClick={fieldEnabled ? () => handleShowModal(true) : undefined}
        inputProps={{
          checked: fieldEnabled,
          disabled,
          id: `${field.name}-toggle`,
          isSaved: field.enabled === fieldById?.enabled,
          onChange: handleEnableCustomField,
          type: 'toggle',
        }}
        customActionIcon={{
          Icon: <AdminIcon />,
          className: styles['action-icon'],
        }}
        Tags={getToggleBanners({
          enabled: fieldEnabled,
          required: Boolean(field.required),
        })}
      />

      <SideDrawer
        handleClose={() => handleShowModal(false)}
        isOpen={showModal && fieldEnabled}
        title={t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.title')}
        className={styles.drawer}
      >
        <div className={styles.container}>
          <div className={styles.section}>
            <NewFieldSideDrawer
              field={fieldById as CustomField}
              disabled={disabled}
              onClose={() => handleShowModal(false)}
              onSave={handleSaveField}
              onDelete={handleDeleteField}
              onChange={handleFieldChange}
            />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
