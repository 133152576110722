// eslint-disable-next-line import/no-namespace
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../components/Typography';
import { DropdownTrigger } from '../../../../../components/DropdownTrigger';

import styles from './TemplatesDropdown.module.css';

type Props = {
  selected?: string;
  setTemplate: (template: string) => void;
};

type Template =
  | 'default'
  | 'matrix'
  | 'sketchy'
  | 'minimal'
  | 'rounded'
  | 'trinity'
  | 'galaxy-era';

const templates: Template[] = [
  'default',
  'matrix',
  'minimal',
  'rounded',
  'trinity',
];

const convertedTemplate = (template: string) => {
  const evaluatedTemplate =
    (templates as string[]).indexOf(template) !== -1 ? template : 'default';
  return evaluatedTemplate as Template;
};

export const TemplatesDropdown = ({
  selected = 'default',
  setTemplate,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className={styles.dropdown}>
      <DropdownMenu.Root>
        <DropdownTrigger>
          <span className={styles.trigger}>
            {t(
              `design_page.modal.settings.templates.${convertedTemplate(
                selected,
              )}`,
            )}
          </span>
        </DropdownTrigger>
        <DropdownMenu.Content className={styles.dropdownContent} align='start'>
          {templates.map((template: Template) => (
            <DropdownMenu.Item
              className={styles.dropdownItem}
              onClick={() => setTemplate(template)}
              key={template}
            >
              <Typography variant='paragraph-2' weight='medium'>
                {t(`design_page.modal.settings.templates.${template}`)}
              </Typography>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    </div>
  );
};
