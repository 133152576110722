/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomHostnameVerificationType,
    CustomHostnameVerificationTypeFromJSON,
    CustomHostnameVerificationTypeFromJSONTyped,
    CustomHostnameVerificationTypeToJSON,
} from './CustomHostnameVerificationType';
import {
    DnsRecordType,
    DnsRecordTypeFromJSON,
    DnsRecordTypeFromJSONTyped,
    DnsRecordTypeToJSON,
} from './DnsRecordType';

/**
 * 
 * @export
 * @interface CustomHostnameVerificationRecord
 */
export interface CustomHostnameVerificationRecord {
    /**
     * 
     * @type {DnsRecordType}
     * @memberof CustomHostnameVerificationRecord
     */
    dnsRecordType: DnsRecordType;
    /**
     * 
     * @type {CustomHostnameVerificationType}
     * @memberof CustomHostnameVerificationRecord
     */
    verificationType: CustomHostnameVerificationType;
    /**
     * 
     * @type {string}
     * @memberof CustomHostnameVerificationRecord
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CustomHostnameVerificationRecord
     */
    value: string;
}

export function CustomHostnameVerificationRecordFromJSON(json: any): CustomHostnameVerificationRecord {
    return CustomHostnameVerificationRecordFromJSONTyped(json, false);
}

export function CustomHostnameVerificationRecordFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomHostnameVerificationRecord {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dnsRecordType': DnsRecordTypeFromJSON(json['dnsRecordType']),
        'verificationType': CustomHostnameVerificationTypeFromJSON(json['verificationType']),
        'name': json['name'],
        'value': json['value'],
    };
}

export function CustomHostnameVerificationRecordToJSON(value?: CustomHostnameVerificationRecord | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dnsRecordType': DnsRecordTypeToJSON(value.dnsRecordType),
        'verificationType': CustomHostnameVerificationTypeToJSON(value.verificationType),
        'name': value.name,
        'value': value.value,
    };
}

