/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OauthResultStatus,
    OauthResultStatusFromJSON,
    OauthResultStatusFromJSONTyped,
    OauthResultStatusToJSON,
} from './OauthResultStatus';

/**
 * 
 * @export
 * @interface OauthResultResponse
 */
export interface OauthResultResponse {
    /**
     * Valid oauth code
     * @type {string}
     * @memberof OauthResultResponse
     */
    code?: string;
    /**
     * Error return from oauth provider when user denies access
     * @type {string}
     * @memberof OauthResultResponse
     */
    error?: string;
    /**
     * 
     * @type {OauthResultStatus}
     * @memberof OauthResultResponse
     */
    status: OauthResultStatus;
}

export function OauthResultResponseFromJSON(json: any): OauthResultResponse {
    return OauthResultResponseFromJSONTyped(json, false);
}

export function OauthResultResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthResultResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'code': !exists(json, 'code') ? undefined : json['code'],
        'error': !exists(json, 'error') ? undefined : json['error'],
        'status': OauthResultStatusFromJSON(json['status']),
    };
}

export function OauthResultResponseToJSON(value?: OauthResultResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'code': value.code,
        'error': value.error,
        'status': OauthResultStatusToJSON(value.status),
    };
}

