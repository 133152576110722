/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BillingSubscriptionCoupon,
    BillingSubscriptionCouponFromJSON,
    BillingSubscriptionCouponFromJSONTyped,
    BillingSubscriptionCouponToJSON,
} from './BillingSubscriptionCoupon';
import {
    BillingSubscriptionPeriod,
    BillingSubscriptionPeriodFromJSON,
    BillingSubscriptionPeriodFromJSONTyped,
    BillingSubscriptionPeriodToJSON,
} from './BillingSubscriptionPeriod';
import {
    BillingSubscriptionPlanTypeEnum,
    BillingSubscriptionPlanTypeEnumFromJSON,
    BillingSubscriptionPlanTypeEnumFromJSONTyped,
    BillingSubscriptionPlanTypeEnumToJSON,
} from './BillingSubscriptionPlanTypeEnum';

/**
 * 
 * @export
 * @interface BillingSubscription
 */
export interface BillingSubscription {
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    billingPortalUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    billingPortalAddPaymentMethodUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingSubscription
     */
    hasPaymentMethod: boolean;
    /**
     * 
     * @type {BillingSubscriptionPlanTypeEnum}
     * @memberof BillingSubscription
     */
    planType: BillingSubscriptionPlanTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof BillingSubscription
     */
    inTrial: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof BillingSubscription
     */
    scope: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscription
     */
    trialStart?: Date;
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscription
     */
    trialEnd?: Date;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    trialDaysLeft?: number;
    /**
     * 
     * @type {BillingSubscriptionPeriod}
     * @memberof BillingSubscription
     */
    currentBillingPeriod?: BillingSubscriptionPeriod;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    version: string;
    /**
     * 
     * @type {boolean}
     * @memberof BillingSubscription
     */
    shouldLockEnvironmentSwitch?: boolean;
    /**
     * 
     * @type {Array<BillingSubscriptionCoupon>}
     * @memberof BillingSubscription
     */
    coupons?: Array<BillingSubscriptionCoupon>;
}

export function BillingSubscriptionFromJSON(json: any): BillingSubscription {
    return BillingSubscriptionFromJSONTyped(json, false);
}

export function BillingSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'billingPortalUrl': !exists(json, 'billingPortalUrl') ? undefined : json['billingPortalUrl'],
        'billingPortalAddPaymentMethodUrl': !exists(json, 'billingPortalAddPaymentMethodUrl') ? undefined : json['billingPortalAddPaymentMethodUrl'],
        'hasPaymentMethod': json['hasPaymentMethod'],
        'planType': BillingSubscriptionPlanTypeEnumFromJSON(json['planType']),
        'inTrial': json['inTrial'],
        'scope': json['scope'],
        'trialStart': !exists(json, 'trialStart') ? undefined : (new Date(json['trialStart'])),
        'trialEnd': !exists(json, 'trialEnd') ? undefined : (new Date(json['trialEnd'])),
        'trialDaysLeft': !exists(json, 'trialDaysLeft') ? undefined : json['trialDaysLeft'],
        'currentBillingPeriod': !exists(json, 'currentBillingPeriod') ? undefined : BillingSubscriptionPeriodFromJSON(json['currentBillingPeriod']),
        'version': json['version'],
        'shouldLockEnvironmentSwitch': !exists(json, 'shouldLockEnvironmentSwitch') ? undefined : json['shouldLockEnvironmentSwitch'],
        'coupons': !exists(json, 'coupons') ? undefined : ((json['coupons'] as Array<any>).map(BillingSubscriptionCouponFromJSON)),
    };
}

export function BillingSubscriptionToJSON(value?: BillingSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'billingPortalUrl': value.billingPortalUrl,
        'billingPortalAddPaymentMethodUrl': value.billingPortalAddPaymentMethodUrl,
        'hasPaymentMethod': value.hasPaymentMethod,
        'planType': BillingSubscriptionPlanTypeEnumToJSON(value.planType),
        'inTrial': value.inTrial,
        'scope': value.scope,
        'trialStart': value.trialStart === undefined ? undefined : (value.trialStart.toISOString()),
        'trialEnd': value.trialEnd === undefined ? undefined : (value.trialEnd.toISOString()),
        'trialDaysLeft': value.trialDaysLeft,
        'currentBillingPeriod': BillingSubscriptionPeriodToJSON(value.currentBillingPeriod),
        'version': value.version,
        'shouldLockEnvironmentSwitch': value.shouldLockEnvironmentSwitch,
        'coupons': value.coupons === undefined ? undefined : ((value.coupons as Array<any>).map(BillingSubscriptionCouponToJSON)),
    };
}

