import classNames from 'classnames';

import { Typography } from '../../Typography';
import { DropdownMenuRenderValue } from '../DropdownMenu.types';
import { Icon } from '../../Icon';
import { ChevronIcon } from '../../../icons';

import styles from './defaultRenderValue.module.scss';

export const defaultRenderValue: DropdownMenuRenderValue<any, any> = (
  option,
  { open, disabled },
) => (
  <div
    className={classNames(styles.container, {
      [styles['container--disabled']]: disabled,
    })}
  >
    <Typography variant='paragraph-2' as='span' weight='medium'>
      {option.props.children}
    </Typography>

    <Icon
      icon={<ChevronIcon />}
      size={16}
      className={classNames(styles.icon, {
        [styles['icon--open']]: open,
      })}
    />
  </div>
);
