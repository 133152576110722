import { useLocation, useNavigate } from 'react-router-dom';

type UseHashLocationProps = {
  availableValues?: string[];
  defaultValue?: string;
};

export const useHashLocation = ({
  availableValues,
  defaultValue,
}: UseHashLocationProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const hash = location.hash.replace('#', '').toLowerCase();

  const getHash = () => {
    if (availableValues) {
      if (availableValues.includes(hash)) {
        return hash;
      }

      return defaultValue;
    }

    return hash;
  };

  const setHash = (value: string) => {
    navigate(`#${value}`);
  };

  const removeHash = () => {
    navigate(location.pathname);
  };

  return {
    hash: getHash(),
    removeHash,
    setHash,
  };
};
