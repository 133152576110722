// There are rules that needs to be fulfilled for some providers
import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { isSignInProvider } from '../../utils';

type FIELDS = keyof Provider;

type VerifyProviderRulesProps = {
  disableAllSocialSignInProviders: VoidFunction;
  field: FIELDS;
  newProviders: Record<ProviderEnum, Provider>;
  prevProviders: Record<ProviderEnum, Provider>;
  provider: ProviderEnum;
  value: Provider[FIELDS];
};

export const verifyProviderRules = ({
  prevProviders,
  provider,
  field,
  value,
  newProviders,
  disableAllSocialSignInProviders,
}: VerifyProviderRulesProps): Record<ProviderEnum, Provider> => {
  const isEnablingSignInProvider =
    field === 'enabledAt' && value !== undefined && isSignInProvider(provider);

  const isMagicEnabled = Boolean(
    prevProviders[ProviderEnum.MagicLink]?.enabledAt,
  );
  const isBloctoEnabled = Boolean(
    prevProviders[ProviderEnum.Blocto]?.enabledAt,
  );

  // If enabling sign in provider (Dynamic, turnkey etc) different from magic link, we should disable magic link
  if (
    provider !== ProviderEnum.MagicLink &&
    isMagicEnabled &&
    isEnablingSignInProvider
  ) {
    newProviders[ProviderEnum.MagicLink] = {
      ...newProviders[ProviderEnum.MagicLink],
      enabledAt: undefined,
    };

    disableAllSocialSignInProviders();
  }

  // If enabling provider different from blocto, we should disable blocto
  if (
    provider !== ProviderEnum.Blocto &&
    isBloctoEnabled &&
    isEnablingSignInProvider
  ) {
    newProviders[ProviderEnum.Blocto] = {
      ...newProviders[ProviderEnum.Blocto],
      enabledAt: undefined,
    };
  }

  // if enabling coinbaseWaas, we should disable turnkey
  if (
    provider === ProviderEnum.CoinbaseWaas &&
    newProviders[ProviderEnum.Turnkey]?.enabledAt
  ) {
    newProviders[ProviderEnum.Turnkey] = {
      ...newProviders[ProviderEnum.Turnkey],
      enabledAt: undefined,
    };
  }

  // if enabling turnkey, we should disable coinbaseWaas
  if (
    provider === ProviderEnum.Turnkey &&
    newProviders[ProviderEnum.CoinbaseWaas]?.enabledAt
  ) {
    newProviders[ProviderEnum.CoinbaseWaas] = {
      ...newProviders[ProviderEnum.CoinbaseWaas],
      enabledAt: undefined,
    };
  }

  // If enabling magic or blocto
  if (
    isEnablingSignInProvider &&
    (provider === ProviderEnum.MagicLink || provider === ProviderEnum.Blocto)
  ) {
    /**
     * Disables all providers that are social or sign in providers
     */
    Object.values(newProviders)
      .filter((providerSetting) => isSignInProvider(providerSetting.provider))
      .forEach((providerSetting) => {
        newProviders[providerSetting.provider] = {
          ...providerSetting,
          enabledAt: undefined,
        };
      });

    disableAllSocialSignInProviders();
  }

  return newProviders;
};
