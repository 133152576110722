/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumToJSON,
    ChainToken,
    ChainTokenFromJSON,
    ChainTokenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
} from '../models';

export interface GetAllTokensByChainRequest {
    chainName: ChainEnum;
    networkId?: number;
}

/**
 * 
 */
export class ChainsApi extends runtime.BaseAPI {

    /**
     * Get tokens for passed chainName.
     */
    async getAllTokensByChainRaw(requestParameters: GetAllTokensByChainRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<ChainToken>>> {
        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling getAllTokensByChain.');
        }

        const queryParameters: any = {};

        if (requestParameters.networkId !== undefined) {
            queryParameters['networkId'] = requestParameters.networkId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/chains/{chainName}/tokens`.replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChainTokenFromJSON));
    }

    /**
     * Get tokens for passed chainName.
     */
    async getAllTokensByChain(requestParameters: GetAllTokensByChainRequest, initOverrides?: RequestInit): Promise<Array<ChainToken>> {
        const response = await this.getAllTokensByChainRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
