import {
  createContext,
  FC,
  useContext,
  useMemo,
  PropsWithChildren,
  useState,
} from 'react';

import { QueryClientProvider, QueryClient } from 'react-query';

import {
  AllowlistsApi,
  AnalyticsApi,
  ChainalysisApi,
  ChainsApi,
  Configuration,
  CustomHostnamesApi,
  EnvironmentsApi,
  EventsApi,
  ExportsApi,
  FetchParams,
  GatesApi,
  InvitesApi,
  MembersApi,
  Middleware,
  OrganizationsApi,
  OriginsApi,
  ProjectsApi,
  ResponseContext,
  SettingsApi,
  TokensApi,
  UsersApi,
  VisitsApi,
  WebhooksApi,
  ExternalJwtApi,
  DeeplinkUrlsApi,
  CustomFieldsApi,
} from '@dynamic-labs/sdk-api';
import { getAuthToken } from '@dynamic-labs/sdk-react-core';

export type RedcoastQueryContextProps = {
  allowListsApi: AllowlistsApi;
  analyticsApi: AnalyticsApi;
  chainalysisApi: ChainalysisApi;
  chainsApi: ChainsApi;
  customFieldsApi: CustomFieldsApi;
  customHostnamesApi: CustomHostnamesApi;
  deeplinkUrlsApi: DeeplinkUrlsApi;
  environmentsApi: EnvironmentsApi;
  eventsApi: EventsApi;
  exportsApi: ExportsApi;
  externalJwtApi: ExternalJwtApi;
  gatesApi: GatesApi;
  invitesApi: InvitesApi;
  membersApi: MembersApi;
  organizationsApi: OrganizationsApi;
  originsApi: OriginsApi;
  projectsApi: ProjectsApi;
  settingsApi: SettingsApi;
  tokensApi: TokensApi;
  usersApi: UsersApi;
  visitsApi: VisitsApi;
  webhooksApi: WebhooksApi;
};

type RedcoastQueryContextProviderProps = {
  baseUrl: string | undefined;
};

export const RedcoastQueryContext = createContext<
  RedcoastQueryContextProps | undefined
>(undefined);

class BearerAuthTokenMiddleware implements Middleware {
  pre(context: ResponseContext): Promise<FetchParams | void> {
    const fetchParams = {
      init: {
        ...context.init,
        headers: {
          ...context.init.headers,
          Authorization: `Bearer ${getAuthToken()}`,
        },
      },
      url: context.url,
    };
    return Promise.resolve(fetchParams);
  }

  post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response);
  }
}

export const RedcoastQueryContextProvider: FC<
  PropsWithChildren<RedcoastQueryContextProviderProps>
> = ({ children, baseUrl }) => {
  const configuration = new Configuration({
    basePath: baseUrl,
    middleware: [new BearerAuthTokenMiddleware()],
  });

  const [projectsApi] = useState<ProjectsApi>(new ProjectsApi(configuration));
  const [usersApi] = useState<UsersApi>(new UsersApi(configuration));
  const [environmentsApi] = useState<EnvironmentsApi>(
    new EnvironmentsApi(configuration),
  );
  const [organizationsApi] = useState<OrganizationsApi>(
    new OrganizationsApi(configuration),
  );
  const [invitesApi] = useState<InvitesApi>(new InvitesApi(configuration));
  const [tokensApi] = useState<TokensApi>(new TokensApi(configuration));
  const [originsApi] = useState<OriginsApi>(new OriginsApi(configuration));
  const [allowListsApi] = useState<AllowlistsApi>(
    new AllowlistsApi(configuration),
  );
  const [gatesApi] = useState<GatesApi>(new GatesApi(configuration));
  const [membersApi] = useState<MembersApi>(new MembersApi(configuration));
  const [chainalysisApi] = useState<ChainalysisApi>(
    new ChainalysisApi(configuration),
  );
  const [analyticsApi] = useState<AnalyticsApi>(
    new AnalyticsApi(configuration),
  );
  const [visitsApi] = useState<VisitsApi>(new VisitsApi(configuration));
  const [settingsApi] = useState<SettingsApi>(new SettingsApi(configuration));
  const [exportsApi] = useState<ExportsApi>(new ExportsApi(configuration));
  const [chainsApi] = useState<ChainsApi>(new ChainsApi(configuration));
  const [webhooksApi] = useState<WebhooksApi>(new WebhooksApi(configuration));
  const [eventsApi] = useState<EventsApi>(new EventsApi(configuration));
  const [deeplinkUrlsApi] = useState(new DeeplinkUrlsApi(configuration));
  const [externalJwtApi] = useState<ExternalJwtApi>(
    new ExternalJwtApi(configuration),
  );
  const [customHostnamesApi] = useState<CustomHostnamesApi>(
    new CustomHostnamesApi(configuration),
  );
  const [customFieldsApi] = useState<CustomFieldsApi>(
    new CustomFieldsApi(configuration),
  );

  const value = useMemo(
    () => ({
      allowListsApi,
      analyticsApi,
      chainalysisApi,
      chainsApi,
      customFieldsApi,
      customHostnamesApi,
      deeplinkUrlsApi,
      environmentsApi,
      eventsApi,
      exportsApi,
      externalJwtApi,
      gatesApi,
      invitesApi,
      membersApi,
      organizationsApi,
      originsApi,
      projectsApi,
      settingsApi,
      tokensApi,
      usersApi,
      visitsApi,
      webhooksApi,
    }),
    [
      allowListsApi,
      analyticsApi,
      chainalysisApi,
      chainsApi,
      environmentsApi,
      customFieldsApi,
      eventsApi,
      exportsApi,
      gatesApi,
      invitesApi,
      membersApi,
      organizationsApi,
      originsApi,
      customHostnamesApi,
      projectsApi,
      settingsApi,
      tokensApi,
      usersApi,
      visitsApi,
      webhooksApi,
      externalJwtApi,
      deeplinkUrlsApi,
    ],
  );

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: process.env.NODE_ENV === 'test' ? false : undefined, // If env is test, disable retry
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <RedcoastQueryContext.Provider value={value}>
        {children}
      </RedcoastQueryContext.Provider>
    </QueryClientProvider>
  );
};

export const useRedcoastQueryContext = () => {
  const context = useContext(RedcoastQueryContext);

  if (context === undefined) {
    throw new Error(
      'usage of useRedcoastQueryContext not wrapped in `RedcoastQueryContextProvider`.',
    );
  }

  return context;
};
