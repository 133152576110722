import { useCallback, useMemo } from 'react';

import { useEnvironmentsContext } from '../../context/EnvironmentsContext';
import { useSettingsContext } from '../../context/SettingsContext';
import { IntegrationCategory } from '../../types';

import { KycSettingType } from './useSetKycFieldProp.types';

export type UpdateActiveEnvKycFieldPropParams = {
  disabled?: boolean;

  isSelected: boolean;

  name: string;

  /** Whether to automatically enable the field itself when enabling one of its properties */
  preventAutoEnableField?: boolean;

  type: KycSettingType;
};

export const useSetKycFieldProp = (category: IntegrationCategory) => {
  const { settings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const updatedActiveEnv = useMemo(
    () => [...settings[activeEnvironmentType][category]],
    [activeEnvironmentType, category, settings],
  );

  const updateActiveEnvKycFieldProp = useCallback(
    ({
      isSelected,
      name,
      type,
      disabled,
      preventAutoEnableField,
    }: UpdateActiveEnvKycFieldPropParams) => {
      if (disabled) {
        return settings;
      }

      const indexToUpdate = updatedActiveEnv.findIndex(
        (setting) => setting.name === name,
      );

      const updateOneFieldByType = (fieldType: KycSettingType) => {
        updatedActiveEnv[indexToUpdate] = {
          ...updatedActiveEnv[indexToUpdate],
          [fieldType]: isSelected,
        };
      };

      updateOneFieldByType(type);

      if (type === 'verify' && isSelected) {
        // if verify is selected, unique should be selected as well
        updateOneFieldByType('unique');
      }

      // if any other field is selected, enabled should be selected as well
      if (!preventAutoEnableField && type !== 'enabled' && isSelected) {
        updateOneFieldByType('enabled');
      }

      const updatedSettings = {
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          [category]: updatedActiveEnv,
        },
      };
      setSettings(updatedSettings);
      return updatedSettings;
    },
    [activeEnvironmentType, category, setSettings, settings, updatedActiveEnv],
  );

  return { updateActiveEnvKycFieldProp };
};
