/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WalletAddressType,
    WalletAddressTypeFromJSON,
    WalletAddressTypeFromJSONTyped,
    WalletAddressTypeToJSON,
} from './WalletAddressType';

/**
 * An additional address associated with a wallet.
 * @export
 * @interface WalletAdditionalAddress
 */
export interface WalletAdditionalAddress {
    /**
     * An address associated with a wallet.
     * @type {string}
     * @memberof WalletAdditionalAddress
     */
    address: string;
    /**
     * The public key associated with the address.
     * @type {string}
     * @memberof WalletAdditionalAddress
     */
    publicKey?: string;
    /**
     * 
     * @type {WalletAddressType}
     * @memberof WalletAdditionalAddress
     */
    type: WalletAddressType;
}

export function WalletAdditionalAddressFromJSON(json: any): WalletAdditionalAddress {
    return WalletAdditionalAddressFromJSONTyped(json, false);
}

export function WalletAdditionalAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): WalletAdditionalAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
        'type': WalletAddressTypeFromJSON(json['type']),
    };
}

export function WalletAdditionalAddressToJSON(value?: WalletAdditionalAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'publicKey': value.publicKey,
        'type': WalletAddressTypeToJSON(value.type),
    };
}

