/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TelegramUser,
    TelegramUserFromJSON,
    TelegramUserFromJSONTyped,
    TelegramUserToJSON,
} from './TelegramUser';

/**
 * 
 * @export
 * @interface TelegramPostRequest
 */
export interface TelegramPostRequest {
    /**
     * 
     * @type {string}
     * @memberof TelegramPostRequest
     */
    state: string;
    /**
     * 
     * @type {TelegramUser}
     * @memberof TelegramPostRequest
     */
    telegramUser: TelegramUser;
}

export function TelegramPostRequestFromJSON(json: any): TelegramPostRequest {
    return TelegramPostRequestFromJSONTyped(json, false);
}

export function TelegramPostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelegramPostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'state': json['state'],
        'telegramUser': TelegramUserFromJSON(json['telegramUser']),
    };
}

export function TelegramPostRequestToJSON(value?: TelegramPostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'state': value.state,
        'telegramUser': TelegramUserToJSON(value.telegramUser),
    };
}

