/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum ChainalysisCheckResultEnum {
    Ok = 'OK',
    Blocked = 'BLOCKED',
    Failed = 'FAILED'
}

export function ChainalysisCheckResultEnumFromJSON(json: any): ChainalysisCheckResultEnum {
    return ChainalysisCheckResultEnumFromJSONTyped(json, false);
}

export function ChainalysisCheckResultEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChainalysisCheckResultEnum {
    return json as ChainalysisCheckResultEnum;
}

export function ChainalysisCheckResultEnumToJSON(value?: ChainalysisCheckResultEnum | null): any {
    return value as any;
}

