import { useQuery } from 'react-query';

import { usersApi } from '../../../../services/api';

export const useUserDetails = (userId?: string) =>
  useQuery(
    [userId, 'userDetails'],
    () =>
      usersApi
        .getUser({
          userId: userId as string,
        })
        .then(({ user }) => user),
    {
      enabled: !!userId,
    },
  );
