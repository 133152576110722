/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';

/**
 * 
 * @export
 * @interface OauthProviderRequest
 */
export interface OauthProviderRequest {
    /**
     * 
     * @type {ProviderEnum}
     * @memberof OauthProviderRequest
     */
    provider?: ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof OauthProviderRequest
     */
    accessToken?: string;
    /**
     * 
     * @type {string}
     * @memberof OauthProviderRequest
     */
    didToken?: string;
}

export function OauthProviderRequestFromJSON(json: any): OauthProviderRequest {
    return OauthProviderRequestFromJSONTyped(json, false);
}

export function OauthProviderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): OauthProviderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': !exists(json, 'provider') ? undefined : ProviderEnumFromJSON(json['provider']),
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'didToken': !exists(json, 'didToken') ? undefined : json['didToken'],
    };
}

export function OauthProviderRequestToJSON(value?: OauthProviderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderEnumToJSON(value.provider),
        'accessToken': value.accessToken,
        'didToken': value.didToken,
    };
}

