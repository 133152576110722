import { ROUTES } from '../components/Navigation/data';

export const quickHelpData = (
  docsUrl: string = ROUTES.documentation.toString(),
) => ({
  content:
    'View a clear summary of all the steps you will be taken to in order to complete your onbording',
  documentation: 'Read documentation',
  documentation_url: docsUrl,
  name: 'Quick help',
});
