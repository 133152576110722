import TagManager, { TagManagerArgs } from 'react-gtm-module';

import environment from '../environments/environment';

if (environment.production) {
  const tagManagerArgs: TagManagerArgs = {
    auth: 'nRYHv76QMpxvLWvxkQllZw',
    gtmId: 'GTM-K9NXSDK',
    preview: 'env-1',
  };

  TagManager.initialize(tagManagerArgs);
}
