/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderUrl,
    ProviderUrlFromJSON,
    ProviderUrlFromJSONTyped,
    ProviderUrlToJSON,
} from './ProviderUrl';

/**
 * 
 * @export
 * @interface ProviderUrlsResponse
 */
export interface ProviderUrlsResponse {
    /**
     * 
     * @type {Array<ProviderUrl>}
     * @memberof ProviderUrlsResponse
     */
    providerUrls?: Array<ProviderUrl>;
}

export function ProviderUrlsResponseFromJSON(json: any): ProviderUrlsResponse {
    return ProviderUrlsResponseFromJSONTyped(json, false);
}

export function ProviderUrlsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderUrlsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providerUrls': !exists(json, 'providerUrls') ? undefined : ((json['providerUrls'] as Array<any>).map(ProviderUrlFromJSON)),
    };
}

export function ProviderUrlsResponseToJSON(value?: ProviderUrlsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providerUrls': value.providerUrls === undefined ? undefined : ((value.providerUrls as Array<any>).map(ProviderUrlToJSON)),
    };
}

