import React, { ChangeEvent, FC, ReactNode } from 'react';

import { CheckArrow } from '../../../icons';
import { Tooltip } from '../Tooltip';

import styles from './checkbox.module.css';

export interface Props
  extends Omit<React.ComponentPropsWithoutRef<'input'>, 'onChange'> {
  ariaLabel?: string;
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  onChange?: (e: ChangeEvent<HTMLInputElement>, value: string) => void;
  showTooltip?: boolean;
  tooltipText?: string[];
}

const Checkbox: FC<Props> = ({
  ariaLabel,
  checked,
  className = '',
  defaultChecked,
  disabled,
  id,
  name,
  onChange,
  value,
  label,
  showTooltip = true,
  tooltipText = [],
}) => (
  <div className={`${styles.wrapper} ${className}`}>
    <Tooltip
      tooltipText={tooltipText}
      disableHover={!showTooltip || tooltipText.length === 0}
    >
      <div className={styles.container}>
        <input
          type='checkbox'
          id={id}
          data-testid={id}
          aria-label={ariaLabel}
          aria-checked={checked}
          className={styles.input}
          value={value}
          checked={checked}
          name={name}
          disabled={disabled}
          defaultChecked={defaultChecked}
          onChange={(e) => !!onChange && onChange(e, e.currentTarget.value)}
        />
        <div className={styles.checkbox}>
          <CheckArrow className={styles.check} />
        </div>
      </div>
    </Tooltip>
    {label && (
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
    )}
  </div>
);

export default Checkbox;
