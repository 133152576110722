/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TurnkeyWalletAccount
 */
export interface TurnkeyWalletAccount {
    /**
     * 
     * @type {string}
     * @memberof TurnkeyWalletAccount
     */
    curve: string;
    /**
     * 
     * @type {string}
     * @memberof TurnkeyWalletAccount
     */
    pathFormat: string;
    /**
     * 
     * @type {string}
     * @memberof TurnkeyWalletAccount
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof TurnkeyWalletAccount
     */
    addressFormat: string;
}

export function TurnkeyWalletAccountFromJSON(json: any): TurnkeyWalletAccount {
    return TurnkeyWalletAccountFromJSONTyped(json, false);
}

export function TurnkeyWalletAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnkeyWalletAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'curve': json['curve'],
        'pathFormat': json['pathFormat'],
        'path': json['path'],
        'addressFormat': json['addressFormat'],
    };
}

export function TurnkeyWalletAccountToJSON(value?: TurnkeyWalletAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'curve': value.curve,
        'pathFormat': value.pathFormat,
        'path': value.path,
        'addressFormat': value.addressFormat,
    };
}

