/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HealthcheckStatus,
    HealthcheckStatusFromJSON,
    HealthcheckStatusFromJSONTyped,
    HealthcheckStatusToJSON,
} from './HealthcheckStatus';

/**
 * 
 * @export
 * @interface HealthcheckResponse
 */
export interface HealthcheckResponse {
    /**
     * 
     * @type {HealthcheckStatus}
     * @memberof HealthcheckResponse
     */
    status: HealthcheckStatus;
}

export function HealthcheckResponseFromJSON(json: any): HealthcheckResponse {
    return HealthcheckResponseFromJSONTyped(json, false);
}

export function HealthcheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': HealthcheckStatusFromJSON(json['status']),
    };
}

export function HealthcheckResponseToJSON(value?: HealthcheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': HealthcheckStatusToJSON(value.status),
    };
}

