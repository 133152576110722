import { useCallback, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Organization as OrganizationDropdownType,
  OrganizationSubItemsAction,
} from '@dynamic-labs/northstar';
import { RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardContext } from '../../../app/context/DashboardContext';
import { useDashboardRole } from '../../../app/hooks/useDashboardRole';

export const useOrganizationsDropdown = () => {
  const { t } = useTranslation();
  const {
    activeOrganizationId,
    setActiveOrganizationId,
    userOrganizations,
    isLoadingUserOrganizations,
  } = useDashboardContext();
  const { checkAccessForOrganizationId } = useDashboardRole();

  const [showRenameOrganizationModal, setShowRenameOrganizationModal] =
    useState<boolean>(false);
  const [showCreateOrganizationModal, setShowCreateOrganizationModal] =
    useState<boolean>(false);

  const checkForOrganizationAccess = useCallback(
    (id: string) =>
      // User must have admin access to create a project
      checkAccessForOrganizationId(id, RoleEnum.Admin),
    [checkAccessForOrganizationId],
  );

  const organizationActions: OrganizationSubItemsAction[] = useMemo(
    () => [
      {
        action: () => setShowRenameOrganizationModal(true),
        // disabled: (organizationId: string) =>
        //   !checkForOrganizationAccess(organizationId),
        // Temporary return true until
        // https://linear.app/dynamic-labs/issue/GVTY-1589
        disabled: true,
        text: t('v2.topbar.organizations.actions.rename'),
      },
    ],
    [checkForOrganizationAccess, t],
  );

  const dropdownOrganizations: OrganizationDropdownType[] = useMemo(
    () =>
      userOrganizations.map((org) => ({
        id: org.id,
        name: org.name,
      })),
    [userOrganizations],
  );

  return {
    organizationsDropdown: {
      activeOrganizationId,
      isLoading: isLoadingUserOrganizations,
      organizationActions,
      organizations: dropdownOrganizations,
      organizationsDropdownCTA: {
        action: () => setShowCreateOrganizationModal(true),
        text: t('v2.topbar.organizations.actions.create'),
      },
      setActiveOrganization: setActiveOrganizationId,
    },
    setShowCreateOrganizationModal,
    setShowRenameOrganizationModal,
    showCreateOrganizationModal,
    showRenameOrganizationModal,
  };
};
