import { createContext, useContext, useState, useMemo } from 'react';

import { UseMutateFunction } from 'react-query';

import { EnvironmentEnum, Gate } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { useGates } from '../../hooks/useGates';

export type GatesContextProps = {
  createGate: UseMutateFunction<Gate, unknown, Gate, unknown>;
  deleteGate: UseMutateFunction<void, unknown, string, unknown>;
  gates: Gate[];
  hasEnabledGatesInLive: boolean;
  hasEnabledGatesInSandbox: boolean;
  setNewGate: React.Dispatch<React.SetStateAction<Gate | undefined>>;
  toggleGate: UseMutateFunction<Gate, unknown, Gate, unknown>;
  updateGate: UseMutateFunction<Gate, unknown, Gate, unknown>;
};

type GatesContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export type CountResult = {
  count: number;
  projectEnvironmentId: string;
};
export const GatesContext = createContext<GatesContextProps | undefined>(
  undefined,
);

export const GatesContextProvider = ({
  children,
}: GatesContextProviderProps) => {
  const { environments, activeEnvironmentType } = useEnvironmentsContext();

  // we need to query both because we need to know if there's active gates
  // in each environment to dislay in the gates card
  const sandboxEnvGatesValues = useGates(environments?.sandbox?.id ?? '');
  const liveEnvGatesValues = useGates(environments?.live?.id ?? '');

  const activeEnvGatesValues = useMemo(
    () =>
      activeEnvironmentType === EnvironmentEnum.Live
        ? liveEnvGatesValues
        : sandboxEnvGatesValues,
    [activeEnvironmentType, liveEnvGatesValues, sandboxEnvGatesValues],
  );

  // this state stores the unpersisted gate that is being created
  const [newGate, setNewGate] = useState<Gate>();

  const allGates = useMemo(() => {
    const all: Gate[] = [...activeEnvGatesValues.gates];

    if (newGate) {
      all.push(newGate);
    }

    return all;
  }, [activeEnvGatesValues, newGate]);

  const hasEnabledGatesInSandbox = !!sandboxEnvGatesValues.gates.find(
    (g) => !!g.enabledAt,
  );
  const hasEnabledGatesInLive = !!liveEnvGatesValues.gates.find(
    (g) => !!g.enabledAt,
  );

  const value = useMemo(
    () => ({
      createGate: activeEnvGatesValues.createGate,
      deleteGate: activeEnvGatesValues.deleteGate,
      gates: allGates,
      hasEnabledGatesInLive,
      hasEnabledGatesInSandbox,
      setNewGate,
      toggleGate: activeEnvGatesValues.toggleGate,
      updateGate: activeEnvGatesValues.updateGate,
    }),
    [
      activeEnvGatesValues,
      allGates,
      hasEnabledGatesInLive,
      hasEnabledGatesInSandbox,
      setNewGate,
    ],
  );

  return (
    <GatesContext.Provider value={value}>{children}</GatesContext.Provider>
  );
};

export const useGatesContext = () => {
  const context = useContext(GatesContext);
  if (context === undefined) {
    throw new Error(
      'usage of useGatesContext not wrapped in `GatesContextProvider`.',
    );
  }

  return context;
};
