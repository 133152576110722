import { createContext, useContext, useMemo } from 'react';

import { EventType } from '@dynamic-labs/sdk-api';

import { useEventTypes } from '../../hooks/useEventTypes';

type EventsContextProps = {
  eventTypes: EventType[] | undefined;
};

type EventsContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const EventsContext = createContext<EventsContextProps | undefined>(
  undefined,
);

export const EventsContextProvider = ({
  children,
}: EventsContextProviderProps) => {
  const { eventTypes } = useEventTypes();

  const value = useMemo(
    () => ({
      eventTypes,
    }),
    [eventTypes],
  );

  return (
    <EventsContext.Provider value={value}>{children}</EventsContext.Provider>
  );
};

export const useEventsContext = () => {
  const context = useContext(EventsContext);

  if (context === undefined) {
    throw new Error(
      'usage of useEventsContext not wrapped in `EventsContextProvider`.',
    );
  }

  return context;
};
