/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum InviteStatusEnum {
    Pending = 'pending',
    Rejected = 'rejected',
    Accepted = 'accepted'
}

export function InviteStatusEnumFromJSON(json: any): InviteStatusEnum {
    return InviteStatusEnumFromJSONTyped(json, false);
}

export function InviteStatusEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteStatusEnum {
    return json as InviteStatusEnum;
}

export function InviteStatusEnumToJSON(value?: InviteStatusEnum | null): any {
    return value as any;
}

