/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PasskeyRegistrationCredential,
    PasskeyRegistrationCredentialFromJSON,
    PasskeyRegistrationCredentialFromJSONTyped,
    PasskeyRegistrationCredentialToJSON,
} from './PasskeyRegistrationCredential';

/**
 * 
 * @export
 * @interface CompletePasskeyRecoveryRequest
 */
export interface CompletePasskeyRecoveryRequest {
    /**
     * 
     * @type {string}
     * @memberof CompletePasskeyRecoveryRequest
     */
    walletId: string;
    /**
     * 
     * @type {PasskeyRegistrationCredential}
     * @memberof CompletePasskeyRecoveryRequest
     */
    attestation: PasskeyRegistrationCredential;
    /**
     * 
     * @type {string}
     * @memberof CompletePasskeyRecoveryRequest
     */
    challenge: string;
}

export function CompletePasskeyRecoveryRequestFromJSON(json: any): CompletePasskeyRecoveryRequest {
    return CompletePasskeyRecoveryRequestFromJSONTyped(json, false);
}

export function CompletePasskeyRecoveryRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompletePasskeyRecoveryRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletId': json['walletId'],
        'attestation': PasskeyRegistrationCredentialFromJSON(json['attestation']),
        'challenge': json['challenge'],
    };
}

export function CompletePasskeyRecoveryRequestToJSON(value?: CompletePasskeyRecoveryRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletId': value.walletId,
        'attestation': PasskeyRegistrationCredentialToJSON(value.attestation),
        'challenge': value.challenge,
    };
}

