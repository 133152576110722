/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MFAGetRecoveryCodesResponse
 */
export interface MFAGetRecoveryCodesResponse {
    /**
     * The number of recovery codes available
     * @type {number}
     * @memberof MFAGetRecoveryCodesResponse
     */
    count: number;
    /**
     * Recovery codes for the user to use in case they lose access to their MFA device
     * @type {Array<string>}
     * @memberof MFAGetRecoveryCodesResponse
     */
    recoveryCodes: Array<string>;
}

export function MFAGetRecoveryCodesResponseFromJSON(json: any): MFAGetRecoveryCodesResponse {
    return MFAGetRecoveryCodesResponseFromJSONTyped(json, false);
}

export function MFAGetRecoveryCodesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAGetRecoveryCodesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'recoveryCodes': json['recoveryCodes'],
    };
}

export function MFAGetRecoveryCodesResponseToJSON(value?: MFAGetRecoveryCodesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'recoveryCodes': value.recoveryCodes,
    };
}

