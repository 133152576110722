import React from 'react';

import { t } from 'i18next';

import { UsePrivacySettings } from '../../../../context/PrivacyContext/helpers/privacyHelpers';
import { ipOptions } from '../optionsData';
import { OptionsSwitcher } from '../../../../components/OptionsSwitcher';
import { Typography } from '../../../../components/Typography';

import styles from './StylingOptions.module.css';

export const StylingOptions = () => {
  const { settings, setCollectIp } = UsePrivacySettings();
  const { collectIp } = settings || {};

  return (
    <div className={styles.options}>
      <Typography variant='paragraph-2' weight='medium'>
        {t<string>('settings_page.privacy.collectIp.userInformationTitle')}
      </Typography>

      <Typography variant='paragraph-1' className={styles.subheading}>
        {t<string>('settings_page.privacy.collectIp.collectIpTitle')}
      </Typography>
      <OptionsSwitcher
        options={ipOptions}
        initialActiveKey={JSON.stringify(collectIp)}
        onOptionClick={(key) => setCollectIp(key)}
      />
    </div>
  );
};
