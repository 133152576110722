/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SdkViewSectionAlignment,
    SdkViewSectionAlignmentFromJSON,
    SdkViewSectionAlignmentFromJSONTyped,
    SdkViewSectionAlignmentToJSON,
} from './SdkViewSectionAlignment';
import {
    SdkViewSectionType,
    SdkViewSectionTypeFromJSON,
    SdkViewSectionTypeFromJSONTyped,
    SdkViewSectionTypeToJSON,
} from './SdkViewSectionType';

/**
 * A section used create the view in the sdk.
 * @export
 * @interface SdkViewSection
 */
export interface SdkViewSection {
    /**
     * 
     * @type {SdkViewSectionType}
     * @memberof SdkViewSection
     */
    type: SdkViewSectionType;
    /**
     * The label for the section. This will be displayed above the section or as part of the separator component if it is a Separator section.
     * @type {string}
     * @memberof SdkViewSection
     */
    label?: string;
    /**
     * The deafult number of items to display in the section. For Wallet section, represents the number of wallet items to be displayed by default. User has to click a button to view more options if there are any. For Social section, represents the number of social providers to be displayed by default. User has to click a button to view more options if there are any.
     * @type {number}
     * @memberof SdkViewSection
     */
    numOfItemsToDisplay?: number;
    /**
     * The option to be displayed as the main one. The default item will be displayed in a more prominent way than the rest of the items in the section. For Wallet section, represents the wallet item to be displayed by default. For Social section, represents the social provider to be displayed by default.
     * @type {string}
     * @memberof SdkViewSection
     */
    defaultItem?: string;
    /**
     * 
     * @type {SdkViewSectionAlignment}
     * @memberof SdkViewSection
     */
    alignment?: SdkViewSectionAlignment;
}

export function SdkViewSectionFromJSON(json: any): SdkViewSection {
    return SdkViewSectionFromJSONTyped(json, false);
}

export function SdkViewSectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkViewSection {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': SdkViewSectionTypeFromJSON(json['type']),
        'label': !exists(json, 'label') ? undefined : json['label'],
        'numOfItemsToDisplay': !exists(json, 'numOfItemsToDisplay') ? undefined : json['numOfItemsToDisplay'],
        'defaultItem': !exists(json, 'defaultItem') ? undefined : json['defaultItem'],
        'alignment': !exists(json, 'alignment') ? undefined : SdkViewSectionAlignmentFromJSON(json['alignment']),
    };
}

export function SdkViewSectionToJSON(value?: SdkViewSection | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': SdkViewSectionTypeToJSON(value.type),
        'label': value.label,
        'numOfItemsToDisplay': value.numOfItemsToDisplay,
        'defaultItem': value.defaultItem,
        'alignment': SdkViewSectionAlignmentToJSON(value.alignment),
    };
}

