/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TurnkeyStamp,
    TurnkeyStampFromJSON,
    TurnkeyStampFromJSONTyped,
    TurnkeyStampToJSON,
} from './TurnkeyStamp';

/**
 * 
 * @export
 * @interface TurnkeySignedRequest
 */
export interface TurnkeySignedRequest {
    /**
     * 
     * @type {string}
     * @memberof TurnkeySignedRequest
     */
    body: string;
    /**
     * 
     * @type {TurnkeyStamp}
     * @memberof TurnkeySignedRequest
     */
    stamp: TurnkeyStamp;
    /**
     * 
     * @type {string}
     * @memberof TurnkeySignedRequest
     */
    url: string;
}

export function TurnkeySignedRequestFromJSON(json: any): TurnkeySignedRequest {
    return TurnkeySignedRequestFromJSONTyped(json, false);
}

export function TurnkeySignedRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnkeySignedRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': json['body'],
        'stamp': TurnkeyStampFromJSON(json['stamp']),
        'url': json['url'],
    };
}

export function TurnkeySignedRequestToJSON(value?: TurnkeySignedRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'stamp': TurnkeyStampToJSON(value.stamp),
        'url': value.url,
    };
}

