import { useEffect, useRef } from 'react';

export const useOnUnMount = (fn: VoidFunction) => {
  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const mounted = () => isMounted.current;

  useEffect(
    () => () => {
      if (!mounted()) {
        fn();
      }
    },
    [mounted, fn],
  );
};
