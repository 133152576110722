import { Dispatch, SetStateAction } from 'react';

import { Allowlist, AllowlistEntriesResponse } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../../services/logger';
import { allowListsApi } from '../../../../../services/api';

export const toggleAllowList = async (allowlist: Allowlist) => {
  try {
    if (allowlist.id) {
      if (allowlist.enabledAt) {
        const list = await allowListsApi.disableAllowlistById({
          allowlistId: allowlist.id,
        });

        return list;
      }
      const list = await allowListsApi.enableAllowlistById({
        allowlistId: allowlist.id,
      });

      return list;
    }

    return null;
  } catch (e) {
    logger.error(e);

    return null;
  }
};

export const fetchAllowListEntries = async (
  allowlistId: string | undefined,
  setEntries: Dispatch<SetStateAction<AllowlistEntriesResponse | undefined>>,
  offset: number,
  limit: number,
  entryFilter?: string,
) => {
  try {
    if (allowlistId) {
      const response = await allowListsApi.getEntriesByAllowlistId({
        allowlistId,
        limit,
        offset,
        ...(entryFilter && { query: entryFilter }),
      });

      setEntries(response);
    }
  } catch (e) {
    logger.error(e);
  }
};

export const deleteAllowListEntryById = async (entryId: string | undefined) => {
  try {
    if (entryId) {
      await allowListsApi.deleteAllowlistEntryById({
        allowlistEntryId: entryId,
      });
    }
  } catch (e) {
    logger.error(e);
  }
};
