import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Section } from '@dynamic-labs/northstar';

import { CorsRow } from './CorsRow';
import { CookieRow } from './CookieRow';
import styles from './DomainsRow.module.scss';

export const DomainsRow: FC = () => {
  const { t } = useTranslation();
  const { enableCustomDomainDashboard } = useFlags();

  return (
    <Section
      title={t('v2.page.security.domains_row.title')}
      description={t('v2.page.security.domains_row.description')}
    >
      <div className={styles.rowWrapper}>
        <CorsRow />
        {enableCustomDomainDashboard && <CookieRow />}
      </div>
    </Section>
  );
};
