import React, { FC } from 'react';

import { User } from '@dynamic-labs/sdk-api';

import CopyButton from '../../../../../components/CopyButton';

export type AccountTabUserFieldProps = {
  copy?: boolean;
  label?: string;
  value?: User[keyof User];
};

const renderValue = (value: User[keyof User]) => {
  if (value instanceof Date) {
    return value.toLocaleString();
  }
  if (typeof value === 'object') {
    return null;
  }
  return value;
};

export const AccountTabUserField: FC<AccountTabUserFieldProps> = ({
  copy,
  label,
  value,
}) => (
  <div className='mb-3'>
    <div className='mb-1'>
      <span className='text-gray-1 text-xs'>{label}</span>
    </div>
    <div className='flex items-center'>
      <span className='text-primary-1 font-medium text-sm'>
        {renderValue(value) || 'Empty'}
      </span>
      {copy && !!value && (
        <CopyButton type='icon' color='gray' textToCopy={value as string} />
      )}
    </div>
  </div>
);
