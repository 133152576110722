/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserSearchFilterParams,
    UserSearchFilterParamsFromJSON,
    UserSearchFilterParamsFromJSONTyped,
    UserSearchFilterParamsToJSON,
} from './UserSearchFilterParams';

/**
 * 
 * @export
 * @interface ExportCreateRequestFilter
 */
export interface ExportCreateRequestFilter {
    /**
     * 
     * @type {UserSearchFilterParams}
     * @memberof ExportCreateRequestFilter
     */
    users?: UserSearchFilterParams;
}

export function ExportCreateRequestFilterFromJSON(json: any): ExportCreateRequestFilter {
    return ExportCreateRequestFilterFromJSONTyped(json, false);
}

export function ExportCreateRequestFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCreateRequestFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'users': !exists(json, 'users') ? undefined : UserSearchFilterParamsFromJSON(json['users']),
    };
}

export function ExportCreateRequestFilterToJSON(value?: ExportCreateRequestFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'users': UserSearchFilterParamsToJSON(value.users),
    };
}

