import { useQuery } from 'react-query';

import { ChainEnum, ChainToken } from '@dynamic-labs/sdk-api';

import { chainsApi } from '../../services/api';

export const useTokensByChain = (chainName: ChainEnum, networkId?: number) => {
  const queryKey = ['chains', chainName, 'tokens', networkId];

  const { data, isLoading } = useQuery<ChainToken[]>(
    queryKey,

    () => chainsApi.getAllTokensByChain({ chainName, networkId }),
    {
      enabled: !!chainName,
    },
  );

  return {
    isLoading,
    tokens: data ?? [],
  };
};
