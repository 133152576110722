import isEqual from 'react-fast-compare';

import { useSettingsContext } from '../../../SettingsContext/index';
import { useEnvironmentsContext } from '../../../EnvironmentsContext';

export const UsePrivacySettings = () => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings, initialSettings, setInitialSettings } =
    useSettingsContext();

  const setCollectIp = (collectIp: string) => {
    if (settings[activeEnvironmentType]?.privacy?.collectIp === undefined)
      return;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        privacy: {
          ...settings[activeEnvironmentType].privacy,
          collectIp: collectIp === 'true',
        },
      },
    });
  };

  const settingsHasChanged = !isEqual(
    initialSettings[activeEnvironmentType].privacy,
    settings[activeEnvironmentType].privacy,
  );

  const updateInitialSettings = () => {
    setInitialSettings(settings);
  };

  const cancelChanges = () => {
    setSettings(initialSettings);
  };

  return {
    cancelChanges,
    globalSettings: settings,
    setCollectIp,
    settings: settings?.[activeEnvironmentType].privacy,
    settingsHasChanged,
    updateInitialSettings,
  };
};
