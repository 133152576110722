import { useQuery } from 'react-query';

import { settingsApi } from '../../services/api';

export const useProvidersRedirectUrlsQuery = (
  environmentId: string | undefined,
) =>
  useQuery(
    ['environments', environmentId, 'providers', 'redirectUrls'],
    () =>
      settingsApi.getEnvironmentProviderUrls({
        environmentId: environmentId ?? '',
      }),
    {
      enabled: !!environmentId,
      refetchOnWindowFocus: false,
    },
  );
