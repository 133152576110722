/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserPasskey,
    UserPasskeyFromJSON,
    UserPasskeyFromJSONTyped,
    UserPasskeyToJSON,
} from './UserPasskey';

/**
 * 
 * @export
 * @interface GetUserPasskeysResponse
 */
export interface GetUserPasskeysResponse {
    /**
     * The passkeys belonging to the user
     * @type {Array<UserPasskey>}
     * @memberof GetUserPasskeysResponse
     */
    passkeys: Array<UserPasskey>;
    /**
     * 
     * @type {number}
     * @memberof GetUserPasskeysResponse
     */
    count: number;
}

export function GetUserPasskeysResponseFromJSON(json: any): GetUserPasskeysResponse {
    return GetUserPasskeysResponseFromJSONTyped(json, false);
}

export function GetUserPasskeysResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserPasskeysResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passkeys': ((json['passkeys'] as Array<any>).map(UserPasskeyFromJSON)),
        'count': json['count'],
    };
}

export function GetUserPasskeysResponseToJSON(value?: GetUserPasskeysResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passkeys': ((value.passkeys as Array<any>).map(UserPasskeyToJSON)),
        'count': value.count,
    };
}

