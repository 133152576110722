import { FC } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Button, TextButton, Typography } from '@dynamic-labs/northstar';

import Portal from '../../../app/components/Portal';
import Header from '../../../app/components/Portal/Header';

import styles from './MissingSettingsModal.module.scss';

type MissingSettingsModalProps = {
  description: string;
  handleClose: VoidFunction;
  missingSettings: {
    action: VoidFunction;
    i18nKeyActionText: string;
    i18nKeyText: string;
  }[];
  title: string;
};

export const MissingSettingsModal: FC<MissingSettingsModalProps> = ({
  handleClose,
  missingSettings,
  title,
  description,
}) => {
  const { t } = useTranslation();

  const handleActionClick = (action: VoidFunction) => {
    action();
    handleClose();
  };

  return (
    <Portal
      handleClose={handleClose}
      className={styles.portal}
      backdropClassName={styles.backdrop}
    >
      <Header handleClose={handleClose} headingText={title} />

      <div className={styles.content}>
        <Typography variant='paragraph-2' weight='regular'>
          {description}
        </Typography>

        <div className={styles.actions}>
          {missingSettings.map(
            ({ action, i18nKeyActionText, i18nKeyText }, index) => (
              <div key={`${i18nKeyText}-${i18nKeyActionText}`}>
                <Typography variant='paragraph-2' weight='bold'>
                  {index + 1}.{' '}
                  <Trans
                    i18nKey={i18nKeyText as any}
                    components={{
                      action: (
                        <TextButton
                          variant='link'
                          text={t(i18nKeyActionText as any)}
                          onClick={() => handleActionClick(action)}
                          color='primary-1'
                        />
                      ),
                    }}
                  />
                </Typography>
              </div>
            ),
          )}
        </div>

        <Button
          text={t(
            'v2.page.embedded_wallets.dynamic_section.missing_settings.close',
          )}
          variant='secondary'
          size='large'
          onClick={handleClose}
        />
      </div>
    </Portal>
  );
};
