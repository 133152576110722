import { Dispatch, SetStateAction, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { EmbeddedWalletVersionEnum } from '@dynamic-labs/sdk-api';
import {
  Button,
  KeyShieldIcon,
  UserPlusIcon,
  Typography,
} from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import Portal from '../../../../../../app/components/Portal';
import Header from '../../../../../../app/components/Portal/Header';
import { logger } from '../../../../../../app/services/logger';

import styles from './DowngradeVersionModal.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type Props = {
  onSubmit: (props: any) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const DowngradeVersionModal = ({ onSubmit, setShowModal }: Props) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings } = useSettingsContext();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (!activeEnvironmentType) return;
    setIsSubmitting(true);

    try {
      onSubmit({
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          sdk: {
            ...settings[activeEnvironmentType].sdk,
            embeddedWallets: {
              ...settings[activeEnvironmentType].sdk.embeddedWallets,
              defaultWalletVersion: EmbeddedWalletVersionEnum.V1,
            },
          },
        },
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
      setShowModal(false);
    }
  };

  const renderSection = (title: string, description: string) => (
    <>
      <div className={styles.iconTextWrapper}>
        <UserPlusIcon />
        <Typography variant='paragraph-3' className={styles.sectionText}>
          {title}
        </Typography>
      </div>
      <Typography variant='paragraph-3' className={styles.sectionParagraph}>
        {description}
      </Typography>
    </>
  );

  return (
    <Portal
      backdropClassName='!z-30'
      handleClose={() => setShowModal(false)}
      className={styles.portal}
    >
      <Header
        handleClose={() => setShowModal(false)}
        headingText=''
        dataTestId='downgrade-header'
      />
      <div className={styles.hero} data-testid='hero'>
        <KeyShieldIcon />
      </div>
      <Typography variant='title' className={styles.title}>
        {t('v2.page.embedded_wallets.downgrade_modal.title')}
      </Typography>
      <Typography className={styles.paragraph}>
        {t('v2.page.embedded_wallets.downgrade_modal.description')}{' '}
      </Typography>
      {renderSection(
        t('v2.page.embedded_wallets.downgrade_modal.section_1_title'),
        t('v2.page.embedded_wallets.downgrade_modal.section_1_description'),
      )}
      {renderSection(
        t('v2.page.embedded_wallets.downgrade_modal.section_2_title'),
        t('v2.page.embedded_wallets.downgrade_modal.section_2_description'),
      )}
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.buttonConfirm}
          onClick={handleSubmit}
          type='submit'
          text={t('v2.page.embedded_wallets.downgrade_modal.downgrade_button')}
          size='large'
          disabled={isSubmitting}
          dataTestId='downgrade-button'
        />
      </div>
    </Portal>
  );
};
