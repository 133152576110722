/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TokenCreatedBy,
    TokenCreatedByFromJSON,
    TokenCreatedByFromJSONTyped,
    TokenCreatedByToJSON,
} from './TokenCreatedBy';
import {
    TokenWithRawProjectEnvironment,
    TokenWithRawProjectEnvironmentFromJSON,
    TokenWithRawProjectEnvironmentFromJSONTyped,
    TokenWithRawProjectEnvironmentToJSON,
} from './TokenWithRawProjectEnvironment';

/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    id: string;
    /**
     * A 6-character alpha-numeric string checksum of the random API Token. The last 6 characters of the raw token
     * @type {string}
     * @memberof Token
     */
    checksum: string;
    /**
     * 
     * @type {TokenCreatedBy}
     * @memberof Token
     */
    createdBy: TokenCreatedBy;
    /**
     * 
     * @type {Date}
     * @memberof Token
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    projectEnvironmentId: string;
    /**
     * 
     * @type {TokenWithRawProjectEnvironment}
     * @memberof Token
     */
    projectEnvironment: TokenWithRawProjectEnvironment;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'checksum': json['checksum'],
        'createdBy': TokenCreatedByFromJSON(json['createdBy']),
        'createdAt': (new Date(json['createdAt'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'projectEnvironmentId': json['projectEnvironmentId'],
        'projectEnvironment': TokenWithRawProjectEnvironmentFromJSON(json['projectEnvironment']),
    };
}

export function TokenToJSON(value?: Token | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'checksum': value.checksum,
        'createdBy': TokenCreatedByToJSON(value.createdBy),
        'createdAt': (value.createdAt.toISOString()),
        'note': value.note,
        'projectEnvironmentId': value.projectEnvironmentId,
        'projectEnvironment': TokenWithRawProjectEnvironmentToJSON(value.projectEnvironment),
    };
}

