import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';

type SupportedDesignSettings =
  | 'splitEmailAndSocial'
  | 'socialAboveEmail'
  | 'emailSubmitButtonInsideInput'
  | 'showWalletsButton';

type DesignSettingToggleProps = {
  disabled: boolean;
  setting: SupportedDesignSettings;
};

export const DesignSettingToggle: FC<DesignSettingToggleProps> = ({
  disabled,
  setting,
}) => {
  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isSettingEnabled = Boolean(
    settings[activeEnvironmentType].design?.modal?.[setting],
  );
  const initialIsSettingEnabled = Boolean(
    initialSettings[activeEnvironmentType].design?.modal?.[setting],
  );
  const isSettingSaved = isSettingEnabled === initialIsSettingEnabled;

  const handleToggleSetting = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            [setting]: e.target.checked,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      accordionKey={`design-setting-${setting}`}
      title={t(`v2.page.log_in_methods.design.${setting}.title`)}
      description={t(`v2.page.log_in_methods.design.${setting}.description`)}
      allowExpand={false}
      inputProps={{
        checked: isSettingEnabled && !disabled,
        disabled,
        id: `design-setting-${setting}`,
        isSaved: isSettingSaved,
        onChange: handleToggleSetting,
        type: 'toggle',
      }}
    />
  );
};
