import { ChangeEventHandler } from 'react';

import classNames from 'classnames';

import { CheckArrow, LockIcon, RefreshIcon } from '../../../icons';
import { Tooltip } from '../Tooltip';

import styles from './toggle.module.css';

type ToggleVariant = 'primary' | 'success' | 'locked';
type Props = {
  ariaLabel: string;
  checked: boolean;
  className?: string;
  disabled?: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  handleDisabledClick?: VoidFunction;
  id: string;
  tooltipDisableHover?: boolean;
  tooltipText?: string[];
  variant?: ToggleVariant;
  withIcon?: boolean;
};

export const Toggle = ({
  id,
  checked,
  handleChange,
  className,
  ariaLabel,
  variant = 'primary',
  withIcon = false,
  disabled = false,
  handleDisabledClick,
  tooltipText = [],
  tooltipDisableHover = false,
}: Props) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (disabled) return;
    handleChange(e);
  };

  const getIcon = () => {
    if (!withIcon) return null;

    if (variant === 'locked') return LockIcon;
    if (variant === 'success') return CheckArrow;
    return RefreshIcon;
  };

  const Icon = getIcon();

  const disabledClass = disabled ? styles.disabled : '';

  return (
    <Tooltip
      tooltipText={tooltipText}
      disableHover={tooltipDisableHover || tooltipText.length === 0}
      placement='top'
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => disabled && handleDisabledClick && handleDisabledClick()}
        className={classNames(styles.container, styles[variant], className)}
        data-testid='toggle'
      >
        <label className={classNames(styles.label, disabledClass)} htmlFor={id}>
          <input
            role='switch'
            type='checkbox'
            disabled={disabled}
            onChange={onChange}
            className={styles.input}
            id={id}
            aria-label={ariaLabel}
            aria-checked={checked}
            checked={checked}
            data-testid={`toggle-input-${id}`}
          />
          <span className={styles.switch} />
          <span className={styles.handle}>
            {!!Icon && (
              <Icon
                className={classNames(styles.icon, {
                  [styles['icon--visible']]: checked || variant === 'locked',
                })}
              />
            )}
          </span>
        </label>
      </div>
    </Tooltip>
  );
};

Toggle.defaultProps = {
  className: '',
};
