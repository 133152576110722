import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section } from '@dynamic-labs/northstar';

import { Chip } from '../../../../../../app/components/Chip';

import { SecurityMethodToggle } from './SecurityMethodToggle';
import styles from './MFARow.module.scss';

type MFARowProps = {
  disabled: boolean;
  showV1Badge?: boolean;
};

export const MFARow: FC<MFARowProps> = ({ disabled, showV1Badge }) => {
  const { t } = useTranslation();

  const title = (
    <div className={styles.title}>
      {t('v2.page.embedded_wallets.dynamic_section.mfa.title')}
      {showV1Badge && (
        <Chip
          label={t('v2.page.embedded_wallets.dynamic_section.mfa.v1_badge')}
          color='grey'
          small
          className={styles.chip}
        />
      )}
    </div>
  );
  return (
    <Section
      title={title}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.mfa.description',
      )}
      alertsAlignment='top'
    >
      <div className={styles.wrapper}>
        <SecurityMethodToggle method='passkey' disabled={disabled} />
        <SecurityMethodToggle method='email' disabled={disabled} />
      </div>
    </Section>
  );
};
