import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translation from './locales/en/translation.json';

export const defaultNS = 'translation';
export const resources = {
  en: {
    translation,
  },
} as const;

i18n.use(initReactI18next).init({
  defaultNS,
  lng: 'en',
  ns: ['translation'],
  resources,
});
