import classNames from 'classnames';

import { TabComponent } from './Tab.types';
import styles from './Tab.module.css';

export const Tab: TabComponent = ({
  className,
  children,
  isActive,
  onClick,
  onKeyDown,
  tabIndex = -1,
  dataTestId,
}) => (
  <div
    data-testid={dataTestId}
    role='button'
    tabIndex={tabIndex}
    onClick={onClick}
    onKeyDown={onKeyDown}
    className={classNames(className, styles.container, {
      [styles.active]: isActive,
    })}
  >
    <div className={styles['inner-container']}>{children}</div>
  </div>
);
