import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { ConfirmModal } from '../../../../../components/ConfirmModal';

type Props = {
  onClickCancelChanges: VoidFunction;
  setShowMagicEmbeddedWalletsWarning: Dispatch<SetStateAction<boolean>>;
  walletsAmount: {
    coinbaseWaas: number;
    magic: number;
    turnkey: number;
  };
};

export const ExistingWalletsWarningModal = ({
  setShowMagicEmbeddedWalletsWarning,
  onClickCancelChanges,
  walletsAmount,
}: Props) => {
  const { t } = useTranslation();
  const getMessage = () =>
    t(
      'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.content',
    )
      .toString()
      .replace('{{value}}', walletsAmount.magic.toString())
      .replace(
        '{{customer_text}}',
        walletsAmount.magic > 1
          ? t(
              'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.customer_text.other',
            )
          : t(
              'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.customer_text.one',
            ),
      )
      .replace(
        '{{wallet_text}}',
        walletsAmount.magic > 1
          ? t(
              'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.wallet_text.other',
            )
          : t(
              'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.wallet_text.one',
            ),
      );
  return (
    <ConfirmModal
      cancelText={t(
        'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.cancel',
      )}
      message={getMessage()}
      onCancel={() => {
        setShowMagicEmbeddedWalletsWarning(false);
        onClickCancelChanges();
      }}
      className='!z-[32]'
      backdropClassName='!z-[31]'
      title={t(
        'integrations.wallets.providers.existing_wallets_magic_embeddedWallets_warning.title',
      )}
    />
  );
};
