import { useMemo } from 'react';

import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';

export const useEnvironmentId = () => {
  const { activeEnvironmentType, environments } = useEnvironmentsContext();

  return useMemo(
    () => environments?.[activeEnvironmentType]?.id,
    [environments, activeEnvironmentType],
  );
};
