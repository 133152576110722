import { t } from 'i18next';

export const viewOptions = [
  {
    key: 'wallet-list',
    name: t('design_page.modal.views.walletList'),
  },
  {
    key: 'pending-signature',
    name: t('design_page.modal.views.noQrNotInstalledView'),
  },
  {
    key: 'qr-code',
    name: t('design_page.modal.views.qrCodeView'),
  },
  {
    key: 'collect-user-data',
    name: t('design_page.modal.views.collectUserDataView'),
  },
];

export const themeOptions = [
  {
    key: 'light',
    name: t('design_page.modal.settings.light'),
  },
  {
    key: 'dark',
    name: t('design_page.modal.settings.dark'),
  },
];

export const borderOptions = [
  {
    key: 'rounded',
    name: t('design_page.modal.settings.rounded'),
  },
  {
    key: 'square',
    name: t('design_page.modal.settings.square'),
  },
];

export const brandTypeOptions = [
  {
    key: 'subtle',
    name: t('design_page.modal.settings.subtle'),
  },
  {
    key: 'bold',
    name: t('design_page.modal.settings.bold'),
  },
];
