/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostAllowlistEntriesRequest
 */
export interface PostAllowlistEntriesRequest {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    walletPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    discordUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    twitterUsername?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistEntriesRequest
     */
    farcasterUsername?: string;
    /**
     * 
     * @type {number}
     * @memberof PostAllowlistEntriesRequest
     */
    farcasterFid?: number;
}

export function PostAllowlistEntriesRequestFromJSON(json: any): PostAllowlistEntriesRequest {
    return PostAllowlistEntriesRequestFromJSONTyped(json, false);
}

export function PostAllowlistEntriesRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAllowlistEntriesRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'discordUsername': !exists(json, 'discordUsername') ? undefined : json['discordUsername'],
        'twitterUsername': !exists(json, 'twitterUsername') ? undefined : json['twitterUsername'],
        'farcasterUsername': !exists(json, 'farcasterUsername') ? undefined : json['farcasterUsername'],
        'farcasterFid': !exists(json, 'farcasterFid') ? undefined : json['farcasterFid'],
    };
}

export function PostAllowlistEntriesRequestToJSON(value?: PostAllowlistEntriesRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletPublicKey': value.walletPublicKey,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'alias': value.alias,
        'discordUsername': value.discordUsername,
        'twitterUsername': value.twitterUsername,
        'farcasterUsername': value.farcasterUsername,
        'farcasterFid': value.farcasterFid,
    };
}

