import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

type UseChangeElementReturnValue<T> = [
  T | undefined,
  Dispatch<SetStateAction<T | undefined>>,
];

// Hook for elements that needs to have an undefined between states
// ie. changing between icons smooth in BooleanInput
// eslint-disable-next-line @typescript-eslint/comma-dangle
export const useChangeElement = <T,>(
  initialElement: T,
  timeout: number,
): UseChangeElementReturnValue<T> => {
  const [element, setElement] = useState<T | undefined>(initialElement);

  const newElement = initialElement;

  // If we want a smooth transition between element, we need to remove the old icon
  // and after time longer than animation duration add the new one
  const changeItem = useCallback(() => {
    if (element && element !== newElement) {
      setElement(undefined);
      setTimeout(() => {
        setElement(newElement);
      }, timeout); // longer than the animation duration
      return;
    }

    setElement(newElement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newElement]); // Don't add element to the dependencies, it will cause infinite loop

  useEffect(() => {
    if (initialElement !== element) {
      changeItem();
    }
  }, [changeItem, element, initialElement]);

  return [element, setElement];
};
