import { CSSProperties, FC, PropsWithChildren } from 'react';

interface Props {
  className?: string;
  style?: CSSProperties;
}

export const DropdownFooter: FC<PropsWithChildren<Props>> = ({
  children,
  className = '',
  style,
}) => (
  <div className={`px-2 ${className}`} style={style}>
    {children}
  </div>
);
