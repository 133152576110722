import React, { useEffect, useState } from 'react';

import Button from '../../components/Button';
import { CopyIcon } from '../../../icons';

import styles from './coinbase-waas-key-export.module.css';

export const CoinbaseWaasKeyExport = () => {
  const [key, setKey] = useState('');
  const [copied, setCopied] = useState(false);
  const handleMessage = (data: any) => {
    if (data.data.type === 'export_key') {
      setKey(data.data.key);
    }
  };

  const copyToClipboard = () => {
    if (!key) {
      return;
    }
    navigator.clipboard.writeText(key);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  return (
    <>
      <div
        className={styles['key-export']}
        data-testid='coinbase-waas-key-export'
      >
        {key}
      </div>
      <Button
        className={styles['key-export-copy']}
        onClick={copyToClipboard}
        variant='secondary'
        large
        leading={<CopyIcon />}
        testId='coinbase-waas-key-export-copy-button'
      >
        {copied ? 'Copied' : 'Copy to clipboard'}
      </Button>
    </>
  );
};
