/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsDesignButton
 */
export interface ProjectSettingsDesignButton {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignButton
     */
    background?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsDesignButton
     */
    fontColor?: string;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingsDesignButton
     */
    paddingHeight?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingsDesignButton
     */
    paddingWidth?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSettingsDesignButton
     */
    radius?: number;
}

export function ProjectSettingsDesignButtonFromJSON(json: any): ProjectSettingsDesignButton {
    return ProjectSettingsDesignButtonFromJSONTyped(json, false);
}

export function ProjectSettingsDesignButtonFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsDesignButton {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'background': !exists(json, 'background') ? undefined : json['background'],
        'fontColor': !exists(json, 'fontColor') ? undefined : json['fontColor'],
        'paddingHeight': !exists(json, 'paddingHeight') ? undefined : json['paddingHeight'],
        'paddingWidth': !exists(json, 'paddingWidth') ? undefined : json['paddingWidth'],
        'radius': !exists(json, 'radius') ? undefined : json['radius'],
    };
}

export function ProjectSettingsDesignButtonToJSON(value?: ProjectSettingsDesignButton | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'background': value.background,
        'fontColor': value.fontColor,
        'paddingHeight': value.paddingHeight,
        'paddingWidth': value.paddingWidth,
        'radius': value.radius,
    };
}

