import { Dispatch, SetStateAction, useState } from 'react';

import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';
import { SubscriptionAdvancedScopeEnum } from '@dynamic-labs/sdk-api';

import { useCustomFieldsContext } from '../../../../../app/context/CustomFieldsContext';
import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../app/context/SettingsContext';
import { useProvidersContext } from '../../../../../app/context/ProvidersContext';
import { enforceProviderRules } from '../../../../../app/utils/enforceProviderRules';
import { useSubscriptionLock } from '../../../../../app/hooks/useSubscriptionLock';
import { useSaveProvidersSettings } from '../../../../hooks';
import { ApiError } from '../../../../components';

import {
  useGetMissingSettingsForLogInMethods,
  type MissingSettingsType,
} from './useGetMissingSettingsForLogInMethods';
import { useGetFormattedApiErrors } from './useGetFormattedApiErrors';

export type UseSaveLoginMethodsReturnType = {
  apiErrors: ApiError[];
  clearErrors: VoidFunction;
  handleReset: VoidFunction;
  handleSave: VoidFunction;
  isLoading: boolean;
  missingSettings: MissingSettingsType[];
  setShouldShowMissingSettingsWarning: Dispatch<SetStateAction<boolean>>;
  shouldShowMissingSettingsWarning: boolean;
  shouldShowSaveBanner: boolean;
};

export const useSaveLoginMethods = (): UseSaveLoginMethodsReturnType => {
  const [
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
  ] = useState(false);

  const { activeEnvironmentType, activeEnvironment } = useEnvironmentsContext();

  const {
    settings,
    settingsHasChangedForActiveEnv: projectSettingsHasChanged,
    updateInitialSettingsForActiveEnv,
    cancelChangesForActiveEnv,
  } = useSettingsContext();

  const {
    hasChanges: hasAnyProviderChanged,
    resetProviderState,
    changedProviders,
  } = useProvidersContext();

  const {
    saveChanges: saveCustomFields,
    settingsHasChanged: customFieldsChanged,
    cancelChanges: cancelCustomFields,
    isLoading: customLoading,
  } = useCustomFieldsContext();

  const { mutate: saveProjectSettings, isLoading: isSavingProjectSettings } =
    useUpdateProjectSettings({
      onSuccess: () => {
        updateInitialSettingsForActiveEnv();
      },
    });

  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );

  const {
    saveProviders,
    isLoading: isSavingProviderSettings,
    errors,
    clearErrors,
  } = useSaveProvidersSettings();

  const handleReset = () => {
    cancelChangesForActiveEnv();
    resetProviderState();
    cancelCustomFields();
  };

  const handleSave = () => {
    if (!shouldLockOnLive) {
      if (missingSettings.length > 0) {
        setShouldShowMissingSettingsWarning(true);
        return;
      }

      if (projectSettingsHasChanged) {
        saveProjectSettings({
          environmentId: activeEnvironment?.id || '',
          projectSettings: settings[activeEnvironmentType],
        });
      }

      if (customFieldsChanged) {
        saveCustomFields();
      }

      if (hasAnyProviderChanged) {
        saveProviders({
          providersToSave: enforceProviderRules(changedProviders),
        });
      }
    }
  };

  const missingSettings = useGetMissingSettingsForLogInMethods();

  const apiErrors = useGetFormattedApiErrors(errors);

  const isLoading =
    isSavingProjectSettings || isSavingProviderSettings || customLoading;

  const shouldShowSaveBanner =
    hasAnyProviderChanged || projectSettingsHasChanged || customFieldsChanged;

  return {
    apiErrors,
    clearErrors,
    handleReset,
    handleSave,
    isLoading,
    missingSettings,
    setShouldShowMissingSettingsWarning,
    shouldShowMissingSettingsWarning,
    shouldShowSaveBanner,
  };
};
