import { endOfDay, startOfDay, sub } from 'date-fns';

import { ValidDateSelection } from '../../Analytics.types';

export const createDateSelectionForPastXDays = (
  key: string,
  duration: {
    days?: number;
    years?: number;
  },
  initialDate: Date = new Date(),
): ValidDateSelection => ({
  end: endOfDay(initialDate),
  key,
  start: startOfDay(sub(initialDate, duration)),
});
