import { useMemo } from 'react';

import merge from 'deepmerge';

export const defaultChartOptions = {
  elements: {
    bar: {
      borderRadius: 100,
    },
  },
  plugins: {
    legend: {
      position: 'bottom',
    },
    tooltip: {
      backgroundColor: 'rgba(59, 61, 71, 1)',
      callbacks: {
        label: (tooltipItem: { formattedValue: string }): string =>
          tooltipItem.formattedValue,
        title: () => '',
      },
      displayColors: false,
    },
  },
  responsive: true,
  scale: {
    grid: {
      color: 'rgba(233, 236, 242, 1)',
    },
  },
};

export const useDefaultChartOptions = <T>(overrideOptions?: T): T =>
  useMemo(
    () => merge(defaultChartOptions, overrideOptions || {}) as unknown as T,
    [overrideOptions],
  );
