/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomFieldType,
    CustomFieldTypeFromJSON,
    CustomFieldTypeFromJSONTyped,
    CustomFieldTypeToJSON,
} from './CustomFieldType';
import {
    CustomFieldValidationRules,
    CustomFieldValidationRulesFromJSON,
    CustomFieldValidationRulesFromJSONTyped,
    CustomFieldValidationRulesToJSON,
} from './CustomFieldValidationRules';

/**
 * 
 * @export
 * @interface CustomFieldRequest
 */
export interface CustomFieldRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldRequest
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof CustomFieldRequest
     */
    enabled: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CustomFieldRequest
     */
    required: boolean;
    /**
     * 
     * @type {CustomFieldType}
     * @memberof CustomFieldRequest
     */
    fieldType: CustomFieldType;
    /**
     * 
     * @type {CustomFieldValidationRules}
     * @memberof CustomFieldRequest
     */
    validationRules?: CustomFieldValidationRules;
    /**
     * 
     * @type {number}
     * @memberof CustomFieldRequest
     */
    position: number;
}

export function CustomFieldRequestFromJSON(json: any): CustomFieldRequest {
    return CustomFieldRequestFromJSONTyped(json, false);
}

export function CustomFieldRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'enabled': json['enabled'],
        'required': json['required'],
        'fieldType': CustomFieldTypeFromJSON(json['fieldType']),
        'validationRules': !exists(json, 'validationRules') ? undefined : CustomFieldValidationRulesFromJSON(json['validationRules']),
        'position': json['position'],
    };
}

export function CustomFieldRequestToJSON(value?: CustomFieldRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'enabled': value.enabled,
        'required': value.required,
        'fieldType': CustomFieldTypeToJSON(value.fieldType),
        'validationRules': CustomFieldValidationRulesToJSON(value.validationRules),
        'position': value.position,
    };
}

