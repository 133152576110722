/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';

/**
 * 
 * @export
 * @interface EmailProviderResponse
 */
export interface EmailProviderResponse {
    /**
     * 
     * @type {ProviderEnum}
     * @memberof EmailProviderResponse
     */
    emailProvider: ProviderEnum;
}

export function EmailProviderResponseFromJSON(json: any): EmailProviderResponse {
    return EmailProviderResponseFromJSONTyped(json, false);
}

export function EmailProviderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailProviderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'emailProvider': ProviderEnumFromJSON(json['emailProvider']),
    };
}

export function EmailProviderResponseToJSON(value?: EmailProviderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'emailProvider': ProviderEnumToJSON(value.emailProvider),
    };
}

