import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Typography, TrashIcon, Button } from '@dynamic-labs/northstar';
import {
  CustomFieldRequest,
  CustomFieldValidValue,
} from '@dynamic-labs/sdk-api';

import styles from './DropdownCustomField.module.scss';

type DropdownCustomFieldProps = {
  activateErrors: boolean;
  disabled: boolean;
  field: CustomFieldRequest;
  onOptionsChange: (options: CustomFieldValidValue[]) => void;
};

export const DropdownCustomField: FC<DropdownCustomFieldProps> = ({
  field,
  disabled,
  onOptionsChange,
  activateErrors,
}) => {
  const { t } = useTranslation();

  const handleOptionChange = (index: number, value: string) => {
    const updatedOptions =
      field.validationRules?.validOptions?.map((option, i) =>
        i === index ? { ...option, label: value } : option,
      ) || [];
    onOptionsChange(updatedOptions);
  };

  const addOption = () => {
    const updatedOptions = [
      ...(field.validationRules?.validOptions || []),
      { key: Date.now().toString(), label: '' },
    ];
    onOptionsChange(updatedOptions);
  };

  const removeOption = (index: number) => {
    const updatedOptions =
      field.validationRules?.validOptions?.filter((_, i) => i !== index) || [];
    onOptionsChange(updatedOptions);
  };

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-3' weight='medium' color='gray-1'>
        {t(
          'v2.page.log_in_methods.kyc.custom_fields.side_drawer.dropdown.title',
        )}
      </Typography>
      {field.validationRules?.validOptions?.map((option, index) => (
        <div key={option.key || `${index}-${option.label}`}>
          <Input
            name={`option-${index}`}
            label={`Option ${index + 1}`}
            value={option.label}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            disabled={disabled}
            Icon={<TrashIcon />}
            iconAction={() => removeOption(index)}
            error={
              activateErrors &&
              (option.label === '' || (option.label === '') === undefined)
            }
          />
        </div>
      ))}
      <div className={styles.add_button}>
        <Button
          dataTestId='add_new_option'
          variant='secondary'
          size='medium'
          text={t(
            'v2.page.log_in_methods.kyc.custom_fields.side_drawer.dropdown.add',
          )}
          onClick={addOption}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
