import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { InfoCircleIcon, Section } from '@dynamic-labs/northstar';

import styles from './DesignSection.module.scss';
import { OptimizedForToggle } from './OptimizedForToggle';
import { DesignSettingToggle } from './DesignSettingToggle';
import { SkipOptionalFieldsToggle } from './SkipOptionalFieldsToggle';
import { MultiAsset } from './MultiAsset';

type DesignSectionProps = {
  disabled: boolean;
};

const SDK_VIEWS_DOCS =
  'https://docs.dynamic.xyz/react-sdk/objects/views#supported-views';

export const DesignSection: FC<DesignSectionProps> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('v2.page.log_in_methods.design.title')}
      description={t('v2.page.log_in_methods.design.description')}
      alerts={[
        {
          IconOverride: <InfoCircleIcon />,
          action: {
            as: 'a',
            href: SDK_VIEWS_DOCS,
            text: t('v2.page.log_in_methods.design.docs.action'),
          },
          title: t('v2.page.log_in_methods.design.docs.title'),
          variant: 'advance',
        },
      ]}
      alertsAlignment='top'
    >
      <div className={styles.section}>
        <SkipOptionalFieldsToggle disabled={disabled} />

        <MultiAsset disabled={disabled} />

        <OptimizedForToggle optimizedFor='web3' disabled={disabled} />

        <OptimizedForToggle optimizedFor='web2' disabled={disabled} />

        <DesignSettingToggle
          setting='splitEmailAndSocial'
          disabled={disabled}
        />

        <DesignSettingToggle setting='socialAboveEmail' disabled={disabled} />

        <DesignSettingToggle
          setting='emailSubmitButtonInsideInput'
          disabled={disabled}
        />

        <DesignSettingToggle setting='showWalletsButton' disabled={disabled} />
      </div>
    </Section>
  );
};
