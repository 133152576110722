import { useEffect, useRef } from 'react';

import { logger } from '../../services/logger';

// BroadcastChannel only works between windows of the same origin, so we had to create this new route in the same
// origin as the oauth redirect url (dashboard/api) so we can open an iframe in the sdk that points to this
// new route (sdkOauth), and then we can communicate between the iframe and the oauth popup, and the iframe can
// then communicate with the sdk via window.parent.postMessage
// https://linear.app/dynamic-labs/issue/GVTY-17/[frontend]-fix-twitter-linking
export const SDKOAuth = () => {
  const messageSent = useRef(false);

  useEffect(() => {
    const handleAuthRedirectMessage = (event: MessageEvent) => {
      logger.warn('[oauth] handleAuthRedirectMessage', { event });

      const message = event.data;

      logger.warn('[oauth] handleAuthRedirectMessage', {
        alreadySent: messageSent.current,
        message,
      });

      if (message?.type !== 'authorization_response' || messageSent.current) {
        return;
      }

      logger.warn(
        '[oauth] handleAuthRedirectMessage will post message to sdk',
        {
          message,
        },
      );
      window.parent.postMessage(message, '*');
      messageSent.current = true;
    };

    const bc = new BroadcastChannel('sdkOAuthChannel');
    bc.addEventListener('message', handleAuthRedirectMessage);
    logger.warn('[oauth] BroadcastChannel created');

    return () => {
      logger.warn('[oauth] BroadcastChannel closed');
      bc.close();
    };
  }, []);

  return <div data-testid='sdk-oauth' />;
};
