import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Alert,
  CopyIcon,
  EditableTable,
  Typography,
} from '@dynamic-labs/northstar';
import { useDeeplinkUrls } from '@dynamic-labs/redcoast-query';

import { useEnvironmentsContext } from '../../../../../app/context/EnvironmentsContext';

import styles from './DeeplinkUrlTable.module.scss';

export const DeeplinkUrlTable: FC = () => {
  const { t } = useTranslation();

  const { activeEnvironment } = useEnvironmentsContext();

  const { addDeeplink, deeplinkUrls, removeDeeplink, isLoading } =
    useDeeplinkUrls(
      { environmentId: activeEnvironment?.id ?? '' },
      { enabled: Boolean(activeEnvironment?.id) },
    );

  const [showUrlRules, setShowUrlRules] = useState(false);

  const copyUrl = (url: string) => {
    navigator.clipboard.writeText(url);
  };

  return (
    <div className={styles.content}>
      <Typography variant='paragraph-2' weight='bold'>
        {t('v2.page.security.deeplink_url_row.side_drawer.add_url')}
      </Typography>

      <Typography variant='paragraph-2'>
        {t('v2.page.security.deeplink_url_row.side_drawer.add_url_explanation')}
      </Typography>

      <EditableTable
        columnsAndProportions={[
          { label: 'URL', proportion: 0.73 },
          { label: 'Created at', proportion: 0.27 },
        ]}
        items={deeplinkUrls.map(({ createdAt, url, id }) => ({
          'Created at': (
            <Typography variant='paragraph-2' weight='medium'>
              {createdAt.toLocaleDateString()}
            </Typography>
          ),
          URL: (
            <button
              className={styles.url}
              type='button'
              onClick={() => copyUrl(url)}
            >
              <Typography
                variant='paragraph-2'
                weight='medium'
                className={styles.text}
              >
                {url}
              </Typography>

              <CopyIcon className={styles.copy} />
            </button>
          ),
          key: id,
        }))}
        rowHeight={40}
        emptyLabel={t(
          isLoading
            ? 'v2.page.security.deeplink_url_row.side_drawer.empty_url_table_label_loading'
            : 'v2.page.security.deeplink_url_row.side_drawer.empty_url_table_label_default',
        )}
        onAdd={(url) => addDeeplink(url)}
        onDelete={(targetIndex) =>
          removeDeeplink(deeplinkUrls[targetIndex].url)
        }
        addButtonLabel={t(
          'v2.page.security.deeplink_url_row.side_drawer.new_url_button_label',
        )}
        onChangeInputVisibility={setShowUrlRules}
      />

      {showUrlRules && (
        <Alert
          title={t(
            'v2.page.security.deeplink_url_row.side_drawer.deeplink_rules',
          )}
          variant='warning'
        />
      )}
    </div>
  );
};
