/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalAuth
 */
export interface ExternalAuth {
    /**
     * 
     * @type {boolean}
     * @memberof ExternalAuth
     */
    enabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuth
     */
    iss?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuth
     */
    aud?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuth
     */
    jwksUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExternalAuth
     */
    cookieName?: string | null;
}

export function ExternalAuthFromJSON(json: any): ExternalAuth {
    return ExternalAuthFromJSONTyped(json, false);
}

export function ExternalAuthFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalAuth {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'iss': !exists(json, 'iss') ? undefined : json['iss'],
        'aud': !exists(json, 'aud') ? undefined : json['aud'],
        'jwksUrl': !exists(json, 'jwksUrl') ? undefined : json['jwksUrl'],
        'cookieName': !exists(json, 'cookieName') ? undefined : json['cookieName'],
    };
}

export function ExternalAuthToJSON(value?: ExternalAuth | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'iss': value.iss,
        'aud': value.aud,
        'jwksUrl': value.jwksUrl,
        'cookieName': value.cookieName,
    };
}

