import { t } from 'i18next';

import { useDashboardContext } from '../../../../context/DashboardContext';
import CreateProjectForm from '../../../../routes/Dashboard/Forms/CreateProjectForm';
import PopupModal from '../../../PopupModal';
import PopupHeader from '../../../PopupModal/PopupHeader';

import styles from './add-project-modal.module.css';

interface Props {
  organizationId: string;
  setShowModal: (showModal: boolean) => void;
}

const AddProjectModal = ({ setShowModal, organizationId }: Props) => {
  const { setActiveProjectId, userProjects } = useDashboardContext();

  const handleClose = () => setShowModal(false);

  return (
    <PopupModal handleClose={handleClose} className={styles.portal}>
      <PopupHeader
        handleClose={handleClose}
        headingText={t('create_project.title')}
      />
      <CreateProjectForm
        submitText={t('create_project.add')}
        cancelText={t('create_project.cancel')}
        cancelAction={handleClose}
        submitAction={handleClose}
        organizationId={organizationId}
        setActiveProjectId={setActiveProjectId}
        userProjects={userProjects}
      />
    </PopupModal>
  );
};

export default AddProjectModal;
