import Portal from '../../../components/Portal';
import { Integration, ProjectSettingCategory } from '../../../types';
import SubmitSettingsSection from '../SingleChain/SubmitSettingsSection';
import { SideModalHeader } from '../../../components/SideModalHeader';
import { useSettingsHasChanged } from '../../../hooks/useSettingsHasChanged';
import { useGeneralSettings } from '../../../context/SettingsContext/helpers/generalSettings';

type IntegrationModalProps = Omit<Integration, 'category' | 'url'> & {
  category: ProjectSettingCategory;
  children?: React.ReactNode;
  setShowModal: (value: boolean) => void;
  socialProvidersHasChanged?: boolean;
};

export const IntegrationModal = ({
  category,
  children,
  Icon,
  title,
  content,
  setShowModal,
  socialProvidersHasChanged,
}: IntegrationModalProps) => {
  const {
    liveHasChanged: liveHasChangedForCategory,
    sandboxHasChanged: sandboxHasChangedForCategory,
  } = useSettingsHasChanged(category);

  const { settingsHasChanged: generalSettingsHasChanged } =
    useGeneralSettings();

  const preventModalClose =
    liveHasChangedForCategory ||
    sandboxHasChangedForCategory ||
    generalSettingsHasChanged ||
    socialProvidersHasChanged;

  const handleClose = () => {
    if (preventModalClose) {
      return;
    }

    setShowModal(false);
  };

  return (
    <Portal
      handleClose={handleClose}
      className='absolute w-2/3 bg-white h-screen right-0 top-0 px-6 pt-[60px] pb-24'
    >
      <SideModalHeader
        Icon={Icon}
        title={title}
        content={content.full}
        showClose
        onClose={handleClose}
      />
      <SubmitSettingsSection category={category} />

      {children}
    </Portal>
  );
};
