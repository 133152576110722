import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { Chain } from '../../ChainDropdownMenu.types';
import { DropdownMenuRenderValue } from '../../../DropdownMenu/DropdownMenu.types';
import { Typography } from '../../../Typography';
import { Icon } from '../../../Icon';
import { SelectorIcon } from '../../../../icons';
import { chainToIconMap, chainToNameMap } from '../../helpers/chains';

import styles from './ChainDropdownSelectedValueRenderer.module.scss';

export const ChainDropdownSelectedValueRenderer: DropdownMenuRenderValue<
  string,
  Chain
> = (option, dropdownState) => {
  const {
    props: { rawOption },
  } = option;

  const { disabled } = dropdownState;

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--disabled']]: disabled,
      })}
    >
      <AnimatePresence initial={false}>
        {rawOption && (
          <div className={styles.item}>
            <Icon icon={chainToIconMap[rawOption.chainName]} size={20} />

            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {chainToNameMap[rawOption.chainName]}
            </Typography>
          </div>
        )}
      </AnimatePresence>
      <div className={styles.trigger}>
        <Icon icon={<SelectorIcon />} size={20} />
      </div>
    </div>
  );
};
