import {
  getWalletBookWallet,
  WalletIcon,
  useWalletBookContext,
} from '@dynamic-labs/wallet-book';

import { logger } from '../../services/logger';
import { normalizeWalletName } from '../../utils/normalizeWalletName';

interface Props {
  hideName?: boolean;
  name: string;
  suffix?: string;
}

const embeddedWallets = ['turnkey', 'turnkeyhd', 'coinbasempc'];

export const WalletName = ({
  name,
  suffix,
  hideName = false,
}: Props): JSX.Element => {
  const { walletBook } = useWalletBookContext();

  try {
    const walletBookWallet = getWalletBookWallet(walletBook, name);

    // Display 'Dynamic embedded wallet' for embedded wallets
    const walletDisplayName = embeddedWallets.includes(name)
      ? 'Dynamic embedded wallet'
      : walletBookWallet.name;

    return (
      <div className='flex items-center capitalize space-x-1.5'>
        <WalletIcon
          walletKey={normalizeWalletName(name)}
          className='w-5 h-5 ring-2 ring-white rounded-full bg-white'
        />
        {!hideName && (
          <div>
            <span>{walletDisplayName}</span>
            <span className='ml-1'>{suffix}</span>
          </div>
        )}
      </div>
    );
  } catch {
    logger.error('unknown wallet name', name);
    return <div className='flex items-center capitalize'> Unknown </div>;
  }
};
