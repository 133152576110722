import { useMemo, useState, useCallback } from 'react';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { CloseIcon, DropdownIcon } from '../../../icons';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { Alert } from '../Alert';
import Button from '../Button';
import { Spinner } from '../Spinner';
import { ConfirmModal } from '../ConfirmModal';
import { Typography } from '../Typography';
import {
  formatDateToInternationalFormat,
  openWindowUrl,
} from '../../utils/constants/defaults';

import styles from './UpsellBanner.module.css';

declare type Props = {
  className?: string;
  daysLeft?: number;
};

type UpsellBannerContent = {
  allowClose?: boolean;
  buttonOnClick?: () => void;
  buttonText?: string;
  text: string;
};

export const UpsellBanner = ({ daysLeft = 0, className }: Props) => {
  const { t } = useTranslation();
  const {
    upgradeToAdvancePlan,
    isUpgrading,
    subscription,
    checks,
    closedInfoAlertsMap,
    setClosedInfoAlertsMap,
  } = useSubscriptionContext();

  const [isClosedClass, setClosedClass] = useState(
    closedInfoAlertsMap?.includes('upsell-banner'),
  );

  const isLegacySubscription = subscription?.version === '1';

  const subscriptionType = isLegacySubscription
    ? t('upsell_banner.advanced')
    : t('upsell_banner.standard');

  const {
    isAdvancedTrialWithoutPaymentMethod,
    isAdvancedTrialWithPaymentMethod,
    isAdvancedWithoutPaymentMethod,
    isFreeWithPaymentMethod,
  } = checks;

  const { trialEnd, billingPortalAddPaymentMethodUrl } = subscription || {};

  const [showUpgradePlanConfirmModal, setShowUpgradePlanConfirmModal] =
    useState(false);

  /* istanbul ignore next */
  const confirmUpgradePlan = useCallback(async () => {
    await upgradeToAdvancePlan();
    setShowUpgradePlanConfirmModal(false);
  }, [upgradeToAdvancePlan]);

  const handleCloseBanner = () => {
    setClosedInfoAlertsMap([...closedInfoAlertsMap, 'upsell-banner']);
    setClosedClass(true);
  };

  const handleAddPaymentMethod = () =>
    openWindowUrl(billingPortalAddPaymentMethodUrl);

  const getContent = useMemo<UpsellBannerContent>(() => {
    // NoPaymentMethodTrialBanner
    if (isAdvancedTrialWithoutPaymentMethod) {
      const daysLabel =
        daysLeft === 1 ? t('subscription.day') : t('subscription.days');
      const trialDaysLeftMsg = t('upsell_banner.days_left_message', {
        DAYS_LABEL: daysLabel,
        DAYS_LEFT: daysLeft,
        type: subscriptionType,
      });

      const trialDaysLeftOrExpirationMessage =
        daysLeft === 0
          ? t('upsell_banner.expired_trial_period_message', {
              type: subscriptionType,
            })
          : trialDaysLeftMsg;

      return {
        buttonOnClick: handleAddPaymentMethod,
        buttonText: t('upsell_banner.add_payment_method'),
        text: trialDaysLeftOrExpirationMessage,
      };
    }

    // TrialBanner
    if (isAdvancedTrialWithPaymentMethod) {
      const formatBillingPeriodMessage = !trialEnd
        ? t('upsell_banner.billing_period_start_label_without_date')
        : `${t('upsell_banner.billing_period_start_label').replace(
            '{{BILLING_DATE}}',
            formatDateToInternationalFormat(trialEnd),
          )}`;

      return { allowClose: true, text: formatBillingPeriodMessage };
    }

    // ExpiredPaidFeatureBanner
    if (isAdvancedWithoutPaymentMethod) {
      return {
        buttonOnClick: handleAddPaymentMethod,
        buttonText: t('upsell_banner.add_payment_method'),
        text: t('upsell_banner.advanced_feature_without_payment_on_expired'),
      };
    }

    // PaidFeatureBanner
    if (isFreeWithPaymentMethod) {
      return {
        buttonOnClick: () => setShowUpgradePlanConfirmModal(true),
        buttonText: t('upsell_banner.upgrade_plan_link_label'),
        text: t('upsell_banner.advanced_feature'),
      };
    }

    // NoPaymentMethodPaidFeatureBanner
    return {
      buttonOnClick: handleAddPaymentMethod,
      buttonText: t('upsell_banner.add_payment_method'),
      text: t('upsell_banner.advanced_feature_without_payment'),
    };
  }, [
    billingPortalAddPaymentMethodUrl,
    daysLeft,
    isAdvancedTrialWithoutPaymentMethod,
    isAdvancedTrialWithPaymentMethod,
    isAdvancedWithoutPaymentMethod,
    isFreeWithPaymentMethod,
    t,
    trialEnd,
  ]);

  const bannerSeverityClass = daysLeft > 4 ? 'info' : 'warning';
  const bannerClassNames = classNames(
    styles['upsell-banner__container'],
    className,
    {
      [styles['upsell-banner__container--closed']]: isClosedClass,
    },
  );

  return (
    <>
      <Alert severity={bannerSeverityClass} className={bannerClassNames}>
        <div
          className={`${styles['upsell-banner--label']} ${styles[bannerSeverityClass]}`}
          data-testid='upsell-banner-component'
        >
          <Typography
            variant='paragraph-2'
            weight='medium'
            dataTestId='trial-expired-message-container'
          >
            {getContent.text}
          </Typography>
        </div>
        <div className={styles['upsell-banner__actions-container']}>
          {getContent.buttonText && getContent.buttonOnClick && (
            <Button
              variant='link'
              testId='upgrade-plan-btn'
              className={styles['upsell-banner--link']}
              onClick={getContent.buttonOnClick}
              suffix={
                !isUpgrading ? (
                  <DropdownIcon
                    className={styles['upsell-banner--link-icon']}
                  />
                ) : (
                  <Spinner className={styles.spinner} />
                )
              }
              disabled={isUpgrading}
            >
              {getContent.buttonText}
            </Button>
          )}

          {getContent.allowClose && (
            <Button
              variant='link'
              onClick={handleCloseBanner}
              testId='banner-close-btn'
            >
              <CloseIcon />
            </Button>
          )}
        </div>
      </Alert>

      {showUpgradePlanConfirmModal && (
        <div data-testid='confirm-modal'>
          <ConfirmModal
            title={t('subscription.upgrade_modal.title')}
            confirmationText={t('subscription.upgrade_modal.confirmBtn')}
            confirmationBtnVariant='primary'
            cancelText={t('subscription.upgrade_modal.cancelBtn')}
            message={t('subscription.upgrade_modal.content')}
            onConfirm={confirmUpgradePlan}
            onCancel={() => setShowUpgradePlanConfirmModal(false)}
            loading={isUpgrading}
          />
        </div>
      )}
    </>
  );
};
