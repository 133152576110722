import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseCreateProviderHook } from './useCreateProvider.types';

export const useCreateProvider: UseCreateProviderHook = (options) => {
  const { settingsApi } = useRedcoastQueryContext();

  return useMutation(
    ({ environmentId, providerCreateRequest }) =>
      settingsApi.createProvider({
        environmentId,
        providerCreateRequest,
      }),
    options,
  );
};
