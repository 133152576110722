import { FC, PropsWithChildren } from 'react';

import { Section, type SectionProps } from '../../Section/Section';

import styles from './MultiSectionItem.module.scss';

export const MultiSectionItem: FC<
  PropsWithChildren<Omit<SectionProps, 'className'>>
> = ({ children, title, description, help, alerts }) => (
  <Section
    className={styles['section-override']}
    title={title}
    description={description}
    help={help}
    alerts={alerts}
  >
    {children}
  </Section>
);
