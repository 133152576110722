/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOutcomeEnum,
    AccessOutcomeEnumFromJSON,
    AccessOutcomeEnumFromJSONTyped,
    AccessOutcomeEnumToJSON,
} from './AccessOutcomeEnum';
import {
    GateRule,
    GateRuleFromJSON,
    GateRuleFromJSONTyped,
    GateRuleToJSON,
} from './GateRule';

/**
 * 
 * @export
 * @interface Gate
 */
export interface Gate {
    /**
     * 
     * @type {string}
     * @memberof Gate
     */
    id: string;
    /**
     * Human-readable name of the gate. This should be something helpful to organize gates.
     * @type {string}
     * @memberof Gate
     */
    name: string;
    /**
     * String for the resulting scope of the gate. If the rules of the gate apply to the user being verified, then this scope will be surfaced in the JWT. If a scope is not provided for the gate, the default behavior of the gate would be to block access to users that the rules do not currently apply to.
     * @type {string}
     * @memberof Gate
     */
    scope?: string;
    /**
     * If the gate is enabled, then this timestamp will be present.
     * @type {Date}
     * @memberof Gate
     */
    enabledAt?: Date | null;
    /**
     * The rules which will be used to evaluate users being verified. If multiple rules are present in a single gate, then all the rules need to apply for the user to gain the scope defined by the gate.
     * @type {Array<GateRule>}
     * @memberof Gate
     */
    rules: Array<GateRule>;
    /**
     * 
     * @type {AccessOutcomeEnum}
     * @memberof Gate
     */
    outcome: AccessOutcomeEnum;
}

export function GateFromJSON(json: any): Gate {
    return GateFromJSONTyped(json, false);
}

export function GateFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (json['enabledAt'] === null ? null : new Date(json['enabledAt'])),
        'rules': ((json['rules'] as Array<any>).map(GateRuleFromJSON)),
        'outcome': AccessOutcomeEnumFromJSON(json['outcome']),
    };
}

export function GateToJSON(value?: Gate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'scope': value.scope,
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt === null ? null : value.enabledAt.toISOString()),
        'rules': ((value.rules as Array<any>).map(GateRuleToJSON)),
        'outcome': AccessOutcomeEnumToJSON(value.outcome),
    };
}

