import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { Typography } from '../Typography';
import { DropdownMenu } from '../DropdownMenu';
import { EnvironmentDot } from '../EnvironmentDot';
import { capitalize } from '../../utils';
import { OpacityAnimation } from '../../animations';
import { LockOutlineIcon } from '../../icons';
import { Icon } from '../Icon';

import styles from './EnvironmentsDropdown.module.scss';
import { EnvironmentsDropdownProps } from './EnvironmentsDropdown.types';
import {
  EnvironmentsDropdownItemsWrapper,
  EnvironmentsDropdownSelectedValueRenderer,
} from './components';

export const EnvironmentsDropdown: FC<EnvironmentsDropdownProps> = ({
  environments,
  setActiveEnvironmentType,
  activeEnvironmentType,
}) => (
  <AnimatePresence>
    <OpacityAnimation>
      <DropdownMenu
        value={activeEnvironmentType}
        onSelect={(value) => value && setActiveEnvironmentType(value)} // Don't allow undefined value
        options={[
          ...environments.map((environment) => (
            <DropdownMenu.Item
              value={environment.type}
              className={styles.item}
              rawOption={environment}
              disabled={environment.disabled}
              onDisabledClick={environment.onDisabledClick}
              key={environment.type}
            >
              <EnvironmentDot type={environment.type} className={styles.dot} />

              <Typography
                variant='paragraph-2'
                weight='medium'
                className={styles.type}
              >
                {capitalize(environment.type)}
              </Typography>

              {environment.disabled && (
                <Icon
                  icon={<LockOutlineIcon />}
                  size={20}
                  className={styles.icon}
                />
              )}
            </DropdownMenu.Item>
          )),
        ]}
        className={styles.container}
        allowUndefinedValue={false} // Don't allow undefined value
        itemsSideOffset={8}
        selectedValueWrapper={EnvironmentsDropdownSelectedValueRenderer}
        itemsWrapper={EnvironmentsDropdownItemsWrapper}
      />
    </OpacityAnimation>
  </AnimatePresence>
);
