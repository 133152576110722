import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  Project as ProjectDropdown,
  ProjectSubItemsAction,
} from '@dynamic-labs/northstar';
import { RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardContext } from '../../../app/context/DashboardContext';
import { useDashboardRole } from '../../../app/hooks/useDashboardRole';

export const useProjectsDropdown = () => {
  const { t } = useTranslation();
  const {
    activeProjectId,
    setActiveProjectId,
    isLoadingUserProjects,
    userProjects,
  } = useDashboardContext();

  const { checkForAccess } = useDashboardRole();

  const [showRenameProjectModal, setShowRenameProjectModal] = useState<
    string | undefined
  >(undefined);
  const [showCreateProjectModal, setShowCreateProjectModal] =
    useState<boolean>(false);

  const projectActions: ProjectSubItemsAction[] = useMemo(
    () => [
      {
        action: (id) => setShowRenameProjectModal(id),
        // User must have admin access to rename a project
        disabled: !checkForAccess(RoleEnum.Admin),
        text: t('v2.topbar.projects.actions.rename'),
      },
    ],
    [t],
  );

  const dropdownProjects: ProjectDropdown[] = useMemo(
    () =>
      userProjects.map((project) => ({
        id: project.id,
        name: project.name,
      })),
    [userProjects],
  );

  return {
    projectsDropdown: {
      activeProjectId,
      isLoading: isLoadingUserProjects,
      projectActions,
      projects: dropdownProjects,
      projectsDropdownCTA: {
        action: () => setShowCreateProjectModal(true),
        disabled: !checkForAccess(RoleEnum.Admin), // User must have admin access to create a project
        text: t('v2.topbar.projects.actions.create'),
      },
      setActiveProject: setActiveProjectId,
    },
    setShowCreateProjectModal,
    setShowRenameProjectModal,
    showCreateProjectModal,
    showRenameProjectModal,
  };
};
