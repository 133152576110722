/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnvironmentEnum,
    EnvironmentEnumFromJSON,
    EnvironmentEnumFromJSONTyped,
    EnvironmentEnumToJSON,
} from './EnvironmentEnum';
import {
    NetworkConfigurationResponse,
    NetworkConfigurationResponseFromJSON,
    NetworkConfigurationResponseFromJSONTyped,
    NetworkConfigurationResponseToJSON,
} from './NetworkConfigurationResponse';
import {
    ProjectSettingsChains,
    ProjectSettingsChainsFromJSON,
    ProjectSettingsChainsFromJSONTyped,
    ProjectSettingsChainsToJSON,
} from './ProjectSettingsChains';
import {
    ProjectSettingsDesign,
    ProjectSettingsDesignFromJSON,
    ProjectSettingsDesignFromJSONTyped,
    ProjectSettingsDesignToJSON,
} from './ProjectSettingsDesign';
import {
    ProjectSettingsGeneral,
    ProjectSettingsGeneralFromJSON,
    ProjectSettingsGeneralFromJSONTyped,
    ProjectSettingsGeneralToJSON,
} from './ProjectSettingsGeneral';
import {
    ProjectSettingsKyc,
    ProjectSettingsKycFromJSON,
    ProjectSettingsKycFromJSONTyped,
    ProjectSettingsKycToJSON,
} from './ProjectSettingsKyc';
import {
    ProjectSettingsPrivacy,
    ProjectSettingsPrivacyFromJSON,
    ProjectSettingsPrivacyFromJSONTyped,
    ProjectSettingsPrivacyToJSON,
} from './ProjectSettingsPrivacy';
import {
    ProjectSettingsSdk,
    ProjectSettingsSdkFromJSON,
    ProjectSettingsSdkFromJSONTyped,
    ProjectSettingsSdkToJSON,
} from './ProjectSettingsSdk';
import {
    ProjectSettingsSecurity,
    ProjectSettingsSecurityFromJSON,
    ProjectSettingsSecurityFromJSONTyped,
    ProjectSettingsSecurityToJSON,
} from './ProjectSettingsSecurity';
import {
    Provider,
    ProviderFromJSON,
    ProviderFromJSONTyped,
    ProviderToJSON,
} from './Provider';

/**
 * 
 * @export
 * @interface ProjectSettings
 */
export interface ProjectSettings {
    /**
     * 
     * @type {EnvironmentEnum}
     * @memberof ProjectSettings
     */
    environmentName?: EnvironmentEnum;
    /**
     * 
     * @type {Array<ProjectSettingsChains>}
     * @memberof ProjectSettings
     */
    chains: Array<ProjectSettingsChains>;
    /**
     * 
     * @type {Array<ProjectSettingsKyc>}
     * @memberof ProjectSettings
     */
    customFields?: Array<ProjectSettingsKyc>;
    /**
     * 
     * @type {Array<ProjectSettingsKyc>}
     * @memberof ProjectSettings
     */
    kyc: Array<ProjectSettingsKyc>;
    /**
     * 
     * @type {ProjectSettingsDesign}
     * @memberof ProjectSettings
     */
    design: ProjectSettingsDesign;
    /**
     * 
     * @type {ProjectSettingsGeneral}
     * @memberof ProjectSettings
     */
    general: ProjectSettingsGeneral;
    /**
     * 
     * @type {ProjectSettingsPrivacy}
     * @memberof ProjectSettings
     */
    privacy: ProjectSettingsPrivacy;
    /**
     * External integrations like e-mail, social credentials, mpc providers, etc.
     * @type {Array<Provider>}
     * @memberof ProjectSettings
     */
    providers?: Array<Provider>;
    /**
     * 
     * @type {ProjectSettingsSdk}
     * @memberof ProjectSettings
     */
    sdk: ProjectSettingsSdk;
    /**
     * 
     * @type {ProjectSettingsSecurity}
     * @memberof ProjectSettings
     */
    security: ProjectSettingsSecurity;
    /**
     * 
     * @type {Array<NetworkConfigurationResponse>}
     * @memberof ProjectSettings
     */
    networks?: Array<NetworkConfigurationResponse>;
}

export function ProjectSettingsFromJSON(json: any): ProjectSettings {
    return ProjectSettingsFromJSONTyped(json, false);
}

export function ProjectSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'environmentName': !exists(json, 'environmentName') ? undefined : EnvironmentEnumFromJSON(json['environmentName']),
        'chains': ((json['chains'] as Array<any>).map(ProjectSettingsChainsFromJSON)),
        'customFields': !exists(json, 'customFields') ? undefined : ((json['customFields'] as Array<any>).map(ProjectSettingsKycFromJSON)),
        'kyc': ((json['kyc'] as Array<any>).map(ProjectSettingsKycFromJSON)),
        'design': ProjectSettingsDesignFromJSON(json['design']),
        'general': ProjectSettingsGeneralFromJSON(json['general']),
        'privacy': ProjectSettingsPrivacyFromJSON(json['privacy']),
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(ProviderFromJSON)),
        'sdk': ProjectSettingsSdkFromJSON(json['sdk']),
        'security': ProjectSettingsSecurityFromJSON(json['security']),
        'networks': !exists(json, 'networks') ? undefined : ((json['networks'] as Array<any>).map(NetworkConfigurationResponseFromJSON)),
    };
}

export function ProjectSettingsToJSON(value?: ProjectSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'environmentName': EnvironmentEnumToJSON(value.environmentName),
        'chains': ((value.chains as Array<any>).map(ProjectSettingsChainsToJSON)),
        'customFields': value.customFields === undefined ? undefined : ((value.customFields as Array<any>).map(ProjectSettingsKycToJSON)),
        'kyc': ((value.kyc as Array<any>).map(ProjectSettingsKycToJSON)),
        'design': ProjectSettingsDesignToJSON(value.design),
        'general': ProjectSettingsGeneralToJSON(value.general),
        'privacy': ProjectSettingsPrivacyToJSON(value.privacy),
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(ProviderToJSON)),
        'sdk': ProjectSettingsSdkToJSON(value.sdk),
        'security': ProjectSettingsSecurityToJSON(value.security),
        'networks': value.networks === undefined ? undefined : ((value.networks as Array<any>).map(NetworkConfigurationResponseToJSON)),
    };
}

