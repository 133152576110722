/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MemberResponse,
    MemberResponseFromJSON,
    MemberResponseToJSON,
    MemberRoleField,
    MemberRoleFieldFromJSON,
    MemberRoleFieldToJSON,
    OrganizationMembersResponse,
    OrganizationMembersResponseFromJSON,
    OrganizationMembersResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface DeleteMemberByIdRequest {
    memberId: string;
}

export interface GetOrganizationMembersRequest {
    organizationId: string;
}

export interface UpdateMemberRoleRequest {
    memberId: string;
    memberRoleField: MemberRoleField;
}

/**
 * 
 */
export class MembersApi extends runtime.BaseAPI {

    /**
     * Delete user membership from being an admin of an organization
     */
    async deleteMemberByIdRaw(requestParameters: DeleteMemberByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling deleteMemberById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/members/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user membership from being an admin of an organization
     */
    async deleteMemberById(requestParameters: DeleteMemberByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteMemberByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get all users that are admins for an organization
     */
    async getOrganizationMembersRaw(requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationMembersResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/members`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMembersResponseFromJSON(jsonValue));
    }

    /**
     * Get all users that are admins for an organization
     */
    async getOrganizationMembers(requestParameters: GetOrganizationMembersRequest, initOverrides?: RequestInit): Promise<OrganizationMembersResponse> {
        const response = await this.getOrganizationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve the list of project environments that the user is a member of.
     * Get Membership Environment IDs
     */
    async membershipEnvironmentIdsGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/membershipEnvironmentIds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Retrieve the list of project environments that the user is a member of.
     * Get Membership Environment IDs
     */
    async membershipEnvironmentIdsGet(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.membershipEnvironmentIdsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update a given members role
     */
    async updateMemberRoleRaw(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<MemberResponse>> {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling updateMemberRole.');
        }

        if (requestParameters.memberRoleField === null || requestParameters.memberRoleField === undefined) {
            throw new runtime.RequiredError('memberRoleField','Required parameter requestParameters.memberRoleField was null or undefined when calling updateMemberRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/members/{memberId}`.replace(`{${"memberId"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MemberRoleFieldToJSON(requestParameters.memberRoleField),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MemberResponseFromJSON(jsonValue));
    }

    /**
     * Update a given members role
     */
    async updateMemberRole(requestParameters: UpdateMemberRoleRequest, initOverrides?: RequestInit): Promise<MemberResponse> {
        const response = await this.updateMemberRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
