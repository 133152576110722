/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomFieldValidValue
 */
export interface CustomFieldValidValue {
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValidValue
     */
    label: string;
    /**
     * 
     * @type {string}
     * @memberof CustomFieldValidValue
     */
    key?: string;
}

export function CustomFieldValidValueFromJSON(json: any): CustomFieldValidValue {
    return CustomFieldValidValueFromJSONTyped(json, false);
}

export function CustomFieldValidValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldValidValue {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': json['label'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function CustomFieldValidValueToJSON(value?: CustomFieldValidValue | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'key': value.key,
    };
}

