import { FC, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Input, Typography } from '@dynamic-labs/northstar';
import { CustomFieldRequest } from '@dynamic-labs/sdk-api';

import styles from './RegexCustomField.module.scss';

type RegexCustomFieldProps = {
  activateErrors: boolean;
  disabled: boolean;
  field: CustomFieldRequest;
  onRegexChange: (regex: string) => void;
};

export const RegexCustomField: FC<RegexCustomFieldProps> = ({
  field,
  disabled,
  onRegexChange,
  activateErrors,
}) => {
  const { t } = useTranslation();
  const [error, setError] = useState<boolean>(false);

  const validateRegex = (regex: string) => {
    try {
      RegExp(regex);
      setError(false);
    } catch (e) {
      setError(true);
    }
  };
  const handleRegexTextChange = (value: string) => {
    validateRegex(value);
    onRegexChange(value);
  };

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-3' weight='medium' color='gray-1'>
        {t('v2.page.log_in_methods.kyc.custom_fields.side_drawer.regex.title')}
      </Typography>
      <Input
        name='regex'
        label={t(
          'v2.page.log_in_methods.kyc.custom_fields.side_drawer.regex.label',
        )}
        value={field.validationRules?.regex}
        onChange={(e) => handleRegexTextChange(e.target.value)}
        disabled={disabled}
        error={activateErrors && error}
      />
    </div>
  );
};
