import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { DropdownMenu } from '../DropdownMenu';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { TextButton } from '../TextButton';
import { Tag } from '../Tag';
import { ThreeDotsIcon } from '../../icons';
import { Skeleton } from '../Skeleton';
import { OpacityAnimation } from '../../animations';

import styles from './UserOrganizationsDropdown.module.scss';
import {
  UserOrganizationsDropdownItemsWrapper,
  OrganizationLogo,
  UserOrganizationsSelectedValueRenderer,
  UserOrganizationsDropdownSubItemsWrapper,
  CreateOrganizationCTA,
} from './components';
import { UserOrganizationsDropdownProps } from './UserOrganizationsDropdown.types';

export const UserOrganizationsDropdown: FC<UserOrganizationsDropdownProps> = ({
  activeOrganizationId,
  organizations,
  setActiveOrganization,
  organizationActions,
  organizationsDropdownCTA,
  isLoading = false,
}) => {
  const getSubItems = (organizationId: string) =>
    organizationActions
      .filter(
        (action) =>
          !action.disabled ||
          (typeof action.disabled === 'function' &&
            !action.disabled(organizationId)),
      )
      .map((action) => (
        <TextButton
          key={action.text}
          onClick={() =>
            activeOrganizationId && action.action(activeOrganizationId)
          }
          text={action.text}
          variant='button-medium'
          className={styles.subItem}
        />
      ));

  if (isLoading || organizations.length === 0) {
    return <Skeleton className={styles.skeleton} />;
  }

  return (
    <AnimatePresence>
      <OpacityAnimation>
        <DropdownMenu
          value={activeOrganizationId}
          onSelect={(value) => value && setActiveOrganization(value)} // Don't allow undefined value
          options={[
            ...organizations.map((organization) => (
              <DropdownMenu.ItemWithSubItemActions
                value={organization.id}
                className={styles.item}
                subItems={getSubItems(organization.id)}
                subMenuTrigger={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Icon
                    size={20}
                    icon={<ThreeDotsIcon />}
                    className={styles.trigger}
                  />
                }
                rawOption={organization}
                subItemsSideOffset={6}
                subItemsWrapper={UserOrganizationsDropdownSubItemsWrapper}
              >
                <OrganizationLogo
                  name={organization.name}
                  logoUrl={organization.logoUrl}
                />

                <Typography
                  variant='paragraph-2'
                  weight='medium'
                  className={styles.name}
                >
                  {organization.name}
                </Typography>

                {organization.subscriptionPlanType && (
                  <Tag
                    color='gray'
                    size='medium'
                    className={styles.tag}
                    text={organization.subscriptionPlanType}
                  />
                )}
              </DropdownMenu.ItemWithSubItemActions>
            )),
          ]}
          className={styles.container}
          allowUndefinedValue={false} // Don't allow undefined value
          itemsWrapper={UserOrganizationsDropdownItemsWrapper}
          selectedValueWrapper={UserOrganizationsSelectedValueRenderer}
          itemsSideOffset={8}
          dropdownCta={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <CreateOrganizationCTA
              text={organizationsDropdownCTA.text}
              action={organizationsDropdownCTA.action}
              setOpen={organizationsDropdownCTA.setOpen}
            />
          }
        />
      </OpacityAnimation>
    </AnimatePresence>
  );
};
