import { ReactNode } from 'react';

import { IconButton, Typography } from '@dynamic-labs/northstar';

import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';

import styles from './header.module.css';

type Props = {
  Icon?: ReactNode;
  className?: string;
  dataTestId?: string;
  handleClose?: VoidFunction;
  headingText: string;
};

export const Header = ({
  className,
  handleClose,
  headingText,
  Icon,
  dataTestId,
}: Props) => (
  <div className={styles.container} data-testid={dataTestId}>
    {Icon}
    <Typography variant='title' className={className}>
      {headingText}
    </Typography>
    {!!handleClose && (
      <IconButton
        icon={<CloseIcon />}
        size={24}
        onClick={handleClose}
        testId='portal-header-modal-close'
      />
    )}
  </div>
);
