/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebhookMessage
 */
export interface WebhookMessage {
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    deliveryId: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    eventId: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    environmentId: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    webhookId: string;
    /**
     * 
     * @type {string}
     * @memberof WebhookMessage
     */
    status: string;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    deliveredAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    messageCreatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    eventReceivedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    eventCreatedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof WebhookMessage
     */
    deliveryAttempt: number;
    /**
     * 
     * @type {object}
     * @memberof WebhookMessage
     */
    request: object;
    /**
     * 
     * @type {object}
     * @memberof WebhookMessage
     */
    response: object;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    updatedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof WebhookMessage
     */
    createdAt: Date;
}

export function WebhookMessageFromJSON(json: any): WebhookMessage {
    return WebhookMessageFromJSONTyped(json, false);
}

export function WebhookMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebhookMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryId': json['deliveryId'],
        'eventId': json['eventId'],
        'messageId': json['messageId'],
        'environmentId': json['environmentId'],
        'webhookId': json['webhookId'],
        'status': json['status'],
        'deliveredAt': (new Date(json['deliveredAt'])),
        'messageCreatedAt': (new Date(json['messageCreatedAt'])),
        'eventReceivedAt': (new Date(json['eventReceivedAt'])),
        'eventCreatedAt': (new Date(json['eventCreatedAt'])),
        'deliveryAttempt': json['deliveryAttempt'],
        'request': json['request'],
        'response': json['response'],
        'updatedAt': (new Date(json['updatedAt'])),
        'createdAt': (new Date(json['createdAt'])),
    };
}

export function WebhookMessageToJSON(value?: WebhookMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryId': value.deliveryId,
        'eventId': value.eventId,
        'messageId': value.messageId,
        'environmentId': value.environmentId,
        'webhookId': value.webhookId,
        'status': value.status,
        'deliveredAt': (value.deliveredAt.toISOString()),
        'messageCreatedAt': (value.messageCreatedAt.toISOString()),
        'eventReceivedAt': (value.eventReceivedAt.toISOString()),
        'eventCreatedAt': (value.eventCreatedAt.toISOString()),
        'deliveryAttempt': value.deliveryAttempt,
        'request': value.request,
        'response': value.response,
        'updatedAt': (value.updatedAt.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
    };
}

