import { FC, ReactElement } from 'react';

import classNames from 'classnames';

import { Icon } from '../Icon';
import { IconColor } from '../Icon/Icon.types';

import styles from './IconButton.module.scss';

type IconButtonProps = {
  className?: string;
  color?: IconColor;
  disabled?: boolean;
  icon: ReactElement;
  onClick: VoidFunction;
  size?: number;
  testId?: string;
};

export const IconButton: FC<IconButtonProps> = ({
  icon,
  onClick,
  disabled,
  color,
  size,
  className,
  testId,
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    type='button'
    className={classNames(
      {
        [styles.disabled]: disabled,
      },
      className,
    )}
    data-testid={testId}
  >
    <Icon icon={icon} color={color} size={size} />
  </button>
);
