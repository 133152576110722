import React, { useEffect, useState } from 'react';

import styles from './OptionsSwitcher.module.css';

type Option = {
  key: string;
  name: string;
  onClick?: VoidFunction;
};

type Props = {
  className?: string;
  disabled?: boolean;
  initialActiveKey?: string;
  onOptionClick?: (key: string) => void;
  options: Option[];
};

export const OptionsSwitcher = ({
  options,
  onOptionClick,
  initialActiveKey = '',
  className = '',
  disabled = false,
}: Props) => {
  const [activeKey, setActiveKey] = useState(initialActiveKey);

  useEffect(() => {
    setActiveKey(initialActiveKey);
  }, [initialActiveKey]);

  const handleClick = (key: string, onClick: VoidFunction | undefined) => {
    onOptionClick?.(key);
    onClick?.();
    setActiveKey(key);
  };

  return (
    <div
      className={
        disabled
          ? `${styles.containerDisabled}`
          : `${styles.container} ${className}`
      }
    >
      {options.map(({ name, key, onClick }) => (
        <button
          key={disabled ? undefined : key}
          type='button'
          disabled={disabled === true}
          className={
            disabled
              ? ''
              : `${styles.option} ${
                  key === activeKey ? styles['option--active'] : ''
                }`
          }
          onClick={() => handleClick(key, onClick)}
        >
          {name}
        </button>
      ))}
    </div>
  );
};
