import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-namespace
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { Field, getIn, useFormikContext } from 'formik';

import { ProjectSettingsGeneral } from '@dynamic-labs/sdk-api';

import Input from '../../../../components/Input';
import { Typography } from '../../../../components/Typography';
import { DropdownTrigger } from '../../../../components/DropdownTrigger';
import { useGeneralSettings } from '../../../../context/SettingsContext/helpers/generalSettings';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { Apps } from '../../../../../icons';

import styles from './SupportedApps.module.css';

export const supportedApps = {
  discord: {
    displayText: 'Discord',
    icon: <Apps.Discord />,
  },
  helpDesk: {
    displayText: 'HelpDesk',
    icon: <Apps.HelpDesk />,
  },
  slack: {
    displayText: 'Slack',
    icon: <Apps.Slack />,
  },
  twitter: {
    displayText: 'X / Twitter',
    icon: <Apps.Twitter />,
  },
};

export const SupportedApps = () => {
  const { t } = useTranslation();
  const { errors, values, initialValues, touched, handleBlur } =
    useFormikContext<ProjectSettingsGeneral>();
  const { setSpecificSettings, globalSettings } = useGeneralSettings();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const [selectedApp] = Object.keys(
    globalSettings[activeEnvironmentType].general.supportUrls || {},
  );

  const [activeSupportApp, setActiveSupportApp] = useState<
    keyof typeof supportedApps
  >(selectedApp as keyof typeof supportedApps);

  const dropdownTriggerIcon =
    activeSupportApp && supportedApps[activeSupportApp].icon;

  const dropdownTriggerText =
    (activeSupportApp && supportedApps[activeSupportApp].displayText) ||
    t('settings_page.general.selectApps');

  useEffect(() => {
    if (selectedApp) {
      setActiveSupportApp(selectedApp as keyof typeof supportedApps);
    }
  }, [selectedApp]);

  return (
    <>
      <Typography
        className={styles.heading}
        as='h2'
        variant='paragraph-2'
        weight='medium'
      >
        {t('settings_page.general.supportUrlHeading')}
      </Typography>
      <Typography
        className={styles.description}
        as='p'
        variant='paragraph-1'
        weight='medium'
      >
        {t('settings_page.general.supportUrlDescription')}
      </Typography>
      <div className={styles.dropdown}>
        <DropdownMenu.Root>
          <DropdownTrigger>
            {dropdownTriggerIcon}
            {dropdownTriggerText}
          </DropdownTrigger>
          <DropdownMenu.Content
            className={styles.dropdownContent}
            align='start'
            sideOffset={8}
          >
            {Object.keys(supportedApps).map((appName) => {
              const supportedAppName = appName as keyof typeof supportedApps;
              return (
                <DropdownMenu.Item
                  className={styles.dropdownItem}
                  onClick={() => setActiveSupportApp(supportedAppName)}
                  key={`dropdown_${supportedAppName}`}
                >
                  <Typography
                    variant='paragraph-2'
                    weight='medium'
                    className={styles.app}
                  >
                    {supportedApps[supportedAppName].icon}
                    {supportedApps[supportedAppName].displayText}
                  </Typography>
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
      {activeSupportApp && (
        <Field
          as={Input}
          name={`supportUrls.${activeSupportApp}`}
          id={`supportUrls.${activeSupportApp}`}
          key={`supportUrls.${activeSupportApp}`}
          label={t('settings_page.general.enterURL')}
          className={styles.input}
          onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
            handleBlur(e);
            if (
              !Object.keys(errors).length &&
              initialValues.supportUrls?.[activeSupportApp] !==
                values.supportUrls?.[activeSupportApp]
            ) {
              setSpecificSettings(values);
            }
          }}
          error={
            !!getIn(errors.supportUrls, activeSupportApp) &&
            !!getIn(touched.supportUrls, activeSupportApp)
          }
        />
      )}
    </>
  );
};
