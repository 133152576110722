import { Fragment, useState } from 'react';

import { useFormikContext } from 'formik';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { EnvironmentEnum, ExternalAuth } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';
import { useSubscriptionContext } from '../../../../app/context/SubscriptionContext';

import { EnableToggle } from './EnableToggle';
import { InputFields } from './InputFields';
import styles from './ThirdPartyAuthFields.module.scss';

export const defaultFields = ['enabled', 'iss', 'jwksUrl', 'aud', 'cookieName'];

export const requiredFields = ['iss', 'jwksUrl'];

export const ThirdPartyAuthFields = () => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const [externalAuthFields] = useState<string[]>(defaultFields);
  const { values } = useFormikContext<ExternalAuth>();
  const { checks } = useSubscriptionContext();
  const { isEnterprisePlan } = checks;
  const { enableExternalAuth } = useFlags();

  const externalAuthEnabled =
    isEnterprisePlan ||
    enableExternalAuth ||
    activeEnvironmentType === EnvironmentEnum.Sandbox;

  return (
    <>
      {externalAuthFields.map((field) => {
        const fieldName = field as keyof typeof values;
        const isBooleanField = typeof values[fieldName] === 'boolean';
        return (
          <Fragment key={`field_${fieldName}`}>
            {isBooleanField ? (
              <EnableToggle
                fieldName={fieldName}
                externalAuthEnabled={externalAuthEnabled}
              />
            ) : null}
          </Fragment>
        );
      })}
      <div className={styles.inputs_wrapper}>
        {externalAuthFields.map((field) => {
          const fieldName = field as keyof typeof values;
          const isBooleanField = typeof values[fieldName] === 'boolean';
          const isRequired = requiredFields.includes(field);
          return (
            <Fragment key={`field_${fieldName}`}>
              {!isBooleanField && (
                <InputFields
                  fieldName={fieldName}
                  isRequired={isRequired}
                  externalAuthEnabled={externalAuthEnabled}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </>
  );
};
