import { FunctionComponent, ReactNode } from 'react';

import { CloseIcon } from '@dynamic-labs/sdk-react-core';

import { Typography } from '../Typography';

import styles from './SideModalHeader.module.css';

type Props = {
  Icon?: FunctionComponent;
  actions?: ReactNode;
  content: string | ReactNode;
  onClose?: () => void;
  showClose?: boolean;
  title: string;
};

export const SideModalHeader = ({
  Icon,
  title,
  content,
  showClose,
  onClose,
  actions,
}: Props) => {
  const header = (
    <div className={styles.header}>
      {Icon && <Icon />}
      <div>
        <Typography
          as='h2'
          variant='subtitle'
          weight='medium'
          className='mb-[6px]'
        >
          {title}
        </Typography>
        {typeof content === 'string' ? (
          <Typography
            variant='paragraph-2'
            weight='regular'
            className={styles.description}
          >
            {content}
          </Typography>
        ) : (
          content
        )}
      </div>
    </div>
  );

  if (!showClose || !onClose) {
    return header;
  }

  return (
    <div className={styles.container}>
      {header}
      {actions ? (
        <div className={styles.actions}>
          {actions}
          <button onClick={onClose} type='button' aria-label='Close modal icon'>
            <CloseIcon />
          </button>
        </div>
      ) : (
        <button onClick={onClose} type='button' aria-label='Close modal icon'>
          <CloseIcon />
        </button>
      )}
    </div>
  );
};
