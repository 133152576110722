import {
  FC,
  createContext,
  useEffect,
  useContext,
  Dispatch,
  useMemo,
  useState,
  SetStateAction,
  PropsWithChildren,
  useCallback,
} from 'react';

import { ProjectSettings, ProjectSettingsChains } from '@dynamic-labs/sdk-api';

import { logger } from '../../services/logger';
import { environmentsApi } from '../../services/api';
import { decodeBase64 } from '../../utils';
import { useEnvironmentsContext } from '../EnvironmentsContext';

const emptySettings: ProjectSettings = {
  chains: [],
  design: {
    button: {},
    modal: {},
    widget: {},
  },
  general: {},
  kyc: [],
  privacy: {},
  providers: [],
  sdk: {},
  security: {},
};

export interface EnvironmentSettings {
  live: ProjectSettings;
  sandbox: ProjectSettings;
}

interface SettingsContextProps {
  cancelChangesForActiveEnv: VoidFunction;
  initialSettings: EnvironmentSettings;
  onboardingSettings: EnvironmentSettings;
  publicKeys?: EnvironmentSettingsPublicKeys;
  setInitialSettings: Dispatch<SetStateAction<EnvironmentSettings>>;
  setOnboardingSettings: Dispatch<SetStateAction<EnvironmentSettings>>;
  setSettings: Dispatch<SetStateAction<EnvironmentSettings>>;
  settings: EnvironmentSettings;
  settingsHasChangedForActiveEnv: boolean;
  updateInitialSettingsForActiveEnv: VoidFunction;
}

interface EnvironmentSettingsPublicKeys {
  live?: string;
  sandbox?: string;
}

export const initialState: SettingsContextProps = {
  initialSettings: {
    live: emptySettings,
    sandbox: emptySettings,
  },
  settings: {
    live: emptySettings,
    sandbox: emptySettings,
  },
} as SettingsContextProps;

export const SettingsContext =
  createContext<SettingsContextProps>(initialState);

export const SettingsContextProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const { environments, activeEnvironmentType } = useEnvironmentsContext();
  const [settings, setSettings] = useState<EnvironmentSettings>(
    initialState.settings,
  );
  const [initialSettings, setInitialSettings] = useState<EnvironmentSettings>(
    initialState.initialSettings,
  );
  const [onboardingSettings, setOnboardingSettings] =
    useState<EnvironmentSettings>(initialState.initialSettings);

  const [publicKeys, setPublicKeys] = useState<EnvironmentSettingsPublicKeys>();

  const disableChains = (chains: ProjectSettingsChains[]) =>
    [...chains].map((chain) => ({
      ...chain,
      enabled: false,
      networks: chain.networks?.length
        ? chain.networks.map((network) => ({ ...network, enabled: false }))
        : undefined,
    }));

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        if (!environments) return;
        const liveSettings = environments.live.settings as ProjectSettings;
        const sandboxSettings = environments.sandbox
          .settings as ProjectSettings;

        setSettings({
          live: liveSettings,
          sandbox: sandboxSettings,
        });

        setInitialSettings({
          live: liveSettings,
          sandbox: sandboxSettings,
        });

        const disabledChainsLive = disableChains(liveSettings.chains);
        const disabledChainsSand = disableChains(sandboxSettings.chains);

        setOnboardingSettings({
          live: { ...liveSettings, chains: disabledChainsLive },
          sandbox: { ...sandboxSettings, chains: disabledChainsSand },
        });

        const allKeysPromises = Promise.all([
          environmentsApi.getKeysForEnvironment({
            environmentId: environments.live.id,
          }),
          environmentsApi.getKeysForEnvironment({
            environmentId: environments.sandbox.id,
          }),
        ]);

        const [liveKey, sandboxKey] = await allKeysPromises;
        const sandboxPublicKey = decodeBase64(sandboxKey.key?.publicKey);
        const livePublicKey = decodeBase64(liveKey.key?.publicKey);

        setPublicKeys({
          live: livePublicKey,
          sandbox: sandboxPublicKey,
        });
      } catch (err) {
        logger.error(err);
      }
    };

    fetchSettings();
  }, [environments]);

  const settingsHasChangedForActiveEnv = useMemo(
    () =>
      JSON.stringify(settings[activeEnvironmentType]) !==
      JSON.stringify(initialSettings[activeEnvironmentType]),
    [settings, initialSettings, activeEnvironmentType],
  );

  const updateInitialSettingsForActiveEnv = useCallback(
    () =>
      setInitialSettings({
        ...initialSettings,
        [activeEnvironmentType]: {
          ...initialSettings[activeEnvironmentType],
          ...settings[activeEnvironmentType],
        },
      }),
    [settings, initialSettings, activeEnvironmentType],
  );

  const cancelChangesForActiveEnv = () => {
    setSettings(initialSettings);
  };

  const value = useMemo(
    () => ({
      cancelChangesForActiveEnv,
      initialSettings,
      onboardingSettings,
      publicKeys,
      setInitialSettings,
      setOnboardingSettings,
      setSettings,
      settings,
      settingsHasChangedForActiveEnv,
      updateInitialSettingsForActiveEnv,
    }),
    [
      initialSettings,
      onboardingSettings,
      publicKeys,
      settings,
      cancelChangesForActiveEnv,
      updateInitialSettingsForActiveEnv,
      settingsHasChangedForActiveEnv,
    ],
  );

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettingsContext = () => useContext(SettingsContext);
