/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOutcomeEnum,
    AccessOutcomeEnumFromJSON,
    AccessOutcomeEnumFromJSONTyped,
    AccessOutcomeEnumToJSON,
} from './AccessOutcomeEnum';
import {
    GateRule,
    GateRuleFromJSON,
    GateRuleFromJSONTyped,
    GateRuleToJSON,
} from './GateRule';

/**
 * Request body to update an existing gate. This will not affect whether a gate is enabled or not.
 * @export
 * @interface GateUpdateRequest
 */
export interface GateUpdateRequest {
    /**
     * Human-readable name of the gate. This should be something helpful to organize gates.
     * @type {string}
     * @memberof GateUpdateRequest
     */
    name?: string;
    /**
     * 
     * @type {AccessOutcomeEnum}
     * @memberof GateUpdateRequest
     */
    outcome?: AccessOutcomeEnum;
    /**
     * String for the resulting scope of the gate. If the rules of the gate apply to the user being verified, then this scope will be surfaced in the JWT
     * @type {string}
     * @memberof GateUpdateRequest
     */
    scope?: string;
    /**
     * The rules which will be used to evaluate users being verified. If multiple rules are present in a single gate, then all the rules need to apply for the user to gain the scope defined by the gate.
     * @type {Array<GateRule>}
     * @memberof GateUpdateRequest
     */
    rules?: Array<GateRule>;
}

export function GateUpdateRequestFromJSON(json: any): GateUpdateRequest {
    return GateUpdateRequestFromJSONTyped(json, false);
}

export function GateUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GateUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'outcome': !exists(json, 'outcome') ? undefined : AccessOutcomeEnumFromJSON(json['outcome']),
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'rules': !exists(json, 'rules') ? undefined : ((json['rules'] as Array<any>).map(GateRuleFromJSON)),
    };
}

export function GateUpdateRequestToJSON(value?: GateUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'outcome': AccessOutcomeEnumToJSON(value.outcome),
        'scope': value.scope,
        'rules': value.rules === undefined ? undefined : ((value.rules as Array<any>).map(GateRuleToJSON)),
    };
}

