import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import { Webhook } from '@dynamic-labs/sdk-api';

import PopupModal from '../../../../components/PopupModal';
import { PopupHeader } from '../../../../components/PopupModal/PopupHeader/PopupHeader';
import { WebhookForm } from '../WebhookForm';

import styles from './EditWebhookModal.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  webhook: Webhook;
};

export const EditWebhookModal = ({ setShowModal, webhook }: Props) => (
  <PopupModal
    backdropClassName='!z-30'
    handleClose={() => setShowModal(false)}
    className={styles.portal}
  >
    <PopupHeader
      handleClose={() => setShowModal(false)}
      headingText={t<string>('webhooks.update_webhook_modal.title')}
    />
    <WebhookForm setShowModal={setShowModal} webhook={webhook} />
  </PopupModal>
);
