import { ReactNode } from 'react';

import { m } from 'framer-motion';

type Props = {
  children: ReactNode;
  className?: string;
  skipAnimation?: boolean;
};

export const ExpandFieldAnimation = ({
  children,
  className = '',
  skipAnimation = false,
}: Props) => (
  <m.div
    initial={
      skipAnimation
        ? {}
        : {
            height: 0,
            opacity: 0,
          }
    }
    animate={
      skipAnimation
        ? {}
        : {
            height: 'auto',
            opacity: 1,
            transition: {
              height: {
                duration: 0.2,
              },
              opacity: {
                delay: 0.1,
                duration: 0.15,
              },
            },
          }
    }
    exit={
      skipAnimation
        ? {}
        : {
            height: 0,
            opacity: 0,
            transition: {
              height: {
                delay: 0.1,
                duration: 0.2,
              },
              opacity: {
                duration: 0.15,
              },
            },
          }
    }
    className={className}
  >
    {children}
  </m.div>
);
