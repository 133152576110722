import { t } from 'i18next';

import { useDashboardContext } from '../../../../context/DashboardContext';
import CreateOrganizationForm from '../../../../routes/OnBoarding/Forms/CreateOrganizationForm';
import PopupModal from '../../../PopupModal';
import PopupHeader from '../../../PopupModal/PopupHeader';

import styles from './add-organization-modal.module.css';

interface Props {
  setShowModal: (showModal: boolean) => void;
}

const AddOrganizationModal = ({ setShowModal }: Props) => {
  const { setUserProjects, userOrganizations, userProjects } =
    useDashboardContext();

  const handleClose = () => setShowModal(false);

  return (
    <PopupModal handleClose={handleClose} className={styles.portal}>
      <PopupHeader
        handleClose={handleClose}
        headingText={t('create_org.title')}
      />
      <CreateOrganizationForm
        setUserProjects={setUserProjects}
        userOrganizations={userOrganizations}
        userProjects={userProjects}
        submitText={t('create_org.add')}
        cancelText={t('create_org.cancel')}
        cancelAction={handleClose}
        submitAction={handleClose}
      />
    </PopupModal>
  );
};

export default AddOrganizationModal;
