import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

type TransformedProvider = Omit<Provider, 'enabledAt'> & {
  enabled: boolean;
};

export const transformProvidersMapToCompare = (
  providers: Record<ProviderEnum, Provider>,
): Record<ProviderEnum, TransformedProvider> =>
  Object.values(providers).reduce((transformedProvider, provider) => {
    const { enabledAt, ...restProvider } = provider;

    return {
      ...transformedProvider,
      [provider.provider]: {
        ...restProvider,
        enabled: !!provider.enabledAt,
      },
    };
  }, {} as Record<ProviderEnum, TransformedProvider>);
