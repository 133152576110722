import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import {
  ErrorTriangleIcon,
  InfoCircleIcon,
  SparklesIcon,
  SuccessCheckIcon,
  WarningCircleIcon,
  XIcon,
} from '../../icons';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { TextButton } from '../TextButton';
import { IconColor } from '../Icon/Icon.types';
import { IconButton } from '../IconButton';
import { OpacityAnimation } from '../../animations';

import styles from './Banner.module.scss';

type BannerVariant = 'info' | 'warning' | 'error' | 'success' | 'advance';

type BannerProps = {
  IconOverride?: ReactElement;
  action?: VoidFunction;
  actionText?: string;
  handleClose?: VoidFunction;
  rounded?: boolean;
  show?: boolean;
  text: string;
  variant: BannerVariant;
};

const IconVariantsMap: Record<BannerVariant, ReactElement> = {
  advance: <SparklesIcon />,
  error: <ErrorTriangleIcon />,
  info: <InfoCircleIcon />,
  success: <SuccessCheckIcon />,
  warning: <WarningCircleIcon />,
};

const colorVariantsMap: Record<BannerVariant, IconColor> = {
  advance: 'primary-1',
  error: 'red-1',
  info: 'gray-2',
  success: 'green-dark',
  warning: 'orange-dark',
};

export const Banner: FC<BannerProps> = ({
  text,
  variant,
  actionText,
  action,
  show = true,
  handleClose,
  IconOverride,
  rounded,
}) => (
  <AnimatePresence>
    {show && (
      <OpacityAnimation>
        <div
          className={classNames(styles.wrapper, styles[`wrapper--${variant}`], {
            [styles['wrapper--rounded']]: rounded,
          })}
        >
          <div className={styles.container}>
            <div className={styles.textFrame}>
              <Icon
                icon={IconOverride || IconVariantsMap[variant]}
                size={20}
                color={colorVariantsMap[variant]}
              />
              <Typography
                variant='paragraph-2'
                className={styles.text}
                weight='medium'
              >
                {text}
              </Typography>

              {actionText && action && (
                <TextButton
                  color='primary-1'
                  text={actionText}
                  onClick={action}
                  variant='button-medium'
                />
              )}
            </div>

            {handleClose && (
              <IconButton
                icon={<XIcon />}
                size={20}
                color={colorVariantsMap[variant]}
                onClick={handleClose}
              />
            )}
          </div>
        </div>
      </OpacityAnimation>
    )}
  </AnimatePresence>
);
