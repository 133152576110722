/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddDeeplinkUrlRequest,
    AddDeeplinkUrlRequestFromJSON,
    AddDeeplinkUrlRequestToJSON,
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    DeeplinkUrlResponse,
    DeeplinkUrlResponseFromJSON,
    DeeplinkUrlResponseToJSON,
    DeeplinkUrlsResponse,
    DeeplinkUrlsResponseFromJSON,
    DeeplinkUrlsResponseToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface AddDeeplinkUrlOperationRequest {
    environmentId: string;
    addDeeplinkUrlRequest: AddDeeplinkUrlRequest;
}

export interface DeleteDeeplinkUrlByIdRequest {
    deeplinkUrlId: string;
}

export interface GetDeeplinkUrlsRequest {
    environmentId: string;
}

/**
 * 
 */
export class DeeplinkUrlsApi extends runtime.BaseAPI {

    /**
     * Whitelists a mobile deeplink URL for this project environment
     */
    async addDeeplinkUrlRaw(requestParameters: AddDeeplinkUrlOperationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeeplinkUrlResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling addDeeplinkUrl.');
        }

        if (requestParameters.addDeeplinkUrlRequest === null || requestParameters.addDeeplinkUrlRequest === undefined) {
            throw new runtime.RequiredError('addDeeplinkUrlRequest','Required parameter requestParameters.addDeeplinkUrlRequest was null or undefined when calling addDeeplinkUrl.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/deeplinkUrls`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddDeeplinkUrlRequestToJSON(requestParameters.addDeeplinkUrlRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeeplinkUrlResponseFromJSON(jsonValue));
    }

    /**
     * Whitelists a mobile deeplink URL for this project environment
     */
    async addDeeplinkUrl(requestParameters: AddDeeplinkUrlOperationRequest, initOverrides?: RequestInit): Promise<DeeplinkUrlResponse> {
        const response = await this.addDeeplinkUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a mobile deeplink URL by id
     */
    async deleteDeeplinkUrlByIdRaw(requestParameters: DeleteDeeplinkUrlByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deeplinkUrlId === null || requestParameters.deeplinkUrlId === undefined) {
            throw new runtime.RequiredError('deeplinkUrlId','Required parameter requestParameters.deeplinkUrlId was null or undefined when calling deleteDeeplinkUrlById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/deeplinkUrls/{deeplinkUrlId}`.replace(`{${"deeplinkUrlId"}}`, encodeURIComponent(String(requestParameters.deeplinkUrlId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a mobile deeplink URL by id
     */
    async deleteDeeplinkUrlById(requestParameters: DeleteDeeplinkUrlByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDeeplinkUrlByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Get all the whitelisted mobile deeplink URLs for a project environment
     */
    async getDeeplinkUrlsRaw(requestParameters: GetDeeplinkUrlsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<DeeplinkUrlsResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getDeeplinkUrls.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/deeplinkUrls`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeeplinkUrlsResponseFromJSON(jsonValue));
    }

    /**
     * Get all the whitelisted mobile deeplink URLs for a project environment
     */
    async getDeeplinkUrls(requestParameters: GetDeeplinkUrlsRequest, initOverrides?: RequestInit): Promise<DeeplinkUrlsResponse> {
        const response = await this.getDeeplinkUrlsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
