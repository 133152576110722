/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PasskeyRegisterVerifyRequest,
    PasskeyRegisterVerifyRequestFromJSON,
    PasskeyRegisterVerifyRequestFromJSONTyped,
    PasskeyRegisterVerifyRequestToJSON,
} from './PasskeyRegisterVerifyRequest';

/**
 * 
 * @export
 * @interface MFARegisterPasskeyDevicePostRequest
 */
export interface MFARegisterPasskeyDevicePostRequest {
    /**
     * 
     * @type {string}
     * @memberof MFARegisterPasskeyDevicePostRequest
     */
    id: string;
    /**
     * 
     * @type {PasskeyRegisterVerifyRequest}
     * @memberof MFARegisterPasskeyDevicePostRequest
     */
    attestation: PasskeyRegisterVerifyRequest;
}

export function MFARegisterPasskeyDevicePostRequestFromJSON(json: any): MFARegisterPasskeyDevicePostRequest {
    return MFARegisterPasskeyDevicePostRequestFromJSONTyped(json, false);
}

export function MFARegisterPasskeyDevicePostRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFARegisterPasskeyDevicePostRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'attestation': PasskeyRegisterVerifyRequestFromJSON(json['attestation']),
    };
}

export function MFARegisterPasskeyDevicePostRequestToJSON(value?: MFARegisterPasskeyDevicePostRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'attestation': PasskeyRegisterVerifyRequestToJSON(value.attestation),
    };
}

