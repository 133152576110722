/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Contains a valid JWKS representation of the public key for this environment. For more information, see: https://auth0.com/blog/navigating-rs256-and-jwks/
 * @export
 * @interface JwksKey
 */
export interface JwksKey {
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    kid?: string;
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    alg?: string;
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    kty?: string;
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    use?: string;
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    e?: string;
    /**
     * 
     * @type {string}
     * @memberof JwksKey
     */
    n?: string;
}

export function JwksKeyFromJSON(json: any): JwksKey {
    return JwksKeyFromJSONTyped(json, false);
}

export function JwksKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwksKey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kid': !exists(json, 'kid') ? undefined : json['kid'],
        'alg': !exists(json, 'alg') ? undefined : json['alg'],
        'kty': !exists(json, 'kty') ? undefined : json['kty'],
        'use': !exists(json, 'use') ? undefined : json['use'],
        'e': !exists(json, 'e') ? undefined : json['e'],
        'n': !exists(json, 'n') ? undefined : json['n'],
    };
}

export function JwksKeyToJSON(value?: JwksKey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'kid': value.kid,
        'alg': value.alg,
        'kty': value.kty,
        'use': value.use,
        'e': value.e,
        'n': value.n,
    };
}

