/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EnvironmentEnum,
    EnvironmentEnumFromJSON,
    EnvironmentEnumFromJSONTyped,
    EnvironmentEnumToJSON,
} from './EnvironmentEnum';

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    eventId: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    environmentId: string;
    /**
     * 
     * @type {EnvironmentEnum}
     * @memberof Event
     */
    environmentName: EnvironmentEnum;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    eventAction?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    eventName: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    resourceType?: string;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    timestamp: Date;
    /**
     * 
     * @type {object}
     * @memberof Event
     */
    data: object;
}

export function EventFromJSON(json: any): Event {
    return EventFromJSONTyped(json, false);
}

export function EventFromJSONTyped(json: any, ignoreDiscriminator: boolean): Event {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventId': json['eventId'],
        'environmentId': json['environmentId'],
        'environmentName': EnvironmentEnumFromJSON(json['environmentName']),
        'eventAction': !exists(json, 'eventAction') ? undefined : json['eventAction'],
        'eventName': json['eventName'],
        'resourceType': !exists(json, 'resourceType') ? undefined : json['resourceType'],
        'timestamp': (new Date(json['timestamp'])),
        'data': json['data'],
    };
}

export function EventToJSON(value?: Event | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventId': value.eventId,
        'environmentId': value.environmentId,
        'environmentName': EnvironmentEnumToJSON(value.environmentName),
        'eventAction': value.eventAction,
        'eventName': value.eventName,
        'resourceType': value.resourceType,
        'timestamp': (value.timestamp.toISOString()),
        'data': value.data,
    };
}

