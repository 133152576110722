import { FC, ReactElement, ReactNode } from 'react';

import { Typography } from '../../../../../components/Typography';
import { Icon } from '../../../../../components/Icon';

type ProviderControlTemplateProps = {
  icon: ReactElement;
  inputs: ReactNode[];
  subTitle: ReactNode;
  title: ReactNode;
  toggle: ReactNode;
};

export const ProviderControlTemplate: FC<ProviderControlTemplateProps> = ({
  subTitle,
  icon,
  inputs,
  title,
  toggle,
}) => (
  <div className='border border-cloud-2 px-4 rounded-xl flex-col flex py-4'>
    {/* Header */}
    <div className='flex gap-2 items-center pb-4'>
      {toggle}

      <div className='flex items-center gap-2 whitespace-nowrap'>
        <Icon icon={icon} />

        <div className='text-sm'>{title}</div>
      </div>
    </div>

    {/* Content */}
    <div className='flex flex-col gap-1'>
      <Typography color='gray-1' variant='paragraph-2'>
        {subTitle}
      </Typography>

      {inputs}
    </div>
  </div>
);
