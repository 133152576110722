import { compare } from 'semver';

import { useEnvironmentsContext } from '../context/EnvironmentsContext';

export const useSdkVersion = (minimalSdkVersion: string) => {
  const { activeEnvironmentType, environments } = useEnvironmentsContext();
  const currentEnvSdkVersion =
    environments?.[activeEnvironmentType].sdkVersion || undefined;

  // if we don't have the sdk version, we can't determine if it's outdated
  // It means that the user hasn't installed it yet,
  // therefore we can assume that they will use the latest
  if (currentEnvSdkVersion === undefined) {
    return {
      currentEnvSdkVersion: undefined,
      isOutdatedSdkVersion: false,
    };
  }

  const isOutdatedSdkVersion = currentEnvSdkVersion
    ? compare(currentEnvSdkVersion, minimalSdkVersion) < 0
    : true;

  return {
    currentEnvSdkVersion,
    isOutdatedSdkVersion,
  };
};
