import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { Paper } from '../../../../components/Paper';
import { Typography } from '../../../../components/Typography';
import { IntegrationCard as IntegrationCardType } from '../../../../data/overviewCards';

import styles from './IntegrationCard.module.css';

export const IntegrationCard = ({
  icon,
  title,
  description,
  id,
  url,
  scope,
}: IntegrationCardType) => {
  const { t } = useTranslation();
  const { isScopeAllowed, isScopeAdvanced, subscription } =
    useSubscriptionContext();

  const iconClassNames = classNames(
    styles.icon,
    !isScopeAdvanced(scope || '') && styles['icon--gray'],
  );

  const isLegacySubscription = subscription?.version === '1';

  return (
    <Link
      key={id}
      to={url}
      className='w-full'
      target={id === 'docs' ? '_blank' : '_self'}
    >
      <Paper className={styles.container}>
        <div className={iconClassNames}>{icon}</div>

        <div className={styles.wrapper}>
          <Typography
            variant='paragraph-1'
            className={styles.wrapper__text}
            weight='medium'
          >
            {title}
          </Typography>
          {scope === undefined || isScopeAllowed(scope) ? (
            <Typography
              className={styles.wrapper__description}
              variant='paragraph-1'
            >
              {description}
            </Typography>
          ) : (
            <Typography className={styles.advanced_description} weight='bold'>
              {t(
                `${
                  isLegacySubscription ? 'advanced_tooltip' : 'standard_tooltip'
                }.title`,
              )}
            </Typography>
          )}
        </div>
      </Paper>
    </Link>
  );
};
