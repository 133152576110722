import { useState } from 'react';

import { t } from 'i18next';

import { DeleteModal } from '../../../../../components/DeleteModal';
import { logger } from '../../../../../services/logger';
import { allowListsApi } from '../../../../../services/api';

type DeleteAllowListModalProps = {
  allowListId: string;
  onClose(): void;
  onDelete(): void;
};

export const DeleteAllowListModal = ({
  onClose,
  onDelete,
  allowListId,
}: DeleteAllowListModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);

    try {
      await allowListsApi.deleteAllowlistById({
        allowlistId: allowListId,
      });

      setLoading(false);
      onDelete();
    } catch (e) {
      setLoading(false);
      logger.error(e);
    }
  };

  return (
    <DeleteModal
      title={t<string>(
        'integrations.onboarding_and_kyc.access_control.access_list.delete_allow_list.title',
      )}
      text={t<string>(
        'integrations.onboarding_and_kyc.access_control.access_list.delete_allow_list.text',
      )}
      onClose={onClose}
      onDelete={handleDeleteClick}
      loading={loading}
    />
  );
};
