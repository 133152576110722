import { useQuery } from 'react-query';

import { EventType } from '@dynamic-labs/sdk-api';

import { eventsApi } from '../../services/api';

export const useEventTypes = () => {
  const queryKey = ['eventTypes'];

  const { data, isLoading, refetch } = useQuery<EventType[]>(queryKey, () =>
    eventsApi.getEventTypes(),
  );

  return {
    eventTypes: data ?? [],
    isLoading,
    refetch,
  };
};
