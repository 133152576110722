import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { exportsColumns } from './exportsColumns';

export const useExportsColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(() => exportsColumns(t), [t]);

  return { columns };
};
