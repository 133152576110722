/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OriginResponse,
    OriginResponseFromJSON,
    OriginResponseFromJSONTyped,
    OriginResponseToJSON,
} from './OriginResponse';

/**
 * 
 * @export
 * @interface OriginsResponse
 */
export interface OriginsResponse {
    /**
     * 
     * @type {Array<OriginResponse>}
     * @memberof OriginsResponse
     */
    origins?: Array<OriginResponse>;
}

export function OriginsResponseFromJSON(json: any): OriginsResponse {
    return OriginsResponseFromJSONTyped(json, false);
}

export function OriginsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OriginsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'origins': !exists(json, 'origins') ? undefined : ((json['origins'] as Array<any>).map(OriginResponseFromJSON)),
    };
}

export function OriginsResponseToJSON(value?: OriginsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'origins': value.origins === undefined ? undefined : ((value.origins as Array<any>).map(OriginResponseToJSON)),
    };
}

