import { SimpleContent } from '../../../../../data/overviewCards';
import { Typography } from '../../../../../components/Typography';

import styles from './main.module.css';

type Props = {
  content: SimpleContent | string;
};

export const Main = ({ content }: Props): JSX.Element => (
  <div className={styles.container}>
    {typeof content === 'string' ? (
      <Typography variant='paragraph-1' className='!text-gray-1'>
        {content}
      </Typography>
    ) : (
      content.content
    )}
  </div>
);
