import { useState } from 'react';

import { Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';

import Checkbox from '../../../../components/Checkbox';
import { Typography } from '../../../../components/Typography';

import { EventTypeListItem } from './EventTypeListItem';

export const EventTypeList = ({
  eventTypes,
  selectedWebhookEvents,
  formik,
}: {
  eventTypes: any;
  formik: FormikProps<any>;
  selectedWebhookEvents: string[];
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string>('');

  const eventTypesMap: { [key: string]: any[] } = {};
  eventTypes?.forEach((eventType: any) => {
    const eventResource = eventType?.name.split('.')[0];
    if (!eventTypesMap[eventResource]) {
      eventTypesMap[eventResource] = [];
    }
    eventTypesMap[eventResource].push(eventType);
  });

  const handleSelectEveryEventInResource = (
    event: any,
    eventResource: string,
    resourceEventNames: string[],
  ) => {
    formik.setFieldValue(
      eventResource,
      event.target.checked ? resourceEventNames : undefined,
    );
    formik.setFieldValue(
      'events',
      event.target.checked
        ? [
            ...new Set([
              ...(formik?.values?.events || []),
              ...resourceEventNames,
            ]),
          ]
        : [
            ...(formik?.values?.events || []).filter(
              (e: string) => !resourceEventNames.includes(e),
            ),
          ],
    );
  };

  return (
    <div className='border rounded-xl divide-y max-h-72 overflow-y-auto'>
      {Object.keys(eventTypesMap).length ? (
        <>
          {Object.keys(eventTypesMap).map((eventResource: string) => {
            const resourceEvents = eventTypesMap?.[eventResource];
            const resourceEventNames = resourceEvents.map((e) => e.name);
            return (
              <EventTypeListItem
                key={eventResource}
                eventResource={eventResource}
                eventTypesMap={eventTypesMap}
                selectedWebhookEvents={selectedWebhookEvents}
                expanded={expanded}
                setExpanded={setExpanded}
              >
                <div className='my-4 flex items-start'>
                  <Field
                    id={eventResource}
                    name={eventResource}
                    as={Checkbox}
                    type='checkbox'
                    className='mr-2'
                    checked={resourceEventNames
                      .map((eventName: string) =>
                        formik?.values?.events?.includes(eventName),
                      )
                      .every(Boolean)}
                    onChange={(e: any) =>
                      handleSelectEveryEventInResource(
                        e,
                        eventResource,
                        resourceEventNames,
                      )
                    }
                  />
                  <Typography variant='paragraph-1' weight='medium'>
                    {t('webhooks.create_webhook_modal.select_all_label')}
                  </Typography>
                </div>
                {eventTypesMap[eventResource].map((eventType) => (
                  <div className='my-4 flex items-start' key={eventType.name}>
                    <Field
                      id={eventType.name}
                      name='events'
                      as={Checkbox}
                      type='checkbox'
                      className='mr-2'
                      value={eventType.name}
                    />
                    <div>
                      <Typography variant='paragraph-1'>
                        {eventType.name}
                      </Typography>
                      <Typography>{eventType.description}</Typography>
                    </div>
                  </div>
                ))}
              </EventTypeListItem>
            );
          })}
        </>
      ) : (
        <Typography variant='paragraph-1' className='mb-3 p-3'>
          No events
        </Typography>
      )}
    </div>
  );
};
