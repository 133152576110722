import { useEffect, useMemo, useState } from 'react';

import {
  ChainEnum,
  VisitorFilterableFieldsEnum,
  VisitorsResponse,
} from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import { visitsApi } from '../../../../services/api';
import { SortBy } from '../../../../types';

export type UseFetchVisitorsArgs = {
  activeProjectId: string | undefined;
  selectedEnvironmentId: string | undefined;
  visitorsChainFilter: ChainEnum | undefined;
  visitorsSearchFilter: VisitorFilterableFieldsEnum;
};

export const useFetchVisitors = ({
  activeProjectId,
  selectedEnvironmentId,
  visitorsSearchFilter,
  visitorsChainFilter,
}: UseFetchVisitorsArgs) => {
  const limit = 20;
  const [visitorSearchQuery, setVisitorSearchQuery] = useState<string>();
  const [sortBy, setSortBy] = useState<SortBy>();
  const [offset, setOffset] = useState<number>(0);
  const [visitors, setVisitors] = useState<VisitorsResponse | undefined>();
  const [page, setPage] = useState<number>(0);
  const [reload, setReload] = useState<boolean>(false);

  // Changing the search filter always triggers an update but
  // we do not want to make an API request when the query is blank since nothing is being filtered
  const memoizedSearchFilter = useMemo(
    () =>
      visitorSearchQuery
        ? visitorsSearchFilter
        : VisitorFilterableFieldsEnum.All,
    [visitorSearchQuery, visitorsSearchFilter],
  );

  useEffect(() => {
    const fetchVisitors = async () => {
      try {
        if (selectedEnvironmentId) {
          const response = await visitsApi.getEnvironmentVisitors({
            environmentId: selectedEnvironmentId,
            filter: {
              ...(visitorSearchQuery && {
                filterValue: visitorSearchQuery,
              }),
              ...(memoizedSearchFilter && {
                filterColumn: memoizedSearchFilter,
              }),
              ...(visitorsChainFilter && { chain: visitorsChainFilter }),
            },
            limit,
            offset,
            orderBy: sortBy && `${sortBy.id}-${sortBy.desc ? 'desc' : 'asc'}`,
          });
          setVisitors(response);
        }
      } catch (e) {
        logger.error(e);
      } finally {
        setReload(false);
      }
    };

    fetchVisitors();
  }, [
    reload,
    selectedEnvironmentId,
    activeProjectId,
    sortBy,
    visitorsSearchFilter,
    memoizedSearchFilter,
    visitorsChainFilter,
    offset,
  ]);

  useEffect(() => {
    setOffset(0);
    setPage(0);
  }, [
    visitorSearchQuery,
    visitorsSearchFilter,
    visitorsChainFilter,
    sortBy,
    selectedEnvironmentId,
  ]);

  const visitorsData = useMemo(() => visitors?.visitors, [visitors]);

  return {
    limit,
    offset,
    page,
    setOffset,
    setPage,
    setReload,
    setSortBy,
    setVisitorSearchQuery,
    total: visitors?.count ?? 0,
    visitorsData,
  };
};
