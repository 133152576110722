/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsGeneral
 */
export interface ProjectSettingsGeneral {
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    supportEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    supportText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    appLogo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    imageUserNotInAccessList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    imageUserInAccessList?: string | null;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ProjectSettingsGeneral
     */
    supportUrls?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    collectUserDataWelcomeHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    collectUserDataWelcomeMessage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectSettingsGeneral
     */
    skipOptionalKYCFieldDuringOnboarding?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSettingsGeneral
     */
    emailCompanyName?: string;
}

export function ProjectSettingsGeneralFromJSON(json: any): ProjectSettingsGeneral {
    return ProjectSettingsGeneralFromJSONTyped(json, false);
}

export function ProjectSettingsGeneralFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsGeneral {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'supportEmail': !exists(json, 'supportEmail') ? undefined : json['supportEmail'],
        'supportText': !exists(json, 'supportText') ? undefined : json['supportText'],
        'appLogo': !exists(json, 'appLogo') ? undefined : json['appLogo'],
        'imageUserNotInAccessList': !exists(json, 'imageUserNotInAccessList') ? undefined : json['imageUserNotInAccessList'],
        'imageUserInAccessList': !exists(json, 'imageUserInAccessList') ? undefined : json['imageUserInAccessList'],
        'supportUrls': !exists(json, 'supportUrls') ? undefined : json['supportUrls'],
        'collectUserDataWelcomeHeader': !exists(json, 'collectUserDataWelcomeHeader') ? undefined : json['collectUserDataWelcomeHeader'],
        'collectUserDataWelcomeMessage': !exists(json, 'collectUserDataWelcomeMessage') ? undefined : json['collectUserDataWelcomeMessage'],
        'skipOptionalKYCFieldDuringOnboarding': !exists(json, 'skipOptionalKYCFieldDuringOnboarding') ? undefined : json['skipOptionalKYCFieldDuringOnboarding'],
        'emailCompanyName': !exists(json, 'emailCompanyName') ? undefined : json['emailCompanyName'],
    };
}

export function ProjectSettingsGeneralToJSON(value?: ProjectSettingsGeneral | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'displayName': value.displayName,
        'supportEmail': value.supportEmail,
        'supportText': value.supportText,
        'appLogo': value.appLogo,
        'imageUserNotInAccessList': value.imageUserNotInAccessList,
        'imageUserInAccessList': value.imageUserInAccessList,
        'supportUrls': value.supportUrls,
        'collectUserDataWelcomeHeader': value.collectUserDataWelcomeHeader,
        'collectUserDataWelcomeMessage': value.collectUserDataWelcomeMessage,
        'skipOptionalKYCFieldDuringOnboarding': value.skipOptionalKYCFieldDuringOnboarding,
        'emailCompanyName': value.emailCompanyName,
    };
}

