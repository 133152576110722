import { FC } from 'react';

// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { t as staticTMethod } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { Input, Typography } from '@dynamic-labs/northstar';

import TextArea from '../../../../../../../../../app/components/TextArea';
import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';
import { SingleKey } from '../../../../../../../../../app/components/SingleKey';

import styles from './OwnCredentialsAppleForm.module.scss';

type OwnCredentialsAppleFormProps = {
  provider: ProviderEnum.Apple;
};

const requiredText = staticTMethod(
  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.required',
);

const appleFormSchema = yup.object({
  appleKeyId: yup.string().required(requiredText),
  appleTeamId: yup.string().required(requiredText),
  clientId: yup.string().required(requiredText),
  clientSecret: yup.string().required(requiredText),
  scopes: yup.string().required(requiredText),
});

type AppleCredentialsForm = yup.InferType<typeof appleFormSchema>;

type FieldTypes =
  | 'clientId'
  | 'clientSecret'
  | 'appleKeyId'
  | 'appleTeamId'
  | 'scopes';
const APPLE_FORM_FIELDS: FieldTypes[] = [
  'clientId',
  'appleKeyId',
  'appleTeamId',
  'clientSecret',
  'scopes',
];

export const OwnCredentialsAppleForm: FC<OwnCredentialsAppleFormProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const {
    getProviderValue,
    onChangeProvider,
    getProviderRedirectUrl,
    getProviderBasicScopes,
  } = useProvidersContext();

  const methods = useForm<AppleCredentialsForm>({
    defaultValues: {
      appleKeyId: getProviderValue(provider, 'appleKeyId'),
      appleTeamId: getProviderValue(provider, 'appleTeamId'),
      clientId: getProviderValue(provider, 'clientId'),
      clientSecret: getProviderValue(provider, 'clientSecret'),
      scopes: getProviderValue(provider, 'scopes'),
    },
    mode: 'all',
    resolver: yupResolver(appleFormSchema),
  });

  const {
    register,
    getValues,
    formState: { errors },
  } = methods;

  const handleOnBlur = () => {
    if (
      errors.clientId ||
      errors.clientSecret ||
      errors.appleKeyId ||
      errors.appleTeamId ||
      errors.scopes
    )
      return;

    onChangeProvider(provider, 'clientId', getValues('clientId'));
    onChangeProvider(provider, 'clientSecret', getValues('clientSecret'));
    onChangeProvider(provider, 'appleKeyId', getValues('appleKeyId'));
    onChangeProvider(provider, 'appleTeamId', getValues('appleTeamId'));
    onChangeProvider(provider, 'scopes', getValues('scopes'));
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onBlur={handleOnBlur} className={styles.form}>
          {APPLE_FORM_FIELDS.map((appleFormField) => (
            <div key={appleFormField}>
              {appleFormField === 'clientSecret' ? (
                <TextArea
                  name={appleFormField}
                  onBlur={register(appleFormField).onBlur}
                  onChange={register(appleFormField).onChange}
                  ref={register(appleFormField).ref}
                  error={!!errors[appleFormField]}
                  label={t(
                    `v2.page.log_in_methods.social.drawer.form.${appleFormField}`,
                  )}
                  rows={10}
                />
              ) : (
                <Input
                  name={appleFormField}
                  onBlur={register(appleFormField).onBlur}
                  onChange={register(appleFormField).onChange}
                  ref={register(appleFormField).ref}
                  error={!!errors[appleFormField]}
                  label={t(
                    `v2.page.log_in_methods.social.drawer.form.${appleFormField}`,
                  )}
                />
              )}
              {errors[appleFormField]?.message && (
                <Typography
                  variant='paragraph-1'
                  color='inherit'
                  className={styles.error}
                >
                  {errors[appleFormField]?.message}
                </Typography>
              )}
            </div>
          ))}
        </form>
      </FormProvider>
      <SingleKey
        className={styles['redirect-url']}
        title={t('v2.page.log_in_methods.social.drawer.form.redirectUrl')}
        keyValue={getProviderRedirectUrl(provider) || ''}
      />

      <SingleKey
        title={t('v2.page.log_in_methods.social.drawer.form.basicScopes')}
        hideCopy
        keyValue={getProviderBasicScopes(provider) || ''}
      />
    </>
  );
};
