/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileSettings
 */
export interface MobileSettings {
    /**
     * Whether to enable whitelisting mobile deeplink URLs, which will be used for redirecting back to the mobile app. Is required for many features such as social sign in.
     * @type {boolean}
     * @memberof MobileSettings
     */
    deeplinkUrlsEnabled?: boolean;
}

export function MobileSettingsFromJSON(json: any): MobileSettings {
    return MobileSettingsFromJSONTyped(json, false);
}

export function MobileSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deeplinkUrlsEnabled': !exists(json, 'deeplinkUrlsEnabled') ? undefined : json['deeplinkUrlsEnabled'],
    };
}

export function MobileSettingsToJSON(value?: MobileSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deeplinkUrlsEnabled': value.deeplinkUrlsEnabled,
    };
}

