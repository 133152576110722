/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderCreateRequestMultichainAccountAbstractionProviders
 */
export interface ProviderCreateRequestMultichainAccountAbstractionProviders {
    /**
     * 
     * @type {string}
     * @memberof ProviderCreateRequestMultichainAccountAbstractionProviders
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderCreateRequestMultichainAccountAbstractionProviders
     */
    chain: string;
    /**
     * 
     * @type {boolean}
     * @memberof ProviderCreateRequestMultichainAccountAbstractionProviders
     */
    _default?: boolean;
}

export function ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSON(json: any): ProviderCreateRequestMultichainAccountAbstractionProviders {
    return ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSONTyped(json, false);
}

export function ProviderCreateRequestMultichainAccountAbstractionProvidersFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProviderCreateRequestMultichainAccountAbstractionProviders {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': json['clientId'],
        'chain': json['chain'],
        '_default': !exists(json, 'default') ? undefined : json['default'],
    };
}

export function ProviderCreateRequestMultichainAccountAbstractionProvidersToJSON(value?: ProviderCreateRequestMultichainAccountAbstractionProviders | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'chain': value.chain,
        'default': value._default,
    };
}

