import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { InfoCircleIcon, Section } from '@dynamic-labs/northstar';

import { useCustomFieldsContext } from '../../../../../../app/context/CustomFieldsContext';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { NAME_SERVICE_FIELD_KEY } from '../../../../GlobalWallets/util/constants';

import styles from './UserInformationSection.module.scss';
import { KycFieldToggle } from './KycFieldToggle';
import { CreateNewFields } from './CreateNewFields';
import { CustomFieldsToggle } from './CustomFieldsToggle';

const kycFieldsToSkip = ['email', 'phoneNumber', 'social', 'policiesConsent'];

const TOS_PP_DOCS =
  'https://docs.dynamic.xyz/wallets/advanced-wallets/tos-privacy-policy';

type UserInformationSectionProps = {
  disabled: boolean;
};

export const UserInformationSection: FC<UserInformationSectionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();

  const { settings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { customFields } = useCustomFieldsContext();
  const { enableCustomFields } = useFlags();

  const availableKycSettings = settings[activeEnvironmentType].kyc
    .filter((field) => !kycFieldsToSkip.includes(field.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  const policiesKycField = settings[activeEnvironmentType].kyc.find(
    (field) => field.name === 'policiesConsent',
  );

  const visibleCustomFields = useMemo(
    () => customFields?.filter((field) => field.key !== NAME_SERVICE_FIELD_KEY),
    [customFields],
  );

  return (
    <>
      <Section
        title={t('v2.page.log_in_methods.policies.title')}
        alerts={[
          {
            IconOverride: <InfoCircleIcon />,
            action: {
              as: 'a',
              href: TOS_PP_DOCS,
              text: t('v2.page.log_in_methods.policies.action'),
            },
            title: t('v2.page.log_in_methods.policies.description'),
            variant: 'advance',
          },
        ]}
      >
        <div className={styles.section}>
          {policiesKycField && (
            <KycFieldToggle
              key={policiesKycField.name}
              field={policiesKycField}
              disabled={disabled}
            />
          )}
        </div>
      </Section>

      <Section
        title={t('v2.page.log_in_methods.kyc.title')}
        description={t('v2.page.log_in_methods.kyc.description')}
      >
        <div className={styles.section}>
          {availableKycSettings.map((kycField) => (
            <KycFieldToggle
              key={kycField.name}
              field={kycField}
              disabled={disabled}
            />
          ))}
          {enableCustomFields &&
            visibleCustomFields
              ?.slice()
              .map((customField) => (
                <CustomFieldsToggle
                  key={customField.id}
                  field={customField}
                  disabled={disabled}
                />
              ))}
        </div>
        {enableCustomFields && (
          <div className={styles.button}>
            <CreateNewFields />
          </div>
        )}
      </Section>
    </>
  );
};
