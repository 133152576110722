import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseDeleteCustomHostnamesHook } from './useDeleteCustomHostnames.types';

export const useDeleteCustomHostnames: UseDeleteCustomHostnamesHook = (
  options,
) => {
  const { customHostnamesApi } = useRedcoastQueryContext();

  return useMutation(
    (deleteCustomHostnamesRequest) =>
      customHostnamesApi.deleteCustomHostnames(deleteCustomHostnamesRequest),
    options,
  );
};
