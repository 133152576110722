/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InviteStatusEnum,
    InviteStatusEnumFromJSON,
    InviteStatusEnumFromJSONTyped,
    InviteStatusEnumToJSON,
} from './InviteStatusEnum';
import {
    RoleEnum,
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface Invite
 */
export interface Invite {
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    organizationId: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    organizationName: string;
    /**
     * 
     * @type {InviteStatusEnum}
     * @memberof Invite
     */
    status: InviteStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof Invite
     */
    walletPublicKey?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof Invite
     */
    role?: RoleEnum;
    /**
     * Email of the user that created the invitation
     * @type {string}
     * @memberof Invite
     */
    createdByEmail?: string;
}

export function InviteFromJSON(json: any): Invite {
    return InviteFromJSONTyped(json, false);
}

export function InviteFromJSONTyped(json: any, ignoreDiscriminator: boolean): Invite {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'organizationId': json['organizationId'],
        'organizationName': json['organizationName'],
        'status': InviteStatusEnumFromJSON(json['status']),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
        'role': !exists(json, 'role') ? undefined : RoleEnumFromJSON(json['role']),
        'createdByEmail': !exists(json, 'createdByEmail') ? undefined : json['createdByEmail'],
    };
}

export function InviteToJSON(value?: Invite | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organizationId': value.organizationId,
        'organizationName': value.organizationName,
        'status': InviteStatusEnumToJSON(value.status),
        'email': value.email,
        'alias': value.alias,
        'walletPublicKey': value.walletPublicKey,
        'role': RoleEnumToJSON(value.role),
        'createdByEmail': value.createdByEmail,
    };
}

