import { createContext, useContext, useMemo } from 'react';

import { UseMutateAsyncFunction } from 'react-query';

import {
  WebhookCreateRequest,
  Webhook,
  WebhookUpdateRequest,
} from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { useWebhooks } from '../../hooks/useWebhooks';

type WebhooksContextProps = {
  createWebhook: UseMutateAsyncFunction<
    WebhookCreateRequest,
    unknown,
    WebhookCreateRequest,
    unknown
  >;
  deleteWebhook: UseMutateAsyncFunction<void, unknown, string, unknown>;
  isLoadingWebhooks: boolean;
  updateWebhook: UseMutateAsyncFunction<
    Webhook,
    unknown,
    {
      webhook: WebhookUpdateRequest;
      webhookId: string;
    },
    unknown
  >;
  webhooks: Webhook[];
};

type WebhooksContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const WebhooksContext = createContext<WebhooksContextProps | undefined>(
  undefined,
);

export const WebhooksContextProvider = ({
  children,
}: WebhooksContextProviderProps) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const environmentId = activeEnvironment?.id;

  const {
    webhooks,
    isLoading: isLoadingWebhooks,
    createWebhook,
    updateWebhook,
    deleteWebhook,
  } = useWebhooks(environmentId || '');

  const value = useMemo(
    () => ({
      createWebhook,
      deleteWebhook,
      isLoadingWebhooks,
      updateWebhook,
      webhooks,
    }),
    [createWebhook, deleteWebhook, isLoadingWebhooks, updateWebhook, webhooks],
  );

  return (
    <WebhooksContext.Provider value={value}>
      {children}
    </WebhooksContext.Provider>
  );
};

export const useWebhooksContext = () => {
  const context = useContext(WebhooksContext);

  if (context === undefined) {
    throw new Error(
      'usage of useWebhooksContext not wrapped in `WebhooksContextProvider`.',
    );
  }

  return context;
};
