/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateMfaToken
 */
export interface CreateMfaToken {
    /**
     * Whether or not the token is single use
     * @type {boolean}
     * @memberof CreateMfaToken
     */
    singleUse: boolean;
}

export function CreateMfaTokenFromJSON(json: any): CreateMfaToken {
    return CreateMfaTokenFromJSONTyped(json, false);
}

export function CreateMfaTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateMfaToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'singleUse': json['singleUse'],
    };
}

export function CreateMfaTokenToJSON(value?: CreateMfaToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'singleUse': value.singleUse,
    };
}

