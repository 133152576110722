import Moment from 'react-moment';
import 'moment-timezone';
import { t } from 'i18next';

import { ChainalysisCheck } from '@dynamic-labs/sdk-api';

import CopyButton from '../../../../../components/CopyButton';
import { WalletAddress } from '../../../../../components/WalletAddress';
import Table from '../../../../../components/Table';
import { Badge } from '../../../../../components/Badge';
import { Typography } from '../../../../../components/Typography';

import styles from './chainalysischecks-table.module.css';

interface Props {
  chain: string | undefined;
  chainalysisChecks: ChainalysisCheck[];
  walletPublicKey: string | undefined;
}

export const ChainalysisChecksTable = ({
  chainalysisChecks,
  chain,
  walletPublicKey,
}: Props) => {
  const headers = [
    t('users_sidebar_modal.chainalysis.table.date'),
    t('users_sidebar_modal.chainalysis.table.time'),
    t('users_sidebar_modal.chainalysis.table.wallet'),
    t('users_sidebar_modal.chainalysis.table.result'),
  ];
  return (
    <Table
      headers={headers}
      rows={
        chainalysisChecks.length > 0
          ? chainalysisChecks.map((check) => (
              <div
                className={`${styles.row__container} ${styles.row__check}`}
                key={`row_${check.createdAt}`}
                data-testid='check'
              >
                <div className={styles.table__column}>
                  <Moment date={check.createdAt} format='MM/DD/YYYY' />
                </div>
                <div className={styles.table__column}>
                  <Moment
                    date={check.createdAt}
                    tz='America/Los_Angeles'
                    format='hh:mm A (z)'
                  />
                </div>
                {walletPublicKey && (
                  <div
                    className={`${styles.table__column} ${styles.table__column_wallet}`}
                  >
                    <WalletAddress
                      address={walletPublicKey}
                      chain={chain}
                      userStyle={styles.address}
                    />
                    <CopyButton
                      textToCopy={walletPublicKey}
                      type='icon'
                      color='gray'
                    />
                  </div>
                )}

                <div className={styles.table__column}>
                  {check.result === 'OK' && (
                    <Badge
                      text={t(
                        'users_sidebar_modal.chainalysis.result_values.success',
                      )}
                      variant='green'
                      size='small'
                    />
                  )}

                  {(check.result === 'FAILED' ||
                    check.result === 'BLOCKED') && (
                    <Badge
                      text={t(
                        'users_sidebar_modal.chainalysis.result_values.failure',
                      )}
                      variant='red'
                      size='small'
                    />
                  )}
                </div>
              </div>
            ))
          : [<Typography variant='paragraph-2'>No results</Typography>]
      }
    />
  );
};
