import { formatISO } from 'date-fns';
import { useQuery } from 'react-query';

import { analyticsApi } from '../../services/api';

import {
  WalletsBreakdownQueryHook,
  WalletsBreakdownQueryHookData,
} from './useWalletsBreakdownQuery.types';

export const useWalletsBreakdownQuery: WalletsBreakdownQueryHook = (
  { endDate, environmentId, startDate },
  options,
) =>
  useQuery<WalletsBreakdownQueryHookData>(
    ['environmentId', environmentId, 'wallets-breakdown', startDate, endDate],
    () =>
      analyticsApi.getWalletsBreakdown({
        endDate: endDate && formatISO(endDate),
        environmentId,
        startDate: startDate && formatISO(startDate),
      }),
    options,
  );
