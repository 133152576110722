/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SolanaChainType {
    Mainnet = 'mainnet',
    Devnet = 'devnet'
}

export function SolanaChainTypeFromJSON(json: any): SolanaChainType {
    return SolanaChainTypeFromJSONTyped(json, false);
}

export function SolanaChainTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SolanaChainType {
    return json as SolanaChainType;
}

export function SolanaChainTypeToJSON(value?: SolanaChainType | null): any {
    return value as any;
}

