/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * This contains the hashed string of the concatenated verified credential IDs grouped by credential format.
 * @export
 * @interface JwtVerifiedCredentialHashes
 */
export interface JwtVerifiedCredentialHashes {
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredentialHashes
     */
    blockchain?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredentialHashes
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredentialHashes
     */
    oauth?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredentialHashes
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JwtVerifiedCredentialHashes
     */
    externalUser?: string;
}

export function JwtVerifiedCredentialHashesFromJSON(json: any): JwtVerifiedCredentialHashes {
    return JwtVerifiedCredentialHashesFromJSONTyped(json, false);
}

export function JwtVerifiedCredentialHashesFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtVerifiedCredentialHashes {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'blockchain': !exists(json, 'blockchain') ? undefined : json['blockchain'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'oauth': !exists(json, 'oauth') ? undefined : json['oauth'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'externalUser': !exists(json, 'externalUser') ? undefined : json['externalUser'],
    };
}

export function JwtVerifiedCredentialHashesToJSON(value?: JwtVerifiedCredentialHashes | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'blockchain': value.blockchain,
        'email': value.email,
        'oauth': value.oauth,
        'phoneNumber': value.phoneNumber,
        'externalUser': value.externalUser,
    };
}

