import { ExternalAuth, ProjectSettingsSecurity } from '@dynamic-labs/sdk-api';

import { useBaseSettings } from '../useBaseSettings';
import { useSettingsContext } from '../..';
import { useEnvironmentsContext } from '../../../EnvironmentsContext';

export const useExternalAuthSettings = () => {
  const sanitizeExternalAuthSettings = (
    obj: ExternalAuth | undefined,
  ): ExternalAuth => ({
    aud: obj?.aud ?? '',
    cookieName: obj?.cookieName ?? '',
    enabled: obj?.enabled ?? false,
    iss: obj?.iss ?? '',
    jwksUrl: obj?.jwksUrl ?? '',
  });

  const { initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const baseSettings = useBaseSettings<ProjectSettingsSecurity>(
    'security',
    (settings) => ({
      externalAuth: sanitizeExternalAuthSettings(settings.externalAuth),
    }),
  );

  return {
    ...baseSettings,
    initialSettings:
      initialSettings?.[activeEnvironmentType]?.security.externalAuth,
  };
};
