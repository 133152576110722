/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines one token address connected with network Id on which it's working
 * @export
 * @interface TokenAddress
 */
export interface TokenAddress {
    /**
     * Id of the network
     * @type {number}
     * @memberof TokenAddress
     */
    networkId: number;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof TokenAddress
     */
    contractAddress: string;
}

export function TokenAddressFromJSON(json: any): TokenAddress {
    return TokenAddressFromJSONTyped(json, false);
}

export function TokenAddressFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenAddress {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'networkId': json['networkId'],
        'contractAddress': json['contractAddress'],
    };
}

export function TokenAddressToJSON(value?: TokenAddress | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'networkId': value.networkId,
        'contractAddress': value.contractAddress,
    };
}

