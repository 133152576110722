import { useCallback } from 'react';

import { UseQueryOptions, useQuery } from 'react-query';

import { CustomField, CustomFieldRequest } from '@dynamic-labs/sdk-api'; // Adjust based on actual types

import { useRedcoastQueryContext } from '../../../contexts/RedcoastQueryContextProvider';

type ApiErrorResponse = {
  json: () => Promise<{ error: string; status: number }>;
};

export type UseCustomFieldsHook = (
  params: { environmentId: string },
  options?: UseQueryOptions<CustomField[], unknown, CustomField[], string[]>,
) => {
  addCustomField: (
    customFieldRequest: CustomFieldRequest,
  ) => Promise<CustomField>;
  customFields: CustomField[] | undefined;
  deleteCustomField: (customFieldId: string) => Promise<void>;
  isLoading: boolean;
  updateCustomField: (
    customFieldId: string,
    customFieldRequest: CustomFieldRequest,
  ) => Promise<CustomField | void>;
};

export const useCustomFields: UseCustomFieldsHook = (
  { environmentId },
  options,
) => {
  const { customFieldsApi } = useRedcoastQueryContext();

  const queryFn = useCallback(() => {
    if (environmentId === '') return Promise.resolve([]);
    return customFieldsApi.getCustomFieldsForEnvironment({ environmentId });
  }, [customFieldsApi, environmentId]);

  const {
    data: customFields,
    isFetching,
    refetch,
  } = useQuery({
    queryFn,
    queryKey: ['customFields', environmentId],
    ...options,
  });

  const handleApiError = (message: {
    error: string;
    status: number;
  }): string => {
    if (message.status === 400) {
      if (message.error.includes('request/body/name')) {
        return 'Invalid Field Name';
      }
      if (message.error.includes('request/body/validationRules/validOptions')) {
        return 'Must Include a value for all included options';
      }
      return message.error;
    }
    return message.error;
  };

  return {
    addCustomField: async (customFieldRequest) => {
      try {
        const createdField =
          await customFieldsApi.createCustomFieldForEnvironment({
            customFieldRequest,
            environmentId,
          });
        await refetch();
        return createdField;
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();
        const errorMessage = handleApiError(message);
        throw new Error(errorMessage);
      }
    },

    customFields,

    deleteCustomField: async (customFieldId) => {
      try {
        await customFieldsApi.deleteCustomFieldById({ customFieldId });
        await refetch();
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();
        throw new Error(message.error);
      }
    },

    isLoading: isFetching,

    updateCustomField: async (customFieldId, customFieldRequest) => {
      try {
        const updatedField = await customFieldsApi.updateCustomFieldById({
          customFieldId,
          customFieldRequest,
        });
        await refetch();
        return updatedField;
      } catch (error) {
        const message = await (error as ApiErrorResponse).json();
        const errorMessage = handleApiError(message);
        throw new Error(errorMessage);
      }
    },
  };
};
