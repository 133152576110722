/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublicKeyCredentialType,
    PublicKeyCredentialTypeFromJSON,
    PublicKeyCredentialTypeFromJSONTyped,
    PublicKeyCredentialTypeToJSON,
} from './PublicKeyCredentialType';

/**
 * 
 * @export
 * @interface PublicKeyCredentialDescriptor
 */
export interface PublicKeyCredentialDescriptor {
    /**
     * 
     * @type {string}
     * @memberof PublicKeyCredentialDescriptor
     */
    id: string;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialDescriptor
     */
    type: PublicKeyCredentialType;
}

export function PublicKeyCredentialDescriptorFromJSON(json: any): PublicKeyCredentialDescriptor {
    return PublicKeyCredentialDescriptorFromJSONTyped(json, false);
}

export function PublicKeyCredentialDescriptorFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicKeyCredentialDescriptor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'type': PublicKeyCredentialTypeFromJSON(json['type']),
    };
}

export function PublicKeyCredentialDescriptorToJSON(value?: PublicKeyCredentialDescriptor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': PublicKeyCredentialTypeToJSON(value.type),
    };
}

