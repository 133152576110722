/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TokenAddress,
    TokenAddressFromJSON,
    TokenAddressFromJSONTyped,
    TokenAddressToJSON,
} from './TokenAddress';

/**
 * Defined an one token which can have multiple contract addresses dependent to networkId
 * @export
 * @interface ChainToken
 */
export interface ChainToken {
    /**
     * Name of the token
     * @type {string}
     * @memberof ChainToken
     */
    name: string;
    /**
     * Symbol of the token
     * @type {string}
     * @memberof ChainToken
     */
    symbol: string;
    /**
     * Array of different contract addresses dependent to networkId
     * @type {Array<TokenAddress>}
     * @memberof ChainToken
     */
    tokenAddresses: Array<TokenAddress>;
}

export function ChainTokenFromJSON(json: any): ChainToken {
    return ChainTokenFromJSONTyped(json, false);
}

export function ChainTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChainToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'symbol': json['symbol'],
        'tokenAddresses': ((json['tokenAddresses'] as Array<any>).map(TokenAddressFromJSON)),
    };
}

export function ChainTokenToJSON(value?: ChainToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'symbol': value.symbol,
        'tokenAddresses': ((value.tokenAddresses as Array<any>).map(TokenAddressToJSON)),
    };
}

