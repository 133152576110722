/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AnalyticsSessionsByDate,
    AnalyticsSessionsByDateFromJSON,
    AnalyticsSessionsByDateFromJSONTyped,
    AnalyticsSessionsByDateToJSON,
} from './AnalyticsSessionsByDate';

/**
 * 
 * @export
 * @interface AnalyticsVisitResponse
 */
export interface AnalyticsVisitResponse {
    /**
     * 
     * @type {number}
     * @memberof AnalyticsVisitResponse
     */
    summedSessionsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof AnalyticsVisitResponse
     */
    summedTotalUniqueCount?: number;
    /**
     * 
     * @type {AnalyticsSessionsByDate}
     * @memberof AnalyticsVisitResponse
     */
    users?: AnalyticsSessionsByDate;
    /**
     * 
     * @type {AnalyticsSessionsByDate}
     * @memberof AnalyticsVisitResponse
     */
    visits?: AnalyticsSessionsByDate;
}

export function AnalyticsVisitResponseFromJSON(json: any): AnalyticsVisitResponse {
    return AnalyticsVisitResponseFromJSONTyped(json, false);
}

export function AnalyticsVisitResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AnalyticsVisitResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'summedSessionsCount': !exists(json, 'summedSessionsCount') ? undefined : json['summedSessionsCount'],
        'summedTotalUniqueCount': !exists(json, 'summedTotalUniqueCount') ? undefined : json['summedTotalUniqueCount'],
        'users': !exists(json, 'users') ? undefined : AnalyticsSessionsByDateFromJSON(json['users']),
        'visits': !exists(json, 'visits') ? undefined : AnalyticsSessionsByDateFromJSON(json['visits']),
    };
}

export function AnalyticsVisitResponseToJSON(value?: AnalyticsVisitResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'summedSessionsCount': value.summedSessionsCount,
        'summedTotalUniqueCount': value.summedTotalUniqueCount,
        'users': AnalyticsSessionsByDateToJSON(value.users),
        'visits': AnalyticsSessionsByDateToJSON(value.visits),
    };
}

