import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import { object, string } from 'yup';

import { ProjectSettingsGeneral } from '@dynamic-labs/sdk-api';

import { logger } from '../../../services/logger';
import { ConfirmToast } from '../../../components/ConfirmToast';
import { useGeneralSettings } from '../../../context/SettingsContext/helpers/generalSettings';
import { useDashboardContext } from '../../../context/DashboardContext';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import { environmentsApi } from '../../../services/api';

import styles from './General.module.css';
import { SupportedApps } from './SupportedApps';
import { GeneralFields } from './GeneralFields';
import { FormErrors } from './FormErrors';

export const General = () => {
  const { t } = useTranslation();
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const {
    updateInitialSettings,
    globalSettings,
    settingsHasChanged,
    settings,
    cancelChanges,
  } = useGeneralSettings();
  const { activeOrganization } = useDashboardContext();
  const {
    displayName,
    appLogo,
    imageUserNotInAccessList,
    imageUserInAccessList,
    collectUserDataWelcomeHeader,
    collectUserDataWelcomeMessage,
    emailCompanyName,
    supportEmail,
    supportUrls,
  } = settings || {};
  const [loading, setLoading] = useState(false);

  const onSubmit = async () => {
    try {
      setLoading(true);

      const response = await environmentsApi.updateProjectSettings({
        environmentId: activeEnvironment?.id ?? '',
        projectSettings: globalSettings[activeEnvironmentType],
      });

      if (!response?.general) return;

      updateInitialSettings();
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    cancelChanges();
  };

  const companyNameRegex = /^(?=\S)[\p{L}\p{N} _.,:!?&%@/+-]+(?<=\S)$/u;
  const appLogoRegex = /.*\.(jpg|jpeg|png)$/i;

  const validationSchema = object().shape({
    appLogo: string().url().matches(appLogoRegex),
    collectUserDataWelcomeHeader: string(),
    collectUserDataWelcomeMessage: string().max(100),
    displayName: string().matches(companyNameRegex),
    emailCompanyName: string().matches(companyNameRegex),
    imageUserInAccessList: string().url(),
    imageUserNotInAccessList: string().url(),
    supportEmail: string().email(),
    supportUrls: object().shape({
      discord: string().url(),
      helpDesk: string().url(),
      slack: string().url(),
      twitter: string().url(),
    }),
  });

  const initialValues: ProjectSettingsGeneral = useMemo(
    () => ({
      appLogo: appLogo ?? '',
      collectUserDataWelcomeHeader: collectUserDataWelcomeHeader ?? '',
      collectUserDataWelcomeMessage: collectUserDataWelcomeMessage ?? '',
      displayName: displayName ?? activeOrganization?.name ?? '',
      emailCompanyName: emailCompanyName ?? activeOrganization?.name ?? '',
      imageUserInAccessList: imageUserInAccessList ?? '',
      imageUserNotInAccessList: imageUserNotInAccessList ?? '',
      supportEmail: supportEmail ?? '',
      supportUrls: {
        discord: supportUrls?.discord ?? '',
        helpDesk: supportUrls?.helpDesk ?? '',
        slack: supportUrls?.slack ?? '',
        twitter: supportUrls?.twitter ?? '',
      },
    }),
    [
      activeEnvironmentType,
      activeOrganization?.name,
      appLogo,
      collectUserDataWelcomeHeader,
      collectUserDataWelcomeMessage,
      displayName,
      emailCompanyName,
      globalSettings,
      imageUserInAccessList,
      imageUserNotInAccessList,
      supportEmail,
    ],
  );

  if (!Object.keys(globalSettings[activeEnvironmentType].general).length) {
    return null;
  }

  return (
    <section className={styles.wrapper} data-testid='settings-general'>
      <div className={styles.container}>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ errors, handleSubmit }) => (
            <Form>
              <FormErrors />
              <GeneralFields />
              <SupportedApps />
              <AnimatePresence>
                {Object.keys(errors).length === 0 && settingsHasChanged && (
                  <ConfirmToast
                    confirmationText={t('settings_page.general.confirmButton')}
                    message={t('settings_page.general.confirmMessage')}
                    onConfirm={handleSubmit}
                    loading={loading}
                    cancelText={t('settings_page.general.cancelButton')}
                    onCancel={handleCancelClick}
                  />
                )}
              </AnimatePresence>
            </Form>
          )}
        </Formik>
      </div>
    </section>
  );
};
