/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    BillingSubscription,
    BillingSubscriptionFromJSON,
    BillingSubscriptionToJSON,
    BillingSubscriptionCoupon,
    BillingSubscriptionCouponFromJSON,
    BillingSubscriptionCouponToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Organization,
    OrganizationFromJSON,
    OrganizationToJSON,
    OrganizationFields,
    OrganizationFieldsFromJSON,
    OrganizationFieldsToJSON,
    OrganizationRequest,
    OrganizationRequestFromJSON,
    OrganizationRequestToJSON,
    OrganizationResponse,
    OrganizationResponseFromJSON,
    OrganizationResponseToJSON,
    OrganizationsResponse,
    OrganizationsResponseFromJSON,
    OrganizationsResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface ApplyCouponForSubscriptionRequest {
    organizationId: string;
    billingSubscriptionCoupon: BillingSubscriptionCoupon;
}

export interface CreateOrganizationRequest {
    organizationRequest: OrganizationRequest;
}

export interface GetBillingSubscriptionByOrganizationRequest {
    organizationId: string;
}

export interface GetOrganizationByIdRequest {
    organizationId: string;
}

export interface UpdateOrganizationByIdRequest {
    organizationId: string;
    organizationFields: OrganizationFields;
}

export interface UpgradeSubscriptionForOrganizationRequest {
    organizationId: string;
}

/**
 * 
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     * Add coupon to subscription
     */
    async applyCouponForSubscriptionRaw(requestParameters: ApplyCouponForSubscriptionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingSubscription>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling applyCouponForSubscription.');
        }

        if (requestParameters.billingSubscriptionCoupon === null || requestParameters.billingSubscriptionCoupon === undefined) {
            throw new runtime.RequiredError('billingSubscriptionCoupon','Required parameter requestParameters.billingSubscriptionCoupon was null or undefined when calling applyCouponForSubscription.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/billing/subscription/coupon`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: BillingSubscriptionCouponToJSON(requestParameters.billingSubscriptionCoupon),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingSubscriptionFromJSON(jsonValue));
    }

    /**
     * Add coupon to subscription
     */
    async applyCouponForSubscription(requestParameters: ApplyCouponForSubscriptionRequest, initOverrides?: RequestInit): Promise<BillingSubscription> {
        const response = await this.applyCouponForSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Creates organization
     */
    async createOrganizationRaw(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.organizationRequest === null || requestParameters.organizationRequest === undefined) {
            throw new runtime.RequiredError('organizationRequest','Required parameter requestParameters.organizationRequest was null or undefined when calling createOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationRequestToJSON(requestParameters.organizationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Creates organization
     */
    async createOrganization(requestParameters: CreateOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> {
        const response = await this.createOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find the subscription of an organization using its ID
     */
    async getBillingSubscriptionByOrganizationRaw(requestParameters: GetBillingSubscriptionByOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingSubscription>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getBillingSubscriptionByOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/billing/subscription`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingSubscriptionFromJSON(jsonValue));
    }

    /**
     * Find the subscription of an organization using its ID
     */
    async getBillingSubscriptionByOrganization(requestParameters: GetBillingSubscriptionByOrganizationRequest, initOverrides?: RequestInit): Promise<BillingSubscription> {
        const response = await this.getBillingSubscriptionByOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Find an organization by ID
     */
    async getOrganizationByIdRaw(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Organization>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationFromJSON(jsonValue));
    }

    /**
     * Find an organization by ID
     */
    async getOrganizationById(requestParameters: GetOrganizationByIdRequest, initOverrides?: RequestInit): Promise<Organization> {
        const response = await this.getOrganizationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Fetches all the active organizations that the user has access to
     */
    async getOrganizationsForMemberRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationsResponseFromJSON(jsonValue));
    }

    /**
     * Fetches all the active organizations that the user has access to
     */
    async getOrganizationsForMember(initOverrides?: RequestInit): Promise<OrganizationsResponse> {
        const response = await this.getOrganizationsForMemberRaw(initOverrides);
        return await response.value();
    }

    /**
     * Update an organization by ID
     */
    async updateOrganizationByIdRaw(requestParameters: UpdateOrganizationByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling updateOrganizationById.');
        }

        if (requestParameters.organizationFields === null || requestParameters.organizationFields === undefined) {
            throw new runtime.RequiredError('organizationFields','Required parameter requestParameters.organizationFields was null or undefined when calling updateOrganizationById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationFieldsToJSON(requestParameters.organizationFields),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Update an organization by ID
     */
    async updateOrganizationById(requestParameters: UpdateOrganizationByIdRequest, initOverrides?: RequestInit): Promise<OrganizationResponse> {
        const response = await this.updateOrganizationByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upgrade organziation to advanced plan
     */
    async upgradeSubscriptionForOrganizationRaw(requestParameters: UpgradeSubscriptionForOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<BillingSubscription>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling upgradeSubscriptionForOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/billing/upgrade`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BillingSubscriptionFromJSON(jsonValue));
    }

    /**
     * Upgrade organziation to advanced plan
     */
    async upgradeSubscriptionForOrganization(requestParameters: UpgradeSubscriptionForOrganizationRequest, initOverrides?: RequestInit): Promise<BillingSubscription> {
        const response = await this.upgradeSubscriptionForOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
