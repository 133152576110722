import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { logger } from '../../../../../services/logger';
import Header from '../../../../../components/Portal/Header';
import Portal from '../../../../../components/Portal';
import Button from '../../../../../components/Button';
import { Typography } from '../../../../../components/Typography';
import { invitesApi } from '../../../../../services/api';
import { useMembersContext } from '../../../../../context/MembersContext';

import styles from './CancelInviteModal.module.css';

interface CancelInviteModalProps {
  alias: string;
  inviteId: string;
  setShowModal: (showModal: boolean) => void;
}

export const CancelInviteModal = ({
  alias,
  inviteId,
  setShowModal,
}: CancelInviteModalProps) => {
  const { t } = useTranslation();
  const { fetchMembers, setMembers } = useMembersContext();

  const [loading, setLoading] = useState(false);

  const cancelInvite = async () => {
    setLoading(true);
    try {
      await invitesApi.deleteInvite({
        inviteId,
      });
      const members = await fetchMembers();
      setMembers(members);
    } catch (e) {
      logger.error(e);
    } finally {
      setShowModal(false);
      setLoading(false);
    }
  };

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <Header
        handleClose={() => setShowModal(false)}
        headingText={t('admin.table.modal.cancelInviteTitle')}
      />
      <Typography
        variant='paragraph-2'
        weight='medium'
        className='text-gray-1 mb-4'
      >
        {t('admin.table.modal.cancelInviteText', { alias })}
      </Typography>
      <div className={styles.buttons}>
        <Button
          type='button'
          large
          variant='secondary'
          onClick={() => setShowModal(false)}
        >
          {t('admin.table.modal.cancelButton')}
        </Button>
        <Button type='button' loading={loading} large onClick={cancelInvite}>
          {t('admin.table.modal.confirmButton')}
        </Button>
      </div>
    </Portal>
  );
};
