import { useMemo, useState } from 'react';

import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { useSubscriptionContext } from '../../../app/context/SubscriptionContext';

export const useEnvironmentsDropdown = () => {
  const [openLockedLiveModal, setOpenLockedLiveModal] = useState(false);
  const { activeEnvironmentType, environments, setActiveEnvironmentType } =
    useEnvironmentsContext();

  const { subscription } = useSubscriptionContext();

  const shouldLockEnvironmentSwitch = subscription?.shouldLockEnvironmentSwitch;

  const environmentsDropdown = useMemo(
    () => [
      {
        disabled: false,
        id: environments?.sandbox.id ?? '',
        type: EnvironmentEnum.Sandbox,
      },
      {
        disabled: shouldLockEnvironmentSwitch,
        id: environments?.live.id ?? '',
        onDisabledClick: () => setOpenLockedLiveModal(true),
        type: EnvironmentEnum.Live,
      },
    ],
    [environments, shouldLockEnvironmentSwitch],
  );

  return {
    environmentsDropdown: {
      activeEnvironmentType,
      environments: environmentsDropdown,
      setActiveEnvironmentType,
    },
    openLockedLiveModal,
    setOpenLockedLiveModal,
  };
};
