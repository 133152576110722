import { WidgetViewComponent } from './WidgetView.types';
import styles from './WidgetView.module.css';

export const WidgetView: WidgetViewComponent = ({ title, children }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>

    {children}
  </div>
);
