import React, { useRef, useState } from 'react';

import { AnimatePresence, m } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { CopyIcon } from '../../../icons';
import { Popper } from '../Popper';

import styles from './copy-button.module.css';

type CopyButtonColors = 'primary' | 'gray';

interface Props {
  className?: string;
  color?: CopyButtonColors;
  textToCopy: string;
  type: 'text' | 'icon';
}

const CopyButton: React.FC<Props> = ({
  className = '',
  textToCopy,
  type,
  color = 'primary',
}) => {
  const { t } = useTranslation();
  const [copy, setCopy] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  const copyToClipboard = () => {
    setIsAnimating(true);
    navigator.clipboard.writeText(textToCopy);
    setCopy(true);
    setTimeout(() => {
      setIsAnimating(false);
      setCopy(false);
    }, 2000);
  };

  const copyButtonClassName = classNames(styles.wrapper, className, {
    [styles['is-animating']]: isAnimating,
  });

  return (
    <div className={copyButtonClassName}>
      <AnimatePresence>
        {copy && (
          <Popper anchorRef={ref} anchorOrigin='top-center'>
            <m.div
              className={styles.tooltip}
              initial={{ opacity: 0 }}
              animate={{
                opacity: [0, 1, 1, 0],
                transition: { duration: 2, times: [0, 0.2, 0.6, 0.9] },
              }}
              exit={{ opacity: 0 }}
            >
              {t('copy_button.copied_to_clipboard')}
            </m.div>
          </Popper>
        )}
      </AnimatePresence>
      {type === 'text' && (
        <button
          ref={ref}
          className={`${styles.container} ${copy ? styles.active : ''}`}
          type='button'
          onBlur={() => setCopy(false)}
          onClick={() => copyToClipboard()}
        >
          {copy ? t('copy_button.copied') : t('copy_button.copy')}
        </button>
      )}
      {type === 'icon' && (
        <button type='button' onClick={() => copyToClipboard()} ref={ref}>
          <CopyIcon className={styles[`icon--${color}`]} />
        </button>
      )}
    </div>
  );
};

export default CopyButton;
