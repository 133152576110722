/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MFADevice,
    MFADeviceFromJSON,
    MFADeviceFromJSONTyped,
    MFADeviceToJSON,
} from './MFADevice';

/**
 * 
 * @export
 * @interface MFAListDevicesResponse
 */
export interface MFAListDevicesResponse {
    /**
     * The number of MFA devices available
     * @type {number}
     * @memberof MFAListDevicesResponse
     */
    count: number;
    /**
     * 
     * @type {Array<MFADevice>}
     * @memberof MFAListDevicesResponse
     */
    devices: Array<MFADevice>;
}

export function MFAListDevicesResponseFromJSON(json: any): MFAListDevicesResponse {
    return MFAListDevicesResponseFromJSONTyped(json, false);
}

export function MFAListDevicesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAListDevicesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': json['count'],
        'devices': ((json['devices'] as Array<any>).map(MFADeviceFromJSON)),
    };
}

export function MFAListDevicesResponseToJSON(value?: MFAListDevicesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'devices': ((value.devices as Array<any>).map(MFADeviceToJSON)),
    };
}

