/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PasskeyStorage,
    PasskeyStorageFromJSON,
    PasskeyStorageFromJSONTyped,
    PasskeyStorageToJSON,
} from './PasskeyStorage';

/**
 * 
 * @export
 * @interface UserPasskey
 */
export interface UserPasskey {
    /**
     * 
     * @type {string}
     * @memberof UserPasskey
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasskey
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasskey
     */
    userAgent?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasskey
     */
    origin?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasskey
     */
    credentialId: string;
    /**
     * 
     * @type {Date}
     * @memberof UserPasskey
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserPasskey
     */
    updatedAt?: Date;
    /**
     * 
     * @type {PasskeyStorage}
     * @memberof UserPasskey
     */
    storage?: PasskeyStorage;
}

export function UserPasskeyFromJSON(json: any): UserPasskey {
    return UserPasskeyFromJSONTyped(json, false);
}

export function UserPasskeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserPasskey {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'userAgent': !exists(json, 'userAgent') ? undefined : json['userAgent'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'credentialId': json['credentialId'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'storage': !exists(json, 'storage') ? undefined : PasskeyStorageFromJSON(json['storage']),
    };
}

export function UserPasskeyToJSON(value?: UserPasskey | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'alias': value.alias,
        'userAgent': value.userAgent,
        'origin': value.origin,
        'credentialId': value.credentialId,
        'createdAt': (value.createdAt.toISOString()),
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt.toISOString()),
        'storage': PasskeyStorageToJSON(value.storage),
    };
}

