import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

export const convertProvidersToMap = (providers: Provider[]) =>
  providers.reduce(
    (providersMap, provider) => ({
      ...providersMap,
      [provider.provider]: provider,
    }),
    {} as Record<ProviderEnum, Provider>,
  );
