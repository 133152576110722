import { useState } from 'react';

import { t } from 'i18next';

import { DeleteModal } from '../../../../../../../components/DeleteModal';
import { logger } from '../../../../../../../services/logger';
import { mfaApi } from '../../../../../../../services/api';

type ResetMfaModalProps = {
  onClose(): void;
  onDelete(): void;
  userId: string;
};

export const ResetMfaModal = ({
  onClose,
  onDelete,
  userId,
}: ResetMfaModalProps) => {
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);

    try {
      await mfaApi.resetAllMfaDevices({
        userId,
      });

      setLoading(false);
      onDelete();
    } catch (e) {
      setLoading(false);
      logger.error(e);
    }
  };

  return (
    <DeleteModal
      title={t<string>('users_details_modal.account.mfa.reset_mfa_modal.title')}
      text={t<string>('users_details_modal.account.mfa.reset_mfa_modal.text')}
      onClose={onClose}
      onDelete={handleDeleteClick}
      loading={loading}
    />
  );
};
