/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PublishEventsEvents
 */
export interface PublishEventsEvents {
    /**
     * 
     * @type {string}
     * @memberof PublishEventsEvents
     */
    anonymousId: string;
    /**
     * 
     * @type {string}
     * @memberof PublishEventsEvents
     */
    event: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PublishEventsEvents
     */
    properties: { [key: string]: object; };
    /**
     * 
     * @type {string}
     * @memberof PublishEventsEvents
     */
    type: PublishEventsEventsTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum PublishEventsEventsTypeEnum {
    Track = 'track'
}

export function PublishEventsEventsFromJSON(json: any): PublishEventsEvents {
    return PublishEventsEventsFromJSONTyped(json, false);
}

export function PublishEventsEventsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublishEventsEvents {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'anonymousId': json['anonymousId'],
        'event': json['event'],
        'properties': json['properties'],
        'type': json['type'],
    };
}

export function PublishEventsEventsToJSON(value?: PublishEventsEvents | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'anonymousId': value.anonymousId,
        'event': value.event,
        'properties': value.properties,
        'type': value.type,
    };
}

