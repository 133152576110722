import classNames from 'classnames';

import { ChartSummaryData } from './ChartSummaryItem.types';
import styles from './ChartSummaryItem.module.css';

export const ChartSummaryItem = ({
  disabled = false,
  label,
  userType,
  value,
}: ChartSummaryData) => {
  const chartSummaryClasses = classNames(styles['chart-summary__item'], {
    [styles['chart-summary__item--disabled']]: disabled,
  });

  return (
    <div className={chartSummaryClasses}>
      <div className={styles['chart-summary__copy']}>
        <div
          className={`${styles['chart-summary__bullet']} ${
            styles[`chart-summary__bullet--${userType}`]
          }`}
        />
        <div className={styles['chart-summary__label']}>{label}</div>
      </div>
      <div className={styles['chart-summary__value']}>
        {typeof value === 'number' ? value.toLocaleString() : value}
      </div>
    </div>
  );
};
