import {
  ComponentPropsWithoutRef,
  forwardRef,
  ReactElement,
  useCallback,
  useId,
  useRef,
} from 'react';

import classNames from 'classnames';

import { ClassStyleProps } from '../../utils/types';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { IconButton } from '../IconButton';

import styles from './Input.module.scss';

export type InputProps = Pick<
  ComponentPropsWithoutRef<'input'>,
  'id' | 'type' | 'onChange' | 'onBlur' | 'value'
> &
  ClassStyleProps & {
    Icon?: ReactElement;
    ariaLabel?: string;
    autofocus?: boolean;
    disabled?: boolean;
    error?: boolean;
    errorText?: string;
    iconAction?: () => void;
    label?: string;
    // Use this prop to mask sensitive content from sending to datadog
    // https://docs.datadoghq.com/real_user_monitoring/session_replay/browser/privacy_options/#mask-mode
    maskDatadogValue?: boolean;
    name: string;
    optional?: boolean;
    optionalText?: string;
    padding?: 'small' | 'regular';
    readOnly?: boolean;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      style,
      disabled,
      readOnly,
      maskDatadogValue,
      type,
      id,
      name,
      onBlur,
      onChange,
      error,
      errorText,
      value,
      optional = false,
      optionalText,
      Icon: icon,
      padding = 'regular',
      autofocus,
      iconAction,
      ariaLabel,
    },
    forwardedRef,
  ) => {
    const hasTriggeredAutofocus = useRef(false);

    const errorTextId = useId();

    // We use a custom ref so that we can also manipulate it
    const ref = useCallback(
      (instance: HTMLInputElement | null) => {
        // Trigger autofocus
        if (autofocus && instance && !hasTriggeredAutofocus.current) {
          instance.focus();
          hasTriggeredAutofocus.current = true;
        }

        // Assign forwarded ref
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(instance);
          } else {
            forwardedRef.current = instance;
          }
        }
      },
      [autofocus, forwardedRef],
    );

    return (
      <div className={classNames(className, styles.wrapper)} style={style}>
        <input
          id={id || name}
          disabled={disabled}
          readOnly={readOnly}
          aria-label={label === undefined ? ariaLabel : undefined}
          aria-describedby={`${errorTextId}`}
          data-testid={`input-${id || name}`}
          data-dd-privacy={maskDatadogValue ? 'mask' : undefined}
          type={type}
          onBlur={onBlur}
          onChange={onChange}
          name={name}
          placeholder={label}
          ref={ref}
          value={value}
          className={classNames(
            styles.input,
            styles[`input--padding-${padding}`],
            {
              [styles['input--with-label']]: label,
              [styles['input--error']]: error,
            },
          )}
        />

        <label
          className={classNames(
            styles.label,
            styles[`label--padding-${padding}`],
            {
              [styles['label--error']]: error,
            },
          )}
          htmlFor={id || name}
        >
          {label}
        </label>

        <label
          className={classNames(styles.right, {
            [styles['right--error']]: error,
          })}
          data-testid='input-right-side'
          htmlFor={id || name}
        >
          {optional && (
            <Typography
              variant='paragraph-1'
              className={styles.optional}
              color='inherit'
            >
              {optionalText || 'Optional'}
            </Typography>
          )}
          {icon && iconAction ? (
            <IconButton
              icon={icon}
              onClick={iconAction}
              size={16}
              testId={`icon-button-${id || name}`}
              disabled={disabled}
            />
          ) : (
            icon && <Icon icon={icon} className={styles.icon} size={16} />
          )}
        </label>

        {errorText && (
          <Typography
            id={errorTextId}
            variant='paragraph-1'
            color='inherit'
            className={classNames(
              styles.errorText,
              styles[`errorText--padding-${padding}`],
            )}
          >
            {errorText}
          </Typography>
        )}
      </div>
    );
  },
);
