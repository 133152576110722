/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Invite,
    InviteFromJSON,
    InviteFromJSONTyped,
    InviteToJSON,
} from './Invite';

/**
 * 
 * @export
 * @interface InvitesResponse
 */
export interface InvitesResponse {
    /**
     * 
     * @type {number}
     * @memberof InvitesResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<Invite>}
     * @memberof InvitesResponse
     */
    invites?: Array<Invite>;
}

export function InvitesResponseFromJSON(json: any): InvitesResponse {
    return InvitesResponseFromJSONTyped(json, false);
}

export function InvitesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvitesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'count': !exists(json, 'count') ? undefined : json['count'],
        'invites': !exists(json, 'invites') ? undefined : ((json['invites'] as Array<any>).map(InviteFromJSON)),
    };
}

export function InvitesResponseToJSON(value?: InvitesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'count': value.count,
        'invites': value.invites === undefined ? undefined : ((value.invites as Array<any>).map(InviteToJSON)),
    };
}

