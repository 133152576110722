import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  capitalize,
  DropdownMenu,
  Icon,
  Input,
  Typography,
} from '@dynamic-labs/northstar';
import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';
import { getChainIcon } from '@dynamic-labs/sdk-react-core';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { NetworkDropdownValue } from '../../../../../../../app/routes/Configurations/Providers/utils';
import { useEnabledChains } from '../../../../../../../app/routes/Configurations/Providers/hooks/useEnabledChains';
import { getNetworkIcon } from '../../../../../../../app/utils/getNetworkIcon';

import styles from './MagicCredentialsSection.module.scss';

const AVAILABLE_CHAINS = ['evm', 'flow'];
const getCAIP2Namespace = (chainName: string) => {
  if (chainName === 'evm') {
    return 'eip155';
  }
  return 'flow';
};

const getCAIP2reference = (chainName: string, chainId?: number) => {
  if (chainName === 'evm') {
    return chainId;
  }
  // for flow return mainnet
  return 'mainnet';
};

export const MagicCredentialsSection: FC = () => {
  const { t } = useTranslation();
  const { getProviderValue, onChangeProvider } = useProvidersContext();
  const enabledChains = useEnabledChains();

  const handleInputValue = (value: string, name: keyof Provider) => {
    onChangeProvider(ProviderEnum.MagicLink, name, value || null);
  };

  const availableEnabledChainsForMagic = enabledChains?.filter((chain) =>
    AVAILABLE_CHAINS.includes(chain.name),
  );

  const dropdownValues: NetworkDropdownValue[] = availableEnabledChainsForMagic
    ?.flatMap((chain) => {
      const enabledNetworks = chain?.networks?.filter(
        (network) => network.enabled,
      );

      if (enabledNetworks && enabledNetworks.length > 0) {
        return enabledNetworks.flatMap((network) => ({
          caip2Value: `${getCAIP2Namespace(chain.name)}:${getCAIP2reference(
            chain.name,
            network.networkId,
          )}`,
          chainName: chain.name,
          displayName: capitalize(network.chainName),
          networkId: network.networkId,
        }));
      }

      return {
        caip2Value: `${getCAIP2Namespace(chain.name)}:${getCAIP2reference(
          chain.name,
        )}`,
        chainName: chain.name,
        displayName: `${capitalize(chain.name)} Mainnet`,
      };
    })
    .sort((a, b) => a.caip2Value.localeCompare(b.caip2Value));

  const currentDefaultChain = getProviderValue(
    ProviderEnum.MagicLink,
    'defaultChain',
  );

  const getDropdownIcon = (chain: NetworkDropdownValue) => {
    const DropdownIcon = chain.networkId
      ? getNetworkIcon(chain.networkId)
      : getChainIcon(chain.chainName);

    return <Icon size={20} icon={<DropdownIcon />} />;
  };

  return (
    <div className={styles.wrapper}>
      <Typography variant='paragraph-2' weight='regular' color='gray-1'>
        {t(
          'v2.page.embedded_wallets.third_party.magicLink.credentials.description',
        )}
      </Typography>

      <Input
        name='clientId'
        label={t(
          'v2.page.embedded_wallets.third_party.magicLink.credentials.client_id',
        )}
        onChange={(e) => handleInputValue(e.target.value, 'clientId')}
        value={getProviderValue(ProviderEnum.MagicLink, 'clientId') || ''}
        maskDatadogValue
      />

      <Input
        name='clientSecret'
        label={t(
          'v2.page.embedded_wallets.third_party.magicLink.credentials.client_secret',
        )}
        onChange={(e) => handleInputValue(e.target.value, 'clientSecret')}
        value={getProviderValue(ProviderEnum.MagicLink, 'clientSecret') || ''}
        maskDatadogValue
      />

      <DropdownMenu
        value={currentDefaultChain}
        onSelect={(value) =>
          onChangeProvider(ProviderEnum.MagicLink, 'defaultChain', value)
        }
        allowUndefinedValue
        options={dropdownValues.map((chain) => (
          <DropdownMenu.Item
            value={chain.caip2Value}
            key={chain.caip2Value}
            rawOption={chain}
          >
            <div className={styles.item}>
              {getDropdownIcon(chain)}
              {chain.displayName}
            </div>
          </DropdownMenu.Item>
        ))}
        itemsSideOffset={4}
        placeholderText={t(
          'v2.page.embedded_wallets.third_party.magicLink.credentials.chain_dropdown_placeholder',
        )}
        zIndex={100}
      />

      <Input
        name='keyExportUrl'
        label={t(
          'v2.page.embedded_wallets.third_party.magicLink.credentials.key_export_url',
        )}
        onChange={(e) => handleInputValue(e.target.value, 'keyExportUrl')}
        value={getProviderValue(ProviderEnum.MagicLink, 'keyExportUrl') || ''}
        maskDatadogValue
        optional
      />
    </div>
  );
};
