import ChainContent from '../ChainContent/ChainContent';
import { IntegrationCategory } from '../../../../types';
import { useSingleSettingState } from '../../../../hooks/useSingleSettingState';

type Props = {
  name: string;
  networkMessage?: string;
  requireCustomRpcUrl?: boolean;
};

export const ChainControls = ({
  name,
  networkMessage,
  requireCustomRpcUrl,
}: Props) => {
  const { setSingleSetting, singleSetting, singleInitialSetting } =
    useSingleSettingState({
      category: IntegrationCategory.Chains,
      name,
    });

  if (!singleInitialSetting || !singleSetting) {
    return null;
  }

  return (
    <ChainContent
      initialChainSetting={singleInitialSetting}
      chainSetting={singleSetting}
      setSingleSetting={setSingleSetting}
      networkMessage={networkMessage}
      requireCustomRpcUrl={requireCustomRpcUrl}
    />
  );
};
