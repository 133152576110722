/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    WalletAdditionalAddress,
    WalletAdditionalAddressFromJSON,
    WalletAdditionalAddressFromJSONTyped,
    WalletAdditionalAddressToJSON,
} from './WalletAdditionalAddress';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface CreateWalletRequest
 */
export interface CreateWalletRequest {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof CreateWalletRequest
     */
    publicWalletAddress: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof CreateWalletRequest
     */
    chain: ChainEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateWalletRequest
     */
    walletName: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof CreateWalletRequest
     */
    walletProvider: WalletProviderEnum;
    /**
     * 
     * @type {Array<WalletAdditionalAddress>}
     * @memberof CreateWalletRequest
     */
    additionalWalletAddresses?: Array<WalletAdditionalAddress>;
}

export function CreateWalletRequestFromJSON(json: any): CreateWalletRequest {
    return CreateWalletRequestFromJSONTyped(json, false);
}

export function CreateWalletRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateWalletRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicWalletAddress': json['publicWalletAddress'],
        'chain': ChainEnumFromJSON(json['chain']),
        'walletName': json['walletName'],
        'walletProvider': WalletProviderEnumFromJSON(json['walletProvider']),
        'additionalWalletAddresses': !exists(json, 'additionalWalletAddresses') ? undefined : ((json['additionalWalletAddresses'] as Array<any>).map(WalletAdditionalAddressFromJSON)),
    };
}

export function CreateWalletRequestToJSON(value?: CreateWalletRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicWalletAddress': value.publicWalletAddress,
        'chain': ChainEnumToJSON(value.chain),
        'walletName': value.walletName,
        'walletProvider': WalletProviderEnumToJSON(value.walletProvider),
        'additionalWalletAddresses': value.additionalWalletAddresses === undefined ? undefined : ((value.additionalWalletAddresses as Array<any>).map(WalletAdditionalAddressToJSON)),
    };
}

