/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SupportedSecurityMethod,
    SupportedSecurityMethodFromJSON,
    SupportedSecurityMethodFromJSONTyped,
    SupportedSecurityMethodToJSON,
} from './SupportedSecurityMethod';

/**
 * 
 * @export
 * @interface SupportedSecurityMethods
 */
export interface SupportedSecurityMethods {
    /**
     * 
     * @type {SupportedSecurityMethod}
     * @memberof SupportedSecurityMethods
     */
    passkey?: SupportedSecurityMethod;
    /**
     * 
     * @type {SupportedSecurityMethod}
     * @memberof SupportedSecurityMethods
     */
    email?: SupportedSecurityMethod;
    /**
     * 
     * @type {SupportedSecurityMethod}
     * @memberof SupportedSecurityMethods
     */
    password?: SupportedSecurityMethod;
}

export function SupportedSecurityMethodsFromJSON(json: any): SupportedSecurityMethods {
    return SupportedSecurityMethodsFromJSONTyped(json, false);
}

export function SupportedSecurityMethodsFromJSONTyped(json: any, ignoreDiscriminator: boolean): SupportedSecurityMethods {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passkey': !exists(json, 'passkey') ? undefined : SupportedSecurityMethodFromJSON(json['passkey']),
        'email': !exists(json, 'email') ? undefined : SupportedSecurityMethodFromJSON(json['email']),
        'password': !exists(json, 'password') ? undefined : SupportedSecurityMethodFromJSON(json['password']),
    };
}

export function SupportedSecurityMethodsToJSON(value?: SupportedSecurityMethods | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'passkey': SupportedSecurityMethodToJSON(value.passkey),
        'email': SupportedSecurityMethodToJSON(value.email),
        'password': SupportedSecurityMethodToJSON(value.password),
    };
}

