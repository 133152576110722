import { FC } from 'react';

import { t } from 'i18next';
import { FieldArrayRenderProps } from 'formik';

import { PlusIcon } from '../../../../../../icons';
import Button from '../../../../../components/Button';

import styles from './form-actions.module.css';

interface Props {
  isUpdateMode?: boolean;
  loading: boolean;
  setShowModal: VoidFunction;
  values: FieldArrayRenderProps;
}

const FormActions: FC<Props> = ({
  setShowModal,
  values,
  loading,
  isUpdateMode = false,
}) => (
  <>
    {!isUpdateMode && (
      <Button
        onClick={() => values.push({ alias: '', walletPublicKey: '' })}
        className={styles['button--add']}
        variant='secondary'
        testId='invite-form-add-invite'
      >
        <PlusIcon />
      </Button>
    )}
    <div className={styles.submit__container}>
      <Button
        onClick={setShowModal}
        className={styles['button--cancel']}
        variant='secondary'
      >
        {t<string>('admin.invites_modal.cancel')}
      </Button>
      <Button loading={loading} type='submit' testId='invite-form-submit'>
        {isUpdateMode
          ? t<string>('admin.invites_modal.update')
          : t<string>('admin.invites_modal.send')}
      </Button>
    </div>
  </>
);

export default FormActions;
