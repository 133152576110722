import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { MultiSection } from '@dynamic-labs/northstar';
import {
  ProviderEnum,
  SubscriptionAdvancedScopeEnum,
} from '@dynamic-labs/sdk-api';
import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';

import { useProvidersContext } from '../../../../../app/context/ProvidersContext';
import { MissingSettingsModal, SaveBanner } from '../../../../components';
import { useSaveProvidersSettings } from '../../../../hooks';
import { useSubscriptionLock } from '../../../../../app/hooks/useSubscriptionLock';

import { BloctoRow } from './BloctoRow';
import { MagicRow } from './MagicRow';
import { useSaveThirdPartySection } from './useSaveThirdPartySection';

type ThirdPartySectionProps = {
  refetchWalletsBreakdown: VoidFunction;
};

export const ThirdPartySection: FC<ThirdPartySectionProps> = ({
  refetchWalletsBreakdown,
}) => {
  const { t } = useTranslation();

  const {
    shouldShowSaveBanner,
    handleSave,
    handleReset,
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
    missingSettings,
  } = useSaveThirdPartySection();
  const { enableMagicLinkProviderDashboardIntegration } = useFlags();
  const { providers } = useProvidersContext();
  const { shouldLockOnLive } = useSubscriptionLock(
    SubscriptionAdvancedScopeEnum.SigninWithEmail,
  );
  const { isLoading: isSavingProjectSettings } = useUpdateProjectSettings();

  const { isLoading: isSavingProviderSettings } = useSaveProvidersSettings();

  const magicExists =
    Boolean(
      providers.find(
        (provider) => provider.provider === ProviderEnum.MagicLink,
      ),
    ) || enableMagicLinkProviderDashboardIntegration;

  const bloctoExists = Boolean(
    providers.find((provider) => provider.provider === ProviderEnum.Blocto),
  );

  const disabled = shouldLockOnLive;

  const isLoading = isSavingProjectSettings || isSavingProviderSettings;

  return (
    <>
      <MultiSection>
        {magicExists && (
          <MagicRow
            disabled={disabled}
            refetchWalletsBreakdown={refetchWalletsBreakdown}
          />
        )}
        {bloctoExists && (
          <BloctoRow
            disabled={disabled}
            refetchWalletsBreakdown={refetchWalletsBreakdown}
          />
        )}
      </MultiSection>

      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnSave={handleSave}
            handleOnCancel={handleReset}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {shouldShowMissingSettingsWarning && (
          <MissingSettingsModal
            handleClose={() => setShouldShowMissingSettingsWarning(false)}
            missingSettings={missingSettings}
            title={t(
              'v2.page.embedded_wallets.third_party.missing_settings.title',
            )}
            description={t(
              'v2.page.embedded_wallets.third_party.missing_settings.description',
            )}
          />
        )}
      </AnimatePresence>
    </>
  );
};
