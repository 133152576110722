import { OriginResponse } from '@dynamic-labs/sdk-api';

import { SortBy } from '../../../../types/index';

export const sortOrigins = (
  origins: OriginResponse[] | undefined,
  sortBy: SortBy,
) => {
  if (!sortBy || !origins) return origins;

  if (sortBy.id !== 'created_at') return origins;

  return [...origins].sort((a, b) => {
    if (a.createdAt && b.createdAt) {
      return sortBy.desc
        ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        : new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    }
    return 0;
  });
};
