import { useTranslation } from 'react-i18next';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { UpsellBanner } from '../../../../components/UpsellBanner';
import { ROUTES } from '../../../../components/Navigation/data';
import {
  ClosableInfoAlert,
  ClosableInfoAlertIds,
} from '../../../../components/ClosableInfoAlert';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { Typography } from '../../../../components/Typography';
import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { UsersCounters } from '../UsersCounters';
import { IntegrationCard } from '../IntegrationCard';
import { integrationCards } from '../../../../data/overviewCards';
import { TrialGiftCard } from '../Card/TrialGiftCard';
import { useEnvironmentVisitorsQuery } from '../../../../hooks/useEnvironmentVisitorsQuery';

import styles from './OnboardingDoneContent.module.css';

export const OnboardingDoneContent = () => {
  const { activeEnvironment } = useEnvironmentsContext();
  const { data: activeEnvironmentVisitors, isLoading } =
    useEnvironmentVisitorsQuery({ environmentId: activeEnvironment?.id });
  const { shouldShowTrialBanner } = useSubscriptionLock();
  const { subscription } = useSubscriptionContext();
  const { t } = useTranslation();
  const { trialDaysLeft, hasPaymentMethod } = subscription || {};

  return (
    <div className={styles['onboarding-done-section__container']}>
      {shouldShowTrialBanner && hasPaymentMethod && (
        <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
      )}
      <Typography variant='title' className={styles.title}>
        {t('overview.configured.heading.title')}
      </Typography>
      <Typography variant='subtitle' className={styles.subtitle}>
        {t('overview.configured.heading.subtitle')}
      </Typography>

      {shouldShowTrialBanner && !hasPaymentMethod && (
        <TrialGiftCard className={styles['gift-card']} />
      )}

      <UsersCounters
        title={t('overview.configured.counters.visitors.title')}
        titleUrl={ROUTES.usersVisitors}
        counters={[
          {
            name: t('overview.configured.counters.visitors.counters.unique'),
            value: activeEnvironmentVisitors?.visitors.count || 0,
          },
        ]}
        isLoading={isLoading}
        monthlyUnique={activeEnvironmentVisitors?.visitors.uniquePastMonth || 0}
        type='visitors'
      />
      <UsersCounters
        title={t('overview.configured.counters.users.title')}
        titleUrl={ROUTES.usersAuthenticated}
        counters={[
          {
            name: t('overview.configured.counters.users.counters.unique'),
            value: activeEnvironmentVisitors?.users.count || 0,
          },
        ]}
        isFreeFeature={false}
        isLoading={isLoading}
        monthlyUnique={activeEnvironmentVisitors?.users.uniquePastMonth || 0}
        type='users'
      />

      <div className={styles.alerts}>
        <ClosableInfoAlert
          id={ClosableInfoAlertIds.connectingVsAuthenticating}
          title={t(
            'overview.configured.alerts.connecting_vs_authenticating.title',
          )}
          message={t(
            'overview.configured.alerts.connecting_vs_authenticating.message',
          )}
          learnMoreLink='https://docs.dynamic.xyz/docs/whats-the-difference'
          learnMoreText={t(
            'overview.configured.alerts.connecting_vs_authenticating.link',
          )}
          isClosable
        />
      </div>

      <div className={styles.integration}>
        <Typography
          variant='paragraph-2'
          className={styles.integration__title}
          weight='bold'
        >
          {t('overview.configured.content.first_paragraph')}
        </Typography>
        <Typography
          variant='paragraph-2'
          className={styles.integration__subtitle}
          weight='regular'
        >
          {t('overview.configured.content.second_paragraph')}
        </Typography>

        <div className={styles.integration__grid}>
          {integrationCards.map((card) => (
            <IntegrationCard
              id={card.id}
              title={card.title}
              description={card.description}
              url={card.url}
              icon={card.icon}
              scope={card.scope}
              key={card.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
