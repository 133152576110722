/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticatorAttestationResponse
 */
export interface AuthenticatorAttestationResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponse
     */
    clientDataJSON: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponse
     */
    attestationObject: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponse
     */
    authenticatorData?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticatorAttestationResponse
     */
    publicKeyAlgorithm?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticatorAttestationResponse
     */
    publicKey?: string;
}

export function AuthenticatorAttestationResponseFromJSON(json: any): AuthenticatorAttestationResponse {
    return AuthenticatorAttestationResponseFromJSONTyped(json, false);
}

export function AuthenticatorAttestationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatorAttestationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientDataJSON': json['clientDataJSON'],
        'attestationObject': json['attestationObject'],
        'authenticatorData': !exists(json, 'authenticatorData') ? undefined : json['authenticatorData'],
        'publicKeyAlgorithm': !exists(json, 'publicKeyAlgorithm') ? undefined : json['publicKeyAlgorithm'],
        'publicKey': !exists(json, 'publicKey') ? undefined : json['publicKey'],
    };
}

export function AuthenticatorAttestationResponseToJSON(value?: AuthenticatorAttestationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientDataJSON': value.clientDataJSON,
        'attestationObject': value.attestationObject,
        'authenticatorData': value.authenticatorData,
        'publicKeyAlgorithm': value.publicKeyAlgorithm,
        'publicKey': value.publicKey,
    };
}

