import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { membersColumns } from './membersColumns';

export const useMembersColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(() => membersColumns(t), [t]);

  return { columns };
};
