import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SingleToggleCard,
  WalletIntegrationIcon,
} from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';

type WalletsProps = {
  disabled: boolean;
};

export const Wallets: FC<WalletsProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const areWalletsEnabled =
    Boolean(!settings[activeEnvironmentType].design?.modal?.emailOnly) &&
    !disabled;
  const areWalletsEnabledSaved = Boolean(
    !initialSettings[activeEnvironmentType].design?.modal?.emailOnly ===
      areWalletsEnabled,
  );

  const setWalletsEnabled = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            emailOnly: !e.target.checked,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      accordionKey='wallets-enabled'
      title={t('v2.page.log_in_methods.branded_wallets.wallets.toggle')}
      description={t(
        'v2.page.log_in_methods.branded_wallets.wallets.description',
      )}
      allowExpand={false}
      inputProps={{
        checked: Boolean(areWalletsEnabled),
        disabled,
        id: 'wallets-enabled',
        isSaved: areWalletsEnabledSaved,
        onChange: setWalletsEnabled,
        type: 'toggle',
      }}
      Icon={<WalletIntegrationIcon />}
    />
  );
};
