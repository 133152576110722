import { t } from 'i18next';

import { WidgetView } from '../WidgetView';

import styles from './LockedWalletBreakdownPieChartWidget.module.css';

export const LockedWalletBreakdownPieChartWidget = () => (
  <WidgetView
    className={styles.widget__wrapper}
    title={t<string>('analytics.widgets.wallet_distribution.title')}
  >
    <div className={styles['widget-children']}>
      <div className={styles['empty-container']}>
        {t<string>('analytics.widgets.wallet_distribution.locked_label')}
      </div>
    </div>
  </WidgetView>
);
