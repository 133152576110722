/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleEnum,
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * 
 * @export
 * @interface InviteSendRequest
 */
export interface InviteSendRequest {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof InviteSendRequest
     */
    walletPublicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteSendRequest
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteSendRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InviteSendRequest
     */
    phoneNumber?: string;
    /**
     * 
     * @type {RoleEnum}
     * @memberof InviteSendRequest
     */
    role: RoleEnum;
}

export function InviteSendRequestFromJSON(json: any): InviteSendRequest {
    return InviteSendRequestFromJSONTyped(json, false);
}

export function InviteSendRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteSendRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletPublicKey': !exists(json, 'walletPublicKey') ? undefined : json['walletPublicKey'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'role': RoleEnumFromJSON(json['role']),
    };
}

export function InviteSendRequestToJSON(value?: InviteSendRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletPublicKey': value.walletPublicKey,
        'alias': value.alias,
        'email': value.email,
        'phoneNumber': value.phoneNumber,
        'role': RoleEnumToJSON(value.role),
    };
}

