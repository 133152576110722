import { cloneElement, MouseEvent, useRef } from 'react';

import { Content, Portal, Root, Trigger } from '@radix-ui/react-dropdown-menu';
import { AnimatePresence } from 'framer-motion';

import { useElementSize } from '../../hooks';

import {
  defaultItemsWrapper,
  defaultRenderValue,
  defaultPlaceholder,
} from './utils';
import { DropdownMenuProps } from './DropdownMenu.types';
import {
  DropdownMenuItem,
  DropdownMenuItemWithSubItemActions,
} from './components';
import styles from './DropdownMenu.module.scss';
import {
  DropdownMenuContextProvider,
  useDropdownMenuContext,
} from './DropdownMenuContext';

const DropdownMenuWithoutContext = <T, K>({
  options,
  value,
  onSelect,
  selectedValueWrapper = defaultRenderValue,
  placeholder,
  placeholderText,
  disabled,
  className,
  itemsWrapper = defaultItemsWrapper,
  itemsSideOffset = 0,
  allowUndefinedValue = false,
  dropdownCta,
  zIndex,
}: DropdownMenuProps<T, K>) => {
  const { open, setOpen } = useDropdownMenuContext();

  const targetRef = useRef<HTMLDivElement | null>(null);

  const targetRect = useElementSize(targetRef);
  const selectedOption = options?.find(
    (option) => option.props.value === value,
  );

  const placeholderElement = placeholderText
    ? defaultPlaceholder(placeholderText, { disabled: !!disabled, open })
    : placeholder;

  const valueElement = selectedOption
    ? selectedValueWrapper(selectedOption, {
        disabled: !!disabled,
        open,
      })
    : placeholderElement;

  const optionsElements = options?.map((option) => {
    const optionValue = option.props.value;

    const isSelected = optionValue === value;

    return cloneElement(option, {
      isSelected,
      key: JSON.stringify(optionValue),
      onDisabledClick: () => {
        setOpen(false);
        option.props.onDisabledClick?.();
      },
      onSelect: (e: MouseEvent<HTMLDivElement>) => {
        if (!onSelect) {
          e.preventDefault();
          return;
        }

        if (optionValue !== value) {
          onSelect(optionValue);
          return;
        }

        if (!allowUndefinedValue) {
          return;
        }

        onSelect(undefined);
      },
      rawOption: option.props.rawOption,
    });
  });

  if (dropdownCta)
    optionsElements.push(
      cloneElement(dropdownCta, {
        setOpen,
      }),
    );

  return (
    <div className={className} ref={targetRef}>
      <Root open={open} onOpenChange={setOpen}>
        <Trigger disabled={disabled} className={styles.trigger}>
          {valueElement}
        </Trigger>
        <AnimatePresence initial={false} mode='wait'>
          {open && (
            // forceMountIs required to prevent the dropdown from unmounting when the dropdown is closed
            // That way framer-motion will execute exit animation
            <Portal forceMount>
              <Content
                align='start'
                sideOffset={itemsSideOffset}
                style={{ zIndex: zIndex ?? 1 }}
              >
                {itemsWrapper({
                  children: optionsElements,
                  targetWidth: targetRect?.width,
                })}
              </Content>
            </Portal>
          )}
        </AnimatePresence>
      </Root>
    </div>
  );
};

export const DropdownMenu = <T, K>(props: DropdownMenuProps<T, K>) => (
  <DropdownMenuContextProvider>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <DropdownMenuWithoutContext {...props} />
  </DropdownMenuContextProvider>
);

DropdownMenu.Item = DropdownMenuItem;
DropdownMenu.ItemWithSubItemActions = DropdownMenuItemWithSubItemActions;
