/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    WalletProperties,
    WalletPropertiesFromJSON,
    WalletPropertiesFromJSONTyped,
    WalletPropertiesToJSON,
} from './WalletProperties';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface Wallet
 */
export interface Wallet {
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    name: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof Wallet
     */
    chain: ChainEnum;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof Wallet
     */
    publicKey: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof Wallet
     */
    provider: WalletProviderEnum;
    /**
     * 
     * @type {WalletProperties}
     * @memberof Wallet
     */
    properties?: WalletProperties;
    /**
     * 
     * @type {string}
     * @memberof Wallet
     */
    lastSelectedAt?: string | null;
}

export function WalletFromJSON(json: any): Wallet {
    return WalletFromJSONTyped(json, false);
}

export function WalletFromJSONTyped(json: any, ignoreDiscriminator: boolean): Wallet {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'chain': ChainEnumFromJSON(json['chain']),
        'publicKey': json['publicKey'],
        'provider': WalletProviderEnumFromJSON(json['provider']),
        'properties': !exists(json, 'properties') ? undefined : WalletPropertiesFromJSON(json['properties']),
        'lastSelectedAt': !exists(json, 'lastSelectedAt') ? undefined : json['lastSelectedAt'],
    };
}

export function WalletToJSON(value?: Wallet | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'chain': ChainEnumToJSON(value.chain),
        'publicKey': value.publicKey,
        'provider': WalletProviderEnumToJSON(value.provider),
        'properties': WalletPropertiesToJSON(value.properties),
        'lastSelectedAt': value.lastSelectedAt,
    };
}

