import { FocusEventHandler, useCallback, useMemo } from 'react';

import { format, parse } from 'date-fns';

import { CalendarIcon } from '../../../icons';

import { DateInputComponent } from './DateInput.types';
import styles from './DateInput.module.css';

const htmlInputFormat = 'yyyy-MM-dd';

export const DateInput: DateInputComponent = ({
  className = '',
  onChange,
  placeholder,
  value,
  id,
  disabled,
  max,
  min,
  dataTestId,
}) => {
  const type = useMemo(
    () => (!!placeholder && !value ? 'text' : 'date'),
    [placeholder, value],
  );

  const handleOnFocus: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (value) return;

      event.target.type = 'date';
    },
    [value],
  );

  return (
    <span className={styles.container}>
      <input
        data-testid={dataTestId}
        id={id}
        className={[styles['date-input'], className].join(' ')}
        type={type}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        value={value ? format(value, htmlInputFormat) : ''}
        disabled={disabled}
        min={min && format(min, htmlInputFormat)}
        max={max && format(max, htmlInputFormat)}
        onChange={(event) => {
          const date = parse(event.target.value, htmlInputFormat, new Date());

          onChange?.(date);
        }}
      />

      <span className={styles['calendar-icon-container']}>
        <CalendarIcon />
      </span>
    </span>
  );
};
