/* eslint-disable react/jsx-wrap-multilines */
import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../app/context/SettingsContext';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../app/routes/Configurations/utils';
import { AdminIcon } from '../../../../icons';
import { SideDrawer } from '../../../components/SideDrawer';

import styles from './AccountMfaRow.module.scss';
import { AccountMfaSidePage } from './AccountMfaSidePage';

export const AccountMfaRow: FC = () => {
  const { t } = useTranslation();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.AccountMfa,
  });

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isMfaEnabled = Boolean(
    settings[activeEnvironmentType].security.mfa?.enabled,
  );

  const isMfaSettingSaved =
    settings[activeEnvironmentType].security.mfa?.enabled ===
    initialSettings[activeEnvironmentType].security.mfa?.enabled;

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        security: {
          ...settings[activeEnvironmentType].security,
          mfa: {
            ...settings[activeEnvironmentType].security.mfa,
            enabled: e.target.checked,
          },
        },
      },
    });
  };

  return (
    <>
      <Section
        title={t('v2.page.security.account_mfa_row.title')}
        description={t('v2.page.security.account_mfa_row.description')}
      >
        <SingleToggleCard
          allowExpand={false}
          accordionKey='account-mfa'
          title={t('v2.page.security.account_mfa_row.toggle.title')}
          description={t('v2.page.security.account_mfa_row.toggle.description')}
          inputProps={{
            checked: isMfaEnabled,
            id: 'account-mfa',
            isSaved: isMfaSettingSaved,
            onChange: handleToggleChange,
            type: 'toggle',
          }}
          onCardClick={() => handleShowModal(true)}
          customActionIcon={{
            Icon: <AdminIcon />,
            className: styles['action-icon'],
          }}
        />
      </Section>

      <SideDrawer
        title={t('v2.page.security.account_mfa_row.side_drawer.title')}
        description={t(
          'v2.page.security.account_mfa_row.side_drawer.description',
        )}
        isOpen={showModal}
        handleClose={() => handleShowModal(false)}
        className={styles['side-drawer']}
      >
        <AccountMfaSidePage mfaEnabled={isMfaEnabled} />
      </SideDrawer>
    </>
  );
};
