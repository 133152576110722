import { useTranslation } from 'react-i18next';

import { getNetworkIcon } from '../../../../../../../../utils/getNetworkIcon';
import { DropdownField } from '../../../../../../../../components/DropdownField';
import { useAvailableNetworks } from '../../../../../hooks/useAvailableNetworks';

type GateRuleNetworkSelectProps = {
  className?: string;
  error?: boolean;
  onSelect: (networkId?: number) => void;
  selectedNetworkId?: number;
};

export const GateRuleNetworkSelect = ({
  selectedNetworkId,
  onSelect,
  className,
  error = false,
}: GateRuleNetworkSelectProps) => {
  const { t } = useTranslation();
  const availableNetworks = useAvailableNetworks();

  availableNetworks.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <DropdownField
      className={className}
      placeholder={t(
        'integrations.onboarding_and_kyc.access_control.gating.forms.selectChainLabel',
      )}
      value={selectedNetworkId}
      onSelect={onSelect}
      error={error}
      options={availableNetworks?.map((network) => {
        const NetworkIcon = getNetworkIcon(network.networkId);
        return {
          icon: <NetworkIcon title={`${network.name}-icon`} />,
          label: network.name,
          value: network.networkId,
        };
      })}
    />
  );
};
