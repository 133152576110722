import { useTranslation } from 'react-i18next';

import { useEnvironmentsContext } from '../../context/EnvironmentsContext';

import styles from './EnvironmentIndicator.module.css';

export const EnvironmentIndicator = () => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div
        className={`${styles.divider} ${
          styles[`divider--${activeEnvironmentType}`]
        }`}
      />
      <div
        className={`${styles.indicator} ${
          styles[`indicator--${activeEnvironmentType}`]
        }`}
      >
        {t('navigation.environment.indicator')}
        <span className={styles['indicator--capitalize']}>
          {activeEnvironmentType}
        </span>
      </div>
    </div>
  );
};
