/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalJwtCheckResponse
 */
export interface ExternalJwtCheckResponse {
    /**
     * Indicates if the JWT is valid
     * @type {boolean}
     * @memberof ExternalJwtCheckResponse
     */
    valid: boolean;
    /**
     * List of error messages if the JWT is invalid
     * @type {Array<string>}
     * @memberof ExternalJwtCheckResponse
     */
    errors?: Array<string>;
}

export function ExternalJwtCheckResponseFromJSON(json: any): ExternalJwtCheckResponse {
    return ExternalJwtCheckResponseFromJSONTyped(json, false);
}

export function ExternalJwtCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalJwtCheckResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'valid': json['valid'],
        'errors': !exists(json, 'errors') ? undefined : json['errors'],
    };
}

export function ExternalJwtCheckResponseToJSON(value?: ExternalJwtCheckResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'valid': value.valid,
        'errors': value.errors,
    };
}

