import { useEffect } from 'react';

import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Icon,
  OpacityAnimation,
  TwirlAnimation,
  Typography,
} from '@dynamic-labs/northstar';

import { useSaveBannerContext } from '../../contexts/SaveBannerContext';
import { RefreshIcon } from '../../../icons';

import styles from './SaveBanner.module.scss';

type SaveBannerProps = {
  handleOnCancel: VoidFunction;
  handleOnSave: VoidFunction;
  isLoading?: boolean;
};

export const SaveBanner = ({
  handleOnCancel,
  handleOnSave,
  isLoading,
}: SaveBannerProps) => {
  const { t } = useTranslation();
  const { saveBannerParentRef, setShouldShowSaveBanner, shouldWaveSaveBanner } =
    useSaveBannerContext();

  useEffect(() => {
    setShouldShowSaveBanner(true);

    return () => {
      setShouldShowSaveBanner(false);
    };
  }, [setShouldShowSaveBanner]);

  if (!saveBannerParentRef?.current) {
    return null;
  }

  return createPortal(
    <OpacityAnimation className={styles.opacity}>
      <>
        <div className={styles.placeholder} />
        <TwirlAnimation
          play={shouldWaveSaveBanner}
          className={styles.container}
        >
          <div className={styles.wrapper}>
            <Icon icon={<RefreshIcon />} size={20} color='gray-2' />
            <Typography variant='paragraph-2' weight='medium' color='black-2'>
              {t('v2.component.save_banner.title')}
            </Typography>

            <Button
              onClick={handleOnCancel}
              text={t('v2.component.save_banner.cancel')}
              variant='secondary'
              size='small'
              className={styles.cancel}
              disabled={isLoading}
            />
            <Button
              onClick={handleOnSave}
              text={t('v2.component.save_banner.save')}
              size='small'
              isLoading={isLoading}
            />
          </div>
        </TwirlAnimation>
      </>
    </OpacityAnimation>,
    saveBannerParentRef.current,
  );
};
