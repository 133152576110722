/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticationExtensionsClientOutputs
 */
export interface AuthenticationExtensionsClientOutputs {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationExtensionsClientOutputs
     */
    appid?: boolean;
    /**
     * 
     * @type {object}
     * @memberof AuthenticationExtensionsClientOutputs
     */
    credProps?: object;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationExtensionsClientOutputs
     */
    hmacCreateSecret?: boolean;
}

export function AuthenticationExtensionsClientOutputsFromJSON(json: any): AuthenticationExtensionsClientOutputs {
    return AuthenticationExtensionsClientOutputsFromJSONTyped(json, false);
}

export function AuthenticationExtensionsClientOutputsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticationExtensionsClientOutputs {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'appid': !exists(json, 'appid') ? undefined : json['appid'],
        'credProps': !exists(json, 'credProps') ? undefined : json['credProps'],
        'hmacCreateSecret': !exists(json, 'hmacCreateSecret') ? undefined : json['hmacCreateSecret'],
    };
}

export function AuthenticationExtensionsClientOutputsToJSON(value?: AuthenticationExtensionsClientOutputs | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'appid': value.appid,
        'credProps': value.credProps,
        'hmacCreateSecret': value.hmacCreateSecret,
    };
}

