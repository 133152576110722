import { useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { StorageService } from '@dynamic-labs/utils';

import { usersColumns } from './users-columns';

export const useUsersColumns = () => {
  const { t } = useTranslation();
  const columns = useMemo(() => usersColumns(t), [t]);

  const savedHiddenColumns: string[] | null | undefined =
    StorageService.getItem('hidden_users_columns');
  const columnsToToggle = columns
    .filter((column) => column.canToggleHidden)
    .map((column) => column.id) as string[];

  const [hiddenColumns, setHiddenColumns] = useState<string[]>(
    savedHiddenColumns ?? columnsToToggle,
  );

  return { columns, columnsToToggle, hiddenColumns, setHiddenColumns };
};
