import { ReactNode } from 'react';

import { m } from 'framer-motion';

type Timers = {
  delay?: number;
  duration?: number;
};

type Props = {
  animation?: Timers;
  children: ReactNode;
  className?: string;
  endPosition: 'left' | 'right' | 'up' | 'down';
  exit?: Timers;
  startPosition: 'left' | 'right' | 'up' | 'down';
};

const slideVariants = {
  down: {
    x: 0,
    y: '100%',
  },
  left: {
    x: '-100%',
    y: 0,
  },
  right: {
    x: '100%',
    y: 0,
  },
  up: {
    x: 0,
    y: '-100%',
  },
};

export const SlideAnimation = ({
  children,
  className,
  startPosition,
  endPosition,
  animation,
  exit,
}: Props) => (
  <m.div
    initial={{
      x: slideVariants[startPosition].x,
      y: slideVariants[startPosition].y,
    }}
    animate={{
      transition: {
        delay: animation?.delay,
        duration: animation?.duration,
      },
      x: 0,
      y: 0,
    }}
    exit={{
      transition: {
        delay: exit?.delay,
        duration: exit?.duration,
      },
      x: slideVariants[endPosition].x,
      y: slideVariants[endPosition].y,
    }}
    className={className}
    layout
  >
    {children}
  </m.div>
);
