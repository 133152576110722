/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleEnum,
    RoleEnumFromJSON,
    RoleEnumFromJSONTyped,
    RoleEnumToJSON,
} from './RoleEnum';

/**
 * Member role field which can be updated
 * @export
 * @interface MemberRoleField
 */
export interface MemberRoleField {
    /**
     * 
     * @type {RoleEnum}
     * @memberof MemberRoleField
     */
    role?: RoleEnum;
}

export function MemberRoleFieldFromJSON(json: any): MemberRoleField {
    return MemberRoleFieldFromJSONTyped(json, false);
}

export function MemberRoleFieldFromJSONTyped(json: any, ignoreDiscriminator: boolean): MemberRoleField {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'role': !exists(json, 'role') ? undefined : RoleEnumFromJSON(json['role']),
    };
}

export function MemberRoleFieldToJSON(value?: MemberRoleField | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'role': RoleEnumToJSON(value.role),
    };
}

