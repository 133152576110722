import React, { useRef } from 'react';

import { m } from 'framer-motion';
import { createPortal } from 'react-dom';

import { RefreshIcon } from '../../../icons';
import Button from '../Button';
import { createModalRoot } from '../../utils/createModalRoot';

import styles from './ConfirmToast.module.css';

type Props = {
  cancelText: string;
  className?: string;
  confirmationText: string;
  loading?: boolean;
  message: string;
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
};

export const ConfirmToast = ({
  message,
  onConfirm,
  onCancel,
  loading,
  cancelText,
  confirmationText,
  className = '',
}: Props) => {
  const { current } = useRef(createModalRoot('confirm-popup'));

  return createPortal(
    <m.div
      className={`${styles.container} ${className}`}
      initial={{ opacity: 0, x: '-50%', y: 100 }}
      animate={{
        opacity: 1,
        transition: {
          duration: 0.3,
        },
        y: 0,
      }}
      exit={{ opacity: 0, x: '-50%', y: 100 }}
    >
      <RefreshIcon className={styles.icon} />
      <p className={styles.message}>{message}</p>
      <Button className={styles.cancel} variant='secondary' onClick={onCancel}>
        {cancelText}
      </Button>
      <Button loading={loading} onClick={onConfirm}>
        {confirmationText}
      </Button>
    </m.div>,
    current,
  );
};
