import { FC } from 'react';

import { Chain, Icon, Typography, chainHelpers } from '@dynamic-labs/northstar';

import styles from './ChainBadge.module.scss';

type ChainBadgeProps = {
  enabledChains: Chain[];
};

export const ChainBadge: FC<ChainBadgeProps> = ({ enabledChains }) => {
  if (enabledChains.length === 0) {
    return null;
  }

  return (
    <div className={styles.pill} data-testid='chain-badge'>
      <div className={styles.chains}>
        {enabledChains.map((chain) => (
          <Icon
            key={chain.chainName}
            icon={chainHelpers.chainToIconMap[chain.chainName]}
            className={styles.icon}
            size={14}
          />
        ))}
      </div>

      <Typography
        variant='paragraph-0'
        weight='bold'
        color='gray-1'
        className={styles.amount}
      >
        {enabledChains.length}
      </Typography>
    </div>
  );
};
