/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    CustomField,
    CustomFieldFromJSON,
    CustomFieldToJSON,
    CustomFieldRequest,
    CustomFieldRequestFromJSON,
    CustomFieldRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateCustomFieldForEnvironmentRequest {
    environmentId: string;
    customFieldRequest: CustomFieldRequest;
}

export interface DeleteCustomFieldByIdRequest {
    customFieldId: string;
}

export interface GetCustomFieldByIdRequest {
    customFieldId: string;
}

export interface GetCustomFieldsForEnvironmentRequest {
    environmentId: string;
}

export interface UpdateCustomFieldByIdRequest {
    customFieldId: string;
    customFieldRequest: CustomFieldRequest;
}

/**
 * 
 */
export class CustomFieldsApi extends runtime.BaseAPI {

    /**
     * Create a new custom field for an environment
     */
    async createCustomFieldForEnvironmentRaw(requestParameters: CreateCustomFieldForEnvironmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomField>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createCustomFieldForEnvironment.');
        }

        if (requestParameters.customFieldRequest === null || requestParameters.customFieldRequest === undefined) {
            throw new runtime.RequiredError('customFieldRequest','Required parameter requestParameters.customFieldRequest was null or undefined when calling createCustomFieldForEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/custom/fields`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldRequestToJSON(requestParameters.customFieldRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldFromJSON(jsonValue));
    }

    /**
     * Create a new custom field for an environment
     */
    async createCustomFieldForEnvironment(requestParameters: CreateCustomFieldForEnvironmentRequest, initOverrides?: RequestInit): Promise<CustomField> {
        const response = await this.createCustomFieldForEnvironmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a specific custom field by its ID
     */
    async deleteCustomFieldByIdRaw(requestParameters: DeleteCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customFieldId === null || requestParameters.customFieldId === undefined) {
            throw new runtime.RequiredError('customFieldId','Required parameter requestParameters.customFieldId was null or undefined when calling deleteCustomFieldById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom/fields/{customFieldId}`.replace(`{${"customFieldId"}}`, encodeURIComponent(String(requestParameters.customFieldId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a specific custom field by its ID
     */
    async deleteCustomFieldById(requestParameters: DeleteCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteCustomFieldByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieve a specific custom field by its ID
     */
    async getCustomFieldByIdRaw(requestParameters: GetCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomField>> {
        if (requestParameters.customFieldId === null || requestParameters.customFieldId === undefined) {
            throw new runtime.RequiredError('customFieldId','Required parameter requestParameters.customFieldId was null or undefined when calling getCustomFieldById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom/fields/{customFieldId}`.replace(`{${"customFieldId"}}`, encodeURIComponent(String(requestParameters.customFieldId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldFromJSON(jsonValue));
    }

    /**
     * Retrieve a specific custom field by its ID
     */
    async getCustomFieldById(requestParameters: GetCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<CustomField> {
        const response = await this.getCustomFieldByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the custom fields for an environment
     */
    async getCustomFieldsForEnvironmentRaw(requestParameters: GetCustomFieldsForEnvironmentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<CustomField>>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getCustomFieldsForEnvironment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/environments/{environmentId}/custom/fields`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CustomFieldFromJSON));
    }

    /**
     * Get the custom fields for an environment
     */
    async getCustomFieldsForEnvironment(requestParameters: GetCustomFieldsForEnvironmentRequest, initOverrides?: RequestInit): Promise<Array<CustomField>> {
        const response = await this.getCustomFieldsForEnvironmentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update an existing custom field by its ID
     */
    async updateCustomFieldByIdRaw(requestParameters: UpdateCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CustomField>> {
        if (requestParameters.customFieldId === null || requestParameters.customFieldId === undefined) {
            throw new runtime.RequiredError('customFieldId','Required parameter requestParameters.customFieldId was null or undefined when calling updateCustomFieldById.');
        }

        if (requestParameters.customFieldRequest === null || requestParameters.customFieldRequest === undefined) {
            throw new runtime.RequiredError('customFieldRequest','Required parameter requestParameters.customFieldRequest was null or undefined when calling updateCustomFieldById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/custom/fields/{customFieldId}`.replace(`{${"customFieldId"}}`, encodeURIComponent(String(requestParameters.customFieldId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: CustomFieldRequestToJSON(requestParameters.customFieldRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomFieldFromJSON(jsonValue));
    }

    /**
     * Update an existing custom field by its ID
     */
    async updateCustomFieldById(requestParameters: UpdateCustomFieldByIdRequest, initOverrides?: RequestInit): Promise<CustomField> {
        const response = await this.updateCustomFieldByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
