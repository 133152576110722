import { useMemo } from 'react';

import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

import { ProviderEnum, SocialSignInProviderEnum } from '@dynamic-labs/sdk-api';

import { getProviderDisplayName } from '../../../../../../app/utils/getProviderDisplayName';
import { DYNAMIC_SOCIAL_CREDENTIAL_PROVIDERS } from '../../../../../../app/routes/Configurations/InformationCapture/data';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useOriginsContext } from '../../../../../../app/context/OriginsContext';
import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { useSignInWithSocialProviders } from '../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';
import { ROUTES } from '../../../../../../app/components/Navigation/data';
import { ModalHashLocations } from '../../../../../../app/routes/Configurations/utils';

export enum MissingSettingsWarningEnum {
  EmailMethodSignInNeedsVerifyAndUnique = 'EmailMethodSignInNeedsVerifyAndUnique',
  EmailMethodUniqueNeedsVerify = 'EmailMethodUniqueNeedsVerify',
  PhoneMethodAllCredentialsFilled = 'PhoneMethodAllCredentialsFilled',
  PhoneMethodAtLestOneCountry = 'PhoneMethodAtLestOneCountry',
  PhoneMethodSignInNeedsVerifyAndUnique = 'PhoneMethodSignInNeedsVerifyAndUnique',
  PhoneMethodUniqueNeedsVerify = 'PhoneMethodUniqueNeedsVerify',
  SocialMissingCors = 'SocialMissingCors',
  SocialMissingCredentials = 'SocialMissingCredentials',
}

export type MissingSettingsType = {
  action: () => void;
  i18nKeyActionText: string;
  i18nKeyText: string;
};

export const useGetMissingSettingsForLogInMethods = () => {
  const navigate = useNavigate();

  const MissingSettingsVariants: Record<
    MissingSettingsWarningEnum,
    Omit<MissingSettingsType, 'action'> & {
      action: (provider?: any) => void;
    }
  > = useMemo(
    () => ({
      [MissingSettingsWarningEnum.EmailMethodSignInNeedsVerifyAndUnique]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsEmailMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.email_method_sign_in_needs_verify_and_unique_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.email_method_sign_in_needs_verify_and_unique',
      },
      [MissingSettingsWarningEnum.EmailMethodUniqueNeedsVerify]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsEmailMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.email_method_unique_needs_verify_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.email_method_unique_needs_verify',
      },
      [MissingSettingsWarningEnum.PhoneMethodAllCredentialsFilled]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsPhoneNumberMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.phone_method_all_credentials_filled_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.phone_method_all_credentials_filled',
      },
      [MissingSettingsWarningEnum.PhoneMethodAtLestOneCountry]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsPhoneNumberMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.phone_method_at_lest_one_country_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.phone_method_at_lest_one_country',
      },
      [MissingSettingsWarningEnum.PhoneMethodSignInNeedsVerifyAndUnique]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsPhoneNumberMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.phone_method_sign_in_needs_verify_and_unique_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.phone_method_sign_in_needs_verify_and_unique',
      },
      [MissingSettingsWarningEnum.PhoneMethodUniqueNeedsVerify]: {
        action: () =>
          navigate(
            `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsPhoneNumberMethod}`,
          ),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.phone_method_unique_needs_verify_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.phone_method_unique_needs_verify',
      },
      [MissingSettingsWarningEnum.SocialMissingCredentials]: {
        action: (provider: ProviderEnum) =>
          navigate(`${ROUTES.logInMethods}#social-${provider}`),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.social_missing_credentials_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.social_missing_credentials',
      },
      [MissingSettingsWarningEnum.SocialMissingCors]: {
        action: () => navigate(ROUTES.security),
        i18nKeyActionText:
          'v2.page.log_in_methods.missing_settings.social_missing_cors_action',
        i18nKeyText:
          'v2.page.log_in_methods.missing_settings.social_missing_cors',
      },
    }),
    [navigate],
  );

  const { activeEnvironmentType } = useEnvironmentsContext();

  const { settings } = useSettingsContext();

  const { originData } = useOriginsContext();

  const { getProviderValue, availableSocialProviders, enabledSocialProviders } =
    useProvidersContext();
  const { enabledProviders } = useSignInWithSocialProviders({
    disabled: false,
  });

  const missingSettings: MissingSettingsType[] = useMemo(() => {
    const missingSettingsList: MissingSettingsType[] = [];

    const emailField = settings[activeEnvironmentType].kyc?.find(
      (kycField) => kycField.name === 'email',
    );

    if (emailField?.enabled) {
      const isDynamicEmailProviderEnabled = Boolean(
        getProviderValue(ProviderEnum.Dynamic, 'enabledAt'),
      );

      // Check if email signup is enabled with verify and unique
      if (
        isDynamicEmailProviderEnabled &&
        (!emailField?.verify || !emailField?.unique)
      ) {
        missingSettingsList.push(
          MissingSettingsVariants.EmailMethodSignInNeedsVerifyAndUnique,
        );
      }

      // Check if email unique is enabled with verify
      if (emailField?.unique && !emailField?.verify) {
        missingSettingsList.push(
          MissingSettingsVariants.EmailMethodUniqueNeedsVerify,
        );
      }
    }

    const phoneNumber = settings[activeEnvironmentType].kyc?.find(
      (kycField) => kycField.name === 'phoneNumber',
    );

    if (phoneNumber?.enabled) {
      const isPhoneNumberEnabled = Boolean(
        getProviderValue(ProviderEnum.Sms, 'enabledAt'),
      );
      const phoneNumberCountries = getProviderValue(
        ProviderEnum.Sms,
        'enabledCountries',
      );

      const phoneNumberCredentials = {
        accountSid: getProviderValue(ProviderEnum.Sms, 'accountSid'),
        clientId: getProviderValue(ProviderEnum.Sms, 'clientId'),
        clientSecret: getProviderValue(ProviderEnum.Sms, 'clientSecret'),
        twilioNumber: getProviderValue(ProviderEnum.Sms, 'twilioNumber'),
      };

      // If phoneNumber signup is enabled with unique and verify
      if (
        isPhoneNumberEnabled &&
        (!phoneNumber?.verify || !phoneNumber?.unique)
      ) {
        missingSettingsList.push(
          MissingSettingsVariants.PhoneMethodSignInNeedsVerifyAndUnique,
        );
      }

      // If any custom twilioCredentials are missing
      if (
        isPhoneNumberEnabled &&
        Object.values(phoneNumberCredentials).filter((value) => value !== '')
          .length !== 4 &&
        Object.values(phoneNumberCredentials).filter(
          (value) => value === '' || value === undefined,
        ).length !== 4
      ) {
        missingSettingsList.push(
          MissingSettingsVariants.PhoneMethodAllCredentialsFilled,
        );
      }

      // Check if phoneNumber unique is enabled with verify
      if (phoneNumber?.unique && !phoneNumber?.verify) {
        missingSettingsList.push(
          MissingSettingsVariants.PhoneMethodUniqueNeedsVerify,
        );
      }

      // if at least one country is selected
      if (
        phoneNumberCountries === undefined ||
        phoneNumberCountries?.length === 0
      ) {
        missingSettingsList.push(
          MissingSettingsVariants.PhoneMethodAtLestOneCountry,
        );
      }
    }

    availableSocialProviders.forEach((provider) => {
      const providersWithoutCredentialsRequired = [ProviderEnum.Farcaster];

      if (providersWithoutCredentialsRequired.includes(provider)) {
        return;
      }

      const isProviderLinkingEnabled = Boolean(
        getProviderValue(provider, 'enabledAt'),
      );
      const isProviderSignUpEnabled = Boolean(
        enabledProviders.includes(
          provider as unknown as SocialSignInProviderEnum,
        ),
      );

      let providerCredentials: {
        appleKeyId?: string;
        appleTeamId?: string;
        clientId: string | undefined;
        clientSecret: string | undefined;
        shopifyStore?: string;
      } = {
        clientId: getProviderValue(provider, 'clientId'),
        clientSecret: getProviderValue(provider, 'clientSecret'),
      };

      if (provider === ProviderEnum.Apple) {
        providerCredentials = {
          ...providerCredentials,
          appleKeyId: getProviderValue(provider, 'appleKeyId'),
          appleTeamId: getProviderValue(provider, 'appleTeamId'),
        };
      }

      if (provider === ProviderEnum.Shopify) {
        providerCredentials = {
          ...providerCredentials,
          shopifyStore: getProviderValue(provider, 'shopifyStore'),
        };
      }

      const isProviderEnabled =
        isProviderLinkingEnabled || isProviderSignUpEnabled;

      const isSandboxEnv = activeEnvironmentType === 'sandbox';

      // if provider is not enabled skip checking for missing settings
      if (!isProviderEnabled) {
        return;
      }

      // If not sandbox and credentials are missing
      if (
        !isSandboxEnv &&
        Object.values(providerCredentials).some(
          (value) => value === '' || value === undefined,
        )
      ) {
        missingSettingsList.push({
          action: () =>
            MissingSettingsVariants.SocialMissingCredentials.action(provider),
          i18nKeyActionText: t(
            MissingSettingsVariants.SocialMissingCredentials.i18nKeyActionText,
            {
              provider: getProviderDisplayName(provider),
            },
          ),
          i18nKeyText:
            MissingSettingsVariants.SocialMissingCredentials.i18nKeyText,
        });
      }

      const canUseDynamicCredentials =
        DYNAMIC_SOCIAL_CREDENTIAL_PROVIDERS.includes(provider);

      // if sandbox and provider CAN'T use dynamic credentials
      if (
        isSandboxEnv &&
        !canUseDynamicCredentials &&
        Object.values(providerCredentials).some(
          (value) => value === '' || value === undefined,
        )
      ) {
        missingSettingsList.push({
          action: () =>
            MissingSettingsVariants.SocialMissingCredentials.action(provider),
          i18nKeyActionText: t(
            MissingSettingsVariants.SocialMissingCredentials.i18nKeyActionText,
            {
              provider: getProviderDisplayName(provider),
            },
          ),
          i18nKeyText:
            MissingSettingsVariants.SocialMissingCredentials.i18nKeyText,
        });
      }

      const anyLinkingOrSignupSocialProvidersEnabled =
        enabledSocialProviders.length > 0 || enabledProviders.length > 0;

      // if any social provider enabled and missing cors
      if (
        (originData === undefined || originData.length === 0) &&
        anyLinkingOrSignupSocialProvidersEnabled
      ) {
        missingSettingsList.push({
          action: MissingSettingsVariants.SocialMissingCors.action,
          i18nKeyActionText: t(
            MissingSettingsVariants.SocialMissingCors.i18nKeyActionText,
          ),
          i18nKeyText: MissingSettingsVariants.SocialMissingCors.i18nKeyText,
        });
      }
    });

    return missingSettingsList;
  }, [
    MissingSettingsVariants,
    activeEnvironmentType,
    availableSocialProviders,
    enabledProviders,
    getProviderValue,
    settings,
    originData,
    enabledSocialProviders,
  ]);

  return missingSettings;
};
