import { cloneElement, MouseEvent } from 'react';

import {
  Item,
  Portal,
  Sub,
  SubContent,
  SubTrigger,
} from '@radix-ui/react-dropdown-menu';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import {
  DropdownMenuItemWithSubItemActionsComponent,
  DropdownMenuOptionSubItem,
} from '../../DropdownMenu.types';
import { useDropdownMenuContext } from '../../DropdownMenuContext';
import { defaultItemsWrapper } from '../../utils';

import styles from './DropdownMenuItemWithSubItemActions.module.scss';

export const DropdownMenuItemWithSubItemActions: DropdownMenuItemWithSubItemActionsComponent =
  ({
    isSelected,
    className,
    children,
    onSelect,
    subItems,
    subMenuTrigger,
    subItemsWrapper = defaultItemsWrapper,
    subItemsClassName,
    subItemsSideOffset = 0,
    value,
    zIndex,
  }) => {
    const {
      open: isParentOpen,
      subMenuOpenId,
      setSubMenuOpenId,
    } = useDropdownMenuContext();

    const parsedOption = JSON.stringify(value);

    const handleSubItemTriggerClick = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setSubMenuOpenId(
        subMenuOpenId === parsedOption ? undefined : parsedOption,
      );
    };

    const handleSubItemClick = (
      onClick: DropdownMenuOptionSubItem<typeof value>['props']['onClick'],
      itemValue: typeof value,
      e?: MouseEvent<HTMLElement>,
    ) => {
      e?.stopPropagation();
      onClick(itemValue, e);
    };

    const optionsElements = subItems?.map((option) =>
      cloneElement(option, {
        key: JSON.stringify(option.props),
        onClick: (itemValue: typeof value, e?: MouseEvent<HTMLElement>) =>
          handleSubItemClick(option.props.onClick, itemValue, e),
      }),
    );

    const dropdownOpen = subMenuOpenId === parsedOption && isParentOpen;

    return (
      <Item
        className={classNames(className, styles.item)}
        onClick={onSelect}
        role='button'
        tabIndex={0}
        data-isselected={isSelected}
        data-issubmenuopen={dropdownOpen}
      >
        {children}

        {subItems && subItems.length > 0 && (
          <Sub open={dropdownOpen}>
            <SubTrigger
              onClick={handleSubItemTriggerClick}
              className={styles.trigger}
            >
              {subMenuTrigger}
            </SubTrigger>
            <AnimatePresence>
              {dropdownOpen && (
                <Portal forceMount>
                  <SubContent
                    className={subItemsClassName}
                    sideOffset={subItemsSideOffset}
                    style={{ zIndex: zIndex ?? 1 }}
                  >
                    {subItemsWrapper({
                      children: optionsElements,
                    })}
                  </SubContent>
                </Portal>
              )}
            </AnimatePresence>
          </Sub>
        )}
      </Item>
    );
  };
