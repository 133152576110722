import { useState } from 'react';

import { BinIcon } from '../../../../../icons';
import { DeleteTokenModal } from '../DeleteTokenModal';

import styles from './DeleteTokenCell.module.css';

type Props = {
  tokenId: string;
};

export const DeleteTokenCell = ({ tokenId }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <button
        type='button'
        onClick={() => setShowModal(true)}
        className={styles['button--delete']}
      >
        <BinIcon />
      </button>
      {showModal && (
        <DeleteTokenModal setShowModal={setShowModal} tokenId={tokenId} />
      )}
    </div>
  );
};
