/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthStorageEnum,
    AuthStorageEnumFromJSON,
    AuthStorageEnumFromJSONTyped,
    AuthStorageEnumToJSON,
} from './AuthStorageEnum';

/**
 * 
 * @export
 * @interface AuthSettings
 */
export interface AuthSettings {
    /**
     * 
     * @type {Array<AuthStorageEnum>}
     * @memberof AuthSettings
     */
    storage: Array<AuthStorageEnum>;
}

export function AuthSettingsFromJSON(json: any): AuthSettings {
    return AuthSettingsFromJSONTyped(json, false);
}

export function AuthSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'storage': ((json['storage'] as Array<any>).map(AuthStorageEnumFromJSON)),
    };
}

export function AuthSettingsToJSON(value?: AuthSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'storage': ((value.storage as Array<any>).map(AuthStorageEnumToJSON)),
    };
}

