import { useTranslation } from 'react-i18next';

import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { Typography } from '../../../../components/Typography';
import { Tooltip } from '../../../../components/Tooltip';
import { Chip } from '../../../../components/Chip';
import { IntegrationBase } from '../../../../types';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';

import styles from './header.module.css';

type Props = IntegrationBase & {
  comingSoon?: boolean;
  isLiveActive: boolean;
  isSandboxActive: boolean;
  scope?: string;
};

const Header = ({
  Icon,
  name,
  isSandboxActive,
  isLiveActive,
  comingSoon,
  scope,
}: Props) => {
  const { checks, subscription } = useSubscriptionContext();
  const { t } = useTranslation();
  const { shouldLock, shouldShowAdvanced } = useSubscriptionLock(scope);

  const { isAdvancedWithPaymentMethod, isStandardWithPaymentMethod } = checks;

  const isLegacySubscription = subscription?.version === '1';

  const getLiveColor = () => {
    if (shouldLock) {
      return 'yellow';
    }

    if (isLiveActive) {
      return 'green';
    }

    return 'grey';
  };

  const tooltipKey = isLegacySubscription
    ? 'advanced_tooltip'
    : 'standard_tooltip';

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles['icon-container']}>
          <Icon />
        </div>
        <div className={styles.content}>
          <div className={styles['header-container']}>
            <Typography
              as='h2'
              variant='paragraph-2'
              weight='medium'
              className='mb-[6px]'
            >
              {name}
            </Typography>
            {shouldShowAdvanced && (
              <Tooltip
                tooltipText={[
                  t(`${tooltipKey}.message_row_1`),
                  t(`${tooltipKey}.message_row_2`),
                ]}
                disableHover={
                  isAdvancedWithPaymentMethod || isStandardWithPaymentMethod
                }
              >
                <Typography className={styles.tooltip} weight='bold'>
                  {t(`${tooltipKey}.title`)}
                </Typography>
              </Tooltip>
            )}
          </div>
          {comingSoon ? (
            <Chip color='blue' label={t('integrations.badge.coming_soon')} />
          ) : (
            <div className={styles.chips}>
              <Chip
                color={isSandboxActive ? 'green' : 'grey'}
                label={t('settings.sandbox')}
                className='mr-2'
              />

              <Chip
                color={getLiveColor()}
                label={t('settings.live')}
                isLocked={shouldLock}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
