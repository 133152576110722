import { FC, ReactElement } from 'react';

import classNames from 'classnames';
import { AnimatePresence } from 'framer-motion';

import {
  ErrorTriangleIcon,
  InfoCircleIcon,
  SparklesIcon,
  SuccessCheckIcon,
  WarningCircleIcon,
} from '../../icons';
import { IconColor } from '../Icon/Icon.types';
import { Icon } from '../Icon';
import { Typography } from '../Typography';
import { TextButton, TextButtonProps } from '../TextButton';
import { OpacityAnimation } from '../../animations';

import styles from './Alert.module.scss';

type AlertVariant = 'info' | 'warning' | 'error' | 'success' | 'advance';

export type AlertProps = {
  IconOverride?: ReactElement;
  action?: Pick<TextButtonProps, 'text' | 'onClick' | 'as' | 'href'>;
  description?: string | ReactElement;
  show?: boolean;
  title: string;
  variant: AlertVariant;
};

const IconVariantsMap: Record<AlertVariant, ReactElement> = {
  advance: <SparklesIcon />,
  error: <ErrorTriangleIcon />,
  info: <InfoCircleIcon />,
  success: <SuccessCheckIcon />,
  warning: <WarningCircleIcon />,
};

const colorVariantsMap: Record<AlertVariant, IconColor> = {
  advance: 'primary-1',
  error: 'red-1',
  info: 'gray-2',
  success: 'green-dark',
  warning: 'orange-dark',
};

export const Alert: FC<AlertProps> = ({
  variant,
  title,
  IconOverride,
  description,
  show = true,
  action,
}) => (
  <AnimatePresence>
    {show && (
      <OpacityAnimation>
        <div
          className={classNames(styles.wrapper, styles[`wrapper--${variant}`])}
        >
          <Icon
            icon={IconOverride || IconVariantsMap[variant]}
            size={20}
            color={colorVariantsMap[variant]}
          />
          <div className={styles.container}>
            <Typography variant='paragraph-2' weight='medium'>
              {title}
            </Typography>

            {description &&
              (typeof description === 'string' ? (
                <Typography
                  variant='paragraph-1'
                  color='gray-1'
                  weight='regular'
                >
                  {description}
                </Typography>
              ) : (
                description
              ))}

            {action && (
              <TextButton
                className={styles.actionButton}
                color='primary-1'
                text={action.text}
                onClick={action.onClick}
                as={action.as}
                href={action.href}
              />
            )}
          </div>
        </div>
      </OpacityAnimation>
    )}
  </AnimatePresence>
);
