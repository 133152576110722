import { FC, PropsWithChildren } from 'react';

import { MultiSectionItem } from './MultiSectionItem';
import styles from './MultiSection.module.scss';

export const MultiSection: FC<PropsWithChildren> & {
  Item: typeof MultiSectionItem;
} = ({ children }) => <section className={styles.wrapper}>{children}</section>;

MultiSection.Item = MultiSectionItem;
