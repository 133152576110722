/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateEmbeddedWalletSpecificOpts,
    CreateEmbeddedWalletSpecificOptsFromJSON,
    CreateEmbeddedWalletSpecificOptsFromJSONTyped,
    CreateEmbeddedWalletSpecificOptsToJSON,
} from './CreateEmbeddedWalletSpecificOpts';
import {
    EmbeddedWalletChainEnum,
    EmbeddedWalletChainEnumFromJSON,
    EmbeddedWalletChainEnumFromJSONTyped,
    EmbeddedWalletChainEnumToJSON,
} from './EmbeddedWalletChainEnum';
import {
    EmbeddedWalletProviderEnum,
    EmbeddedWalletProviderEnumFromJSON,
    EmbeddedWalletProviderEnumFromJSONTyped,
    EmbeddedWalletProviderEnumToJSON,
} from './EmbeddedWalletProviderEnum';

/**
 * 
 * @export
 * @interface CreateEmbeddedWalletParams
 */
export interface CreateEmbeddedWalletParams {
    /**
     * The chains to create the wallet for
     * @type {Array<EmbeddedWalletChainEnum>}
     * @memberof CreateEmbeddedWalletParams
     */
    chains?: Array<EmbeddedWalletChainEnum>;
    /**
     * 
     * @type {EmbeddedWalletChainEnum}
     * @memberof CreateEmbeddedWalletParams
     */
    chain?: EmbeddedWalletChainEnum;
    /**
     * 
     * @type {EmbeddedWalletProviderEnum}
     * @memberof CreateEmbeddedWalletParams
     */
    embeddedWalletProvider: EmbeddedWalletProviderEnum;
    /**
     * 
     * @type {CreateEmbeddedWalletSpecificOpts}
     * @memberof CreateEmbeddedWalletParams
     */
    embeddedWalletSpecificOpts?: CreateEmbeddedWalletSpecificOpts;
    /**
     * Whether or not the wallet will be created with an authenticator (passkey, api key, etc) attached to it.
     * @type {boolean}
     * @memberof CreateEmbeddedWalletParams
     */
    isAuthenticatorAttached?: boolean;
    /**
     * The alias for the passkey
     * @type {string}
     * @memberof CreateEmbeddedWalletParams
     */
    passkeyAlias?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateEmbeddedWalletParams
     */
    sessionPublicKey?: string;
}

export function CreateEmbeddedWalletParamsFromJSON(json: any): CreateEmbeddedWalletParams {
    return CreateEmbeddedWalletParamsFromJSONTyped(json, false);
}

export function CreateEmbeddedWalletParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateEmbeddedWalletParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chains': !exists(json, 'chains') ? undefined : ((json['chains'] as Array<any>).map(EmbeddedWalletChainEnumFromJSON)),
        'chain': !exists(json, 'chain') ? undefined : EmbeddedWalletChainEnumFromJSON(json['chain']),
        'embeddedWalletProvider': EmbeddedWalletProviderEnumFromJSON(json['embeddedWalletProvider']),
        'embeddedWalletSpecificOpts': !exists(json, 'embeddedWalletSpecificOpts') ? undefined : CreateEmbeddedWalletSpecificOptsFromJSON(json['embeddedWalletSpecificOpts']),
        'isAuthenticatorAttached': !exists(json, 'isAuthenticatorAttached') ? undefined : json['isAuthenticatorAttached'],
        'passkeyAlias': !exists(json, 'passkeyAlias') ? undefined : json['passkeyAlias'],
        'sessionPublicKey': !exists(json, 'sessionPublicKey') ? undefined : json['sessionPublicKey'],
    };
}

export function CreateEmbeddedWalletParamsToJSON(value?: CreateEmbeddedWalletParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chains': value.chains === undefined ? undefined : ((value.chains as Array<any>).map(EmbeddedWalletChainEnumToJSON)),
        'chain': EmbeddedWalletChainEnumToJSON(value.chain),
        'embeddedWalletProvider': EmbeddedWalletProviderEnumToJSON(value.embeddedWalletProvider),
        'embeddedWalletSpecificOpts': CreateEmbeddedWalletSpecificOptsToJSON(value.embeddedWalletSpecificOpts),
        'isAuthenticatorAttached': value.isAuthenticatorAttached,
        'passkeyAlias': value.passkeyAlias,
        'sessionPublicKey': value.sessionPublicKey,
    };
}

