/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AuthenticatorAttachment {
    CrossPlatform = 'cross-platform',
    Platform = 'platform'
}

export function AuthenticatorAttachmentFromJSON(json: any): AuthenticatorAttachment {
    return AuthenticatorAttachmentFromJSONTyped(json, false);
}

export function AuthenticatorAttachmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthenticatorAttachment {
    return json as AuthenticatorAttachment;
}

export function AuthenticatorAttachmentToJSON(value?: AuthenticatorAttachment | null): any {
    return value as any;
}

