/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';

/**
 * 
 * @export
 * @interface CreateUserOauthRequest
 */
export interface CreateUserOauthRequest {
    /**
     * 
     * @type {ProviderEnum}
     * @memberof CreateUserOauthRequest
     */
    provider: ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOauthRequest
     */
    accountId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserOauthRequest
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOauthRequest
     */
    displayName?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserOauthRequest
     */
    username?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserOauthRequest
     */
    photos?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof CreateUserOauthRequest
     */
    profile?: object;
}

export function CreateUserOauthRequestFromJSON(json: any): CreateUserOauthRequest {
    return CreateUserOauthRequestFromJSONTyped(json, false);
}

export function CreateUserOauthRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateUserOauthRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'provider': ProviderEnumFromJSON(json['provider']),
        'accountId': json['accountId'],
        'emails': !exists(json, 'emails') ? undefined : json['emails'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'photos': !exists(json, 'photos') ? undefined : json['photos'],
        'profile': !exists(json, 'profile') ? undefined : json['profile'],
    };
}

export function CreateUserOauthRequestToJSON(value?: CreateUserOauthRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'provider': ProviderEnumToJSON(value.provider),
        'accountId': value.accountId,
        'emails': value.emails,
        'displayName': value.displayName,
        'username': value.username,
        'photos': value.photos,
        'profile': value.profile,
    };
}

