import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { AccessOutcomeEnum } from '@dynamic-labs/sdk-api';

import Checkbox from '../../../../../components/Checkbox';

import styles from './AccessOutcomeSelect.module.css';

type AccessOutcomeSelectProps = {
  className?: string;
  disabled?: boolean;
  onSelect: (outcome: AccessOutcomeEnum | undefined) => void;
  selectedOutcome?: AccessOutcomeEnum;
};

export const AccessOutcomeSelect = ({
  selectedOutcome,
  onSelect,
  className,
  disabled = false,
}: AccessOutcomeSelectProps) => {
  const { t } = useTranslation();

  const outcomeOptions = [
    AccessOutcomeEnum.SiteAccess,
    AccessOutcomeEnum.Scope,
    AccessOutcomeEnum.Block,
  ];

  return (
    <div className={classNames(styles.wrapper, className)}>
      {outcomeOptions.map((outcome) => (
        <Checkbox
          id={`outcome-option-${outcome}`}
          checked={selectedOutcome === outcome}
          onChange={() => {
            onSelect(outcome);
          }}
          ariaLabel={`set ${outcome} as the outcome`}
          disabled={disabled}
          label={t(
            `integrations.onboarding_and_kyc.access_control.outcomeOptions.${outcome}`,
          )}
          key={outcome}
        />
      ))}
    </div>
  );
};
