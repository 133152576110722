/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CustomHostname,
    CustomHostnameFromJSON,
    CustomHostnameFromJSONTyped,
    CustomHostnameToJSON,
} from './CustomHostname';

/**
 * 
 * @export
 * @interface CustomHostnamesResponse
 */
export interface CustomHostnamesResponse {
    /**
     * 
     * @type {Array<CustomHostname>}
     * @memberof CustomHostnamesResponse
     */
    customHostnames: Array<CustomHostname>;
}

export function CustomHostnamesResponseFromJSON(json: any): CustomHostnamesResponse {
    return CustomHostnamesResponseFromJSONTyped(json, false);
}

export function CustomHostnamesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomHostnamesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customHostnames': ((json['customHostnames'] as Array<any>).map(CustomHostnameFromJSON)),
    };
}

export function CustomHostnamesResponseToJSON(value?: CustomHostnamesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customHostnames': ((value.customHostnames as Array<any>).map(CustomHostnameToJSON)),
    };
}

