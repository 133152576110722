/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TurnkeyDeleteEmbeddedWalletsRequestBodyParameters,
    TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSON,
    TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSONTyped,
    TurnkeyDeleteEmbeddedWalletsRequestBodyParametersToJSON,
} from './TurnkeyDeleteEmbeddedWalletsRequestBodyParameters';

/**
 * 
 * @export
 * @interface TurnkeyDeleteEmbeddedWalletsRequestBody
 */
export interface TurnkeyDeleteEmbeddedWalletsRequestBody {
    /**
     * 
     * @type {string}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBody
     */
    organizationId: string;
    /**
     * 
     * @type {TurnkeyDeleteEmbeddedWalletsRequestBodyParameters}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBody
     */
    parameters: TurnkeyDeleteEmbeddedWalletsRequestBodyParameters;
    /**
     * 
     * @type {string}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBody
     */
    timestampMs: string;
    /**
     * 
     * @type {string}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBody
     */
    type: string;
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyFromJSON(json: any): TurnkeyDeleteEmbeddedWalletsRequestBody {
    return TurnkeyDeleteEmbeddedWalletsRequestBodyFromJSONTyped(json, false);
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnkeyDeleteEmbeddedWalletsRequestBody {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizationId': json['organizationId'],
        'parameters': TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSON(json['parameters']),
        'timestampMs': json['timestampMs'],
        'type': json['type'],
    };
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyToJSON(value?: TurnkeyDeleteEmbeddedWalletsRequestBody | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizationId': value.organizationId,
        'parameters': TurnkeyDeleteEmbeddedWalletsRequestBodyParametersToJSON(value.parameters),
        'timestampMs': value.timestampMs,
        'type': value.type,
    };
}

