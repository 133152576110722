import classNames from 'classnames';

import { getChainIcon } from '@dynamic-labs/sdk-react-core';
import { WalletIcon } from '@dynamic-labs/wallet-book';

import { Typography } from '../Typography';
import { normalizeWalletName } from '../../utils/normalizeWalletName';
import { shortenWalletAddress } from '../../../appV2/utils/address';
import CopyButton from '../CopyButton';

import styles from './wallet-address.module.css';

type Props = {
  address: string | undefined;
  chain?: string;
  hideAddress?: boolean;
  shouldTruncate?: boolean;
  userStyle?: string;
  walletName?: string;
};

export const WalletAddress = ({
  address,
  chain,
  userStyle,
  walletName,
  hideAddress = false,
  shouldTruncate = false,
}: Props): JSX.Element => {
  let ChainIcon = null;
  if (typeof chain !== 'undefined') {
    ChainIcon = getChainIcon(chain);
  }

  return (
    <div
      className={`${styles.container} group ${
        walletName ? 'flex-row-reverse' : ''
      } ${userStyle || ''}`}
    >
      {ChainIcon && (
        <ChainIcon
          className={`${styles.icon} ${walletName ? 'ml-1 !mr-0' : ''}`}
        />
      )}
      <Typography
        className={classNames(styles.address, { hidden: hideAddress })}
      >
        {shouldTruncate ? shortenWalletAddress(address, 6, 5) : address}
      </Typography>
      {walletName && (
        <div className={styles.icon}>
          <WalletIcon walletKey={normalizeWalletName(walletName)} />
        </div>
      )}
      <button
        className='group-hover:flex hidden absolute right-1 bg-white'
        onClick={(e) => e.stopPropagation()}
        type='button'
      >
        <CopyButton
          textToCopy={address || ''}
          type='icon'
          className='rounded-md'
        />
      </button>
    </div>
  );
};
