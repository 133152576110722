import classNames from 'classnames';

import { Typography } from '../Typography/Typography';

import styles from './Badge.module.css';

type Props = {
  children?: React.ReactNode;
  className?: string;
  size: 'small' | 'big';
  text: string;
  variant: 'green' | 'yellow' | 'red' | 'primary' | 'secondary' | 'white';
};

export const Badge = ({ variant, text, size, className, children }: Props) => {
  const containerSizeClassName = styles[`container--${size}`];
  const colorClassName = styles[`color--${variant}`];

  if (children) {
    return (
      <div
        className={classNames(
          styles.container,
          containerSizeClassName,
          colorClassName,
          className,
        )}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className={classNames(
        styles.container,
        containerSizeClassName,
        colorClassName,
        className,
      )}
    >
      <Typography
        variant={`paragraph-${size === 'small' ? 0 : 2}`}
        weight='medium'
        className={colorClassName}
      >
        {text}
      </Typography>
    </div>
  );
};
