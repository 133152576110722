import { useCallback } from 'react';

import classNames from 'classnames';

import { ClassStyleProps } from '../../utils/types';
import { IconButton } from '../IconButton';
import { Typography } from '../Typography';
import { CopyIcon } from '../../icons';

import styles from './CopyField.module.scss';

export type CopyFieldProps = ClassStyleProps & {
  /** Uses custom children instead of the default text */
  children?: JSX.Element | JSX.Element[];

  /** The text to copy, regardless of children is set to */
  copyText: string;

  /** Customize the icon size (default 14) */
  iconSize?: number;

  /** Renders the copy button, defaults to true */
  showCopy?: boolean;
};

export const CopyField = ({
  children,
  className,
  copyText,
  iconSize = 14,
  showCopy = true,
}: CopyFieldProps) => {
  // TODO(GVTY-2404): Add tooltip after migrating tooltip to northstar
  const copyTextToClipboard = useCallback(
    () => navigator.clipboard.writeText(copyText),
    [copyText],
  );

  return (
    <div className={classNames(className, styles.wrapper)}>
      {children || (
        <Typography as='span' variant='paragraph-2'>
          {copyText}
        </Typography>
      )}
      {showCopy && (
        <IconButton
          size={iconSize}
          icon={<CopyIcon />}
          onClick={copyTextToClipboard}
        />
      )}
    </div>
  );
};
