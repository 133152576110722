import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  FC,
  Dispatch,
  SetStateAction,
  PropsWithChildren,
} from 'react';

import {
  InvitesResponse,
  InviteStatusEnum,
  OrganizationMembersResponse,
  RoleEnum,
} from '@dynamic-labs/sdk-api';

import { useDashboardContext } from '../DashboardContext';
import { invitesApi, membersApi } from '../../services/api';
import { logger } from '../../services/logger';

export interface Member {
  alias: string;
  email: string | null | undefined;
  firstName: string | null | undefined;
  id?: string;
  role?: RoleEnum;
  status?: InviteStatusEnum;
  type: 'user' | 'invite';
  wallet?: string;
  walletPublicKey?: string;
}

const combineUsersAndInvites = (
  membersResponse: OrganizationMembersResponse,
  invites: InvitesResponse,
): Member[] => {
  const members: Member[] = [];
  const membersData = membersResponse.members ?? [];
  const invitesData = invites.invites ?? [];

  members.push(
    ...membersData.map((member) => ({
      alias: member.user.alias ?? '',
      email: member.user.email,
      firstName: member.user.firstName,
      id: member.id,
      role: member.role,
      type: 'user' as const,
      wallet: member.user.wallets?.[0]
        ? member.user.wallets[0].name
        : undefined,
      walletPublicKey: member.user.wallets?.[0]
        ? member.user.wallets[0].publicKey
        : undefined,
    })),
    ...invitesData
      .filter((invite) => invite.status === InviteStatusEnum.Pending)
      .map((invite) => ({
        alias: invite.alias ?? '',
        email: invite.email ?? '',
        firstName: '',
        id: invite.id,
        role: invite.role,
        status: invite.status,
        type: 'invite' as const,
        walletPublicKey: invite.walletPublicKey,
      })),
  );

  members.sort((a, b) => a.alias.localeCompare(b.alias));

  return members;
};

export type MembersContextValue = {
  fetchMembers(): Promise<Member[] | undefined>;
  members: Member[] | undefined;
  setMembers: Dispatch<SetStateAction<Member[] | undefined>>;
};

export const MembersContext = createContext<MembersContextValue | undefined>(
  undefined,
);

export const MembersContextProvider: FC<PropsWithChildren<object>> = ({
  children,
}) => {
  const { activeOrganizationId } = useDashboardContext();
  const [members, setMembers] = useState<Member[] | undefined>();

  const fetchMembers = async (): Promise<Member[] | undefined> => {
    try {
      if (activeOrganizationId === undefined) {
        throw new Error('activeOrganizationId not defined');
      }

      const membersResponse: OrganizationMembersResponse =
        await membersApi.getOrganizationMembers({
          organizationId: activeOrganizationId,
        });

      const invitesResponse =
        await invitesApi.getOrganizationInvitesByOrganizationId({
          organizationId: activeOrganizationId,
        });

      return combineUsersAndInvites(membersResponse, invitesResponse);
    } catch (e) {
      logger.error(e);
      return undefined;
    }
  };

  useEffect(() => {
    const getMembers = async () => {
      const response = await fetchMembers();
      setMembers(response);
    };

    getMembers();
  }, [activeOrganizationId]);

  const value = useMemo(
    () => ({
      fetchMembers,
      members,
      setMembers,
    }),
    [members],
  );

  return (
    <MembersContext.Provider value={value}>{children}</MembersContext.Provider>
  );
};

export const useMembersContext = () => {
  const context = useContext(MembersContext);

  if (context === undefined) {
    throw new Error(
      'usage of useMembersContext not wrapped in `MembersContextProvider`.',
    );
  }

  return context;
};
