import { PropsWithChildren, ReactElement, ReactNode } from 'react';

import classNames from 'classnames';
import { Item } from '@radix-ui/react-dropdown-menu';

import { Typography } from '../../../Typography';

import styles from './SelectItem.module.css';

export type SelectItemProps<T> = {
  className?: string;
  isSelected?: boolean;
  leading?: ReactNode;
  onSelect?: () => void;
  value: T;
};

export type SelectItemComponent = <T>(
  props: PropsWithChildren<SelectItemProps<T>>,
) => ReactElement;

export const SelectItem: SelectItemComponent = ({
  isSelected,
  className,
  children,
  onSelect,
  leading,
}) => (
  <Item
    className={classNames(className, styles.selectItem)}
    onClick={onSelect}
    role='button'
    tabIndex={0}
  >
    {leading}

    <Typography
      variant='paragraph-2'
      weight='medium'
      color={isSelected ? 'primary-1' : undefined}
    >
      {children}
    </Typography>
  </Item>
);
