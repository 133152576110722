import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { useDefaultChartOptions } from '../../hooks';

import { BarChartComponent, BarOptions } from './BarChart.types';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

ChartJS.defaults.font.size = 12;
ChartJS.defaults.font.weight = '500';
ChartJS.defaults.font.family = 'Roboto Mono';

export const BarChart: BarChartComponent = ({ data, options }) => {
  const effectiveOptions = useDefaultChartOptions<BarOptions>(options);

  return <Bar options={effectiveOptions} data={data} />;
};
