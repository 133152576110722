import { useState, useCallback } from 'react';

import { Duration, ProjectSettings } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../services/logger';
import { environmentsApi } from '../../../../services/api';
import { useSettingsContext } from '../..';
import { useEnvironmentsContext } from '../../../EnvironmentsContext';
import { useBaseSettings } from '../useBaseSettings';

const sanitizeJwtSettings = (
  obj: ProjectSettings['security'],
): ProjectSettings['security'] => obj;
export const useJwtSettings = () => {
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const { settings } = useSettingsContext();
  const [isLoading, setIsLoading] = useState(false);

  const {
    cancelChanges,
    setSpecificSettings,
    settingsHasChanged,
    updateInitialSettings,
  } = useBaseSettings<ProjectSettings['security']>(
    'security',
    sanitizeJwtSettings,
  );

  const setJwtSettings = (values: Duration) => {
    const newSettings = values;
    setSpecificSettings({
      ...settings[activeEnvironmentType].security,
      jwtDuration: {
        amount: newSettings.amount,
        unit: newSettings.unit,
      },
    });
  };

  const saveJwtSettings = useCallback(async () => {
    if (!settingsHasChanged) {
      return;
    }
    try {
      setIsLoading(true);

      const response = await environmentsApi.updateProjectSettings({
        environmentId: activeEnvironment?.id ?? '',
        projectSettings: settings[activeEnvironmentType],
      });

      if (!response?.security) return;

      updateInitialSettings();
    } catch (e) {
      logger.error(e);
    } finally {
      setIsLoading(false);
    }
  }, [
    settingsHasChanged,
    activeEnvironment?.id,
    settings,
    activeEnvironmentType,
  ]);

  return {
    cancelChanges,
    globalSettings: settings,
    isLoading,
    saveJwtSettings,
    setJwtSettings,
    settings: settings?.[activeEnvironmentType].security,
    settingsHasChanged,
    updateInitialSettings,
  };
};
