import { t } from 'i18next';

import PopupModal from '../../../components/PopupModal';
import { PopupHeader } from '../../../components/PopupModal/PopupHeader/PopupHeader';
import { Member } from '../../../context/MembersContext';

import Form from './Form';
import styles from './invites-modal.module.css';

interface Props {
  isUpdateMode?: boolean;
  memberToUpdate?: Member;
  setShowModal: (showModal: boolean) => void;
}

const InvitesModal = ({
  setShowModal,
  isUpdateMode = false,
  memberToUpdate,
}: Props) => (
  <PopupModal handleClose={() => setShowModal(false)} className={styles.portal}>
    <PopupHeader
      handleClose={() => setShowModal(false)}
      headingText={
        isUpdateMode
          ? t<string>('admin.invites_modal.update_title')
          : t<string>('admin.invites_modal.title')
      }
    />
    <p className={styles.paragraph}>
      {isUpdateMode
        ? t<string>('admin.invites_modal.update_description')
        : t<string>('admin.invites_modal.description')}
    </p>
    <Form
      setShowModal={() => setShowModal(false)}
      isUpdateMode={isUpdateMode}
      memberToUpdate={memberToUpdate}
    />
  </PopupModal>
);

export default InvitesModal;
