/* eslint-disable react/jsx-wrap-multilines */
import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section, SingleToggleCard } from '@dynamic-labs/northstar';

import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../app/context/SettingsContext';
import {
  ModalHashLocations,
  useModalHashLocation,
} from '../../../../app/routes/Configurations/utils';
import { AdminIcon } from '../../../../icons';
import { SideDrawer } from '../../../components/SideDrawer';

import styles from './DeeplinkUrlRow.module.scss';
import { DeeplinkUrlTable } from './DeeplinkUrlTable';

// TODO: update this link when we have docs on this
const DOCS_URL = 'https://docs.dynamic.xyz/react-native/deeplink-urls';

export const DeeplinkUrlRow: FC = () => {
  const { t } = useTranslation();

  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.MobileDeeplinkUrl,
  });

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isDeeplinkUrlEnabled = Boolean(
    settings[activeEnvironmentType].sdk.mobile?.deeplinkUrlsEnabled,
  );

  const isDeeplinkUrlSettingSaved =
    settings[activeEnvironmentType].sdk.mobile?.deeplinkUrlsEnabled ===
    initialSettings[activeEnvironmentType].sdk.mobile?.deeplinkUrlsEnabled;

  const handleToggleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          mobile: {
            ...settings[activeEnvironmentType].sdk.mobile,
            deeplinkUrlsEnabled: e.target.checked,
          },
        },
      },
    });
  };

  return (
    <>
      <Section
        title={t('v2.page.security.deeplink_url_row.title')}
        description={t('v2.page.security.deeplink_url_row.description')}
      >
        <SingleToggleCard
          allowExpand={false}
          accordionKey='deeplink-url'
          title={t('v2.page.security.deeplink_url_row.toggle.title')}
          description={t(
            'v2.page.security.deeplink_url_row.toggle.description',
          )}
          inputProps={{
            checked: isDeeplinkUrlEnabled,
            id: 'deeplink-url',
            isSaved: isDeeplinkUrlSettingSaved,
            onChange: handleToggleChange,
            type: 'toggle',
          }}
          onCardClick={
            isDeeplinkUrlEnabled ? () => handleShowModal(true) : undefined
          }
          customActionIcon={{
            Icon: <AdminIcon />,
            className: styles['action-icon'],
          }}
        />
      </Section>

      <SideDrawer
        title={t('v2.page.security.deeplink_url_row.side_drawer.title')}
        description={t(
          'v2.page.security.deeplink_url_row.side_drawer.description',
        )}
        isOpen={showModal && isDeeplinkUrlEnabled}
        handleClose={() => handleShowModal(false)}
        className={styles['side-drawer']}
        docs={{
          text: t(
            'v2.page.embedded_wallets.dynamic_section.embedded_wallet.turnkey.docs',
          ),
          url: DOCS_URL,
        }}
      >
        <DeeplinkUrlTable />
      </SideDrawer>
    </>
  );
};
