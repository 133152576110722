/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    OauthProviderRequest,
    OauthProviderRequestFromJSON,
    OauthProviderRequestFromJSONTyped,
    OauthProviderRequestToJSON,
} from './OauthProviderRequest';
import {
    PasswordSourceTypeEnum,
    PasswordSourceTypeEnumFromJSON,
    PasswordSourceTypeEnumFromJSONTyped,
    PasswordSourceTypeEnumToJSON,
} from './PasswordSourceTypeEnum';
import {
    WalletAdditionalAddress,
    WalletAdditionalAddressFromJSON,
    WalletAdditionalAddressFromJSONTyped,
    WalletAdditionalAddressToJSON,
} from './WalletAdditionalAddress';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface VerifyRequest
 */
export interface VerifyRequest {
    /**
     * 
     * @type {OauthProviderRequest}
     * @memberof VerifyRequest
     */
    oauth?: OauthProviderRequest;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    signedMessage: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    messageToSign: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    publicWalletAddress: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof VerifyRequest
     */
    chain: ChainEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    walletName: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof VerifyRequest
     */
    walletProvider: WalletProviderEnum;
    /**
     * Used for wallet transfers. When set to true it will execute the transfer even if the wallet is the only one that the transferor has.Be warn that this will result an orphan account that will be impossible to access.
     * @type {boolean}
     * @memberof VerifyRequest
     */
    skipEmptyAccountCheck?: boolean;
    /**
     * When provided, used to verify that a captcha is valid and get the success/failure result from the captcha provider server-side.
     * @type {string}
     * @memberof VerifyRequest
     */
    captchaToken?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    network?: string;
    /**
     * Additional addresses associated with the wallet.
     * @type {Array<WalletAdditionalAddress>}
     * @memberof VerifyRequest
     */
    additionalWalletAddresses?: Array<WalletAdditionalAddress>;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    backup?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    password?: string;
    /**
     * 
     * @type {PasswordSourceTypeEnum}
     * @memberof VerifyRequest
     */
    passwordSource?: PasswordSourceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyRequest
     */
    sessionPublicKey?: string;
}

export function VerifyRequestFromJSON(json: any): VerifyRequest {
    return VerifyRequestFromJSONTyped(json, false);
}

export function VerifyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oauth': !exists(json, 'oauth') ? undefined : OauthProviderRequestFromJSON(json['oauth']),
        'signedMessage': json['signedMessage'],
        'messageToSign': json['messageToSign'],
        'publicWalletAddress': json['publicWalletAddress'],
        'chain': ChainEnumFromJSON(json['chain']),
        'walletName': json['walletName'],
        'walletProvider': WalletProviderEnumFromJSON(json['walletProvider']),
        'skipEmptyAccountCheck': !exists(json, 'skipEmptyAccountCheck') ? undefined : json['skipEmptyAccountCheck'],
        'captchaToken': !exists(json, 'captchaToken') ? undefined : json['captchaToken'],
        'network': !exists(json, 'network') ? undefined : json['network'],
        'additionalWalletAddresses': !exists(json, 'additionalWalletAddresses') ? undefined : ((json['additionalWalletAddresses'] as Array<any>).map(WalletAdditionalAddressFromJSON)),
        'backup': !exists(json, 'backup') ? undefined : json['backup'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'passwordSource': !exists(json, 'passwordSource') ? undefined : PasswordSourceTypeEnumFromJSON(json['passwordSource']),
        'sessionPublicKey': !exists(json, 'sessionPublicKey') ? undefined : json['sessionPublicKey'],
    };
}

export function VerifyRequestToJSON(value?: VerifyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oauth': OauthProviderRequestToJSON(value.oauth),
        'signedMessage': value.signedMessage,
        'messageToSign': value.messageToSign,
        'publicWalletAddress': value.publicWalletAddress,
        'chain': ChainEnumToJSON(value.chain),
        'walletName': value.walletName,
        'walletProvider': WalletProviderEnumToJSON(value.walletProvider),
        'skipEmptyAccountCheck': value.skipEmptyAccountCheck,
        'captchaToken': value.captchaToken,
        'network': value.network,
        'additionalWalletAddresses': value.additionalWalletAddresses === undefined ? undefined : ((value.additionalWalletAddresses as Array<any>).map(WalletAdditionalAddressToJSON)),
        'backup': value.backup,
        'password': value.password,
        'passwordSource': PasswordSourceTypeEnumToJSON(value.passwordSource),
        'sessionPublicKey': value.sessionPublicKey,
    };
}

