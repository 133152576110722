/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserOauthAccessTokenResponse
 */
export interface UserOauthAccessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof UserOauthAccessTokenResponse
     */
    accessToken: string;
}

export function UserOauthAccessTokenResponseFromJSON(json: any): UserOauthAccessTokenResponse {
    return UserOauthAccessTokenResponseFromJSONTyped(json, false);
}

export function UserOauthAccessTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserOauthAccessTokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
    };
}

export function UserOauthAccessTokenResponseToJSON(value?: UserOauthAccessTokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
    };
}

