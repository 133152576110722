/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TurnkeySignedRequest,
    TurnkeySignedRequestFromJSON,
    TurnkeySignedRequestFromJSONTyped,
    TurnkeySignedRequestToJSON,
} from './TurnkeySignedRequest';

/**
 * 
 * @export
 * @interface DeleteEmbeddedWalletsRequest
 */
export interface DeleteEmbeddedWalletsRequest {
    /**
     * 
     * @type {TurnkeySignedRequest}
     * @memberof DeleteEmbeddedWalletsRequest
     */
    turnkeySignedRequest: TurnkeySignedRequest;
}

export function DeleteEmbeddedWalletsRequestFromJSON(json: any): DeleteEmbeddedWalletsRequest {
    return DeleteEmbeddedWalletsRequestFromJSONTyped(json, false);
}

export function DeleteEmbeddedWalletsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteEmbeddedWalletsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'turnkeySignedRequest': TurnkeySignedRequestFromJSON(json['turnkeySignedRequest']),
    };
}

export function DeleteEmbeddedWalletsRequestToJSON(value?: DeleteEmbeddedWalletsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'turnkeySignedRequest': TurnkeySignedRequestToJSON(value.turnkeySignedRequest),
    };
}

