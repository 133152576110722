import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { getAllNetworks, getAvailableNetworks } from '../helpers';

export const useAvailableNetworks = () => {
  const { activeEnvironment } = useEnvironmentsContext();
  if (!activeEnvironment?.id) {
    return [];
  }

  const enabledNetworks = getAllNetworks(
    activeEnvironment.settings?.chains ?? [],
  );

  const availableNetworks = getAvailableNetworks(enabledNetworks);

  return availableNetworks;
};
