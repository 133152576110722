/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Defines the filter properties used for the gate
 * @export
 * @interface GateRuleFilter
 */
export interface GateRuleFilter {
    /**
     * 
     * @type {string}
     * @memberof GateRuleFilter
     */
    tokenId?: string;
    /**
     * Minimum token or NFT amount held by the user. If this `amount` field is provided, then Dynamic will also check that the user has this minimum amount held by the user of the NFT or Token
     * @type {number}
     * @memberof GateRuleFilter
     */
    amount?: number;
}

export function GateRuleFilterFromJSON(json: any): GateRuleFilter {
    return GateRuleFilterFromJSONTyped(json, false);
}

export function GateRuleFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): GateRuleFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tokenId': !exists(json, 'tokenId') ? undefined : json['tokenId'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
    };
}

export function GateRuleFilterToJSON(value?: GateRuleFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tokenId': value.tokenId,
        'amount': value.amount,
    };
}

