import {
  Provider,
  ProviderEnum,
  ProvidersResponse,
} from '@dynamic-labs/sdk-api';

export const getProviderInProvidersResponse = (
  providersData: ProvidersResponse | undefined,
  providerToTest: ProviderEnum,
): Provider =>
  providersData?.providers?.find(
    (provider) => provider.provider === providerToTest,
  ) || {
    provider: providerToTest,
  };
