/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum HealthcheckStatus {
    Healthy = 'healthy',
    Unhealthy = 'unhealthy'
}

export function HealthcheckStatusFromJSON(json: any): HealthcheckStatus {
    return HealthcheckStatusFromJSONTyped(json, false);
}

export function HealthcheckStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthcheckStatus {
    return json as HealthcheckStatus;
}

export function HealthcheckStatusToJSON(value?: HealthcheckStatus | null): any {
    return value as any;
}

