import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { AccessOutcomeEnum, Allowlist, Gate } from '@dynamic-labs/sdk-api';

import { Divider } from '../../../../../components/Divider';
import { Typography } from '../../../../../components/Typography';
import { useAccessListsContext } from '../../../../../context/AccessListsContext';
import { useGatesContext } from '../../../../../context/GatesContext';
import { isGate } from '../../helpers/isGate';
import { GateView } from '../../Gate/GateView';
import { AccessListView } from '../../AccessList/AccessListView';

import styles from './AccessControlView.module.css';

export const AccessControlView = () => {
  const { t } = useTranslation();

  const { gates } = useGatesContext();
  const { accessLists } = useAccessListsContext();

  const siteAccessRules: Array<Allowlist | Gate> = useMemo(
    () => [
      ...accessLists.filter((l) => l.outcome === AccessOutcomeEnum.SiteAccess),
      ...gates.filter((g) => g.outcome === AccessOutcomeEnum.SiteAccess),
    ],
    [accessLists, gates],
  );

  const scopeRules: Array<Allowlist | Gate> = useMemo(
    () => [
      ...accessLists.filter((l) => l.outcome === AccessOutcomeEnum.Scope),
      ...gates.filter((g) => g.outcome === AccessOutcomeEnum.Scope),
    ],
    [accessLists, gates],
  );

  const blockRules: Array<Allowlist | Gate> = useMemo(
    () => [
      ...accessLists.filter((l) => l.outcome === AccessOutcomeEnum.Block),
      ...gates.filter((g) => g.outcome === AccessOutcomeEnum.Block),
    ],
    [accessLists, gates],
  );

  const renderOutcomeRules = (rules: Array<Allowlist | Gate>) =>
    rules.map((rule, index) => (
      <>
        {index > 0 && (
          <Divider
            label={t(
              'integrations.onboarding_and_kyc.access_control.logicalOperator.or',
            )}
            position='center'
            className={styles.outcome__divider}
            key={`rule-divider-${rule.id}`}
          />
        )}
        {isGate(rule) ? (
          <GateView
            key={`gate-${rule.id}`}
            gate={rule}
            className={styles.outome__rule}
          />
        ) : (
          <AccessListView
            key={`acess-list-${rule.id}`}
            allowList={rule}
            className={styles.outome__rule}
          />
        )}
      </>
    ));

  return (
    <div className={styles.outcomes}>
      {siteAccessRules?.length > 0 && (
        <div className={styles.outcome}>
          <Typography as='h2' variant='subtitle' weight='medium'>
            {t(
              'integrations.onboarding_and_kyc.access_control.site_access_section_title',
            )}
          </Typography>
          <div className={styles.outcome__rules}>
            {renderOutcomeRules(siteAccessRules)}
          </div>
        </div>
      )}
      {scopeRules?.length > 0 && (
        <div className={styles.outcome}>
          <Typography as='h2' variant='subtitle' weight='medium'>
            {t(
              'integrations.onboarding_and_kyc.access_control.scopes_section_title',
            )}
          </Typography>
          <div className={styles.outcome__rules}>
            {renderOutcomeRules(scopeRules)}
          </div>
        </div>
      )}
      {blockRules?.length > 0 && (
        <div className={styles.outcome}>
          <Typography as='h2' variant='subtitle' weight='medium'>
            {t(
              'integrations.onboarding_and_kyc.access_control.block_section_title',
            )}
          </Typography>
          <div className={styles.outcome__rules}>
            {renderOutcomeRules(blockRules)}
          </div>
        </div>
      )}
    </div>
  );
};
