import { ReactNode } from 'react';

import { DynamicContextProvider } from '@dynamic-labs/sdk-react-core';
import { EthereumWalletConnectors } from '@dynamic-labs/ethereum';
import { AlgorandWalletConnectors } from '@dynamic-labs/algorand';
import { SolanaWalletConnectors } from '@dynamic-labs/solana';
import { FlowWalletConnectors } from '@dynamic-labs/flow';
import { StarknetWalletConnectors } from '@dynamic-labs/starknet';
import { CosmosWalletConnectors } from '@dynamic-labs/cosmos';
import { BitcoinWalletConnectors } from '@dynamic-labs/bitcoin';
import { dynamicTopBarWidgetCssOverrides } from '@dynamic-labs/northstar';

import environment from '../environments/environment';

import { baseUrl } from './services/api';

const App = ({ children }: { children?: ReactNode }) => {
  let environmentId = '';
  if (!process.env.NX_BUILD_DYNAMIC_ENVIRONMENT_ID) {
    throw new Error('NX_BUILD_DYNAMIC_ENVIRONMENT_ID must be defined');
  } else {
    environmentId = process.env.NX_BUILD_DYNAMIC_ENVIRONMENT_ID;
  }

  return (
    <DynamicContextProvider
      emitErrors
      settings={{
        apiBaseUrl: baseUrl,
        appLogoUrl: environment.companyLogoUrl,
        appName: 'Dynamic',
        cssOverrides: dynamicTopBarWidgetCssOverrides,
        environmentId,
        privacyPolicyUrl: 'https://www.dynamic.xyz/privacy-policy',
        socialMediaLinkText: environment.socialMediaLinkText,
        socialMediaUrl: environment.socialMediaUrl,
        termsOfServiceUrl: 'https://www.dynamic.xyz/terms-conditions',
        walletConnectors: [
          EthereumWalletConnectors,
          AlgorandWalletConnectors,
          SolanaWalletConnectors,
          FlowWalletConnectors,
          StarknetWalletConnectors,
          CosmosWalletConnectors,
          BitcoinWalletConnectors,
        ],
      }}
    >
      {children}
    </DynamicContextProvider>
  );
};

export default App;
