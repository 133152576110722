import { TFunction } from 'react-i18next';
import Moment from 'react-moment';

import { Token } from '@dynamic-labs/sdk-api';

import { TableSkeleton } from '../../../components/TableSkeleton';

import { DeleteTokenCell } from './DeleteTokenCell';

export const apiKeysColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: '#',
    accessor: (_row: Token, i: number) => i + 1,
    className: 'text-gray-2 min-w-[28px]',
    id: 'index',
    sortable: false,
  },
  {
    Header: t('api_keys_table.token'),
    accessor: (row: Token) => (
      <div className='text-gray-1 text-xs overflow-hidden text-ellipsis max-w-[180px]'>
        ...{row.checksum}
      </div>
    ),
    className: 'min-w-[180px] w-full',
    id: 'key',
    skeleton: <TableSkeleton />,
  },
  {
    Header: t('api_keys_table.name'),
    accessor: (row: Token) => (
      <div className='truncate max-w-[200px]'>{row.note}</div>
    ),
    className: '',
    id: 'name',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
  {
    Header: t('api_keys_table.created_at'),
    accessor: (row: Token) => (
      <Moment format='MM/DD/YYYY'>{row.createdAt}</Moment>
    ),
    id: 'created_at',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
  {
    Header: t('api_keys_table.created_by_id'),
    accessor: (row: Token) => <div>{row.createdBy?.id}</div>,
    id: 'created_by_alias',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
  {
    Header: t('api_keys_table.created_by_name'),
    accessor: (row: Token) =>
      row.createdBy?.alias ? (
        <div className='truncate min-w-[110px] max-w-[130px]'>
          {row.createdBy?.alias}
        </div>
      ) : (
        <div className='min-w-[110px] text-center'>-</div>
      ),
    id: 'created_by_id',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
  {
    accessor: (row: Token) => <DeleteTokenCell tokenId={row.id} />,
    id: 'delete_by_id',
    skeleton: <TableSkeleton />,
  },
];
