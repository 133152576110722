import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseEnableProviderHook } from './useEnableProvider.types';

export const useEnableProvider: UseEnableProviderHook = (options) => {
  const { settingsApi } = useRedcoastQueryContext();

  return useMutation(
    ({ providerId }) =>
      settingsApi.enableProvider({
        providerId,
      }),
    options,
  );
};
