import React, { forwardRef } from 'react';

import styles from './textarea.module.css';

type Props = React.ComponentPropsWithoutRef<'textarea'> & {
  className?: string;
  cols?: number;
  ddHide?: boolean;
  error?: boolean;
  inputClassName?: string;
  label: string;
  rows?: number;
};

const TextArea = forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      className = '',
      cols = 1,
      disabled,
      error = false,
      id,
      label,
      onChange,
      onBlur,
      name,
      rows = 3,
      value,
      inputClassName,
      ddHide,
    },
    ref,
  ) => (
    <div className={`${styles.container} ${className}`}>
      <textarea
        cols={cols}
        rows={rows}
        disabled={disabled}
        name={name}
        aria-label={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        id={id}
        placeholder={label}
        className={`peer peer-focus:pt-6 ${styles.textarea} ${
          error ? styles['textarea-error'] : ''
        } ${inputClassName}`}
        data-dd-privacy={ddHide ? 'mask' : undefined}
        ref={ref}
      />
      <label
        htmlFor={id}
        className={`peer peer-placeholder-shown:text-xs peer-placeholder-shown:top-[16px] peer-focus:top-2 peer-focus:text-[10px] peer-focus:leading-[12px] ${
          styles.label
        } ${error ? styles['label-error'] : ''}`}
      >
        {label}
      </label>
    </div>
  ),
);

export default TextArea;
