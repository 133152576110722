import classNames from 'classnames';

import { Icon } from '../Icon';
import { IconColor } from '../Icon/Icon.types';
import { SpinnerIcon } from '../../icons';
import { pxToRem } from '../../helpers/pxToRem';

import styles from './Spinner.module.scss';

interface Props {
  className?: string;
  color?: IconColor;
  size?: number;
}

export const Spinner = ({
  className = '',
  size = 16,
  color = 'primary-1',
}: Props) => (
  <div
    data-testid='spinner-component'
    className={className}
    style={{
      display: 'flex',
      maxHeight: pxToRem(size),
      maxWidth: pxToRem(size),
    }}
  >
    <Icon
      className={classNames(styles.spinner, styles[`spinner--${color}`])}
      icon={<SpinnerIcon />}
      size={size}
      color={color}
    />
  </div>
);
