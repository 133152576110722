import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useSetKycFieldProp } from '../../../../../../../app/hooks/useSetKycFieldProp';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { IntegrationCategory } from '../../../../../../../app/types';

type KycPropToggleProps = {
  disabled?: boolean;
  field: 'required' | 'unique' | 'verify';
  name: string;
};

export const KycPropToggle: FC<KycPropToggleProps> = ({
  field,
  name,
  disabled,
}) => {
  const { t } = useTranslation();

  const { settings, initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { updateActiveEnvKycFieldProp } = useSetKycFieldProp(
    IntegrationCategory.OnboardingAndKYC,
  );

  const kycFieldTypeValue = settings[activeEnvironmentType].kyc.find(
    (kycField) => kycField.name === name,
  )?.[field];
  const savedKycFieldTypeValue = initialSettings[
    activeEnvironmentType
  ].kyc.find((kycField) => kycField.name === name)?.[field];

  const isKycFieldTypeValueSaved = kycFieldTypeValue === savedKycFieldTypeValue;

  const isFieldEnabled = settings[activeEnvironmentType].kyc.find(
    (kycField) => kycField.name === name,
  )?.enabled;

  const showDescription = name === 'email' || name === 'phoneNumber';

  return (
    <SingleToggleCard
      accordionKey={`kyc-prop-toggle-${name}-${field}`}
      title={t(`v2.page.log_in_methods.kyc.${field}.title`)}
      descriptionModificators={
        showDescription
          ? {
              components: {
                strong: <strong />,
              },
              i18nKey: `v2.page.log_in_methods.kyc.${field}.${name}_description`,
              values: {
                field: t(`v2.page.log_in_methods.kyc.names.${name}`),
              },
            }
          : undefined
      }
      description={
        field === 'required'
          ? t(`v2.page.log_in_methods.kyc.${field}.description`)
          : undefined
      }
      allowExpand={false}
      inputProps={{
        checked: Boolean(kycFieldTypeValue && isFieldEnabled),
        disabled: !isFieldEnabled || disabled,
        id: `kyc-prop-toggle-${name}-${field}`,
        isSaved: isKycFieldTypeValueSaved,
        onChange: (e) =>
          updateActiveEnvKycFieldProp({
            isSelected: e.target.checked,
            name,
            type: field,
          }),
        type: 'toggle',
      }}
    />
  );
};
