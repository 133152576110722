import { CopyField, Input, Typography } from '@dynamic-labs/northstar';

import styles from './TestAccountFormField.module.scss';

/** View component for Test Account fields */
export const TestAccountFormField = ({
  copyValue,
  description,
  readOnlyValue,
  title,
}: {
  copyValue?: string;
  description: string;
  readOnlyValue?: string;
  title: string;
}) => (
  <>
    <Typography as='p' variant='paragraph-2' weight='bold' color='black-2'>
      {title}
    </Typography>
    <Typography as='p' variant='paragraph-1' weight='regular' color='black-2'>
      {description}
    </Typography>

    {readOnlyValue && (
      <Input
        name={title}
        ariaLabel={title}
        className={styles.input}
        value={readOnlyValue}
        readOnly
      />
    )}

    {copyValue && <CopyField className={styles.copy} copyText={copyValue} />}
  </>
);
