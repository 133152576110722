import { FC } from 'react';

import classNames from 'classnames';

import { ClassStyleProps, Typography } from '@dynamic-labs/northstar';

import styles from './PageHeader.module.scss';

type PageHeaderProps = ClassStyleProps & {
  description: string;
  title: string;
};

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  className,
  style,
}) => (
  <div className={classNames(styles.wrapper, className)} style={style}>
    <Typography as='h1' variant='title'>
      {title}
    </Typography>

    <Typography as='h2' variant='subtitle' color='gray-1'>
      {description}
    </Typography>
  </div>
);
