import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { ROUTES } from '../../../../../components/Navigation/data';
import { Typography } from '../../../../../components/Typography';
import { Card } from '../Card';
import styles from '../card.module.css';
import { useSubscriptionContext } from '../../../../../context/SubscriptionContext';

type Props = {
  className?: string;
};

export const TrialGiftCard = ({ className }: Props) => {
  const { t } = useTranslation();
  const { subscription } = useSubscriptionContext();

  const type =
    subscription?.version === '1'
      ? t('upsell_banner.advanced')
      : t('upsell_banner.standard');

  return (
    <Card
      key='card-trial-gift'
      className={classNames(styles['overview-card__content--blue'], className)}
      title={t('overview.trial_gift.heading', {
        type,
      })}
      content={{
        content: (
          <Typography variant='paragraph-1'>
            <Trans
              i18nKey='overview.trial_gift.content'
              values={{
                type,
              }}
              components={{
                anchor: (
                  // eslint-disable-next-line jsx-a11y/control-has-associated-label, jsx-a11y/anchor-has-content
                  <a
                    href={`${ROUTES.adminSubscriptionTab}`}
                    target='_blank'
                    rel='noreferrer'
                  />
                ),
              }}
            />
          </Typography>
        ),
        type: 'simple',
      }}
    />
  );
};
