import { FC } from 'react';

import { Icon } from '../../../Icon';
import { TextButton } from '../../../TextButton';
import { PlusIcon } from '../../../../icons';
import { OrganizationsDropdownCTA } from '../../UserOrganizationsDropdown.types';

import styles from './CreateOrganizationCTA.module.scss';

export const CreateOrganizationCTA: FC<OrganizationsDropdownCTA> = ({
  text,
  action,
  setOpen,
}) => (
  <TextButton
    className={styles.button}
    leading={<Icon icon={<PlusIcon />} size={20} className={styles.icon} />}
    onClick={() => {
      setOpen?.(false);
      action();
    }}
    color='inherit'
    text={text}
  />
);
