/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Key,
    KeyFromJSON,
    KeyFromJSONTyped,
    KeyToJSON,
} from './Key';

/**
 * 
 * @export
 * @interface KeyResponse
 */
export interface KeyResponse {
    /**
     * 
     * @type {Key}
     * @memberof KeyResponse
     */
    key?: Key;
}

export function KeyResponseFromJSON(json: any): KeyResponse {
    return KeyResponseFromJSONTyped(json, false);
}

export function KeyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): KeyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'key': !exists(json, 'key') ? undefined : KeyFromJSON(json['key']),
    };
}

export function KeyResponseToJSON(value?: KeyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'key': KeyToJSON(value.key),
    };
}

