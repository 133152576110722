/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TokenCreatedBy,
    TokenCreatedByFromJSON,
    TokenCreatedByFromJSONTyped,
    TokenCreatedByToJSON,
} from './TokenCreatedBy';
import {
    TokenWithRawProjectEnvironment,
    TokenWithRawProjectEnvironmentFromJSON,
    TokenWithRawProjectEnvironmentFromJSONTyped,
    TokenWithRawProjectEnvironmentToJSON,
} from './TokenWithRawProjectEnvironment';

/**
 * 
 * @export
 * @interface TokenWithRaw
 */
export interface TokenWithRaw {
    /**
     * 
     * @type {string}
     * @memberof TokenWithRaw
     */
    id: string;
    /**
     * The actual token created. If you do not save it, it can never be retrieved again
     * @type {string}
     * @memberof TokenWithRaw
     */
    rawToken: string;
    /**
     * A 6-character alpha-numeric string checksum of the random API Token. The last 6 characters of the raw token
     * @type {string}
     * @memberof TokenWithRaw
     */
    checksum: string;
    /**
     * 
     * @type {TokenCreatedBy}
     * @memberof TokenWithRaw
     */
    createdBy: TokenCreatedBy;
    /**
     * Only active tokens can be used.
     * @type {boolean}
     * @memberof TokenWithRaw
     */
    active?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof TokenWithRaw
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof TokenWithRaw
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenWithRaw
     */
    projectEnvironmentId: string;
    /**
     * 
     * @type {TokenWithRawProjectEnvironment}
     * @memberof TokenWithRaw
     */
    projectEnvironment: TokenWithRawProjectEnvironment;
}

export function TokenWithRawFromJSON(json: any): TokenWithRaw {
    return TokenWithRawFromJSONTyped(json, false);
}

export function TokenWithRawFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenWithRaw {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'rawToken': json['rawToken'],
        'checksum': json['checksum'],
        'createdBy': TokenCreatedByFromJSON(json['createdBy']),
        'active': !exists(json, 'active') ? undefined : json['active'],
        'createdAt': (new Date(json['createdAt'])),
        'note': !exists(json, 'note') ? undefined : json['note'],
        'projectEnvironmentId': json['projectEnvironmentId'],
        'projectEnvironment': TokenWithRawProjectEnvironmentFromJSON(json['projectEnvironment']),
    };
}

export function TokenWithRawToJSON(value?: TokenWithRaw | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rawToken': value.rawToken,
        'checksum': value.checksum,
        'createdBy': TokenCreatedByToJSON(value.createdBy),
        'active': value.active,
        'createdAt': (value.createdAt.toISOString()),
        'note': value.note,
        'projectEnvironmentId': value.projectEnvironmentId,
        'projectEnvironment': TokenWithRawProjectEnvironmentToJSON(value.projectEnvironment),
    };
}

