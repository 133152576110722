import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { DropdownMenu } from '../DropdownMenu';
import { Typography } from '../Typography';
import { Icon } from '../Icon';
import { TextButton } from '../TextButton';
import { ThreeDotsIcon } from '../../icons';
import {
  UserOrganizationsDropdownItemsWrapper,
  UserOrganizationsDropdownSubItemsWrapper,
} from '../UserOrganizationsDropdown/components';
import { Skeleton } from '../Skeleton';
import { OpacityAnimation } from '../../animations';

import styles from './UserProjectsDropdown.module.scss';
import {
  UserProjectsSelectedValueRenderer,
  CreateProjectCTA,
} from './components';
import { UserProjectsDropdownProps } from './UserProjectsDropdown.types';

export const UserProjectsDropdown: FC<UserProjectsDropdownProps> = ({
  activeProjectId,
  projects,
  setActiveProject,
  projectActions,
  projectsDropdownCTA,
  isLoading = false,
}) => {
  const subItems = projectActions
    .filter((action) => !action.disabled)
    .map((action) => (
      <TextButton
        key={action.text}
        onClick={() => activeProjectId && action.action(activeProjectId)}
        text={action.text}
        variant='button-medium'
        className={styles.subItem}
      />
    ));

  if (isLoading || projects.length === 0) {
    return <Skeleton className={styles.skeleton} />;
  }

  return (
    <AnimatePresence>
      <OpacityAnimation>
        <DropdownMenu
          value={activeProjectId}
          onSelect={(value) => value && setActiveProject(value)} // Don't allow undefined value
          options={[
            ...projects.map((organization) => (
              <DropdownMenu.ItemWithSubItemActions
                value={organization.id}
                className={styles.item}
                subItems={subItems}
                subMenuTrigger={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Icon
                    size={20}
                    icon={<ThreeDotsIcon />}
                    className={styles.trigger}
                  />
                }
                rawOption={organization}
                subItemsSideOffset={6}
                subItemsWrapper={UserOrganizationsDropdownSubItemsWrapper}
              >
                <Typography
                  variant='paragraph-2'
                  weight='medium'
                  className={styles.name}
                >
                  {organization.name}
                </Typography>
              </DropdownMenu.ItemWithSubItemActions>
            )),
          ]}
          className={styles.container}
          allowUndefinedValue={false} // Don't allow undefined value
          itemsWrapper={UserOrganizationsDropdownItemsWrapper}
          selectedValueWrapper={UserProjectsSelectedValueRenderer}
          itemsSideOffset={8}
          dropdownCta={
            projectsDropdownCTA.disabled ? undefined : (
              // eslint-disable-next-line react/jsx-wrap-multilines
              <CreateProjectCTA
                text={projectsDropdownCTA.text}
                action={projectsDropdownCTA.action}
              />
            )
          }
        />
      </OpacityAnimation>
    </AnimatePresence>
  );
};
