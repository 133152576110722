/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MFADeviceType,
    MFADeviceTypeFromJSON,
    MFADeviceTypeFromJSONTyped,
    MFADeviceTypeToJSON,
} from './MFADeviceType';

/**
 * 
 * @export
 * @interface MFASettings
 */
export interface MFASettings {
    /**
     * 
     * @type {boolean}
     * @memberof MFASettings
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MFASettings
     */
    required?: boolean;
    /**
     * 
     * @type {Array<MFADeviceType>}
     * @memberof MFASettings
     */
    availableMethods?: Array<MFADeviceType>;
}

export function MFASettingsFromJSON(json: any): MFASettings {
    return MFASettingsFromJSONTyped(json, false);
}

export function MFASettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFASettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'required': !exists(json, 'required') ? undefined : json['required'],
        'availableMethods': !exists(json, 'availableMethods') ? undefined : ((json['availableMethods'] as Array<any>).map(MFADeviceTypeFromJSON)),
    };
}

export function MFASettingsToJSON(value?: MFASettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'required': value.required,
        'availableMethods': value.availableMethods === undefined ? undefined : ((value.availableMethods as Array<any>).map(MFADeviceTypeToJSON)),
    };
}

