/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NextViewEnum {
    Done = 'done',
    VerifyEmail = 'verify-email',
    VerifiedAndTransferred = 'verified-and-transferred',
    VerifySms = 'verify-sms'
}

export function NextViewEnumFromJSON(json: any): NextViewEnum {
    return NextViewEnumFromJSONTyped(json, false);
}

export function NextViewEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextViewEnum {
    return json as NextViewEnum;
}

export function NextViewEnumToJSON(value?: NextViewEnum | null): any {
    return value as any;
}

