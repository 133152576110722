// eslint-disable-next-line import/no-namespace
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import classNames from 'classnames';

import { DropdownIcon, LockIcon } from '../../../icons';
import { Typography } from '../Typography';

import styles from './DropdownTrigger.module.css';

type Props = {
  children: React.ReactNode;
  disabled?: boolean;
  error?: boolean;
  rootClassName?: string;
};

export const DropdownTrigger = ({
  children,
  disabled,
  rootClassName,
  error,
}: Props) => (
  <DropdownMenu.Trigger
    disabled={disabled}
    className={classNames(
      styles.container,
      {
        [styles.disabled]: disabled,
        [styles['container-error']]: error,
      },
      rootClassName,
    )}
  >
    <Typography
      className={classNames(styles.content, {
        [styles['content-error']]: error,
      })}
      variant='paragraph-1'
      weight='regular'
      as='span'
    >
      {children}
    </Typography>
    {disabled ? (
      <LockIcon className={styles.icon} />
    ) : (
      <DropdownIcon className={styles.icon} />
    )}
  </DropdownMenu.Trigger>
);
