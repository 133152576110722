import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../../app/components/Typography';

import styles from './JwtTestResponse.module.css';

export const JwtTestResponse = ({ response }: { response: any }) => {
  const { t } = useTranslation();
  if (!response) return null;

  const renderErrorList = (errors: string[]) => (
    <ul className={styles.errorList}>
      {errors.map((error: string) => (
        <li key={error} className={styles.errorItem}>
          {error}
        </li>
      ))}
    </ul>
  );

  const renderSingleError = (message: string) => (
    <ul className={styles.errorList}>
      <li className={styles.errorItem}>{message}</li>
    </ul>
  );

  const getSingleErrorMessage = (status: number) =>
    status === 400
      ? t('third_party_auth.check_jwt.errors.missing_fields')
      : t('third_party_auth.check_jwt.errors.generic_error');

  const responseContent = () => {
    if (response.valid) {
      return {
        heading: t('third_party_auth.check_jwt.valid'),
        headingClass: styles.successHeading,
        message: '',
        messageClass: styles.successMessage,
      };
    }
    const heading = t('third_party_auth.check_jwt.invalid');
    const headingClass = styles.errorHeading;
    const messageClass = styles.errorMessage;
    const message = response.errors
      ? renderErrorList(response.errors)
      : renderSingleError(getSingleErrorMessage(response.status));
    return { heading, headingClass, message, messageClass };
  };

  const { heading, message, headingClass, messageClass } = responseContent();

  return (
    <div className={styles.responseContainer}>
      <Typography
        variant='paragraph-2'
        weight='medium'
        className={`${styles.heading} ${headingClass}`}
      >
        {heading}
      </Typography>
      <Typography variant='paragraph-1' className={messageClass}>
        {message}
      </Typography>
    </div>
  );
};
