import { Typography } from '../Typography';

import styles from './form-error.module.css';

type Props = {
  message: string;
  title: string;
};

export const FormError = ({ title, message }: Props) => (
  <span className={styles['error-message__container']}>
    <Typography variant='paragraph-2' weight='bold' className={styles.heading}>
      {title}
    </Typography>
    <Typography
      variant='paragraph-1'
      weight='medium'
      className={styles['error-message']}
    >
      {message}
    </Typography>
  </span>
);
