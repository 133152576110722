/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TurnkeyDeleteEmbeddedWalletsRequestBodyParameters
 */
export interface TurnkeyDeleteEmbeddedWalletsRequestBodyParameters {
    /**
     * 
     * @type {Array<string>}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBodyParameters
     */
    walletIds: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof TurnkeyDeleteEmbeddedWalletsRequestBodyParameters
     */
    deleteWithoutExport?: boolean;
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSON(json: any): TurnkeyDeleteEmbeddedWalletsRequestBodyParameters {
    return TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSONTyped(json, false);
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): TurnkeyDeleteEmbeddedWalletsRequestBodyParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletIds': json['walletIds'],
        'deleteWithoutExport': !exists(json, 'deleteWithoutExport') ? undefined : json['deleteWithoutExport'],
    };
}

export function TurnkeyDeleteEmbeddedWalletsRequestBodyParametersToJSON(value?: TurnkeyDeleteEmbeddedWalletsRequestBodyParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletIds': value.walletIds,
        'deleteWithoutExport': value.deleteWithoutExport,
    };
}

