import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { Typography } from '../../../../../components/Typography';
import { useSettingsContext } from '../../../../../context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { RadioButton } from '../../../../../components/RadioButton';
import { Toggle } from '../../../../../components/Toggle';

type Props = {
  disabled: boolean;
  enabled: boolean;
  onToggleChange: (value: boolean) => void;
  provider: ProviderEnum.CoinbaseWaas | ProviderEnum.Turnkey;
  providerInitialEnabled: boolean;
};

enum WalletCreationMode {
  mandatory = 'mandatory',
  pregenerate = 'pregenerate',
}

const ADVANCED_MODE_DOCS =
  'https://docs.dynamic.xyz/embedded-wallets/dynamic-embedded-wallets#defer-wallet-creation';

export const WalletCreationModeToggle = ({
  enabled,
  onToggleChange,
  disabled: turnkeyToggleDisabled,
  providerInitialEnabled,
  provider,
}: Props) => {
  const { t } = useTranslation();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!enabled) {
      onToggleChange(true);
    }

    if (event.target.value === WalletCreationMode.pregenerate) {
      setSettings({
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          sdk: {
            ...settings[activeEnvironmentType].sdk,
            embeddedWallets: {
              ...settings[activeEnvironmentType].sdk.embeddedWallets,
              allowSkippingAuthenticatorAtSignup: false,
              automaticEmbeddedWalletCreation: true,
              forceAuthenticatorAtSignup: false,
            },
          },
        },
      });

      return;
    }

    if (isTurnkeyAndPasskeyDisabled) {
      return;
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            automaticEmbeddedWalletCreation: true,
            forceAuthenticatorAtSignup: true,
          },
        },
      },
    });
  };

  const isChecked = (value: WalletCreationMode): boolean => {
    if (value === WalletCreationMode.pregenerate) {
      return Boolean(
        settings[activeEnvironmentType].sdk.embeddedWallets
          ?.automaticEmbeddedWalletCreation &&
          !settings[activeEnvironmentType].sdk.embeddedWallets
            ?.forceAuthenticatorAtSignup &&
          enabled &&
          !turnkeyToggleDisabled,
      );
    }

    return Boolean(
      settings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreation &&
        settings[activeEnvironmentType].sdk.embeddedWallets
          ?.forceAuthenticatorAtSignup &&
        enabled &&
        !turnkeyToggleDisabled,
    );
  };

  const isTurnkeyAndPasskeyDisabled =
    provider === ProviderEnum.Turnkey &&
    !settings[activeEnvironmentType]?.sdk.embeddedWallets
      ?.supportedSecurityMethods?.passkey?.isEnabled;

  const handleToggleManualMode = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!enabled) {
      onToggleChange(true);
    }

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            automaticEmbeddedWalletCreation: !event.target.checked,
            forceAuthenticatorAtSignup: false,
          },
        },
      },
    });
  };

  const shouldManualModeBeChecked = () =>
    Boolean(
      !settings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreation &&
        !settings[activeEnvironmentType].sdk.embeddedWallets
          ?.forceAuthenticatorAtSignup &&
        enabled &&
        !turnkeyToggleDisabled,
    );

  const isManualModeSettingSaved =
    settings[activeEnvironmentType].sdk.embeddedWallets
      ?.automaticEmbeddedWalletCreation ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.automaticEmbeddedWalletCreation && providerInitialEnabled;

  return (
    <div>
      <div className='flex flex-col'>
        <Typography variant='paragraph-2' weight='bold'>
          {t(`integrations.wallets.providers.${provider}.creation_mode.title`)}
        </Typography>
        {provider === ProviderEnum.Turnkey && (
          <Typography variant='paragraph-1' weight='medium' color='gray-2'>
            {t(`integrations.wallets.providers.${provider}.creation_mode.info`)}
          </Typography>
        )}
      </div>

      <div className='flex flex-col gap-3 pt-4'>
        <div className='flex flex-col gap-2'>
          <RadioButton
            name={provider}
            id={`${provider}-mandatory`}
            label={t(
              `integrations.wallets.providers.${provider}.creation_mode.mandatory.label`,
            )}
            description={t(
              `integrations.wallets.providers.${provider}.creation_mode.mandatory.description`,
            )}
            value={WalletCreationMode.mandatory}
            checked={isChecked(WalletCreationMode.mandatory)}
            onChange={handleChange}
            disabled={
              turnkeyToggleDisabled || (isTurnkeyAndPasskeyDisabled && enabled)
            }
          />
        </div>

        <RadioButton
          name={provider}
          id={`${provider}-pregenerate`}
          label={t(
            `integrations.wallets.providers.${provider}.creation_mode.pregenerate.label`,
          )}
          description={t(
            `integrations.wallets.providers.${provider}.creation_mode.pregenerate.description`,
          )}
          value={WalletCreationMode.pregenerate}
          checked={isChecked(WalletCreationMode.pregenerate)}
          onChange={handleChange}
          disabled={turnkeyToggleDisabled}
        />
      </div>

      <div className='flex gap-3 items-center pt-4'>
        <Toggle
          handleChange={handleToggleManualMode}
          variant={isManualModeSettingSaved ? 'success' : 'primary'}
          checked={shouldManualModeBeChecked()}
          id={`${provider}-advanced-mode`}
          ariaLabel={`${provider}-advanced-mode`}
          disabled={turnkeyToggleDisabled}
          withIcon
          className='!flex-none'
        />
        <div className='flex flex-col'>
          <Typography variant='paragraph-2' weight='bold'>
            {t(
              `integrations.wallets.providers.${provider}.advanced_mode.label`,
            )}
          </Typography>
          <Typography variant='paragraph-1' weight='medium' color='gray-2'>
            <Trans
              i18nKey={`integrations.wallets.providers.${provider}.advanced_mode.description`}
            >
              Check the
              <a
                href={ADVANCED_MODE_DOCS}
                rel='noreferrer'
                target='_blank'
                className='text-primary-1'
              >
                docs
              </a>
            </Trans>
          </Typography>
        </div>
      </div>
    </div>
  );
};
