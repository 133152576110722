import { t } from 'i18next';

export const ipOptions = [
  {
    key: 'true',
    name: t('settings_page.privacy.collectIp.on'),
  },
  {
    key: 'false',
    name: t('settings_page.privacy.collectIp.off'),
  },
];
