import { ChangeEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum, SmsCountryCode } from '@dynamic-labs/sdk-api';
import { SingleToggleCard, Typography, Tag } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../app/context/ProvidersContext';
import {
  CountryCode,
  dynamicSupportedCountries,
  supportedCountries,
} from '../../../../../../../../app/routes/Configurations/Providers/components/SmsProviderSection/utils/countries';

import { OwnTwilioCredentialsInput } from './OwnTwilioCredentialsInput';
import styles from './PhoneNumberCredentials.module.scss';

export const PhoneNumberCredentials = () => {
  const { t } = useTranslation();

  const { getProviderValue, onChangeProvider, hasProviderChanged, providers } =
    useProvidersContext();
  const [credentialsAccordionState, setCredentialsAccordionState] = useState<
    string | undefined
  >(undefined);

  // if some setting is missing set useDynamicCredentials true
  const initialUsedDynamicCredentials = Boolean(
    !getProviderValue(ProviderEnum.Sms, 'clientId') ||
      !getProviderValue(ProviderEnum.Sms, 'clientSecret') ||
      !getProviderValue(ProviderEnum.Sms, 'accountSid') ||
      !getProviderValue(ProviderEnum.Sms, 'twilioNumber'),
  );

  const [useDynamicCredentials, setUseDynamicCredentials] = useState(
    initialUsedDynamicCredentials,
  );

  const dynamicEnabledCountries: SmsCountryCode[] =
    dynamicSupportedCountries.map((countryCode: CountryCode) => ({
      isoCountryCode: countryCode,
      phoneCountryCode: supportedCountries[countryCode].code,
    }));

  const smsProvider = providers.find(
    (provider) => provider.provider === ProviderEnum.Sms,
  );

  // if toggle useDynamicCredentials set all fields to empty
  const handleUseDynamicCredentials = (
    e: ChangeEvent<HTMLInputElement>,
    type: 'dynamic' | 'own',
  ) => {
    if (type === 'dynamic' && e.target.checked) {
      setUseDynamicCredentials(true);
      setCredentialsAccordionState(undefined);

      onChangeProvider(ProviderEnum.Sms, 'clientId', '');
      onChangeProvider(ProviderEnum.Sms, 'clientSecret', '');
      onChangeProvider(ProviderEnum.Sms, 'accountSid', '');
      onChangeProvider(ProviderEnum.Sms, 'twilioNumber', '');
      onChangeProvider(
        ProviderEnum.Sms,
        'enabledCountries',
        dynamicEnabledCountries.filter(
          (dynamicCountry) =>
            !!smsProvider?.enabledCountries?.find(
              (country) =>
                country.isoCountryCode === dynamicCountry.isoCountryCode,
            ),
        ),
      );
      return;
    }

    setCredentialsAccordionState('twilio-own-credentials');
    setUseDynamicCredentials(false);
  };

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-3' weight='medium' color='gray-1'>
        {t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.header',
        )}
      </Typography>

      <SingleToggleCard
        accordionKey='twilio-dynamic-credentials'
        title={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.use_dynamic.title',
        )}
        description={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.use_dynamic.description',
        )}
        Tags={[
          {
            Tag: (
              <Tag
                size='medium'
                text={t(
                  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.use_dynamic.badge',
                )}
                color='gray'
                className={styles.tag}
              />
            ),
            id: 'dynamic-credentials-tag',
          },
        ]}
        allowExpand={false}
        inputProps={{
          checked: useDynamicCredentials,
          id: 'use-dynamic-credentials',
          isSaved: !hasProviderChanged(ProviderEnum.Sms),
          onChange: (e) => handleUseDynamicCredentials(e, 'dynamic'),
          type: 'radio',
        }}
      />

      <SingleToggleCard
        accordionKey='twilio-own-credentials'
        title={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.use_own.title',
        )}
        description={t(
          'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.use_own.description',
        )}
        allowExpand={!useDynamicCredentials}
        inputProps={{
          checked: !useDynamicCredentials,
          id: 'use-own-credentials',
          isSaved: !hasProviderChanged(ProviderEnum.Sms),
          onChange: (e) => handleUseDynamicCredentials(e, 'own'),
          type: 'radio',
        }}
        onValueChange={(value) => setCredentialsAccordionState(value)}
        value={useDynamicCredentials ? undefined : credentialsAccordionState}
      >
        <div className={styles.content}>
          <OwnTwilioCredentialsInput />
        </div>
      </SingleToggleCard>
    </div>
  );
};
