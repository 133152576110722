import { FC } from 'react';

import classNames from 'classnames';

import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import styles from './EnvironmentDot.module.scss';

type EnvironmentDotProps = {
  className?: string;
  type: EnvironmentEnum;
};

export const EnvironmentDot: FC<EnvironmentDotProps> = ({
  type,
  className,
}) => (
  <div
    className={classNames(
      styles.container,
      {
        [styles['container--live']]: type === EnvironmentEnum.Live,
        [styles['container--sandbox']]: type === EnvironmentEnum.Sandbox,
      },
      className,
    )}
    data-testid='environment-dot'
  >
    <div className={styles.backdrop}>
      <div className={styles.dot} />
    </div>
  </div>
);
