import { ChangeEvent, ReactNode } from 'react';

import classNames from 'classnames';

import { Toggle } from '../Toggle';
import { ArrowIcon } from '../../../icons';

import styles from './ControlFieldHeader.module.css';

type Props = {
  Trailing?: ReactNode;
  className?: string;
  clickedDisabledFieldToggle?: VoidFunction;
  description?: ReactNode;
  disableExpanding?: boolean;
  disabled: boolean;
  handleToggleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  header: ReactNode;
  isExpanded?: boolean;
  isInitialEnabled?: boolean;
  noPadding?: boolean;
  setIsExpanded?: (isExpanded: boolean) => void;
  showLock?: boolean;
  toggleClassName?: string;
  toggleId: string;
  toggleValue: boolean;
  tooltipText?: string[];
};

export const ControlFieldHeader = ({
  header,
  disabled,
  isExpanded,
  toggleValue,
  setIsExpanded,
  handleToggleChange,
  description,
  isInitialEnabled,
  toggleId,
  Trailing,
  className,
  toggleClassName,
  clickedDisabledFieldToggle,
  noPadding = false,
  showLock = false,
  tooltipText = [],
  disableExpanding,
}: Props) => {
  const getToggleVariant = () => {
    // When toggle is not enabled, we want to show it as locked
    if (showLock && !toggleValue) {
      return 'locked';
    }

    return isInitialEnabled ? 'success' : 'primary';
  };

  const handleExpand = () => {
    if (disableExpanding) {
      return;
    }

    return setIsExpanded && setIsExpanded(!isExpanded);
  };

  return (
    <div className={classNames(className, styles.wrapper)}>
      <Toggle
        withIcon
        disabled={disabled}
        className={classNames(styles.toggle, toggleClassName, {
          [styles['toggle--top']]: description,
        })}
        variant={getToggleVariant()}
        id={toggleId}
        checked={toggleValue}
        handleChange={handleToggleChange}
        ariaLabel={toggleId}
        handleDisabledClick={clickedDisabledFieldToggle}
        tooltipText={tooltipText}
        tooltipDisableHover={toggleValue}
      />

      <button
        type='button'
        aria-label='Collapse list view'
        onClick={handleExpand}
        className={classNames(styles.button__expand, {
          [styles['button__expand--enabled']]:
            !disableExpanding && setIsExpanded,
          [styles['button__expand--no-padding']]: noPadding,
        })}
      >
        <div className={styles.text}>
          {header}
          {description}
        </div>
        {Trailing}

        {!disableExpanding && setIsExpanded && (
          <div
            className={`${styles.icon} ${
              isExpanded ? styles.icon__expanded : ''
            }`}
          >
            <ArrowIcon />
          </div>
        )}
      </button>
    </div>
  );
};
