/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CreateProjectResponseProject,
    CreateProjectResponseProjectFromJSON,
    CreateProjectResponseProjectFromJSONTyped,
    CreateProjectResponseProjectToJSON,
} from './CreateProjectResponseProject';

/**
 * 
 * @export
 * @interface CreateProjectResponse
 */
export interface CreateProjectResponse {
    /**
     * 
     * @type {CreateProjectResponseProject}
     * @memberof CreateProjectResponse
     */
    project?: CreateProjectResponseProject;
}

export function CreateProjectResponseFromJSON(json: any): CreateProjectResponse {
    return CreateProjectResponseFromJSONTyped(json, false);
}

export function CreateProjectResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProjectResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'project': !exists(json, 'project') ? undefined : CreateProjectResponseProjectFromJSON(json['project']),
    };
}

export function CreateProjectResponseToJSON(value?: CreateProjectResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'project': CreateProjectResponseProjectToJSON(value.project),
    };
}

