import { useMemo } from 'react';

import { useNavigate } from 'react-router-dom';

import {
  ProviderEnum,
  UnprocessableEntityErrorCode,
} from '@dynamic-labs/sdk-api';

import { ApiError } from '../../../../../components';
import { ProviderError } from '../../../../../hooks';
import { ROUTES } from '../../../../../../app/components/Navigation/data';
import { ModalHashLocations } from '../../../../../../app/routes/Configurations/utils';
import { useProvidersContext } from '../../../../../../app/context/ProvidersContext';
import { getProviderDisplayName } from '../../../../../../app/utils/getProviderDisplayName';

type UseGetFormattedApiErrorsHook = (errors: ProviderError[]) => ApiError[];

type SupportedUnprocessableEntityErrorCodes =
  UnprocessableEntityErrorCode.InvalidPrivateKeyFormat;

export const useGetFormattedApiErrors: UseGetFormattedApiErrorsHook = (
  errors,
) => {
  const navigate = useNavigate();

  const { providers } = useProvidersContext();

  const ApiErrorsVariants: Record<
    SupportedUnprocessableEntityErrorCodes,
    Omit<ApiError, 'action'> & {
      action: (provider?: any) => void;
    }
  > = {
    [UnprocessableEntityErrorCode.InvalidPrivateKeyFormat]: {
      action: (provider) =>
        navigate(`${ROUTES.logInMethods}#social-${provider}`),
      i18nKeyActionText:
        'v2.page.log_in_methods.api_errors.invalid_private_key_format_action',
      i18nKeyText:
        'v2.page.log_in_methods.api_errors.invalid_private_key_format',
    },
  };

  const getProviderAction = (provider: ProviderEnum) => {
    if (provider === ProviderEnum.Dynamic) {
      return () =>
        navigate(
          `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsEmailMethod}`,
        );
    }

    if (provider === ProviderEnum.Sms) {
      return () =>
        navigate(
          `${ROUTES.logInMethods}#${ModalHashLocations.LogInMethodsPhoneNumberMethod}`,
        );
    }

    return () => navigate(`${ROUTES.logInMethods}#social-${provider}`);
  };

  const getProviderName = (provider: ProviderEnum) => {
    switch (provider) {
      case ProviderEnum.Dynamic:
        return 'Email';
      case ProviderEnum.Sms:
        return 'Phone number';
      default:
        return getProviderDisplayName(provider);
    }
  };

  const apiErrors: ApiError[] = useMemo(
    () =>
      errors.map((error) => {
        const currentProvider =
          error.provider ??
          (providers.find((provider) => provider.id === error.providerId)
            ?.provider as ProviderEnum);

        if (error.code && Object.keys(ApiErrorsVariants).includes(error.code)) {
          const apiError =
            ApiErrorsVariants[
              error.code as SupportedUnprocessableEntityErrorCodes
            ];

          return {
            action: () => apiError.action(currentProvider),
            i18nKeyActionText: apiError.i18nKeyActionText,
            i18nKeyText: apiError.i18nKeyText,
            provider: getProviderName(currentProvider),
          };
        }

        if (error.message) {
          return {
            action: getProviderAction(currentProvider),
            i18nKeyActionText:
              'v2.page.log_in_methods.api_errors.api_message_action',
            i18nKeyText: `${error.message} <action/>`,
            provider: getProviderName(currentProvider),
          };
        }

        return {
          i18nKeyText: 'v2.page.log_in_methods.api_errors.unknown_error',
          provider: getProviderName(currentProvider),
        };
      }),
    [ApiErrorsVariants, errors, getProviderAction, providers],
  );

  return apiErrors;
};
