import { useTranslation } from 'react-i18next';

import { SingleKey } from '../../../../components/SingleKey/SingleKey';
import { Paper } from '../../../../components/Paper';
import { Typography } from '../../../../components/Typography';

import styles from './EnvironmentKeysCard.module.css';

type Props = {
  environmentId: string;
  publicKey: string;
};

export const EnvironmentKeysCard = ({ environmentId, publicKey }: Props) => {
  const { t } = useTranslation();

  return (
    <Paper>
      <>
        <div className={`${styles.row}  mb-3`}>
          <div className={styles.info}>
            <Typography variant='paragraph-1' className={styles.title}>
              {t('apis.public')}
            </Typography>
            <Typography variant='paragraph-1' className={styles.subtitle}>
              {t('apis.public_used_for')}
            </Typography>
          </div>
          <SingleKey ddHide keyValue={publicKey} />
        </div>

        <div className={`${styles.row} items-center`}>
          <div className={styles.info}>
            <Typography variant='paragraph-1' className={styles.title}>
              {t('apis.environmentId')}
            </Typography>
            <Typography variant='paragraph-1' className={styles.subtitle}>
              {t('apis.environment_id_used_for')}
            </Typography>
          </div>
          <SingleKey keyValue={environmentId} className='items-center' />
        </div>
        <div />
      </>
    </Paper>
  );
};
