import { FormikErrors } from 'formik';
import { t } from 'i18next';

import { getValueByKey } from '@dynamic-labs/sdk-react-core';

export type InputsErrors = FormikErrors<{
  listName: string;
  scope: string;
}>;

const requiredErrorsMap = {
  listName: t(
    'integrations.onboarding_and_kyc.access_control.access_list.forms.listNameLabel',
  ),
  scope: t('integrations.onboarding_and_kyc.access_control.scopeNameLabel'),
};

export const buildErrorsInfo = (errors: InputsErrors) => {
  const requiredErrors = Object.entries(errors)
    .filter((errorData) => errorData[1] === 'required')
    .map(([key]) => getValueByKey(requiredErrorsMap, key));

  const requiredError =
    !!requiredErrors?.length &&
    t(
      'integrations.onboarding_and_kyc.access_control.errors.required_error_singular',
      {
        error: requiredErrors.join(', '),
      },
    );

  const duplicatedName =
    errors.listName === 'duplicatedName'
      ? t(
          'integrations.onboarding_and_kyc.access_control.access_list.duplicate_list_name_error',
        )
      : undefined;

  const duplicatedScope =
    errors.scope === 'duplicatedName'
      ? t(
          'integrations.onboarding_and_kyc.access_control.errors.duplicate_scope_name_error',
        )
      : undefined;

  return { duplicatedName, duplicatedScope, requiredError };
};
