/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthModeEnum,
    AuthModeEnumFromJSON,
    AuthModeEnumFromJSONTyped,
    AuthModeEnumToJSON,
} from './AuthModeEnum';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface ConnectRequest
 */
export interface ConnectRequest {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof ConnectRequest
     */
    address: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof ConnectRequest
     */
    chain: ChainEnum;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof ConnectRequest
     */
    provider: WalletProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof ConnectRequest
     */
    walletName: string;
    /**
     * 
     * @type {AuthModeEnum}
     * @memberof ConnectRequest
     */
    authMode?: AuthModeEnum;
}

export function ConnectRequestFromJSON(json: any): ConnectRequest {
    return ConnectRequestFromJSONTyped(json, false);
}

export function ConnectRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConnectRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'chain': ChainEnumFromJSON(json['chain']),
        'provider': WalletProviderEnumFromJSON(json['provider']),
        'walletName': json['walletName'],
        'authMode': !exists(json, 'authMode') ? undefined : AuthModeEnumFromJSON(json['authMode']),
    };
}

export function ConnectRequestToJSON(value?: ConnectRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'chain': ChainEnumToJSON(value.chain),
        'provider': WalletProviderEnumToJSON(value.provider),
        'walletName': value.walletName,
        'authMode': AuthModeEnumToJSON(value.authMode),
    };
}

