import { useDashboardContext } from '../../context/DashboardContext';

import { AdminView } from './AdminView';

export const AdminContainer = () => {
  const {
    activeOrganization,
    fetchUserOrganizations,
    fetchUserProjects,
    userProjects,
  } = useDashboardContext();

  return !activeOrganization ? null : (
    <AdminView
      organization={activeOrganization}
      onUpdateSuccess={fetchUserOrganizations}
      onProjectUpdate={fetchUserProjects}
      onProjectDelete={fetchUserProjects}
      projects={userProjects}
    />
  );
};
