/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmbeddedWalletProviderEnum {
    Turnkey = 'turnkey',
    Turnkeyhd = 'turnkeyhd'
}

export function EmbeddedWalletProviderEnumFromJSON(json: any): EmbeddedWalletProviderEnum {
    return EmbeddedWalletProviderEnumFromJSONTyped(json, false);
}

export function EmbeddedWalletProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddedWalletProviderEnum {
    return json as EmbeddedWalletProviderEnum;
}

export function EmbeddedWalletProviderEnumToJSON(value?: EmbeddedWalletProviderEnum | null): any {
    return value as any;
}

