import {
  FC,
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import classNames from 'classnames';

import { useOnClickOutside } from '@dynamic-labs/sdk-react-core';

import {
  DropdownControl,
  DropdownFooter,
  DropdownItem,
  DropdownList,
} from './components';
import { DropdownContext } from './dropdown-context';

interface DropdownComposition {
  Control: typeof DropdownControl;
  Footer: typeof DropdownFooter;
  Item: typeof DropdownItem;
  List: typeof DropdownList;
}

export type Props = React.ComponentPropsWithoutRef<'button'> & {
  className?: string;
};

const Dropdown: FC<PropsWithChildren<Props>> & DropdownComposition = ({
  children,
  className,
}: Props) => {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const value = useMemo(() => ({ isOpen, setIsOpen }), [isOpen]);
  const closeOnOutsideClick = useCallback(() => setIsOpen(false), []);

  useOnClickOutside(dropdownRef, closeOnOutsideClick);

  return (
    <DropdownContext.Provider value={value}>
      <div className={classNames('relative', className)} ref={dropdownRef}>
        {children}
      </div>
    </DropdownContext.Provider>
  );
};

Dropdown.Item = DropdownItem;
Dropdown.List = DropdownList;
Dropdown.Control = DropdownControl;
Dropdown.Footer = DropdownFooter;

export default Dropdown;
