import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { ChainEnum } from '@dynamic-labs/sdk-api';
import { getChainDisplayName } from '@dynamic-labs/sdk-react-core';

import { Selector } from '../../../../../icons';
import Dropdown from '../../../../components/Dropdown';

import styles from './ChainFilterDropdown.module.css';

type Props = {
  chainFilter: ChainEnum | undefined;
  setChainFilter: Dispatch<SetStateAction<ChainEnum | undefined>>;
};

export const ChainFilterDropdown = ({
  setChainFilter,
  chainFilter,
}: Props): JSX.Element => {
  const { t } = useTranslation();

  const defaultSelection = t('users_management_table.all_chains');
  const filters = Object.values(ChainEnum).filter(
    (val) => val !== ChainEnum.Eth,
  );

  const currentValue = !chainFilter
    ? defaultSelection
    : getChainDisplayName(chainFilter);

  return (
    <Dropdown>
      <Dropdown.Control>
        <div className={styles.control}>
          <span className={styles.control__selected}>{currentValue}</span>
          <Selector className={styles.control__arrow} />
        </div>
      </Dropdown.Control>
      <Dropdown.List className='!min-w-[182px] !-bottom-3'>
        <Dropdown.Item
          key={defaultSelection}
          active={!chainFilter}
          onClick={() => setChainFilter(undefined)}
          className={styles.item}
        >
          <span className='mx-1'>{defaultSelection}</span>
        </Dropdown.Item>
        {filters.map((id) => (
          <Dropdown.Item
            key={id}
            active={chainFilter === id}
            onClick={() => setChainFilter(id)}
            className={styles.item}
          >
            <span className='mx-1'>{getChainDisplayName(id)}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.List>
    </Dropdown>
  );
};
