export type ClosableInfoAlertProps = {
  id: string;
  isClosable?: boolean;
  learnMoreLink?: string;
  learnMoreText?: string;
  message: string;
  title?: string;
};

export enum ClosableInfoAlertIds {
  analyticsUsers = 'analytics_users',
  connectingVsAuthenticating = 'connecting_vs_authenticating',
  sandboxLimitations = 'sandbox_limitations',
}
