import { FC } from 'react';

import { Root as AccordionRoot } from '@radix-ui/react-accordion';

import { ToggleCard, type ToggleCardProps } from '../ToggleCard';
import { TextButton } from '../TextButton';

type SingleToggleCardProps = {
  actionLink?: {
    onClick: VoidFunction;
    text: string;
  };
  defaultExpanded?: boolean;
  onValueChange?: (value: string | undefined) => void;
  value?: string;
} & ToggleCardProps;

/**
 * <strong>Use it when you don't need to have multiple toggle cards</strong>
 *
 * Use this single toggle card to display a card with an optional icon, title, description, and content.
 */
export const SingleToggleCard: FC<SingleToggleCardProps> = ({
  children,
  inputProps,
  title,
  allowExpand = true,
  notification,
  description,
  Icon: icon,
  Tags,
  accordionKey,
  defaultExpanded,
  onValueChange,
  onCardClick,
  customActionIcon,
  titleModificators,
  descriptionModificators,
  RightSideTags,
  value,
  actionLink,
}) => (
  <AccordionRoot
    type='single'
    collapsible
    onValueChange={onValueChange}
    defaultValue={defaultExpanded ? accordionKey : undefined}
    value={value}
  >
    <ToggleCard
      inputProps={inputProps}
      title={title}
      allowExpand={allowExpand}
      notification={notification}
      description={description}
      Icon={icon}
      Tags={Tags}
      accordionKey={accordionKey}
      onCardClick={onCardClick}
      customActionIcon={customActionIcon}
      titleModificators={titleModificators}
      descriptionModificators={descriptionModificators}
      RightSideTags={RightSideTags}
      actionLink={actionLink}
    >
      {children}
      {actionLink && (
        <TextButton
          as='button'
          text={actionLink.text}
          onClick={actionLink.onClick}
          color='primary-1'
        />
      )}
    </ToggleCard>
  </AccordionRoot>
);
