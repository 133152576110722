/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum EmbeddedWalletChainEnum {
    Evm = 'EVM',
    Sol = 'SOL'
}

export function EmbeddedWalletChainEnumFromJSON(json: any): EmbeddedWalletChainEnum {
    return EmbeddedWalletChainEnumFromJSONTyped(json, false);
}

export function EmbeddedWalletChainEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddedWalletChainEnum {
    return json as EmbeddedWalletChainEnum;
}

export function EmbeddedWalletChainEnumToJSON(value?: EmbeddedWalletChainEnum | null): any {
    return value as any;
}

