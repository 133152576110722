/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomFieldType {
    Text = 'text',
    Checkbox = 'checkbox',
    Select = 'select'
}

export function CustomFieldTypeFromJSON(json: any): CustomFieldType {
    return CustomFieldTypeFromJSONTyped(json, false);
}

export function CustomFieldTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomFieldType {
    return json as CustomFieldType;
}

export function CustomFieldTypeToJSON(value?: CustomFieldType | null): any {
    return value as any;
}

