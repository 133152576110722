import { useState } from 'react';

import { t } from 'i18next';

import { DeleteModal } from '../../../../../components/DeleteModal';
import { useGatesContext } from '../../../../../context/GatesContext';
import { logger } from '../../../../../services/logger';

type DeleteGateModalProps = {
  gateId: string;
  onClose(): void;
  onDelete(): void;
};

export const DeleteGateModal = ({
  onClose,
  onDelete,
  gateId,
}: DeleteGateModalProps) => {
  const { deleteGate } = useGatesContext();
  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);

    deleteGate(gateId, {
      onError: (e) => {
        logger.error(e);
      },
      onSettled: () => {
        setLoading(false);
        onDelete();
      },
    });
  };

  return (
    <DeleteModal
      title={t<string>(
        'integrations.onboarding_and_kyc.access_control.gating.delete_gate.title',
      )}
      text={t<string>(
        'integrations.onboarding_and_kyc.access_control.gating.delete_gate.text',
      )}
      onClose={onClose}
      onDelete={handleDeleteClick}
      loading={loading}
    />
  );
};
