import { useState } from 'react';

import { t } from 'i18next';
import classNames from 'classnames';

import { CloseIcon } from '@dynamic-labs/sdk-react-core';

import { LampIcon } from '../../../icons';
import { Alert } from '../Alert';
import Button from '../Button';
import { Typography } from '../Typography';
import { useSubscriptionContext } from '../../context/SubscriptionContext';

import styles from './ClosableInfoAlert.module.css';
import { ClosableInfoAlertProps } from './ClosableInfoAlert.types';

export const ClosableInfoAlert = ({
  id,
  isClosable,
  learnMoreText = t('analytics.closable_banner.learn_more_default_label'),
  learnMoreLink,
  message,
  title,
}: ClosableInfoAlertProps) => {
  const { closedInfoAlertsMap, setClosedInfoAlertsMap } =
    useSubscriptionContext();
  const [isClosedClass, setClosedClass] = useState(
    closedInfoAlertsMap.includes(id),
  );

  const alertClassNames = classNames(styles['closable-alert__container'], {
    [styles['closable-alert__container--closed']]: isClosedClass,
  });

  const updateInfoAlertStatus = () => {
    setClosedInfoAlertsMap([...closedInfoAlertsMap, id]);
    setClosedClass(true);
  };

  return (
    <Alert severity='info' className={alertClassNames}>
      <div data-testid='closable-info-alert-wrapper'>
        <LampIcon width={16} height={16} />
        <div className={styles['closable-alert__copy']}>
          {title && (
            <Typography as='h4' variant='paragraph-2' weight='medium'>
              {title}
            </Typography>
          )}
          <div className={styles['closable-alert__learn-more']}>
            <Typography variant='paragraph-1'>{message}</Typography>
            {learnMoreText && learnMoreLink && (
              <a href={learnMoreLink} target='_blank' rel='noreferrer'>
                {learnMoreText}
              </a>
            )}
          </div>
        </div>
      </div>
      {isClosable && (
        <Button
          className={styles['closable-alert__btn-close']}
          variant='secondary'
          onClick={updateInfoAlertStatus}
        >
          <CloseIcon data-testid='close-alert-btn--icon' />
        </Button>
      )}
    </Alert>
  );
};
