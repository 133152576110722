import { useTranslation } from 'react-i18next';

import { AccessOutcomeEnum, Gate, GateRuleType } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { useAccessListsContext } from '../../../../context/AccessListsContext';
import { Icon } from '../../../../components/Icon';
import { PlusIcon } from '../../../../../icons';
import Button from '../../../../components/Button';
import { useGatesContext } from '../../../../context/GatesContext';
import { EnvironmentTabsLayout } from '../../../../layouts/EnvironmentTabsLayout';
import Portal from '../../../../components/Portal';
import { useSubscriptionContext } from '../../../../context/SubscriptionContext';
import { UpsellBanner } from '../../../../components/UpsellBanner';
import { useSubscriptionLock } from '../../../../hooks/useSubscriptionLock';
import { SideModalHeader } from '../../../../components/SideModalHeader';
import { AccessListView } from '../AccessList/AccessListView';
import { GateView } from '../Gate/GateView';

import styles from './AccessControlModal.module.css';
import { AccessControlView } from './AccessControlView';

type Props = {
  content: string;
  icon: React.FunctionComponent;
  scope?: string;
  setShowModal: (value: boolean) => void;
  title: string;
};

export const AccessControlModal = ({
  icon,
  content,
  setShowModal,
  title,
  scope,
}: Props) => {
  const { t } = useTranslation();
  const { activeEnvironment } = useEnvironmentsContext();
  const { subscription } = useSubscriptionContext();
  const { shouldLockOnLive, shouldShowTrialBanner } =
    useSubscriptionLock(scope);

  const { trialDaysLeft } = subscription || {};

  const { gates, setNewGate } = useGatesContext();
  const { accessLists, setAccessLists, setActiveList } =
    useAccessListsContext();

  const createAllowList = () => {
    if (!activeEnvironment || shouldLockOnLive) {
      return;
    }
    const newList = {
      id: '',
      name: '',
      outcome: AccessOutcomeEnum.SiteAccess,
      projectEnvironmentId: activeEnvironment?.id,
    };

    setAccessLists([...accessLists, newList]);
    setActiveList(newList.id);
  };

  const createGate = () => {
    if (!activeEnvironment || shouldLockOnLive) {
      return;
    }

    const newGate: Gate = {
      id: '',
      name: '',
      outcome: AccessOutcomeEnum.SiteAccess,
      rules: [
        {
          address: {
            contractAddress: '',
            networkId: undefined,
          } as any,
          filter: { amount: 1 },
          type: '' as GateRuleType,
        },
      ],
      scope: '',
    };

    setNewGate(newGate);
  };

  const newAccessList = accessLists.find((l) => l.id === '');
  const newGate = gates.find((g) => g.id === '');

  const shouldDisableCreateButton =
    !!newAccessList || !!newGate || shouldLockOnLive;

  // not creating new access list or gate
  const showAccessControlView = !newAccessList && !newGate;

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <EnvironmentTabsLayout className={styles.layout}>
        <SideModalHeader
          Icon={icon}
          title={title}
          content={content}
          showClose
          onClose={() => setShowModal(false)}
        />

        {shouldShowTrialBanner && (
          <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
        )}

        {/* not creating new access list or gate */}
        {!shouldLockOnLive && showAccessControlView && <AccessControlView />}

        {/* creating new access list */}
        {!shouldLockOnLive && newAccessList && (
          <AccessListView allowList={newAccessList} />
        )}

        {/* creating new gate */}
        {!shouldLockOnLive && newGate && <GateView gate={newGate} />}

        {!newAccessList && !newGate && (
          <div className={styles.footer__buttons}>
            <Button
              testId='new_access_list_button'
              variant='secondary'
              type='submit'
              disabled={shouldDisableCreateButton}
              onClick={createAllowList}
              leading={<Icon size='medium' icon={<PlusIcon />} />}
            >
              {t(
                'integrations.onboarding_and_kyc.access_control.access_list.create_new_list',
              )}
            </Button>
            <Button
              testId='new_gate_button'
              variant='secondary'
              type='submit'
              disabled={shouldDisableCreateButton}
              onClick={createGate}
              leading={<Icon size='medium' icon={<PlusIcon />} />}
            >
              {t(
                'integrations.onboarding_and_kyc.access_control.gating.create_new_gate',
              )}
            </Button>
          </div>
        )}
      </EnvironmentTabsLayout>
    </Portal>
  );
};
