import { t } from 'i18next';

import { WidgetView } from '../WidgetView';
import { LoadingBarChart } from '../LoadingBarChart';
import { ChartSummaryItem } from '../WidgetView/ChartSummaryItem/ChartSummaryItem';

import styles from './LockedDailyUsersBarChartWidget.module.css';
import { LockedDailyUsersBarChartWidgetComponent } from './LockedDailyUsersBarChartWidget.types';

export const LockedDailyUsersBarChartWidget: LockedDailyUsersBarChartWidgetComponent =
  ({ endDate, startDate }) => (
    <WidgetView title={t<string>('analytics.widgets.daily_users.title')}>
      <div className={styles.summary__wrapper}>
        <ChartSummaryItem
          disabled
          userType='visitors'
          label={t('analytics.widgets.daily_users.visitors_label')}
          value='N/A'
        />
        <ChartSummaryItem
          disabled
          userType='unique'
          label={t('analytics.widgets.daily_users.authenticated_user_label')}
          value='N/A'
        />
      </div>

      <div className={styles['widget-children']}>
        <div className={styles['chart-container']}>
          <LoadingBarChart startDate={startDate} endDate={endDate} />
        </div>
      </div>
    </WidgetView>
  );
