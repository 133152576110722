import { Typography } from '../../../../components/Typography';
import { Paper } from '../../../../components/Paper';
import {
  ExtendedContent,
  SimpleContent,
  VisitedContent,
} from '../../../../data/overviewCards';

import styles from './card.module.css';
import { Main } from './Main';
import { MainExtended } from './MainExtended';
import { MainVisits } from './MainVisits';

type MainSwitcherProps = {
  buttonText?: string;
  content: string | SimpleContent | ExtendedContent | VisitedContent;
  values?: {
    live: number;
    sandbox: number;
  };
};

type Props = {
  buttonText?: string;
  className?: string;
  content: string | SimpleContent | ExtendedContent | VisitedContent;
  imageUrl?: string;
  title?: string;
  values?: {
    live: number;
    sandbox: number;
  };
};

export const Card = ({
  className,
  title,
  content,
  imageUrl,
  buttonText,
  values,
}: Props): JSX.Element => (
  <Paper className={`${styles.paper} ${className}`}>
    <div className={styles.wrapper}>
      <div
        className={`${styles.container} ${
          typeof content === 'string' ? styles['container-with-image'] : ''
        }`}
      >
        {title && (
          <Typography
            variant='subtitle'
            className={styles.heading}
            weight='medium'
          >
            {title}
          </Typography>
        )}
        <MainSwitcher
          content={content}
          buttonText={buttonText}
          values={values}
        />
      </div>
      {imageUrl && (
        <div
          className={styles.image}
          style={{
            backgroundImage: `url(${imageUrl})`,
          }}
        />
      )}
    </div>
  </Paper>
);

const MainSwitcher = ({
  content,
  buttonText = '',
  values = { live: 0, sandbox: 0 },
}: MainSwitcherProps): JSX.Element => {
  if (typeof content === 'string' || content.type === 'simple') {
    return <Main content={content} />;
  }

  if (content.type !== 'extended') {
    return (
      <MainVisits content={content} buttonText={buttonText} values={values} />
    );
  }
  return <MainExtended content={content} />;
};
