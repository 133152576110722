/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostTokenFields
 */
export interface PostTokenFields {
    /**
     * 
     * @type {string}
     * @memberof PostTokenFields
     */
    note: string;
}

export function PostTokenFieldsFromJSON(json: any): PostTokenFields {
    return PostTokenFieldsFromJSONTyped(json, false);
}

export function PostTokenFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostTokenFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'note': json['note'],
    };
}

export function PostTokenFieldsToJSON(value?: PostTokenFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'note': value.note,
    };
}

