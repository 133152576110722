import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { webhooksColumns } from '../webhooks-columns';

export const useWebhooksColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(() => webhooksColumns(t), [t]);

  return { columns };
};
