/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PasswordSourceTypeEnum,
    PasswordSourceTypeEnumFromJSON,
    PasswordSourceTypeEnumFromJSONTyped,
    PasswordSourceTypeEnumToJSON,
} from './PasswordSourceTypeEnum';

/**
 * 
 * @export
 * @interface EmbeddedWalletSecret
 */
export interface EmbeddedWalletSecret {
    /**
     * 
     * @type {string}
     * @memberof EmbeddedWalletSecret
     */
    walletId: string;
    /**
     * 
     * @type {string}
     * @memberof EmbeddedWalletSecret
     */
    secret: string;
    /**
     * 
     * @type {PasswordSourceTypeEnum}
     * @memberof EmbeddedWalletSecret
     */
    source?: PasswordSourceTypeEnum;
}

export function EmbeddedWalletSecretFromJSON(json: any): EmbeddedWalletSecret {
    return EmbeddedWalletSecretFromJSONTyped(json, false);
}

export function EmbeddedWalletSecretFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmbeddedWalletSecret {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'walletId': json['walletId'],
        'secret': json['secret'],
        'source': !exists(json, 'source') ? undefined : PasswordSourceTypeEnumFromJSON(json['source']),
    };
}

export function EmbeddedWalletSecretToJSON(value?: EmbeddedWalletSecret | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'walletId': value.walletId,
        'secret': value.secret,
        'source': PasswordSourceTypeEnumToJSON(value.source),
    };
}

