import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { quickHelpData } from '../../data/api';
import { useSettingsContext } from '../../context/SettingsContext';
import { useEnvironmentsContext } from '../../context/EnvironmentsContext';
import { Typography } from '../../components/Typography';
import Button from '../../components/Button';
import ReactTable from '../../components/ReactTable';
import { useTokensContext } from '../../context/TokensContext';
import { QuickHelp } from '../../components/QuickHelp';
import { DocumentationIcon, QuickHelpIcon, PlusIcon } from '../../../icons';
import { Toaster } from '../../components/Toaster';
import { Icon } from '../../components/Icon';
import { UpsellBanner } from '../../components/UpsellBanner';
import { useSubscriptionContext } from '../../context/SubscriptionContext';
import { useSubscriptionLock } from '../../hooks/useSubscriptionLock';

import styles from './Api/api.module.css';
import {
  EnvironmentKeysCard,
  CreateTokenModal,
  useApiKeysColumns,
  JwksCard,
} from './Api';

const ApiRoute = () => {
  const [showModal, setShowModal] = useState(false);
  const [showToaster, setShowToaster] = useState(false);

  const { t } = useTranslation();
  const { columns } = useApiKeysColumns();
  const { publicKeys } = useSettingsContext();
  const { environments, activeEnvironmentType, docsUrl } =
    useEnvironmentsContext();
  const { tokens } = useTokensContext();
  const { subscription } = useSubscriptionContext();
  const { shouldShowTrialBanner } = useSubscriptionLock();

  const disabled = tokens && tokens.length >= 10;

  useEffect(() => {
    if (!showToaster) {
      return;
    }
    setTimeout(() => {
      setShowToaster(false);
    }, 3000);
  }, [showToaster]);

  const helpData = quickHelpData(docsUrl);

  return (
    <>
      <div className={styles.container}>
        {showModal && (
          <CreateTokenModal setShowModal={() => setShowModal(false)} />
        )}
        <div className={styles.row__container}>
          {shouldShowTrialBanner && (
            <UpsellBanner
              daysLeft={subscription?.trialDaysLeft}
              className='mb-4'
            />
          )}
          <div className={styles.row}>
            <div className='mr-6'>
              <Typography variant='paragraph-2' className={styles.heading}>
                {t('apis.keys')}
              </Typography>
              {activeEnvironmentType === 'sandbox' && (
                <>
                  <EnvironmentKeysCard
                    publicKey={publicKeys?.sandbox || ''}
                    environmentId={environments?.sandbox?.id || ''}
                  />
                  <JwksCard environmentId={environments?.sandbox?.id || ''} />
                </>
              )}
              {activeEnvironmentType === 'live' && (
                <>
                  <EnvironmentKeysCard
                    publicKey={publicKeys?.live || ''}
                    environmentId={environments?.live?.id || ''}
                  />
                  <JwksCard environmentId={environments?.live?.id || ''} />
                </>
              )}
            </div>
            <QuickHelp
              className='mt-[26px]'
              name={helpData.name}
              content={helpData.content}
              documentation={helpData.documentation}
              url={helpData.documentation_url}
              HeadingIcon={QuickHelpIcon}
              ButtonIcon={DocumentationIcon}
            />
          </div>
          <div className={styles.table__container}>
            <Typography variant='paragraph-2' className={styles.heading}>
              {t('api_keys_table.title')}
            </Typography>
            <Typography variant='paragraph-1' className={styles.subheading}>
              {t('api_keys_table.subtitle')}
            </Typography>
            <div data-testid='token_management'>
              <ReactTable
                columns={columns}
                data={tokens}
                loading={!tokens}
                noDataMessage={t('apis.noDataMessage')}
              />
            </div>
          </div>
          <Button
            onClick={() =>
              disabled ? setShowToaster(true) : setShowModal(true)
            }
            variant='secondary'
            className={styles['button--add']}
            leading={<Icon size='medium' icon={<PlusIcon />} />}
          >
            {t('apis.create_token_modal_button')}
          </Button>
        </div>
      </div>

      <AnimatePresence>
        {showToaster && (
          <Toaster
            variant='error'
            message={t('apis.max_tokens_amount')}
            onExit={() => setShowToaster(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default ApiRoute;
