import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import { FullScope } from '../../../../data/subscription';
import Header from '../../SingleChain/Header';
import { AccountAbstractionCircularIcon } from '../../../../../icons';
import { Typography } from '../../../../components/Typography';
import { useEnvironmentProviders } from '../../../../hooks/useEnvironmentProviders';
import { AccountAbstractionModal } from '../AccountAbstractionModal';
import {
  isAnyProviderEnabled,
  ModalHashLocations,
  useModalHashLocation,
} from '../../utils';

import styles from './AccountAbstractionCard.module.css';

const providers = [ProviderEnum.Alchemy, ProviderEnum.Zerodev];

export const AccountAbstractionCard: FC = () => {
  const { t } = useTranslation();
  const { environments } = useEnvironmentsContext();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.AccountAbstraction,
  });

  const { data: providersLiveData } = useEnvironmentProviders(
    environments?.live.id,
  );

  const { data: providersSandboxData } = useEnvironmentProviders(
    environments?.sandbox.id,
  );

  const liveProviders = (providersLiveData?.providers ?? []).filter(
    (provider) => providers.includes(provider.provider),
  );

  const sandboxProviders = (providersSandboxData?.providers ?? []).filter(
    (provider) => providers.includes(provider.provider),
  );

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <AccountAbstractionModal
            onClickClose={() => handleShowModal(false)}
          />
        )}
      </AnimatePresence>

      <button
        type='button'
        className='max-w-[350px] p-4 border border-cloud-2 rounded-xl flex flex-col justify-start items-start'
        onClick={() => handleShowModal(true)}
      >
        <Header
          Icon={AccountAbstractionCircularIcon}
          name={t('integrations.wallets.account_abstraction.name')}
          isLiveActive={isAnyProviderEnabled(liveProviders)}
          isSandboxActive={isAnyProviderEnabled(sandboxProviders)}
          scope={FullScope.AccountAbstraction}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {t('integrations.wallets.account_abstraction.content')}
        </Typography>
      </button>
    </>
  );
};
