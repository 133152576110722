/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';

/**
 * 
 * @export
 * @interface PrefetchRequest
 */
export interface PrefetchRequest {
    /**
     * 
     * @type {ChainEnum}
     * @memberof PrefetchRequest
     */
    chain: ChainEnum;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof PrefetchRequest
     */
    publicWalletAddress: string;
}

export function PrefetchRequestFromJSON(json: any): PrefetchRequest {
    return PrefetchRequestFromJSONTyped(json, false);
}

export function PrefetchRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrefetchRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chain': ChainEnumFromJSON(json['chain']),
        'publicWalletAddress': json['publicWalletAddress'],
    };
}

export function PrefetchRequestToJSON(value?: PrefetchRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chain': ChainEnumToJSON(value.chain),
        'publicWalletAddress': value.publicWalletAddress,
    };
}

