/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JwksKey,
    JwksKeyFromJSON,
    JwksKeyFromJSONTyped,
    JwksKeyToJSON,
} from './JwksKey';

/**
 * 
 * @export
 * @interface JwksResponse
 */
export interface JwksResponse {
    /**
     * 
     * @type {Array<JwksKey>}
     * @memberof JwksResponse
     */
    keys: Array<JwksKey>;
}

export function JwksResponseFromJSON(json: any): JwksResponse {
    return JwksResponseFromJSONTyped(json, false);
}

export function JwksResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwksResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'keys': ((json['keys'] as Array<any>).map(JwksKeyFromJSON)),
    };
}

export function JwksResponseToJSON(value?: JwksResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'keys': ((value.keys as Array<any>).map(JwksKeyToJSON)),
    };
}

