import { useTranslation } from 'react-i18next';

import { ErrorInfo } from '../../../../../../components/ErrorInfo';

import { buildErrorsInfo, InputsErrors } from './helpers';
import styles from './form-errors.module.css';

interface Props {
  errors: InputsErrors;
  showErrors: boolean;
}

export const FormErrors = ({
  errors,
  showErrors,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { requiredError, duplicatedName, duplicatedScope } =
    buildErrorsInfo(errors);
  if (!showErrors) return null;

  return (
    <ErrorInfo className='mb-5'>
      {requiredError && (
        <span>
          <h3 className={styles.heading}>
            {t('create_project.required_error_title')}
          </h3>
          <p className={styles['error-message']}>{requiredError}</p>
        </span>
      )}

      {duplicatedName && (
        <span>
          <h3 className={styles.heading}>
            {t(
              'integrations.onboarding_and_kyc.access_control.access_list.duplicate_list_name_error_title',
            )}
          </h3>
          <p className={styles['error-message']}>{duplicatedName}</p>
        </span>
      )}

      {duplicatedScope && (
        <span>
          <h3 className={styles.heading}>
            {t(
              'integrations.onboarding_and_kyc.access_control.errors.duplicate_scope_name_error_title',
            )}
          </h3>
          <p className={styles['error-message']}>{duplicatedScope}</p>
        </span>
      )}
    </ErrorInfo>
  );
};
