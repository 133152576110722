import { AnimatePresence } from 'framer-motion';

import { Typography } from '../../../components/Typography';
import { Integration } from '../../../types';
import Header from '../SingleChain/Header';
import { OnrampProvidersContextProvider } from '../../../context/OnrampProvidersContext';
import { useOnrampProviders } from '../../../hooks/useOnrampProviders';
import { useEnvironmentsContext } from '../../../context/EnvironmentsContext';
import {
  isAnyProviderEnabled,
  ModalHashLocations,
  useModalHashLocation,
} from '../utils';

import styles from './OnrampCard.module.css';
import { OnrampModal } from './OnrampModal/OnrampModal';

type OnrampCardProps = Omit<Integration, 'category' | 'url' | 'name'>;

export const OnrampCard = ({ Icon, title, content }: OnrampCardProps) => {
  const { environments } = useEnvironmentsContext();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.Onramp,
  });

  const { providers: liveProviders } = useOnrampProviders(
    environments?.live.id || '',
  );
  const { providers: sandboxProviders } = useOnrampProviders(
    environments?.sandbox.id || '',
  );

  return (
    <OnrampProvidersContextProvider>
      <AnimatePresence>
        {showModal && (
          <OnrampModal
            title={title}
            Icon={Icon}
            content={content.full}
            setShowModal={handleShowModal}
          />
        )}
      </AnimatePresence>
      <button
        type='button'
        className='max-w-[350px] p-4 border border-cloud-2 rounded-xl flex flex-col justify-start items-start'
        onClick={() => handleShowModal(!showModal)}
      >
        <Header
          Icon={Icon}
          name={title}
          isLiveActive={isAnyProviderEnabled(liveProviders)}
          isSandboxActive={isAnyProviderEnabled(sandboxProviders)}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {content.short}
        </Typography>
      </button>
    </OnrampProvidersContextProvider>
  );
};
