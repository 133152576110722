/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OriginResponse
 */
export interface OriginResponse {
    /**
     * 
     * @type {string}
     * @memberof OriginResponse
     */
    id?: string;
    /**
     * An [RFC 6454](https://www.rfc-editor.org/rfc/rfc6454) valid url. Must not include any paths. A single wildcard (*) can be used as the first subdomain.
     * @type {string}
     * @memberof OriginResponse
     */
    origin?: string;
    /**
     * 
     * @type {Date}
     * @memberof OriginResponse
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof OriginResponse
     */
    projectEnvironmentId?: string;
}

export function OriginResponseFromJSON(json: any): OriginResponse {
    return OriginResponseFromJSONTyped(json, false);
}

export function OriginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OriginResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'projectEnvironmentId': !exists(json, 'projectEnvironmentId') ? undefined : json['projectEnvironmentId'],
    };
}

export function OriginResponseToJSON(value?: OriginResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'origin': value.origin,
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'projectEnvironmentId': value.projectEnvironmentId,
    };
}

