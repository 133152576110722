import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Typography } from '@dynamic-labs/northstar';

import styles from './AccountMfaSidePage.module.scss';
import { RequiredAtOnboardingToggle } from './RequiredAtOnboardingToggle';

type AccountMfaSidePageProps = {
  mfaEnabled: boolean;
};

export const AccountMfaSidePage: FC<AccountMfaSidePageProps> = ({
  mfaEnabled,
}) => {
  const { t } = useTranslation();

  const { enableMfa } = useFlags();

  return (
    <div>
      <Typography
        className={styles.heading}
        as='h1'
        variant='paragraph-2'
        weight='bold'
        color='black-2'
      >
        {t('v2.page.security.account_mfa_row.side_drawer.heading')}
      </Typography>
      <div className={styles.container}>
        {enableMfa && <RequiredAtOnboardingToggle disabled={!mfaEnabled} />}
      </div>
    </div>
  );
};
