import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

import { Banner, OpacityAnimation } from '@dynamic-labs/northstar';

import {
  ApiErrorsModal,
  MissingSettingsModal,
  PageHeader,
  SaveBanner,
} from '../../../components';
import { useSubscriptionLock } from '../../../../app/hooks/useSubscriptionLock';
import { FullScope } from '../../../../app/data/subscription';
import { ROUTES } from '../../../../app/components/Navigation/data';
import { useOnUnMount } from '../../../hooks';

import { EmailAndPhoneNumberSection } from './Sections/EmailAndPhoneNumberSection';
import { BrandedWalletsSection } from './Sections/BrandedWalletsSection';
import { SocialSection } from './Sections/SocialSection';
import { DesignSection } from './Sections/DesignSection';
import { UserInformationSection } from './Sections/UserInformationSection';
import { useSaveLoginMethods } from './useSaveLoginMethods';
import styles from './LogInMethodsPage.module.scss';

export const LogInMethodsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    shouldShowSaveBanner,
    handleSave,
    handleReset,
    shouldShowMissingSettingsWarning,
    setShouldShowMissingSettingsWarning,
    missingSettings,
    isLoading,
    apiErrors,
    clearErrors,
  } = useSaveLoginMethods();

  const { shouldLockOnLive } = useSubscriptionLock(FullScope.InfoCapture);

  useOnUnMount(() => {
    if (shouldShowSaveBanner) {
      handleReset();
    }
  });

  return (
    <>
      <section className={styles.container}>
        <PageHeader
          title={t('v2.page.log_in_methods.title')}
          description={t('v2.page.log_in_methods.description')}
        />

        {shouldLockOnLive && (
          <Banner
            text={t('v2.page.log_in_methods.paid.title')}
            variant='warning'
            actionText={t('v2.page.log_in_methods.paid.action')}
            action={() => navigate(ROUTES.adminSubscriptionTab)}
            rounded
          />
        )}

        <EmailAndPhoneNumberSection disabled={shouldLockOnLive} />

        <BrandedWalletsSection disabled={shouldLockOnLive} />

        <SocialSection disabled={shouldLockOnLive} />

        <UserInformationSection disabled={shouldLockOnLive} />

        <DesignSection disabled={shouldLockOnLive} />
      </section>

      <AnimatePresence mode='wait'>
        {shouldShowSaveBanner && (
          <SaveBanner
            handleOnCancel={handleReset}
            handleOnSave={handleSave}
            isLoading={isLoading}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {shouldShowMissingSettingsWarning && (
          <OpacityAnimation>
            <MissingSettingsModal
              handleClose={() => setShouldShowMissingSettingsWarning(false)}
              missingSettings={missingSettings}
              title={t('v2.page.log_in_methods.missing_settings.title')}
              description={t(
                'v2.page.log_in_methods.missing_settings.description',
              )}
            />
          </OpacityAnimation>
        )}
      </AnimatePresence>

      <AnimatePresence mode='wait'>
        {apiErrors.length > 0 && (
          <OpacityAnimation>
            <ApiErrorsModal
              apiErrors={apiErrors}
              handleClose={clearErrors}
              title={t('v2.page.log_in_methods.api_errors.title')}
            />
          </OpacityAnimation>
        )}
      </AnimatePresence>
    </>
  );
};
