import { FC } from 'react';

import classNames from 'classnames';

import { Typography } from '../../../Typography';

import styles from './OrganizationLogo.module.scss';

type OrganizationLogoProps = {
  logoUrl?: string;
  name: string;
};

export const OrganizationLogo: FC<OrganizationLogoProps> = ({
  logoUrl,
  name,
}) => {
  if (logoUrl) {
    return (
      <div className={styles.logo}>
        <img src={logoUrl} alt={name} className={styles.logo__img} />
      </div>
    );
  }

  return (
    <div className={classNames(styles.logo, styles['logo--char'])}>
      <Typography variant='paragraph-2' weight='bold' color='inherit'>
        {name[0]}
      </Typography>
    </div>
  );
};
