/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscriptionFreeScopeEnum {
    ConnectOnly = 'connect_only',
    Design = 'design',
    ApiKeys = 'api_keys',
    VisitManagement = 'visit_management',
    SettingsGeneral = 'settings_general',
    SettingsSecurity = 'settings_security',
    SettingsPrivacy = 'settings_privacy',
    AdminOrgs = 'admin_orgs',
    AdminBilling = 'admin_billing',
    AdminMembers = 'admin_members',
    UserManagement = 'user_management'
}

export function SubscriptionFreeScopeEnumFromJSON(json: any): SubscriptionFreeScopeEnum {
    return SubscriptionFreeScopeEnumFromJSONTyped(json, false);
}

export function SubscriptionFreeScopeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionFreeScopeEnum {
    return json as SubscriptionFreeScopeEnum;
}

export function SubscriptionFreeScopeEnumToJSON(value?: SubscriptionFreeScopeEnum | null): any {
    return value as any;
}

