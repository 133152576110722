import { FC } from 'react';

import { Section } from './Section';
import styles from './SidebarNavigation.module.scss';
import { SidebarNavigationProps } from './SidebarNavigation.types';

export const SidebarNavigation: FC<SidebarNavigationProps> = ({
  items,
  disabled,
  onDisabledClick,
}) => (
  <div className={styles.sidebar}>
    {items.map((navItem) => (
      <Section
        items={navItem.items}
        title={navItem.title}
        key={navItem.title}
        disabled={disabled}
        onDisabledClick={onDisabledClick}
      />
    ))}
  </div>
);
