import { useTranslation } from 'react-i18next';

import { ErrorInfo } from '../../../../../components/ErrorInfo';
import { Typography } from '../../../../../components/Typography';

import { buildErrorsInfo, InputsErrors } from './helpers';
import styles from './form-errors.module.css';

interface Props {
  errors: InputsErrors;
  showErrors: boolean;
}

export const FormErrors = ({
  errors,
  showErrors,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const { requiredError, duplicatedName, invalidProjectNameError } =
    buildErrorsInfo(errors);
  if (!showErrors) return null;

  return (
    <ErrorInfo className='mb-5'>
      {requiredError && (
        <span>
          <Typography className={styles.heading}>
            {t('create_project.required_error_title')}
          </Typography>
          <p className={styles['error-message']}>{requiredError}</p>
        </span>
      )}

      {invalidProjectNameError && (
        <span>
          <Typography className={styles.heading}>
            {t('create_project.invalid_project_name_error')}
          </Typography>
          <p className={styles['error-message']}>{requiredError}</p>
        </span>
      )}

      {duplicatedName && (
        <span>
          <Typography className={styles.heading}>
            {t('create_project.duplicate_name_error_title')}
          </Typography>
          <p className={styles['error-message']}>{duplicatedName}</p>
        </span>
      )}
    </ErrorInfo>
  );
};
