import { InputLabelComponent } from './InputLabel.types';
import styles from './InputLabel.module.css';

export const InputLabel: InputLabelComponent = ({
  children,
  label,
  htmlFor,
  className = '',
  labelClassName = '',
}) => (
  <div className={[styles.container, className].join(' ')}>
    <label
      htmlFor={htmlFor}
      className={[styles.label, labelClassName].join(' ')}
    >
      {label}
    </label>

    {children}
  </div>
);
