import { useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { Gate } from '@dynamic-labs/sdk-api';

import { ControlFieldHeader } from '../../../../../components/ControlFieldHeader';
import { Toaster } from '../../../../../components/Toaster';
import { useGatesContext } from '../../../../../context/GatesContext';
import Button from '../../../../../components/Button';
import { ExpandFieldAnimation } from '../../../../../animations/ExpandFieldAnimation';
import { DeleteGateModal } from '../DeleteGateModal';
import { AccessControlTypeChip } from '../../AccessControlModal/AccessControlTypeChip';
import { AccessControlScopeChip } from '../../AccessControlModal/AccessControlScopeChip';

import { GateForm, GateFormValues } from './GateForm';
import styles from './GateView.module.css';

export type GateViewProps = {
  className?: string;
  gate: Gate;
};

export const GateView = ({ gate, className }: GateViewProps) => {
  const { toggleGate, setNewGate } = useGatesContext();

  const { t } = useTranslation();
  const [modal, setModal] = useState<'delete' | undefined>();
  const [expand, setExpand] = useState<boolean>(!gate.id);
  const [loading, setLoading] = useState(false);

  const formRef = useRef<FormikProps<GateFormValues>>(null);
  const [shouldValidateForm, setShouldValidateForm] = useState(false);
  const [showToaster, setShowToaster] = useState(false);

  const isActive = !!gate?.enabledAt;
  const isPersisted = !!gate?.id;

  const handleToggle = async () => {
    if (!isPersisted) {
      return;
    }

    setLoading(true);

    toggleGate(gate, {
      onSettled: () => setLoading(false),
    });
  };

  const handleSubmit = () => {
    if (formRef.current) {
      setShouldValidateForm(true);
      formRef.current.submitForm();
    }
  };

  const onCreateSuccess = () => {
    setExpand(false);
    setNewGate(undefined);
  };

  const onUpdateSuccess = () => {
    setExpand(false);
    setShowToaster(true);
    setTimeout(() => setShowToaster(false), 3000);
  };

  const TypeAndOutcomeChips = (
    <div className={styles.header__chips}>
      <AccessControlTypeChip type='gating' />
      <AccessControlScopeChip scope={gate?.scope} />
    </div>
  );

  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles['no-border']]: isPersisted,
        },
        className,
      )}
    >
      <ControlFieldHeader
        disabled={loading}
        isExpanded={expand}
        setIsExpanded={isPersisted ? setExpand : undefined}
        handleToggleChange={handleToggle}
        isInitialEnabled
        header={<div className={styles.header__title}>{gate.name}</div>}
        className={styles.header__container}
        toggleId={`toggle-${gate?.id}`}
        toggleValue={isActive}
        Trailing={TypeAndOutcomeChips}
        noPadding
      />

      <AnimatePresence>
        {expand && (
          <ExpandFieldAnimation>
            <div className={styles.inner_container}>
              <GateForm
                formikFormRef={formRef}
                gate={gate}
                loading={loading}
                setLoading={setLoading}
                shouldValidateForm={shouldValidateForm}
                onCreateSuccess={onCreateSuccess}
                onUpdateSuccess={onUpdateSuccess}
              />

              <div className={styles.footer__container}>
                <Button
                  testId='delete-gate-button'
                  variant='secondary'
                  className={styles['button--delete']}
                  onClick={() => {
                    if (isPersisted) {
                      setModal('delete');
                    } else {
                      setNewGate(undefined);
                    }
                  }}
                >
                  {isPersisted
                    ? t(
                        'integrations.onboarding_and_kyc.access_control.gating.delete_gate_button',
                      )
                    : t(
                        'integrations.onboarding_and_kyc.access_control.create_cancel_button',
                      )}
                </Button>
                <Button
                  variant='primary'
                  type='submit'
                  onClick={() => {
                    handleSubmit();
                  }}
                  loading={loading}
                >
                  {isPersisted
                    ? t(
                        'integrations.onboarding_and_kyc.access_control.gating.update_gate',
                      )
                    : t(
                        'integrations.onboarding_and_kyc.access_control.gating.save_gate',
                      )}
                </Button>
              </div>
            </div>
          </ExpandFieldAnimation>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showToaster && (
          <Toaster
            message={t(
              'integrations.onboarding_and_kyc.access_control.gating.toaster',
            ).replace('{{gateName}}', gate?.name || 'Gate')}
            variant='success'
            onExit={() => setShowToaster(false)}
            position='absolute'
          />
        )}
      </AnimatePresence>

      {modal === 'delete' && gate && (
        <DeleteGateModal
          onClose={() => {
            setModal(undefined);
          }}
          onDelete={() => {
            setModal(undefined);
          }}
          gateId={gate.id}
        />
      )}
    </div>
  );
};
