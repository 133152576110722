import { Dispatch, SetStateAction, useState } from 'react';

import { Field, Form, FormikProvider, useFormik } from 'formik';
import { object, string, array } from 'yup';
import { useTranslation } from 'react-i18next';

import { Webhook } from '@dynamic-labs/sdk-api';

import Checkbox from '../../../../components/Checkbox';
import { useWebhooksContext } from '../../../../context/WebhooksContext';
import { Typography } from '../../../../components/Typography';
import { logger } from '../../../../services/logger';
import { useEnvironmentsContext } from '../../../../context/EnvironmentsContext';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { useEventsContext } from '../../../../context/EventsContext';
import { Badge } from '../../../../components/Badge';
import { Toggle } from '../../../../components/Toggle';

import styles from './WebhookForm.module.css';
import { EventTypeList } from './EventTypeList';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  webhook?: Webhook;
};

interface FormValues {
  events: string[];
  isEnabled: boolean;
  url: string;
}

const VALID_URL_REGEX =
  /^(https:\/\/)[a-z0-9]+([-.][a-z0-9]+)*\.[a-z]{2,10}(\/.*)?$|^arn:aws:sns:[a-z0-9-]*:[0-9]{12}:[a-zA-Z0-9-_]+$/;

const ErrorMessage = ({ children }: { children: string }) => (
  <div className='text-red-1 text-sm'>{children}</div>
);

export const WebhookForm = ({ setShowModal, webhook }: Props) => {
  const { t } = useTranslation();
  const { activeEnvironment } = useEnvironmentsContext();
  const { createWebhook, updateWebhook } = useWebhooksContext();
  const { eventTypes } = useEventsContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: FormValues) => {
    if (!activeEnvironment) return;

    try {
      setIsLoading(true);
      if (webhook) {
        await updateWebhook({
          webhook: {
            events: values.events,
            isEnabled: values.isEnabled,
            url: values.url,
          },
          webhookId: webhook.webhookId,
        });
      } else {
        await createWebhook({
          events: values.events,
          isEnabled: values.isEnabled,
          url: values.url,
        });
      }
      setIsLoading(false);
      setShowModal(false);
    } catch (error) {
      setIsLoading(false);
      logger.error(error);
    }
  };

  const CreateWebhookSchema = object().shape({
    events: array().min(1, 'Required'),
    url: string().matches(VALID_URL_REGEX, 'Invalid url').required('Required'),
  });

  const formik = useFormik<any>({
    initialValues: {
      events: webhook?.events || [],
      isEnabled:
        typeof webhook?.isEnabled !== 'undefined' ? webhook?.isEnabled : true,
      url: webhook?.url || '',
    },
    onSubmit: handleSubmit,
    validationSchema: CreateWebhookSchema,
  });

  const handleSelectEveryEvent = (event: any) => {
    const allEventNames = Object.values(eventTypes || []).map((e) => e.name);
    formik.setFieldValue(
      'allEvents',
      event.target.checked ? 'everything' : undefined,
    );
    formik.setFieldValue('events', event.target.checked ? allEventNames : []);
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={formik.handleSubmit}>
        <div className='flex space-x-4 items-center flex-wrap p-3 border border-cloud-2 w-full rounded-lg mb-4'>
          <div className='w-9 h-5'>
            <Field
              as={Toggle}
              type='checkbox'
              id='isEnabled'
              name='isEnabled'
              checked={formik.values.isEnabled}
              withIcon
              variant={formik.values.isEnabled ? 'success' : 'primary'}
              handleChange={(e: any) =>
                formik.setFieldValue('isEnabled', e.target.checked)
              }
            />
          </div>
          <div className='text-sm'>Enabled</div>
        </div>
        <div className='flex items-center space-x-2 mb-3'>
          <div className={styles['field-item--icon']}>1</div>
          <Typography variant='paragraph-2' weight='bold'>
            {t('webhooks.create_webhook_modal.payload_url_input_label')}
          </Typography>
        </div>
        <Field
          as={Input}
          type='text'
          id='url'
          name='url'
          value={formik.values.url}
          label={t('webhooks.create_webhook_modal.payload_url_input')}
        />
        {formik.touched.url && formik.errors.url ? (
          <ErrorMessage>{formik.errors.url.toString()}</ErrorMessage>
        ) : null}
        <div className='flex justify-between items-center mb-3 mt-6'>
          <div className='flex items-center space-x-2'>
            <div className={styles['field-item--icon']}>2</div>
            <Typography variant='paragraph-2' weight='bold'>
              {t(
                'webhooks.create_webhook_modal.webhook_event_configuration_input',
              )}
            </Typography>
            {formik.values.events.length > 0 && (
              <Badge
                variant='secondary'
                text={`${formik.values.events.length} selected`}
                size='small'
              />
            )}
          </div>
          <div className='flex items-start'>
            <Field
              id='allEvents'
              name='allEvents'
              as={Checkbox}
              type='checkbox'
              className='mr-2'
              data-testid='select-all-events'
              checked={eventTypes?.length === formik.values.events.length}
              onChange={handleSelectEveryEvent}
            />
            <Typography variant='paragraph-1' weight='medium'>
              {t('webhooks.create_webhook_modal.select_all_label')}
            </Typography>
          </div>
        </div>
        {formik.touched.events && formik.errors.events ? (
          <ErrorMessage>{formik.errors.events.toString()}</ErrorMessage>
        ) : null}
        <EventTypeList
          eventTypes={eventTypes}
          selectedWebhookEvents={formik.values.events}
          formik={formik}
        />
        <div className={styles.buttonRow}>
          <Button
            className='w-full'
            onClick={() => setShowModal(false)}
            variant='secondary'
          >
            {t('webhooks.update_webhook_modal.cancel_button')}
          </Button>
          <Button
            type='submit'
            className='w-full'
            loading={isLoading}
            disabled={
              !formik.values.url ||
              formik.values.events.length === 0 ||
              JSON.stringify(formik.values) ===
                JSON.stringify({
                  events: webhook?.events,
                  isEnabled: webhook?.isEnabled,
                  url: webhook?.url,
                })
            }
          >
            {webhook
              ? t('webhooks.update_webhook_modal.update_button')
              : t('webhooks.create_webhook_modal.create_button')}
          </Button>
        </div>
      </Form>
    </FormikProvider>
  );
};
