import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard, Typography } from '@dynamic-labs/northstar';
import { SocialIcon } from '@dynamic-labs/iconic';

import { useSignInWithSocialProviders } from '../../../../../../../app/routes/Configurations/Providers/hooks/useSignInWithSocialProviders';

import styles from './MagicSocialSection.module.scss';

const magicProviders = [
  'apple',
  'bitbucket',
  'discord',
  'facebook',
  'github',
  'gitlab',
  'google',
  'linkedin',
  'microsoft',
  'twitch',
  'twitter',
];

export const MagicSocialSection: FC = () => {
  const { t } = useTranslation();

  const {
    listOfProviders,
    enabledProviders,
    initialProviders,
    toggleSocialSignInProvider,
  } = useSignInWithSocialProviders({
    disabled: false,
  });

  const filteredProviders = [
    ...listOfProviders.filter((provider) => magicProviders.includes(provider)),
  ];

  const magicEnabledProviders = [...enabledProviders];
  const magicInitialProviders = [...initialProviders];

  return (
    <div className={styles.wrapper}>
      <Typography variant='paragraph-2' weight='regular' color='gray-1'>
        {t(
          'v2.page.embedded_wallets.third_party.magicLink.socials.description',
        )}
      </Typography>

      {filteredProviders.map((provider) => (
        <SingleToggleCard
          key={provider}
          Icon={<SocialIcon name={provider} />}
          allowExpand={false}
          title={provider}
          accordionKey={provider}
          inputProps={{
            checked: magicEnabledProviders.includes(provider),
            id: provider,
            isSaved: magicInitialProviders.includes(provider),
            onChange: () => toggleSocialSignInProvider(provider),
            type: 'toggle',
          }}
        />
      ))}
    </div>
  );
};
