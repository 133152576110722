import { Position } from '../../Popper.types';

/** Return element's top-left position relative to root */
export const getChildPosition = (
  root: HTMLElement,
  element: HTMLElement,
): Position => {
  const rootRect = root.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  return {
    left: elementRect.x - rootRect.x,
    top: elementRect.y - rootRect.y,
  };
};
