import React, { useState } from 'react';

import { t } from 'i18next';
import validateColor from 'validate-color';

import { ErrorInfo } from '../../../../components/ErrorInfo';
import { useSdkVersion } from '../../../../hooks/useSdkVersion';
import { useModalDesignSettings } from '../../../../context/SettingsContext/helpers/designHelpers';
import { ColorInput } from '../../../../components/ColorInput';
import { themeOptions, brandTypeOptions } from '../optionsData';
import { OptionsSwitcher } from '../../../../components/OptionsSwitcher';
import { Typography } from '../../../../components/Typography';
import { Alert } from '../../../../components/Alert';

import styles from './StylingOptions.module.css';
import { TemplatesDropdown } from './TemplatesDropdown/TemplatesDropdown';

export const StylingOptions = () => {
  const { settings, setTheme, setBrand, setPrimaryColor, setTemplate } =
    useModalDesignSettings();
  const { brand, primaryColor, template, theme } = settings || {};

  const MINIMAL_SDK_VERSION = '0.15.0-RC';

  const { currentEnvSdkVersion, isOutdatedSdkVersion } =
    useSdkVersion(MINIMAL_SDK_VERSION);

  const [isColorValid, setIsColorValid] = useState(true);

  const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      const validateColorResult = validateColor(e.target.value);

      if (!validateColorResult) {
        setIsColorValid(false);
        return;
      }
    }

    setIsColorValid(true);
    setPrimaryColor(e.target.value || primaryColor || '#4779FF');
  };

  return (
    <div className={styles.options}>
      <Typography variant='paragraph-2' weight='medium'>
        {t<string>('design_page.modal.settings.themeTitle')}
      </Typography>

      <Typography variant='paragraph-1' className={styles.subheading}>
        {t<string>('design_page.modal.settings.templateTitle')}
      </Typography>
      <TemplatesDropdown
        selected={template}
        setTemplate={(key) => setTemplate(key)}
      />

      <Typography variant='paragraph-1' className={styles.subheading}>
        {t<string>('design_page.modal.settings.themeStyle')}
      </Typography>
      <OptionsSwitcher
        options={themeOptions}
        initialActiveKey={theme}
        onOptionClick={(key) => setTheme(key)}
      />

      <Typography variant='paragraph-2' weight='medium' className='mt-6'>
        {t<string>('design_page.modal.settings.brandTitle')}
      </Typography>

      <Typography variant='paragraph-1' className={styles.subheading}>
        {t<string>('design_page.modal.settings.customColor')}
      </Typography>
      {!isColorValid && (
        <ErrorInfo
          message={t<string>('design_page.modal.warnings.invalid_color')}
          className='mb-2'
        />
      )}

      <ColorInput
        className='w-[220px]'
        defaultValue={primaryColor}
        onBlur={handleColorChange}
      />

      <Typography variant='paragraph-1' className={styles.subheading}>
        {t<string>('design_page.modal.settings.brandType')}
      </Typography>
      <OptionsSwitcher
        options={brandTypeOptions}
        initialActiveKey={brand}
        onOptionClick={(key) => setBrand(key)}
      />
      {isOutdatedSdkVersion && brand === 'subtle' && theme === 'dark' && (
        <Alert severity='warning' className='mb-4 mt-2'>
          {t(
            'design_page.modal.warnings.subtle_dark_mode_minimum_version',
          ).replace('{{MINIMAL_SDK_VERSION}}', MINIMAL_SDK_VERSION)}
          {currentEnvSdkVersion &&
            ` ${t('design_page.modal.warnings.current_version').replace(
              '{{CURRENT_ENV_SDK_VERSION}}',
              currentEnvSdkVersion,
            )}`}
        </Alert>
      )}
    </div>
  );
};
