/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AuthenticatorTransportProtocol,
    AuthenticatorTransportProtocolFromJSON,
    AuthenticatorTransportProtocolFromJSONTyped,
    AuthenticatorTransportProtocolToJSON,
} from './AuthenticatorTransportProtocol';

/**
 * 
 * @export
 * @interface PasskeyRegistrationCredential
 */
export interface PasskeyRegistrationCredential {
    /**
     * 
     * @type {string}
     * @memberof PasskeyRegistrationCredential
     */
    attestationObject: string;
    /**
     * 
     * @type {string}
     * @memberof PasskeyRegistrationCredential
     */
    clientDataJson: string;
    /**
     * 
     * @type {string}
     * @memberof PasskeyRegistrationCredential
     */
    credentialId: string;
    /**
     * 
     * @type {Array<AuthenticatorTransportProtocol>}
     * @memberof PasskeyRegistrationCredential
     */
    transports: Array<AuthenticatorTransportProtocol>;
}

export function PasskeyRegistrationCredentialFromJSON(json: any): PasskeyRegistrationCredential {
    return PasskeyRegistrationCredentialFromJSONTyped(json, false);
}

export function PasskeyRegistrationCredentialFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasskeyRegistrationCredential {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'attestationObject': json['attestationObject'],
        'clientDataJson': json['clientDataJson'],
        'credentialId': json['credentialId'],
        'transports': ((json['transports'] as Array<any>).map(AuthenticatorTransportProtocolFromJSON)),
    };
}

export function PasskeyRegistrationCredentialToJSON(value?: PasskeyRegistrationCredential | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'attestationObject': value.attestationObject,
        'clientDataJson': value.clientDataJson,
        'credentialId': value.credentialId,
        'transports': ((value.transports as Array<any>).map(AuthenticatorTransportProtocolToJSON)),
    };
}

