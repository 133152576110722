import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { securityColumns } from './security-columns';

export const useSecurityColumns = () => {
  const { t } = useTranslation();

  const columns = useMemo(() => securityColumns(t), [t]);

  return { columns };
};
