import { useState } from 'react';

import { BinIcon } from '../../../../../icons';
import { DeleteWebhookModal } from '../DeleteWebhookModal';

import styles from './DeleteWebhookCell.module.css';

type Props = {
  webhookId: string;
};

export const DeleteWebhookCell = ({ webhookId }: Props) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={styles.container}>
      <button
        type='button'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
        className={styles['button--delete']}
        data-testid='delete-webhook-button'
      >
        <BinIcon />
      </button>
      {showModal && (
        <DeleteWebhookModal setShowModal={setShowModal} webhookId={webhookId} />
      )}
    </div>
  );
};
