/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SdkViewSection,
    SdkViewSectionFromJSON,
    SdkViewSectionFromJSONTyped,
    SdkViewSectionToJSON,
} from './SdkViewSection';
import {
    SdkViewType,
    SdkViewTypeFromJSON,
    SdkViewTypeFromJSONTyped,
    SdkViewTypeToJSON,
} from './SdkViewType';

/**
 * Configs used to create the view in the sdk.
 * @export
 * @interface SdkView
 */
export interface SdkView {
    /**
     * 
     * @type {SdkViewType}
     * @memberof SdkView
     */
    type: SdkViewType;
    /**
     * The sections which will be used create the view in the sdk. The sections will be displayed in the order that they appear in the array.
     * @type {Array<SdkViewSection>}
     * @memberof SdkView
     */
    sections?: Array<SdkViewSection>;
}

export function SdkViewFromJSON(json: any): SdkView {
    return SdkViewFromJSONTyped(json, false);
}

export function SdkViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': SdkViewTypeFromJSON(json['type']),
        'sections': !exists(json, 'sections') ? undefined : ((json['sections'] as Array<any>).map(SdkViewSectionFromJSON)),
    };
}

export function SdkViewToJSON(value?: SdkView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': SdkViewTypeToJSON(value.type),
        'sections': value.sections === undefined ? undefined : ((value.sections as Array<any>).map(SdkViewSectionToJSON)),
    };
}

