/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProjectSettingsSdkAccountAbstraction
 */
export interface ProjectSettingsSdkAccountAbstraction {
    /**
     * If set to false, will create smart wallets for embedded wallets only.
     * @type {boolean}
     * @memberof ProjectSettingsSdkAccountAbstraction
     */
    allWallets?: boolean;
    /**
     * If set to false, will create smart wallets for new users only.
     * @type {boolean}
     * @memberof ProjectSettingsSdkAccountAbstraction
     */
    allUsers?: boolean;
    /**
     * if set to false, only smart wallet will be in user wallets list.
     * @type {boolean}
     * @memberof ProjectSettingsSdkAccountAbstraction
     */
    separateSmartWalletAndSigner?: boolean;
}

export function ProjectSettingsSdkAccountAbstractionFromJSON(json: any): ProjectSettingsSdkAccountAbstraction {
    return ProjectSettingsSdkAccountAbstractionFromJSONTyped(json, false);
}

export function ProjectSettingsSdkAccountAbstractionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProjectSettingsSdkAccountAbstraction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allWallets': !exists(json, 'allWallets') ? undefined : json['allWallets'],
        'allUsers': !exists(json, 'allUsers') ? undefined : json['allUsers'],
        'separateSmartWalletAndSigner': !exists(json, 'separateSmartWalletAndSigner') ? undefined : json['separateSmartWalletAndSigner'],
    };
}

export function ProjectSettingsSdkAccountAbstractionToJSON(value?: ProjectSettingsSdkAccountAbstraction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allWallets': value.allWallets,
        'allUsers': value.allUsers,
        'separateSmartWalletAndSigner': value.separateSmartWalletAndSigner,
    };
}

