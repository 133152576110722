import { getIn, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { ProjectSettingsGeneral } from '@dynamic-labs/sdk-api';

import { ErrorInfo } from '../../../../components/ErrorInfo';
import { Typography } from '../../../../components/Typography';

import styles from './FormErrors.module.css';

export const FormErrors = () => {
  const { t } = useTranslation();
  const { errors, touched } = useFormikContext<ProjectSettingsGeneral>();
  const showErrors =
    !!Object.keys(touched).length && !!Object.keys(errors).length;

  if (!showErrors) return null;

  return (
    <ErrorInfo className='mb-5'>
      <>
        <Typography
          variant='paragraph-2'
          weight='medium'
          className={styles.heading}
          key='errors'
        >
          {t('settings_page.general.errors.invalidData')}
        </Typography>
        {Object.keys(errors).map((key) => {
          if (key === 'supportUrls') {
            return (
              <div key={`error_${key}`}>
                {Object.keys(getIn(errors, key)).map((appName) => (
                  <Typography
                    variant='paragraph-1'
                    className={styles['error-message']}
                    key={`error_${key}_${appName}`}
                  >
                    {i18next.t<string>(`settings_page.general.errors.${key}`, {
                      appName,
                    })}
                  </Typography>
                ))}
              </div>
            );
          }
          return (
            <Typography
              variant='paragraph-1'
              className={styles['error-message']}
              key={`error_${key}`}
            >
              {i18next.t<string>(`settings_page.general.errors.${key}`)}
            </Typography>
          );
        })}
      </>
    </ErrorInfo>
  );
};
