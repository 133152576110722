/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterSessionKeyRequest
 */
export interface RegisterSessionKeyRequest {
    /**
     * 
     * @type {string}
     * @memberof RegisterSessionKeyRequest
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof RegisterSessionKeyRequest
     */
    prevSessionKeySignature?: string;
}

export function RegisterSessionKeyRequestFromJSON(json: any): RegisterSessionKeyRequest {
    return RegisterSessionKeyRequestFromJSONTyped(json, false);
}

export function RegisterSessionKeyRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterSessionKeyRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'publicKey': json['publicKey'],
        'prevSessionKeySignature': !exists(json, 'prevSessionKeySignature') ? undefined : json['prevSessionKeySignature'],
    };
}

export function RegisterSessionKeyRequestToJSON(value?: RegisterSessionKeyRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'publicKey': value.publicKey,
        'prevSessionKeySignature': value.prevSessionKeySignature,
    };
}

