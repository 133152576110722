import { useState } from 'react';

import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { Typography } from '../../../../components/Typography';
import { useOriginsContext } from '../../../../context/OriginsContext';
import Button from '../../../../components/Button';
import { PlusIcon } from '../../../../../icons';
import ReactTable from '../../../../components/ReactTable';
import { SortBy } from '../../../../types';
import { sortOrigins } from '../helpers/sortOrigins';
import { useSecurityColumns } from '../helpers/useSecurityColumns';
import { AddOriginModal } from '../AddOriginModal';
import { Icon } from '../../../../components/Icon';

import styles from './CORS.module.css';

export const CORS = () => {
  const { t } = useTranslation();
  const [isAddNew, setIsAddNew] = useState(false);
  const [prevSort, setPrevSort] = useState<SortBy | undefined>();
  const { setOrigins, originData, environmentId } = useOriginsContext();
  const { columns } = useSecurityColumns();
  return (
    <>
      <div className={styles.container}>
        <Typography className={styles.heading} as='h2'>
          {t('settings_page.security.cors.heading')}
        </Typography>
        <div className={styles.text}>
          {t('settings_page.security.cors.text')}{' '}
          {t('settings_page.security.cors.learn_more')}{' '}
          <a href={t('settings_page.security.cors.learn_more_url')}>
            {t('settings_page.security.cors.learn_more_title')}
          </a>
        </div>
        <ReactTable
          columns={columns}
          data={originData}
          loading={!originData}
          withSortParams={{
            onSort: (sortParam) => {
              if (sortParam === prevSort) return;
              setOrigins(sortOrigins(originData, sortParam));
              setPrevSort(sortParam);
            },
          }}
        />
        <Button
          className={styles.button}
          variant='secondary'
          onClick={() => setIsAddNew(true)}
          leading={<Icon size='medium' icon={<PlusIcon />} />}
        >
          {t('settings_page.security.cors.button_text')}
        </Button>
      </div>
      <AnimatePresence>
        {isAddNew && environmentId && (
          <AddOriginModal
            environmentId={environmentId}
            handleClose={() => setIsAddNew(false)}
          />
        )}
      </AnimatePresence>
    </>
  );
};
