import { useTranslation } from 'react-i18next';

import { ErrorInfo } from '../../../../../../components/ErrorInfo';
import { Typography } from '../../../../../../components/Typography/Typography'; // Assuming the Typography component is imported from a separate file

import { buildErrorsInfo, InputsErrors } from './helpers';
import styles from './form-errors.module.css';

interface Props {
  errors: InputsErrors;
  showErrors: boolean;
}

export const FormErrors = ({
  errors,
  showErrors,
}: Props): JSX.Element | null => {
  const { t } = useTranslation();
  const {
    requiredError,
    duplicatedName,
    formatError,
    farcasterInvalidNumberError,
  } = buildErrorsInfo(errors);
  if (!showErrors) return null;

  return (
    <ErrorInfo className='mb-5'>
      {formatError && (
        <span>
          <Typography variant='subtitle' className={styles.heading}>
            {t(
              'integrations.onboarding_and_kyc.access_control.access_list.format_error_title',
            )}
          </Typography>
          <Typography variant='paragraph-0' className={styles['error-message']}>
            {formatError}
          </Typography>
        </span>
      )}
      {requiredError && (
        <span>
          <Typography variant='subtitle' className={styles.heading}>
            {t('create_project.required_error_title')}
          </Typography>
          <Typography variant='paragraph-0' className={styles['error-message']}>
            {requiredError}
          </Typography>
        </span>
      )}
      {duplicatedName && (
        <span>
          <Typography variant='subtitle' className={styles.heading}>
            {t(
              'integrations.onboarding_and_kyc.access_control.access_list.duplicate_address_error_title',
            )}
          </Typography>
          <Typography variant='paragraph-0' className={styles['error-message']}>
            {duplicatedName}
          </Typography>
        </span>
      )}
      {farcasterInvalidNumberError && (
        <span>
          <Typography variant='subtitle' className={styles.heading}>
            {t(
              'integrations.onboarding_and_kyc.access_control.access_list.invalid_number_error_title',
            )}
          </Typography>
          <Typography variant='paragraph-0' className={styles['error-message']}>
            {farcasterInvalidNumberError}
          </Typography>
        </span>
      )}
    </ErrorInfo>
  );
};
