/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Organization,
    OrganizationFromJSON,
    OrganizationFromJSONTyped,
    OrganizationToJSON,
} from './Organization';

/**
 * 
 * @export
 * @interface OrganizationsResponse
 */
export interface OrganizationsResponse {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof OrganizationsResponse
     */
    organizations?: Array<Organization>;
}

export function OrganizationsResponseFromJSON(json: any): OrganizationsResponse {
    return OrganizationsResponseFromJSONTyped(json, false);
}

export function OrganizationsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organizations': !exists(json, 'organizations') ? undefined : ((json['organizations'] as Array<any>).map(OrganizationFromJSON)),
    };
}

export function OrganizationsResponseToJSON(value?: OrganizationsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organizations': value.organizations === undefined ? undefined : ((value.organizations as Array<any>).map(OrganizationToJSON)),
    };
}

