export const shortenWalletAddress = (
  walletAddress: string | undefined,
  first = 6,
  last = 4,
) => {
  if (!walletAddress) return '';

  const walletAddressLength = Number(walletAddress.length);
  if (first >= walletAddressLength || last >= walletAddressLength)
    return walletAddress;

  return walletAddress.replace(
    walletAddress.substring(first, Number(walletAddressLength) - last),
    '...',
  );
};
