import { environmentsApi } from '../../../services/api';
import { EnvironmentVisitorsQueryHookData } from '../useEnvironmentVisitorsQuery.types';

export const fetchVisitorCountByEnvId = async (
  environmentId?: string,
): Promise<EnvironmentVisitorsQueryHookData> => {
  if (!environmentId) {
    return;
  }

  const data = await environmentsApi.getVisitorsCountByEnvironmentId({
    environmentId,
  });

  const values = {
    users: {
      count: data?.users?.count || 0,
      uniquePastMonth: data?.users?.uniquePastMonth || 0,
    },
    visitors: {
      count: data?.visitors?.count || 0,
      uniquePastMonth: data?.visitors?.uniquePastMonth || 0,
    },
  };

  return values;
};
