/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PublicKeyCredentialType,
    PublicKeyCredentialTypeFromJSON,
    PublicKeyCredentialTypeFromJSONTyped,
    PublicKeyCredentialTypeToJSON,
} from './PublicKeyCredentialType';

/**
 * 
 * @export
 * @interface PublicKeyCredentialParameters
 */
export interface PublicKeyCredentialParameters {
    /**
     * 
     * @type {number}
     * @memberof PublicKeyCredentialParameters
     */
    alg: number;
    /**
     * 
     * @type {PublicKeyCredentialType}
     * @memberof PublicKeyCredentialParameters
     */
    type: PublicKeyCredentialType;
}

export function PublicKeyCredentialParametersFromJSON(json: any): PublicKeyCredentialParameters {
    return PublicKeyCredentialParametersFromJSONTyped(json, false);
}

export function PublicKeyCredentialParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PublicKeyCredentialParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'alg': json['alg'],
        'type': PublicKeyCredentialTypeFromJSON(json['type']),
    };
}

export function PublicKeyCredentialParametersToJSON(value?: PublicKeyCredentialParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'alg': value.alg,
        'type': PublicKeyCredentialTypeToJSON(value.type),
    };
}

