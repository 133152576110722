import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';

type OptimizedForToggleProps = {
  disabled: boolean;
  optimizedFor: 'web2' | 'web3';
};

const web3DisplayOrder = ['wallet', 'email'];
const web2DisplayOrder = ['email', 'wallet'];

export const OptimizedForToggle: FC<OptimizedForToggleProps> = ({
  optimizedFor,
  disabled,
}) => {
  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isDisplayOrderForCurrentOptimizedFor =
    settings[activeEnvironmentType].design?.modal?.displayOrder?.toString() ===
    (optimizedFor === 'web3' ? web3DisplayOrder : web2DisplayOrder).toString();

  const initialIsDisplayOrderForCurrentOptimizedFor =
    initialSettings[
      activeEnvironmentType
    ].design?.modal?.displayOrder?.toString() ===
    (optimizedFor === 'web3' ? web3DisplayOrder : web2DisplayOrder).toString();

  const isDisplayOrderSaved =
    isDisplayOrderForCurrentOptimizedFor ===
    initialIsDisplayOrderForCurrentOptimizedFor;

  const setDisplayOrder = (e: ChangeEvent<HTMLInputElement>) => {
    const optimizedForDisplayOrder =
      optimizedFor === 'web3' ? web3DisplayOrder : web2DisplayOrder;

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        design: {
          ...settings[activeEnvironmentType].design,
          modal: {
            ...settings[activeEnvironmentType].design.modal,
            displayOrder: e.target.checked ? optimizedForDisplayOrder : [],
            emailOnly: false,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      accordionKey={`optimized-for-${optimizedFor}`}
      title={t('v2.page.log_in_methods.design.optimized_for.title', {
        value: t(`v2.page.log_in_methods.design.optimized_for.${optimizedFor}`),
      })}
      description={
        optimizedFor === 'web3'
          ? t('v2.page.log_in_methods.design.optimized_for.description_web3')
          : t('v2.page.log_in_methods.design.optimized_for.description_web2')
      }
      allowExpand={false}
      inputProps={{
        checked: isDisplayOrderForCurrentOptimizedFor && !disabled,
        disabled,
        id: `optimized-for-${optimizedFor}`,
        isSaved: isDisplayOrderSaved,
        onChange: setDisplayOrder,
        type: 'toggle',
      }}
    />
  );
};
