/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Token,
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
} from './Token';

/**
 * 
 * @export
 * @interface TokensResponse
 */
export interface TokensResponse {
    /**
     * 
     * @type {Array<Token>}
     * @memberof TokensResponse
     */
    tokens?: Array<Token>;
}

export function TokensResponseFromJSON(json: any): TokensResponse {
    return TokensResponseFromJSONTyped(json, false);
}

export function TokensResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokensResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tokens': !exists(json, 'tokens') ? undefined : ((json['tokens'] as Array<any>).map(TokenFromJSON)),
    };
}

export function TokensResponseToJSON(value?: TokensResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tokens': value.tokens === undefined ? undefined : ((value.tokens as Array<any>).map(TokenToJSON)),
    };
}

