import { createContext, useContext, useMemo } from 'react';

import { UseMutateAsyncFunction } from 'react-query';

import { EnvironmentEnum, TestAccountResponse } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { useTestAccount } from '../../hooks/useTestAccount';

type TestAccountContextProps = {
  createTestAccount: UseMutateAsyncFunction<
    TestAccountResponse,
    unknown,
    void,
    unknown
  >;
  deleteTestAccount: UseMutateAsyncFunction<void, unknown, void, unknown>;
  isLoadingTestAccounts: boolean;
  isSandbox: boolean;
  testAccount: TestAccountResponse | undefined;
};

type TestAccountContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const TestAccountContext = createContext<
  TestAccountContextProps | undefined
>(undefined);

export const TestAccountContextProvider = ({
  children,
}: TestAccountContextProviderProps) => {
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const environmentId = activeEnvironment?.id;

  const {
    createTestAccount,
    deleteTestAccount,
    isLoading: isLoadingTestAccounts,
    testAccount,
  } = useTestAccount(
    environmentId || '',
    activeEnvironmentType === EnvironmentEnum.Sandbox,
  );

  const value = useMemo(
    () => ({
      createTestAccount,
      deleteTestAccount,
      isLoadingTestAccounts,
      isSandbox: activeEnvironmentType === EnvironmentEnum.Sandbox,
      testAccount,
    }),
    [
      activeEnvironmentType,
      createTestAccount,
      deleteTestAccount,
      isLoadingTestAccounts,
      testAccount,
    ],
  );

  return (
    <TestAccountContext.Provider value={value}>
      {children}
    </TestAccountContext.Provider>
  );
};

export const useTestAccountContext = () => {
  const context = useContext(TestAccountContext);

  if (context === undefined) {
    throw new Error(
      'usage of useTestAccountContext not wrapped in `TestAccountContextProvider`.',
    );
  }

  if (!context.isSandbox) {
    throw new Error(
      'useTestAccountContext is not allowed outside of sandbox environments.',
    );
  }

  return context;
};
