import { useUpdateProjectSettings } from '@dynamic-labs/redcoast-query';
import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import { ProjectSettingCategory } from '../../../../app/types';
import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';
import { useSettingsHasChanged } from '../../../../app/hooks/useSettingsHasChanged';
import { useSettingsContext } from '../../../../app/context/SettingsContext';

export const useProjectSettingsUpdate = (
  settingsType: ProjectSettingCategory,
) => {
  const {
    cancelChanges: cancelProjectChanges,
    updateInitialSettings: updateProjectInitialSettings,
    liveHasChanged,
    sandboxHasChanged,
  } = useSettingsHasChanged(settingsType);
  const { activeEnvironmentType, environments } = useEnvironmentsContext();
  const { settings } = useSettingsContext();

  const projectSettingsHasChanged =
    (liveHasChanged && activeEnvironmentType === EnvironmentEnum.Live) ||
    (sandboxHasChanged && activeEnvironmentType === EnvironmentEnum.Sandbox);

  const { mutate: saveProjectSettings } = useUpdateProjectSettings({
    onSuccess: () => {
      updateProjectInitialSettings(activeEnvironmentType);
    },
  });

  const handleSaveProjectSettings = () => {
    if (projectSettingsHasChanged) {
      saveProjectSettings({
        environmentId: environments?.[activeEnvironmentType].id || '',
        projectSettings: settings[activeEnvironmentType],
      });
    }
  };

  const handleReset = () => {
    cancelProjectChanges();
  };

  return {
    handleReset,
    handleSaveProjectSettings,
    projectSettingsHasChanged,
  };
};
