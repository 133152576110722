/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DeeplinkUrlResponse,
    DeeplinkUrlResponseFromJSON,
    DeeplinkUrlResponseFromJSONTyped,
    DeeplinkUrlResponseToJSON,
} from './DeeplinkUrlResponse';

/**
 * 
 * @export
 * @interface DeeplinkUrlsResponse
 */
export interface DeeplinkUrlsResponse {
    /**
     * 
     * @type {Array<DeeplinkUrlResponse>}
     * @memberof DeeplinkUrlsResponse
     */
    deeplinkUrls: Array<DeeplinkUrlResponse>;
}

export function DeeplinkUrlsResponseFromJSON(json: any): DeeplinkUrlsResponse {
    return DeeplinkUrlsResponseFromJSONTyped(json, false);
}

export function DeeplinkUrlsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeeplinkUrlsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deeplinkUrls': ((json['deeplinkUrls'] as Array<any>).map(DeeplinkUrlResponseFromJSON)),
    };
}

export function DeeplinkUrlsResponseToJSON(value?: DeeplinkUrlsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deeplinkUrls': ((value.deeplinkUrls as Array<any>).map(DeeplinkUrlResponseToJSON)),
    };
}

