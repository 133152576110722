import { ProjectSettingsGeneral } from '@dynamic-labs/sdk-api';

import { useBaseSettings } from '../useBaseSettings';
import { useSettingsContext } from '../..';
import { useEnvironmentsContext } from '../../../EnvironmentsContext';

export const useGeneralSettings = () => {
  const sanitizeGeneralSettings = (
    obj: ProjectSettingsGeneral,
  ): ProjectSettingsGeneral => obj;

  const { initialSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const baseSettings = useBaseSettings<ProjectSettingsGeneral>(
    'general',
    sanitizeGeneralSettings,
  );

  return {
    ...baseSettings,
    initialSettings: initialSettings?.[activeEnvironmentType]?.general,
  };
};
