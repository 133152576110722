import { useCallback } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { ExternalAuth } from '@dynamic-labs/sdk-api';
import { LockIcon, SingleToggleCard, Tag } from '@dynamic-labs/northstar';

import { useExternalAuthSettings } from '../../../../../app/context/SettingsContext/helpers/externalAuthSettings';

import styles from './EnableToggle.module.scss';

export const EnableToggle = ({
  fieldName,
  externalAuthEnabled,
}: {
  externalAuthEnabled: boolean;
  fieldName: keyof ExternalAuth;
}) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<ExternalAuth>();
  const { setSpecificSettings, initialSettings, settings } =
    useExternalAuthSettings();

  const handleToggleChange = useCallback(() => {
    const newEnabledValue = !values.enabled;
    setFieldValue('enabled', newEnabledValue);
    setSpecificSettings({
      externalAuth: { ...values, enabled: newEnabledValue },
    });
  }, [setFieldValue, setSpecificSettings, values]);

  return (
    <SingleToggleCard
      allowExpand={false}
      accordionKey='third_party_auth'
      title={t(`third_party_auth.${fieldName}.Heading`)}
      description={t(`third_party_auth.${fieldName}.Description`)}
      inputProps={{
        checked: values.enabled ?? false,
        disabled: !externalAuthEnabled,
        id: 'enabled-toggle',
        isSaved: initialSettings?.enabled === settings?.externalAuth?.enabled,
        onChange: handleToggleChange,
        type: 'toggle',
      }}
      Tags={
        !externalAuthEnabled
          ? [
              {
                Tag: (
                  <Tag
                    className={styles.tag}
                    icon={<LockIcon />}
                    iconPosition='left'
                    text={t('third_party_auth.enterprise_only')}
                    color='primary'
                    size='large'
                  />
                ),
                id: 'enable-toggle-enterprise-tag',
              },
            ]
          : undefined
      }
    />
  );
};
