import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Typography } from '@dynamic-labs/northstar';
import { MFADevice, RoleEnum } from '@dynamic-labs/sdk-api';

import { Tooltip } from '../../../../../../components/Tooltip';
import { useDashboardRole } from '../../../../../../hooks/useDashboardRole';

import { ResetMfaModal } from './ResetMfaModal';

type MfaDevicesSectionProps = {
  devices: MFADevice[];
  onUpdate: () => void;
  userId?: string;
};

export const MfaDevicesSection = ({
  devices,
  onUpdate,
  userId,
}: MfaDevicesSectionProps) => {
  const { t } = useTranslation();

  const { checkForAccess } = useDashboardRole();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const isAdmin = checkForAccess(RoleEnum.Admin);

  return (
    <div className='mb-6'>
      <p className='mb-2 text-xs text-gray-1'>
        {t('users_details_modal.account.mfa.title')}
      </p>

      {!devices.length || !userId ? (
        <Typography variant='paragraph-2' weight='medium' color='primary-1'>
          {t('users_details_modal.account.mfa.no_devices')}
        </Typography>
      ) : (
        <div className='flex gap-4 items-center'>
          <Typography variant='paragraph-2' weight='medium'>
            {t('users_details_modal.account.mfa.totp')}
          </Typography>
          <Tooltip
            tooltipText={t(
              'users_details_modal.account.mfa.reset_mfa_button.tooltip',
            )}
            disableHover={isAdmin}
            wrapperClassName='flex'
          >
            <button
              type='button'
              onClick={() => setShowDeleteModal(true)}
              className='text-primary-1 disabled:text-tertiary text-xs disabled:cursor-not-allowed'
              disabled={!isAdmin}
            >
              {t('users_details_modal.account.mfa.reset_mfa_button.label')}
            </button>
          </Tooltip>
        </div>
      )}

      {userId && showDeleteModal && (
        <ResetMfaModal
          onClose={() => {
            setShowDeleteModal(false);
          }}
          onDelete={() => {
            setShowDeleteModal(false);
            onUpdate();
          }}
          userId={userId}
        />
      )}
    </div>
  );
};
