/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumFromJSONTyped,
    ChainEnumToJSON,
} from './ChainEnum';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface Visitor
 */
export interface Visitor {
    /**
     * 
     * @type {string}
     * @memberof Visitor
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Visitor
     */
    address: string;
    /**
     * 
     * @type {ChainEnum}
     * @memberof Visitor
     */
    chain: ChainEnum;
    /**
     * 
     * @type {string}
     * @memberof Visitor
     */
    walletName: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof Visitor
     */
    provider: WalletProviderEnum;
    /**
     * 
     * @type {Date}
     * @memberof Visitor
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof Visitor
     */
    projectEnvironmentId?: string;
}

export function VisitorFromJSON(json: any): Visitor {
    return VisitorFromJSONTyped(json, false);
}

export function VisitorFromJSONTyped(json: any, ignoreDiscriminator: boolean): Visitor {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'address': json['address'],
        'chain': ChainEnumFromJSON(json['chain']),
        'walletName': json['walletName'],
        'provider': WalletProviderEnumFromJSON(json['provider']),
        'createdAt': (new Date(json['createdAt'])),
        'projectEnvironmentId': !exists(json, 'projectEnvironmentId') ? undefined : json['projectEnvironmentId'],
    };
}

export function VisitorToJSON(value?: Visitor | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'address': value.address,
        'chain': ChainEnumToJSON(value.chain),
        'walletName': value.walletName,
        'provider': WalletProviderEnumToJSON(value.provider),
        'createdAt': (value.createdAt.toISOString()),
        'projectEnvironmentId': value.projectEnvironmentId,
    };
}

