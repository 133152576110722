import { useTranslation } from 'react-i18next';

import { ChainEnum } from '@dynamic-labs/sdk-api';

import { useTokensByChain } from '../../../../../../../../hooks/useTokensByChain';
import { DropdownField } from '../../../../../../../../components/DropdownField';

type GateRuleTokenSelectProps = {
  chainName: ChainEnum;
  className?: string;
  error?: boolean;
  networkId?: number;
  onSelect: (address?: string) => void;
  selectedTokenAddress?: string;
};

export const GateRuleTokenSelect = ({
  selectedTokenAddress,
  chainName,
  networkId,
  onSelect,
  className,
  error = false,
}: GateRuleTokenSelectProps) => {
  const { t } = useTranslation();
  const { tokens } = useTokensByChain(chainName, networkId);

  return (
    <DropdownField
      className={className}
      placeholder={t(
        'integrations.onboarding_and_kyc.access_control.gating.forms.selectTokenLabel',
      )}
      value={selectedTokenAddress}
      onSelect={onSelect}
      error={error}
      options={tokens
        ?.map((token) => ({
          label: token.name,
          value: token.tokenAddresses[0].contractAddress,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};
