import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { logger } from '../../../../services/logger';
import { useOriginsContext } from '../../../../context/OriginsContext';
import Button from '../../../../components/Button';
import Portal from '../../../../components/Portal';
import { originsApi } from '../../../../services/api';
import { filterOrigins } from '../helpers/filterOrigins';

import styles from './DeleteOriginModal.module.css';

type Props = {
  handleClose: VoidFunction;
  origin: string;
  originId: string;
};

export const DeleteOriginModal = ({
  handleClose,
  originId,
  origin,
}: Props): JSX.Element => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { setOrigins } = useOriginsContext();

  const submitDelete = async () => {
    try {
      setLoading(true);
      await originsApi.deleteOriginById({
        originId,
      });
      const origins = filterOrigins(originId);
      setOrigins(origins);
    } catch (e) {
      logger.error(e);
    } finally {
      setLoading(false);
      handleClose();
    }
  };

  return (
    <Portal handleClose={handleClose} className={styles.portal}>
      <div className={styles.heading}>
        {t('settings_page.security.cors.delete_origin.heading')}
        <span className={styles.subheading}> {origin} </span>
      </div>
      <div className={styles.buttons}>
        <Button onClick={handleClose} variant='secondary'>
          {t('settings_page.security.cors.delete_origin.buttons.cancel')}
        </Button>
        <Button
          loading={loading}
          onClick={() => submitDelete()}
          variant='delete'
        >
          {t('settings_page.security.cors.delete_origin.buttons.delete')}
        </Button>
      </div>
    </Portal>
  );
};
