/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOutcomeEnum,
    AccessOutcomeEnumFromJSON,
    AccessOutcomeEnumFromJSONTyped,
    AccessOutcomeEnumToJSON,
} from './AccessOutcomeEnum';

/**
 * 
 * @export
 * @interface PostAllowlistsRequest
 */
export interface PostAllowlistsRequest {
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistsRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PostAllowlistsRequest
     */
    scope?: string;
    /**
     * 
     * @type {AccessOutcomeEnum}
     * @memberof PostAllowlistsRequest
     */
    outcome?: AccessOutcomeEnum;
}

export function PostAllowlistsRequestFromJSON(json: any): PostAllowlistsRequest {
    return PostAllowlistsRequestFromJSONTyped(json, false);
}

export function PostAllowlistsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostAllowlistsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'outcome': !exists(json, 'outcome') ? undefined : AccessOutcomeEnumFromJSON(json['outcome']),
    };
}

export function PostAllowlistsRequestToJSON(value?: PostAllowlistsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'scope': value.scope,
        'outcome': AccessOutcomeEnumToJSON(value.outcome),
    };
}

