import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetEnvironmentsByProjectIdHook } from './useGetEnvironmentsByProjectId.types';

export const useGetEnvironmentsByProjectId: UseGetEnvironmentsByProjectIdHook =
  ({ projectId }, options) => {
    const { environmentsApi } = useRedcoastQueryContext();

    return useQuery(
      ['environmentsApi', 'getEnvironmentsByProjectId', projectId],
      async () =>
        environmentsApi.getEnvironmentsByProjectId({
          projectId,
        }),
      options,
    );
  };
