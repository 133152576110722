import { FormikErrors } from 'formik';
import { t } from 'i18next';
import {
  TestContext,
  ValidationError,
  array,
  number,
  object,
  string,
} from 'yup';

import { getValueByKey } from '@dynamic-labs/sdk-react-core';
import { Gate, AccessOutcomeEnum } from '@dynamic-labs/sdk-api';

import { ErrorMessage, GateErrors, GateRulePartial } from './types';

const propLabels = {
  amount: t(
    'integrations.onboarding_and_kyc.access_control.gating.forms.minimumAmountLabel',
  ),
  contractAddress: t(
    'integrations.onboarding_and_kyc.access_control.gating.forms.contractAddressLabel',
  ),
  gateName: t(
    'integrations.onboarding_and_kyc.access_control.gating.forms.gateNameLabel',
  ),
  networkId: t(
    'integrations.onboarding_and_kyc.access_control.gating.forms.chainLabel',
  ),
  outcome: t('integrations.onboarding_and_kyc.access_control.outcomeLabel'),
  scopeName: t('integrations.onboarding_and_kyc.access_control.scopeNameLabel'),
  type: t(
    'integrations.onboarding_and_kyc.access_control.gating.forms.typeLabel',
  ),
};

// Finds all required props in the formik validation errors without repeating them in case of props in arrays
const findRequiredProps = (
  obj: any,
  path = '',
  requiredProps: string[] = [],
) => {
  Object.entries(obj).forEach(([key, value]) => {
    const newPath = path ? `${path}.${key}` : key;
    if (value === 'required' && !requiredProps.includes(key)) {
      requiredProps.push(key);
    } else if (typeof value === 'object' && value !== null) {
      findRequiredProps(value, newPath, requiredProps);
    }
  });

  return requiredProps;
};

// Builds error messages (title, message) from the formik validation errors
export const buildErrorMessages = (errors: GateErrors): ErrorMessage[] => {
  const errorMessages = [];

  const requiredProps = findRequiredProps(errors).map((key) =>
    getValueByKey(propLabels, key),
  );

  let requiredError;

  if (requiredProps?.length === 1) {
    requiredError = t(
      'integrations.onboarding_and_kyc.access_control.errors.required_error_singular',
      {
        error: requiredProps[0],
      },
    );
  } else if (requiredProps?.length > 1) {
    requiredError = t(
      'integrations.onboarding_and_kyc.access_control.errors.required_error_plural',
      {
        error: requiredProps.join(', '),
      },
    );
  }

  // If there are required props, add the error message
  if (requiredError) {
    errorMessages.push({
      message: requiredError,
      title: t('create_project.required_error_title'),
    });
  }

  // If the gate name already exists, add the error message
  if (errors.gateName === 'duplicatedName') {
    errorMessages.push({
      message: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.duplicate_gate_name_error',
      ),
      title: t('create_project.duplicate_name_error_title'),
    });
  }

  // If the scope name already exists, add the error message
  if (errors.scopeName === 'duplicatedName') {
    errorMessages.push({
      message: t(
        'integrations.onboarding_and_kyc.access_control.errors.duplicate_scope_name_error',
      ),
      title: t('create_project.duplicate_name_error_title'),
    });
  }

  const hasAmountError =
    errors?.rules &&
    (errors.rules as FormikErrors<GateRulePartial[]>)?.find(
      (rule) =>
        typeof rule?.filter === 'object' &&
        (rule?.filter as any).amount === 'invalidAmount',
    );

  // If any of the rules have an invalid amount, add the error message
  if (hasAmountError) {
    errorMessages.push({
      message: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_amount_error',
      ),
      title: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_amount_error_title',
      ),
    });
  }

  const hasTokenIdError =
    errors?.rules &&
    (errors.rules as FormikErrors<GateRulePartial[]>)?.find(
      (rule) =>
        typeof rule?.filter === 'object' &&
        (rule?.filter as any).tokenId === 'invalidTokenId',
    );

  // If any of the rules have an invalid tokenId, add the error message
  if (hasTokenIdError) {
    errorMessages.push({
      message: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_token_id_error',
      ),
      title: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_token_id_error_title',
      ),
    });
  }

  const hasAddressError =
    errors?.rules &&
    (errors.rules as FormikErrors<GateRulePartial[]>)?.find(
      (rule) =>
        typeof rule?.address === 'object' &&
        (rule?.address as any).contractAddress === 'invalidAddress',
    );

  // If any of the rules have an invalid contract address, add the error message
  if (hasAddressError) {
    errorMessages.push({
      message: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_address_error',
      ),
      title: t(
        'integrations.onboarding_and_kyc.access_control.gating.errors.invalid_address_error_title',
      ),
    });
  }

  return errorMessages;
};

export const getValidationSchema = (currentGate?: Gate, allGates?: Gate[]) => {
  const otherGates =
    allGates && currentGate
      ? allGates.filter((g) => g.id !== currentGate?.id)
      : [];

  const validationSchema = object().shape({
    gateName: string()
      .required('required')
      .test('duplicated_name', '', (value, context) =>
        checkDuplicatedName(value, context, otherGates, 'name'),
      ),
    outcome: string().required('required'),
    rules: array()
      .of(
        object().shape({
          address: object().shape({
            contractAddress: string()
              .required('required')
              .matches(/^[A-Za-z0-9]{18,65}$/, 'invalidAddress'),
            networkId: number().required('required'),
          }),
          filter: object()
            .shape({
              amount: number().required('required').positive('invalidAmount'),
              tokenId: string().matches(
                /^$|^(0x|0X)[a-fA-F0-9]{1,40}$/,
                'invalidTokenId',
              ),
            })
            .required('required'),
          type: string().required('required'),
        }),
      )
      .required('required'),
    scopeName: string().when('outcome', {
      is: (outcome: AccessOutcomeEnum) => outcome === AccessOutcomeEnum.Scope,
      then: string()
        .required('required')
        .test('duplicated_name', '', (value, context) =>
          checkDuplicatedName(value, context, otherGates, 'scope'),
        ),
    }),
  });

  return validationSchema;
};

// Check for duplicated gate or scope name
export const checkDuplicatedName = (
  value: string | undefined,
  context: TestContext,
  gates: Gate[],
  prop: 'name' | 'scope',
): ValidationError | boolean => {
  const { path, createError } = context;

  if (!value) return createError({ message: 'required', path });
  if (gates.some((gate) => gate[prop]?.toLowerCase() === value.toLowerCase())) {
    return createError({
      message: 'duplicatedName',
      path,
    });
  }
  return true;
};
