import { useMutation } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseUpdateProviderHook } from './useUpdateProvider.types';

export const useUpdateProvider: UseUpdateProviderHook = (options) => {
  const { settingsApi } = useRedcoastQueryContext();

  return useMutation(
    ({ providerId, providerUpdateRequest }) =>
      settingsApi.updateProvider({
        providerId,
        providerUpdateRequest,
      }),
    options,
  );
};
