import { useTranslation } from 'react-i18next';

import { Chip } from '../Chip';

type Props = {
  daysLeft: number;
  small?: boolean;
};

export const TrialChip = ({ daysLeft, small }: Props) => {
  const { t } = useTranslation();
  const color = daysLeft <= 4 ? 'yellow' : 'blue';

  const daysLeftText =
    daysLeft === 1 ? t('subscription.day') : t('subscription.days');

  return (
    <Chip
      label={`${daysLeft} ${daysLeftText} ${t('subscription.left_on_trial')}`}
      color={color}
      small={small}
    />
  );
};
