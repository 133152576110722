import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { UnprocessableEntity } from '@dynamic-labs/sdk-api';

import { logger } from '../../../../../services/logger';
import Header from '../../../../../components/Portal/Header';
import Portal from '../../../../../components/Portal';
import Button from '../../../../../components/Button';
import { ErrorInfo } from '../../../../../components/ErrorInfo';
import { Typography } from '../../../../../components/Typography';
import { membersApi } from '../../../../../services/api';
import {
  Member,
  useMembersContext,
} from '../../../../../context/MembersContext';

import styles from './RemoveUserModal.module.css';

interface RemoveUserModalProps {
  member: Member;
  setShowModal: (showModal: boolean) => void;
}

export const RemoveUserModal = ({
  member,
  setShowModal,
}: RemoveUserModalProps) => {
  const { t } = useTranslation();
  const { fetchMembers, setMembers } = useMembersContext();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const removeUser = async () => {
    try {
      if (member.id === undefined) {
        throw new Error('Member id is undefined');
      }

      setLoading(true);

      await membersApi.deleteMemberById({
        memberId: member.id,
      });
      const members = await fetchMembers();
      setMembers(members);
      setShowModal(false);
    } catch (e: any) {
      if (e.status === 422) {
        const error: UnprocessableEntity = await e.json();
        setErrorMessage(error.error);
      } else {
        logger.error(e);
        setErrorMessage(t('admin.table.modal.removeUserError'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Portal handleClose={() => setShowModal(false)} className={styles.portal}>
      <Header
        handleClose={() => setShowModal(false)}
        headingText={t('admin.table.modal.removeUserTitle')}
      />
      <Typography
        variant='paragraph-2'
        weight='medium'
        className='text-gray-1 mb-4'
      >
        {t('admin.table.modal.removeUserText', { alias: member.alias })}
      </Typography>
      {errorMessage && (
        <ErrorInfo className='mb-5'>
          <span>
            <Typography className={styles.errorMessage} variant='paragraph-1'>
              {errorMessage}
            </Typography>
          </span>
        </ErrorInfo>
      )}
      <div className={styles.buttons}>
        <Button
          type='button'
          large
          variant='secondary'
          onClick={() => setShowModal(false)}
        >
          {t('admin.table.modal.cancelButton')}
        </Button>
        <Button type='button' loading={loading} large onClick={removeUser}>
          {t('admin.table.modal.confirmButton')}
        </Button>
      </div>
    </Portal>
  );
};
