/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessOutcomeEnum,
    AccessOutcomeEnumFromJSON,
    AccessOutcomeEnumFromJSONTyped,
    AccessOutcomeEnumToJSON,
} from './AccessOutcomeEnum';

/**
 * 
 * @export
 * @interface Allowlist
 */
export interface Allowlist {
    /**
     * 
     * @type {string}
     * @memberof Allowlist
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Allowlist
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof Allowlist
     */
    enabledAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Allowlist
     */
    projectEnvironmentId: string;
    /**
     * 
     * @type {string}
     * @memberof Allowlist
     */
    scope?: string | null;
    /**
     * 
     * @type {AccessOutcomeEnum}
     * @memberof Allowlist
     */
    outcome?: AccessOutcomeEnum;
}

export function AllowlistFromJSON(json: any): Allowlist {
    return AllowlistFromJSONTyped(json, false);
}

export function AllowlistFromJSONTyped(json: any, ignoreDiscriminator: boolean): Allowlist {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'enabledAt': !exists(json, 'enabledAt') ? undefined : (json['enabledAt'] === null ? null : new Date(json['enabledAt'])),
        'projectEnvironmentId': json['projectEnvironmentId'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
        'outcome': !exists(json, 'outcome') ? undefined : AccessOutcomeEnumFromJSON(json['outcome']),
    };
}

export function AllowlistToJSON(value?: Allowlist | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'enabledAt': value.enabledAt === undefined ? undefined : (value.enabledAt === null ? null : value.enabledAt.toISOString()),
        'projectEnvironmentId': value.projectEnvironmentId,
        'scope': value.scope,
        'outcome': AccessOutcomeEnumToJSON(value.outcome),
    };
}

