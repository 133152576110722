/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Provider,
    ProviderFromJSON,
    ProviderFromJSONTyped,
    ProviderToJSON,
} from './Provider';

/**
 * 
 * @export
 * @interface ProvidersResponse
 */
export interface ProvidersResponse {
    /**
     * 
     * @type {Array<Provider>}
     * @memberof ProvidersResponse
     */
    providers?: Array<Provider>;
}

export function ProvidersResponseFromJSON(json: any): ProvidersResponse {
    return ProvidersResponseFromJSONTyped(json, false);
}

export function ProvidersResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProvidersResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'providers': !exists(json, 'providers') ? undefined : ((json['providers'] as Array<any>).map(ProviderFromJSON)),
    };
}

export function ProvidersResponseToJSON(value?: ProvidersResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'providers': value.providers === undefined ? undefined : ((value.providers as Array<any>).map(ProviderToJSON)),
    };
}

