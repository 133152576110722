/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TelegramUser
 */
export interface TelegramUser {
    /**
     * 
     * @type {number}
     * @memberof TelegramUser
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    photoURL?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof TelegramUser
     */
    authDate: string;
}

export function TelegramUserFromJSON(json: any): TelegramUser {
    return TelegramUserFromJSONTyped(json, false);
}

export function TelegramUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelegramUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'hash': json['hash'],
        'photoURL': !exists(json, 'photoURL') ? undefined : json['photoURL'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'authDate': json['authDate'],
    };
}

export function TelegramUserToJSON(value?: TelegramUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'hash': value.hash,
        'photoURL': value.photoURL,
        'username': value.username,
        'authDate': value.authDate,
    };
}

