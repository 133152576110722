import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { SingleToggleCard } from '@dynamic-labs/northstar';
import { ProviderEnum } from '@dynamic-labs/sdk-api';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';
import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';

type PromptForKeyExportToggleProps = {
  disabled: boolean;
};

export const PromptForKeyExportToggle: FC<PromptForKeyExportToggleProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged } = useProvidersContext();

  const { activeEnvironmentType } = useEnvironmentsContext();
  const { setSettings, settings, initialSettings } = useSettingsContext();

  const isTurnkeyEnabled = Boolean(
    getProviderValue(ProviderEnum.Turnkey, 'enabledAt'),
  );

  const isTurnkeySettingSaved = Boolean(
    !hasProviderChanged(ProviderEnum.Turnkey),
  );

  const isPromptForKeyExportEnabled = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets?.promptForKeyExport &&
      isTurnkeyEnabled &&
      !disabled,
  );

  const isPromptForKeyExportSaved = Boolean(
    settings[activeEnvironmentType].sdk.embeddedWallets?.promptForKeyExport ===
      initialSettings[activeEnvironmentType].sdk.embeddedWallets
        ?.promptForKeyExport,
  );

  const handleOnToggleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          embeddedWallets: {
            ...settings[activeEnvironmentType].sdk.embeddedWallets,
            promptForKeyExport: event.target.checked,
          },
        },
      },
    });
  };

  return (
    <SingleToggleCard
      allowExpand={false}
      title={t(
        'v2.page.embedded_wallets.dynamic_section.settings.prompt_for_key_export.title',
      )}
      description={t(
        'v2.page.embedded_wallets.dynamic_section.settings.prompt_for_key_export.description',
      )}
      accordionKey='prompt_for_key_export'
      inputProps={{
        checked: isTurnkeyEnabled && isPromptForKeyExportEnabled,
        disabled: !isTurnkeyEnabled || disabled,
        id: 'prompt_for_key_export',
        isSaved: isTurnkeySettingSaved && isPromptForKeyExportSaved,
        onChange: handleOnToggleChange,
        type: 'toggle',
      }}
    />
  );
};
