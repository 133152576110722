import React, { FC, MouseEvent } from 'react';

import { Trigger } from '@radix-ui/react-accordion';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { ChevronIcon } from '../../../icons';
import { Icon } from '../../Icon';
import { OpacityAnimation } from '../../../animations';
import { Typography } from '../../Typography';
import type { MenuItemProps } from '../SidebarNavigation.types';

import styles from './MenuItem.module.scss';

export const MenuItem: FC<MenuItemProps> = ({
  icon,
  linkTo,
  text,
  expandable,
  handleSetExpandedField,
  isSubItem = false,
  disabled,
  onDisabledClick,
}) => {
  const handleExpandableItemClick = (e: MouseEvent<HTMLAnchorElement>) => {
    // If navigation is disabled and not expandable prevent default
    // Which means no changing pages (ie. when saving settings)
    if (disabled && !expandable) {
      e.preventDefault();
      onDisabledClick?.();
      return;
    }

    if (!expandable || !handleSetExpandedField) return;

    // IF item is expandable add current link to expanded fields,
    // so accordion can expand
    handleSetExpandedField(linkTo);

    // if item is expandable we don't want to change the route
    // Element needs to be a NavLink for active state management and styling
    e.preventDefault();
  };

  const Element = (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.container, {
          [styles['container--active']]: isActive,
          [styles['container--sub-item']]: isSubItem,
        })
      }
      onClick={handleExpandableItemClick}
      to={linkTo}
    >
      {icon && <Icon icon={icon} size={20} className={styles.icon} />}
      <Typography
        variant='paragraph-2'
        weight='medium'
        className={classNames(styles.text, {
          [styles['text--no-icon']]: !icon,
        })}
      >
        {text}
      </Typography>

      {expandable && (
        <OpacityAnimation className={styles.dropdownWrapper}>
          <Icon
            icon={<ChevronIcon />}
            size={20}
            className={styles.dropdownIcon}
          />
        </OpacityAnimation>
      )}
    </NavLink>
  );

  if (expandable) {
    return <Trigger className={styles.trigger}>{Element}</Trigger>;
  }

  return <div className={styles.trigger}>{Element}</div>;
};
