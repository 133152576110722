import { useMemo } from 'react';

import { ProjectSettings } from '@dynamic-labs/sdk-api';

import { useSettingsContext } from '..';
import { useEnvironmentsContext } from '../../EnvironmentsContext';

type SanitizeFunction<T> = (obj: T) => T;

export const useBaseSettings = <T>(
  settingsKey: keyof ProjectSettings,
  sanitizeFn: SanitizeFunction<T>,
) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings, setSettings, initialSettings, setInitialSettings } =
    useSettingsContext();

  const sanitize = (obj: T): T =>
    JSON.parse(
      JSON.stringify(obj, (_, value) =>
        value === null || value === '' ? null : value,
      ),
    );

  const setSpecificSettings = (values: T) => {
    const newSettings = sanitize(sanitizeFn(values));

    const currentSettings = settings[activeEnvironmentType] || {};
    const currentKeySettings = (currentSettings[settingsKey] as object) || {};

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...currentSettings,
        [settingsKey]: {
          ...currentKeySettings,
          ...newSettings,
        },
      },
    });
  };

  const settingsHasChanged = useMemo(
    () =>
      JSON.stringify(settings[activeEnvironmentType]?.[settingsKey]) !==
      JSON.stringify(initialSettings[activeEnvironmentType]?.[settingsKey]),
    [activeEnvironmentType, initialSettings, settings, settingsKey],
  );

  const updateInitialSettings = () => {
    setInitialSettings(settings);
  };

  const cancelChanges = () => {
    setSettings(initialSettings);
  };

  return {
    cancelChanges,
    globalSettings: settings,
    setSpecificSettings,
    settings: settings?.[activeEnvironmentType]?.[settingsKey] as T,
    settingsHasChanged,
    updateInitialSettings,
  };
};
