import { FC, useEffect, useState } from 'react';

import { AnimatePresence } from 'framer-motion';

import { ChainEnum, IntegrationSetting } from '@dynamic-labs/sdk-api';
import { useWalletBookContext } from '@dynamic-labs/wallet-book';

import { Integration, WalletRow } from '../../../types';
import { isBadgeActive } from '../../../utils';
import { Typography } from '../../../components/Typography';
import { ModalHashLocations, useModalHashLocation } from '../utils';

import Header from './Header';
import ChainDetailsModal from './ChainDetailsModal';
import styles from './single-chain.module.css';
import { getWalletRows } from './ChainDetailsModal/data';

interface Add {
  liveSettings: IntegrationSetting[];
  networkMessage?: string;
  requireCustomRpcUrl?: boolean;
  sandboxSettings: IntegrationSetting[];
  scope?: string;
}

const chainIntegrationCardMap = {
  [ChainEnum.Eclipse]: ModalHashLocations.Eclipse,
  [ChainEnum.Eth]: ModalHashLocations.Evm,
  [ChainEnum.Evm]: ModalHashLocations.Evm,
  [ChainEnum.Sol]: ModalHashLocations.Solana,
  [ChainEnum.Flow]: ModalHashLocations.Flow,
  [ChainEnum.Algo]: ModalHashLocations.Algorand,
  [ChainEnum.Stark]: ModalHashLocations.Starknet,
  [ChainEnum.Cosmos]: ModalHashLocations.Cosmos,
  [ChainEnum.Btc]: ModalHashLocations.Bitcoin,
};

const SingleChain: FC<Integration & Add> = ({
  Icon,
  content,
  name,
  // url,
  chain = ChainEnum.Eth,
  walletDescription = '',
  liveSettings,
  sandboxSettings,
  title,
  comingSoon,
  scope,
  networkMessage,
  requireCustomRpcUrl,
}) => {
  const [wallets, setWallets] = useState<WalletRow[]>([]);
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: chainIntegrationCardMap[chain],
  });
  const { walletBook } = useWalletBookContext();

  useEffect(() => {
    getWalletRows(walletBook, chain, walletDescription).then(setWallets);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ChainDetailsModal
            wallets={wallets}
            title={title}
            Icon={Icon}
            name={name}
            content={content}
            liveSettings={liveSettings}
            setShowModal={handleShowModal}
            chain={chain}
            walletDescription={walletDescription}
            scope={scope}
            networkMessage={networkMessage}
            requireCustomRpcUrl={requireCustomRpcUrl}
          />
        )}
      </AnimatePresence>
      <button
        type='button'
        className={`${styles.container} ${
          comingSoon ? styles['container--coming-soon'] : ''
        }`}
        onClick={() => (comingSoon ? null : handleShowModal(!showModal))}
      >
        <Header
          Icon={Icon}
          name={title}
          isLiveActive={isBadgeActive(liveSettings, name)}
          isSandboxActive={isBadgeActive(sandboxSettings, name)}
          comingSoon={comingSoon}
          scope={scope}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {content.short}
        </Typography>
      </button>
    </>
  );
};

export default SingleChain;
