import { Typography } from '../Typography';

import styles from './table.module.css';

type Props = {
  headers?: string[];
  rows: JSX.Element[];
};

const HeaderMap: Record<number, string> = {
  0: 'grid-cols-0',
  1: 'grid-cols-1',
  2: 'grid-cols-2',
  3: 'grid-cols-3',
  4: 'grid-cols-4',
  5: 'grid-cols-5',
  6: 'grid-cols-6',
};

const Table = ({ rows, headers }: Props) => {
  const className = headers && HeaderMap[headers.length];

  return (
    <div className={styles.container}>
      <div className={`${styles.headers} ${className}`}>
        {headers &&
          headers.map((header, index) => (
            <Typography
              key={header !== '' ? header : `empty-${index}`}
              variant='paragraph-1'
              className={styles.header}
            >
              {header}
            </Typography>
          ))}
      </div>
      <div>{rows}</div>
    </div>
  );
};

export default Table;
