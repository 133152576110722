/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NameServiceData,
    NameServiceDataFromJSON,
    NameServiceDataFromJSONTyped,
    NameServiceDataToJSON,
} from './NameServiceData';
import {
    WalletProviderEnum,
    WalletProviderEnumFromJSON,
    WalletProviderEnumFromJSONTyped,
    WalletProviderEnumToJSON,
} from './WalletProviderEnum';

/**
 * 
 * @export
 * @interface JwtBlockchainAccount
 */
export interface JwtBlockchainAccount {
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof JwtBlockchainAccount
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof JwtBlockchainAccount
     */
    chain: string;
    /**
     * 
     * @type {string}
     * @memberof JwtBlockchainAccount
     */
    id: string;
    /**
     * 
     * @type {NameServiceData}
     * @memberof JwtBlockchainAccount
     */
    nameService?: NameServiceData;
    /**
     * 
     * @type {string}
     * @memberof JwtBlockchainAccount
     */
    walletName: string;
    /**
     * 
     * @type {WalletProviderEnum}
     * @memberof JwtBlockchainAccount
     */
    walletProvider: WalletProviderEnum;
}

export function JwtBlockchainAccountFromJSON(json: any): JwtBlockchainAccount {
    return JwtBlockchainAccountFromJSONTyped(json, false);
}

export function JwtBlockchainAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): JwtBlockchainAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'address': json['address'],
        'chain': json['chain'],
        'id': json['id'],
        'nameService': !exists(json, 'name_service') ? undefined : NameServiceDataFromJSON(json['name_service']),
        'walletName': json['wallet_name'],
        'walletProvider': WalletProviderEnumFromJSON(json['wallet_provider']),
    };
}

export function JwtBlockchainAccountToJSON(value?: JwtBlockchainAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'address': value.address,
        'chain': value.chain,
        'id': value.id,
        'name_service': NameServiceDataToJSON(value.nameService),
        'wallet_name': value.walletName,
        'wallet_provider': WalletProviderEnumToJSON(value.walletProvider),
    };
}

