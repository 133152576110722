/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CurrencyType {
    Fiat = 'fiat',
    Crypto = 'crypto'
}

export function CurrencyTypeFromJSON(json: any): CurrencyType {
    return CurrencyTypeFromJSONTyped(json, false);
}

export function CurrencyTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CurrencyType {
    return json as CurrencyType;
}

export function CurrencyTypeToJSON(value?: CurrencyType | null): any {
    return value as any;
}

