import { FC, PropsWithChildren } from 'react';

import { useDashboardContext } from '../../../app/context/DashboardContext';
import { useEnvironmentsContext } from '../../../app/context/EnvironmentsContext';
import { useLDIdentify } from '../../../app/hooks/useLDIdentify';

export const LaunchDarklyProvider: FC<PropsWithChildren> = ({ children }) => {
  const { activeOrganizationId, activeProjectId } = useDashboardContext();

  const { activeEnvironment } = useEnvironmentsContext();

  useLDIdentify({
    activeEnvironmentId: activeEnvironment?.id,
    activeOrganizationId,
    activeProjectId,
  });

  return children;
};
