import { useTranslation } from 'react-i18next';

import { Chip } from '../../../../../components/Chip';

type Props = {
  scope?: string | null;
};

export const AccessControlScopeChip = ({ scope }: Props) => {
  const { t } = useTranslation();

  if (!scope) {
    return null;
  }

  const scopeLabel = `${t(
    'integrations.onboarding_and_kyc.access_control.scopeLabel',
  )} = "${scope}"`;

  return <Chip label={scopeLabel} color='white' />;
};
