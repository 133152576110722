import { FC } from 'react';

import { Typography } from '@dynamic-labs/northstar';

import styles from './ChangedSettingsIndicator.module.scss';

type ChangedSettingsIndicatorProps = {
  amount: number;
};

export const ChangedSettingsIndicator: FC<ChangedSettingsIndicatorProps> = ({
  amount,
}) => {
  if (amount < 1) {
    return null;
  }

  return (
    <Typography
      variant='paragraph-2'
      weight='medium'
      color='primary-1'
      className={styles.wrapper}
    >
      +{amount}
    </Typography>
  );
};
