import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section } from '@dynamic-labs/northstar';

import styles from './BrandedWalletsSection.module.scss';
import { Wallets } from './Wallets';
import { MultiWallet } from './MultiWallet';
import { HideNetworkIcon } from './HideNetworkIcon';
import { WalletConnect } from './WalletConnect';

type BrandedWalletsSectionProps = {
  disabled: boolean;
};

export const BrandedWalletsSection: FC<BrandedWalletsSectionProps> = ({
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('v2.page.log_in_methods.branded_wallets.title')}
      description={t('v2.page.log_in_methods.branded_wallets.description')}
    >
      <div className={styles.section}>
        <Wallets disabled={disabled} />

        <MultiWallet disabled={disabled} />

        <HideNetworkIcon disabled={disabled} />

        <WalletConnect disabled={disabled} />
      </div>
    </Section>
  );
};
