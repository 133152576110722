/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NextJsSettings,
    NextJsSettingsFromJSON,
    NextJsSettingsFromJSONTyped,
    NextJsSettingsToJSON,
} from './NextJsSettings';
import {
    ReactSettings,
    ReactSettingsFromJSON,
    ReactSettingsFromJSONTyped,
    ReactSettingsToJSON,
} from './ReactSettings';

/**
 * 
 * @export
 * @interface FrameworkSettings
 */
export interface FrameworkSettings {
    /**
     * 
     * @type {ReactSettings}
     * @memberof FrameworkSettings
     */
    react?: ReactSettings;
    /**
     * 
     * @type {NextJsSettings}
     * @memberof FrameworkSettings
     */
    nextjs?: NextJsSettings;
}

export function FrameworkSettingsFromJSON(json: any): FrameworkSettings {
    return FrameworkSettingsFromJSONTyped(json, false);
}

export function FrameworkSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FrameworkSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'react': !exists(json, 'react') ? undefined : ReactSettingsFromJSON(json['react']),
        'nextjs': !exists(json, 'nextjs') ? undefined : NextJsSettingsFromJSON(json['nextjs']),
    };
}

export function FrameworkSettingsToJSON(value?: FrameworkSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'react': ReactSettingsToJSON(value.react),
        'nextjs': NextJsSettingsToJSON(value.nextjs),
    };
}

