import { BinIcon } from '../../../../../../icons';

import styles from './DeleteEntryCell.module.css';

type Props = {
  entryId: string;
  handleDeleteClick: (entryId: string) => void;
};

export const DeleteEntryCell = ({ entryId, handleDeleteClick }: Props) => (
  <div className={`${styles.container}`}>
    <button
      type='button'
      aria-label='Delete entry'
      className={styles.button__delete}
      onClick={() => {
        handleDeleteClick(entryId);
      }}
    >
      <BinIcon />
    </button>
  </div>
);
