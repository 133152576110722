/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HCaptchaSettings
 */
export interface HCaptchaSettings {
    /**
     * 
     * @type {boolean}
     * @memberof HCaptchaSettings
     */
    enabled?: boolean;
    /**
     * Secret key used to validate captcha response. This will never be surfaced in a GET response.
     * @type {string}
     * @memberof HCaptchaSettings
     */
    secretKey?: string;
    /**
     * 
     * @type {string}
     * @memberof HCaptchaSettings
     */
    siteKey?: string;
}

export function HCaptchaSettingsFromJSON(json: any): HCaptchaSettings {
    return HCaptchaSettingsFromJSONTyped(json, false);
}

export function HCaptchaSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): HCaptchaSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'secretKey': !exists(json, 'secretKey') ? undefined : json['secretKey'],
        'siteKey': !exists(json, 'siteKey') ? undefined : json['siteKey'],
    };
}

export function HCaptchaSettingsToJSON(value?: HCaptchaSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'enabled': value.enabled,
        'secretKey': value.secretKey,
        'siteKey': value.siteKey,
    };
}

