/* eslint-disable no-console */
import { datadogRum } from '@datadog/browser-rum';

import versionFile from '../../../../version.json';
import environment from '../environments/environment';

import { rumBeforeSend } from './rumBeforeSend';

const allowedTracingOrigins: string[] = [];
const BASE_URL = process.env.NX_BUILD_DYNAMIC_API_BASE_URL;
if (BASE_URL) {
  allowedTracingOrigins.push(BASE_URL);
}

console.log(`Running version: ${versionFile.version}`);

// Disable DD Rum by setting trackingConsent to 'not-granted' in non-deployed envs
const disableTracking = !environment.isDeployed || environment.preview;

datadogRum.init({
  allowedTracingUrls: [
    'https://app.dynamic.xyz',
    'https://app.dynamic-preprod.xyz',
    'https://redcoast.dynamic.dev',
    ...allowedTracingOrigins,
  ],
  applicationId: '1cd9aadd-8c82-402a-978e-015a0d9f939a',
  beforeSend: rumBeforeSend,
  clientToken: 'pubf0f9fc45beda22be2ab0daf62b79e316',

  defaultPrivacyLevel: 'allow',

  service: 'red-coast',

  sessionReplaySampleRate: 100,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sessionSampleRate: 100,
  site: 'datadoghq.com',
  trackLongTasks: true,
  trackResources: true,
  trackUserInteractions: true,
  trackingConsent: disableTracking ? 'not-granted' : 'granted',

  version: versionFile.version,
  ...environment.datadog,
});
