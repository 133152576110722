/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SignInProviderEnum {
    Dynamic = 'dynamic',
    MagicLink = 'magicLink',
    Blocto = 'blocto',
    Turnkey = 'turnkey',
    CoinbaseWaas = 'coinbaseWaas'
}

export function SignInProviderEnumFromJSON(json: any): SignInProviderEnum {
    return SignInProviderEnumFromJSONTyped(json, false);
}

export function SignInProviderEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): SignInProviderEnum {
    return json as SignInProviderEnum;
}

export function SignInProviderEnumToJSON(value?: SignInProviderEnum | null): any {
    return value as any;
}

