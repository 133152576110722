/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Asset,
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import {
    AssetTransfer,
    AssetTransferFromJSON,
    AssetTransferFromJSONTyped,
    AssetTransferToJSON,
} from './AssetTransfer';

/**
 * 
 * @export
 * @interface AssetDiff
 */
export interface AssetDiff {
    /**
     * 
     * @type {Asset}
     * @memberof AssetDiff
     */
    asset: Asset;
    /**
     * 
     * @type {Array<AssetTransfer>}
     * @memberof AssetDiff
     */
    transferIn: Array<AssetTransfer>;
    /**
     * 
     * @type {Array<AssetTransfer>}
     * @memberof AssetDiff
     */
    transferOut: Array<AssetTransfer>;
}

export function AssetDiffFromJSON(json: any): AssetDiff {
    return AssetDiffFromJSONTyped(json, false);
}

export function AssetDiffFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetDiff {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'asset': AssetFromJSON(json['asset']),
        'transferIn': ((json['transferIn'] as Array<any>).map(AssetTransferFromJSON)),
        'transferOut': ((json['transferOut'] as Array<any>).map(AssetTransferFromJSON)),
    };
}

export function AssetDiffToJSON(value?: AssetDiff | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'asset': AssetToJSON(value.asset),
        'transferIn': ((value.transferIn as Array<any>).map(AssetTransferToJSON)),
        'transferOut': ((value.transferOut as Array<any>).map(AssetTransferToJSON)),
    };
}

