import { MfaBackupCodeAcknowledgement } from '@dynamic-labs/sdk-api';
import { useDynamicContext, UserProfile } from '@dynamic-labs/sdk-react-core';

const hasPendingMfaAction = (user: UserProfile) =>
  user.scope?.includes('requiresAdditionalAuth') ||
  user.mfaBackupCodeAcknowledgement === MfaBackupCodeAcknowledgement.Pending;

export const useHasUserWithPendingMfa = () => {
  const { userWithMissingInfo } = useDynamicContext();

  return Boolean(
    userWithMissingInfo && hasPendingMfaAction(userWithMissingInfo),
  );
};
