import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { useSubscriptionContext } from '../../../context/SubscriptionContext';
import { Paper } from '../../../components/Paper';
import { Typography } from '../../../components/Typography';
import { TrialChip } from '../../../components/TrialChip';
import Button from '../../../components/Button';
import { UpsellBanner } from '../../../components/UpsellBanner';
import { useSubscriptionLock } from '../../../hooks/useSubscriptionLock';
import { openWindowUrl } from '../../../utils/constants/defaults';

import styles from './SubscriptionContent.module.css';

const ADVANCED_PLAN_PRICE = 99;
const PRICE_PER_USER = '0.05';
const PAY_OVER_USERS = '2000';

export const SubscriptionContent = () => {
  const { subscription, upgradeToAdvancePlan, isUpgrading, checks } =
    useSubscriptionContext();
  const { shouldShowTrialBanner, shouldShowTrialChip } = useSubscriptionLock();
  const { t } = useTranslation();

  const {
    isAdvancedPlan,
    isStandardPlan,
    isEnterprisePlan,
    isFreePlan,
    isAdvancedTrialWithoutPaymentMethod,
    isAdvancedTrialWithPaymentMethod,
    isAdvancedWithPaymentMethod,
    isAdvancedWithoutPaymentMethod,
    isStandardWithoutPaymentMethod,
    isStandardWithPaymentMethod,
  } = checks;

  const {
    planType,
    trialDaysLeft,
    inTrial,
    billingPortalUrl,
    billingPortalAddPaymentMethodUrl,
  } = subscription || {};

  const pricePerOverUsersText = t('subscription.price_per_user')
    .replace('{{PRICE}}', PRICE_PER_USER)
    .replace('{{USERS_AMOUNT}}', PAY_OVER_USERS);

  const descriptionText = useMemo<string>(() => {
    if (isAdvancedPlan) {
      return t('subscription.advanced_description');
    }
    if (isStandardPlan) {
      return t('subscription.standard_description');
    }
    if (isEnterprisePlan) {
      return t('subscription.enterprise_description');
    }

    return t('subscription.free_description');
  }, [isAdvancedPlan, isEnterprisePlan, t, isStandardPlan]);

  const simplePriceText = useMemo(() => {
    if (isEnterprisePlan) {
      return t('subscription.custom');
    }
    if (inTrial) {
      return t('subscription.price_trial');
    }
    return t('subscription.free');
  }, [inTrial, isEnterprisePlan, t]);

  const showUpgradeButton =
    (!isAdvancedTrialWithoutPaymentMethod && isFreePlan) ||
    isAdvancedWithoutPaymentMethod;

  const showBillingPortalButton = Boolean(
    billingPortalUrl &&
      (isAdvancedTrialWithPaymentMethod ||
        isAdvancedWithPaymentMethod ||
        isStandardWithPaymentMethod ||
        isEnterprisePlan),
  );

  return (
    <div>
      {subscription && (
        <>
          {shouldShowTrialBanner && (
            <UpsellBanner daysLeft={trialDaysLeft} className={styles.banner} />
          )}
          <Paper className={styles.paper}>
            <div className={styles.wrapper}>
              <div className={`${styles.panel} ${styles['panel--left']}`}>
                <div className={`${styles.inline} ${styles['inline--margin']}`}>
                  <Typography variant='subtitle' className={styles.title}>
                    {planType && planType.toString()}
                    {t('subscription.plan')}
                  </Typography>
                  {shouldShowTrialChip && trialDaysLeft && (
                    <TrialChip daysLeft={trialDaysLeft} />
                  )}
                </div>

                <Typography
                  variant='paragraph-2'
                  className={styles.description}
                >
                  {descriptionText}
                </Typography>
                <Button
                  onClick={() => openWindowUrl('https://dynamic.xyz/pricing')}
                  className={styles.link}
                  variant='link'
                  testId='pricing-link-button'
                >
                  <Typography
                    variant='paragraph-2'
                    weight='medium'
                    className={styles['link--label']}
                  >
                    {t('subscription.view_pricing')}
                  </Typography>
                </Button>
              </div>
              <div className={`${styles.panel} ${styles['panel--right']}`}>
                <div className={`${styles.inline} ${styles['inline--end']}`}>
                  {isAdvancedWithPaymentMethod || isStandardPlan ? (
                    <>
                      <Typography variant='numbers' className={styles.price}>
                        ${ADVANCED_PLAN_PRICE}
                      </Typography>
                      <Typography
                        variant='paragraph-1'
                        className={styles.month}
                      >
                        {t('subscription.per_month')}
                      </Typography>
                    </>
                  ) : (
                    <Typography variant='subtitle' className={styles.price}>
                      {simplePriceText}
                    </Typography>
                  )}
                </div>
                {(isAdvancedWithPaymentMethod || isStandardPlan) && (
                  <Typography
                    variant='paragraph-2'
                    className={`${styles.description} ${styles['description--small']}`}
                  >
                    {pricePerOverUsersText}
                  </Typography>
                )}
              </div>
            </div>

            <div className={styles.divider} />
            <div className={styles['button-wrapper']}>
              {(isAdvancedTrialWithoutPaymentMethod ||
                isStandardWithoutPaymentMethod) &&
                billingPortalAddPaymentMethodUrl && (
                  <Button
                    onClick={() =>
                      openWindowUrl(billingPortalAddPaymentMethodUrl)
                    }
                    className={styles.button}
                  >
                    {t('subscription.add_payment_method')}
                  </Button>
                )}

              {showUpgradeButton && (
                <Button
                  onClick={upgradeToAdvancePlan}
                  disabled={isUpgrading}
                  className={styles.button}
                >
                  {t('subscription.upgrade_to_advanced')}
                </Button>
              )}

              {showBillingPortalButton && (
                <Button
                  onClick={() => openWindowUrl(billingPortalUrl)}
                  variant='secondary'
                  className={styles.button}
                >
                  <Typography variant='paragraph-1' weight='medium'>
                    {t('subscription.go_to_billing_portal')}
                  </Typography>
                </Button>
              )}
            </div>
          </Paper>
        </>
      )}
    </div>
  );
};
