/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ExportCreateRequestFilter,
    ExportCreateRequestFilterFromJSON,
    ExportCreateRequestFilterFromJSONTyped,
    ExportCreateRequestFilterToJSON,
} from './ExportCreateRequestFilter';
import {
    ExportFormatEnum,
    ExportFormatEnumFromJSON,
    ExportFormatEnumFromJSONTyped,
    ExportFormatEnumToJSON,
} from './ExportFormatEnum';
import {
    ExportModelEnum,
    ExportModelEnumFromJSON,
    ExportModelEnumFromJSONTyped,
    ExportModelEnumToJSON,
} from './ExportModelEnum';

/**
 * Request body to create a new export.
 * @export
 * @interface ExportCreateRequest
 */
export interface ExportCreateRequest {
    /**
     * 
     * @type {ExportFormatEnum}
     * @memberof ExportCreateRequest
     */
    format: ExportFormatEnum;
    /**
     * 
     * @type {ExportModelEnum}
     * @memberof ExportCreateRequest
     */
    model: ExportModelEnum;
    /**
     * 
     * @type {ExportCreateRequestFilter}
     * @memberof ExportCreateRequest
     */
    filter?: ExportCreateRequestFilter;
}

export function ExportCreateRequestFromJSON(json: any): ExportCreateRequest {
    return ExportCreateRequestFromJSONTyped(json, false);
}

export function ExportCreateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExportCreateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': ExportFormatEnumFromJSON(json['format']),
        'model': ExportModelEnumFromJSON(json['model']),
        'filter': !exists(json, 'filter') ? undefined : ExportCreateRequestFilterFromJSON(json['filter']),
    };
}

export function ExportCreateRequestToJSON(value?: ExportCreateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': ExportFormatEnumToJSON(value.format),
        'model': ExportModelEnumToJSON(value.model),
        'filter': ExportCreateRequestFilterToJSON(value.filter),
    };
}

