import { FC, useEffect, useState } from 'react';

import { t } from 'i18next';
import { FormikProps } from 'formik';
// eslint-disable-next-line import/no-namespace
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { RoleEnum } from '@dynamic-labs/sdk-api';

import { DropdownTrigger } from '../../../../../components/DropdownTrigger';
import Input from '../../../../../components/Input';
import { Typography } from '../../../../../components/Typography';

import styles from './input-fields.module.css';

type InviteFields = {
  alias: string;
  email: string;
  role: RoleEnum;
  walletPublicKey: string;
};
interface Invites {
  invites: InviteFields[];
}
interface Props {
  formik: FormikProps<Invites>;
  index: number;
  initialActiveField: ActiveFields;
  isUpdateMode?: boolean;
}

type ActiveFields = 'email' | 'wallet';

const DROPDOWN_FIELDS = [
  {
    label: t('admin.invites_modal.dropdown.wallet'),
    value: 'wallet' as ActiveFields,
  },
  {
    label: t('admin.invites_modal.dropdown.email'),
    value: 'email' as ActiveFields,
  },
];

const ROLE_FIELDS = [
  {
    label: t('admin.invites_modal.dropdown.admin'),
    value: RoleEnum.Admin,
  },
  {
    label: t('admin.invites_modal.dropdown.developer'),
    value: RoleEnum.Developer,
  },
  {
    label: t('admin.invites_modal.dropdown.viewer'),
    value: RoleEnum.Viewer,
  },
];

const InputFields: FC<Props> = ({
  formik,
  index,
  isUpdateMode = false,
  initialActiveField,
}) => {
  const [activeField, setActiveField] =
    useState<ActiveFields>(initialActiveField);
  const [roleField, setRoleField] = useState<RoleEnum>(RoleEnum.Admin);

  useEffect(() => {
    if (isUpdateMode) {
      setRoleField(formik.values.invites[index].role);
    }
  }, [isUpdateMode, formik.values.invites, index]);

  const activeFieldLabel = DROPDOWN_FIELDS.find(
    (field) => field.value === activeField,
  )?.label;
  const roleFieldLabel = ROLE_FIELDS.find(
    (field) => field.value === roleField,
  )?.label;

  return (
    <div className={styles.container} key={index}>
      <div className={styles.dropdown}>
        <DropdownMenu.Root>
          <DropdownTrigger disabled={isUpdateMode}>
            <span className={styles.trigger} data-testid='invite-dropdown'>
              {activeFieldLabel}
            </span>
          </DropdownTrigger>
          <DropdownMenu.Content
            className={styles.dropdownContent}
            align='start'
          >
            {DROPDOWN_FIELDS.map((field) => (
              <DropdownMenu.Item
                className={styles.dropdownItem}
                onClick={() => setActiveField(field.value)}
                key={field.value}
              >
                <Typography variant='paragraph-2' weight='medium'>
                  {field.label}
                </Typography>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>

      <div className={styles.dropdown}>
        <DropdownMenu.Root>
          <DropdownTrigger>
            <span className={styles.trigger} data-testid='invite-role-dropdown'>
              {roleFieldLabel}
            </span>
          </DropdownTrigger>
          <DropdownMenu.Content
            className={`${styles.dropdownContent} z-50`}
            align='start'
          >
            {ROLE_FIELDS.map((field) => (
              <DropdownMenu.Item
                className={styles.dropdownItem}
                onClick={() => {
                  setRoleField(field.value);
                  formik.values.invites[index].role = field.value;
                }}
                key={field.value}
              >
                <Typography variant='paragraph-2' weight='medium'>
                  {field.label}
                </Typography>
              </DropdownMenu.Item>
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>

      <Input
        id='alias'
        className={styles.inputShort}
        label={t('admin.invites_modal.inputs.name')}
        name={`invites.${index}.alias`}
        value={formik.values.invites[index].alias}
        onChange={formik.handleChange}
        disabled={isUpdateMode}
      />
      {activeField === 'wallet' && (
        <Input
          id='wallet-address'
          className={styles.input}
          label={t('admin.invites_modal.inputs.wallet_public_key')}
          name={`invites[${index}].walletPublicKey`}
          value={formik.values.invites[index].walletPublicKey}
          onChange={formik.handleChange}
          disabled={isUpdateMode}
        />
      )}
      {activeField === 'email' && (
        <Input
          id='email-address'
          className={styles.input}
          label={t('admin.invites_modal.inputs.email')}
          name={`invites[${index}].email`}
          value={formik.values.invites[index].email}
          onChange={formik.handleChange}
          disabled={isUpdateMode}
        />
      )}
    </div>
  );
};

export default InputFields;
