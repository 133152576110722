import { useState } from 'react';

import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Button, Typography } from '@dynamic-labs/northstar';
import { useCheckExternalJwt } from '@dynamic-labs/redcoast-query';
import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../../../../app/context/EnvironmentsContext';
import { useSubscriptionContext } from '../../../../app/context/SubscriptionContext';

import styles from './JwtTest.module.scss';
import { JwtTestField } from './JwtTestField';
import { JwtTestResponse } from './JwtTestResponse';

export const JwtTest = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext<{ jwt: string }>();
  const { activeEnvironment, activeEnvironmentType } = useEnvironmentsContext();
  const [jwtResponse, setJwtResponse] = useState<any>(null);
  const { checks } = useSubscriptionContext();
  const { isEnterprisePlan } = checks;
  const { enableExternalAuth } = useFlags();

  const externalAuthEnabled =
    isEnterprisePlan ||
    enableExternalAuth ||
    activeEnvironmentType === EnvironmentEnum.Sandbox;

  const { mutateAsync: testJwt } = useCheckExternalJwt();

  const handleTestJwt = async () => {
    try {
      const response = await testJwt({
        environmentId: activeEnvironment?.id ?? '',
        externalJwtCheckRequest: {
          encodedJwt: values.jwt,
        },
      });
      setJwtResponse(response);
    } catch (error: any) {
      setJwtResponse(error);
    }
  };

  const checkButton = t('third_party_auth.check_jwt.check_button');
  const description = t('third_party_auth.check_jwt.description').replace(
    '{check_button}',
    checkButton,
  );
  const title = t('third_party_auth.check_jwt.title');

  return (
    <div className={styles.jwtTest}>
      <Typography
        className={styles.heading}
        as='h2'
        variant='paragraph-2'
        weight='bold'
        color='black-2'
      >
        {title}
      </Typography>
      <Typography
        className={styles.description}
        as='p'
        variant='paragraph-1'
        weight='regular'
        color='black-2'
      >
        {description}
      </Typography>
      <JwtTestResponse response={jwtResponse} />
      <div className={styles.container}>
        <JwtTestField externalAuthEnabled={externalAuthEnabled} />
        <Button
          onClick={handleTestJwt}
          text={checkButton}
          variant='primary'
          size='medium'
          className={styles.primaryButton}
          disabled={!externalAuthEnabled}
        />
      </div>
    </div>
  );
};
