import { CSSProperties, FC, PropsWithChildren, useContext } from 'react';

import classNames from 'classnames';

import { DropdownContext } from '../dropdown-context';

import styles from './components.module.css';

interface Props {
  className?: string;
  containerClassNames?: string;
  style?: CSSProperties;
}

export const DropdownList: FC<PropsWithChildren<Props>> = ({
  children,
  className,
  containerClassNames,
  style,
}) => {
  const { isOpen } = useContext(DropdownContext);

  if (!isOpen) return null;

  return (
    <div className={classNames(styles.container, containerClassNames)}>
      <div className={classNames(styles.list, className)} style={style}>
        <ul>{children}</ul>
      </div>
    </div>
  );
};

DropdownList.defaultProps = { className: '', style: undefined };
