/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MfaBackupCodeAcknowledgement,
    MfaBackupCodeAcknowledgementFromJSON,
    MfaBackupCodeAcknowledgementFromJSONTyped,
    MfaBackupCodeAcknowledgementToJSON,
} from './MfaBackupCodeAcknowledgement';

/**
 * 
 * @export
 * @interface UserFields
 */
export interface UserFields {
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    alias?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    jobTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    phoneNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    tShirtSize?: string;
    /**
     * 
     * @type {string}
     * @memberof UserFields
     */
    team?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserFields
     */
    policiesConsent?: boolean;
    /**
     * Standard ISO 3166-1 alpha-2 two-letter country code
     * @type {string}
     * @memberof UserFields
     */
    country?: string | null;
    /**
     * Alphanumeric with slugs and underscores username
     * @type {string}
     * @memberof UserFields
     */
    username?: string | null;
    /**
     * When provided, used to verify that a captcha is valid and get the success/failure result from the captcha provider server-side.
     * @type {string}
     * @memberof UserFields
     */
    captchaToken?: string;
    /**
     * 
     * @type {object}
     * @memberof UserFields
     */
    metadata?: object;
    /**
     * 
     * @type {MfaBackupCodeAcknowledgement}
     * @memberof UserFields
     */
    mfaBackupCodeAcknowledgement?: MfaBackupCodeAcknowledgement | null;
    /**
     * BTC wallet address
     * @type {string}
     * @memberof UserFields
     */
    btcWallet?: string | null;
    /**
     * KDA wallet address
     * @type {string}
     * @memberof UserFields
     */
    kdaWallet?: string | null;
    /**
     * LTC wallet address
     * @type {string}
     * @memberof UserFields
     */
    ltcWallet?: string | null;
    /**
     * CKB wallet address
     * @type {string}
     * @memberof UserFields
     */
    ckbWallet?: string | null;
    /**
     * KAS wallet address
     * @type {string}
     * @memberof UserFields
     */
    kasWallet?: string | null;
    /**
     * DOGE wallet address
     * @type {string}
     * @memberof UserFields
     */
    dogeWallet?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserFields
     */
    emailNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFields
     */
    discordNotification?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserFields
     */
    newsletterNotification?: boolean;
}

export function UserFieldsFromJSON(json: any): UserFields {
    return UserFieldsFromJSONTyped(json, false);
}

export function UserFieldsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserFields {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'alias': !exists(json, 'alias') ? undefined : json['alias'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'jobTitle': !exists(json, 'jobTitle') ? undefined : json['jobTitle'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'tShirtSize': !exists(json, 'tShirtSize') ? undefined : json['tShirtSize'],
        'team': !exists(json, 'team') ? undefined : json['team'],
        'policiesConsent': !exists(json, 'policiesConsent') ? undefined : json['policiesConsent'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'captchaToken': !exists(json, 'captchaToken') ? undefined : json['captchaToken'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'mfaBackupCodeAcknowledgement': !exists(json, 'mfaBackupCodeAcknowledgement') ? undefined : MfaBackupCodeAcknowledgementFromJSON(json['mfaBackupCodeAcknowledgement']),
        'btcWallet': !exists(json, 'btcWallet') ? undefined : json['btcWallet'],
        'kdaWallet': !exists(json, 'kdaWallet') ? undefined : json['kdaWallet'],
        'ltcWallet': !exists(json, 'ltcWallet') ? undefined : json['ltcWallet'],
        'ckbWallet': !exists(json, 'ckbWallet') ? undefined : json['ckbWallet'],
        'kasWallet': !exists(json, 'kasWallet') ? undefined : json['kasWallet'],
        'dogeWallet': !exists(json, 'dogeWallet') ? undefined : json['dogeWallet'],
        'emailNotification': !exists(json, 'emailNotification') ? undefined : json['emailNotification'],
        'discordNotification': !exists(json, 'discordNotification') ? undefined : json['discordNotification'],
        'newsletterNotification': !exists(json, 'newsletterNotification') ? undefined : json['newsletterNotification'],
    };
}

export function UserFieldsToJSON(value?: UserFields | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'alias': value.alias,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'jobTitle': value.jobTitle,
        'phoneNumber': value.phoneNumber,
        'tShirtSize': value.tShirtSize,
        'team': value.team,
        'policiesConsent': value.policiesConsent,
        'country': value.country,
        'username': value.username,
        'captchaToken': value.captchaToken,
        'metadata': value.metadata,
        'mfaBackupCodeAcknowledgement': MfaBackupCodeAcknowledgementToJSON(value.mfaBackupCodeAcknowledgement),
        'btcWallet': value.btcWallet,
        'kdaWallet': value.kdaWallet,
        'ltcWallet': value.ltcWallet,
        'ckbWallet': value.ckbWallet,
        'kasWallet': value.kasWallet,
        'dogeWallet': value.dogeWallet,
        'emailNotification': value.emailNotification,
        'discordNotification': value.discordNotification,
        'newsletterNotification': value.newsletterNotification,
    };
}

