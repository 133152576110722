import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';
import { getProviderDisplayName } from '../../../../../../../../../app/utils/getProviderDisplayName';
import { useSettingsContext } from '../../../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../../../app/context/EnvironmentsContext';

type SocialUseForLinkingToggleProps = {
  disabled?: boolean;
  provider: ProviderEnum;
};
export const SocialUseForLinkingToggle: FC<SocialUseForLinkingToggleProps> = ({
  provider,
  disabled,
}) => {
  const { t } = useTranslation();
  const {
    getProviderValue,
    hasProviderChanged,
    toggleProvider,
    enabledSocialProviders,
  } = useProvidersContext();
  const { settings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();
  const isProviderEnabled = Boolean(getProviderValue(provider, 'enabledAt'));
  const isProviderSaved = Boolean(!hasProviderChanged(provider));

  const handleToggleProvider = (e: ChangeEvent<HTMLInputElement>) => {
    const anyLinkingProvidersEnabled = enabledSocialProviders.length > 1;

    // when toggling provider off, disable social kyc field if there is no other social provider enabled
    if (!e.target.checked) {
      if (!anyLinkingProvidersEnabled) {
        const updatedKyc = settings[activeEnvironmentType].kyc.map((field) => {
          if (field.name === 'social') {
            return {
              ...field,
              enabled: false,
            };
          }

          return field;
        });

        setSettings({
          ...settings,
          [activeEnvironmentType]: {
            ...settings[activeEnvironmentType],
            kyc: updatedKyc,
          },
        });
      }

      toggleProvider(provider, false);
      return;
    }

    const updatedKyc = settings[activeEnvironmentType].kyc.map((field) => {
      if (field.name === 'social') {
        return {
          ...field,
          enabled: true,
        };
      }

      return field;
    });

    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        kyc: updatedKyc,
      },
    });

    toggleProvider(provider, true);
  };

  return (
    <SingleToggleCard
      accordionKey={`linking-${provider}`}
      title={t(
        'v2.page.log_in_methods.social.drawer.authentication.linking.title',
      )}
      description={t(
        'v2.page.log_in_methods.social.drawer.authentication.linking.description',
        {
          provider: getProviderDisplayName(provider),
        },
      )}
      allowExpand={false}
      inputProps={{
        checked: isProviderEnabled,
        disabled,
        id: `linking-${provider}`,
        isSaved: isProviderSaved,
        onChange: handleToggleProvider,
        type: 'toggle',
      }}
    />
  );
};
