/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GateRuleFilter,
    GateRuleFilterFromJSON,
    GateRuleFilterFromJSONTyped,
    GateRuleFilterToJSON,
} from './GateRuleFilter';
import {
    GateRuleType,
    GateRuleTypeFromJSON,
    GateRuleTypeFromJSONTyped,
    GateRuleTypeToJSON,
} from './GateRuleType';
import {
    TokenAddress,
    TokenAddressFromJSON,
    TokenAddressFromJSONTyped,
    TokenAddressToJSON,
} from './TokenAddress';

/**
 * Definition for a single rule in a Gate. This will check for the presence of a token or NFT, given the contract address, in the wallet of a user being evaluated. For multi-wallet users, we will check all wallets. If a filter is defined, then we will also check that the filter checks apply to the user
 * @export
 * @interface GateRule
 */
export interface GateRule {
    /**
     * 
     * @type {GateRuleType}
     * @memberof GateRule
     */
    type: GateRuleType;
    /**
     * 
     * @type {TokenAddress}
     * @memberof GateRule
     */
    address: TokenAddress;
    /**
     * 
     * @type {GateRuleFilter}
     * @memberof GateRule
     */
    filter?: GateRuleFilter;
}

export function GateRuleFromJSON(json: any): GateRule {
    return GateRuleFromJSONTyped(json, false);
}

export function GateRuleFromJSONTyped(json: any, ignoreDiscriminator: boolean): GateRule {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': GateRuleTypeFromJSON(json['type']),
        'address': TokenAddressFromJSON(json['address']),
        'filter': !exists(json, 'filter') ? undefined : GateRuleFilterFromJSON(json['filter']),
    };
}

export function GateRuleToJSON(value?: GateRule | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': GateRuleTypeToJSON(value.type),
        'address': TokenAddressToJSON(value.address),
        'filter': GateRuleFilterToJSON(value.filter),
    };
}

