import { useTranslation } from 'react-i18next';

import { GateRuleType } from '@dynamic-labs/sdk-api';

import { DropdownField } from '../../../../../../../../components/DropdownField';

import { getGateRuleTypeIcon } from './helpers/helpers';

type GateRuleTypeSelectProps = {
  className?: string;
  disabled?: boolean;
  error?: boolean;
  onSelect: (type: GateRuleType | undefined) => void;
  selectedType?: GateRuleType;
};

export const GateRuleTypeSelect = ({
  selectedType,
  onSelect,
  className,
  error = false,
  disabled = false,
}: GateRuleTypeSelectProps) => {
  const { t } = useTranslation();

  return (
    <DropdownField
      className={className}
      placeholder={t(
        'integrations.onboarding_and_kyc.access_control.gating.forms.selectTypeLabel',
      )}
      value={selectedType}
      onSelect={onSelect}
      error={error}
      disabled={disabled}
      options={Object.values(GateRuleType)?.map((type) => {
        const GateRuleTypeIcon = getGateRuleTypeIcon(type);
        return {
          icon: <GateRuleTypeIcon />,
          label: t(
            `integrations.onboarding_and_kyc.access_control.gating.forms.selectTypeOptions.${type}`,
          ),
          value: type,
        };
      })}
    />
  );
};
