import { IntegrationSetting } from '@dynamic-labs/sdk-api';

export const isBadgeActive = (
  envSettings: IntegrationSetting[],
  attributeName = '',
) => {
  const filteredAtrributes = envSettings.filter(
    ({ name: attribute }) => attribute === attributeName,
  );
  const settings =
    filteredAtrributes.length > 0 ? filteredAtrributes : envSettings;
  return settings.some(({ enabled }) => enabled);
};

export const decodeBase64 = (text: string | undefined): string | undefined =>
  text ? Buffer.from(text, 'base64').toString() : undefined;
