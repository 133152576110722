import { ReactElement } from 'react';

import { Tag } from '@dynamic-labs/northstar';

type Props = {
  enabled: boolean;
  required?: boolean;
  unique?: boolean;
  useForLinking?: boolean;
  useForLogIn?: boolean;
  verify?: boolean;
};

type ReturnValue = {
  Tag: ReactElement;
  id: string;
}[];

export const getToggleBanners = ({
  enabled,
  verify,
  required,
  unique,
  useForLogIn,
  useForLinking,
}: Props): ReturnValue | undefined => {
  if (!enabled) {
    return undefined;
  }

  const banners: ReturnValue = [];

  if (verify) {
    banners.push({
      Tag: <Tag color='transparent' size='medium' text='VERIFY' />,
      id: 'verify',
    });
  }

  if (required) {
    banners.push({
      Tag: <Tag color='transparent' size='medium' text='REQUIRED' />,
      id: 'required',
    });
  }

  if (unique) {
    banners.push({
      Tag: <Tag color='transparent' size='medium' text='ENFORCE UNIQUENESS' />,
      id: 'unique',
    });
  }

  if (useForLogIn) {
    banners.push({
      Tag: <Tag color='transparent' size='medium' text='LOGIN' />,
      id: 'useForLogIn',
    });
  }

  if (useForLinking) {
    banners.push({
      Tag: <Tag color='transparent' size='medium' text='LINKING' />,
      id: 'useForLinking',
    });
  }

  return banners;
};
