import { FC } from 'react';

import { AnimatePresence } from 'framer-motion';

import { ChainalysisControlModal } from '../../../types';
import Header from '../SingleChain/Header';
import { Typography } from '../../../components/Typography';
import { useChainalysisContext } from '../../../context/ChainalysisContext/ChainalysisContext';
import { ModalHashLocations, useModalHashLocation } from '../utils';

import styles from './ChainalysisControl.module.css';
import { ChainalysisModal } from './ChainalysisModal';

const ChainalysisControl: FC<ChainalysisControlModal> = ({
  Icon,
  content,
  title,
  comingSoon,
  scope,
}) => {
  const { newConfiguration } = useChainalysisContext();
  const { showModal, handleShowModal } = useModalHashLocation({
    currentHash: ModalHashLocations.Chainalysis,
  });

  return (
    <>
      <AnimatePresence>
        {showModal && (
          <ChainalysisModal
            title={title}
            Icon={Icon}
            content={content.full}
            setShowModal={handleShowModal}
            scope={scope}
          />
        )}
      </AnimatePresence>
      <button
        aria-label='Open Chainalysis modal'
        type='button'
        className={styles.container}
        onClick={() => (comingSoon ? null : handleShowModal(!showModal))}
      >
        <Header
          Icon={Icon}
          name={title}
          isLiveActive={newConfiguration.live.sanctionsApiEnabled}
          isSandboxActive={newConfiguration.sandbox.sanctionsApiEnabled}
          comingSoon={comingSoon}
          scope={scope}
        />
        <Typography
          variant='paragraph-2'
          weight='regular'
          className={styles.content}
        >
          {content.short}
        </Typography>
      </button>
    </>
  );
};

export default ChainalysisControl;
