import React from 'react';

import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import { DEPLOYED_ENVS, DEVELOPMENT_ENV } from '../../environments/environment';

export const envToClientSideId: Record<string, string> = {
  nonProduction: '62f2992522647111bfb04542',
  production: '62f2992522647111bfb04543',
};

export const getClientSideId = () => {
  if (String(process.env.DYNAMIC_ENV) === 'production') {
    return envToClientSideId.production;
  }
  if (
    [...DEPLOYED_ENVS, DEVELOPMENT_ENV].includes(
      String(process.env.DYNAMIC_ENV),
    )
  ) {
    return envToClientSideId.nonProduction;
  }
  return undefined;
};

export const getLaunchDarklyProvider: () => Promise<
  ({ children }: { children: React.ReactElement }) => JSX.Element
> = () => {
  const clientSideID = getClientSideId();
  if (clientSideID) {
    // At the beggining we authorize as default user who has applied all default feature flags.
    return asyncWithLDProvider({
      clientSideID,
      context: {
        anonymous: true,
        key: 'anonymous',
      },
    });
  }
  return Promise.resolve(({ children }) => children);
};
