import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import {
  SingleToggleCard,
  NetworkIntegrationIcon,
} from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../../app/context/EnvironmentsContext';

type HideNetworkIconProps = {
  disabled: boolean;
};

export const HideNetworkIcon: FC<HideNetworkIconProps> = ({ disabled }) => {
  const { t } = useTranslation();
  const { settings, initialSettings, setSettings } = useSettingsContext();
  const { activeEnvironmentType } = useEnvironmentsContext();

  const isSettingEnabled =
    Boolean(settings[activeEnvironmentType].sdk?.hideNetworkInDynamicWidget) &&
    !disabled;

  const isSettingSaved = Boolean(
    initialSettings[activeEnvironmentType].sdk?.hideNetworkInDynamicWidget ===
      isSettingEnabled,
  );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    setSettings({
      ...settings,
      [activeEnvironmentType]: {
        ...settings[activeEnvironmentType],
        sdk: {
          ...settings[activeEnvironmentType].sdk,
          hideNetworkInDynamicWidget: e.target.checked,
        },
      },
    });
  };

  return (
    <SingleToggleCard
      accordionKey='hideNetworkInDynamicWidget'
      title={t(
        'v2.page.log_in_methods.branded_wallets.hideNetworkInDynamicWidget.toggle',
      )}
      description={t(
        'v2.page.log_in_methods.branded_wallets.hideNetworkInDynamicWidget.description',
      )}
      allowExpand={false}
      inputProps={{
        checked: isSettingEnabled,
        disabled,
        id: 'hideNetworkInDynamicWidget',
        isSaved: isSettingSaved,
        onChange: handleToggle,
        type: 'toggle',
      }}
      Icon={<NetworkIntegrationIcon />}
    />
  );
};
