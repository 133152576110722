import { useMemo } from 'react';

import { t } from 'i18next';

import { useVisitAnalyticsQuery } from '../../hooks';
import { SmallWidgetView } from '../SmallWidgetView';
import { SmallLoadingWidgetView } from '../SmallLoadingWidgetView';

import { SessionsWidgetComponent } from './SessionsWidget.types';

export const SessionsWidget: SessionsWidgetComponent = ({
  endDate,
  environmentId,
  startDate,
}) => {
  const { data: visitAnalyticsData, isLoading } = useVisitAnalyticsQuery({
    endDate,
    environmentId,
    startDate,
  });

  const totalSessions = useMemo(
    () => visitAnalyticsData?.summedSessionsCount,
    [visitAnalyticsData],
  );

  if (isLoading) {
    return <SmallLoadingWidgetView />;
  }

  return (
    <SmallWidgetView
      title={t<string>('analytics.widgets.sessions.title')}
      value={totalSessions || 0}
    />
  );
};
