import { useTranslation } from 'react-i18next';

import { Background } from '../../../icons';

import styles from './placeholder.module.css';

const Placeholder = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <section className={styles.section__left}>
        {t('placeholder.coming_soon')}
      </section>
      <div className={styles.section__right}>
        <Background />
      </div>
    </div>
  );
};

export default Placeholder;
