/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderEnum,
    ProviderEnumFromJSON,
    ProviderEnumFromJSONTyped,
    ProviderEnumToJSON,
} from './ProviderEnum';

/**
 * 
 * @export
 * @interface OAuthAccount
 */
export interface OAuthAccount {
    /**
     * 
     * @type {string}
     * @memberof OAuthAccount
     */
    id?: string;
    /**
     * 
     * @type {ProviderEnum}
     * @memberof OAuthAccount
     */
    provider?: ProviderEnum;
    /**
     * 
     * @type {string}
     * @memberof OAuthAccount
     */
    accountUsername?: string;
}

export function OAuthAccountFromJSON(json: any): OAuthAccount {
    return OAuthAccountFromJSONTyped(json, false);
}

export function OAuthAccountFromJSONTyped(json: any, ignoreDiscriminator: boolean): OAuthAccount {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'provider': !exists(json, 'provider') ? undefined : ProviderEnumFromJSON(json['provider']),
        'accountUsername': !exists(json, 'accountUsername') ? undefined : json['accountUsername'],
    };
}

export function OAuthAccountToJSON(value?: OAuthAccount | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'provider': ProviderEnumToJSON(value.provider),
        'accountUsername': value.accountUsername,
    };
}

