import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Dropdown from '../../../../../components/Dropdown';
import { MoreIcon } from '../../../../../../icons';
import { Typography } from '../../../../../components/Typography';
import { CancelInviteModal } from '../CancelInviteModal';

import styles from './InviteDropdown.module.css';

interface InviteDropdownProps {
  alias: string;
  inviteId: string;
}

export const InviteDropdown = ({ alias, inviteId }: InviteDropdownProps) => {
  const { t } = useTranslation();
  const [showCancelInviteModal, setShowCancelInviteModal] = useState(false);

  return (
    <>
      <Dropdown>
        <Dropdown.Control>
          <MoreIcon className={styles.more} />
        </Dropdown.Control>
        <Dropdown.List className='z-20'>
          <Dropdown.Item
            key={`cancel_${alias}`}
            onClick={() => setShowCancelInviteModal(true)}
          >
            <Typography
              variant='paragraph-2'
              weight='medium'
              className='flex pl-2'
            >
              {t('admin.table.dropdown.cancel')}
            </Typography>
          </Dropdown.Item>
        </Dropdown.List>
      </Dropdown>
      {showCancelInviteModal && (
        <CancelInviteModal
          setShowModal={setShowCancelInviteModal}
          inviteId={inviteId}
          alias={alias}
        />
      )}
    </>
  );
};
