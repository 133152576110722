import styles from './Security.module.css';
import { CORS } from './CORS';
import { JWT } from './JWT';

export const Security = () => (
  <section className={styles.wrapper}>
    <JWT />
    <CORS />
  </section>
);
