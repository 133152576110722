import { Dispatch, SetStateAction } from 'react';

import { t } from 'i18next';

import { Invite, Organization } from '@dynamic-labs/sdk-api';

import Button from '../../../components/Button';
import DynamicSplitLayout from '../../../layouts/DynamicSplitLayout';
import { Typography } from '../../../components/Typography';

import InviteBanner from './InviteBanner';
import styles from './accept-invites.module.css';

type Props = {
  invites: Invite[];
  onSubmit: () => void;
  setActiveOrganizationId: (organizationId: string | undefined) => void;
  setHasUserAcceptedInvitation: Dispatch<SetStateAction<boolean>>;
  setUserInvites: Dispatch<SetStateAction<Invite[]>>;
  setUserOrganizations: Dispatch<SetStateAction<Organization[]>>;
};

const AcceptInvites = ({
  invites,
  setUserInvites,
  setActiveOrganizationId,
  setUserOrganizations,
  setHasUserAcceptedInvitation,
  onSubmit,
}: Props) => (
  <DynamicSplitLayout>
    <div>
      <Typography as='h2' variant='subtitle' weight='medium' className='mb-1'>
        {t<string>('invites.subtitle')}
      </Typography>
      <Typography as='h1' variant='title' weight='medium' className='mb-2'>
        {t<string>('invites.title')}
      </Typography>
      <Typography as='h1' variant='paragraph-2' className='mb-6 !text-gray-1'>
        {t<string>('invites.content')}
      </Typography>
      <div className={styles.invites__container}>
        {invites.map(
          ({
            organizationName,
            organizationId,
            status,
            id,
            role,
            createdByEmail,
          }: Invite) => (
            <InviteBanner
              id={id}
              createdByEmail={createdByEmail}
              organizationName={organizationName}
              organizationId={organizationId}
              key={organizationName}
              role={role}
              status={status}
              setActiveOrganizationId={setActiveOrganizationId}
              setUserOrganizations={setUserOrganizations}
              setHasUserAcceptedInvitation={setHasUserAcceptedInvitation}
              setUserInvites={setUserInvites}
            />
          ),
        )}
      </div>
      <Button onClick={onSubmit} variant='secondary'>
        {t<string>('invites.accept_later')}
      </Button>
    </div>
  </DynamicSplitLayout>
);
export default AcceptInvites;
