/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    MFADeviceType,
    MFADeviceTypeFromJSON,
    MFADeviceTypeToJSON,
    OrganizationMfaSettings,
    OrganizationMfaSettingsFromJSON,
    OrganizationMfaSettingsToJSON,
    OrganizationMfaSettingsResponse,
    OrganizationMfaSettingsResponseFromJSON,
    OrganizationMfaSettingsResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
} from '../models';

export interface DisableMfaDeviceForOrganizationRequest {
    organizationId: string;
    mfaDeviceType: MFADeviceType;
}

export interface EnableMfaDeviceForOrganizationRequest {
    organizationId: string;
    mfaDeviceType: MFADeviceType;
}

export interface GetOrganizationMfaSettingsRequest {
    organizationId: string;
}

/**
 * 
 */
export class MfaSettingsApi extends runtime.BaseAPI {

    /**
     * Disable the given mfa device for the organization
     */
    async disableMfaDeviceForOrganizationRaw(requestParameters: DisableMfaDeviceForOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationMfaSettings>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling disableMfaDeviceForOrganization.');
        }

        if (requestParameters.mfaDeviceType === null || requestParameters.mfaDeviceType === undefined) {
            throw new runtime.RequiredError('mfaDeviceType','Required parameter requestParameters.mfaDeviceType was null or undefined when calling disableMfaDeviceForOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/mfaSettings/{mfaDeviceType}/disable`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"mfaDeviceType"}}`, encodeURIComponent(String(requestParameters.mfaDeviceType))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMfaSettingsFromJSON(jsonValue));
    }

    /**
     * Disable the given mfa device for the organization
     */
    async disableMfaDeviceForOrganization(requestParameters: DisableMfaDeviceForOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationMfaSettings> {
        const response = await this.disableMfaDeviceForOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable the given mfa device for the organization
     */
    async enableMfaDeviceForOrganizationRaw(requestParameters: EnableMfaDeviceForOrganizationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationMfaSettings>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling enableMfaDeviceForOrganization.');
        }

        if (requestParameters.mfaDeviceType === null || requestParameters.mfaDeviceType === undefined) {
            throw new runtime.RequiredError('mfaDeviceType','Required parameter requestParameters.mfaDeviceType was null or undefined when calling enableMfaDeviceForOrganization.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/mfaSettings/{mfaDeviceType}/enable`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))).replace(`{${"mfaDeviceType"}}`, encodeURIComponent(String(requestParameters.mfaDeviceType))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMfaSettingsFromJSON(jsonValue));
    }

    /**
     * Enable the given mfa device for the organization
     */
    async enableMfaDeviceForOrganization(requestParameters: EnableMfaDeviceForOrganizationRequest, initOverrides?: RequestInit): Promise<OrganizationMfaSettings> {
        const response = await this.enableMfaDeviceForOrganizationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get all mfa settings for an organization
     */
    async getOrganizationMfaSettingsRaw(requestParameters: GetOrganizationMfaSettingsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<OrganizationMfaSettingsResponse>> {
        if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
            throw new runtime.RequiredError('organizationId','Required parameter requestParameters.organizationId was null or undefined when calling getOrganizationMfaSettings.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organizations/{organizationId}/mfaSettings`.replace(`{${"organizationId"}}`, encodeURIComponent(String(requestParameters.organizationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationMfaSettingsResponseFromJSON(jsonValue));
    }

    /**
     * Get all mfa settings for an organization
     */
    async getOrganizationMfaSettings(requestParameters: GetOrganizationMfaSettingsRequest, initOverrides?: RequestInit): Promise<OrganizationMfaSettingsResponse> {
        const response = await this.getOrganizationMfaSettingsRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
