import { ChangeEvent, FC } from 'react';

import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { SingleToggleCard } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../app/context/ProvidersContext';
import { useSetKycFieldProp } from '../../../../../../../app/hooks/useSetKycFieldProp';
import { IntegrationCategory } from '../../../../../../../app/types';

type UseForSignUpToggleProps = {
  kycField: 'email' | 'phoneNumber';
};

export const UseForSignUpToggle: FC<UseForSignUpToggleProps> = ({
  kycField,
}) => {
  const { t } = useTranslation();
  const { getProviderValue, hasProviderChanged, toggleProvider } =
    useProvidersContext();
  const { updateActiveEnvKycFieldProp } = useSetKycFieldProp(
    IntegrationCategory.OnboardingAndKYC,
  );

  const providerForKycField =
    kycField === 'email' ? ProviderEnum.Dynamic : ProviderEnum.Sms;

  const isProviderEnabled = Boolean(
    getProviderValue(providerForKycField, 'enabledAt'),
  );

  const isProviderEnabledSaved = Boolean(
    !hasProviderChanged(providerForKycField),
  );

  const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      updateActiveEnvKycFieldProp({
        isSelected: true,
        name: kycField,
        type: 'verify',
      });
    }
    toggleProvider(providerForKycField, e.target.checked);
  };

  return (
    <SingleToggleCard
      accordionKey={`sign-up-toggle-${kycField}`}
      title={t(
        'v2.page.log_in_methods.email_and_phoneNumber.shared.sign_up_toggle.title',
      )}
      description={t(
        'v2.page.log_in_methods.email_and_phoneNumber.shared.sign_up_toggle.description',
      )}
      allowExpand={false}
      inputProps={{
        checked: isProviderEnabled,
        id: `sign-up-toggle-${kycField}`,
        isSaved: isProviderEnabledSaved,
        onChange: handleToggle,
        type: 'toggle',
      }}
    />
  );
};
