import { OpacityAnimation, SlideAnimation } from '../../../animations';
import { DropdownMenuDropdownWrapper } from '../DropdownMenu.types';

import styles from './defaultItemsWrapper.module.scss';

export const defaultItemsWrapper: DropdownMenuDropdownWrapper = ({
  children,
  targetWidth,
}) => (
  <OpacityAnimation>
    <SlideAnimation startPosition='down' endPosition='down'>
      <div className={styles.container} style={{ width: targetWidth }}>
        {children}
      </div>
    </SlideAnimation>
  </OpacityAnimation>
);
