import { Dispatch, SetStateAction, useState } from 'react';

import { t } from 'i18next';

import { SingleKey } from '../../../../../components/SingleKey';
import { Alert } from '../../../../../components/Alert';
import Checkbox from '../../../../../components/Checkbox';
import Button from '../../../../../components/Button';

import styles from './CopyTokenView.module.css';

type Props = {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  tokenName: string;
  tokenValue: string;
};

export const CopyTokenView = ({
  setShowModal,
  tokenName,
  tokenValue,
}: Props) => {
  const [selected, setSelected] = useState(false);

  return (
    <>
      <Alert severity='warning' className={styles.alert}>
        {t<string>('apis.create_token_modal.warning_alert')}
      </Alert>
      <p className={styles.paragraph}>
        {t<string>('apis.create_token_modal.token_name')}
      </p>
      <div className={styles['token-name__container']}>{tokenName}</div>
      <p className={styles.paragraph}>
        {t<string>('apis.create_token_modal.token')}
      </p>
      <SingleKey ddHide keyValue={tokenValue} className={styles.token} />
      <label htmlFor='consent' className={styles.label}>
        <Checkbox
          type='checkbox'
          id='consent'
          name='consent'
          onChange={() => setSelected(!selected)}
          ariaLabel='Copy token consent'
        />
        <span className={styles.consent}>
          {t<string>('apis.create_token_modal.consent')}
        </span>
      </label>
      <div className={styles.button__container}>
        <Button onClick={() => setShowModal(false)} disabled={!selected} full>
          {t<string>('apis.create_token_modal.done_button')}
        </Button>
      </div>
    </>
  );
};
