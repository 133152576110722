import { TFunction } from 'react-i18next';

import { OriginResponse } from '@dynamic-labs/sdk-api';

import { TableSkeleton } from '../../../../components/TableSkeleton';
import { OriginCell } from '../OriginCell';
import { CreatedAtCell } from '../CreatedAtCell';

export const securityColumns = (t: TFunction<'translation', undefined>) => [
  {
    Header: '#',
    accessor: (_row: OriginResponse, i: number) => i + 1,
    className: 'text-gray-2 center w-[40px]',
    id: 'index',
    sortable: false,
  },
  {
    Header: t('settings_page.security.cors.table.columns.origin'),
    accessor: (row: OriginResponse) => <OriginCell text={row.origin} />,
    className: 'max-w-[500px]',
    id: 'origin',
    skeleton: <TableSkeleton />,
    sortable: false,
  },
  {
    Header: t('settings_page.security.cors.table.columns.created_at'),
    accessor: (row: OriginResponse) => (
      <CreatedAtCell
        text={row.createdAt}
        origin={row.origin}
        originId={row.id}
      />
    ),

    className: 'min-w-[270px]',
    id: 'created_at',
    skeleton: <TableSkeleton />,
    sortable: true,
  },
];
