/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum CustomHostnameVerificationType {
    Certificate = 'certificate',
    Ownership = 'ownership',
    Alias = 'alias'
}

export function CustomHostnameVerificationTypeFromJSON(json: any): CustomHostnameVerificationType {
    return CustomHostnameVerificationTypeFromJSONTyped(json, false);
}

export function CustomHostnameVerificationTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomHostnameVerificationType {
    return json as CustomHostnameVerificationType;
}

export function CustomHostnameVerificationTypeToJSON(value?: CustomHostnameVerificationType | null): any {
    return value as any;
}

