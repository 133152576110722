/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SdkViewType {
    Login = 'login'
}

export function SdkViewTypeFromJSON(json: any): SdkViewType {
    return SdkViewTypeFromJSONTyped(json, false);
}

export function SdkViewTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdkViewType {
    return json as SdkViewType;
}

export function SdkViewTypeToJSON(value?: SdkViewType | null): any {
    return value as any;
}

