import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { SingleKey } from '../../../../../components/SingleKey/SingleKey';
import { Typography } from '../../../../../components/Typography';
import { ExtendedContent } from '../../../../../data/overviewCards';

import styles from './mainExtended.module.css';

type Props = {
  content: ExtendedContent;
};

export const MainExtended = ({ content }: Props): JSX.Element => {
  const { environments } = useEnvironmentsContext();

  return (
    <div className={styles.container}>
      <Typography variant='paragraph-1'>{content.firstParagraph}</Typography>
      {content.secondParagraph}
      <SingleKey
        keyValue={environments?.sandbox.id || content.noData}
        className={styles['single-key']}
      />
    </div>
  );
};
