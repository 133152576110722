import { FC } from 'react';

// eslint-disable-next-line import/no-namespace
import * as yup from 'yup';
import { t as staticTMethod } from 'i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';

import { ProviderEnum } from '@dynamic-labs/sdk-api';
import { Input, Typography } from '@dynamic-labs/northstar';

import { useProvidersContext } from '../../../../../../../../../app/context/ProvidersContext';
import { SingleKey } from '../../../../../../../../../app/components/SingleKey';

import styles from './OwnCredentialsShopifyForm.module.scss';

type OwnCredentialsShopifyFormProps = {
  provider: ProviderEnum.Shopify;
};

const requiredText = staticTMethod(
  'v2.page.log_in_methods.email_and_phoneNumber.phoneNumber.drawer.credentials.fields.required',
);

const shopifyFormSchema = yup.object({
  clientId: yup.string().required(requiredText),
  clientSecret: yup.string().required(requiredText),
  scopes: yup.string().required(requiredText),
  shopifyStore: yup.string().required(requiredText),
});

type ShopifyCredentialsForm = yup.InferType<typeof shopifyFormSchema>;

type FieldTypes = 'clientId' | 'clientSecret' | 'shopifyStore' | 'scopes';
const SHOPIFY_FORM_FIELDS: FieldTypes[] = [
  'clientId',
  'clientSecret',
  'shopifyStore',
  'scopes',
];

export const OwnCredentialsShopifyForm: FC<OwnCredentialsShopifyFormProps> = ({
  provider,
}) => {
  const { t } = useTranslation();

  const {
    getProviderValue,
    onChangeProvider,
    getProviderRedirectUrl,
    getProviderBasicScopes,
  } = useProvidersContext();

  const methods = useForm<ShopifyCredentialsForm>({
    defaultValues: {
      clientId: getProviderValue(provider, 'clientId'),
      clientSecret: getProviderValue(provider, 'clientSecret'),
      scopes: getProviderValue(provider, 'scopes'),
      shopifyStore: getProviderValue(provider, 'shopifyStore'),
    },
    mode: 'all',
    resolver: yupResolver(shopifyFormSchema),
  });

  const {
    register,
    getValues,
    formState: { errors },
  } = methods;

  const handleOnBlur = () => {
    if (
      errors.clientId ||
      errors.clientSecret ||
      errors.shopifyStore ||
      errors.scopes
    )
      return;

    onChangeProvider(provider, 'clientId', getValues('clientId'));
    onChangeProvider(provider, 'clientSecret', getValues('clientSecret'));
    onChangeProvider(provider, 'shopifyStore', getValues('shopifyStore'));
    onChangeProvider(provider, 'scopes', getValues('scopes'));
  };

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <FormProvider {...methods}>
        <form onBlur={handleOnBlur} className={styles.form}>
          {SHOPIFY_FORM_FIELDS.map((shopifyFields) => (
            <div key={shopifyFields}>
              <Input
                name={shopifyFields}
                onBlur={register(shopifyFields).onBlur}
                onChange={register(shopifyFields).onChange}
                ref={register(shopifyFields).ref}
                error={!!errors[shopifyFields]}
                label={t(
                  `v2.page.log_in_methods.social.drawer.form.${shopifyFields}`,
                )}
              />
              {errors[shopifyFields]?.message && (
                <Typography
                  variant='paragraph-1'
                  color='inherit'
                  className={styles.error}
                >
                  {errors[shopifyFields]?.message}
                </Typography>
              )}
            </div>
          ))}
        </form>
      </FormProvider>
      <SingleKey
        className={styles['redirect-url']}
        title={t('v2.page.log_in_methods.social.drawer.form.redirectUrl')}
        keyValue={getProviderRedirectUrl(provider) || ''}
      />

      <SingleKey
        title={t('v2.page.log_in_methods.social.drawer.form.basicScopes')}
        hideCopy
        keyValue={getProviderBasicScopes(provider) || ''}
      />
    </>
  );
};
