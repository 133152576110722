import { createContext, useContext, useMemo } from 'react';

import { UseMutateAsyncFunction } from 'react-query';

import {
  ChainEnum,
  NameServiceCreateRequest,
  NameServiceResponse,
  NameServiceSigningMessage,
  NameServiceSigningMessageRequest,
} from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';
import { useNameServices } from '../../hooks/useNameServices';

type NameServiceContextProps = {
  createNameServices: UseMutateAsyncFunction<
    NameServiceResponse,
    unknown,
    NameServiceCreateRequest,
    unknown
  >;
  deleteNameServices: UseMutateAsyncFunction<void, unknown, void, unknown>;
  disableNameServices: UseMutateAsyncFunction<
    NameServiceResponse,
    unknown,
    void,
    unknown
  >;
  enableNameServices: UseMutateAsyncFunction<
    NameServiceResponse,
    unknown,
    void,
    unknown
  >;
  fetchNameServiceSigningMessage: UseMutateAsyncFunction<
    NameServiceSigningMessage,
    unknown,
    NameServiceSigningMessageRequest,
    unknown
  >;
  isLoadingNameServices: boolean;
  nameServices: NameServiceResponse | undefined;
};

type NameServicesContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const NameServicesContext = createContext<
  NameServiceContextProps | undefined
>(undefined);

export const NameServicesContextProvider = ({
  children,
}: NameServicesContextProviderProps) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const environmentId = activeEnvironment?.id;

  const {
    createNameServices,
    deleteNameServices,
    disableNameServices,
    enableNameServices,
    fetchNameServiceSigningMessage,
    isLoading,
    nameServices,
  } = useNameServices(environmentId || '', ChainEnum.Evm);

  const services = useMemo(
    () => ({
      createNameServices,
      deleteNameServices,
      disableNameServices,
      enableNameServices,
      fetchNameServiceSigningMessage,
      isLoadingNameServices: isLoading,
      nameServices,
    }),
    [
      createNameServices,
      deleteNameServices,
      disableNameServices,
      enableNameServices,
      fetchNameServiceSigningMessage,
      isLoading,
      nameServices,
    ],
  );

  return (
    <NameServicesContext.Provider value={services}>
      {children}
    </NameServicesContext.Provider>
  );
};

export const useNameServicesContext = () => {
  const context = useContext(NameServicesContext);

  if (context === undefined) {
    throw new Error(
      'usage of useNameServicesContext not wrapped in `NameServicesContextProvider`.',
    );
  }

  return context;
};
