import {
  createContext,
  useContext,
  useState,
  useMemo,
  Dispatch,
  useEffect,
  SetStateAction,
} from 'react';

import { Token } from '@dynamic-labs/sdk-api';

import { logger } from '../../services/logger';
import { tokensApi } from '../../services/api';
import { useEnvironmentsContext } from '../EnvironmentsContext';

type TokensContextProps = {
  setTokens: Dispatch<SetStateAction<Token[] | undefined>>;
  tokens: Token[] | undefined;
};

type TokensContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const TokensContext = createContext<TokensContextProps | undefined>(
  undefined,
);

export const TokensContextProvider = ({
  children,
}: TokensContextProviderProps) => {
  const { activeEnvironment } = useEnvironmentsContext();
  const [tokens, setTokens] = useState<Token[] | undefined>();

  const environmentId = activeEnvironment?.id;

  useEffect(() => {
    const getTokens = async () => {
      try {
        if (environmentId === undefined) {
          return;
        }

        const response = await tokensApi.getTokens({
          environmentId,
        });

        setTokens(response.tokens);
      } catch (e) {
        logger.error(e);
      }
    };

    getTokens();
  }, [environmentId]);

  const value = useMemo(
    () => ({
      setTokens,
      tokens,
    }),
    [tokens],
  );

  return (
    <TokensContext.Provider value={value}>{children}</TokensContext.Provider>
  );
};

export const useTokensContext = () => {
  const context = useContext(TokensContext);

  if (context === undefined) {
    throw new Error(
      'usage of useTokensContext not wrapped in `TokensContextProvider`.',
    );
  }

  return context;
};
