/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChainEnum,
    ChainEnumFromJSON,
    ChainEnumToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    NameServiceCreateRequest,
    NameServiceCreateRequestFromJSON,
    NameServiceCreateRequestToJSON,
    NameServiceResponse,
    NameServiceResponseFromJSON,
    NameServiceResponseToJSON,
    NameServiceSigningMessage,
    NameServiceSigningMessageFromJSON,
    NameServiceSigningMessageToJSON,
    NameServiceSigningMessageRequest,
    NameServiceSigningMessageRequestFromJSON,
    NameServiceSigningMessageRequestToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateNameServiceRequest {
    environmentId: string;
    chainName: ChainEnum;
    nameServiceCreateRequest: NameServiceCreateRequest;
}

export interface DeleteNameServiceRequest {
    environmentId: string;
    chainName: ChainEnum;
}

export interface DisableNameServiceRequest {
    environmentId: string;
    chainName: ChainEnum;
}

export interface EnableNameServiceRequest {
    environmentId: string;
    chainName: ChainEnum;
}

export interface FetchNameServiceSigningMessageRequest {
    environmentId: string;
    chainName: ChainEnum;
    nameServiceSigningMessageRequest: NameServiceSigningMessageRequest;
}

export interface GetNameServiceRequest {
    environmentId: string;
    chainName: ChainEnum;
}

/**
 * 
 */
export class NameServicesApi extends runtime.BaseAPI {

    /**
     * Create a new name service for the environment and chain
     */
    async createNameServiceRaw(requestParameters: CreateNameServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NameServiceResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createNameService.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling createNameService.');
        }

        if (requestParameters.nameServiceCreateRequest === null || requestParameters.nameServiceCreateRequest === undefined) {
            throw new runtime.RequiredError('nameServiceCreateRequest','Required parameter requestParameters.nameServiceCreateRequest was null or undefined when calling createNameService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NameServiceCreateRequestToJSON(requestParameters.nameServiceCreateRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NameServiceResponseFromJSON(jsonValue));
    }

    /**
     * Create a new name service for the environment and chain
     */
    async createNameService(requestParameters: CreateNameServiceRequest, initOverrides?: RequestInit): Promise<NameServiceResponse> {
        const response = await this.createNameServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * delete the name service for the environment and chain
     */
    async deleteNameServiceRaw(requestParameters: DeleteNameServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteNameService.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling deleteNameService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * delete the name service for the environment and chain
     */
    async deleteNameService(requestParameters: DeleteNameServiceRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteNameServiceRaw(requestParameters, initOverrides);
    }

    /**
     * Disabled name service for this environment and chain
     */
    async disableNameServiceRaw(requestParameters: DisableNameServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NameServiceResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling disableNameService.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling disableNameService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}/disable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NameServiceResponseFromJSON(jsonValue));
    }

    /**
     * Disabled name service for this environment and chain
     */
    async disableNameService(requestParameters: DisableNameServiceRequest, initOverrides?: RequestInit): Promise<NameServiceResponse> {
        const response = await this.disableNameServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Enable name service for this environment and chain
     */
    async enableNameServiceRaw(requestParameters: EnableNameServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NameServiceResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling enableNameService.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling enableNameService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}/enable`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NameServiceResponseFromJSON(jsonValue));
    }

    /**
     * Enable name service for this environment and chain
     */
    async enableNameService(requestParameters: EnableNameServiceRequest, initOverrides?: RequestInit): Promise<NameServiceResponse> {
        const response = await this.enableNameServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the unsigned chain-specific message needed to be signed by the wallet owner using the wallet private key
     */
    async fetchNameServiceSigningMessageRaw(requestParameters: FetchNameServiceSigningMessageRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NameServiceSigningMessage>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling fetchNameServiceSigningMessage.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling fetchNameServiceSigningMessage.');
        }

        if (requestParameters.nameServiceSigningMessageRequest === null || requestParameters.nameServiceSigningMessageRequest === undefined) {
            throw new runtime.RequiredError('nameServiceSigningMessageRequest','Required parameter requestParameters.nameServiceSigningMessageRequest was null or undefined when calling fetchNameServiceSigningMessage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}/signingMessage`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: NameServiceSigningMessageRequestToJSON(requestParameters.nameServiceSigningMessageRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NameServiceSigningMessageFromJSON(jsonValue));
    }

    /**
     * Get the unsigned chain-specific message needed to be signed by the wallet owner using the wallet private key
     */
    async fetchNameServiceSigningMessage(requestParameters: FetchNameServiceSigningMessageRequest, initOverrides?: RequestInit): Promise<NameServiceSigningMessage> {
        const response = await this.fetchNameServiceSigningMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the name service for the environment and chain
     */
    async getNameServiceRaw(requestParameters: GetNameServiceRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NameServiceResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getNameService.');
        }

        if (requestParameters.chainName === null || requestParameters.chainName === undefined) {
            throw new runtime.RequiredError('chainName','Required parameter requestParameters.chainName was null or undefined when calling getNameService.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/nameService/{chainName}`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))).replace(`{${"chainName"}}`, encodeURIComponent(String(requestParameters.chainName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NameServiceResponseFromJSON(jsonValue));
    }

    /**
     * Get the name service for the environment and chain
     */
    async getNameService(requestParameters: GetNameServiceRequest, initOverrides?: RequestInit): Promise<NameServiceResponse> {
        const response = await this.getNameServiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
