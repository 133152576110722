import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import { Section } from '@dynamic-labs/northstar';

import { EmailMethod } from './EmailMethod';
import { PhoneNumberMethod } from './PhoneNumberMethod';
import styles from './EmailAndPhoneNumberSection.module.scss';

type EmailAndPhoneNumberSectionProps = {
  disabled: boolean;
};

export const EmailAndPhoneNumberSection: FC<
  EmailAndPhoneNumberSectionProps
> = ({ disabled }) => {
  const { t } = useTranslation();

  return (
    <Section
      title={t('v2.page.log_in_methods.email_and_phoneNumber.title')}
      description={t(
        'v2.page.log_in_methods.email_and_phoneNumber.description',
      )}
    >
      <div className={styles.section}>
        <EmailMethod disabled={disabled} />

        <PhoneNumberMethod disabled={disabled} />
      </div>
    </Section>
  );
};
