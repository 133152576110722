/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InviteStatusEnum,
    InviteStatusEnumFromJSON,
    InviteStatusEnumFromJSONTyped,
    InviteStatusEnumToJSON,
} from './InviteStatusEnum';

/**
 * 
 * @export
 * @interface InviteUpdateRequest
 */
export interface InviteUpdateRequest {
    /**
     * 
     * @type {InviteStatusEnum}
     * @memberof InviteUpdateRequest
     */
    status: InviteStatusEnum;
}

export function InviteUpdateRequestFromJSON(json: any): InviteUpdateRequest {
    return InviteUpdateRequestFromJSONTyped(json, false);
}

export function InviteUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): InviteUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'status': InviteStatusEnumFromJSON(json['status']),
    };
}

export function InviteUpdateRequestToJSON(value?: InviteUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': InviteStatusEnumToJSON(value.status),
    };
}

