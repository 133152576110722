import { useCallback, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';

import {
  IntegrationSetting,
  Network,
  ProjectSettingsChains,
} from '@dynamic-labs/sdk-api';

import { RpcTestResult } from '../../../../types';
import {
  testEvmRpcUrl,
  testSolanaRpcUrl,
  testStarknetRpcUrl,
} from '../../../../utils/testRpcUrls';
import { ArrowIcon } from '../../../../../icons';
import { getNetworkIcon } from '../../../../utils/getNetworkIcon';
import { Toggle } from '../../../../components/Toggle';
import { DefaultRpcMap, ChainName } from '../../../../data/DefaultRpcUrls';

import styles from './NetworkContent.module.css';
import RpcInputForm from './RpcInputForm';

interface NetworkContentProps {
  chainSetting: ProjectSettingsChains;
  deprecated?: boolean;
  index: number;
  initialChainSetting: ProjectSettingsChains;
  isExpanded: boolean;
  /** Whether this is the only network of the chain */
  isSingleNetwork: boolean;
  network: Network;
  requireCustomRpcUrl?: boolean;
  setSetting: (newSetting: IntegrationSetting) => void;
}

const NetworkContent = ({
  chainSetting,
  index,
  initialChainSetting,
  network,
  setSetting,
  isExpanded,
  isSingleNetwork,
  requireCustomRpcUrl,
  deprecated,
}: NetworkContentProps) => {
  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(isExpanded);
  const [loading, setLoading] = useState(false);
  const [testResult, setTestResult] = useState<RpcTestResult>();

  const NetworkIcon = getNetworkIcon(network.networkId);

  const testUrl = useCallback(
    (url: string) => {
      if (loading) return;

      setLoading(true);
      let verifier = null;
      switch (chainSetting.name) {
        case 'evm':
          verifier = testEvmRpcUrl;
          break;
        case 'starknet':
          verifier = testStarknetRpcUrl;
          break;
        case 'solana':
        case 'eclipse':
          verifier = testSolanaRpcUrl;
          break;
        default:
          break;
      }

      if (!verifier) {
        setTestResult({
          message: t('integrations.rpcUrls.failed'),
          success: false,
        });
        setLoading(false);
        return;
      }

      verifier({ networkId: network?.networkId, rpcUrl: url })
        .then((response) => {
          if (response === true) {
            setTestResult({
              message: t('integrations.rpcUrls.successful'),
              success: true,
            });
          } else {
            setTestResult({
              message: t('integrations.rpcUrls.failed'),
              success: false,
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [chainSetting.name, loading, network.networkId],
  );

  const handleChange = useCallback(() => {
    const currentNetwork = network;

    const currentValue = isSingleNetwork
      ? currentNetwork.enabled && chainSetting.enabled
      : currentNetwork.enabled;

    const newValue = !currentValue;

    const updatedNetwork = {
      ...currentNetwork,
      enabled: newValue,
    };

    const updatedNetworks = chainSetting.networks?.slice() || [];
    updatedNetworks[index] = updatedNetwork;

    const updatedChain = {
      ...chainSetting,
      networks: updatedNetworks,
    };

    // When it's a single network, toggling it should toggle the chain itself as well
    if (isSingleNetwork) updatedChain.enabled = newValue;

    setSetting({
      ...chainSetting,
      ...updatedChain,
    });
  }, [chainSetting, index, isSingleNetwork, network, setSetting]);

  const [defaultRpcUrl] = DefaultRpcMap[chainSetting.name as ChainName]?.[
    network.networkId
  ] || [''];

  const debounced = useDebouncedCallback((rpcUrl) => {
    const currentNetwork = network;

    const updatedNetwork = {
      ...currentNetwork,
      rpcUrl,
    };

    const updatedNetworks = chainSetting.networks?.slice() || [];
    updatedNetworks[index] = updatedNetwork;

    const updatedChain = {
      ...chainSetting,
      networks: updatedNetworks,
    };

    setSetting({
      ...chainSetting,
      ...updatedChain,
    });
  }, 750);

  const handleRpcUrlChange = useCallback(
    (e: any) => {
      // Disable if it's required and empty
      if (e.target.value.length === 0 && requireCustomRpcUrl && network.enabled)
        handleChange();

      debounced(e.target.value);
    },
    [debounced, handleChange, network.enabled, requireCustomRpcUrl],
  );

  const hasCustomRpcUrl = network.rpcUrl ? network.rpcUrl.length > 0 : false;

  const toggleIsChecked = network.enabled && chainSetting.enabled;

  const disableToggle =
    (!chainSetting.enabled && !isSingleNetwork) ||
    (requireCustomRpcUrl && !hasCustomRpcUrl && !toggleIsChecked) ||
    (!toggleIsChecked && deprecated);

  const hideNetwork = deprecated && !toggleIsChecked;

  if (hideNetwork) return null;

  return (
    <div
      key={network.chainName}
      className={classNames('flex flex-col border-cloud', {
        'border-b py-3': !isSingleNetwork,
      })}
    >
      <div className='flex items-center flex-wrap'>
        <div className='w-9 h-5'>
          <Toggle
            disabled={disableToggle}
            withIcon
            variant={
              /* istanbul ignore next */
              initialChainSetting?.networks?.[index]?.enabled ===
                network.enabled &&
              initialChainSetting.enabled === chainSetting.enabled
                ? 'success'
                : 'primary'
            }
            className=''
            id={`toggle-network-${network.chainName}-activation`}
            handleChange={handleChange}
            checked={toggleIsChecked}
            ariaLabel={`${t(
              `integrations.chains.networks.toggle${
                deprecated ? '_deprecated' : ''
              }`,
              {
                network: network.chainName,
              },
            )}`}
          />
        </div>

        {!isSingleNetwork && (
          <div className='w-6 h-6 shrink-0 rounded-full ml-4 mr-2'>
            <NetworkIcon
              title={`${network.chainName}-icon`}
              className='w-6 h-6'
            />
          </div>
        )}

        <div
          className={classNames('mr-2 text-sm', { 'ml-4': isSingleNetwork })}
        >
          {isSingleNetwork
            ? t('integrations.chains.toggle.label')
            : network.chainName}

          {deprecated && ` (${t('integrations.deprecated')})`}
        </div>

        <div className={`mr-2 text-sm ${styles.network__expand}`}>
          <button
            aria-label='Collapse list view'
            className={`${styles.button__expand} ${
              expanded ? styles.button__expanded : ''
            }`}
            type='button'
            onClick={() => setExpanded(!expanded)}
          >
            <ArrowIcon className={expanded ? styles.icon__expanded : ''} />
          </button>
        </div>

        {expanded && (
          <RpcInputForm
            key={`rpc-${network.chainName}`}
            testUrl={testUrl}
            testResult={testResult}
            value={network.rpcUrl || ''}
            chainName={network.chainName}
            disabled={loading}
            label={requireCustomRpcUrl ? '' : defaultRpcUrl}
            defaultRpcUrl={defaultRpcUrl}
            onChange={handleRpcUrlChange}
            requireCustomRpcUrl={requireCustomRpcUrl}
          />
        )}
      </div>
    </div>
  );
};

export default NetworkContent;
