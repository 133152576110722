import {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';

import { ChainalysisConfiguration } from '@dynamic-labs/sdk-api';

import { useEnvironmentsContext } from '../EnvironmentsContext';

import { DefaultConfiguration, fetchChainalysisConfiguration } from './helpers';

type EnvironmentConfigurationObject = {
  live: ChainalysisConfiguration;
  sandbox: ChainalysisConfiguration;
};

export type ChainalysisContextProps = {
  configuration: EnvironmentConfigurationObject;
  newConfiguration: EnvironmentConfigurationObject;
  setConfiguration: Dispatch<SetStateAction<EnvironmentConfigurationObject>>;
  setNewConfiguration: Dispatch<SetStateAction<EnvironmentConfigurationObject>>;
};

type ChainalysisContextProviderProps = {
  children: JSX.Element | JSX.Element[];
};

export const ChainalysisContext = createContext<
  ChainalysisContextProps | undefined
>(undefined);

export const ChainalysisContextProvider = ({
  children,
}: ChainalysisContextProviderProps) => {
  const { environments } = useEnvironmentsContext();
  const [configuration, setConfiguration] =
    useState<EnvironmentConfigurationObject>({
      live: DefaultConfiguration,
      sandbox: DefaultConfiguration,
    });
  const [newConfiguration, setNewConfiguration] =
    useState<EnvironmentConfigurationObject>({
      live: DefaultConfiguration,
      sandbox: DefaultConfiguration,
    });

  useEffect(() => {
    const setConfigs = async () => {
      const live = await fetchChainalysisConfiguration(environments?.live?.id);
      const sandbox = await fetchChainalysisConfiguration(
        environments?.sandbox?.id,
      );
      const config = {
        live,
        sandbox,
      };

      setConfiguration(config);
      setNewConfiguration(config);
    };
    setConfigs();
  }, [environments]);

  const value = useMemo(
    () => ({
      configuration,
      newConfiguration,
      setConfiguration,
      setNewConfiguration,
    }),
    [configuration, newConfiguration],
  );

  return (
    <ChainalysisContext.Provider value={value}>
      {children}
    </ChainalysisContext.Provider>
  );
};

export const useChainalysisContext = () => {
  const context = useContext(ChainalysisContext);

  if (context === undefined) {
    throw new Error(
      'usage of useChainalysisContext not wrapped in `ChainalysisContextProvider`.',
    );
  }

  return context;
};
