/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ChainalysisCheckResultEnum,
    ChainalysisCheckResultEnumFromJSON,
    ChainalysisCheckResultEnumFromJSONTyped,
    ChainalysisCheckResultEnumToJSON,
} from './ChainalysisCheckResultEnum';

/**
 * 
 * @export
 * @interface ChainalysisCheck
 */
export interface ChainalysisCheck {
    /**
     * 
     * @type {string}
     * @memberof ChainalysisCheck
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof ChainalysisCheck
     */
    createdAt: Date;
    /**
     * 
     * @type {ChainalysisCheckResultEnum}
     * @memberof ChainalysisCheck
     */
    result: ChainalysisCheckResultEnum;
    /**
     * Valid blockchain wallet address, must be an alphanumeric string without any special characters
     * @type {string}
     * @memberof ChainalysisCheck
     */
    walletPublicKey: string;
    /**
     * 
     * @type {string}
     * @memberof ChainalysisCheck
     */
    response: string;
}

export function ChainalysisCheckFromJSON(json: any): ChainalysisCheck {
    return ChainalysisCheckFromJSONTyped(json, false);
}

export function ChainalysisCheckFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChainalysisCheck {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'createdAt': (new Date(json['createdAt'])),
        'result': ChainalysisCheckResultEnumFromJSON(json['result']),
        'walletPublicKey': json['walletPublicKey'],
        'response': json['response'],
    };
}

export function ChainalysisCheckToJSON(value?: ChainalysisCheck | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'createdAt': (value.createdAt.toISOString()),
        'result': ChainalysisCheckResultEnumToJSON(value.result),
        'walletPublicKey': value.walletPublicKey,
        'response': value.response,
    };
}

