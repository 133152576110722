import { Dispatch, MutableRefObject, SetStateAction, useState } from 'react';

import { Field, Form, Formik, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { object, string, TestContext, ValidationError } from 'yup';

import { Allowlist, AccessOutcomeEnum } from '@dynamic-labs/sdk-api';

import { AccessOutcomeSelect } from '../../AccessControlModal/AccessOutcomeSelect';
import { Typography } from '../../../../../components/Typography';
import { ErrorInfo } from '../../../../../components/ErrorInfo';
import { useEnvironmentsContext } from '../../../../../context/EnvironmentsContext';
import { allowListsApi } from '../../../../../services/api';
import Input from '../../../../../components/Input';
import { useAccessListsContext } from '../../../../../context/AccessListsContext';

import { FormErrors } from './FormErrors';

export type AllowListFormValues = {
  listName: string;
  outcome: AccessOutcomeEnum;
  scope: string;
};

interface Props {
  allowlist?: Allowlist;
  formikFormRef: MutableRefObject<FormikProps<AllowListFormValues> | null>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  shouldValidateForm?: boolean;
}

// Check for duplicated gate or scope name
export const checkDuplicatedName = (
  value: string | undefined,
  context: TestContext,
  allowlists: Allowlist[],
  prop: 'name' | 'scope',
): ValidationError | boolean => {
  const { path, createError } = context;

  if (!value) return createError({ message: 'required', path });
  if (
    allowlists.some((list) => list[prop]?.toLowerCase() === value.toLowerCase())
  ) {
    return createError({
      message: 'duplicatedName',
      path,
    });
  }
  return true;
};

export const AccessListForm = ({
  allowlist,
  formikFormRef,
  setLoading,
  shouldValidateForm,
}: Props) => {
  const { t } = useTranslation();
  const { activeEnvironment } = useEnvironmentsContext();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { accessLists, fetchAccessLists, setActiveList } =
    useAccessListsContext();
  const envId = activeEnvironment?.id || '';

  const otherLists = accessLists.filter((list) => list.id !== allowlist?.id);
  const validationSchema = object().shape({
    listName: string()
      .required('required')
      .test('duplicated_name', '', (value, context) =>
        checkDuplicatedName(value, context, otherLists, 'name'),
      ),
    outcome: string().required('required'),
    scope: string().when('outcome', {
      is: (outcome: AccessOutcomeEnum) => outcome === AccessOutcomeEnum.Scope,
      then: string()
        .required('required')
        .test('duplicated_name', '', (value, context) =>
          checkDuplicatedName(value, context, otherLists, 'scope'),
        ),
    }),
  });

  const onSubmit = async (values: AllowListFormValues) => {
    try {
      setLoading(true);

      if (allowlist?.id) {
        await allowListsApi.updateAllowlistById({
          allowlistId: allowlist?.id,
          postAllowlistsRequest: {
            name: values.listName,
            outcome: values.outcome,
            scope:
              values.outcome === AccessOutcomeEnum.Scope
                ? values.scope
                : undefined,
          },
        });
        setActiveList('');

        fetchAccessLists();
      } else if (envId) {
        const list = await allowListsApi.createAllowlistByEnvironmentId({
          environmentId: envId,
          postAllowlistsRequest: {
            name: values.listName,
            outcome: values.outcome,
            scope: values.scope === '' ? undefined : values.scope,
          },
        });

        setActiveList(list.id);
        fetchAccessLists();
      }
    } catch (e: any) {
      setErrorMessage(
        t(
          'integrations.onboarding_and_kyc.access_control.errors.unexpected_error',
        ),
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formik
      innerRef={formikFormRef}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange={shouldValidateForm}
      validateOnBlur={shouldValidateForm}
      initialValues={{
        listName: allowlist?.name || '',
        outcome: allowlist?.outcome || AccessOutcomeEnum.SiteAccess,
        scope: allowlist?.scope || '',
      }}
    >
      {({ values, errors, touched, setFieldValue }) => (
        <Form id='allowListForm'>
          {errorMessage && (
            <ErrorInfo className='mb-5'>
              <span>
                <Typography variant='paragraph-1'>{errorMessage}</Typography>
              </span>
            </ErrorInfo>
          )}
          <FormErrors
            errors={errors}
            showErrors={
              !!Object.keys(touched).length && !!Object.keys(errors).length
            }
          />

          <Field
            as={Input}
            name='listName'
            id='listName'
            label={t(
              'integrations.onboarding_and_kyc.access_control.access_list.forms.listNameLabel',
            )}
            error={!!errors.listName && !!touched.listName}
          />

          <AccessOutcomeSelect
            selectedOutcome={values.outcome}
            onSelect={(outcome?: AccessOutcomeEnum) =>
              setFieldValue('outcome', outcome)
            }
            className='mb-2'
          />

          {values.outcome === AccessOutcomeEnum.Scope && (
            <Field
              as={Input}
              name='scope'
              id='scope'
              label={t(
                'integrations.onboarding_and_kyc.access_control.scopeNameLabel',
              )}
              error={!!errors.scope && !!touched.scope}
            />
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AccessListForm;
