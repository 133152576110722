import { ReactNode } from 'react';

import styles from './Alert.module.css';

type SeverityType = 'warning' | 'info';

type Props = {
  children: ReactNode;
  className?: string;
  severity: SeverityType;
};

export const Alert = ({ children, severity, className = '' }: Props) => (
  <div className={`${styles.container} ${styles[severity]} ${className}`}>
    {children}
  </div>
);
