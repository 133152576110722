import { AnimatePresence } from 'framer-motion';
import classNames from 'classnames';

import { EnvironmentEnum } from '@dynamic-labs/sdk-api';

import { Environment } from '../../EnvironmentsDropdown.types';
import { DropdownMenuRenderValue } from '../../../DropdownMenu/DropdownMenu.types';
import { EnvironmentDot } from '../../../EnvironmentDot';
import { Typography } from '../../../Typography';
import { Icon } from '../../../Icon';
import { SelectorIcon } from '../../../../icons';
import { capitalize } from '../../../../utils';

import styles from './EnvironmentsDropdownSelectedValueRenderer.module.scss';

export const EnvironmentsDropdownSelectedValueRenderer: DropdownMenuRenderValue<
  string,
  Environment
> = (option) => {
  const {
    props: { rawOption },
  } = option;

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--live']]: rawOption?.type === EnvironmentEnum.Live,
        [styles['container--sandbox']]:
          rawOption?.type === EnvironmentEnum.Sandbox,
      })}
    >
      <AnimatePresence initial={false}>
        {rawOption && (
          <div className={styles.item}>
            <EnvironmentDot type={rawOption.type} className={styles.dot} />

            <Typography
              variant='paragraph-2'
              weight='medium'
              className={styles.text}
            >
              {capitalize(rawOption.type)}
            </Typography>
          </div>
        )}
      </AnimatePresence>
      <div className={styles.trigger}>
        <Icon icon={<SelectorIcon />} size={20} />
      </div>
    </div>
  );
};
