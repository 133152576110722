import { Dispatch, SetStateAction, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { EmbeddedWalletVersionEnum } from '@dynamic-labs/sdk-api';
import {
  Button,
  KeyShieldIcon,
  UserPlusIcon,
  Typography,
} from '@dynamic-labs/northstar';

import { useSettingsContext } from '../../../../../../app/context/SettingsContext';
import { useEnvironmentsContext } from '../../../../../../app/context/EnvironmentsContext';
import Portal from '../../../../../../app/components/Portal';
import Header from '../../../../../../app/components/Portal/Header';
import { logger } from '../../../../../../app/services/logger';

import styles from './UpgradeVersionModal.module.scss';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
type Props = {
  onSubmit: (props: any) => void;
  setShowModal: Dispatch<SetStateAction<boolean>>;
};

export const UpgradeVersionModal = ({ onSubmit, setShowModal }: Props) => {
  const { activeEnvironmentType } = useEnvironmentsContext();
  const { settings } = useSettingsContext();

  const { t } = useTranslation();
  const [acknowledgement, setAcknowledgement] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (!activeEnvironmentType) return;
    setIsSubmitting(true);

    try {
      onSubmit({
        ...settings,
        [activeEnvironmentType]: {
          ...settings[activeEnvironmentType],
          sdk: {
            ...settings[activeEnvironmentType].sdk,
            embeddedWallets: {
              ...settings[activeEnvironmentType].sdk.embeddedWallets,
              defaultWalletVersion: EmbeddedWalletVersionEnum.V2,
            },
          },
        },
      });
    } catch (error) {
      logger.error(error);
    } finally {
      setIsSubmitting(false);
      setShowModal(false);
    }
  };

  return (
    <Portal
      backdropClassName='!z-30'
      handleClose={() => setShowModal(false)}
      className={styles.portal}
    >
      <Header
        handleClose={() => setShowModal(false)}
        headingText=''
        dataTestId='upgrade-header'
      />
      <div className={styles.hero} data-testid='hero'>
        <KeyShieldIcon />
      </div>
      <div className={styles.title}>
        {t('v2.page.embedded_wallets.upgrade_modal.title')}
      </div>
      <Typography variant='paragraph-0' className={styles.paragraph}>
        {t('v2.page.embedded_wallets.upgrade_modal.description')}{' '}
        <a
          className={styles.link}
          href='https://docs.dynamic.xyz/wallets/embedded-wallets/dynamic-embedded-wallets#seamless-signing-session-keys'
          target='_blank'
          rel='noreferrer'
        >
          {t('v2.page.embedded_wallets.upgrade_modal.learn_more')}
        </a>
      </Typography>
      <div className={styles.iconTextWrapper}>
        <UserPlusIcon />
        <Typography variant='paragraph-3' className={styles.sectionText}>
          {t('v2.page.embedded_wallets.upgrade_modal.section_1_title')}
        </Typography>
      </div>
      <Typography variant='paragraph-3' className={styles.sectionParagraph}>
        {t('v2.page.embedded_wallets.upgrade_modal.section_1_description')}
      </Typography>
      <div className={styles.iconTextWrapper}>
        <UserPlusIcon />
        <Typography variant='paragraph-3' className={styles.sectionText}>
          {t('v2.page.embedded_wallets.upgrade_modal.section_2_title')}
        </Typography>
      </div>
      <Typography variant='paragraph-3' className={styles.sectionParagraph}>
        {t('v2.page.embedded_wallets.upgrade_modal.section_2_description')}
      </Typography>
      <div className={styles.iconTextWrapper}>
        <UserPlusIcon />
        <div className={styles.sectionText}>
          {t('v2.page.embedded_wallets.upgrade_modal.section_3_title')}
        </div>
      </div>
      <Typography variant='paragraph-3' className={styles.sectionParagraph}>
        {t('v2.page.embedded_wallets.upgrade_modal.section_3_description_1')}
      </Typography>
      <Typography variant='paragraph-3' className={styles.sectionParagraph}>
        {t('v2.page.embedded_wallets.upgrade_modal.section_3_description_2')}
      </Typography>
      <div className={styles.acknowledgement}>
        <input
          data-testid='acknowledgement-checkbox'
          type='checkbox'
          checked={acknowledgement}
          onChange={() => setAcknowledgement(!acknowledgement)}
        />
        <span className={styles.acknowledgementText}>
          {t('v2.page.embedded_wallets.upgrade_modal.acknowledgement')}{' '}
          <a
            className={styles.upgradeGuideLink}
            href='https://docs.dynamic.xyz/react-sdk/upgrade/v4'
            target='_blank'
            rel='noreferrer'
          >
            {t('v2.page.embedded_wallets.upgrade_modal.sdk_upgrade_guide')}
          </a>
        </span>
      </div>
      <div className={styles.buttonWrapper}>
        <Button
          className={styles.button}
          onClick={handleSubmit}
          text={t('v2.page.embedded_wallets.upgrade_modal.upgrade_button')}
          size='large'
          disabled={!acknowledgement || isSubmitting}
          dataTestId='upgrade-button'
        />
      </div>
    </Portal>
  );
};
