/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum UnprocessableEntityErrorCode {
    EmailAlreadyExists = 'email_already_exists',
    AllowlistAlreadyExists = 'allowlist_already_exists',
    AllowlistEntryAlreadyExists = 'allowlist_entry_already_exists',
    ReassignWalletError = 'reassign_wallet_error',
    ReassignWalletConfirm = 'reassign_wallet_confirm',
    MembersCannotDeleteThemself = 'members_cannot_delete_themself',
    UsernameAlreadyExists = 'username_already_exists',
    WrongEmailVerificationToken = 'wrong_email_verification_token',
    WrongSmsVerificationToken = 'wrong_sms_verification_token',
    InvalidEmailVerification = 'invalid_email_verification',
    InvalidSmsVerification = 'invalid_sms_verification',
    InvalidVerification = 'invalid_verification',
    InvalidPosition = 'invalid_position',
    TooManySmsVerificationAttempts = 'too_many_sms_verification_attempts',
    TooManyEmailVerificationAttempts = 'too_many_email_verification_attempts',
    TooManyVerificationAttempts = 'too_many_verification_attempts',
    OrganizationNameAlreadyExists = 'organization_name_already_exists',
    ProjectNameAlreadyExists = 'project_name_already_exists',
    WalletNotDeployed = 'wallet_not_deployed',
    EmailVerificationRequired = 'email_verification_required',
    PhoneVerificationRequired = 'phone_verification_required',
    InviteAddressRequired = 'invite_address_required',
    ProviderNotAvailable = 'provider_not_available',
    ForbiddenUnlinkRequest = 'forbidden_unlink_request',
    InvalidUnlinkRequest = 'invalid_unlink_request',
    TooManyApiTokens = 'too_many_api_tokens',
    LockTimeout = 'lock_timeout',
    LockTooManyAttempts = 'lock_too_many_attempts',
    NftTokenGatingNotSupportedForChain = 'nft_token_gating_not_supported_for_chain',
    EmptyChainName = 'empty_chain_name',
    NoEnabledEmailProvider = 'no_enabled_email_provider',
    NoEnabledSmsProvider = 'no_enabled_sms_provider',
    InvalidKeyExportUrl = 'invalid_key_export_url',
    InvalidDynamicProps = 'invalid_dynamic_props',
    TooManyRequests = 'too_many_requests',
    TooManyOrganizationsForUser = 'too_many_organizations_for_user',
    TooManyProjectsForOrganization = 'too_many_projects_for_organization',
    EmailAssociatedWithDifferentProvider = 'email_associated_with_different_provider',
    UserHasAlreadyAccountWithEmail = 'user_has_already_account_with_email',
    UserHasAlreadyAccountWithPhoneNumber = 'user_has_already_account_with_phone_number',
    OtherVerifyFailure = 'other_verify_failure',
    EmailTiedToEmbeddedWallet = 'email_tied_to_embedded_wallet',
    InvalidInvite = 'invalid_invite',
    SocialAccountAlreadyExists = 'social_account_already_exists',
    InvalidEmailAddress = 'invalid_email_address',
    InvalidGate = 'invalid_gate',
    ConflictingEmbeddedWalletProviders = 'conflicting_embedded_wallet_providers',
    InvalidUser = 'invalid_user',
    InvalidCorsOrigins = 'invalid_cors_origins',
    InvalidMobileDeeplinkUrls = 'invalid_mobile_deeplink_urls',
    UnauthorizedMobileDeeplinkUrl = 'unauthorized_mobile_deeplink_url',
    InvalidPrivateKeyFormat = 'invalid_private_key_format',
    InvalidEmbeddedWalletSettings = 'invalid_embedded_wallet_settings',
    WalletNotSupportPasskey = 'wallet_not_support_passkey',
    RecoveryEmailUnavailableOrInvalid = 'recovery_email_unavailable_or_invalid',
    ConnectError = 'connect_error',
    InvalidWalletName = 'invalid_wallet_name',
    InvalidWalletAddress = 'invalid_wallet_address',
    EmailRecoveryDisabled = 'email_recovery_disabled',
    NoCompatibleWalletServiceEnabled = 'no_compatible_wallet_service_enabled',
    MissingAaProjectId = 'missing_aa_project_id',
    LinkedEmbeddedWallet = 'linked_embedded_wallet',
    InvalidMpcEnvironment = 'invalid_mpc_environment',
    InvalidRegex = 'invalid_regex',
    RepeatedOptions = 'repeated_options',
    MissingPhoneNumberOrEmail = 'missing_phone_number_or_email',
    DuplicateExists = 'duplicate_exists',
    MfaDeviceNotFound = 'mfa_device_not_found',
    MfaInvalidCode = 'mfa_invalid_code',
    MfaInvalidRequest = 'mfa_invalid_request',
    InvalidPhone = 'invalid_phone',
    GateExists = 'gate_exists',
    InvalidEmbeddedWalletChainsConfiguration = 'invalid_embedded_wallet_chains_configuration',
    MergeAccountsConfirmation = 'merge_accounts_confirmation',
    MergeAccountsInvalid = 'merge_accounts_invalid',
    InvalidWalletId = 'invalid_wallet_id',
    UnableToFetchBalances = 'unable_to_fetch_balances',
    CannotSwitchToUnique = 'cannot_switch_to_unique',
    CustomFieldDataNotUnique = 'custom_field_data_not_unique',
    MetadataSizeExceeded = 'metadata_size_exceeded',
    InvalidCustomHostname = 'invalid_custom_hostname',
    BlockedUserCannotTransferWallet = 'blocked_user_cannot_transfer_wallet',
    FeatureNotEnabledInSuborg = 'feature_not_enabled_in_suborg',
    InvalidUsername = 'invalid_username',
    InvalidExternalAuth = 'invalid_external_auth',
    InvalidChainAddressMatch = 'invalid_chain_address_match',
    InvalidUpdate = 'invalid_update',
    CannotDeleteLastProject = 'cannot_delete_last_project',
    NoHdWalletFound = 'no_hd_wallet_found',
    WalletAccountExistsForChain = 'wallet_account_exists_for_chain',
    TooManyEmbeddedWalletsForUser = 'too_many_embedded_wallets_for_user',
    TooManyEmbeddedWalletsPerChainForUser = 'too_many_embedded_wallets_per_chain_for_user',
    InvalidSessionPublicKey = 'invalid_session_public_key',
    CustomFieldNotUnique = 'custom_field_not_unique'
}

export function UnprocessableEntityErrorCodeFromJSON(json: any): UnprocessableEntityErrorCode {
    return UnprocessableEntityErrorCodeFromJSONTyped(json, false);
}

export function UnprocessableEntityErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): UnprocessableEntityErrorCode {
    return json as UnprocessableEntityErrorCode;
}

export function UnprocessableEntityErrorCodeToJSON(value?: UnprocessableEntityErrorCode | null): any {
    return value as any;
}

