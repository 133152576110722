import classNames from 'classnames';

import { WalletIcon } from '@dynamic-labs/wallet-book';

import { Typography } from '../../../../components/Typography';

import styles from './AvailableWallet.module.css';

type Props = {
  walletData: {
    chainClassName: string;
    key: string;
    name: string;
    title: string;
  };
};

export const AvailableWallet = ({ walletData }: Props) => (
  <div className={styles.container}>
    <div className={classNames(styles.chain, walletData.chainClassName)}>
      <div data-testid='chain-icon' className={styles.icon}>
        <WalletIcon walletKey={walletData.key} />
      </div>

      <Typography variant='paragraph-2' weight='medium'>
        {walletData.title}
      </Typography>
    </div>
  </div>
);
