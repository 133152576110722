import { useState } from 'react';

import { VisitorFilterableFieldsEnum, ChainEnum } from '@dynamic-labs/sdk-api';

export const useVisitorsSearchFilter = () => {
  const [visitorsSearchFilter, setVisitorsSearchFilter] =
    useState<VisitorFilterableFieldsEnum>(VisitorFilterableFieldsEnum.All);
  const [visitorsChainFilter, setVisitorsChainFilter] = useState<ChainEnum>();

  return {
    setVisitorsChainFilter,
    setVisitorsSearchFilter,
    visitorsChainFilter,
    visitorsSearchFilter,
  };
};
