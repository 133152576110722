import { RoleEnum } from '@dynamic-labs/sdk-api';

import { useDashboardContext } from '../../context/DashboardContext';

const rolesLevel = {
  [RoleEnum.Owner]: 0,
  [RoleEnum.Admin]: 1,
  [RoleEnum.Developer]: 2,
  [RoleEnum.Viewer]: 3,
};

export const useDashboardRole = () => {
  const { activeOrganization, userOrganizations } = useDashboardContext();

  // Default to viewer role for security reasons
  const userRole = activeOrganization?.role || RoleEnum.Viewer;

  const checkForAccess = (role: RoleEnum) =>
    rolesLevel[userRole] <= rolesLevel[role];

  const checkAccessForOrganizationId = (
    organizationId: string,
    role: RoleEnum,
  ) => {
    const organizationRole = userOrganizations.find(
      (org) => org.id === organizationId,
    )?.role;

    if (!organizationRole) return false;

    return rolesLevel[organizationRole] <= rolesLevel[role];
  };

  return {
    checkAccessForOrganizationId,
    checkForAccess,
    userRole,
  };
};
