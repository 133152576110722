import { SpinnerIcon } from '../../../icons';

import styles from './spinner.module.css';

interface Props {
  className?: string;
}

export const Spinner = ({ className = '' }: Props) => (
  <SpinnerIcon className={`spinner ${styles.spinner} ${className}`} />
);
