/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BadRequest,
    BadRequestFromJSON,
    BadRequestToJSON,
    Forbidden,
    ForbiddenFromJSON,
    ForbiddenToJSON,
    InternalServerError,
    InternalServerErrorFromJSON,
    InternalServerErrorToJSON,
    TestAccountResponse,
    TestAccountResponseFromJSON,
    TestAccountResponseToJSON,
    Unauthorized,
    UnauthorizedFromJSON,
    UnauthorizedToJSON,
    UnprocessableEntity,
    UnprocessableEntityFromJSON,
    UnprocessableEntityToJSON,
} from '../models';

export interface CreateTestAccountRequest {
    environmentId: string;
}

export interface DeleteTestAccountRequest {
    environmentId: string;
}

export interface GetTestAccountRequest {
    environmentId: string;
}

/**
 * 
 */
export class TestAccountApi extends runtime.BaseAPI {

    /**
     * Create a new test account for this environment
     */
    async createTestAccountRaw(requestParameters: CreateTestAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TestAccountResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling createTestAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/test/account`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestAccountResponseFromJSON(jsonValue));
    }

    /**
     * Create a new test account for this environment
     */
    async createTestAccount(requestParameters: CreateTestAccountRequest, initOverrides?: RequestInit): Promise<TestAccountResponse> {
        const response = await this.createTestAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete the test account for this environment
     */
    async deleteTestAccountRaw(requestParameters: DeleteTestAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling deleteTestAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/test/account`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete the test account for this environment
     */
    async deleteTestAccount(requestParameters: DeleteTestAccountRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteTestAccountRaw(requestParameters, initOverrides);
    }

    /**
     * Get the test account for this environemnt
     */
    async getTestAccountRaw(requestParameters: GetTestAccountRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<TestAccountResponse>> {
        if (requestParameters.environmentId === null || requestParameters.environmentId === undefined) {
            throw new runtime.RequiredError('environmentId','Required parameter requestParameters.environmentId was null or undefined when calling getTestAccount.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/environments/{environmentId}/test/account`.replace(`{${"environmentId"}}`, encodeURIComponent(String(requestParameters.environmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TestAccountResponseFromJSON(jsonValue));
    }

    /**
     * Get the test account for this environemnt
     */
    async getTestAccount(requestParameters: GetTestAccountRequest, initOverrides?: RequestInit): Promise<TestAccountResponse> {
        const response = await this.getTestAccountRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
