import React, { FC } from 'react';

import classNames from 'classnames';

export type SkeletonProps = {
  className?: string;
};

export const Skeleton: FC<SkeletonProps> = ({ className }) => (
  <div
    data-testid='Skeleton'
    className={classNames('bg-cloud-1 w-full py-3 mb-1', className)}
  />
);
