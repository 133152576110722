import { Provider, ProviderEnum } from '@dynamic-labs/sdk-api';

import { isProviderEnabled } from './isProviderEnabled';

const isProviderDisabled = (
  providers: Provider[],
  provider: ProviderEnum,
): boolean => !isProviderEnabled(providers, provider);

const disableProviders = (
  providers: Provider[],
  providerToDisable: ProviderEnum[],
): Provider[] =>
  providers.map((p) => {
    if (providerToDisable.includes(p.provider)) {
      return { ...p, enabledAt: undefined };
    }

    return p;
  });

export const enforceProviderRules = (providers: Provider[]): Provider[] => {
  if (
    isProviderDisabled(providers, ProviderEnum.Turnkey) &&
    isProviderDisabled(providers, ProviderEnum.CoinbaseWaas) &&
    isProviderDisabled(providers, ProviderEnum.MagicLink)
  ) {
    return disableProviders(providers, [
      ProviderEnum.Zerodev,
      ProviderEnum.Alchemy,
    ]);
  }

  return providers;
};
