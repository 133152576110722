import { useMutation, useQuery } from 'react-query';

import { TestAccountResponse } from '@dynamic-labs/sdk-api';

import { testAccountApi } from '../../services/api';

export const useTestAccount = (environmentId: string, enabled = true) => {
  const queryKey = ['testAccount', environmentId];

  /**
   * useQuery wrapper for getTestAccount
   *
   * On fetch, set data to undefined if server errors (404 page expected).
   */
  const { data, isLoading, refetch } = useQuery<
    TestAccountResponse | undefined
  >(
    queryKey,
    async () => {
      try {
        return await testAccountApi.getTestAccount({ environmentId });
      } catch (e) {
        return undefined;
      }
    },
    {
      enabled: !!environmentId && enabled,
    },
  );

  /** useMutation wrapper for createTestAccount */
  const createTestAccountMutation = useMutation(
    () =>
      testAccountApi.createTestAccount({
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  /** useMutation wrapper for deleteTestAccount */
  const deleteTestAccountMutation = useMutation(
    () =>
      testAccountApi.deleteTestAccount({
        environmentId,
      }),
    {
      onSuccess: () => refetch(),
    },
  );

  return {
    createTestAccount: createTestAccountMutation.mutateAsync,
    deleteTestAccount: deleteTestAccountMutation.mutateAsync,
    isLoading,
    refetch,
    testAccount: data,
  };
};
