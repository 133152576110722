import { useMemo } from 'react';

import { useTranslation } from 'react-i18next';

import { listEntriesColumns } from '../AccessListEntriesView/list-entries-columns';

export const useListEntriesColumns = (
  deleteHandler: (entryId: string) => Promise<void>,
) => {
  const { t } = useTranslation();

  const columns = useMemo(
    () => listEntriesColumns(t, deleteHandler),
    [t, deleteHandler],
  );

  return { columns };
};
