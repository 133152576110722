import { FC, useCallback, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';

import {
  Alert,
  Input,
  SingleToggleCard,
  Typography,
} from '@dynamic-labs/northstar';
import { NameServiceCreateRequest } from '@dynamic-labs/sdk-api';
import { useDynamicContext } from '@dynamic-labs/sdk-react-core';

import { logger } from '../../../../../app/services/logger';
import { useWalletDomainsContext } from '../../../../../app/context/WalletDomainsContext';
import { SideDrawer } from '../../../../components/SideDrawer';
import { useNameServicesContext } from '../../../../../app/context/NameServicesContext';
import { ResolverInstructions } from '../ResolverInstructions';
import { getNamestoneSigningMessageRequest } from '../../util';

import styles from './SetUpEnsSubdomains.module.scss';

export const SetUpEnsSubdomains: FC = () => {
  const { t } = useTranslation();

  const { primaryWallet } = useDynamicContext();
  const { primaryEnsDomain, isLoadingDomains } = useWalletDomainsContext();

  const { createNameServices, fetchNameServiceSigningMessage } =
    useNameServicesContext();

  const [ensInputValue, setEnsInputValue] = useState<string>(
    primaryEnsDomain?.name ?? '',
  );
  const [ensInputError, setEnsInputError] = useState<string | undefined>(
    undefined,
  );
  const [isSigning, setIsSigning] = useState<boolean>(false);

  const onDomainChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEnsInputValue(event.target.value);
      setEnsInputError(undefined);
    },
    [setEnsInputValue],
  );

  const getSigningMessage = useCallback(
    async (address: string) => {
      const signingRequest = getNamestoneSigningMessageRequest(address);

      try {
        return await fetchNameServiceSigningMessage(signingRequest);
      } catch (error) {
        logger.error(error);
      }
      return undefined;
    },
    [fetchNameServiceSigningMessage],
  );

  const signMessage = useCallback(
    async (unsignedMessage: string) => {
      try {
        return await primaryWallet?.signMessage(unsignedMessage);
      } catch (error) {
        logger.error(error);
      }
      return undefined;
    },
    [primaryWallet],
  );

  const handleConfirm = useCallback(async () => {
    if (!primaryWallet) {
      setEnsInputError(
        t(
          'v2.page.global_wallets.subdomains.drawer.error.primary_wallet_error',
        ),
      );
      return;
    }

    setIsSigning(true);
    const signingMessage = await getSigningMessage(primaryWallet.address);

    if (
      signingMessage === undefined ||
      signingMessage.unsignedMessage === undefined
    ) {
      setEnsInputError(
        t(
          'v2.page.global_wallets.subdomains.drawer.error.signing_message_fetch_error',
        ),
      );
      setIsSigning(false);
      return;
    }

    const signedMessage = await signMessage(signingMessage.unsignedMessage);

    if (signedMessage === undefined) {
      setEnsInputError(
        t(
          'v2.page.global_wallets.subdomains.drawer.error.signing_cancelled_error',
        ),
      );
      setIsSigning(false);
      return;
    }

    const createRequest: NameServiceCreateRequest = {
      address: primaryWallet.address,
      domain: ensInputValue,
      signature: signedMessage,
    };

    try {
      await createNameServices(createRequest);
    } catch (error) {
      logger.error(error);
      setEnsInputError(
        t('v2.page.global_wallets.subdomains.drawer.error.namestone_api_error'),
      );
    } finally {
      setIsSigning(false);
    }
  }, [
    getSigningMessage,
    signMessage,
    primaryWallet,
    ensInputValue,
    createNameServices,
    t,
  ]);

  const noAddressesDescription = (
    <Typography
      variant='paragraph-1'
      color='gray-1'
      weight='regular'
      className={styles.alert_description}
    >
      <Trans i18nKey='v2.page.global_wallets.subdomains.drawer.no_addresses_alert.description'>
        We haven&apos;t found any ENS domains connected to your wallet. If you
        still need to purchase your ENS domain, visit
        <a
          href='https://ens.domains'
          target='_blank'
          rel='noreferrer'
          className='font-bold text-primary-1'
        >
          ens.domains
        </a>
        to search and register one.
      </Trans>
    </Typography>
  );

  return (
    <>
      <SingleToggleCard
        accordionKey='ens-step-1'
        title={t('v2.page.global_wallets.subdomains.drawer.steps.step_1.title')}
        description={t(
          'v2.page.global_wallets.subdomains.drawer.steps.step_1.description',
        )}
        allowExpand
        defaultExpanded
        customActionIcon={{
          Icon: <> </>,
        }}
      >
        <div className={styles.card_content}>
          {isLoadingDomains && (
            <Typography
              as='p'
              variant='paragraph-2'
              weight='bold'
              color='black-2'
            >
              {t('v2.page.global_wallets.subdomains.drawer.loading')}
            </Typography>
          )}

          {!isLoadingDomains && primaryEnsDomain === undefined && (
            <Alert
              title={t(
                'v2.page.global_wallets.subdomains.drawer.no_addresses_alert.title',
              )}
              description={noAddressesDescription}
              variant='warning'
            />
          )}

          <Typography
            as='p'
            variant='paragraph-1'
            weight='regular'
            color='black-2'
          >
            {t('v2.page.global_wallets.subdomains.drawer.input_description')}
          </Typography>
          <Input
            name='ens-domain'
            value={ensInputValue}
            error={ensInputError !== undefined}
            errorText={ensInputError}
            onChange={onDomainChange}
            label={t('v2.page.global_wallets.subdomains.drawer.input_label')}
          />
        </div>
      </SingleToggleCard>

      <ResolverInstructions domainName={ensInputValue} />

      <SideDrawer.Footer
        confirmText={t('v2.page.global_wallets.subdomains.drawer.confirm')}
        confirmDisabled={isSigning || ensInputValue === ''}
        onConfirm={handleConfirm}
      />
    </>
  );
};
