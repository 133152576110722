/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AssetTransfer
 */
export interface AssetTransfer {
    /**
     * 
     * @type {string}
     * @memberof AssetTransfer
     */
    rawValue?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetTransfer
     */
    summary?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetTransfer
     */
    usdPrice?: string;
    /**
     * 
     * @type {string}
     * @memberof AssetTransfer
     */
    value?: string;
}

export function AssetTransferFromJSON(json: any): AssetTransfer {
    return AssetTransferFromJSONTyped(json, false);
}

export function AssetTransferFromJSONTyped(json: any, ignoreDiscriminator: boolean): AssetTransfer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'rawValue': !exists(json, 'rawValue') ? undefined : json['rawValue'],
        'summary': !exists(json, 'summary') ? undefined : json['summary'],
        'usdPrice': !exists(json, 'usdPrice') ? undefined : json['usdPrice'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function AssetTransferToJSON(value?: AssetTransfer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'rawValue': value.rawValue,
        'summary': value.summary,
        'usdPrice': value.usdPrice,
        'value': value.value,
    };
}

