/* tslint:disable */
/* eslint-disable */
/**
 * Dashboard API
 * Dashboard API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NativeCurrency
 */
export interface NativeCurrency {
    /**
     * 
     * @type {number}
     * @memberof NativeCurrency
     */
    decimals: number;
    /**
     * 
     * @type {string}
     * @memberof NativeCurrency
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof NativeCurrency
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof NativeCurrency
     */
    denom?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeCurrency
     */
    iconUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NativeCurrency
     */
    pricingProviderTokenId?: string;
}

export function NativeCurrencyFromJSON(json: any): NativeCurrency {
    return NativeCurrencyFromJSONTyped(json, false);
}

export function NativeCurrencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): NativeCurrency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'decimals': json['decimals'],
        'name': json['name'],
        'symbol': json['symbol'],
        'denom': !exists(json, 'denom') ? undefined : json['denom'],
        'iconUrl': !exists(json, 'iconUrl') ? undefined : json['iconUrl'],
        'pricingProviderTokenId': !exists(json, 'pricingProviderTokenId') ? undefined : json['pricingProviderTokenId'],
    };
}

export function NativeCurrencyToJSON(value?: NativeCurrency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'decimals': value.decimals,
        'name': value.name,
        'symbol': value.symbol,
        'denom': value.denom,
        'iconUrl': value.iconUrl,
        'pricingProviderTokenId': value.pricingProviderTokenId,
    };
}

