import { useQuery } from 'react-query';

import { useRedcoastQueryContext } from '../../../contexts';

import { UseGetProjectsHook } from './useGetProjects.types';

export const useGetProjects: UseGetProjectsHook = (
  { organizationId },
  options,
) => {
  const { projectsApi } = useRedcoastQueryContext();

  return useQuery(
    ['projectsApi', 'getProjects', organizationId],
    async () => projectsApi.getProjects({ organizationId }),
    options,
  );
};
